import { Expose } from 'class-transformer'
import { IsDefined } from 'class-validator'
import { QuizTopic } from '../../entity'

export class ListQuizTopicsDTO {

    @Expose()
    @IsDefined()
    quizTopics!: QuizTopic[]

}

