import { store } from '../../store'

export const exportUsers = async () => {

    let response: Response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/users/export`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'get'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'EXPORT_USERS_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'EXPORT_USERS_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'EXPORT_USERS_UNEXPECTEDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'EXPORT_USERS_OK' } )
    return await response.text()

}
