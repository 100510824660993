import { useState } from "react";
import { useSelector } from "react-redux";
import { deleteQuoteParty, listAllQuotes, listQuoteParties, listQuotes, saveQuoteParty } from '../../action/quotes'
import { MediaLibraryFile } from "../../entity";
import { State } from "../../state";
import { TextField } from "../field";
import { ImageEditor } from "../image-editor/image-editor";
import { MediaLibrary } from "../media-library";
import { Modal } from "../modal";
import "./quote-parties-modal.scss";
import { deleteNotification } from '../../action/questionnaires'
import { getQuestionnaire } from '../../action/questionnaires/get-questionnaire.action'

interface QuotePartiesModalProps {
  onModalClose?: () => void;
}

export const QuotePartiesModal = (props: QuotePartiesModalProps) => {
  const { quoteParties } = useSelector((state: State) => state.quotes);

  const [image, setImage] = useState(null as MediaLibraryFile | null);
  const [file, setFile] = useState(null as MediaLibraryFile | null);
  const [showMediaLibrary, setShowMediaLibrary] = useState(false);
  const [showImageEditor, setShowImageEditor] = useState(false);
  const [selectedQuoteParty, setSelectedQuoteParty] = useState(
    undefined as number | null | undefined
  );
  const [name, setName] = useState({ error: ``, value: `` });
  const [imageMissing, setImageMissing] = useState(false);

  const save = async () => {
    if (name.value === "") {
      setName({ value: "", error: "missing-field" });
    } else {
      setName({ ...name, error: `` });
    }
    if (!image) {
      setImageMissing(true);
    } else {
      setImageMissing(false);
    }

    if (name.value !== "" && image) {
      await saveQuoteParty({
        id: selectedQuoteParty || null,
        name: name.value,
        image: image!.id,
      });
      await listQuoteParties();
      if (name.error === "" && image !== null) {
        setName({ error: "", value: "" });
        setImage(null);
      }
    }
  };

  const selectParty = (id: number | null) => {
    setImageMissing(false);
    if (id === null) {
      setImage(null);
      setName({ value: ``, error: "" });
      setSelectedQuoteParty(null);
      return;
    }

    const quoteParty = quoteParties.find((quoteParty) => quoteParty.id === id);
    if (quoteParty === undefined) {
      return;
    }

    setName({ value: quoteParty.name, error: "" });
    setImage(quoteParty.image!);
    setSelectedQuoteParty(id);
  };

    const [ showConfirmDelete, setShowConfirmDelete ] = useState( false )
    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )

    const onPartyDelete = async () => {

        setDeleteFunc( () => {

            return async () => {

                await deleteQuoteParty( selectedQuoteParty! )
                await listQuoteParties()
                await listQuotes()
                await listAllQuotes()
                setSelectedQuoteParty( undefined )

            }

        } )
        setShowConfirmDelete( true )

    }

    const deleteParty = () => {

        if ( deleteFunc !== null ) {

            deleteFunc()

        }
        setDeleteFunc( null )
        setShowConfirmDelete( false )

    }


    return (
    <Modal
      className="quote-parties-modal"
      onModalClose={() => props.onModalClose && props.onModalClose()}
    >
      <div className="modal-title">Politikai pártok</div>

      <img
        className="modal-close"
        src="/image/close.svg"
        onClick={() => props.onModalClose && props.onModalClose()}
      />

      <main>
        <div className="quote-parties">
          {quoteParties.map((quoteParty) => (
            <div
              className={`quote-party${
                quoteParty.id === selectedQuoteParty
                  ? ` quote-party--selected`
                  : ``
              }`}
              onClick={(e) => selectParty(quoteParty.id)}
            >
              <span>{quoteParty.name}</span>

              <img alt="" src="/image/edit.svg" />
            </div>
          ))}

          <button className="button" onClick={(e) => selectParty(null)}>
            Új párt hozzáadása +
          </button>
        </div>

        <div className="separator"></div>

        {selectedQuoteParty !== undefined ? (
          <div className="quote-party-form card-div">
            <div className="quote-party-form__title">
              Párt {selectedQuoteParty === null ? `hozzáadása` : `módosítása`}
            </div>
            <div className="input-field">
              <label>Párt neve:</label>
              <TextField
                field={name}
                onChange={(e) => setName({ ...name, value: e.target.value })}
                maxlength={255}
              />
            </div>

            <div className="input-field">
              <label>Párt logója:</label>
              <div
                className={`image-selector${
                  imageMissing ? ` image-selector__error` : ``
                }`}
                onClick={(e) => setShowMediaLibrary(true)}
              >
                {image === null ? (
                  <>
                    <div className="image-selector__icon">
                      <img src="/image/add-white.svg" />
                    </div>

                    <div className="image-selector__caption">
                      Párt hozzáadása
                      <br />
                      1:1
                    </div>
                  </>
                ) : (
                  <img className="image-selector__image" src={image.url} />
                )}
              </div>
            </div>

            <footer>
              <button
                className="button"
                style={{ minWidth: 250 }}
                onClick={(e) => save()}
              >
                Módosítások mentése
              </button>
                { selectedQuoteParty !== null ? (

                    <button className="button button--ghost button--warning" style={{ marginLeft: 30, minWidth: 200 }} onClick={ () => onPartyDelete() }>Törlés</button>

                ) : null }
            </footer>
          </div>
        ) : null}

        {showMediaLibrary ? (
          <MediaLibrary
            onClose={() => setShowMediaLibrary(false)}
            onMediaSelected={(file: MediaLibraryFile) => {
              setFile(file);
              setShowMediaLibrary(false);
              setShowImageEditor(true);
            }}
          />
        ) : null}

        {showImageEditor ? (
          <ImageEditor
            aspectRatio={{ name: `Párt`, ratio: 1 / 1 }}
            mediaLibraryFile={file!}
            onClose={() => setShowImageEditor(false)}
            onReady={(file) => {
              setImage(file);
              setShowImageEditor(false);
            }}
          />
        ) : null}

        { showConfirmDelete ? (

          <Modal className="confirm-delete-party-modal" onModalClose={ () => setShowConfirmDelete( false ) }>

              <div className="modal-title">Párt törlése</div>
              <div className="modal-description">Biztosan törlöd a pártot? A párthoz tartozó összes politikus és azok idézetei is törlésre kerülnek!</div>
              <footer>

                  <button className="button button--ghost" onClick={ () => setShowConfirmDelete( false ) }>Mégse</button>
                  <button className="button button--primary" onClick={ deleteParty }>Törlés</button>

              </footer>

          </Modal>

        ) : null }

      </main>
    </Modal>
  );
};
