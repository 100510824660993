import { store } from "../../store"
import { createCall } from "../base"
import { BASE_METHODS } from "../base/base.constants"
import { getPoliticianQuestions } from "./get-politician-questions.action"
import { DELETE_POLITICIAN_QUESTION_ACTIONS } from "./politicians.constants"

export const deletePoliticianQuestion = async (id: number) => {

    store.dispatch( { type: DELETE_POLITICIAN_QUESTION_ACTIONS.DELETE_POLITICIAN_QUESTION_STARTED } )

    const promise = createCall(`politicians/questions/`+id, BASE_METHODS.DELETE)
    promise.then(async response => {

        store.dispatch( { type: DELETE_POLITICIAN_QUESTION_ACTIONS.DELETE_POLITICIAN_QUESTION_OK, response: response.text() } )

        getPoliticianQuestions()

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })

}
