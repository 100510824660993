import { MouseEvent, useEffect, useState } from "react";
import ContentEditable from "react-contenteditable";
import sanitizeHtml from "sanitize-html";
import "./description-text.area.scss";

interface DescriptionTextAreaProps {
  field: {
    error: string;
    required?: boolean;
    value: string;
  };
  onChange: (text: string) => void;
  placeholder?: string;
  title?: string;
  maxLength?: number;
  disabled?: boolean;
}

export const DescriptionTextArea = (props: DescriptionTextAreaProps) => {

  const [htmlText, setHtmlText] = useState(props.field.value);
  const [selectedText, setSelectedText] = useState("");

  var sanitizeConf = {
    allowedTags: [ "b", "i", "em", "strong", "a", "p", "ul", "ol", "li" ],
    allowedAttributes: { a: [ 'href', 'name', 'target' ] },
    transformTags: {
      'a': sanitizeHtml.simpleTransform('a', {target: '_blank'})
     }
  };

  const onBoldButtonClick = (event: MouseEvent) => {
    event.preventDefault();

    document.execCommand("bold", false);
  };

  const onItalicButtonClick = (event: MouseEvent) => {
    event.preventDefault();

    document.execCommand("italic", false);
  };

  const onUnderlineButtonClick = (event: MouseEvent) => {
    event.preventDefault();

    document.execCommand("underline", false);
  };

    const onOrderedListButtonClick = (event: MouseEvent) => {
        event.preventDefault();

        document.execCommand("insertOrderedList", false);

    };

    const onUnorderedListButtonClick = (event: MouseEvent) => {
        event.preventDefault();

        document.execCommand("insertUnorderedList", false);

    };

  const onHyperlinkButtonClick = (event: MouseEvent) => {
    event.preventDefault();

    let newURL = prompt("Kérem adja meg a link teljes URL-jét");
    if (newURL && newURL.length != 0) {
      if (!/^https?:\/\//i.test(newURL)) {
        newURL = 'http://' + newURL;
      }
      document.execCommand('insertHTML', true, '<a href="' + newURL + '" target="_blank">' + newURL + '</a>');


    } else {
      var textArea = document.getElementById(
        "description"
      ) as HTMLTextAreaElement;
      textArea.focus();
    }
  };

  const sanitize = () => {
    setHtmlText(sanitizeHtml(props.field.value, sanitizeConf));
    // props.onChange(sanitizeHtml(props.field.value, sanitizeConf) )
  };


  const handleChange = (event: any) => {
    // setHtmlText(event.target.value)
    if (
      props.maxLength !== undefined &&
      event.target.value.length > props.maxLength
    ) {
      event.target.value = props.field.value;
    }
    if (selectedText.length > 0 && document.queryCommandState("bold")) {
      document.execCommand("bold", false);
      setSelectedText("");
    }
    if (selectedText.length > 0 && document.queryCommandState("italic")) {
      document.execCommand("italic", false);
      setSelectedText("");
    }
    if (selectedText.length > 0 && document.queryCommandState("underline")) {
      document.execCommand("underline", false);
      setSelectedText("");
    }
    props.onChange(event.target.value);
  };

  const handlePaste = (event: any) => {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    const cleanText = text.replace(/<[^>]+>/g, "");
    document.execCommand("insertText", false, cleanText);
  };

  const handleSelectionChange = () => {
    const selection = window.getSelection();
    if (selection && selection.toString()) {
      setSelectedText(selection.toString());
    }
  };

  useEffect(() => {
    document.addEventListener("selectionchange", handleSelectionChange);
    return () => {
      document.removeEventListener("selectionchange", handleSelectionChange);
    };
  }, []);

  return (
    <div className="description-area--text text-section-container container-top-margin ">
      <div className="input-container-header">
        <h3 className="title-text-h3 container-top-margin">{props.title}</h3>

        <div className="description-editor-container">
          <button className="editor-button bold" onClick={onBoldButtonClick}>
            B
          </button>
          <div className="editor-separator" />
          <button
            className="editor-button italic"
            onClick={onItalicButtonClick}
          >
            I
          </button>
          <div className="editor-separator" />
          <button
            className="editor-button underline"
            onClick={onUnderlineButtonClick}
          >
            U
          </button>
          <div className="editor-separator" />

            <button
                className="editor-button unordered-list"
                onClick={onUnorderedListButtonClick}
            >
                <img alt="" src="/image/list-ul-solid.svg" />
            </button>
            <div className="editor-separator" />
            <button
                className="editor-button ordered-list"
                onClick={onOrderedListButtonClick}
            >
                <img alt="" src="/image/list-ol-solid.svg" />
            </button>
            <div className="editor-separator" />
          <button className="editor-button" onClick={onHyperlinkButtonClick}>
            <span className="material-symbols-outlined link">link</span>
          </button>
        </div>
      </div>

      <ContentEditable
        className="area--description"
        html={props.field.value}
        onChange={handleChange}
        onBlur={sanitize}
        onPaste={handlePaste}
        disabled={props.disabled === true}
      />
    </div>
  );
};
