import { ChangeEvent, MouseEvent, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PoliticianBiographyItem } from '../../../entity'
import { TextField, NumberField } from '../../field'
import './politician-biography-element.scss'
import { Modal } from '../../modal'

interface PoliticianBiographyElementProps {
    biographyItemElement: PoliticianBiographyItem
}

export const PoliticianBiographyElement = ( props: PoliticianBiographyElementProps ) => {
  
    const dispatch = useDispatch()

    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )
    const [ showConfirmModal, setShowConfirmModal ] = useState( false )

    const onDeleteConfirm = async () => {

        await deleteFunc!()
        setShowConfirmModal( false )

    }

    const onYearChange = ( event: ChangeEvent<HTMLInputElement> ) => {
        event.preventDefault()
        dispatch( { type: 'CREATE_POLITICIAN_CHANGE_BIOGRAPHY_ITEM_YEAR', year: event.target.value, id: props.biographyItemElement.id } )
    }

    const onTitleChange = ( event: ChangeEvent<HTMLInputElement> ) => {
        event.preventDefault()
        dispatch( { type: 'CREATE_POLITICIAN_CHANGE_BIOGRAPHY_ITEM_TITLE', title: event.target.value, id: props.biographyItemElement.id } )
    }

    const onDescriptionChange = ( event: ChangeEvent<HTMLInputElement> ) => {
        event.preventDefault()
        dispatch( { type: 'CREATE_POLITICIAN_CHANGE_BIOGRAPHY_ITEM_DESCRIPTION', description: event.target.value, id: props.biographyItemElement.id } )
    }

    const onDelete = ( event: MouseEvent ) => {
        event.preventDefault()
        setDeleteFunc( () => {
            return async () => {
                dispatch( { type: 'CREATE_POLITICIAN_REMOVE_BIOGRAPHY_ITEM', id: props.biographyItemElement.id } )
            }
        } )
        setShowConfirmModal( true )
    }

    return (
        <li key={ props.biographyItemElement.id } className="politician-biography-list__politician-biography-element">

            <div className="column bottom-margin">
                <div className="year-div">
                    <h3 className="title-text-h3 bottom-margin">Évszám</h3>

                    <NumberField field={ props.biographyItemElement.yearField } onChange={ onYearChange } category='year' maxlength={4} min={1001} max= {new Date().getFullYear()+4}/>

                </div>

                <div className="title-div">
                    <div className="title-div-header">
                        <h3 className="title-text-h3 bottom-margin">Megnevezés</h3>

                        <img className="delete" onClick={ onDelete } alt={ "Törlés ikon" } src="/image/trash.svg " />

                    </div>

                    <TextField field={ props.biographyItemElement.titleField } onChange={ onTitleChange } category='year' maxlength={255}/>
                </div>

            </div>

            <div className="row">
                <h3 className="title-text-h3 bottom-margin">Leírás</h3>
                <TextField field={ props.biographyItemElement.descriptionField } onChange={ onDescriptionChange } category='year' />
            </div>

            { showConfirmModal ? (

                <Modal className="politician-biography-confirm-modal" onModalClose={ () => setShowConfirmModal( false ) }>

                    <div className="modal-title">Biztosan törlöd az életút eseményt?</div>
                    <div className="modal-description">Az életút esemény nem kerül ténylegesen törlésre, ameddig nem mented a politikust!</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowConfirmModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ onDeleteConfirm }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

        </li >
    )

}
