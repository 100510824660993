import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetPoliticianQuestionDTO } from '../../dto/politician'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { getPoliticianQuestions } from './get-politician-questions.action'
import { CREATE_POLITICIAN_QUESTION_ACTIONS } from './politicians.constants'

export const createPoliticianQuestion = async () => {

    const body = JSON.stringify( {
        question: store.getState().createPoliticianQuestion.form.question.value,
    } )

    const questionId = store.getState().createPoliticianQuestion.form.id

    store.dispatch( { type: CREATE_POLITICIAN_QUESTION_ACTIONS.CREATE_POLITICIAN_QUESTION_STARTED } )

    const promise = createCall(`politicians/questions`+ (questionId != null ? (`/`+questionId) : ''), questionId != null ? BASE_METHODS.PUT : BASE_METHODS.POST, body)
    promise.then(async response => {

        let responseBody: GetPoliticianQuestionDTO

        try {
            responseBody = plainToClass( GetPoliticianQuestionDTO, JSON.parse( await response.text() ) )
        } catch ( exception ) {
            store.dispatch( { type: CREATE_POLITICIAN_QUESTION_ACTIONS.CREATE_POLITICIAN_QUESTION_INVALID_JSON } )
            return
        }
    
        if ( ( await validate( responseBody ) ).length > 0 ) {
            store.dispatch( { type: CREATE_POLITICIAN_QUESTION_ACTIONS.CREATE_POLITICIAN_QUESTION_INVALID_RESPONSE } )
            return
        }
    
        store.dispatch( { type: CREATE_POLITICIAN_QUESTION_ACTIONS.CREATE_POLITICIAN_QUESTION_OK, question: responseBody.question } )
    
        getPoliticianQuestions()

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })
   
}
