import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getUsers } from '../../../action'
import { Table, UserModal } from '../../../component'
import { User } from '../../../entity'
import { address, county, createFormattedDateTimeString, position } from '../../../helper'
import { State } from '../../../state'
import { InfoSlot, Toolbar, UsersTableMenuButton } from './component'
import { onTableOrderChange, onTableRowClick } from './event'
import './mobile-app-users.page.scss'

export const MobileAppUsers = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { order, stats, users, selectedUser, pagination } = useSelector( ( state: State ) => state.users )

    useEffect(()=>{
        dispatch({ type: 'CLOSE_USER_FORM' })
    },[])

    const columns = [
        {
            align: 'center',
            caption: 'ID',
            key: 'membershipId',
            name: 'user.membershipId',
            width: 200
        },
        {
            align: 'left',
            caption: 'Név',
            name: 'name',
            render: ( row: User ) => `${ row.lastName } ${ row.firstName }`,
            width: 250
        },
        {
            align: 'left',
            caption: 'E-mail cím',
            name: 'email',
            render: ( row: User ) => `${ row.email }`,
            width: 250
        },
        {
            align: 'center',
            canOrder: false,
            caption: 'Telefonszám',
            name: 'phone',
            render: ( row: User ) => row.phone!,
            width: 150
        },
        {
            align: 'center',
            canOrder: false,
            caption: 'Cím',
            name: 'address',
            render: ( row: User ) => address( row ),
            width: 300
        },
        {
            align: 'center',
            caption: 'Megye',
            name: 'user.county',
            render: ( row: User ) => `${ county( row.county ) }`,
            width: 200
        },
        {
            align: 'center',
            caption: 'Pozíció',
            name: 'user.position',
            render: ( row: User ) => `${ position( row.position ) }`,
            width: 200
        },
        {
            align: 'center',
            caption: 'TP',
            key: 'experience',
            name: 'user.experience',
            width: 200
        },
        {
            align: 'center',
            caption: 'Utolsó aktivitás',
            name: 'lastActivity',
            render: ( row: User ) => `${ row.lastUserActivity ? createFormattedDateTimeString( row.lastUserActivity ) : `nincs adat` }`,
            width: 200
        },
        {
            align: 'center',
            canOrder: false,
            render: ( user: User ) => <UsersTableMenuButton user={ user } users={ users } navigate={ navigate } />,
            width: 100
        }
    ]


    return (

        <main className="page page--mobile-app-users">

            <div className="main-div">

                <header>

                    <h1>Mobil App Felhasználók</h1>
                    <div className="stat">Összes tag: { stats.usersCount } fő</div>

                </header>

                <Toolbar />

                <div className="card-div">

                    <header>

                        <h2>Kiemelt statisztikai adatok</h2>

                    </header>

                    <div className="caption">

                        A legfőbb adatok itt jelennek meg:

                    </div>

                    <div className="info-slots">

                        <InfoSlot
                            additionalInfo={ `${ stats.activelast30days / ( stats.activelast30daysbefore === 0 ? 1 : stats.activelast30daysbefore ) * 100 }%-al ${ stats.activelast30days > stats.activelast30daysbefore ? `több` : `kevesebb` } aktív felhasználó` }
                            caption="Aktív felhasználók száma az utolsó 30 napban"
                            color={ stats.activelast30days < stats.activelast30daysbefore ? `red` : `green` }
                            info={ { suffix: `fő`, value: stats.activelast30days.toString() } }
                        />

                        <InfoSlot
                            additionalInfo={ `${ stats.last30days / ( stats.last30daysbefore === 0 ? 1 : stats.last30daysbefore ) * 100 }%-al ${ stats.last30days > stats.last30daysbefore ? `több` : `kevesebb` } felhasználó` }
                            caption="Új felhasználók száma az utolsó 30 napban"
                            color={ stats.last30days < stats.last30daysbefore ? `red` : `green` }
                            info={ { suffix: `fő`, value: stats.last30days.toString() } }
                        />

                    </div>

                </div>

                <div className="card-div">

                    <Table
                        columns={ columns }
                        dataset={ users }
                        order={ order }
                        onOrderChange={ column => onTableOrderChange( column, order ) }
                        onRowClick={ row => onTableRowClick( navigate, row ) }
                        pagination={ { ...pagination, onPageChange: page => getUsers( [ 1 ], page ) } }

                    />

                </div>

            </div>

            { selectedUser !== null ? <UserModal /> : null }

        </main>

    )

}

