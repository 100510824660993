import { Expose, Type } from 'class-transformer'
import { ValidateNested } from 'class-validator'
import { User } from '../../entity'

export class GetMeDTO {

    @Expose()
    @ValidateNested()
    @Type( () => User )
    user!: User

}
