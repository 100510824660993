import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetAllNewsDTO } from '../../dto'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { getNewsStats } from './get-news-stats.action'
import { getAllNews } from './get-all-news.action'
import { MODIFY_NEWS_STATU_ACTIONS } from './news.constants'

export const modifyNewsStatus = async (newsIds:number[], statusId:number) => {

    store.dispatch( { type: MODIFY_NEWS_STATU_ACTIONS.MODIFY_NEWS_STATUS_STARTED } )

    const body =  JSON.stringify( {
        newsIds: newsIds,
        statusId: statusId
    } )

    const promise = createCall( `news/modify-status`, BASE_METHODS.POST, body )
    promise.then(async response => {
        let responseBody: GetAllNewsDTO

        try {
            responseBody = plainToClass( GetAllNewsDTO, JSON.parse( await response!.text() ) )
        } catch ( exception ) {
            store.dispatch( { type: MODIFY_NEWS_STATU_ACTIONS.MODIFY_NEWS_STATUS_INVALID_JSON } )
            return
        }
    
        if ( ( await validate( responseBody ) ).length > 0 ) {
            store.dispatch( { type: MODIFY_NEWS_STATU_ACTIONS.MODIFY_NEWS_STATUS_INVALID_RESPONSE } )
            return
        }

        store.dispatch( { type: MODIFY_NEWS_STATU_ACTIONS.MODIFY_NEWS_STATUS_OK, news: responseBody.news } )

        getAllNews()

        getNewsStats()
    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })

}
