import { CREATE_POLITICIAN_QUESTION_ACTIONS, CREATE_POLITICIAN_TITLE_ACTIONS, DELETE_POLITICIAN_TITLE_ACTIONS } from '../../action/politician/politicians.constants'
import { CreatePoliticianQuestionState, CreatePoliticianTitleState } from '../../state/politicians'
import { message } from '../../helper'

const initialCreatePoliticianTitleState: CreatePoliticianTitleState  = {
    form: {
        id: null,
        title: {
            error: '',
            value: '',
            required: true
        },
        position: {
            error: '',
            value: 10,
            required: true
        }
    },
    saveDate: null,
    politicianTitle: null
}

export const createPoliticianTitleReducer = ( state: CreatePoliticianTitleState = initialCreatePoliticianTitleState, action: any ): CreatePoliticianTitleState => {
    switch ( action.type ) {
        case CREATE_POLITICIAN_TITLE_ACTIONS.CREATE_POLITICIAN_TITLE_CHANGE_TITLE:
            return { ...state, form: { ...state.form, title: { ...state.form.title, value: action.title } } }
        case CREATE_POLITICIAN_TITLE_ACTIONS.CREATE_POLITICIAN_TITLE_CHANGE_POSITION:
            return { ...state, form: { ...state.form, position: { ...state.form.title, value: action.position } } }
        case CREATE_POLITICIAN_TITLE_ACTIONS.CREATE_POLITICIAN_TITLE_STARTED:
            return { ...state, saveDate: new Date() }
        case CREATE_POLITICIAN_TITLE_ACTIONS.CREATE_POLITICIAN_TITLE_INVALID_JSON:
            return { ...state, saveDate: null, politicianTitle: null }
        case CREATE_POLITICIAN_TITLE_ACTIONS.CREATE_POLITICIAN_TITLE_INVALID_RESPONSE:
            return { ...state, saveDate: null, politicianTitle: null }
        case CREATE_POLITICIAN_TITLE_ACTIONS.CREATE_POLITICIAN_TITLE_OK:
            message( `A titulus sikeresen mentésre került!` )
            return { ...state, saveDate: null, form: { ...state.form, id: null, title: { ...state.form.title, value: "" }}, politicianTitle: action.politicianTitle }
        case DELETE_POLITICIAN_TITLE_ACTIONS.DELETE_POLITICIAN_TITLE_OK:
            message( `A titulus sikeresen törlésre került!`, `Sikeres törlés` )
            return state
        case CREATE_POLITICIAN_TITLE_ACTIONS.CREATE_POLITICIAN_TITLE_CLEAR:
            return { ...state, form: { ...state.form, id: null, title: { ...state.form.title, value: "" } } }
        case CREATE_POLITICIAN_TITLE_ACTIONS.CREATE_POLITICIAN_TITLE_MODIFY_TITLE:

            return { ...state,
                form: { ...state.form,
                    id: action.politicianTitle.id,
                    title: {
                        error: '',
                        value: action.politicianTitle.title,
                        required: true
                    },
                    position: {
                        error: '',
                        value: action.politicianTitle.position,
                        required: true
                    }
                }
            }
        default:
            return state
    }
}

