import { ChangeEvent, createRef, MouseEvent, KeyboardEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TextArea } from '../../component/textarea'
import { State } from '../../state'
import { FloatingMessageTable } from './component'
import { SearchButton, SearchInput } from '../../component/search'
import { CREATE_FLOATING_MESSAGE_ACTIONS, GET_FLOATING_MESSAGES_ACTIONS } from '../../action/floating-messages/floating-messages.constants'
import { createFloatingMessage, getFloatingMessages } from '../../action/floating-messages'
import { Dropdown } from '../../component'
import './floating-message.page.scss'
import { MessageModal } from '../../component/modal'

interface FlotaingMessagesProps { }

export const FlotaingMessages = ( props: FlotaingMessagesProps ) => {

    const dispatch = useDispatch()

    const [infoModalVisibility, setInfoModalVisibility] = useState(false);
    const [infoModalTitle, setInfoModalTitle] = useState("");
    const [infoModalDescription, setInfoModalDescription] = useState("");


    const form = useSelector( ( state: State ) => state.floatingMessages.form )
    let typesElements = [{ value: "Hírek", id: 1 },
                        { value: "Események", id: 2 },
                        { value: "Kédőívek", id: 3 },
                        { value: "Politikusok", id: 4 }]


    const formElement = createRef<HTMLFormElement>()

    const onMessageChange = ( event: ChangeEvent<HTMLTextAreaElement> ) => {
        event.preventDefault()
        dispatch( { type: CREATE_FLOATING_MESSAGE_ACTIONS.CREATE_FLOATING_MESSAGE_CHANGE_MESSAGE, message: event.target.value } )
    }

    const onTypeChange = (typeId: any) => {
        dispatch({ type: CREATE_FLOATING_MESSAGE_ACTIONS.CREATE_FLOATING_MESSAGE_CHANGE_TYPE, typeId: typeId })
    }
 
    const onIconChange = async ( imageId: any ) => {
        dispatch({ type: CREATE_FLOATING_MESSAGE_ACTIONS.CREATE_FLOATING_MESSAGE_CHANGE_IMAGE_ID, imageId: imageId })
    }

    const onSaveClick = async( event: MouseEvent ) => {
        event.preventDefault()

        if (!checkFields()) {
            return;
        }

        if ( !formElement.current?.reportValidity() ) { return }

        await createFloatingMessage()
    }

    const checkFields = (): boolean => {
        if (form.message.value.trim().length === 0) {
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription( "A szöveg megadása kötelező!");
            setInfoModalVisibility(true);
            return false
        }

        return true
    }

    const onSearchChange = async ( event: ChangeEvent<HTMLInputElement> ) => {
        event.preventDefault()

        dispatch( { type: GET_FLOATING_MESSAGES_ACTIONS.GET_FLOATING_MESSAGES_SEARCH, search: event.target.value } )

        if ( event.target.value.length == 0 ) {
            await getFloatingMessages()
        }
    }

    const onSearchButtonClick = async ( event: MouseEvent | KeyboardEvent ) => {
        event.preventDefault()

        await getFloatingMessages()
    }

    const onCreateFloatingMessageButtonClick = async ( event: MouseEvent ) => {
        event.preventDefault()

        dispatch( { type: CREATE_FLOATING_MESSAGE_ACTIONS.CREATE_FLOATING_MESSAGE_CLEAR } )
    }

    return (
        <main className="page page-floating-messages">

            <div className="main-div">

                <div className="navigation-title">
                    <h1 className="title-text-h1">Floating üzenetek</h1>
                </div>

                <div className="toolbar">
                    <SearchInput onSearch={ onSearchButtonClick } onChange={ onSearchChange } placeholder="Keresés az üzenetek között" />
                    <SearchButton onClick={ onSearchButtonClick } />
                    <button className="button categories-button" onClick={ onCreateFloatingMessageButtonClick } >+ Floating üzenet</button>
              
                </div>

                <form action="" className="create-floating-message-form" ref={ formElement }>

                    <div className="container">
                        <div className="card-div column">

                            <h3 className="title-text-h2 ">Új Floating üzenet hozzáadása</h3>

                            <div className="text-section-container">
                                <h3 className="title-text-h3 title-text-top-padding">Hol legyen elhelyezve az üzenet *</h3>
                                    <Dropdown
                                        value={form.type}
                                        items={typesElements}
                                        onChange={onTypeChange}
                                        compact={false} />
                        
                                <h3 className="title-text-h3 title-text-top-padding">Szöveg *</h3>
                                <TextArea field={ form.message } onChange={ onMessageChange } placeholder="" category='lead' maxLength={255} /> 

                                <div className="scroll-menu">

                                    <img className={ `scroll-menu__icon ${ form.imageId == 1 ? ` active` : ``  }` } onClick={ () => onIconChange( 1 ) } alt="Floating Feri ikon" src="/image/floating/floating_1.png" />
                                    <img className={ `scroll-menu__icon ${ form.imageId == 2 ? ` active` : ``  }` } onClick={ () => onIconChange( 2 ) } alt="Floating Feri ikon" src="/image/floating/floating_2.png" />
                                    <img className={ `scroll-menu__icon ${ form.imageId == 3 ? ` active` : ``  }` } onClick={ () => onIconChange( 3 ) } alt="Floating Feri ikon" src="/image/floating/floating_3.png" />
                                    <img className={ `scroll-menu__icon ${ form.imageId == 4 ? ` active` : ``  }` } onClick={ () => onIconChange( 4 ) } alt="Floating Feri ikon" src="/image/floating/floating_4.png" />
                                    <img className={ `scroll-menu__icon ${ form.imageId == 5 ? ` active` : ``  }` } onClick={ () => onIconChange( 5 ) } alt="Floating Feri ikon" src="/image/floating/floating_5.png" />
                                    <img className={ `scroll-menu__icon ${ form.imageId == 6 ? ` active` : ``  }` } onClick={ () => onIconChange( 6 ) } alt="Floating Feri ikon" src="/image/floating/floating_6.png" />
                                </div>
                            </div>

                        </div>

                    </div>

                </form>

                <div className="footer">
                    <button className="button save-button" onClick={ onSaveClick } >Mentés</button>
                </div>

                <FloatingMessageTable/>
                
            </div>

            {infoModalVisibility ? (
                <MessageModal
                    title={infoModalTitle}
                    message={infoModalDescription}
                    onCloseClick={() => {
                        setInfoModalVisibility(false);
                    }}
                />
            ) : null}

        </main>
    )
}



