export const prizeType = ( prizeType: number ) => {

    switch ( prizeType ) {

        case 1:
            return `Digitális`
        case 2:
            return `Tapasztalati pont`
        default:
            return `Fizikai`

    }

}