import { store } from "../../store"
import { createCall } from "../base"
import { BASE_METHODS } from "../base/base.constants"
import { getQuestionnaireTags } from "./get-questionnaire-tags.action"
import { DELETE_QUESTIONNAIRE_TAG_ACTIONS } from "./questionnaires.constants"

export const deleteQuestionnaireTag = async (id: number) => {

    store.dispatch( { type: DELETE_QUESTIONNAIRE_TAG_ACTIONS.DELETE_QUESTIONNAIRE_TAG_STARTED } )

    const promise = createCall(`questionnaires/tag/`+id, BASE_METHODS.DELETE)
    promise.then(async response => {

        // //Ha hiba jön a szerverről
        // if ( response.status ===  400 ) {
        //     //olyan titlust akar törölni amihez politikus tartoznak
        //     store.dispatch( { type: POLITICIANS_ACTIONS.GET_POLITICIAN_TITLES_POLITICIAN_NOT_EMPTY } )
        //     return
        // }

        store.dispatch( { type: DELETE_QUESTIONNAIRE_TAG_ACTIONS.DELETE_QUESTIONNAIRE_TAG_OK, response: response.text() } )

        getQuestionnaireTags()

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })

}
