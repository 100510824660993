import { store } from '../../store'

export const resetPassword = async ( token: string, password: string ) => {

    let response: Response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/users/reset-password`, {
            body: JSON.stringify( {
                token: token,
                password: password
            } ),
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'RESET_PASSWORD_TIMEOUT' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'RESET_PASSWORD_UNEXPECTEDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'RESET_PASSWORD_OK' } )

}
