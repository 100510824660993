import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetQuestionnaireTagDTO } from '../../dto/questionnaires'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { getQuestionnaireTags } from './get-questionnaire-tags.action'
import { CREATE_QUESTIONNAIRE_TAG_ACTIONS } from './questionnaires.constants'

export const createQuestionnaireTag = async () => {

    const body = JSON.stringify( {
        tag: store.getState().createQuestionnaireTag.form.tag.value,
    } )

    const tagId = store.getState().createQuestionnaireTag.form.id

    store.dispatch( { type: CREATE_QUESTIONNAIRE_TAG_ACTIONS.CREATE_QUESTIONNAIRE_TAG_STARTED } )

    const promise = createCall(`questionnaires/tag`+ (tagId != null ? (`/`+tagId) : ''), tagId != null ? BASE_METHODS.PUT : BASE_METHODS.POST, body)
    promise.then(async response => {

        let responseBody: GetQuestionnaireTagDTO

        try {
            responseBody = plainToClass( GetQuestionnaireTagDTO, JSON.parse( await response.text() ) )
        } catch ( exception ) {
            store.dispatch( { type: CREATE_QUESTIONNAIRE_TAG_ACTIONS.CREATE_QUESTIONNAIRE_TAG_INVALID_JSON } )
            return
        }
    
        if ( ( await validate( responseBody ) ).length > 0 ) {
            store.dispatch( { type: CREATE_QUESTIONNAIRE_TAG_ACTIONS.CREATE_QUESTIONNAIRE_TAG_INVALID_RESPONSE } )
            return
        }
    
        store.dispatch( { type: CREATE_QUESTIONNAIRE_TAG_ACTIONS.CREATE_QUESTIONNAIRE_TAG_OK, questionnaireTag: responseBody.questionnaireTag } )
    
        getQuestionnaireTags()

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })
   
}
