import { News } from '../../../entity'
import { createFormattedDateString } from '../../../helper'
import './news-card.scss'

interface NewsCardProps {
    newsElement: News | null 
}

export const NewsCard = ( props: NewsCardProps ) => {

    return (
        <div className="news-card">
  
            <img className= { `${props.newsElement == null ? "image-loading" : "news-image"}` } 
                 src= { `${ props.newsElement == null ? null : `${ props.newsElement.imageFile == null ? "/image/dk-logo-16_9.svg" : props.newsElement.imageFile.url }` }` } alt= "Hírhez tartozó kép" />
                      
            { props.newsElement == null ? 
                <h3 className= "title-text-loading" ></h3>
                :      
                ( props.newsElement.statementBy == null ? 
                    <h3 className="title-text-h3 title"> { props.newsElement.title }</h3>
                    : 
                    <h3 className="title-text-h3 title"><a className="statement">{  props.newsElement.statementBy != '' ? props.newsElement.statementBy +':' : "" }</a> { props.newsElement.title }</h3>
                )
            }
            <div className="bottom-div">
           
                <span className={`${props.newsElement == null ? "text-loading" : "label-text date"}`} > { props.newsElement == null ? "" : createFormattedDateString( props.newsElement.visibleFrom ) }</span>

                { (props.newsElement != null && props.newsElement.highlighted) ?  <img className="icon" alt="Kiemelt hír ikon" src= "/image/pin.svg"/> : "" }

                {props.newsElement != null ? ( props.newsElement.category != null ? <span className="category" style={{ background: props.newsElement.category.color }} >{ props.newsElement.category.name }</span> : "") : ""}

            </div>
            
        </div>
    )

}
