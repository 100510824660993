import { MessageModal, Modal } from '../../modal'
import { ChangeEvent, createRef, MouseEvent, MouseEventHandler } from 'react'
import './news-category-modal.scss'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../state'
import { NewsCategory } from '../../../entity'
import { NewsCategoryElement } from './news-category-element'
import { TextField } from '../../field'
import { createNewsCategory } from '../../../action/news-category'
import { GET_NEWS_CATEGORIES_ACTIONS } from '../../../action/news-category/news-category.constants'

interface NewsCategoryModalProps {
    onCloseClick?: MouseEventHandler
    onModalClose?: () => void
}

export const NewsCategoryModal = ( props: NewsCategoryModalProps ) => {

    const dispatch = useDispatch()

    const saveDate = useSelector( ( state: State ) => state.createNewsCategory.saveDate )
    const newsCategories = useSelector( ( state: State ) => state.newsCategories.newsCategories )
    const newsCategoryId = useSelector( ( state: State ) => state.createNewsCategory.form.id )

    const form = useSelector( ( state: State ) => state.createNewsCategory.form )

    const newsNotEmpty = useSelector( ( state: State ) => state.newsCategories.newsNotEmpty )

    const formElement = createRef<HTMLFormElement>()

    let categoriesElements = [ { value: '', id: -1 } ]
    const newsCategryElements: JSX.Element[] = []

    if ( newsCategories !== null && newsCategories !== undefined ) {
        categoriesElements = []
        newsCategories.map( ( newsCategoryElement: NewsCategory ) => {
            newsCategryElements.push( <NewsCategoryElement newsCategoryElement={ newsCategoryElement } /> )
        } )
    }

    const onSaveButtonClick = async ( event: MouseEvent ) => {
        event.preventDefault()
        if ( !formElement.current?.reportValidity() ) { return }
        await createNewsCategory()
    }

    const onNewCategoryButtonClick = async ( event: MouseEvent ) => {
        event.preventDefault()
        dispatch( { type: 'CREATE_NEWS_CATEGORY_CLEAR' } )
    }

    const onNameChange = ( event: ChangeEvent<HTMLInputElement> ) => {
        event.preventDefault()
        dispatch( { type: 'CREATE_NEWS_CATEGORY_CHANGE_NAME', name: event.target.value } )
    }

    const onColorButtonClick = async ( color: string ) => {
        dispatch( { type: 'CREATE_NEWS_CATEGORY_CHANGE_COLOR', color: color } )
    }

    return (
        <Modal onModalClose={ () => props.onModalClose && props.onModalClose() }>

            <div className="news-category-modal">

                <div className="news-category-modal__header">
                    <div className="news-category-modal__title"> Kategóriák </div>
                    <button className="close-button" onClick={ props.onCloseClick }> <img alt="bezárás gomb" src="/image/close.svg" /> </button>
                </div>

                <div className="container">

                    <div className=" ">
                        <ul id={ "news_category_table" } className="news-table-list">
                            { newsCategryElements.length != 0 ? newsCategryElements : <span className="label-text empty-text">Nincs megjeleníthető kategória.</span> }
                        </ul>

                        <div className="new-category-button-div">
                            <button className="button " onClick={ onNewCategoryButtonClick }> Új kategória hozzáadása +</button>
                        </div>
                    </div>

                    <div className="separator" />

                    <div className="card-div" >

                        <form action="" className="editor-div" ref={ formElement }>

                            <h2 className="title-text-h2 title-margin">Kategória módosítása</h2>

                            <div className="name-div">
                                <h3 className="title-text-h3 text-right-margin">Kategória neve:</h3>
                                <div className="text-section-container">
                                    <TextField maxlength={ 255 } field={ form.name } onChange={ onNameChange } placeholder="" type={ "small" } />
                                </div>
                            </div>

                            <div className="name-div">
                                <h3 className="title-text-h3 title-text-bottom-padding text-right-margin">Kategória színe:</h3>
                                <span className="color" style={ { background: form.color } } ></span>
                            </div>

                            <div className="colors-div">
                                <button className="color-button" type="button" onClick={ e => onColorButtonClick( "#2AA7DF" ) } style={ { background: "#2AA7DF" } } />
                                <button className="color-button" type="button" onClick={ e => onColorButtonClick( "#C30870" ) } style={ { background: "#C30870" } } />
                                <button className="color-button" type="button" onClick={ e => onColorButtonClick( "#FFD200" ) } style={ { background: "#FFD200" } } />
                                <button className="color-button" type="button" onClick={ e => onColorButtonClick( "#007F4C" ) } style={ { background: "#007F4C" } } />
                                <button className="color-button" type="button" onClick={ e => onColorButtonClick( "#7FAD17" ) } style={ { background: "#7FAD17" } } />
                                <button className="color-button" type="button" onClick={ e => onColorButtonClick( "#9A8822" ) } style={ { background: "#9A8822" } } />

                                <button className="color-button" type="button" onClick={ e => onColorButtonClick( "#555ADF" ) } style={ { background: "#555ADF" } } />
                                <button className="color-button" type="button" onClick={ e => onColorButtonClick( "#401794" ) } style={ { background: "#401794" } } />
                                <button className="color-button" type="button" onClick={ e => onColorButtonClick( "#7D3B8A" ) } style={ { background: "#7D3B8A" } } />
                                <button className="color-button" type="button" onClick={ e => onColorButtonClick( "#784CD2" ) } style={ { background: "#784CD2" } } />
                                <button className="color-button" type="button" onClick={ e => onColorButtonClick( "#C61A2E" ) } style={ { background: "#C61A2E" } } />
                                <button className="color-button" type="button" onClick={ e => onColorButtonClick( "#FF168B" ) } style={ { background: "#FF168B" } } />

                                <button className="color-button" type="button" onClick={ e => onColorButtonClick( "#852270" ) } style={ { background: "#852270" } } />
                                <button className="color-button" type="button" onClick={ e => onColorButtonClick( "#FD575A" ) } style={ { background: "#FD575A" } } />
                                <button className="color-button" type="button" onClick={ e => onColorButtonClick( "#12A7EB" ) } style={ { background: "#12A7EB" } } />
                                <button className="color-button" type="button" onClick={ e => onColorButtonClick( "#8F4834" ) } style={ { background: "#8F4834" } } />
                                <button className="color-button" type="button" onClick={ e => onColorButtonClick( "#336BA9" ) } style={ { background: "#336BA9" } } />
                                <button className="color-button" type="button" onClick={ e => onColorButtonClick( "#B06595" ) } style={ { background: "#B06595" } } />

                                <button className="color-button" type="button" onClick={ e => onColorButtonClick( "#0B8997" ) } style={ { background: "#0B8997" } } />
                                <button className="color-button" type="button" onClick={ e => onColorButtonClick( "#182619" ) } style={ { background: "#182619" } } />
                                <button className="color-button" type="button" onClick={ e => onColorButtonClick( "#DFF199" ) } style={ { background: "#DFF199" } } />
                            </div>

                            <div className="new-category-button-div">
                                <button className="button" type="submit" onClick={ onSaveButtonClick } > { newsCategoryId == null ? "Mentés" : "Módosítások mentése" }</button>
                            </div>

                        </form>

                    </div>

                </div>

                {
                    newsNotEmpty ?

                        <MessageModal title={ "Figyelmeztetés" } message={ "A választott kategóriát nem lehet törölni, mert hírekhez van rendelve. Először állítsd át a híreknél a kategóriát, amit törölni szeretnél." } onCloseClick={ () => { dispatch( { type: GET_NEWS_CATEGORIES_ACTIONS.GET_NEWS_CATEGORIES_NEWS_NOT_EMPTY_CLEAR } ) } } />


                        : ""
                }

            </div>

        </Modal>
    )
}
