 import { ChangeEventHandler } from 'react'
 import './checkbox.scss'

 interface CheckboxProps {
    id: any
    onChange: ChangeEventHandler<HTMLInputElement>
    checked: boolean
 }

 export const Checkbox = ( props: CheckboxProps ) => {

    return (
        // TODO label csere div- re
        <label className= { `checkbox` } id={ "checkbox" }>
            <input 
                id={props.id}
                type="checkbox"
                onChange={props.onChange}
                defaultChecked={props.checked}
            />
            <div className={`${props.checked ? "checkbox--active" : "checkbox--hidden "}`} > 
                <span className="material-symbols-outlined checked">check</span>
            </div> 
        </label>
    );
}

