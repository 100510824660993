import { MouseEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deletePoliticianTitle } from '../../../action/politician'
import { CREATE_POLITICIAN_TITLE_ACTIONS } from '../../../action/politician/politicians.constants'
import { PoliticianTitle } from '../../../entity'
import { State } from '../../../state'
import { MessageModal } from '../../modal'
import './politician-title-modal-element.scss'

interface  PoliticianTitleModalElementProps {
    politicianTitleElement: PoliticianTitle
    deleteVisible: boolean 
    checkVisible?: boolean 
}

export const PoliticianTitleModalElement = ( props: PoliticianTitleModalElementProps ) => {

    const dispatch = useDispatch()
    const selectedTitleId = useSelector( ( state: State ) => state.createPoliticianTitle.form.id )
    const [ deleteModalVisibility, setDeleteModalVisibility ] = useState( false )
    const selectedTitle = useSelector( ( state: State ) => state.createPolitician.form.titles )

    const onDeleteButtonClick = async ( event: MouseEvent ) => {
        event.preventDefault()
        setDeleteModalVisibility(true)
    }

    const onItemSelectClick = async ( event: MouseEvent ) => {
        event.preventDefault()
        dispatch ( { type: CREATE_POLITICIAN_TITLE_ACTIONS.CREATE_POLITICIAN_TITLE_MODIFY_TITLE, politicianTitle: props.politicianTitleElement } )
    }

    const onAddTitleClick = ( event: MouseEvent ) => {
        event.preventDefault()
        if(!selectedTitle.some( item => props.politicianTitleElement.id === item.id )){
            dispatch ( { type: 'CREATE_POLITICIAN_ADD_TITLE', selectedTitle: props.politicianTitleElement } )
        }else{
            dispatch ( { type: 'CREATE_POLITICIAN_REMOVE_TITLE', selectedTitleId: props.politicianTitleElement.id } )
        }
    }

    return (
        <li key={ props.politicianTitleElement.title } className={ `politician-title-list__politician-title-element ${ selectedTitleId == props.politicianTitleElement.id ? `active` : `` }` } onClick={ props.deleteVisible ? onItemSelectClick : onAddTitleClick }>
            <span className="label-text name"> { props.politicianTitleElement.title }</span>

            { props.deleteVisible ? <img className="delete" onClick={ onDeleteButtonClick } alt={ "Tiltulus törlés ikon" } src="/image/trash.svg" /> : null }

            { props.checkVisible ? <img className="check" alt={ "Tiltulus pipa ikon" } src="/image/pin.svg" /> : null }

            { deleteModalVisibility ?
                <MessageModal title={ "Figyelmeztetés" } message={ "Biztosan törölni szeretnéd a titulust?" }  
                        onOkClick={ async () => { 
                            await deletePoliticianTitle(props.politicianTitleElement.id)
                            dispatch ( { type: CREATE_POLITICIAN_TITLE_ACTIONS.CREATE_POLITICIAN_TITLE_CLEAR } )
                            setDeleteModalVisibility(false)
                         } }   
                        onCloseClick={ () => { setDeleteModalVisibility(false)} } />
                : null
            }
        </li >
    )

}
