import { LoaderFunctionArgs } from 'react-router-dom'
import { checkResetPassword } from '../../action'
import { store } from '../../store'

export const resetPasswordLoader = async ( args: LoaderFunctionArgs ) => {

    store.dispatch( { type: `RESET_PASSWORD_CLEAR` } )
    await checkResetPassword( args.params.token! )

}
