import { QuestionnaireQuestionUserAnswer } from '../../../../entity'
import './user-text-answer-element.scss'

interface UserTextAnswerElementProps {
    answerElement: QuestionnaireQuestionUserAnswer
}

export const UserTextAnswerElement = ( props: UserTextAnswerElementProps ) => {

    return (
        <li key={ props.answerElement.id} className="user-text-answer-table__user-text-answer-element" >

            <div className="text-section-container">

                <h3 className="label-text">{props.answerElement.answer}</h3>

            </div>
        
        </li >
    )

}
