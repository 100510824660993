import React, { MouseEvent, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import './courses-list.page.scss'
import { SearchButton, SearchInput } from '../../../component/search'
import { useDispatch, useSelector } from 'react-redux'
import { deleteCourse, editCourse, listCourses } from '../../../action/courses'
import { CoursesTable } from '../../../component/courses-table'
import { State } from '../../../state'
import { CourseTopicsModal } from '../../../component/course-topics-modal'
import { CourseTopic } from '../../../component/course-topic'
import { createFormattedDateTimeString, message } from '../../../helper'
import { MenuButton, Modal } from '../../../component'

export const CoursesList = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { archive, pagination, courseTopics } = useSelector( ( state: State ) => state.courses )
    const highlighted: any = useSelector( ( state: State ) => state.courses.highlighted )
    const [ showTopicsModal, setShowTopicsModal ] = useState( false )

    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )
    const [ showConfirmModal, setShowConfirmModal ] = useState( false )

    const onDelete = async () => {

        await deleteFunc!()
        setShowConfirmModal( false )

    }

    const search = async () => {

        await listCourses()
        navigate( `/kepzesek/lista` )

    }

    const create = ( e: MouseEvent ) => {

        if ( courseTopics.length === 0 ) {

            message( `A képzések létrehozása előtt legalább egy téma létrehozása szükséges!`, `Hiba` )
            return

        }

        e.preventDefault()
        navigate( `/kepzesek/uj` )

    }

    const toggleArchive = async () => {

        dispatch( { type: `TOGGLE_COURSE_ARCHIVE` } )
        await search()

    }

    const setKeyword = async ( keyword: string ) => {

        dispatch( { type: `SET_COURSE_KEYWORD`, keyword } )

        if ( keyword.trim().length === 0 ) {
            await search()
        }

    }

    const onArchiveChange = async ( id: number ) => {

        await editCourse( id, { endDate: Math.round( new Date().getTime() / 1000 ) } )
        await listCourses( pagination.page )

    }

    const onMenuSelect = async ( action: number, id: number ) => {

        switch ( action ) {

            case 3:
                setDeleteFunc( () => {
                    return async () => {
                        await deleteCourse( id )
                        await listCourses( pagination.page )
                    }
                } )
                setShowConfirmModal( true )
                break

            case 2:
                await onArchiveChange( id )
                break

            default:
                navigate( `/kepzesek/${ id }` )

        }

    }

    const openTopicModal = () => {}

    return (

        <main className="page page--courses-list">

            <div className="main-div">

                <div className="navigation-title">

                    <NavLink to="/kepzesek">

                        <h1 className="title-text-h1">Képzések &gt;</h1>

                    </NavLink>

                    <h1 className="title-text-h1">Képzések lista</h1>

                </div>

                <div className="toolbar">

                    <SearchInput placeholder="Keresés a képzések között" onChange={ e => setKeyword( e.target.value ) } onSearch={ search } />
                    <SearchButton onClick={ search } />
                    <button className="button button--primary" onClick={ toggleArchive }>{ archive ? `Aktív` : `Archív` } képzések</button>
                    <button className="button button--ghost" onClick={ () => setShowTopicsModal( true ) }>Témák</button>
                    <button className="button button--primary" onClick={ create }>+ Új képzés</button>

                </div>

                { highlighted !== null ? (

                    <div className="card-div card-div--highlighted">

                            <h2 className="title-text-h2">Kiemelt Országos képzés</h2>

                            <div className="card-div highlighted-course">

                                <div className="title">{ highlighted.title }</div>
                                <div className="topic-holder"><CourseTopic color={ highlighted.topic_color } name={ highlighted.topic_name } /></div>
                                <div className="start-date">{ createFormattedDateTimeString( highlighted.startDate ) }</div>
                                <div className="fills"><span>Kitöltések száma:</span> { highlighted.answers } fő</div>
                                <MenuButton hasBackground={ false } items={ [
                                    {
                                        id: 0,
                                        value: `Szerkesztés`,
                                        icon: `edit.svg`
                                    },
                                    {
                                        id: 1,
                                        value: `Megtekintés`,
                                        icon: `eye.svg`
                                    },
                                    {
                                        id: 2,
                                        value: `Archiválás`,
                                        icon: `folder-times.svg`
                                    },
                                    {
                                        id: 3,
                                        value: `Törlés`,
                                        icon: `trash.svg`
                                    }
                                ] } onChange={ ( action: number ) => onMenuSelect( action, highlighted.id ) } />

                            </div>

                    </div>

                ) : null }

                <div className="card-div card-div--table">

                    <CoursesTable />

                </div>

            </div>

            { showTopicsModal ? (

                <CourseTopicsModal onClose={ () => setShowTopicsModal( false ) } />

            ) : null }

            { showConfirmModal ? (

                <Modal className="application-version-confirm-modal" onModalClose={ () => setShowConfirmModal( false ) }>

                    <div className="modal-title">Biztosan törlöd a képzést?</div>
                    <div className="modal-description">Ezt a műveletet nem lehet visszavonni! Biztosan törlöd?</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowConfirmModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ onDelete }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

        </main>

    )

}