import { GET_QUESTIONNAIRES_STAT_ACTIONS } from '../../action/questionnaires/questionnaires.constants'
import { QuestionnaireStatsState } from '../../state/questionnaires'

const initialQuestionnaireStatsState: QuestionnaireStatsState  = {
    lastQuestionnaire: null, 
    mostFillingQuestionnaire: null,
    lastFillingQuestionnaire: null, 
    questionnaireCount: 0, 
    fillingCount: 0
}

export const questionnaireStatsReducer = ( state: QuestionnaireStatsState = initialQuestionnaireStatsState, action: any ): QuestionnaireStatsState => {
    switch ( action.type ) {
        case GET_QUESTIONNAIRES_STAT_ACTIONS.GET_QUESTIONNAIRES_STATS_OK:
            return { ...state, 
                lastQuestionnaire: action.lastQuestionnaire, 
                mostFillingQuestionnaire: action.mostFillingQuestionnaire, 
                lastFillingQuestionnaire: action.lastFillingQuestionnaire, 
                questionnaireCount: action.questionnaireCount, 
                fillingCount: action.fillingCount 
            }
        case GET_QUESTIONNAIRES_STAT_ACTIONS.GET_QUESTIONNAIRES_STATS_INVALID_JSON:
            return { ...state, lastQuestionnaire: null, mostFillingQuestionnaire: null, lastFillingQuestionnaire: null }
        case GET_QUESTIONNAIRES_STAT_ACTIONS.GET_QUESTIONNAIRES_STATS_INVALID_RESPONSE:
            return { ...state, lastQuestionnaire: null, mostFillingQuestionnaire: null, lastFillingQuestionnaire: null }
        default:
            return state
    }
}
