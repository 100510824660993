import { Expose } from 'class-transformer'
import { IsDefined, ValidateIf } from 'class-validator'
import { Course } from '../../entity'

export class GetQuizStatsDTO {

    @Expose()
    @IsDefined()
    courses!: number

    @Expose()
    @IsDefined()
    answers!: number

    @Expose()
    @IsDefined()
    @ValidateIf( ( object, value ) => value !== null )
    newest!: Course | null

    @Expose()
    @IsDefined()
    @ValidateIf( ( object, value ) => value !== null )
    mostAnswer!: Course | null

    @Expose()
    @IsDefined()
    @ValidateIf( ( object, value ) => value !== null )
    leastAnswer!: Course | null

}

