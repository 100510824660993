import { ChangeEvent, MouseEvent } from 'react'
import { QuestionnaireQuestionAnswer } from '../../../../entity'
import { useDispatch } from 'react-redux'
import { CREATE_QUESTIONNAIRE_ACTIONS } from '../../../../action/questionnaires/questionnaires.constants'
import './qestionnaire-question-answer-element.scss'
import { TextField } from '../../../../component/field'
import { TagSelector } from '../../../../component/tag-selector'

interface QuestionnaireQuestionAnswerElementProps {
    position: number
    questionId: number
    answerElement: QuestionnaireQuestionAnswer
    disabled?: boolean
}

export const QuestionnaireQuestionAnswerElement = ( props: QuestionnaireQuestionAnswerElementProps ) => {

    const dispatch = useDispatch()

    const onAnswerChange = ( event: ChangeEvent<HTMLInputElement> ) => {
        event.preventDefault()
        dispatch( { type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ANSWER_ITEM_ANSWER, answer: event.target.value , id: props.answerElement.id, questionId: props.questionId} )
    }

    const onDeleteButtonClick = ( event: MouseEvent<HTMLButtonElement> ) => {
        event.preventDefault()
        dispatch( { type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_REMOVE_QUESTION_ANSWER, selectedAnswerId: props.answerElement.id, questionId: props.questionId } )
    }

    return (
        <li key={ props.answerElement.id+props.position} className="questionnaire-question-answer-table__questionnaire-question-answer-element" >

            <div className="text-section-container">

                <div className="input-container-header">
                    <h3 className="title-text-h3">{props.position}. Válasz *</h3>
                    <button className={`delete-button${ props.disabled === true ? ` disabled` : `` }`} onClick={ onDeleteButtonClick }> <img  className="delete-icon" alt={ "Válasz törlés ikon" } src="/image/trash.svg"/>Törlés</button>
                </div>

                <TextField field={ props.answerElement.answerField } maxlength={ 255 } onChange={ onAnswerChange } placeholder="" category='question' disabled={ props.disabled === true } />

                <TagSelector compact={ true } tags={ props.answerElement.tags || [] } onTagAdd={ tag => dispatch( { type: `QUESTIONNAIRE_ANSWER_ADD_TAG`, tag: tag, id: props.answerElement.id } ) } onTagDelete={ tag => dispatch( { type: `QUESTIONNAIRE_ANSWER_REMOVE_TAG`, tag: tag, id: props.answerElement.id } ) } />

            </div>
        
        </li >
    )

}
