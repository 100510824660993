import { getLeague } from '../../action/gamification'
import { LoaderFunctionArgs } from 'react-router-dom'
import { listTags } from '../../action/tags'

export const leaguePageLoader = async ( args: LoaderFunctionArgs ) => {

    await listTags()
    await getLeague( parseInt( args.params.id as string ) )

    return { seasonId: parseInt( args.params.seasonId as string ) }

}
