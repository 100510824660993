import { Expose } from 'class-transformer'
import { AchievementGroup } from '../../entity'

export class ListAchievementGroupsDTO {

    @Expose()
    achievementGroups!: AchievementGroup[]

}

