import { ChangeEvent } from 'react'
import './password.field.scss'

interface PasswordFieldProps {
    field: {
        error: string
        required?: boolean
        value: string
    }
    onChange: ( event: ChangeEvent<HTMLInputElement> ) => void
    placeholder?: string
}

export const PasswordField = ( props: PasswordFieldProps ) => {

    return (
        <div className={ `field${ props.field.error.length > 0 ? ` field--error` : `` } field--password` }>
            <input
                onChange={ props.onChange }
                placeholder={ props.placeholder }
                required={ props.field.required !== false }
                type="password"
                value={ props.field.value } />
        </div>
    )
}
