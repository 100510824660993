import { Expose, Type } from 'class-transformer'
import { IsDefined, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'
import { ImageFile } from './image-file.entity'
import { PoliticianPhrase } from './politician-phrase.entity'
import { PoliticianQuestion } from './politician-question.entity'
import { PoliticianTitle } from './politician-title.entity'
import { Question } from './question.entity'
import { Tag } from './tag.entity'
import { Quote } from './quote.entity'

export class Politician {

    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number 

    @Expose()
    @IsDefined()
    @IsString()
    firstName!: string

    @Expose()
    @IsDefined()
    @IsString()
    lastName!: string

    @Expose()
    @IsDefined()
    @IsString()
    innerPosition!: string

    @Expose()
    @IsDefined()
    @IsString()
    shadowGovernmentPosition!: string

    @Expose()
    @IsDefined()
    @IsString()
    position!: string

    @Expose()
    @IsDefined()
    @IsString()
    description!: string

    @IsOptional()
    @ValidateNested()
    @Type( () => ImageFile )
    imageFile?: ImageFile | null

    @IsOptional()
    @ValidateNested()
    @Type( () => PoliticianPhrase )
    phrases?: PoliticianPhrase[] | null

    @IsOptional()
    @ValidateNested()
    @Type( () => PoliticianQuestion )
    questions?: PoliticianQuestion[] | null

    @IsOptional()
    @ValidateNested()
    @Type( () => Question )
    question?: Question | null

    @IsOptional()
    @ValidateNested()
    @Type( () => PoliticianTitle )
    titles?: PoliticianTitle[] | null

    @Expose()
    tags!: Tag[]

    @Expose()
    experience!: number

    @Expose()
    quizExperience!: number

    @Expose()
    quotes!: Quote[]

    @Expose()
    isVisible!: boolean

}

   