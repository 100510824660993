import { NavLink, Outlet, useLoaderData } from "react-router-dom"
import "./league.page.scss";
import { State } from "../../state";
import { useSelector } from "react-redux";
import { LeagueModal } from "../../component/league-modal";
import { useState } from "react";

export const LeaguePage = () => {

    const loaderData: any = useLoaderData()
  const { league } = useSelector((state: State) => state.gamification);
  const [showLeagueModal, setShowLeagueModal] = useState(false);

  return (
    <main className="page page--league">
      <div className="main-div">
        <div className="navigation-title">
          <NavLink to="/ligak-es-kihivasok">
            <h1 className="title-text-h1">Ligák és kihívások &gt;</h1>
          </NavLink>
          <h1 className="title-text-h1">{league!.name}</h1>
        </div>

        <div className="card-div league-top">
          <div className="league-detail-card">
            <div className="league-detail-card__top">
              <img src="/image/leagues/0001.png" />
              <div className="league-name">{league!.name}</div>
              <button
                className="button button--primary"
                style={{ minWidth: 215 }}
                onClick={() => setShowLeagueModal(true)}
              >
                Szerkesztés
              </button>
            </div>

            <div className="league-detail-card__bottom">
              <div className="info-box">
                <div className="info-box__caption">Résztvevők</div>
                <div className="info-box__value">{league!.usersCount} fő</div>
              </div>

              <div className="info-box">
                <div className="info-box__caption">Kihívások száma</div>
                <div className="info-box__value">{league!.questsCount}</div>
              </div>

              {league!.requiredAdvanceEXP ||
              league!.requiredAdvanceTopPercentage != 0 ? (
                <div className="info-box">
                  <div className="info-box__caption">
                    Továbbjutási feltételek
                  </div>
                  <div className="info-box__value">
                    {league!.id === 1
                      ? `${league!.id === 1} TP`
                      : `felső ${league!.requiredAdvanceTopPercentage}%`}{" "}
                  </div>
                </div>
              ) : null}

              {league!.allQuestsRequiredToAdvance ? (
                <div className="info-box">
                  <div className="info-box__caption">
                    Összes feladat elvégzése
                  </div>
                  <div className="info-box__value">
                    {league!.allQuestsRequiredToAdvance ? `igen` : `nem`}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="card-div page-selector">
          <div className="pages">
            <NavLink to={`/szezon/${ loaderData.seasonId }/ligak/${league!.id}/statisztika`}>
              Statisztika
            </NavLink>
            <NavLink to={`/szezon/${ loaderData.seasonId }/ligak/${league!.id}/kihivasok`}>Kihívások</NavLink>
            <NavLink to={`/szezon/${ loaderData.seasonId }/ligak/${league!.id}/ranglista`}>Ranglista</NavLink>
            {typeof league?.id === `number` && league.id !== 1 ? (
              <NavLink to={`/szezon/${ loaderData.seasonId }/ligak/${league!.id}/nyeremenyek`}>
                Nyeremények
              </NavLink>
            ) : null}
            <NavLink to={`/szezon/${ loaderData.seasonId }/ligak/${league!.id}/push-uzenetek`}>
              Értesítések
            </NavLink>
          </div>
        </div>

        <Outlet />

        {showLeagueModal ? (
          <LeagueModal
            league={league!}
            onClose={() => setShowLeagueModal(false)}
          />
        ) : null}
      </div>
    </main>
  );
};
