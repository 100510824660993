import { ChangeEvent, createRef, MouseEvent, MouseEventHandler } from 'react'
import './questionnaire-tag-modal.scss'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../../state'
import { QuestionnaireTag } from '../../../../entity'
import { QuestionnaireTagModalElement } from './questionnaire-tag-modal-element'
import { createQuestionnaireTag } from '../../../../action/questionnaires'
import { CREATE_QUESTIONNAIRE_TAG_ACTIONS, GET_QUESTIONNAIRES_ACTIONS } from '../../../../action/questionnaires/questionnaires.constants'
import { Modal } from '../../../../component'
import { TextField } from '../../../../component/field'
import { MessageModal } from '../../../../component/modal'

interface QuestionnaireTagModalProps {
    onCloseClick?: MouseEventHandler
}

export const QuestionnaireTagModal = (  props: QuestionnaireTagModalProps ) => {

    const dispatch = useDispatch()

    const saveDate = useSelector( ( state: State ) => state.createQuestionnaireTag.saveDate )
    const questionnaireTags = useSelector( ( state: State ) => state.questionnaires.questionnaireTags )
    const tagId = useSelector( ( state: State ) => state.createQuestionnaireTag.form.id )

    const form = useSelector( ( state: State ) => state.createQuestionnaireTag.form )

    const questionnairesNotEmpty = useSelector( ( state: State ) => state.questionnaires.questionnairsNotEmpty )

    const formElement = createRef<HTMLFormElement>()

    let tagElements = [{ value: '', id: -1 }]
    const questionnaireTagElements: JSX.Element[] = []

    if ( questionnaireTags !== null &&  questionnaireTags !== undefined) {
        tagElements = []
        questionnaireTags.map(( questionnaireTagElement: QuestionnaireTag  ) => {
            questionnaireTagElements.push( <QuestionnaireTagModalElement questionnaireTagElement={ questionnaireTagElement } /> )
        })
    }

    const onSaveButtonClick = async ( event: MouseEvent ) => {
        event.preventDefault()
        if ( !formElement.current?.reportValidity() ) { return }
        await createQuestionnaireTag()
    }

    const onNewTagButtonClick = async ( event: MouseEvent ) => {
        event.preventDefault()
        dispatch( { type: CREATE_QUESTIONNAIRE_TAG_ACTIONS.CREATE_QUESTIONNAIRE_TAG_CLEAR } )
    }

    const onNameChange = ( event: ChangeEvent<HTMLInputElement> ) => {
        event.preventDefault()
        dispatch( { type: CREATE_QUESTIONNAIRE_TAG_ACTIONS.CREATE_QUESTIONNAIRE_TAG_CHANGE_TAG, tag: event.target.value } )
    }

    return (
        <Modal onModalClose={ () => { } }>

            <div className="questionnaire-tag-modal">

                <div className="questionnaire-tag-modal__header">
                    <div className="questionnaire-tag-modal__title"> Tag-ek </div>
                    <button className="close-button" onClick={ props.onCloseClick }> <img alt="bezárás gomb" src="/image/close.svg" /> </button>
                </div>

                <div className="container">

                    <div className=" ">
                        <ul id={"questionnaire-tag-table"} className="questionnaire-tag-list">
                            { questionnaireTagElements.length != 0 ? questionnaireTagElements : <span className="label-text empty-text">Nincs megjeleníthető tag.</span> }
                        </ul>

                        <div className="new-category-button-div">
                            <button className="button " onClick={ onNewTagButtonClick }> Új tag hozzáadása +</button>
                        </div>
                    </div>

                    <div className="separator"/>

                    <div className="card-div" >

                        <form action="" className="editor-div" ref={ formElement }>

                            <h2 className="title-text-h2 title-margin">Tag módosítása</h2>

                            <div className="name-div">
                                <h3 className="title-text-h3 text-right-margin">Tag neve:</h3>
                                <div className="text-section-container">
                                    <TextField maxlength={ 255 } field={ form.tag } onChange={ onNameChange } placeholder="" type={"small"} />
                                </div>
                            </div>

                            <div className="new-category-button-div edit-button-top-margin">
                                <button className="button" type="submit"  onClick={ onSaveButtonClick } > {tagId == null ? "Mentés" : "Módosítások mentése"}</button>
                            </div>

                        </form>

                    </div>

                </div>

                {
                    questionnairesNotEmpty ? 

                    <MessageModal title={ "Figyelmeztetés" } message={ "A választott tag nem lehet törölni, mert kérdőívhez van rendelve. Először állítsd át a kérdőív tag-et, amit törölni szeretnél." } onCloseClick={ () => { dispatch( { type: GET_QUESTIONNAIRES_ACTIONS.GET_QUESTIONNAIRE_TAG_QUESTIONNAIRE_NOT_EMPTY_CLEAR } ) } }/>

                    : ""
                }

            </div>

        </Modal>
    )
}
