import { Expose, Type } from 'class-transformer'
import { IsDefined, ValidateNested } from 'class-validator'
import { UserActivity } from '../../entity'

export class GetUserActivitiesDTO {

    @Expose()
    @IsDefined()
    @ValidateNested()
    @Type( type => UserActivity )
    activities!: UserActivity[]

}
