import { LoaderFunctionArgs } from 'react-router-dom'
import { listCourses, listCourseTopics } from '../../action/courses'
import {store} from "../../store";

export const coursesListLoader = async ( args: LoaderFunctionArgs ) => {

    await listCourseTopics()
    await listCourses()
    store.dispatch( { type: `SET_COURSE_KEYWORD`, keyword: `` } )

}
