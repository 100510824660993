import { store } from '../../store'

export const deleteBannedWord = async ( id: number ): Promise<boolean> => {

    let response: Response
    const token = store.getState().session.token

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/2.0.0/cms/banned-words/${ id }`, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            method: 'delete'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'DELETE_BANNED_WORD_TIMEOUT' } )
        return false

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'DELETE_BANNED_WORD_UNAUTHORIZED' } )
        return false

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'DELETE_BANNED_WORD_UNEXPECTEDRESPONSE' } )
        return false

    }

    store.dispatch( { type: 'DELETE_BANNED_WORD_OK' } )
    return true

}
