import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetNewsStatsDTO } from '../../dto'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { GET_NEWS_STAT_ACTIONS } from './news.constants'

export const getNewsStats = async () => {

    const promise = createCall('news/stats', BASE_METHODS.GET)
    promise.then(async response => {
        let body: GetNewsStatsDTO

        try {
            body = plainToClass( GetNewsStatsDTO, JSON.parse( await response.text() ) )
        } catch ( exception ) {
            store.dispatch( { type: GET_NEWS_STAT_ACTIONS.GET_NEWS_STATS_INVALID_JSON } )
            return
        }
    
        if ( ( await validate( body ) ).length > 0 ) {
            store.dispatch( { type: GET_NEWS_STAT_ACTIONS.GET_NEWS_STATS_INVALID_RESPONSE } )
            return
        }
    
        store.dispatch( { type: GET_NEWS_STAT_ACTIONS.GET_NEWS_STATS_OK, 
            highlightedNews: body.highlightedNews,
            highlightedNewsStats: body.highlightedNewsStats,
            mostReadedNews: body.mostReadedNews, 
            lastReadedNews: body.lastReadedNews, 
            newsCount: body.newsCount, 
            userCount: body.userCount 
        } )
    
    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })

}
