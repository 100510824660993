import { Expose } from 'class-transformer'
import { Quote } from '../../entity'

export class GetThreeScheduledQuotesDTO {

    @Expose()
    scheduledQuotes!: [

        date: number,
        quote: Quote

    ]


}
