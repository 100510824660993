import { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PoliticianBiographyItem } from '../../../entity'
import { State } from '../../../state'
import { PoliticianBiographyElement } from './politician-biography-element'
import './politician-biography-table.scss'

interface PoliticianBiographyTableProps {

}

export const PoliticianBiographyTable = ( props: PoliticianBiographyTableProps ) => {
    
    const dispatch = useDispatch()

    const biographyItems = useSelector( ( state: State ) => state.createPolitician.form.biographyItems )

    const onAddBiographyItem = async ( event: MouseEvent ) => {
        event.preventDefault()
        dispatch( { type: 'CREATE_POLITICIAN_ADD_BIOGRAPHY_ITEM' } )
    }

    const biogrphyElements: JSX.Element[] = []
    if ( biographyItems !== null && biographyItems !== undefined ) {
        biographyItems.map(( biogrphyElement: PoliticianBiographyItem  ) => {
            biogrphyElements.push( <PoliticianBiographyElement biographyItemElement={ biogrphyElement }  /> )
        })
    }
    return (
        <div >
            <div className="">
                <ul id={"biography_table"} className="politician-biography-list">
                    { biogrphyElements.length != 0 ? biogrphyElements : "" }
                </ul>
            </div>

            <div className="right-gravity-div">
                <button className="button" onClick={ onAddBiographyItem } >+ Új életesemény</button>
            </div>
        </div>
    )

}
