import { store } from '../../store'

export const addExperience = async ( id: number, experience: number ): Promise<boolean> => {

    let response: Response
    const token = store.getState().session.token

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/2.0.0/cms/users/${ id }/experience`, {
            body: JSON.stringify( {
                experience: experience
            } ),
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            method: 'post'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'ADD_EXPERIENCE_TIMEOUT' } )
        return false

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'ADD_EXPERIENCE_UNAUTHORIZED' } )
        return false

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'ADD_EXPERIENCE_UNEXPECTEDRESPONSE' } )
        return false

    }

    store.dispatch( { type: 'ADD_EXPERIENCE_OK' } )
    return true

}
