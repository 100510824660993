import './prizes.page.scss'
import { PrizeCard } from '../../../../component/prize-card'
import { useSelector } from 'react-redux'
import { State } from '../../../../state'
import { useState } from 'react'
import { MediaLibrary } from '../../../../component/media-library'
import { MediaLibraryFile } from '../../../../entity'
import { ImageEditor } from '../../../../component/image-editor/image-editor'
import {ConsolationPrizeCard} from "../../../../component/consolation-prize-card";

export const PrizesPage = () => {

    const { league, prizes, consolationPrizes } = useSelector( ( state: State ) => state.gamification )

    const [ showMediaLibrary, setShowMediaLibrary ] = useState( false )
    const [ showImageEditor, setShowImageEditor ] = useState( false )
    const [ selectedLeagueId, setSelectedLeagueId ] = useState( null as null | number )
    const [ selectedPosition, setSelectedPosition ] = useState( null as null | number )
    const [ file, setFile ] = useState( null as null | MediaLibraryFile )

    const addImage = ( leagueId: number, position: number ) => {

        setSelectedLeagueId( leagueId )
        setSelectedPosition( position )
        setShowMediaLibrary( true )

    }
    const deleteImage = ( leagueId: number, position: number ) => {

        const prize = prizes.find( prize => prize.position === position && prize.league.id === leagueId )
        if ( prize !== undefined ) { prize.image = null }

    }

    return (

        <div className="prizes-page">

            { typeof league?.id === `number` && league.id !== 1 ? (

                <>

                    <PrizeCard prize={ prizes.find( p => p.league.id === league!.id && p.position === 0 ) } leagueId={ league!.id } position={ 0 } onAddImage={ () => addImage( league!.id, 0 ) } onDeleteImage={ () => deleteImage( league!.id, 0 ) } />

                    <PrizeCard prize={ prizes.find( p => p.league.id === league!.id && p.position === 1 ) } leagueId={ league!.id } position={ 1 } onAddImage={ () => addImage( league!.id, 1 ) } onDeleteImage={ () => deleteImage( league!.id, 1 ) } />

                    <PrizeCard prize={ prizes.find( p => p.league.id === league!.id && p.position === 2 ) } leagueId={ league!.id } position={ 2 } onAddImage={ () => addImage( league!.id, 2 ) } onDeleteImage={ () => deleteImage( league!.id, 2 ) } />

                    <PrizeCard prize={ prizes.find( p => p.league.id === league!.id && p.position === 3 ) } leagueId={ league!.id } position={ 3 } onAddImage={ () => addImage( league!.id, 3 ) } onDeleteImage={ () => deleteImage( league!.id, 3 ) } />

                    <ConsolationPrizeCard exp={ consolationPrizes.find( cP => cP.leagueId === league!.id )?.exp || 0 } leagueId={ league!.id } />

                </>

            ) : null }

            { showMediaLibrary ? (

                <MediaLibrary onClose={ () => setShowMediaLibrary( false ) } onMediaSelected={ ( file: MediaLibraryFile ) => {
                    setFile( file )
                    setShowMediaLibrary( false )
                    setShowImageEditor( true )
                } } />

            ) : null }

            { showImageEditor ? (

                <ImageEditor aspectRatio={ { name: `1:1`, ratio: 1 / 1 } } mediaLibraryFile={ file! } onClose={ () => setShowImageEditor( false ) } onReady={ file => {
                    const prize = prizes.find( prize => prize.position === selectedPosition && prize.league.id === selectedLeagueId )
                    if ( prize !== undefined ) { prize.image = file }
                    setShowImageEditor( false )
                } } />

            ) : null }

        </div>

    )

}