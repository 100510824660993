
import { Expose, Type } from 'class-transformer'
import { IsDefined, IsNumber, IsOptional, ValidateNested } from 'class-validator'
import { News } from '../../entity'
import { GetNewsItemStatsDTO } from "./get-news-item-stats.dto"

export class GetNewsStatsDTO {

    @IsOptional()
    @ValidateNested()
    @Type( () => News )
    highlightedNews?: News | null

    @IsOptional()
    @ValidateNested()
    @Type( () => GetNewsItemStatsDTO )
    highlightedNewsStats?: GetNewsItemStatsDTO | null

    @IsOptional()
    @ValidateNested()
    @Type( () => News )
    mostReadedNews?: News | null

    @IsOptional()
    @ValidateNested()
    @Type( () => News )
    lastReadedNews?: News | null

    @Expose()
    @IsDefined()
    @IsNumber()
    newsCount!: number 

    @Expose()
    @IsDefined()
    @IsNumber()
    userCount!: number 

}

