
import { Expose, Type } from 'class-transformer'
import { IsDefined, IsNumber, IsOptional, ValidateNested } from 'class-validator'
import { News, Event, PushMessage } from '../../entity'

export class GetDashboardStatsDTO {

    @Expose()
    @IsDefined()
    @IsNumber()
    applicationLaunchedCount!: number 

    @Expose()
    @IsDefined()
    @IsNumber()
    applicationLaunchedCountPercent!: number 

    @Expose()
    @IsDefined()
    @IsNumber()
    userCount!: number 

    @Expose()
    @IsDefined()
    @IsNumber()
    userCountPercent!: number 

    @Expose()
    @ValidateNested()
    @Type( () => Event )
    lastEvents!: Event[]

    @Expose()
    @ValidateNested()
    @Type( () => News )
    lastNews!: News[]
    
    @Expose()
    @ValidateNested()
    @Type( () => PushMessage )
    lastPushMessages!: PushMessage[]

}

