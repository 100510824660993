import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetQuoteDTO } from '../../dto/quotes'
import { store } from '../../store'

export const getQuote = async ( id: number ) => {

    let body, response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/quotes/${ id }`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'get'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'GET_QUOTE_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'GET_QUOTE_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'GET_QUOTE_UNEXPECTEDRESPONSE' } )
        return

    }

    try {

        body = plainToClass( GetQuoteDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'GET_QUOTE_INVALIDJSON' } )
        return

    }

    if ( ( await validate( body ) ).length > 0 ) {

        store.dispatch( { type: 'GET_QUOTE_INVALIDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'GET_QUOTE_OK', quote: body.quote } )

}
