import { Expose } from 'class-transformer'
import { Season } from '../../entity'

export class ListSeasonsDTO {

    @Expose()
    active!: Season

    @Expose()
    previous!: Season[]

    @Expose()
    next!: Season[]

}
