import './tester.page.scss'
import { useState } from 'react'

export const TesterPage = () => {

    const [ accessToken, setAccessToken ] = useState( `` )

    const [ activity, setActivity ] = useState( 19 )
    const [ id, setId ] = useState( 1 )

    const [ round, setRound ] = useState( null as any )

    const getRound = async () => {

        const response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/rounds`, {
            headers: {
                Authorization: `Bearer ${ accessToken }`
            }
        } )

        if ( response.status === 200 ) {

            setRound( await response.json() )

        }

    }

    const claimQuest = async ( id: number ) => {

        await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/quests/${ id }`, {
            headers: {
                Authorization: `Bearer ${ accessToken }`
            },
            method: `POST`
        } )

        await getRound()

    }

    const claimQuestGroup = async ( id: number ) => {

        await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/quest-groups/${ id }`, {
            headers: {
                Authorization: `Bearer ${ accessToken }`
            },
            method: `POST`
        } )

        await getRound()

    }

    const sendUserActivity = async () => {

        let additionalData

        switch ( activity ) {

            case 19:
                additionalData = { moduleId: id }
                break
            case 18:
                additionalData = { externalLink: id }
                break
            case 11:
                additionalData = { questionnaireId: id }
                break
            case 13:
                additionalData = { eventId: id }
                break
            case 3:
                additionalData = { newsId: id }
                break
            case 8:
                additionalData = { politicianId: id }
                break
            default:
                additionalData = {}
                break

        }

        await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/users/activity`, {
            body: JSON.stringify( {
                activities: [
                    {
                        activityDate: Math.round( new Date().getTime() / 1000 ),
                        activityType: activity,
                        additionalData: additionalData
                    }
                ]
            } ),
            headers: {
                Authorization: `Bearer ${ accessToken }`,
                'Content-Type': `application/json`
            },
            method: `POST`
        } )

        await getRound()

    }

    const start = async () => {

        await getRound()

    }

    return (

        <main className="page page--tester">

            <div className="main-div">

                <div className="navigation-title">

                    <h1 className="title-text-h1">Tester</h1>

                </div>

                <div style={{ alignItems: `center`, display: `flex` }}>

                    <span style={{ marginRight: 20 }}>Access token:</span>
                    <input style={{ width: 500, marginRight: 20 }} type="text" value={ accessToken } onChange={ e => setAccessToken( e.target.value ) } />
                    <button onClick={ start }>Start</button>

                </div>

                <div style={{ marginTop: 30 }}>

                    User activity:
                    <div style={{ alignItems: `center`, display: `flex` }}>

                        <select style={{ marginRight: 20 }} value={ activity } onChange={ e => setActivity( parseInt( e.target.value ) ) }>
                            <option value={ 19 }>Modul megnyitás</option>
                            <option value={ 18 }>Külső link</option>
                            <option value={ 11 }>Kérdőív kitöltés</option>
                            <option value={ 13 }>Eseményen ottlevés</option>
                            <option value={ 3 }>Hír elolvasása</option>
                            <option value={ 8 }>Politikus oldal elolvasása</option>
                            <option value={ 17 }>Véleményvezér</option>
                        </select>

                        <input style={{ marginRight: 20 }} type="number" value={ id } onChange={ e => setId( parseInt( e.target.value ) ) } />

                        <button onClick={ sendUserActivity }>Küldés</button>

                    </div>

                </div>


                { round && round.anteRoom ? (

                    <div style={{ marginTop: 30 }}>

                        <h1>Előszoba</h1>

                        <div style={{ marginBottom: 15 }}>Current xp: { round.anteRoom.currentExperience }</div>
                        <div style={{ marginBottom: 15 }}>Required xp: { round.anteRoom.requiredExperience }</div>
                        <div style={{ marginBottom: 15 }}>Percentage: { round.anteRoom.percentage }</div>
                        <div style={{ marginBottom: 15 }}>
                            Quests:
                            { round.anteRoom.quests.map( ( quest: any ) => (

                                <div className="quest">

                                    <div className="quest__type">{ quest.questType }</div>
                                    <div className="quest__name">{ quest.name }</div>
                                    <div className="quest__exp">{ quest.experience }XP</div>
                                    <div className="quest__percentage">{ quest.percentage }%</div>
                                    <div className="quest__claimed">{ quest.claimed ? `Be van váltva` : `Nincs beváltva` }</div>
                                    <div className="quest__claim"><button disabled={ quest.percentage !== 100 || quest.claimed } onClick={ () => claimQuest( quest.id ) }>Claim</button></div>
                                    <div className="quest__contentId">{ quest.contentId }</div>

                                </div>

                            ) ) }
                        </div>
                        <div>
                            Quest groups:
                            { round.anteRoom.questGroups.map( ( questGroup: any ) => (

                                <>

                                    <div className="questGroup">

                                        <div className="questGroup__name">{ questGroup.name }</div>
                                        <div className="questGroup__exp">{ questGroup.experience }XP</div>
                                        <div className="questGroup__percentage">{ questGroup.percentage }%</div>
                                        <div className="questGroup__claimed">{ questGroup.claimed ? `Be van váltva` : `Nincs beváltva` }</div>
                                        <div className="questGroup__claim"><button disabled={ questGroup.percentage !== 100 || questGroup.claimed } onClick={ () => claimQuestGroup( questGroup.id ) }>Claim</button></div>
                                    </div>
                                    <div className="questGroup-quests">
                                        { questGroup.quests.map( ( quest: any ) => (
                                            <div className="quest">

                                                <div className="quest__type">{ quest.questType }</div>
                                                <div className="quest__name">{ quest.name }</div>
                                                <div className="quest__percentage">{ quest.percentage }%</div>
                                                <div className="quest__contentId">{ quest.contentId }</div>

                                            </div>
                                        ) ) }
                                    </div>

                                </>

                            ) ) }
                        </div>

                    </div>

                ) : null }


                { round && round.currentRound ? (

                    <div style={{ marginTop: 30 }}>

                        <h1>Round</h1>

                        <div style={{ marginBottom: 15 }}>Current xp: { round.currentRound.currentExperience }</div>
                        <div style={{ marginBottom: 15 }}>Current league: { round.currentRound.league.name }</div>
                        <div style={{ marginBottom: 15 }}>Advancing position: { round.currentRound.isInAdvancingPosition ? `Igen` : `Nem` }</div>
                        <div style={{ marginBottom: 15 }}>Percentage: { round.currentRound.percentage }</div>
                        <div style={{ marginBottom: 15 }}>
                            Quests:
                            { round.currentRound.quests.map( ( quest: any ) => (

                                <div className="quest">

                                    <div className="quest__type">{ quest.questType }</div>
                                    <div className="quest__name">{ quest.name }</div>
                                    <div className="quest__exp">{ quest.experience }XP</div>
                                    <div className="quest__percentage">{ quest.percentage }%</div>
                                    <div className="quest__claimed">{ quest.claimed ? `Be van váltva` : `Nincs beváltva` }</div>
                                    <div className="quest__claim"><button disabled={ quest.percentage !== 100 || quest.claimed } onClick={ () => claimQuest( quest.id ) }>Claim</button></div>
                                    <div className="quest__contentId">{ quest.contentId }</div>

                                </div>

                            ) ) }
                        </div>
                        <div>
                            Quest groups:
                            { round.currentRound.questGroups.map( ( questGroup: any ) => (

                                <>

                                    <div className="questGroup">

                                        <div className="questGroup__name">{ questGroup.name }</div>
                                        <div className="questGroup__exp">{ questGroup.experience }XP</div>
                                        <div className="questGroup__percentage">{ questGroup.percentage }%</div>
                                        <div className="questGroup__claimed">{ questGroup.claimed ? `Be van váltva` : `Nincs beváltva` }</div>
                                        <div className="questGroup__claim"><button disabled={ questGroup.percentage !== 100 || questGroup.claimed } onClick={ () => claimQuestGroup( questGroup.id ) }>Claim</button></div>
                                    </div>
                                    <div className="questGroup-quests">
                                        { questGroup.quests.map( ( quest: any ) => (
                                            <div className="quest">

                                                <div className="quest__type">{ quest.questType }</div>
                                                <div className="quest__name">{ quest.name }</div>
                                                <div className="quest__percentage">{ quest.percentage }%</div>
                                                <div className="quest__contentId">{ quest.contentId }</div>

                                            </div>
                                        ) ) }
                                    </div>

                                </>

                            ) ) }
                        </div>

                    </div>

                ) : null }

                { round && round.nextRoundStart ? (

                    <div style={{ marginTop: 30 }}>

                        <h1>Next round:</h1>
                        <div>{ new Date( round.nextRoundStart * 1000 ).toISOString() }</div>

                    </div>

                ) : null }


            </div>

        </main>

    )

}