import { getDefaultReactions, listQuoteParties, listQuotePoliticians } from '../../action/quotes'
import { listTags } from '../../action/tags'

export const newQuoteLoader = () => {

    getDefaultReactions()
    listTags()
    listQuoteParties()
    listQuotePoliticians()

}
