import { Expose } from 'class-transformer'
import { Quote } from '../../entity'

export class ListQuotesDTO {

    @Expose()
    quotes!: Quote[]

    @Expose()
    pagination!: {
        page: number
        from: number
        to: number
        count: number
    }

}
