import { Vote } from "../../entity"
import { Expose } from 'class-transformer'
import { IsDefined } from "class-validator"

export class ListVotesDTO
{

    @Expose()
    votes!: Vote[]

    @Expose()
    pagination!: {
        page: number
        from: number
        to: number
        count: number
    }

}