import { useDispatch, useSelector } from 'react-redux'
import { deleteEvent, getEvents } from '../../../action/events'
import { createFormattedDateTimeString } from '../../../helper'
import { State } from '../../../state'
import { Table } from '../../table'
import { EventsTableMenuButton } from '../events-table-menu-button'
import { useNavigate } from "react-router-dom"
import { EventModal } from "../../event-modal";
import { useState } from "react";
import { Event } from '../../../entity'
import { Modal } from '../../modal'
import './events-table.scss'

export const EventsTable = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { events, orderBy, orderDirection } = useSelector( ( state: State ) => state.events )
    const { page, from, to, count } = useSelector( ( state: State ) => state.events.pagination )
    const [ event, setEvent ] = useState( null as Event | null )
    const [ showPreview, setShowPreview ] = useState( false )
    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )
    const [ showConfirmModal, setShowConfirmModal ] = useState( false )

    const onPageChange = ( page: number ) => {

        dispatch( { type: `EVENTS_CHANGE_PAGE`, page: page } )
        getEvents()

    }

    const onOrderChange = ( column: string ) => {

        let direction = `ASC`

        if ( orderBy === column ) {

            if ( orderDirection === `ASC` ) {

                direction = `DESC`

            } else if ( orderDirection === `DESC` ) {

                column = ``
                direction = ``

            }

        }

        dispatch( { type: `EVENTS_CHANGE_ORDER`, orderBy: column, orderDirection: direction } )
        getEvents()

    }

    const onDelete = async () => {

        if ( deleteFunc !== null ) {

            await deleteFunc()

        }
        setShowConfirmModal( false )

    }

    const onMenuButtonClick = ( id: number, eventId: number ) => {

        switch ( id ) {
            case 0:
                navigate( `/esemenyek/${ eventId }` )
                break
            case 1:
                let ev = null
                events.forEach( e => {
                    if ( e.id === eventId ) {
                        ev = e
                    }
                } )
                setEvent( ev )
                setShowPreview( true )
                break
            case 2:
                setDeleteFunc( () => {
                    return async () => {
                        await deleteEvent( eventId )
                        await getEvents()
                    }
                } )
                setShowConfirmModal( true )
                break
        }

    }

    const columns = [
        {
            align: `center`,
            caption: `ID`,
            key: `id`,
            name: `id`,
            width: 100
        },
        {
            caption: ``,
            key: `title`,
            name: `title`,
            width: 452
        },
        {
            align: `center`,
            caption: `Típus`,
            key: `type`,
            name: `type`,
            width: 150,
            render: ( data: any ) => {
                switch ( data.visibility ) {
                    case 0:
                        return `Nyílt`
                    case 1:
                        return `Belső`
                    default:
                        return `Zárt`
                }
            }
        },
        {
            align: `center`,
            caption: `Esemény kezdete`,
            render: ( data: any ) => createFormattedDateTimeString( data.startDate ),
            name: `startDate`,
            width: 200
        },
        {
            align: `center`,
            caption: `Esemény vége`,
            render: ( data: any ) => data.endDate === null ? `` : createFormattedDateTimeString( data.endDate ),
            name: `endDate`,
            width: 200
        },
        {
            align: `center`,
            caption: ``,
            name: `actions`,
            render: ( data: any ) => <EventsTableMenuButton event={ data } onMenuButtonClick={ onMenuButtonClick } />,
            width: 150
        }
    ]

    return (

        <>

            <Table
                columns={ columns }
                dataset={ events }
                order={ { by: orderBy, direction: orderDirection } }
                onOrderChange={ onOrderChange }
                pagination={ { page: page, from: from, to: to, count: count, onPageChange: onPageChange } }
                onRowClick={ row => navigate( `/esemenyek/${ row.id }` ) } />

            { showPreview ? <EventModal event={ event! } onCloseClick={ () => setShowPreview( false ) } onModalClose={ () => setShowPreview( false ) } /> : null }

            { showConfirmModal ? (

                <Modal className="event-table-confirm-modal" onModalClose={ () => setShowConfirmModal( false ) }>

                    <div className="modal-title">Biztosan törlöd az eseményt?</div>
                    <div className="modal-description">Ezt a műveletet nem lehet visszavonni! Biztosan törlöd?</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowConfirmModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ onDelete }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

        </>
    )

}
