import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetNewsDTO } from '../../dto'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { getNewsStats } from './get-news-stats.action'
import { CREATE_NEWS_ACTIONS } from './news.constants'

export const createNews = async () => {

    store.dispatch( { type: CREATE_NEWS_ACTIONS.CREATE_NEWS_STARTED } )

    const newsId = store.getState().createNews.form.id

    var questionJson = undefined

    if(store.getState().createNews.form.question.value != ''){
        var answers = []

        if(store.getState().createNews.form.question.answer1.value != ''){
            answers.push( {
                id: store.getState().createNews.form.question.answer1id,
                answer: store.getState().createNews.form.question.answer1.value,
                isSolution: store.getState().createNews.form.question.correctAnswerId == 1
            } )
        }
        if(store.getState().createNews.form.question.answer2.value != ''){
            answers.push( {
                id: store.getState().createNews.form.question.answer2id,
                answer: store.getState().createNews.form.question.answer2.value,
                isSolution: store.getState().createNews.form.question.correctAnswerId == 2
            } )
        }

        if(store.getState().createNews.form.question.answer3.value != ''){
            answers.push( {
                id: store.getState().createNews.form.question.answer3id,
                answer: store.getState().createNews.form.question.answer3.value,
                isSolution: store.getState().createNews.form.question.correctAnswerId == 3
            } )
        }

        if(store.getState().createNews.form.question.answer4.value != ''){
            answers.push( {
                id: store.getState().createNews.form.question.answer4id,
                answer: store.getState().createNews.form.question.answer4.value,
                isSolution: store.getState().createNews.form.question.correctAnswerId == 4
            } )
        }

        questionJson = {
            question: store.getState().createNews.form.question.value,
            answers: answers
        }
    }

    let politicianIds: number[] = []

    if(store.getState().createNews.form.relatedPoliticians && store.getState().createNews.form.relatedPoliticians.length != 0){
        store.getState().createNews.form.relatedPoliticians.map(item => (
            politicianIds.push(item.id)
        ))
    }


    const body = JSON.stringify( {
        statementBy: store.getState().createNews.form.statementBy.value == '' ? null : store.getState().createNews.form.statementBy.value ,
        title: store.getState().createNews.form.title.value,
        lead: store.getState().createNews.form.lead.value,
        description: store.getState().createNews.form.description.value,
        highlighted: store.getState().createNews.form.highlighted == 0 ? false : true,
        newsCategoryId: store.getState().createNews.form.category,
        visibilityId: store.getState().createNews.form.visibility,
        imageVisibilityInList: store.getState().createNews.form.imageVisibilityInList == 0 ? true : false,
        statusId: store.getState().createNews.form.status ,
        visibleFrom: new Date(store.getState().createNews.form.visibleFrom).getTime() / 1000,
        visibleTo: store.getState().createNews.form.visibleTo != null ? ( new Date(store.getState().createNews.form.visibleTo!).getTime() / 1000 ) : null,
        sendPush: store.getState().createNews.form.sendPush == 1 ? false : true,
        question: questionJson,
        relatedPoliticianIds: politicianIds ,
        tags: store.getState().createNews.form.tags,
        mediaLibraryFileId: store.getState().createNews.form.mediaLibraryFile?.id ?? null,
        experience: parseInt( store.getState().createNews.form.experience.value ),
        quizExperience: parseInt( store.getState().createNews.form.quizExperience.value )
    } )

    const promise = createCall( `cms/news`+ (newsId != null ? (`/`+newsId) : ''), newsId != null ?  BASE_METHODS.PUT :  BASE_METHODS.POST, body, `2.0.0` )
    promise.then(async response => {
        let responseBody: GetNewsDTO

        try {
            responseBody = plainToClass( GetNewsDTO, JSON.parse( await response.text() ) )
        } catch ( exception ) {
            store.dispatch( { type: CREATE_NEWS_ACTIONS.CREATE_NEWS_INVALID_JSON } )
            return
        }

        if ( ( await validate( responseBody ) ).length > 0 ) {
            store.dispatch( { type: CREATE_NEWS_ACTIONS.CREATE_NEWS_INVALID_RESPONSE } )
            return
        }

        store.dispatch( { type: CREATE_NEWS_ACTIONS.CREATE_NEWS_OK, news: responseBody.news } )

        getNewsStats()

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })

}
