import { ChangeEvent, KeyboardEventHandler } from 'react'
import '../text/text.field.scss'

interface NumberFieldProps {
    field: {
        disabled?: boolean
        error: string
        name?: string
        required?: boolean
        value: string 
    }
    onChange: ( event: ChangeEvent<HTMLInputElement> ) => void
    placeholder?: string
    type?: 'normal' | 'small'
    category?: string
    maxlength?: number
    max?: number
    min?: number
    disabled?: boolean
}

export const NumberField = ( props: NumberFieldProps ) => {


    return (
        <div className={ `field${ props.field?.error.length > 0 ? ` field--error` : `` }${ props.type === 'small' ? ` field--small` : `` } field--text` } >
            <input
                className={ props.category }
                onChange={ props.onChange }
                disabled={ props.disabled == true || props.field?.disabled === true }
                id={ props.field?.name }
                name={ props.field?.name }
                placeholder={ props.placeholder }
                required={ props.field?.required === true }
                type='number'
                max={ props.max }
                min={ props.min }
                maxLength={ props.maxlength }
                value={ props.field?.value } />
        </div>
    )
}
