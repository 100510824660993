import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetPoliticianTitlesDTO } from '../../dto/politician'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { POLITICIANS_ACTIONS } from './politicians.constants'

export const getPoliticianTitles = async () => {

    const promise = createCall(`politicians/titles`, BASE_METHODS.GET)
    promise.then(async response => {

        let body: GetPoliticianTitlesDTO

        try {
            body = plainToClass( GetPoliticianTitlesDTO, JSON.parse( await response.text() ) )
        } catch ( exception ) {
            store.dispatch( { type: POLITICIANS_ACTIONS.GET_POLITICIAN_TITLES_INVALID_JSON } )
            return
        }
    
        if ( ( await validate( body ) ).length > 0 ) {
            store.dispatch( { type: POLITICIANS_ACTIONS.GET_POLITICIAN_TITLES_INVALID_RESPONSE } )
            return
        }
    
        store.dispatch( { type: POLITICIANS_ACTIONS.GET_POLITICIAN_TITLES_OK, politicianTitles: body.politicianTitles } )

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })

}