import './politician-stats.tab.scss'
import { useSelector } from 'react-redux'
import { State } from '../../../state'

interface PoliticainStatsTabProps { }

export const PoliticainStatsTab = ( props: PoliticainStatsTabProps ) => {

    const { openCount, openPercent, sendQuestionCount } = useSelector( ( state: State ) => state.createPolitician.stats )

    return (
        <div className="card-div ">
     
            <div className="container column">

                <div className="caption">
                    Az poltikushoz tartozó statisztikai adatok itt jelennek meg:
                </div>

                <div className="container stats-container">
                    <div className="card-div card-div-right-margin">

                        <div className="info-slot">
                            <div className="info-slot__description">
                                <span className="caption"> Hány felhasználó nyitotta meg a politikust </span>
                            </div>

                            <div className="info-slot__info">
                                <span className="value">{ openCount }</span> { "db" }
                            </div>
                        </div>

                    </div>

                    <div className="card-div card-div-right-margin">

                        <div className="info-slot">
                            <div className="info-slot__description">
                                <span className="caption"> Hány felhasználó töltöte ki a politikushoz tartozó kvízt </span>
                            </div>

                            <div className="info-slot__info">
                                <span className="value">{ sendQuestionCount }</span> { "db" }
                            </div>
                        </div>

                    </div>

                    <div className="card-div ">

                        <div className="info-slot">
                            <div className="info-slot__description">
                                <span className="caption"> Az összes felhasználónak hány %-a nyitotta meg a politikust </span>
                            </div>

                            <div className="info-slot__info">
                                <span className="value">{ (openPercent *100).toFixed(0) }</span> { "%" }
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}


