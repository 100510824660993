import { ChangeEvent, createRef, MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'
import { login } from '../../action'
import { LoadingIcon } from '../../component/icon'
import { EmailField, PasswordField } from '../../component/field'
import { MessageModal } from '../../component/modal'
import { State } from '../../state'
import './login.page.scss'
import { title } from '../../helper'

export const LoginPage = () => {

    const authenticatedUser = useSelector( ( state: State ) => state.session.authenticatedUser )
    const dispatch = useDispatch()
    const { alertMessage, form, loginStarted } = useSelector( ( state: State ) => state.login )
    const setAlertMessage = ( message: string | null ) => dispatch( { type: `SET_ALERT_MESSAGE`, message: message } )
    const formElement = createRef<HTMLFormElement>()

    if ( authenticatedUser === undefined ) {
        return null
    }
    if ( authenticatedUser !== null ) {
        const url = new URL( window.location.href ).searchParams.get( 'redirect' ) ?? '/'
        return <Navigate to={ url } />
    }

    const onLoginButtonClick = async ( event: MouseEvent ) => {
        event.preventDefault()
        if ( !formElement.current?.reportValidity() ) { return }
        await login()
    }

    const onEmailChange = ( event: ChangeEvent<HTMLInputElement> ) => {
        event.preventDefault()
        dispatch( { type: 'LOGIN_CHANGE_EMAIL', email: event.target.value } )
    }

    const onPasswordChange = ( event: ChangeEvent<HTMLInputElement> ) => {
        event.preventDefault()
        dispatch( { type: 'LOGIN_CHANGE_PASSWORD', password: event.target.value } )
    }


    return (

        <main className="page page--login">

            <form action="" className="login-form" ref={ formElement }>
                <img alt="A Demokratikus Koalíció logója" className="login-form__logo" src="/image/dk-logo.svg" />
                <h1>{ title() }</h1>
                <EmailField field={ form.email } onChange={ onEmailChange } placeholder="e-mail cím" />
                <PasswordField field={ form.password } onChange={ onPasswordChange } placeholder="jelszó" />
                <div className="login-form__links">
                    <Link className={ `login-form__links__link${ form.email.error.length > 0 ? ` login-form__links__link--error` : `` }` } to="/elfelejtettem-a-jelszavam">
                        { form.email.error.length > 0 ? `Helytelen e-mail cím vagy jelszó! Igényelj új jelszót!` : `Elfelejtetted a jelszavad?` }
                    </Link>
                </div>
                <footer>
                    <button className="button" disabled={ loginStarted !== null } onClick={ onLoginButtonClick } type="submit">
                        { loginStarted !== null ? <LoadingIcon /> : `Belépés` }
                    </button>
                </footer>
            </form>

            { alertMessage !== null ? (

                <MessageModal title="Váratlan hiba történt" message={ alertMessage } onCloseClick={ () => setAlertMessage( null ) } />

            ) : null }

        </main>

    )

}
