interface TabViewPageProps {

    children: JSX.Element | JSX.Element[]
    key: string

}

export const TabViewPage = ( props: TabViewPageProps ) => {

    return (
        <div className="tab-view-page">

            { props.children }

        </div>
    )

}
