import { store } from "../../store"
import { createCall } from "../base"
import { BASE_METHODS } from "../base/base.constants"
import { getQuestionnairesStats } from "./get-questionnaires-stats.action"
import { getQuestionnaires } from "./get-questionnaires.action"
import { DELETE_QUESTIONNAIRE_ACTIONS } from "./questionnaires.constants"

export const deleteQuestionnaire = async (questionnaireId: number) => {

    store.dispatch( { type: DELETE_QUESTIONNAIRE_ACTIONS.DELETE_QUESTIONNAIRE_STARTED } )

    const promise = createCall(`questionnaires/`+questionnaireId, BASE_METHODS.DELETE)
    promise.then(async response => {

        store.dispatch( { type: DELETE_QUESTIONNAIRE_ACTIONS.DELETE_QUESTIONNAIRE_OK, response: response.text() } )

        getQuestionnaires()

        getQuestionnairesStats()

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })

}
