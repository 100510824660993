import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { GetPoliticianDTO } from "../../dto/politician";
import { store } from "../../store";
import { getPoliticians } from "./get-politicians.action";

export const createPolitician = async () => {
  let body: GetPoliticianDTO;
  let response: Response;

  const token = store.getState().session.token;
  const politicianId = store.getState().createPolitician.form.id;

  store.dispatch({ type: "CREATE_POLITICIAN_STARTED" });

  let phrases = undefined;

  const phrasesArray = store.getState().createPolitician.form.phrases;
  if (phrasesArray != null) {
    phrases = [];

    phrasesArray.map((item) =>
      phrases.push({
        id: item.isNew === true ? null : item.id,
        description: item.descriptionField.value,
      })
    );
  }

  let questions = undefined;

  const questionAnswerArray = store.getState().createPolitician.form.questions;
  if (questionAnswerArray != null) {
    questions = [];

    questionAnswerArray.map((item) =>
      questions.push({
        questionId: item.id,
        answer: item.value,
      })
    );
  }

  let biographyItems = undefined;

  const biographyItemArray =
    store.getState().createPolitician.form.biographyItems;
  if (biographyItemArray != null) {
    biographyItems = [];

    biographyItemArray.map((item) =>
      biographyItems.push({
        year: item.yearField.value,
        title: item.titleField.value,
        description: item.descriptionField.value,
      })
    );
  }

  var questionJson = {
    question: store.getState().createPolitician.form.question.value,
    answers: [
      {
        answer: store.getState().createPolitician.form.question.answer1.value,
        isSolution:
          store.getState().createPolitician.form.question.correctAnswerId == 1,
      },
      {
        answer: store.getState().createPolitician.form.question.answer2.value,
        isSolution:
          store.getState().createPolitician.form.question.correctAnswerId == 2,
      },
      {
        answer: store.getState().createPolitician.form.question.answer3.value,
        isSolution:
          store.getState().createPolitician.form.question.correctAnswerId == 3,
      },
      {
        answer: store.getState().createPolitician.form.question.answer4.value,
        isSolution:
          store.getState().createPolitician.form.question.correctAnswerId == 4,
      },
    ],
  };

//   if (store.getState().createPolitician.form.question.value != "") {
//     var answers = [];

//     if (store.getState().createPolitician.form.question.answer1.value != "") {
//       answers.push({
//         answer: store.getState().createPolitician.form.question.answer1.value,
//         isSolution:
//           store.getState().createPolitician.form.question.correctAnswerId == 1,
//       });
//     }
//     if (store.getState().createPolitician.form.question.answer2.value != "") {
//       answers.push({
//         answer: store.getState().createPolitician.form.question.answer2.value,
//         isSolution:
//           store.getState().createPolitician.form.question.correctAnswerId == 2,
//       });
//     }

//     if (store.getState().createPolitician.form.question.answer3.value != "") {
//       answers.push({
//         answer: store.getState().createPolitician.form.question.answer3.value,
//         isSolution:
//           store.getState().createPolitician.form.question.correctAnswerId == 3,
//       });
//     }

//     if (store.getState().createPolitician.form.question.answer4.value != "") {
//       answers.push({
//         answer: store.getState().createPolitician.form.question.answer4.value,
//         isSolution:
//           store.getState().createPolitician.form.question.correctAnswerId == 4,
//       });
//     }

//     questionJson = {
//       question: store.getState().createPolitician.form.question.value,
//       answers: answers,
//     };
//   }

  let titleIds: number[] = [];

  if (store.getState().createPolitician.form.titles.length != 0) {
    store
      .getState()
      .createPolitician.form.titles.map((item) => titleIds.push(item.id));
  }

  try {
    response = await fetch(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/politicians` +
        (politicianId != null ? `/` + politicianId : ""),
      {
        body: JSON.stringify({
            experience: parseInt( store.getState().createPolitician.form.experience.value ),
            quizExperience: parseInt( store.getState().createPolitician.form.quizExperience.value ),
          firstName: store.getState().createPolitician.form.firstName.value,
          lastName: store.getState().createPolitician.form.lastName.value,
          innerPosition:
            store.getState().createPolitician.form.innerPosition.value,
            isVisible: store.getState().createPolitician.form.isVisible,
          position: store.getState().createPolitician.form.position.value,
          shadowGovernmentPosition:
            store.getState().createPolitician.form.shadowGovernmentPosition
              .value,
          description: store.getState().createPolitician.form.description.value,
          phrases: phrases,
          question: questionJson,
          questions: questions,
          titleIds: titleIds,
          biographyItems: biographyItems,
          tags: store.getState().createPolitician.form.tags,
          mediaLibraryFileId:
            store.getState().createPolitician.form.mediaLibraryFile?.id ?? null,
        }),
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        method: politicianId != null ? "put" : "post",
      }
    );
  } catch (exception) {
    store.dispatch({ type: "CREATE_POLITICIAN_TIMEOUT" });
    return;
  }

  if (response.status === 401) {
    store.dispatch({ type: "CREATE_POLITICIAN_UNAUTHORIZED" });
    return;
  }

  if (![200, 201].includes(response.status)) {
    store.dispatch({ type: "CREATE_POLITICIAN_UNEXPECTEDRESPONSE" });
    return;
  }

  try {
    body = plainToClass(GetPoliticianDTO, JSON.parse(await response.text()));
  } catch (exception) {
    store.dispatch({ type: "CREATE_POLITICIAN_INVALIDJSON" });
    return;
  }

  if ((await validate(body)).length > 0) {
    store.dispatch({ type: "CREATE_POLITICIAN_INVALIDRESPONSE" });
    return;
  }

  store.dispatch({ type: "CREATE_POLITICIAN_OK", politician: body.politician });

  getPoliticians();
};
