import './test-results-modal.scss'
import { Modal } from '../modal'
import { TextField } from '../field'
import { useState } from 'react'
import { TextArea } from '../textarea'
import { TagSelector } from '../tag-selector'
import { AddImage } from '../add-image'
import { MediaLibraryFile, Tag, TestResult } from '../../entity'
import { MediaLibrary } from '../media-library'
import { ImageEditor } from '../image-editor/image-editor'
import { useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'

interface TestResultsModalProps {

    results: TestResult[]
    onClose?: () => void

}

export const TestResultsModal = ( props: TestResultsModalProps ) => {

    const dispatch = useDispatch()

    const onClose = () => { if ( props.onClose ) props.onClose()  }

    const [ selectedResult, setSelectedResult ] = useState( null as number | null )

    const [ name, setName ] = useState( `` )
    const [ description, setDescription ] = useState( `` )
    const [ tags, setTags ] = useState( [] as Tag[] )
    const [ image, setImage ] = useState( null as MediaLibraryFile | null )

    const [ showMediaLibrary, setShowMediaLibrary ] = useState( false )
    const [ showImageEditor, setShowImageEditor ] = useState( false )
    const [ file, setFile ] = useState( null as null | MediaLibraryFile )

    const onTagAdd = ( tag: Tag ) => {

        if ( tags.find( t => t.id === tag.id ) === undefined ) {

            setTags( [ ...tags, tag ] )

        }

    }

    const onTagDelete = ( tag: Tag ) => {

        setTags( tags.filter( t => t.id !== tag.id ) )

    }

    const create = () => {

        setName( `` )
        setDescription( `` )
        setImage( null )
        setTags( [] )
        setSelectedResult( null )

    }

    const select = ( result: TestResult ) => {

        setName( result.name )
        setDescription( result.description )
        setImage( result.image )
        setTags( result.tags )
        setSelectedResult( result.id )

    }

    const save = () => {

        dispatch( { type: `QUIZ_RESULT_SAVE`, result: {
            id: selectedResult,
            uuid: uuid(),
            name: name,
            description: description,
            tags: tags,
            image: image
        } } )
        create()

    }

    const deleteResult = () => {

        dispatch( { type: `QUIZ_RESULT_DELETE`, id: selectedResult } )
        create()

    }

    return (

        <Modal className="test-results-modal" onModalClose={ onClose }>

            <img alt="" className="modal-close" src="/image/close.svg" onClick={ onClose } />

            <div className="modal-header">

                <div className="modal-title">Teszt eredmények</div>

            </div>

            <div className="modal-body">

                <div className="results-list">

                    { props.results.map( result => (

                        <div className="test-result" onClick={ () => select( result ) }>

                            <span>{ result.name }</span>
                            <img alt="" src="/image/edit.svg" />

                        </div>

                    ) ) }
                    <button className="button button--primary" onClick={ create }>Új teszt eredmény hozzáadása +</button>

                </div>

                <div className="separator"></div>

                <div className="result-detail card-div">

                    <div className="result-detail--title">Teszt eredmény { selectedResult === null ? `hozzáadása` : `szerkesztése` }</div>

                    <div className="result-detail--form">

                        <div className="result-detail--field">

                            <label>Eredmény neve:</label>
                            <TextField field={{ value: name, error: `` }} onChange={ e => setName( e.target.value ) } />

                        </div>

                        <div className="result-detail--field">

                            <label>Eredmény leírása:</label>
                            <TextArea field={{ value: description, error: `` }} onChange={ e => setDescription( e.target.value ) } />

                        </div>

                        <div className="result-detail--field">

                            <label className="no-margin">Zárt címkék:</label>
                            <div className="tags">

                                <TagSelector compact={ true } tags={ tags } onTagAdd={ onTagAdd } onTagDelete={ onTagDelete } />

                            </div>

                        </div>

                        <div className="result-detail--field last">

                            <label className="no-margin">Eredmény képe:</label>
                            <AddImage imageUrl={ image ? image.url! : `` } text="Kép hozzáadása az eredményhez" width="250px" height="141px" onAddImage={ () => setShowMediaLibrary( true ) } onDeleteImage={ () => setImage( null ) } />

                        </div>

                        <div className="result-detail--footer">

                            { selectedResult !== null ? (

                                <button className="button button--warning button--ghost" onClick={ deleteResult }>Törlés</button>

                            ) : null }
                            <button className="button button--primary" onClick={ save } >Módosítások mentése</button>

                        </div>

                    </div>

                </div>

                { showMediaLibrary ? (

                    <MediaLibrary onClose={ () => setShowMediaLibrary( false ) } onMediaSelected={ ( file: MediaLibraryFile ) => {

                        setFile( file )
                        setShowMediaLibrary( false )
                        setShowImageEditor( true )

                    } } />

                ) : null }

                { showImageEditor ? (

                    <ImageEditor aspectRatio={{ name: `Eredmény képe`, ratio: 16 / 9 }} mediaLibraryFile={ file! } onClose={ () => setShowImageEditor( false ) } onReady={ ( file: MediaLibraryFile ) => {

                        setImage( file )
                        setShowImageEditor( false )

                    } } />

                ) : null }

            </div>

        </Modal>

    )

}