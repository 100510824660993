import { useDispatch } from 'react-redux'
import { deletePoliticianQuestion } from '../../../action/politician'
import { PoliticianPhrase, Quote } from '../../../entity'
import { MenuButton } from '../../button'
import './politician-phrase-element.scss'

interface PoliticianPhraseElementProps {
    politicianPhraseElement: Quote
}

export const PoliticianPhraseElement = ( props: PoliticianPhraseElementProps ) => {

    return (
        <li key={ props.politicianPhraseElement.id } className="politician-phrase-list__politician-phrase-element">
            <div className="phrase-container">
                <h3 className="label-text phrase">{props.politicianPhraseElement.quote}</h3>
            </div>
        </li >
    )

}
