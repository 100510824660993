import './leaderboard.page.scss'
import { State } from '../../../../state'
import { useSelector } from 'react-redux'
import { Table } from '../../../../component'
import { county, position } from '../../../../helper'
import { User } from '../../../../entity'

export const LeaderboardPage = () => {

    const { advancedUsers, advancingUsers, notAdvancingUsers, league } = useSelector( ( state: State ) => state.gamification )

    const columns = [
        {
            align: `center`,
            name: `id`,
            headerRender: () => `ID`,
            render: ( user: any ) => user.membershipId,
            width: 150
        },
        {
            align: `left`,
            name: `name`,
            headerRender: () => `Név`,
            render: ( user: any ) => `${ user.lastName } ${ user.firstName }`,
            width: 300
        },
        {
            align: `center`,
            name: `county`,
            headerRender: () => `Megye`,
            render: ( user: any ) => county( user.county ),
            width: 300
        },
        {
            align: `center`,
            name: `position`,
            headerRender: () => `Pozíció`,
            render: ( user: any ) => position( user.position ),
            width: 300
        },
        {
            align: `center`,
            name: `interest`,
            headerRender: () => `Érdeklődés`,
            render: ( user: any ) => ``,
            width: 300
        }
    ]

    return (

        <div className="leaderboard-page">

            <div className="caption">Felhasználók, akik továbbjutottak:</div>

            <div className="card-div">

                <Table columns={ columns } dataset={ advancedUsers } />

            </div>

            { league!.id !== 1 ? (

                <>

                    <div className="caption">Felhasználók, akik továbbjutó helyen vannak:</div>

                    <div className="card-div">

                        <Table columns={ columns } dataset={ advancingUsers } />

                    </div>

                </>

            ) : null }

            <div className="caption">Felhasználók a ligában:</div>

            <div className="card-div">

                <Table columns={ columns } dataset={ notAdvancingUsers } />

            </div>


        </div>

    )

}