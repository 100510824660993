import { Expose, Type } from 'class-transformer'
import { ValidateNested } from 'class-validator'
import { PoliticianTitle } from '../../entity'

export class GetPoliticianTitleDTO {

    @Expose()
    @ValidateNested()
    @Type( () => PoliticianTitle)
    politicianTitle!: PoliticianTitle

}