import { Expose, Type } from 'class-transformer'
import { IsDefined, ValidateNested, ValidateIf } from 'class-validator'
import { Event } from '../../entity'

export class GetEventsDTO {

    @Expose()
    @IsDefined()
    @Type( type => Event )
    @ValidateNested()
    events!: Event[]

    @Expose()
    @IsDefined()
    @Type( type => Event )
    @ValidateNested()
    @ValidateIf((object, value) => value !== null)
    highlightedEvent!: Event | null;

    @Expose()
    @IsDefined()
    pagination!: {
        page: number
        from: number
        to: number
        count: number
    }

}

