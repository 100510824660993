import './folder.scss'

interface FolderProps {

    name: string
    selected?: boolean
    onClick?: CallableFunction
    onDoubleClick?: CallableFunction

}

export const Folder = ( props: FolderProps ) => {

    const onClick = () => { if ( props.onClick !== undefined ) { props.onClick() } }
    const onDoubleClick = () => { if ( props.onDoubleClick !== undefined ) { props.onDoubleClick() } }

    return (

        <div className={ `folder${ props.selected === true ? ` selected` : `` }` } onClick={ onClick } onDoubleClick={ onDoubleClick }>

            <div className="folder__icon"></div>

            <div className="folder__name">

                { props.name }

            </div>

        </div>

    )

}
