import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { store } from '../../store'
import { GetLeagueStatsDTO } from '../../dto/gamification'

export const getLeagueStats = async ( id: number ) => {

    let body, response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/leagues/${ id }/stats`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'get'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'GET_LEAGUE_STATS_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'GET_LEAGUE_STATS_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'GET_LEAGUE_STATS_UNEXPECTEDRESPONSE' } )
        return

    }

    try {

        body = plainToClass( GetLeagueStatsDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'GET_LEAGUE_STATS_INVALIDJSON' } )
        return

    }

    if ( ( await validate( body ) ).length > 0 ) {

        store.dispatch( { type: 'GET_LEAGUE_STATS_INVALIDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'GET_LEAGUE_STATS_OK', stats: body } )

}
