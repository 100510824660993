import React, { MouseEvent, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import './quizes-list.page.scss'
import { SearchButton, SearchInput } from '../../../component/search'
import { useDispatch, useSelector } from 'react-redux'
import { deleteQuiz, deleteTest, editQuiz, editTest, listQuizes } from '../../../action/quizes'
import { QuizesTable } from '../../../component/quizes-table'
import { State } from '../../../state'
import { MenuButton, Modal } from '../../../component'
import { QuizTopicsModal } from '../../../component/quiz-topics-modal'
import { createFormattedDateTimeString, message } from '../../../helper'
import { QuizTopic } from '../../../component/quiz-topic'

export const QuizesList = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { archive, pagination, quizTopics } = useSelector( ( state: State ) => state.quizes )
    const highlighted: any = useSelector( ( state: State ) => state.quizes.highlighted )

    const [ showCreateQuiz, setShowCreateQuiz ] = useState( false )
    const [ showTopicsModal, setShowTopicsModal ] = useState( false )
    const [ quizSelected, setQuizSelected ] = useState( false )
    const [ testSelected, setTestSelected ] = useState( false )

    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )
    const [ showConfirmModal, setShowConfirmModal ] = useState( false )


    const onDelete = async () => {

        await deleteFunc!()
        setShowConfirmModal( false )

    }

    const search = async () => {

        await listQuizes()
        navigate( `/kvizek-es-tesztek/lista` )

    }

    const create = () => {

        if ( quizTopics.length === 0 ) {

            message( `A kvízek létrehozása előtt legalább egy téma létrehozása szükséges!`, `Hiba` )
            return

        }

        if ( quizSelected ) {

            navigate( `/kvizek-es-tesztek/kvizek/uj` )

        } else {

            navigate( `/kvizek-es-tesztek/tesztek/uj` )

        }

    }

    const toggleArchive = async () => {

        dispatch( { type: `TOGGLE_QUIZ_ARCHIVE` } )
        await search()

    }

    const setKeyword = async ( keyword: string ) => {

        dispatch( { type: `SET_QUIZ_KEYWORD`, keyword } )

        if ( keyword.trim().length === 0 ) {
            await search()
        }

    }

    const selectQuiz = () => {

        setQuizSelected( !quizSelected )
        setTestSelected( false )

    }

    const selectTest = () => {

        setQuizSelected( false )
        setTestSelected( !testSelected )

    }

    const onArchiveChange = async ( id: number, type: string ) => {

        if ( type === `quiz` ) {

            await editQuiz( id, { visibleTo: Math.round( new Date().getTime() / 1000 ) } )

        } else {

            await editTest( id, { visibleTo: Math.round( new Date().getTime() / 1000 ) } )

        }

        await listQuizes( pagination.page )

    }

    const onMenuSelect = async ( action: number, id: number, type: string ) => {

        switch ( action ) {

            case 3:
                setDeleteFunc( () => {
                    return async () => {

                        if ( type === `quiz` ) {

                            await deleteQuiz( id )

                        } else {

                            await deleteTest( id )

                        }
                        await listQuizes( pagination.page )
                    }
                } )
                setShowConfirmModal( true )
                break

            case 2:
                await onArchiveChange( id, type )
                break

            default:
                navigate( `/kepzesek/${ id }` )

        }

    }

    return (

        <main className="page page--quizes-list">

            <div className="main-div">

                <div className="navigation-title">

                    <NavLink to="/kvizek-es-tesztek">

                        <h1 className="title-text-h1">Kvízek &gt;</h1>

                    </NavLink>

                    <h1 className="title-text-h1">Kvízek lista</h1>

                </div>

                <div className="toolbar">

                    <SearchInput placeholder="Keresés a kvízek és tesztek között" onChange={ e => setKeyword( e.target.value ) } onSearch={ search } />
                    <SearchButton onClick={ search } />
                    <button className="button button--primary" onClick={ toggleArchive }>{ archive ? `Aktív` : `Archív` } kvízek</button>
                    <button className="button button--ghost" onClick={ () => setShowTopicsModal( true ) }>Témák</button>
                    <button className="button button--primary" onClick={ () => setShowCreateQuiz( true ) }>+ Új kvíz</button>

                </div>

                { highlighted !== null ? (

                    <div className="card-div card-div--highlighted">

                        <h2 className="title-text-h2">Kiemelt Országos kvíz</h2>

                        <div className="card-div highlighted-course">

                            <div className="title">{ highlighted.title }</div>
                            <div className="topic-holder"><QuizTopic color={ highlighted.topic_color } name={ highlighted.topic_name } /></div>
                            <div className="start-date">{ createFormattedDateTimeString( highlighted.visibleFrom ) }</div>
                            <div className="fills"><span>Kitöltések száma:</span> { highlighted.answers } fő</div>
                            <MenuButton hasBackground={ false } items={ [
                                {
                                    id: 0,
                                    value: `Szerkesztés`,
                                    icon: `edit.svg`
                                },
                                {
                                    id: 1,
                                    value: `Megtekintés`,
                                    icon: `eye.svg`
                                },
                                {
                                    id: 2,
                                    value: `Archiválás`,
                                    icon: `folder-times.svg`
                                },
                                {
                                    id: 3,
                                    value: `Törlés`,
                                    icon: `trash.svg`
                                }
                            ] } onChange={ ( action: number ) => onMenuSelect( action, highlighted.id, highlighted.type ) } />

                        </div>

                    </div>

                ) : null }

                <div className="card-div card-div--table">

                    <QuizesTable />

                </div>

                { showCreateQuiz ? (

                    <Modal className="create-quiz-modal" onModalClose={ () => setShowCreateQuiz( false ) }>

                        <div className="modal-header">

                            <div className="modal-title">Új kvíztipus kiválasztása</div>

                        </div>

                        <div className="modal-body">

                            <div className={ `option ${ quizSelected ? `option--selected` : `` }` } onClick={ selectQuiz }>Kvíz</div>
                            <div className={ `option ${ testSelected ? `option--selected` : `` }` } onClick={ selectTest }>Teszt</div>

                        </div>

                        <div className="modal-footer">

                            <button className="button button--primary" disabled={ !quizSelected && !testSelected } onClick={ create }>Módosítások mentése</button>

                        </div>

                    </Modal>

                ) : null }

            </div>

            { showTopicsModal ? (

                <QuizTopicsModal onClose={ () => setShowTopicsModal( false ) } />

            ) : null }

            { showConfirmModal ? (

                <Modal className="application-version-confirm-modal" onModalClose={ () => setShowConfirmModal( false ) }>

                    <div className="modal-title">Biztosan törlöd a kvízt?</div>
                    <div className="modal-description">Ezt a műveletet nem lehet visszavonni! Biztosan törlöd?</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowConfirmModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ onDelete }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

        </main>

    )

}
