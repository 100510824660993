import './push.page.scss'
import { TextArea } from '../../../../component/textarea'
import { useState } from 'react'
import { MessageModal } from '../../../../component/modal'
import { sendPush } from '../../../../action/gamification'
import { useSelector } from 'react-redux'
import { State } from '../../../../state'

export const PushPage = () => {

    const { league } = useSelector( ( state: State ) => state.gamification )

    const [ pushTitle, setPushTitle ] = useState( `` )
    const [ pushMessage, setPushMessage ] = useState( `` )

    const [infoModalVisibility, setInfoModalVisibility] = useState(false);
    const [infoModalTitle, setInfoModalTitle] = useState("");
    const [infoModalDescription, setInfoModalDescription] = useState("");

    const onSendClick = async (  ) => {
        if (!checkFields()) {
            return;
        }
        await sendPush( pushTitle, pushMessage, [ league!.id ] )
    }

    const checkFields = (): boolean => {
        if (pushTitle.trim().length === 0) {
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription( "A Push címének megadása kötelező!");
            setInfoModalVisibility(true);
            return false
        }

        if (pushMessage.trim().length === 0) {
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription( "A Push szöveg megadása kötelező!");
            setInfoModalVisibility(true);
            return false
        }
      
        return true
    }

    return (

        <div className="card-div push-page">

            <div className="field">

                <label>Push címe *</label>
                <TextArea field={{ value: pushTitle, error: `` }} onChange={ e => setPushTitle( e.target.value ) } />

            </div>

            <div className="field">

                <label>Push szöveg megadása *</label>
                <TextArea field={{ value: pushMessage, error: `` }} onChange={ e => setPushMessage( e.target.value ) } />

            </div>

            <footer>

                <button className="button button--primary" onClick={ onSendClick } style={{minWidth: 176}}>Küldés</button>

            </footer>

            {infoModalVisibility ? (
                <MessageModal
                    title={infoModalTitle}
                    message={infoModalDescription}
                    onCloseClick={() => {
                        setInfoModalVisibility(false);
                    }}
                />
            ) : null}

        </div>

    )

}