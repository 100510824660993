import { useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { deleteQuotes } from '../../../action/quotes'
import { State } from '../../../state'
import './view-quote.page.scss'
import { useState } from 'react'
import { Modal } from '../../../component'

export const ViewQuotePage = () => {

    const navigate = useNavigate()
    const { quote } = useSelector( ( state: State ) => state.quotes )

    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )
    const [ showConfirmModal, setShowConfirmModal ] = useState( false )

    const onDelete = async () => {

        await deleteFunc!()
        setShowConfirmModal( false )

    }

    const deleteQuote = async () => {

        setDeleteFunc( () => {
            return async () => {
                await deleteQuotes( [ quote!.id ] )
                navigate( `/velemenyvezerek` )
            }
        } )
        setShowConfirmModal( true )

    }

    return (

        <main className="page page--view-quote">

            <div className="main-div">

                <div className="navigation-title">

                    <NavLink to="/velemenyvezerek">
                        <h1 className="title-text-h1">Véleményvezérek &gt;</h1>
                    </NavLink>
                    <h1 className="title-text-h1">#{ quote?.id } - { quote?.quotePolitician?.name } - { quote?.quote }</h1>

                </div>

                <div className="toolbar">

                    <NavLink to="/velemenyvezerek" style={ { marginRight: `auto` } }>

                        <button className="button button--ghost" style={ { minWidth: 173 } }>Vissza</button>

                    </NavLink>

                    <button className="button button--warning" style={ { marginLeft: `auto`, minWidth: 171 } } onClick={ e => deleteQuote() }>Törlés</button>

                </div>

                <main>

                    <div className="quote-card-front">

                        <img alt="" className="background" src={ quote?.quotePolitician?.image?.url } />
                        <img alt="" className="party" src={ quote?.quoteParty?.image?.url } />
                        <div className="quote">“{ quote?.quote }”</div>
                        <div className="layer"></div>

                    </div>

                    <div className="quote-card-back">

                        <div className="quote">“{ quote?.quote }”</div>

                        <div className="caption">Ki mondta?</div>
                        <div className="value">{ quote?.quotePolitician?.name }</div>

                        <div className="caption">Mikor mondta?</div>
                        <div className="value">{ quote?.quoteWhen }</div>

                        <div className="caption">Hol mondta?</div>
                        <div className="value value--double-line">{ quote?.quoteWhere }</div>

                    </div>

                    <div style={ { width: `100%` } }>

                        <div className="card-div" style={ { flexDirection: `column` } }>

                            <div className="info-caption">Info</div>
                            <div className="info-stat">89%</div>
                            <div className="info-description">

                                Az idézet a szavazók 89%-nak tetszik, ezért megjelenhet a politikus profil oldalán.<br />
                                A megjelenést eltávolíthatod, a politikus alatt.

                            </div>

                        </div>

                        { quote?.politician ? (

                            <footer>

                                <button className="button" style={ { minWidth: 326 } } onClick={ e => navigate( `/politikusok/${ quote?.politician?.id }` ) }>Tovább a politikus oldalára</button>

                            </footer>

                        ) : null }

                    </div>

                </main>

            </div>

            { showConfirmModal ? (

                <Modal className="quote-confirm-modal" onModalClose={ () => setShowConfirmModal( false ) }>

                    <div className="modal-title">Biztosan törlöd a mondatot?</div>
                    <div className="modal-description">Ezt a műveletet nem lehet visszavonni! Biztosan törlöd?</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowConfirmModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ onDelete }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

        </main>

    )

}
