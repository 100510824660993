import { MenuElement } from './menu-element'
import './menu-group.scss'

interface MenuGroupProps {
    menuGroup: any
    open?: boolean
    onMobileMenuToggle?: ( open: boolean ) => void
}


export const MenuGroup = ( props: MenuGroupProps ) => {

    const menuElements: JSX.Element[] = []
    props.menuGroup.forEach( ( menuElement: any ) => {
        menuElements.push( <MenuElement key={ menuElement.path } menuElement={ menuElement } onMobileMenuToggle= { props.onMobileMenuToggle }/> )
    } )

    return (
        <ul className="menu__menu-group">
            { props.open !== false ?  menuElements  : null }
        </ul>
    )

}
