import { store } from '../../store'

export const sendTestPush = async ( id: number, body: string ) => {

    let response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/tests/push`, {
            body: JSON.stringify( { id, body } ),
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'POST'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'SEND_TEST_PUSH_TIMEOUT' } )
        return false

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'SEND_TEST_PUSH_UNAUTHORIZED' } )
        return false

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'SEND_TEST_PUSH_UNEXPECTEDRESPONSE' } )
        return false

    }

    store.dispatch( { type: 'SEND_TEST_PUSH_OK' } )
    return true

}