import { ChangeEvent, MouseEvent, KeyboardEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { SearchButton, SearchInput } from '../../component/search'
import { State } from '../../state'
import './politicians.page.scss'
import { getPoliticians } from '../../action/politician'
import { Politician } from '../../entity'
import { PoliticianCard } from '../../component/politicians'
import { Dropdown } from '../../component'
import { POLITICIANS_ACTIONS } from '../../action/politician/politicians.constants'
import { PoliticianTitleModal } from '../../component/politicians/politician-title-modal'

interface PoliticiansProps { }

export const Politicians = ( props: PoliticiansProps ) => {

    const dispatch = useDispatch()

    const [ politicianTitlesModalVisibility, setPoliticianTitlesModalVisibility ] = useState<boolean>( false )

    const orderOptions = [
        { id: 1, value: 'ABC szerinti növekvő sorrend' },
        { id: 2, value: 'ABC szerinti csökkenő sorrend' },
        // { id: 3, value: 'Megtekintés szerinti növekvő sorrend' },
        // { id: 3, value: 'Megtekintés szerinti csökkenő sorrend' },
    ]

    const politicians = useSelector( ( state: State ) => state.politicians.politicians )
    const order = useSelector( ( state: State ) => state.politicians.order )

    useEffect( () => {
        dispatch( { type: 'CREATE_POLITICIAN_CLEAR' } )
    }, [] )

    const onSearchChange = async ( event: ChangeEvent<HTMLInputElement> ) => {
        event.preventDefault()

        dispatch( { type: POLITICIANS_ACTIONS.GET_POLITICIANS_SEARCH, search: event.target.value } )

        if ( event.target.value.length == 0 ) {
            await getPoliticians()
        }
    }

    const onSearchButtonClick = async ( event: MouseEvent | KeyboardEvent ) => {
        event.preventDefault()

        await getPoliticians()
    }

    const onOrderChange = async ( itemId: any ) => {
        dispatch( { type: POLITICIANS_ACTIONS.GET_POLITICIANS_ORDER } )
        await getPoliticians()
        //TODO megtekintés szetinti sorrend
    }

    const onTitlesButtonClick = async ( event: MouseEvent ) => {
        event.preventDefault()

        dispatch( { type: 'CREATE_POLITICIAN_TITLE_CLEAR' } )

        setPoliticianTitlesModalVisibility( true )
    }

    const onTitlesCloseButtonClick = async ( event: MouseEvent ) => {
        event.preventDefault()

        setPoliticianTitlesModalVisibility( false )
    }

    const politicianElements: JSX.Element[] = []

    if ( politicians !== null && politicians !== undefined ) {
        politicians.map( ( politicianElement: Politician ) => {
            politicianElements.push( <PoliticianCard politicianElement={ politicianElement } uncompletedQuestionCountvisible={ true } /> )
        } )
    }

    return (
        <main className="page page-politicians">

            <div className="main-div">

                <header>
                    <h1 className="title-text-h1">Politikusaink</h1>
                </header>

                <div className="toolbar">
                    <SearchInput onSearch={ onSearchButtonClick } onChange={ onSearchChange } placeholder="Keresés a politikusok között" />
                    <SearchButton onClick={ onSearchButtonClick } />
                    <button className="button button-white titles-button" onClick={ onTitlesButtonClick } >Titulusok</button>

                    <NavLink className="questions-a" to="/politikusok/kerdesek">
                        <button className="button questions-button">Kérdések</button>
                    </NavLink>
                    <NavLink className="new-a" to="/politikusok/uj">
                        <button className="button new-button">+ Új politikus</button>
                    </NavLink>
                </div>

                <div className="container main-container">
                    <div className="card-div">

                        <div className="container column">

                            <div className="title-container">
                                <h2 className="title-text-h2 title-text-width">DK-s politikusok</h2>
                                <span className="label-text title-right-margin">Rendezés:</span>
                                <Dropdown value={ order == "ASC" ? 1 : 2 } items={ orderOptions } onChange={ onOrderChange } compact={ true } />
                            </div>

                            <div className="politician-section-container">
                                <ul id={ "related_politician_table" } className="politician-list">
                                    { politicianElements.length != 0 ? politicianElements : "" }
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            { politicianTitlesModalVisibility ? <PoliticianTitleModal onCloseClick={ onTitlesCloseButtonClick } onModalClose={ () => setPoliticianTitlesModalVisibility( false ) } /> : null }

        </main>
    )

}
