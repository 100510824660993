import { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CREATE_QUESTIONNAIRE_ACTIONS } from '../../../../action/questionnaires/questionnaires.constants'
import { QuestionnaireQuestionAnswer } from '../../../../entity'
import { QuestionnaireQuestionAnswerElement } from './qestionnaire-question-answer-element'
import './qestionnaire-question-answer-table.scss'

interface QuestionnaireQuestionAnswerTableProps {
    questionId: number
    answerArray: QuestionnaireQuestionAnswer[]
    disabled?: boolean
}

export const QuestionnaireQuestionAnswerTable = ( props: QuestionnaireQuestionAnswerTableProps ) => {
    
    const dispatch = useDispatch()

    useEffect(() => { 

    }, [])

    const onAddAnswerItem = async ( event: MouseEvent ) => {
        event.preventDefault()
        dispatch( { type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_ADD_QUESTION_ANSWER, id: props.questionId } )
    }

    const questionElements: JSX.Element[] = []
    props.answerArray.map(( answerElement: QuestionnaireQuestionAnswer, index  ) => {

        questionElements.push( <QuestionnaireQuestionAnswerElement answerElement={ answerElement } position={ index+1 } questionId={ props.questionId } disabled={ props.disabled === true } /> )
    })

    return (
        <div className="questionnaire-question-answer-table-container">

            <ul id={"questionnaire_question_answer_table"} className="questionnaire-question-answer-table">
                { questionElements.length != 0 ? questionElements : null }
            </ul>

            <div className={`add-questionnaire-button${ props.disabled === true ? ` disabled` : `` }`} onClick={onAddAnswerItem}>
                <img className="add-questionnaire-button--icon" alt={ "Hozzáadás ikon" } src={`/image/add.svg` } />

                <h3 className="add-questionnaire-button--text">Új válasz hozzáadása </h3>
            </div>
        
        </div>
    )

}
