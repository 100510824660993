import './test-result.scss'

interface TestResultProps {

    id: number
    name: string

    onClick?: () => void

    selected?: boolean

}

export const TestResult = ( props: TestResultProps ) => {

    const onClick = () => { if ( props.onClick ) props.onClick() }

    return (

        <div className={ `test-result${ props.selected === true ? ` test-result--selected` : `` }` } onClick={ onClick }>

            <div className="test-result__name">{ props.name }</div>

        </div>

    )

}