import { Expose, Type } from 'class-transformer'
import { ValidateNested } from 'class-validator'
import { Politician } from '../../entity'

export class GetPoliticianDTO {

    @Expose()
    @ValidateNested()
    @Type( () => Politician )
    politician!: Politician
    
}
