import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import './firebase.page.scss'
import { useState } from 'react'
import { store } from '../../store'

export const FirebasePage = () => {

    const [ token, setToken ] = useState( `` )
    const [ topic, setTopic ] = useState( `` )

    const messaging = getMessaging()

    const onTokenButtonClick = async () => {

        setToken( await getToken( messaging ) )

    }

    const onSubscribeButtonClick = async () => {

        if ( token.length === 0 ) {

            await onTokenButtonClick()

        }

        await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/firebase/sub/${ token }/${ topic }`, {
            headers: {
                'Content-Type': `application/json`,
                'Authorization': `Bearer ${ store.getState().session.token }`
            }
        } )

    }

    return (

        <main className="page page--firebase">

            <div className="main-div">

                <div className="navigation-title">

                    <h1 className="title-text-h1">Firebase</h1>

                </div>

                <div style={{ display: `flex`, flexDirection: `column`, marginBottom: 30 }}>

                    <button className="button button--primary" style={{ marginBottom: 10, width: 200 }} onClick={ onTokenButtonClick }>Get token</button>
                    <label>Token: { token }</label>

                </div>

                <div style={{ display: `flex`, flexDirection: `column` }}>

                    <div style={{ marginBottom: 10 }}>Topic: <input type="text" style={{ width: 300 }} value={ topic } onChange={ e => setTopic( e.target.value ) } /></div>
                    <button className="button button--primary" style={{ width: 200 }} onClick={ onSubscribeButtonClick }>Subscribe</button>

                </div>

            </div>

        </main>

    )

}