import { ChangeEventHandler, createRef, MouseEventHandler } from 'react'
import { Modal } from './modal'
import './input-modal.scss'
import { TextArea } from '../textarea'

interface InputModalProps {
    title: string
    placeholder: string
    field: {
        error: string
        required?: boolean
        value: string
    }
    onInputChange: ChangeEventHandler<HTMLTextAreaElement>
    onCloseClick: MouseEventHandler
    onSaveClick: any
    onModalClose?: () => void
}

export const InputModal = ( props: InputModalProps ) => {

    const formElement = createRef<HTMLFormElement>()

    const onSaveButtonClick = (): void => {
        if ( !formElement.current?.reportValidity() ) { return }
        props.onSaveClick()
    }

    return (
        <Modal onModalClose={ () => props.onModalClose && props.onModalClose() }>

            <div className="input-modal">

                <div className="input-modal__header">
                    <div className="input-modal__title"> { props.title } </div>
                    <button className="close-button" onClick={ props.onCloseClick }> <img alt="bezárás gomb" src="/image/close.svg" /> </button>

                </div>

                <form action="" className="create-news-form" ref={ formElement }>
                    <div className="">
                        <TextArea id={ "input_modal_text_area" } maxLength={ 120 } field={ props.field } onChange={ props.onInputChange } placeholder={ props.placeholder } category='lead' />
                    </div>
                </form>

                <div className="input-modal__footer">
                    <button className="button" type="button" onClick={ onSaveButtonClick } >Mentés</button>
                </div>

            </div>

        </Modal>
    )

}
