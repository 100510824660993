import './tags.page.scss'
import { SearchButton, SearchInput } from '../../component/search'
import { useState } from 'react'
import { TagGroup } from './tag-group/tag-group'
import { TextField } from '../../component/field'
import { Dropdown, Modal } from '../../component'
import { ColorPicker } from './colorpicker/color-picker'
import { useSelector } from 'react-redux'
import { State } from '../../state'
import { deleteTag, deleteTagGroup, listTags, saveTag, saveTagGroup } from '../../action/tags'
import { Tag } from '../../entity'
import { MessageModal } from '../../component/modal'

export const Tags = () => {

    const [ id, setId ] = useState( undefined as number | null | undefined )
    const [ name, setName ] = useState( null as string | null )
    const [ group, setGroup ] = useState( null as number | null )
    const [ color, setColor ] = useState( null as string | null )

    const [ tagGroupId, setTagGroupId ] = useState( undefined as number | null | undefined )
    const [ tagGroupName, setTagGroupName ] = useState( null as string | null )

    const [ keyword, setKeyword ] = useState( `` )

    const [ showDeleteModal, setShowDeleteModal ] = useState( false )
    const [ showTagGroupModal, setShowTagGroupModal ] = useState( false )

    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )
    const [ deletableType, setDeletableType ] = useState( `` )

    const { tagGroups } = useSelector( ( state: State ) => state.tags )

    const [infoModalVisibility, setInfoModalVisibility] = useState(false)
    const [infoModalTitle, setInfoModalTitle] = useState("")
    const [infoModalDescription, setInfoModalDescription] = useState("")

    const createTag = () => {

        setId( null )
        setName( `` )
        setGroup( tagGroups[ 0 ].id )
        setColor( `#CC0066` )

    }

    const createTagGroup = () => {

        setTagGroupId( null )
        setTagGroupName( `` )
        setShowTagGroupModal( true )

    }

    const editTag = ( id: number ) => {

        let selectedTag = null
        let selectedTagGroup = null

        for ( const tagGroup of tagGroups ) {
            for ( const tag of tagGroup.tags ) {
                if ( tag.id === id ) {
                    selectedTag = tag
                    selectedTagGroup = tagGroup
                }
            }
        }

        if ( selectedTag === null || selectedTagGroup === null ) { return }

        setId( id )
        setName( selectedTag.name )
        setGroup( selectedTagGroup.id )
        setColor( selectedTag.color )

    }

    const onSave = async () => {

        if (!checkFields()) {
            return
        }

        await saveTag( id!, name!, color!, group! )
        await listTags()
        setId( undefined )

    }

    const checkFields = (): boolean => {
        if (name === "") {        
          setInfoModalTitle("Figyelmeztetés");
          setInfoModalDescription( "Címke nevének megadása kötelező!");
          setInfoModalVisibility(true);
          return false
        }

        if (group === null) {        
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription( "Címke csoport megadása kötelező!");
            setInfoModalVisibility(true);
            return false
        }

        if (color === "") {        
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription( "Címke színénke megadása kötelező!");
            setInfoModalVisibility(true);
            return false
        }

    
        return true
      }

    const confirmDelete = async () => {

        if ( deleteFunc !== null ) {

            await deleteFunc()

        }
        setDeleteFunc( null )
        setShowDeleteModal( false )

    }

    const onDelete = async () => {

        const deleteFunc = async () => {
            await deleteTag( id! )
            await listTags()
            setId( undefined )
        }

        setDeletableType( `tag` )
        setDeleteFunc( () => deleteFunc )
        setShowDeleteModal( true )

    }

    const editTagGroup = ( id: number ) => {

        let selectedTagGroup = null

        for ( const tagGroup of tagGroups ) {
            if ( tagGroup.id === id ) {
                selectedTagGroup = tagGroup
            }
        }

        if ( selectedTagGroup === null ) { return }

        setTagGroupId( id )
        setTagGroupName( selectedTagGroup.name )

        setShowTagGroupModal( true )

    }

    const onDeleteTagGroup = () => {

        const deleteFunc = async () => {
            await deleteTagGroup( tagGroupId! )
            await listTags()
            setTagGroupId( undefined )
        }

        setDeletableType( `tag-group` )
        setDeleteFunc( () => deleteFunc )
        setShowTagGroupModal( false )
        setShowDeleteModal( true )

    }
    const onSaveTagGroup = async () => {

        if (tagGroupName === "") {        
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription( "Címke csoport megadása kötelező!");
            setInfoModalVisibility(true);
            return false
        }

        await saveTagGroup( tagGroupId!, tagGroupName! )
        await listTags()
        setTagGroupId( undefined )
        setShowTagGroupModal( false )

    }

    return (

        <main className="page page--tags">

            <div className="main-div">

                <div className="navigation-title">

                    <h1 className="title-text-h1">Címkéző</h1>

                </div>

                <div className="toolbar">

                    <SearchInput onChange={ e => setKeyword( e.target.value ) } placeholder="Keresés a címkék között" />
                    <SearchButton onClick={ e => {} } />
                    <button className="button button--primary" style={{minWidth: 246}} onClick={ () => createTagGroup() }>+ Címke csoport</button>
                    <button className="button button--primary" style={{minWidth: 183}} onClick={ () => createTag() }>+ Címke</button>

                </div>

                <div className="columns-view">

                    <div className="column">

                        { tagGroups.map( tagGroup => (

                            <TagGroup key={ tagGroup.id } { ...tagGroup } onEditTag={ id => editTag( id ) } keyword={ keyword } onEdit={ () => editTagGroup( tagGroup.id ) } />

                        ) ) }

                    </div>

                    <div className="separator"></div>

                    <div className="column">

                        { id !== undefined ? (

                            <div className="card-div tag-editor">

                                <div className="tag-editor__title">

                                    Címke { id === null ? `létrehozása` : `módosítása` }

                                </div>

                                <div className="field">

                                    <label>Címke neve *</label>
                                    <TextField field={{ value: name!, error: `` }} onChange={ e => setName( e.target.value ) } />

                                </div>

                                <div className="field">

                                    <label>Címke csoport *</label>
                                    <Dropdown compact={ true } value={ group } items={ tagGroups.map( tagGroup => ({ id: tagGroup.id, value: tagGroup.name }) ) } onChange={ ( id: number ) => setGroup( id ) } />

                                </div>

                                <div className="field field--color">

                                    <label>Címke színe *</label>
                                    <div className="color-bar" style={{backgroundColor: color!}} />

                                </div>

                                <ColorPicker onColorSelect={ color => setColor( color ) } />

                                <footer>

                                    { id !== null ? (

                                        <button className="button button--ghost button--warning" style={{minWidth: 187}} onClick={ onDelete }>Címke törlése</button>

                                    ) : null }

                                    <button className="button button--primary" style={{minWidth: 235}} onClick={ onSave }>Módosítások mentése</button>

                                </footer>

                            </div>

                        ) : null }

                    </div>

                </div>

            </div>

            { showDeleteModal ? (

                <Modal onModalClose={ () => setShowDeleteModal( false ) }>

                    <div className="modal-title">Biztosan törlöd a { deletableType === `tag` ? `címkét` : `címke csoportot` }?</div>
                    <div className="modal-description">{ deletableType === `tag` ? `A címke a továbbiakban nem lesz használható, és az eddig hozzá csatolt entitásokról is eltávolításra kerül.` : `A csoportban található összes címke is törlésre kerül. A címkék a továbbiakban nem lesznek használhatóak, és az eddig hozzájuk csatolt entitásokról is eltávolításra kerülnek.` }</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowDeleteModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ () => confirmDelete() }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

            { showTagGroupModal ? (

                <Modal onModalClose={ () => setShowTagGroupModal( false ) }>

                    <div className="modal-title">Címke csoport módosítása</div>
                    <div className="modal-description">
                        <div className="field">
                            <label>Címke csoport *</label>
                            <TextField field={{ value: tagGroupName!, error: `` }} onChange={ e => setTagGroupName( e.target.value ) } />
                        </div>
                    </div>
                    <footer>
                        <button className="button button--ghost button--warning" style={{minWidth: 219}} onClick={ () => onDeleteTagGroup() }>Címke csoport törlése</button>
                        <button className="button button--primary" style={{minWidth: 235}} onClick={ () => onSaveTagGroup() }>Módosítások mentése</button>
                    </footer>

                </Modal>

            ) : null }

            {infoModalVisibility ? (
                <MessageModal
                    title={infoModalTitle}
                    message={infoModalDescription}
                    onCloseClick={() => {
                        setInfoModalVisibility(false);
                    }}
                />
            ) : null}       

        </main>

    )

}