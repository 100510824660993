import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetMediaLibraryFileDTO } from '../../dto'
import { store } from '../../store'

interface GetImageOptions {

    cut: {
        x: number
        y: number,
        w: number,
        h: number
    }

}

export const getImage = async ( id: number = 1, image: Blob, options: GetImageOptions ) => {

    const formData = new FormData()
    formData.append( `x`, options.cut.x.toString() )
    formData.append( `y`, options.cut.y.toString() )
    formData.append( `w`, options.cut.w.toString() )
    formData.append( `h`, options.cut.h.toString() )
    formData.append( `image`, image )

    const response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/media-library/image/${ id }`, {
        body: formData,
        headers: {
            'Authorization': 'Bearer ' + store.getState().session.token
        },
        method: `POST`
    } )

    if ( response.status === 401 ) {
        throw new Error()
    }

    if ( response.status !== 200 ) {
        throw new Error()
    }

    const body = plainToClass( GetMediaLibraryFileDTO, JSON.parse( await response.text() ) )

    if ( ( await validate( body ) ).length > 0 ) {
        throw new Error()
    }

    return body.mediaLibraryFile

}
