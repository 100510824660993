import { MenuButton } from '../button'
import { MouseEvent } from 'react'
import './add-image.scss'

interface AddImageProps {
    imageUrl: string
    text: string
    width: string
    height: string
    onAddImage: CallableFunction
    onDeleteImage: CallableFunction
    disabled?: boolean
}

export const AddImage = (props: AddImageProps) => {
    const menuOptions = [
        { id: 1, value: 'Szerkesztés', icon: 'edit.svg' },
        { id: 2, value: 'Törlés', icon: 'trash.svg' },
    ]

    const onMenuItemChange = async (itemId: any) => {
        switch (itemId) {
            case 1:
                props.onAddImage()
                break
            case 2:
                props.onDeleteImage()
                break
            default:
                break
        }
    }

    const onAddImage = (event: MouseEvent) => {
        if (props.disabled !== true && event.target === event.currentTarget) {
            props.onAddImage()
        }
    }

    return (
        <div className="add-image" style={{ width: props.width, height: props.height }}>
            {props.imageUrl !== '' ? (
                <div className="add-image-wrapper">
                    <img className="add-image-image" src={props.imageUrl} alt="Kép" />

                    <MenuButton hasBackground={true} onChange={onMenuItemChange} items={menuOptions} disabled={props.disabled} />
                </div>
            ) : (
                <div className="add-image-container" onClick={onAddImage}>
                    <div className="add-image-inner-container" onClick={onAddImage}>
                        <span className="material-symbols-outlined icon-white" onClick={onAddImage}>
                            add
                        </span>
                    </div>
                    <span className="label-text label-text-primary" onClick={onAddImage}>
                        {props.text}
                    </span>
                </div>
            )}
        </div>
    )
}
