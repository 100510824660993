export const NEWS_ACTIONS = {
    GET_NEWS_INVALID_JSON: "GET_NEWS_INVALID_JSON",
    GET_NEWS_INVALID_RESPONSE: "GET_NEWS_INVALID_RESPONSE",
    GET_NEWS_OK: "GET_NEWS_OK",
    GET_NEWS_CHANGE_PAGE: "GET_NEWS_CHANGE_PAGE",
    GET_NEWS_SEARCH: "GET_NEWS_SEARCH",
    GET_NEWS_ORDER: "GET_NEWS_ORDER",
    GET_NEWS_DELETED: "GET_NEWS_DELETED",
}

export const GET_NEWS_ITEM_STATS_ACTIONS = {
    GET_NEWS_ITEM_STATS_INVALID_JSON: "GET_NEWS_ITEM_STATS_INVALID_JSON",
    GET_NEWS_ITEM_STATS_INVALID_RESPONSE: "GET_NEWS_ITEM_STATS_INVALID_RESPONSE",
    GET_NEWS_ITEM_STATS_OK: "GET_NEWS_ITEM_STATS_OK",
}

export const CREATE_NEWS_ACTIONS = {
    CREATE_NEWS_STARTED: "CREATE_NEWS_STARTED",
    CREATE_NEWS_INVALID_JSON: "CREATE_NEWS_INVALID_JSON",
    CREATE_NEWS_INVALID_RESPONSE: "CREATE_NEWS_INVALID_RESPONSE",
    CREATE_NEWS_OK: "CREATE_NEWS_OK",
    CREATE_NEWS_CHANGE_MEDIA_LIBRARY_FILE: "CREATE_NEWS_CHANGE_MEDIA_LIBRARY_FILE",
    CREATE_NEWS_CHANGE_VISIBLE_FROM: "CREATE_NEWS_CHANGE_VISIBLE_FROM",
    CREATE_NEWS_CHANGE_VISIBLE_TO: "CREATE_NEWS_CHANGE_VISIBLE_TO",
    CREATE_NEWS_CHANGE_IMAGE_VISIBILITY_IN_LIST: "CREATE_NEWS_CHANGE_IMAGE_VISIBILITY_IN_LIST",
    CREATE_NEWS_CHANGE_CATEGORY: "CREATE_NEWS_CHANGE_CATEGORY",
    CREATE_NEWS_CHANGE_STATUS: "CREATE_NEWS_CHANGE_STATUS",
    CREATE_NEWS_REMOVE_POLITICIAN: "CREATE_NEWS_REMOVE_POLITICIAN",
    CREATE_NEWS_CHANGE_VISIBILITY: "CREATE_NEWS_CHANGE_VISIBILITY",
    CREATE_NEWS_CHANGE_HIGHLIGHTED: "CREATE_NEWS_CHANGE_HIGHLIGHTED",
    CREATE_NEWS_CHANGE_PUSH: "CREATE_NEWS_CHANGE_PUSH",
    CREATE_NEWS_CHANGE_STATEMENT_BY: "CREATE_NEWS_CHANGE_STATEMENT_BY",
    CREATE_NEWS_CHANGE_TITLE: "CREATE_NEWS_CHANGE_TITLE",
    CREATE_NEWS_CHANGE_LEAD: "CREATE_NEWS_CHANGE_LEAD",
    CREATE_NEWS_CHANGE_DESCRIPTION: "CREATE_NEWS_CHANGE_DESCRIPTION",
    CREATE_NEWS_CHANGE_QUESTION: "CREATE_NEWS_CHANGE_QUESTION",
    CREATE_NEWS_CHANGE_ANSWER1: "CREATE_NEWS_CHANGE_ANSWER1",
    CREATE_NEWS_CHANGE_ANSWER2: "CREATE_NEWS_CHANGE_ANSWER2",
    CREATE_NEWS_CHANGE_ANSWER3: "CREATE_NEWS_CHANGE_ANSWER3",
    CREATE_NEWS_CHANGE_ANSWER4: "CREATE_NEWS_CHANGE_ANSWER4",
    CREATE_NEWS_CHANGE_CORRECT_ANSWER_ID: "CREATE_NEWS_CHANGE_CORRECT_ANSWER_ID",
    CREATE_NEWS_ADD_POLITICIAN: "CREATE_NEWS_ADD_POLITICIAN",
    CREATE_NEWS_CLEAR: "CREATE_NEWS_CLEAR",
    CREATE_NEWS_MODIFY_NEWS: "CREATE_NEWS_MODIFY_NEWS",
}

export const DELETE_NEWS_ACTIONS = {
    DELETE_NEWS_STARTED: "DELETE_NEWS_STARTED",
    DELETE_NEWS_OK: "DELETE_NEWS_OK",
}

export const GET_NEWS_STAT_ACTIONS = {
    GET_NEWS_STATS_INVALID_JSON: "GET_NEWS_STATS_INVALID_JSON",
    GET_NEWS_STATS_INVALID_RESPONSE: "GET_NEWS_STATS_INVALID_RESPONSE",
    GET_NEWS_STATS_OK: "GET_NEWS_STATS_OK",
}

export const MODIFY_NEWS_STATU_ACTIONS = {
    MODIFY_NEWS_STATUS_STARTED: "MODIFY_NEWS_STATUS_STARTED",
    MODIFY_NEWS_STATUS_INVALID_JSON: "MODIFY_NEWS_STATUS_INVALID_JSON",
    MODIFY_NEWS_STATUS_INVALID_RESPONSE: "MODIFY_NEWS_STATUS_INVALID_RESPONSE",
    MODIFY_NEWS_STATUS_OK: "MODIFY_NEWS_STATUS_OK",
}

