import './tag.scss'

interface TagProps {
    color: string
    text: string
}

export const Tag = ( props: TagProps ) => {

    return (
        <span className="tag" style={{background:  props.color }}>{ props.text }</span>
    )
}
