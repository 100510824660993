import { store } from '../../store'
import { plainToClass } from 'class-transformer'
import { ListUserAnswersDTO } from '../../dto/questionnaires'
import { validate } from 'class-validator'

export const listUserAnswers = async ( id: number ) => {

    let body, response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/2.0.0/cms/questionnaires/${ id }/user-answers`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'GET'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'LIST_QUESTIONNAIRE_USER_ANSWERS_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'LIST_QUESTIONNAIRE_USER_ANSWERS_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'LIST_QUESTIONNAIRE_USER_ANSWERS_UNEXPECTEDRESPONSE' } )
        return

    }

    try {

        body = plainToClass( ListUserAnswersDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'LIST_QUESTIONNAIRE_USER_ANSWERS_INVALIDJSON' } )
        return

    }

    if ( ( await validate( body ) ).length > 0 ) {

        store.dispatch( { type: 'LIST_QUESTIONNAIRE_USER_ANSWERS_INVALIDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'LIST_QUESTIONNAIRE_USER_ANSWERS_OK', questions: body.questions } )

}