import { LoaderFunctionArgs } from 'react-router-dom'
import { getPoliticians, getPoliticianTitles } from '../../action/politician'
import { store } from '../../store'
import { listTags } from '../../action/tags'

export const eventsNewLoader = async ( args: LoaderFunctionArgs ) => {

    listTags()
    getPoliticians()
    getPoliticianTitles()

    store.dispatch( { type: `EVENTS_CLEAR_FORM` } )
    store.dispatch( { type: `EVENT_SET_POLITICIANS`, politicians: [] } )

}
