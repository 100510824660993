import { createFormattedDateTimeString } from '../../helper'
import './user-activity.scss'

interface UserActivityProps {

    action: string
    time: Date
    description: string

}

export const UserActivity = ( props: UserActivityProps ) => {

    return (
        <div className="user-activity">

            <div className="user-activity__description">

                <span className="action-name">{ props.action }</span>
                
                <span className="action-name"> - </span>

                <span className="description">{ props.description }</span>

            </div>

            <div className="user-activity__date">

                { createFormattedDateTimeString( props.time.getTime() / 1000 ) }

            </div>

        </div>
    )

}
