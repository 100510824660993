import { MediaLibraryFile, QuizAnswer, QuizQuestion as QuizQuestionEntity } from '../../entity'
import './quiz-question.scss'
import { Dropdown } from '../dropdown'
import { useDispatch, useSelector } from 'react-redux'
import { TextField } from '../field'
import { TagSelector } from '../tag-selector'
import { AddImage } from '../add-image'
import { useState } from 'react'
import { MediaLibrary } from '../media-library'
import { ImageEditor } from '../image-editor/image-editor'
import { DescriptionTextArea } from '../textarea/description'
import { State } from '../../state'
import { TestResultSelector } from '../test-result-selector'

interface LessonQuizQuestionProps {

    question: QuizQuestionEntity

}

export const QuizQuestion = ( props: LessonQuizQuestionProps ) => {

    const dispatch = useDispatch()
    const { type } = useSelector( ( state: State ) => state.quizes )
    const [ showDescription, setShowDescription ] = useState( props.question.description !== null )
    const [ showMediaLibrary, setShowMediaLibrary ] = useState( false )
    const [ showImageEditor, setShowImageEditor ] = useState( false )
    const [ selectedAnswer, setSelectedAnswer ] = useState( null as QuizAnswer | null )
    const [ file, setFile ] = useState( null as MediaLibraryFile | null )

    const [ open, setOpen ] = useState( true )

    const u = ( field: string, value: any ) => {

        dispatch( { type: `UPDATE_QUIZ_QUIZ_QUESTION`, id: props.question.id, field, value } )

    }

    const uA = ( id: any, field: string, value: any ) => {

        dispatch( { type: `UPDATE_QUIZ_QUIZ_ANSWER`, questionId: props.question.id, id, field, value } )

    }

    const onDelete = () => {

        dispatch( { type: `DELETE_QUIZ_QUIZ_QUESTION`, id: props.question.id } )

    }

    const onAddQuestionImage = () => {

        setSelectedAnswer( null )
        setShowMediaLibrary( true )

    }

    const onDeleteQuestionImage = () => {

        u( `image`, null )

    }

    const onAddImage = ( answer: QuizAnswer ) => {

        setSelectedAnswer( answer )
        setShowMediaLibrary( true )

    }

    const onDeleteImage = ( answer: QuizAnswer ) => {

        uA( answer.id, `image`, null )

    }

    return (

        <div className={ `quiz-question ${ open ? `quiz-question--open` : `` } card-div` }>

            { open ? (

                <div className="form">

                    { props.question.type === 1 ? (

                        <div className="question-image">

                            <AddImage imageUrl={ props.question.image === null ? `` : props.question.image.url! } text="Kép hozzáadása a kvízhez" width="250px" height="141px" onAddImage={ onAddQuestionImage } onDeleteImage={ onDeleteQuestionImage } />

                        </div>

                    ) : null }

                    <div className="caption caption--small caption--no-margin">Kérdés</div>

                    <div className="shared-field">

                        <TextField field={ { value: props.question.title, error: `` } } onChange={ e => u( `title`, e.target.value ) } />
                        <Dropdown value={ props.question.type } items={ type === `quiz` ? [ { id: 0, value: `Kvíz` }, { id: 1, value: `Kvíz + kép` }, { id: 2, value: `Kvíz + videó` }, { id: 3, value: `Képválasztó` } ] : [ { id: 0, value: `Teszt` }, { id: 1, value: `Teszt + kép` }, { id: 3, value: `Képválasztó` } ] } onChange={ ( id: number ) => u( `type`, id ) } compact={ false } />

                    </div>

                    { props.question.type === 2 ? (

                        <div className="question-video-url">

                            <div className="caption caption--small">Kérdés videó URL-je</div>
                            <TextField field={ { value: props.question.videoUrl, error: `` } } onChange={ e => u( `videoUrl`, e.target.value ) } />

                        </div>

                    ) : null }

                    { showDescription ? (

                        <div className="question-description">

                            <div className="caption caption--small">Kérdés leírásásnak megadása</div>
                            <DescriptionTextArea field={ { value: props.question.description, error: `` } } onChange={ html => u( `description`, html ) } />

                        </div>

                    ) : null }

                    { [ 0, 1, 2 ].includes( props.question.type ) ? (

                        <div className="answers answers--text">

                            { props.question.answers.map( ( answer, index ) => (

                                <div className="quiz-question">

                                    <div className="question-header">

                                        <div className="caption caption--small">{ index + 1 }. Válasz</div>
                                        { type === `quiz` ? (

                                            <button className={ `button button--is-solution button--ghost ${ answer.isSolution ? `button--is-solution--selected` : `` }` } onClick={ () => dispatch( { type: `UPDATE_QUIZ_QUIZ_SOLUTION`, questionId: props.question.id, id: answer.id } ) }>Ez a helyes válasz</button>

                                        ) : null }

                                    </div>

                                    <TextField field={ { value: answer.title, error: `` } } onChange={ e => uA( answer.id, `title`, e.target.value ) } />

                                    { type === `quiz` ? (

                                        <TagSelector tags={ answer.tags } compact={ true } onTagAdd={ tag => dispatch( { type: `QUIZ_QUIZ_ANSWER_ADD_TAG`, questionId: props.question.id, id: answer.id, tag } ) } onTagDelete={ tag => dispatch( { type: `QUIZ_QUIZ_ANSWER_REMOVE_TAG`, questionId: props.question.id, id: answer.id, tag } ) } />

                                    ) : (

                                        <TestResultSelector results={ answer.results! } compact={ true } onResultAdd={ result => dispatch( { type: `QUIZ_ANSWER_ADD_RESULT`, questionId: props.question.id, id: answer.id, result } ) } onResultDelete={ result => dispatch( { type: `QUIZ_ANSWER_REMOVE_RESULT`, questionId: props.question.id, id: answer.id, result } ) } />

                                    ) }

                                </div>

                            ) ) }

                        </div>

                    ) : null }

                    { props.question.type === 3 ? (

                        <div className="answers answers--image">

                            { props.question.answers.map( ( answer, index ) => (

                                <div className="quiz-question">

                                    <AddImage imageUrl={ answer.image ? answer.image.url! : `` } text="Kép hozzáadása" width="250px" height="141px" onAddImage={ () => onAddImage( answer ) } onDeleteImage={ () => onDeleteImage( answer ) } />

                                    <div className="answer-info">

                                        { type === `quiz` ? (

                                            <button className={ `button button--is-solution button--ghost ${ answer.isSolution ? `button--is-solution--selected` : `` }` } onClick={ () => dispatch( { type: `UPDATE_QUIZ_QUIZ_SOLUTION`, questionId: props.question.id, id: answer.id } ) }>Ez a helyes válasz</button>

                                        ) : null }

                                        { type === `quiz` ? (

                                            <TagSelector tags={ answer.tags } compact={ true } onTagAdd={ tag => dispatch( { type: `QUIZ_QUIZ_ANSWER_ADD_TAG`, questionId: props.question.id, id: answer.id, tag } ) } onTagDelete={ tag => dispatch( { type: `QUIZ_QUIZ_ANSWER_REMOVE_TAG`, questionId: props.question.id, id: answer.id, tag } ) } />

                                        ) : (

                                            <TestResultSelector results={ answer.results! } compact={ true } onResultAdd={ result => dispatch( { type: `QUIZ_ANSWER_ADD_RESULT`, questionId: props.question.id, id: answer.id, result } ) } onResultDelete={ result => dispatch( { type: `QUIZ_ANSWER_REMOVE_RESULT`, questionId: props.question.id, id: answer.id, result } ) } />

                                        ) }

                                    </div>

                                </div>

                            ) ) }

                        </div>

                    ) : null }

                    <div className="question-footer">

                        { showDescription ? null : (

                            <button className="button button--ghost" style={{ marginRight: 20 }} onClick={ () => setShowDescription( true ) }>Leírás hozzáadása</button>

                        ) }

                        <button className="button button--warning" onClick={ onDelete }>Törlés</button>

                        <button className="button button--expand" onClick={ () => setOpen( false ) }><img alt="" src="/image/chevron-left.svg" /></button>

                    </div>

                </div>

            ) : (

                <div className="quiz-question__header">

                    <h2 className="title-text-h2">{ props.question.title }</h2>
                    <button className="button button--expand" onClick={ () => setOpen( true ) }><img alt="" src="/image/chevron-left.svg" /></button>

                </div>

            ) }



            { showMediaLibrary ? (

                <MediaLibrary onMediaSelected={ ( file: MediaLibraryFile ) => {

                    setFile( file )
                    setShowMediaLibrary( false )
                    setShowImageEditor( true )

                } } onClose={ () => setShowMediaLibrary( false ) } />

            ) : null }

            { showImageEditor ? (

                <ImageEditor mediaLibraryFile={ file! } onClose={ () => setShowImageEditor( false ) } onReady={ file => {

                    if ( selectedAnswer !== null ) {
                        uA( selectedAnswer.id, `image`, file )
                    } else {
                        u( `image`, file )
                    }
                    setShowImageEditor( false )

                } } aspectRatio={ { name: `Válasz képe`, ratio: 16 / 9 } } />

            ) : null }

        </div>

    )

}