import { Expose } from 'class-transformer'
import { IsDefined } from 'class-validator'
import { Quest } from '../../entity'

export class SaveQuestDTO {

    @Expose()
    @IsDefined()
    quest!: Quest

}
