import { MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Politician } from '../../../entity'
import { State } from '../../../state'
import './politician-element.scss'

interface  PoliticianElementProps {
    politicianElement: Politician
}

export const PoliticianElement = ( props: PoliticianElementProps ) => {

    const dispatch = useDispatch()
    const selectedPolitician = useSelector( ( state: State ) => state.politicians.selectedPolitician )

    const onItemSelectClick = async ( event: MouseEvent ) => {
        event.preventDefault()
        dispatch ( { type: 'GET_POLITICIANS_SELECTED_POLITICIAN', selectedPolitician: props.politicianElement } )
    }

    return (

        <li key={ props.politicianElement.firstName+props.politicianElement.lastName } className={ `politicians-table-list__news-category-element ${ selectedPolitician == props.politicianElement ? `active` : `` }` } onClick={ onItemSelectClick }>
            <span className="label-text name"> { props.politicianElement.lastName } { props.politicianElement.firstName }</span>
        </li >
    )

}
