import { Expose } from 'class-transformer'
import { IsDefined } from 'class-validator'
import { Prize, User } from '../../entity'

export class ListLeagueUsersDTO {

    @Expose()
    @IsDefined()
    advancedUsers!: User[]

    @Expose()
    @IsDefined()
    advancingUsers!: User[]

    @Expose()
    @IsDefined()
    notAdvancingUsers!: User[]

}

