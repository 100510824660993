import { Expose, Type } from 'class-transformer'
import { ValidateNested } from 'class-validator'
import { Questionnaire } from '../../entity'

export class GetQuestionnaireDTO {

    @Expose()
    @ValidateNested()
    @Type( () => Questionnaire )
    questionnaire!: Questionnaire

}

