import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetQuotesStatisticsDTO, GetStatisticsDTO } from '../../dto/statistics'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { GET_QUOTES_STATISTICS_ACTIONS } from './statistics.constants'

export const getQuotesStatistics = async ( startDate?: Date, endDate?: Date ) => {

    store.dispatch( { type: GET_QUOTES_STATISTICS_ACTIONS.GET_QUOTES_STATISTICS_STARTED } )

    let start = null
    if(startDate != undefined ){
        start = startDate.getTime() / 1000
    }

    let end = null
    if(endDate != undefined ){
        end = endDate.getTime() / 1000
    }

    const promise = createCall(`cms/statistics/quotes?start=`+start+`&end=`+end, BASE_METHODS.GET)
    promise.then(async response => {

        let responseBody: GetQuotesStatisticsDTO

        try {
            responseBody = plainToClass( GetQuotesStatisticsDTO, JSON.parse( await response.text() ) )
        } catch ( exception ) {
            store.dispatch( { type: GET_QUOTES_STATISTICS_ACTIONS.GET_QUOTES_STATISTICS_INVALID_JSON } )
            return
        }
    
        if ( ( await validate( responseBody ) ).length > 0 ) {
            store.dispatch( { type: GET_QUOTES_STATISTICS_ACTIONS.GET_QUOTES_STATISTICS_INVALID_RESPONSE } )
            return
        }
    
        store.dispatch( { type: GET_QUOTES_STATISTICS_ACTIONS.GET_QUOTES_STATISTICS_OK, 
            quotePoliticianSwipedCount: responseBody.quotePoliticianSwipedCount, 
            quotePoliticianSwipedXAxis: responseBody.quotePoliticianSwipedXAxis,
            quotePoliticianSwipedData: responseBody.quotePoliticianSwipedData,
        } )

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })
   
}