import { LoaderFunctionArgs } from 'react-router-dom'
import { listQuizes, listQuizTopics } from '../../action/quizes'
import {store} from "../../store";

export const quizesListLoader = async ( args: LoaderFunctionArgs ) => {

    await listQuizTopics()
    await listQuizes()
    store.dispatch( { type: `SET_QUIZ_KEYWORD`, keyword: `` } )

}
