export const authority = ( position: number ): string => {

    if ( position === undefined ) {

        return ``

    }

    switch ( position ) {

        case 1:
            return `Belső`
        case 2:
            return `Külső`

        default:
            return ``

    }

}

export const position = ( position: number ): string => {

    if ( position === undefined ) {

        return ``

    }

    switch ( position ) {

        case 1:
            return `Párttag`
        case 2:
            return `Aktivista`

        default:
            return ``

    }

}

export const positions = () => {

    const positions = []
    for ( let i = 1; i < 3; i++ ) {

        positions.push( { id: i, value: position( i ) } )

    }

    return positions

}
