import { Event } from '../../../entity'
import { createFormattedFullDateString } from '../../../helper'
import './event-card.scss'

interface EventCardProps {

    eventElement: Event | null

}

export const EventCard = ( props: EventCardProps ) => {

    return (
        <div className="event-card">

            { props.eventElement === null ? (

                <img className="event-image image-loading" alt="Eseményhez tartozó kép" />

            ) : (

                <img className="event-image" src={ `${ props.eventElement == null ? null : `${ props.eventElement.imageFile == null ? "/image/dk-logo-16_9.svg" : props.eventElement.imageFile.url }` }` } alt="Eseményhez tartozó kép" />

            ) }

            { props.eventElement === null ? (

                <h3 className="title-text-h3 title title-text-loading"></h3>

            ) : (

                <h3 className="title-text-h3 title">

                    { props.eventElement?.title }

                </h3>

            ) }

            { props.eventElement === null ? (

                <div className="bottom-div">

                    <span className="text-loading"></span>

                </div>

            ) : (

                <div className="bottom-div">

                    <div className="time">

                        <img src="/image/time.png" />
                        <div>
                            <div>{ createFormattedFullDateString( props.eventElement.startDate ) }-tól</div>
                            <div>{ props.eventElement.endDate !== null ? `${ createFormattedFullDateString( props.eventElement.endDate ) }-ig` : `` }</div>
                        </div>

                    </div>

                    <div className="location">

                        <img src="/image/location.png" />
                        <div>{ props.eventElement.location }</div>

                    </div>

                </div>

            ) }

        </div>

    )

}
