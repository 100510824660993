import './experience-points.page.scss'
import { NumberField, TextField } from "../../component/field"
import { useSelector } from 'react-redux'
import { State } from '../../state'
import { listDefaultExperiencePoints, updateDefaultExperiencePoint } from '../../action/gamification'
import { useState } from "react"

export const ExperiencePointsPage = () => {

    const { defaultExperiencePoints } = useSelector( ( state: State ) => state.gamification )
    const [ form, setForm ] = useState( {
        name: defaultExperiencePoints.find( dep => dep.name === `name` )?.experience.toString() ?? `0`,
        user_low_percentage: defaultExperiencePoints.find( dep => dep.name === `user_low_percentage` )?.experience.toString() ?? `0`,
        personaldata: defaultExperiencePoints.find( dep => dep.name === `personaldata` )?.experience.toString() ?? `0`,
        address: defaultExperiencePoints.find( dep => dep.name === `address` )?.experience.toString() ?? `0`,
        alldata: defaultExperiencePoints.find( dep => dep.name === `alldata` )?.experience.toString() ?? `0`,

        newsread: defaultExperiencePoints.find( dep => dep.name === `newsread` )?.experience.toString() ?? `0`,
        news_low_percentage: defaultExperiencePoints.find( dep => dep.name === `news_low_percentage` )?.experience.toString() ?? `0`,
        newsquiz: defaultExperiencePoints.find( dep => dep.name === `newsquiz` )?.experience.toString() ?? `0`,

        politicianread: defaultExperiencePoints.find( dep => dep.name === `politicianread` )?.experience.toString() ?? `0`,
        politician_low_percentage: defaultExperiencePoints.find( dep => dep.name === `politician_low_percentage` )?.experience.toString() ?? `0`,
        politicianquiz: defaultExperiencePoints.find( dep => dep.name === `politicianquiz` )?.experience.toString() ?? `0`,

        questionairecompleted: defaultExperiencePoints.find( dep => dep.name === `questionairecompleted` )?.experience.toString() ?? `0`,
        questionnaire_low_percentage: defaultExperiencePoints.find( dep => dep.name === `questionnaire_low_percentage` )?.experience.toString() ?? `0`,

        eventuploaded: defaultExperiencePoints.find( dep => dep.name === `eventuploaded` )?.experience.toString() ?? `0`,
        event_low_percentage: defaultExperiencePoints.find( dep => dep.name === `event_low_percentage` )?.experience.toString() ?? `0`,

        quotescompleted: defaultExperiencePoints.find( dep => dep.name === `quotescompleted` )?.experience.toString() ?? `0`,

        courseanswer: defaultExperiencePoints.find( dep => dep.name === `courseanswer` )?.experience.toString() ?? `0`,
        course_low_percentage: defaultExperiencePoints.find( dep => dep.name === `course_low_percentage` )?.experience.toString() ?? `0`,
        courseonestar: defaultExperiencePoints.find( dep => dep.name === `courseonestar` )?.experience.toString() ?? `0`,
        coursetwostar: defaultExperiencePoints.find( dep => dep.name === `coursetwostar` )?.experience.toString() ?? `0`,
        coursethreestar: defaultExperiencePoints.find( dep => dep.name === `coursethreestar` )?.experience.toString() ?? `0`,

        quizanswer: defaultExperiencePoints.find( dep => dep.name === `quizanswer` )?.experience.toString() ?? `0`,
        quiz_low_percentage: defaultExperiencePoints.find( dep => dep.name === `quiz_low_percentage` )?.experience.toString() ?? `0`,
        quizexperience: defaultExperiencePoints.find( dep => dep.name === `quizexperience` )?.experience.toString() ?? `0`,
        testexperience: defaultExperiencePoints.find( dep => dep.name === `testexperience` )?.experience.toString() ?? `0`
    } )

    const onFieldChange = async ( field: string, value: any ) => {

        setForm( { ...form, [ field ]: value } )
        if ( !isNaN( parseInt( value ) ) ) {

            await updateDefaultExperiencePoint( field, value )
            await listDefaultExperiencePoints()

        }

    }

    return (

        <main className="page page--experience-points">

            <div className="main-div">

                <div className="navigation-title">

                    <h1 className="title-text-h1">Tapasztalati pontok</h1>

                </div>

                <div className="card-div">

                    <div className="caption">Regisztrációs Tapasztalati pontok megadása</div>

                    <div className="row-field">

                        <label>Név megadása *</label>
                        <NumberField field={{ value: form.name, error: `` }} onChange={ e => onFieldChange( `name`, e.target.value ) } />
                        <img src="/image/exp.svg" />

                        <label className="percentage">egy hét után csak %-ot</label>
                        <NumberField field={{ value: form.user_low_percentage, error: `` }} onChange={ e => onFieldChange( `user_low_percentage`, parseInt( e.target.value ) ) } max={ 100 } min={ 1 } />


                    </div>

                    <div className="row-field">

                        <label>Személyes adatok *</label>
                        <NumberField field={{ value: form.personaldata, error: `` }} onChange={ e => onFieldChange( `personaldata`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                    </div>

                    <div className="row-field">

                        <label>Lakóhely adatok *</label>
                        <NumberField field={{ value: form.address, error: `` }} onChange={ e => onFieldChange( `address`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                    </div>

                    <div className="row-field">

                        <label>Minden adat kitöltésre került *</label>
                        <NumberField field={{ value: form.alldata, error: `` }} onChange={ e => onFieldChange( `alldata`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                    </div>

                </div>

                <div className="card-div">

                    <div className="caption">Hírek modul Tapasztalati pontok megadása</div>

                    <div className="row-field">

                        <label>Hír végigolvasása *</label>
                        <NumberField field={{ value: form.newsread, error: `` }} onChange={ e => onFieldChange( `newsread`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                        <label className="percentage">egy hét után csak %-ot</label>
                        <NumberField field={{ value: form.news_low_percentage, error: `` }} onChange={ e => onFieldChange( `news_low_percentage`, parseInt( e.target.value ) ) } max={ 100 } min={ 1 } />

                    </div>

                    <div className="row-field">

                        <label>Hír kvíz helyes kitöltése *</label>
                        <NumberField field={{ value: form.newsquiz, error: `` }} onChange={ e => onFieldChange( `newsquiz`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                    </div>

                </div>

                <div className="card-div">

                    <div className="caption">Politikus modul Tapasztalati pontok megadása</div>

                    <div className="row-field">

                        <label>Politikus legörgetése *</label>
                        <NumberField field={{ value: form.politicianread, error: `` }} onChange={ e => onFieldChange( `politicianread`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                        <label className="percentage">egy hét után csak %-ot</label>
                        <NumberField field={{ value: form.politician_low_percentage, error: `` }} onChange={ e => onFieldChange( `politician_low_percentage`, parseInt( e.target.value ) ) } max={ 100 } min={ 1 } />

                    </div>

                    <div className="row-field">

                        <label>Politikus kvíz helyes kitöltése *</label>
                        <NumberField field={{ value: form.politicianquiz, error: `` }} onChange={ e => onFieldChange( `politicianquiz`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                    </div>

                </div>

                <div className="card-div">

                    <div className="caption">Kérdőívek modul Tapasztalati pontok megadása</div>

                    <div className="row-field">

                        <label>Kitöltött egy kérdőívet *</label>
                        <NumberField field={{ value: form.questionairecompleted, error: `` }} onChange={ e => onFieldChange( `questionairecompleted`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                        <label className="percentage">egy hét után csak %-ot</label>
                        <NumberField field={{ value: form.questionnaire_low_percentage, error: `` }} onChange={ e => onFieldChange( `questionnaire_low_percentage`, parseInt( e.target.value ) ) } max={ 100 } min={ 1 } />

                    </div>

                </div>

                <div className="card-div">

                    <div className="caption">Események modul Tapasztalati pontok megadása</div>

                    <div className="row-field">

                        <label>Képfeltöltése egy eseményhez *</label>
                        <NumberField field={{ value: form.eventuploaded, error: `` }} onChange={ e => onFieldChange( `eventuploaded`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                        <label className="percentage">egy hét után csak %-ot</label>
                        <NumberField field={{ value: form.event_low_percentage, error: `` }} onChange={ e => onFieldChange( `event_low_percentage`, parseInt( e.target.value ) ) } max={ 100 } min={ 1 } />

                    </div>

                </div>

                <div className="card-div">

                    <div className="caption">Véleményvezér modul Tapasztalati pontok megadása</div>

                    <div className="row-field">

                        <label>Elhúzta a napi 3 kártyát *</label>
                        <NumberField field={{ value: form.quotescompleted, error: `` }} onChange={ e => onFieldChange( `quotescompleted`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                    </div>

                </div>

                <div className="card-div">

                    <div className="caption">Képzések modul Tapasztalati pontok megadása</div>

                    <div className="row-field">

                        <label>Tapasztalati pont a jó válaszért</label>
                        <NumberField field={{ value: form.courseanswer, error: `` }} onChange={ e => onFieldChange( `courseanswer`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                        <label className="percentage">egy hét után csak %-ot</label>
                        <NumberField field={{ value: form.course_low_percentage, error: `` }} onChange={ e => onFieldChange( `course_low_percentage`, parseInt( e.target.value ) ) } max={ 100 } min={ 1 } />

                    </div>

                    <div className="row-field">

                        <label>Lecke 1 csillag</label>
                        <NumberField field={{ value: form.courseonestar, error: `` }} onChange={ e => onFieldChange( `courseonestar`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                    </div>

                    <div className="row-field">

                        <label>Lecke 2 csillag</label>
                        <NumberField field={{ value: form.coursetwostar, error: `` }} onChange={ e => onFieldChange( `coursetwostar`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                    </div>

                    <div className="row-field">

                        <label>Lecke 3 csillag</label>
                        <NumberField field={{ value: form.coursethreestar, error: `` }} onChange={ e => onFieldChange( `coursethreestar`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                    </div>

                </div>

                <div className="card-div">

                    <div className="caption">Kvízek és tesztek modul Tapasztalati pontok megadása</div>

                    <div className="row-field">

                        <label>Tapasztalati pont a jó válaszért</label>
                        <NumberField field={{ value: form.quizanswer, error: `` }} onChange={ e => onFieldChange( `quizanswer`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                        <label className="percentage">egy hét után csak %-ot</label>
                        <NumberField field={{ value: form.quiz_low_percentage, error: `` }} onChange={ e => onFieldChange( `quiz_low_percentage`, parseInt( e.target.value ) ) } max={ 100 } min={ 1 } />

                    </div>

                    <div className="row-field">

                        <label>Tapasztalati pont a kvízért</label>
                        <NumberField field={{ value: form.quizexperience, error: `` }} onChange={ e => onFieldChange( `quizexperience`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                    </div>

                    <div className="row-field">

                        <label>Tapasztalati pont a tesztért</label>
                        <NumberField field={{ value: form.testexperience, error: `` }} onChange={ e => onFieldChange( `testexperience`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                    </div>

                </div>

            </div>

        </main>

    )

}
