import { Expose, Type } from 'class-transformer'
import { ValidateNested } from 'class-validator'
import { PoliticianQuestion } from '../../entity'

export class GetPoliticianQuestionDTO {

    @Expose()
    @ValidateNested()
    @Type( () => PoliticianQuestion )
    question!: PoliticianQuestion

}