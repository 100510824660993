import { store } from '../../store'

export const forgotPassword = async ( email: string ) => {

    let response: Response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/users/forgot-password?email=${ email }`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'get'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'FORGOT_PASSWORD_TIMEOUT' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'FORGOT_PASSWORD_UNEXPECTEDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'FORGOT_PASSWORD_OK' } )

}
