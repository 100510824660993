import { POLITICIANS_ACTIONS } from '../../action/politician/politicians.constants'
import { PoliticiansState } from '../../state/politicians'

const initialPoliticiansState: PoliticiansState = {
    politicians: undefined,
    selectedPolitician: null,
    search: "",
    order: 'ASC',
    questions: undefined,
    questionsSearch: "",
    politicianTitles: undefined,
    politiciansNotEmpty: false
}

export const politiciansReducer = ( state: PoliticiansState = initialPoliticiansState, action: any ): PoliticiansState => {
    switch ( action.type ) {
        case POLITICIANS_ACTIONS.GET_POLITICIANS_OK:
            return { ...state, politicians: action.politicians, selectedPolitician: action.politicians[ 0 ] }
        case POLITICIANS_ACTIONS.GET_POLITICIANS_SEARCH:
            return { ...state, search: action.search }
        case POLITICIANS_ACTIONS.GET_POLITICIANS_ORDER:
            return { ...state, order: state.order == 'ASC' ? 'DESC' : 'ASC' }
        case POLITICIANS_ACTIONS.GET_POLITICIANS_INVALID_JSON:
            return { ...state, politicians: null }
        case POLITICIANS_ACTIONS.GET_POLITICIANS_INVALID_RESPONSE:
            return { ...state, politicians: null }
        case POLITICIANS_ACTIONS.GET_POLITICIANS_SELECTED_POLITICIAN:
            return { ...state, selectedPolitician: action.selectedPolitician }
        case POLITICIANS_ACTIONS.GET_POLITICIAN_QUESTIONS_OK:
            return { ...state, questions: action.questions }
        case POLITICIANS_ACTIONS.GET_POLITICIAN_QUESTION_SEARCH:
            return { ...state, questionsSearch: action.search }
        case POLITICIANS_ACTIONS.GET_POLITICIAN_QUESTIONS_INVALID_JSON:
            return { ...state, questions: null }
        case POLITICIANS_ACTIONS.GET_POLITICIAN_QUESTIONS_INVALID_RESPONSE:
            return { ...state, questions: null }
        case POLITICIANS_ACTIONS.GET_POLITICIAN_TITLES_OK:
            return { ...state, politicianTitles: action.politicianTitles }
        case POLITICIANS_ACTIONS.GET_POLITICIAN_TITLES_INVALID_JSON:
            return { ...state, politicianTitles: null }
        case POLITICIANS_ACTIONS.GET_POLITICIAN_TITLES_INVALID_RESPONSE:
            return { ...state, politicianTitles: null }
        case POLITICIANS_ACTIONS.GET_POLITICIAN_TITLES_POLITICIAN_NOT_EMPTY:
            return { ...state, politiciansNotEmpty: true }
        case POLITICIANS_ACTIONS.GET_POLITICIAN_TITLES_POLITICIAN_NOT_EMPTY_CLEAR:
            return { ...state, politiciansNotEmpty: false }
        default:
            return state
    }
}
