import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { BASE_ACTIONS } from '../../action/base/base.constants'
import { Header, Sidebar } from '../../component'
import { MessageModal } from '../../component/modal'
import { State } from '../../state'
import './default.layout.scss'
import React, {useState} from "react";
import { ConfirmMessage } from '../../component/confirm-message'
import { ProfileModal } from '../../component/profile-modal'

interface DefaultLayoutProps { }

export const DefaultLayout = ( props: DefaultLayoutProps ) => {

    const dispatch = useDispatch()
    const { activeConfirmMessage, activeTitle, showProfileModal } = useSelector( ( state: State ) => state.session )

    const { errorMessage, errorTitle } = useSelector( ( state: State ) => state.base )
    const [ mobileMenuOpen, setMobileMenuOpen ] = useState( false )

    return (
        <div className={ `layout layout--default ${ mobileMenuOpen ? `layout--mobile-menu-open` : `` }` }>
            <Header onMobileMenuToggle={ open => setMobileMenuOpen( open ) } />
            <Sidebar onMobileMenuToggle={ open => setMobileMenuOpen( open ) }/>
            <Outlet />

            { showProfileModal ? <ProfileModal onModalClose={ () => dispatch( { type: `CLOSE_PROFILE_MODAL` } ) } /> : null }

            { errorMessage ? <MessageModal title={ errorTitle ?? "" } message={ errorMessage } onCloseClick={ () => { dispatch( { type: BASE_ACTIONS.CLEAR_ERROR } ) } }/> : "" }

            { activeConfirmMessage !== null ? <ConfirmMessage message={ activeConfirmMessage } title={ activeTitle } onClose={ () => dispatch( { type: `SET_ACTIVE_CONFIRM_MESSAGE`, message: null } ) } /> : null }

        </div>
    )

}
