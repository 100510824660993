import { store } from '../../store'

export const deleteCourse = async ( id: number ) => {

    let response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/courses/${ id }`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'DELETE'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'DELETE_COURSE_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'DELETE_COURSE_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'DELETE_COURSE_UNEXPECTEDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'DELETE_COURSE_OK' } )

}