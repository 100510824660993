export const GET_ALL_PUSH_ACTIONS = {
    GET_ALL_PUSH_INVALID_JSON: 'GET_ALL_PUSH_INVALID_JSON',
    GET_ALL_PUSH_INVALID_RESPONSE: 'GET_ALL_PUSH_INVALID_RESPONSE',
    GET_ALL_PUSH_OK: 'GET_ALL_PUSH_OK',
    GET_ALL_PUSH_CHANGE_PAGE: 'GET_ALL_PUSH_CHANGE_PAGE',
}

export const CREATE_PUSH_ACTIONS = {
    CREATE_PUSH_STARTED: 'CREATE_PUSH_STARTED',
    CREATE_PUSH_INVALID_JSON: 'CREATE_PUSH_INVALID_JSON',
    CREATE_PUSH_INVALID_RESPONSE: 'CREATE_PUSH_INVALID_RESPONSE',
    CREATE_PUSH_OK: 'CREATE_PUSH_OK',
    CREATE_PUSH_CHANGE_BODY: 'CREATE_PUSH_CHANGE_BODY',
    CREATE_PUSH_CHANGE_TITLE: 'CREATE_PUSH_CHANGE_TITLE',
    CREATE_PUSH_CHANGE_CONTENT_ACTION_CATEGORY_ID: 'CREATE_PUSH_CHANGE_CONTENT_ACTION_CATEGORY_ID',
    CREATE_PUSH_CHANGE_CONTENT_ACTION_ID: 'CREATE_PUSH_CHANGE_CONTENT_ACTION_ID',
    CREATE_PUSH_CHANGE_CONTENT_ACTION_CONTENT_ID: 'CREATE_PUSH_CHANGE_CONTENT_ACTION_CONTENT_ID',
    CREATE_PUSH_CHANGE_CONTENT_ACTION_URL: 'CREATE_PUSH_CHANGE_CONTENT_ACTION_URL',
    CREATE_PUSH_CHANGE_MEDIA_LIBRARY_FILE: 'CREATE_PUSH_CHANGE_MEDIA_LIBRARY_FILE',
    CREATE_PUSH_CHANGE_TIMING: 'CREATE_PUSH_CHANGE_TIMING',
    CREATE_PUSH_CHANGE_IMMEDIATE: 'CREATE_PUSH_CHANGE_IMMEDIATE',
    CREATE_PUSH_ADD_TARGET: 'CREATE_PUSH_ADD_TARGET',
    CREATE_PUSH_REMOVE_TARGET: 'CREATE_PUSH_REMOVE_TARGET',
    CREATE_PUSH_CHANGE_TARGET_TYPE: 'CREATE_PUSH_CHANGE_TARGET_TYPE',
    CREATE_PUSH_CHANGE_TARGET_SUB_TYPE: 'CREATE_PUSH_CHANGE_TARGET_SUB_TYPE',
    CREATE_PUSH_CHANGE_TARGET_SUB_SUB_TYPE: 'CREATE_PUSH_CHANGE_TARGET_SUB_SUB_TYPE',
    CREATE_PUSH_CLEAR: 'CREATE_PUSH_CLEAR',
    CREATE_PUSH_RELOAD_PUSH: 'CREATE_PUSH_RELOAD_PUSH',
}

export const GET_PUSH_SETTINGS_ACTIONS = {
    GET_PUSH_SETTINGS_INVALID_JSON: 'GET_PUSH_SETTINGS_INVALID_JSON',
    GET_PUSH_SETTINGS_INVALID_RESPONSE: 'GET_PUSH_SETTINGS_INVALID_RESPONSE',
    GET_PUSH_SETTINGS_OK: 'GET_PUSH_SETTINGS_OK',
}

export const CHANGE_PUSH_SETTINGS_ACTIONS = {
    CHANGE_PUSH_SETTINGS_STARTED: 'CHANGE_PUSH_SETTINGS_STARTED',
    CHANGE_PUSH_SETTINGS_INVALID_JSON: 'CHANGE_PUSH_SETTINGS_INVALID_JSON',
    CHANGE_PUSH_SETTINGS_INVALID_RESPONSE: 'CHANGE_PUSH_SETTINGS_INVALID_RESPONSE',
    CHANGE_PUSH_SETTINGS_OK: 'CHANGE_PUSH_SETTINGS_OK',
    CHANGE_PUSH_SETTINGS_CHANGE: 'CHANGE_PUSH_SETTINGS_CHANGE',
}
