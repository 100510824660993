import { useDispatch } from 'react-redux'
import { MenuButton } from '../../../component'
import { PushMessage } from '../../../entity'
import { CREATE_PUSH_ACTIONS } from '../../../action/push/push.constants'

interface PushMesageTableMenuButtonProps {
    pushMessageElement: PushMessage
}

export const PushMesageTableMenuButton = (props: PushMesageTableMenuButtonProps) => {
    const dispatch = useDispatch()

    const onMenuItemChange = async (itemId: any) => {
        switch (itemId) {
            default:
                dispatch({
                    type: CREATE_PUSH_ACTIONS.CREATE_PUSH_RELOAD_PUSH,
                    push: props.pushMessageElement,
                })
                break
        }
    }

    return (
        <>
            <MenuButton hasBackground={true} items={[{ id: 0, value: 'Visszatöltés', icon: 'reset.svg' }]} onChange={onMenuItemChange} />
        </>
    )
}

const onMenuItemChange = async (itemId: any) => {}
