import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetMonthQuotesDTO } from '../../dto/quotes'
import { store } from '../../store'

export const getMonthQuotes = async ( date: Date ) => {

    let body, response
    const year = date.getFullYear()
    const month = date.getMonth() + 1

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/quotes/month/${ year }/${ month }`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'get'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'GET_MONTH_QUOTES_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'GET_MONTH_QUOTES_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'GET_MONTH_QUOTES_UNEXPECTEDRESPONSE' } )
        return

    }

    try {

        body = plainToClass( GetMonthQuotesDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'GET_MONTH_QUOTES_INVALIDJSON' } )
        return

    }

    if ( ( await validate( body ) ).length > 0 ) {

        store.dispatch( { type: 'GET_MONTH_QUOTES_INVALIDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'GET_MONTH_QUOTES_OK', monthQuotes: body.monthQuotes } )

}
