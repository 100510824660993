import { Expose } from 'class-transformer'
import { IsDefined, IsNumber } from 'class-validator'

export class PushMessageTarget {

    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number 

    @Expose()
    @IsDefined()
    @IsNumber()
    type!: number 

    @Expose()
    @IsDefined()
    @IsNumber()
    subType!: number 

    @Expose()
    @IsDefined()
    @IsNumber()
    subSubType!: number 
   
}
