import { store } from '../../store'
import { plainToClass } from 'class-transformer'
import { ListVideoCategoriesDTO } from '../../dto/videos'
import { validate } from 'class-validator'

export const listVideoCategories = async () => {

    let body, response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/2.0.0/cms/videos/categories`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'GET'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'LIST_VIDEO_CATEGORIES_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'LIST_VIDEO_CATEGORIES_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'LIST_VIDEO_CATEGORIES_UNEXPECTEDRESPONSE' } )
        return

    }

    try {

        body = plainToClass( ListVideoCategoriesDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'LIST_VIDEO_CATEGORIES_INVALIDJSON' } )
        return

    }

    if ( ( await validate( body ) ).length > 0 ) {

        store.dispatch( { type: 'LIST_VIDEO_CATEGORIES_INVALIDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'LIST_VIDEO_CATEGORIES_OK', videoCategories: body.categories } )

}