import { store } from '../../store'
import { plainToClass } from 'class-transformer'
import { ListAchievementGroupsDTO } from '../../dto/achievements'
import { validate } from 'class-validator'

export const listAchievementGroups = async () => {

    let body, response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/achievement-groups`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'GET'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'LIST_ACHIEVEMENT_GROUPS_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'LIST_ACHIEVEMENT_GROUPS_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'LIST_ACHIEVEMENT_GROUPS_UNEXPECTEDRESPONSE' } )
        return

    }

    try {

        body = plainToClass( ListAchievementGroupsDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'LIST_ACHIEVEMENT_GROUPS_INVALIDJSON' } )
        return

    }

    if ( ( await validate( body ) ).length > 0 ) {

        store.dispatch( { type: 'LIST_ACHIEVEMENT_GROUPS_INVALIDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'LIST_ACHIEVEMENT_GROUPS_OK', achievementGroups: body.achievementGroups } )

}