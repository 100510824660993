import './settings.page.scss'
import { ApplicationVersionModal, ApplicationVersionsTable } from "../../component";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../state";

interface SettingsProps {
}

export const Settings = ( props: SettingsProps ) => {

    const dispatch = useDispatch()
    const { applicationVersion } = useSelector( ( state: State ) => state.applicationVersions )

    const closeModal = () => {

        dispatch( { type: `SELECT_APPLICATION_VERSION`, applicationVersionId: null } )

    }

    const createApplicationVersion = () => {

        dispatch( { type: `NEW_APPLICATION_VERSION` } )

    }

    return ( <main className="page page--settings">

            <div className="main-div">

                <div className="navigation-title">

                    <h1 className="title-text-h1">Beállítások</h1>

                </div>

                <div className="columns">

                    <div className="card-div">

                        <h2 className="title-text-h2">Adatvédelmi nyilatkozat</h2>
                        <a href="https://dkp.hu/adat/dkplusz.html">https://dkp.hu/adat/dkplusz.html</a>

                    </div>

                    <div className="card-div">

                        <h2 className="title-text-h2">Használati feltételek</h2>
                        <a href="https://dkp.hu/adat/dkplusz.html">https://dkp.hu/adat/dkplusz_hasznalati.html</a>

                    </div>

                </div>

                <div className="columns">

                    <div className="card-div">

                        <h2 className="title-text-h2">Felhasználó inaktiválása</h2>
                        <a href="https://dkp.hu/uploads/adatk_taj_20211113.pdf">https://dkp.hu/uploads/adatk_taj_20211113.pdf</a>

                    </div>

                </div>

                <div className="navigation-title" style={ { marginTop: 30 } }>

                    <h1 className="title-text-h1">Alkalmazás verziók</h1>

                </div>

                <div className="card-div">

                    <ApplicationVersionsTable />

                </div>

                <div className="button-container">

                    <button className="button button--primary" onClick={ e => createApplicationVersion() }>Létrehozás</button>

                </div>

            </div>

            { applicationVersion !== null ? (

                <ApplicationVersionModal onModalClose={ () => closeModal() } />

            ) : null }

        </main> )
}
