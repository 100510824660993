import { useState } from 'react'
import './dropdown.scss'

interface DropdownProps {
    disabled?: boolean
    value: any
    items: DropdownItem[]
    onChange: any
    compact: boolean
}

interface DropdownItem {
    id: number
    value: string
    color?: string
}

export const Dropdown = ( props: DropdownProps ) => {

    const [ showItems, setShowItems ] = useState( false )

    const onDropDownClick = () => {
        setShowItems( !showItems )
    }

    const onSelectItem = ( item: DropdownItem ) => {
        setShowItems( false )
        props.onChange( item.id )
    }

    const getValue = () => {
        const valueItem = props.items.find( x => x.id === props.value )
        return valueItem != undefined ? valueItem.value : ( props.items.length > 0 ? props.items[ 0 ].value : `` )
    }

    const getColor = () => {
        const valueItem = props.items.find( x => x.id === props.value )
        return valueItem != undefined ? valueItem.color : ( props.items.length > 0 ? props.items[ 0 ].color : `` )
    }

    return (
        <div className={ `select-box--box ${ props.compact ? "" : "select-box--box-full" } ${ props.disabled === true ? `select-box--box-disabled` : `` }` } >

            <div className={ `select-box--container ${ props.compact ? "" : "select-box--container-full" }` }>

                <div style={ { color: getColor() } } className={ `select-box--selected-item ${ props.compact ? "" : "select-box--selected-item-full" }` } onClick={ onDropDownClick } id={ "dropdown" }>
                    { getValue() }
                </div>

                <div className={ `select-box--arrow ${ props.compact ? "" : "select-box--arrow-full" }` } onClick={ onDropDownClick }>
                    <span id={ "dropdown" } className="material-symbols-outlined select-arrow"> { showItems ? "expand_less" : "expand_more" }</span>
                </div>

                <div style={ { display: showItems ? "inline" : "none" } } className={ `select-box--items ${ props.compact ? "" : "select-box--items-full" }` } >
                    { props.items.map( item => (
                        <div key={ item.id + "dropdown" } onClick={ () =>  onSelectItem( item )} className={ "" }>
                            <span id={ "dropdown" } style={ { color: item.color } } className={ props.value === item.id ? "select-box--item-selected" : "" }> { item != null ? item.value : "" }</span>
                        </div>
                    ) ) }
                </div>

            </div>
        </div>
    )

}
