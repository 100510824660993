import { LoaderFunctionArgs } from 'react-router-dom'
import { getQuestionnaire } from '../../action/questionnaires/get-questionnaire.action'
import { listTags } from '../../action/tags'
import { listDefaultExperiencePoints } from '../../action/gamification'

export const createQuestionnaireLoader = async ( args: LoaderFunctionArgs ) => {

    await listDefaultExperiencePoints()
    await listTags()
    if(args.params.id != undefined && args.params.id != null ){
        await getQuestionnaire( parseInt( args.params.id as string ) )
    }

}
