import './news-statistics.tab.scss'
import Chart from 'react-apexcharts'
import { useSelector } from 'react-redux'
import { State } from '../../../state'

interface QuestionnairesStatisticsTabProps { }

export const QuestionnairesStatisticsTab = ( props: QuestionnairesStatisticsTabProps ) => {

    const { newQuestionnaireCount, newQuestionnaireXAxis, newQuestionnaireData, sendQuestionnaireCount, sendQuestionnaireXAxis, sendQuestionnaireData } = useSelector( ( state: State ) => state.statistics.questionnairesStats )

    const newQuestionnaireOptions = {
        chart: {
            id: 'line-chart',    
        },
        dataLabels: {
            enabled: true
        },
        stroke: {
            curve: "smooth" as "smooth",
        },
        colors: ['#0267AA'],
        xaxis: {
          categories: newQuestionnaireXAxis
        }
    }

    const newQuestionnaireSeries = [{
        name: 'Új aktív kérdőív',
        data: newQuestionnaireData
    }]


    const sendQuestionnaireOptions = {
        chart: {
          id: 'bar-chart'
        },
        dataLabels: {
            enabled: true
        },
        colors: ['#0267AA'],

        xaxis: {
          categories: sendQuestionnaireXAxis
        }
    }

    const sendQuestionnaireSeries = [{
        name: 'Hány ember töltött ki kérdőívet',
        data: sendQuestionnaireData
    }]
    
  
    return (
        <div className="card-div">

            <div className="container">
                <div className="card-div card-div-right-margin">

                    <div className="container column">
                        <div className="info-slot">
                            <div className="info-slot__description">
                                <span className="caption"> Új aktív kérdőív </span>
                                <span className="additional-info"> Hány új kérdőív van a héten </span>
                            </div>

                            <div className="info-slot__info">
                                <span className="value">{ newQuestionnaireCount }</span> { "db" }
                            </div>
                        </div>

                        <Chart options={ newQuestionnaireOptions } series={ newQuestionnaireSeries } type="bar" height={ 260 } />
                    </div>

                </div>

                <div className="card-div card-div-right-margin">

                    <div className="container column">
                        <div className="info-slot">
                            <div className="info-slot__description">
                                <span className="caption"> Hány felhasználó töltött ki kérdőívet </span>
                                <span className="additional-info"> Hányan töltöttek ki az adott héten kérdőíveket </span>
                            </div>

                            <div className="info-slot__info">
                                <span className="value">{ sendQuestionnaireCount }</span> { "db" }
                            </div>
                        </div>

                        <Chart options={ sendQuestionnaireOptions } series={ sendQuestionnaireSeries } type="bar" height={ 260 } />
                    </div>

                </div>

            </div>

        </div>
    )
}


