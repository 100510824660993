import { GET_EVENTS_STATISTICS_ACTIONS, GET_NEWS_STATISTICS_ACTIONS, GET_QUESTIONNAIRES_STATISTICS_ACTIONS, GET_STATISTICS_ACTIONS, GET_QUOTES_STATISTICS_ACTIONS } from "../action/statistics/statistics.constants"
import { StatisticsState } from "../state"

const initialStatisticsState: StatisticsState  = {

    dateRange: [null, null],

    applicationLaunchedCount: 0, 
    applicationLaunchedXAxis: [], 
    applicationLaunchedData: [], 

    userCount: 0,
    usersChartXAxis: [],
    usersChartData: [],
    
    iOSCount: 0,
    androidCount: 0,

    newsStats: {
        newNewsCount: 0,
        newNewsXAxis: [], 
        newNewsData: [], 
        
        newsReaderCount: 0,
        newsReaderXAxis: [],
        newsReaderData: [],
        
        thisWeekNewsReadCount: 0,
        thisWeekNewsOpenCount: 0
    },

    eventsStats: {
        eventAttendanceCount: 0,
        eventAttendanceXAxis: [],
        eventAttendanceData: [] 
    },

    questionnairesStats: {
        newQuestionnaireCount: 0,
        newQuestionnaireXAxis: [],
        newQuestionnaireData: [],

        sendQuestionnaireCount: 0,
        sendQuestionnaireXAxis: [],
        sendQuestionnaireData: [],
    },

    quotesStats: {
        quotePoliticianSwipedCount: 0,
        quotePoliticianSwipedXAxis: [],
        quotePoliticianSwipedData: [],
    }
}

export const statisticsReducer = ( state: StatisticsState = initialStatisticsState, action: any ): StatisticsState => {
    switch ( action.type ) {
        case GET_STATISTICS_ACTIONS.GET_STATISTICS_SET_DATE_RANGE:
            return { ...state, dateRange: action.dateRange }
            
        case GET_STATISTICS_ACTIONS.GET_STATISTICS_OK:
            return { ...state, 
                applicationLaunchedCount: action.applicationLaunchedCount, 
                applicationLaunchedXAxis: action.applicationLaunchedXAxis,
                applicationLaunchedData: action.applicationLaunchedData,

                userCount: action.userCount, 
                usersChartXAxis: action.usersChartXAxis,
                usersChartData: action.usersChartData,

                iOSCount: action.iOSCount, 
                androidCount: action.androidCount,
            }
        case GET_STATISTICS_ACTIONS.GET_STATISTICS_INVALID_JSON:
            return { ...state, 
                applicationLaunchedCount: 0, 
                applicationLaunchedXAxis: [],
                applicationLaunchedData: [],
                userCount: 0, 
                usersChartXAxis: [],
                usersChartData: [],
                iOSCount: 0, 
                androidCount: 0,
            }
        case GET_STATISTICS_ACTIONS.GET_STATISTICS_INVALID_RESPONSE:
            return { ...state, 
                applicationLaunchedCount: 0, 
                applicationLaunchedXAxis: [],
                applicationLaunchedData: [],
                userCount: 0, 
                usersChartXAxis: [],
                usersChartData: [],
                iOSCount: 0, 
                androidCount: 0,
            }

        case GET_NEWS_STATISTICS_ACTIONS.GET_NEWS_STATISTICS_OK:
            return { ...state, newsStats: {
                newNewsCount: action.newNewsCount, 
                newNewsXAxis: action.newNewsXAxis,
                newNewsData: action.newNewsData,
    
                newsReaderCount: action.newsReaderCount, 
                newsReaderXAxis: action.newsReaderXAxis,
                newsReaderData: action.newsReaderData,
    
                thisWeekNewsReadCount: action.thisWeekNewsReadCount, 
                thisWeekNewsOpenCount: action.thisWeekNewsOpenCount,
            }}
        case GET_NEWS_STATISTICS_ACTIONS.GET_NEWS_STATISTICS_INVALID_JSON:
            return { ...state, newsStats: {
                newNewsCount: 0, 
                newNewsXAxis: [],
                newNewsData: [],
    
                newsReaderCount: 0, 
                newsReaderXAxis: [],
                newsReaderData: [],
    
                thisWeekNewsReadCount: 0, 
                thisWeekNewsOpenCount: 0,
            }}
        case GET_NEWS_STATISTICS_ACTIONS.GET_NEWS_STATISTICS_INVALID_RESPONSE:
            return { ...state, newsStats: {
                newNewsCount: 0, 
                newNewsXAxis: [],
                newNewsData: [],
    
                newsReaderCount: 0, 
                newsReaderXAxis: [],
                newsReaderData: [],
    
                thisWeekNewsReadCount: 0, 
                thisWeekNewsOpenCount: 0,
            }}

        case GET_EVENTS_STATISTICS_ACTIONS.GET_EVENTS_STATISTICS_OK:
            return { ...state, eventsStats: {
                eventAttendanceCount: action.eventAttendanceCount, 
                eventAttendanceXAxis: action.eventAttendanceXAxis,
                eventAttendanceData: action.eventAttendanceData,
            }}
        case GET_EVENTS_STATISTICS_ACTIONS.GET_EVENTS_STATISTICS_INVALID_JSON:
            return { ...state, eventsStats: {
                eventAttendanceCount: 0, 
                eventAttendanceXAxis: [],
                eventAttendanceData: []
            }}
        case GET_EVENTS_STATISTICS_ACTIONS.GET_EVENTS_STATISTICS_INVALID_RESPONSE:
            return { ...state, eventsStats: {
                eventAttendanceCount: 0, 
                eventAttendanceXAxis: [],
                eventAttendanceData: [],
            }}

        case GET_QUESTIONNAIRES_STATISTICS_ACTIONS.GET_QUESTIONNAIRES_STATISTICS_OK:
            return { ...state, questionnairesStats: {
                newQuestionnaireCount: action.newQuestionnaireCount,
                newQuestionnaireXAxis: action.newQuestionnaireXAxis,
                newQuestionnaireData: action.newQuestionnaireData,
            
                sendQuestionnaireCount: action.sendQuestionnaireCount,
                sendQuestionnaireXAxis: action.sendQuestionnaireXAxis,
                sendQuestionnaireData: action.sendQuestionnaireData,
            }}
        case GET_QUESTIONNAIRES_STATISTICS_ACTIONS.GET_QUESTIONNAIRES_STATISTICS_INVALID_JSON:
            return { ...state, questionnairesStats: {
                newQuestionnaireCount: 0,
                newQuestionnaireXAxis: [],
                newQuestionnaireData: [],
            
                sendQuestionnaireCount: 0,
                sendQuestionnaireXAxis: [],
                sendQuestionnaireData: [],
            }}
        case GET_QUESTIONNAIRES_STATISTICS_ACTIONS.GET_QUESTIONNAIRES_STATISTICS_INVALID_RESPONSE:
            return { ...state, questionnairesStats: {
                newQuestionnaireCount: 0,
                newQuestionnaireXAxis: [],
                newQuestionnaireData: [],
            
                sendQuestionnaireCount: 0,
                sendQuestionnaireXAxis: [],
                sendQuestionnaireData: [],
            }}

        case GET_QUOTES_STATISTICS_ACTIONS.GET_QUOTES_STATISTICS_OK:
            return { ...state, quotesStats: {
                quotePoliticianSwipedCount: action.quotePoliticianSwipedCount, 
                quotePoliticianSwipedXAxis: action.quotePoliticianSwipedXAxis,
                quotePoliticianSwipedData: action.quotePoliticianSwipedData,
            }}
        case GET_QUOTES_STATISTICS_ACTIONS.GET_QUOTES_STATISTICS_INVALID_JSON:
            return { ...state, quotesStats: {
                quotePoliticianSwipedCount: 0, 
                quotePoliticianSwipedXAxis: [],
                quotePoliticianSwipedData: [],
            }}
        case GET_QUOTES_STATISTICS_ACTIONS.GET_QUOTES_STATISTICS_INVALID_RESPONSE:
            return { ...state, quotesStats: {
                quotePoliticianSwipedCount: 0, 
                quotePoliticianSwipedXAxis: [],
                quotePoliticianSwipedData: [],
            }}
        default:
            return state
    }
}
