import { store } from '../../store'

export const deleteFile = async ( id: number, type: string ) => {

    let response: Response

    try {
        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/media-library/${ id }?type=${ type }`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: `DELETE`
        } )

    } catch ( exception ) {
        store.dispatch( { type: 'MEDIA_LIBRARY_DELETE_TIMEOUT' } )
        return
    }

    if ( response.status === 401 ) {
        store.dispatch( { type: 'MEDIA_LIBRARY_DELETE_UNAUTHORIZED' } )
        return
    }

    if ( response.status !== 200 ) {
        store.dispatch( { type: 'MEDIA_LIBRARY_DELETE_UNEXPECTEDRESPONSE' } )
        return
    }

    store.dispatch( { type: 'MEDIA_LIBRARY_DELETE_OK' } )

}
