import { CREATE_POLITICIAN_QUESTION_ACTIONS, DELETE_POLITICIAN_QUESTION_ACTIONS } from '../../action/politician/politicians.constants'
import { CreatePoliticianQuestionState } from '../../state/politicians'
import { message } from '../../helper'

const initialCreatePoliticianQuestionState: CreatePoliticianQuestionState  = {
    form: {
        id: null,
        question: {
            error: '',
            value: '',
            required: true
        },
    },
    saveDate: null,
    question: null
}

export const createPoliticianQuestionReducer = ( state: CreatePoliticianQuestionState = initialCreatePoliticianQuestionState, action: any ): CreatePoliticianQuestionState => {
    switch ( action.type ) {
        case CREATE_POLITICIAN_QUESTION_ACTIONS.CREATE_POLITICIAN_QUESTION_CHANGE_QUESTION:
            return { ...state, form: { ...state.form, question: { ...state.form.question, value: action.question } } }
        case CREATE_POLITICIAN_QUESTION_ACTIONS.CREATE_POLITICIAN_QUESTION_STARTED:
            return { ...state, saveDate: new Date() }
        case CREATE_POLITICIAN_QUESTION_ACTIONS.CREATE_POLITICIAN_QUESTION_INVALID_JSON:
            return { ...state, saveDate: null, question: null }
        case CREATE_POLITICIAN_QUESTION_ACTIONS.CREATE_POLITICIAN_QUESTION_INVALID_RESPONSE:
            return { ...state, saveDate: null, question: null }
        case CREATE_POLITICIAN_QUESTION_ACTIONS.CREATE_POLITICIAN_QUESTION_OK:
            message( `A kérdés sikeresen mentésre került!` )
            return { ...state, saveDate: null, question: action.question }
        case DELETE_POLITICIAN_QUESTION_ACTIONS.DELETE_POLITICIAN_QUESTION_OK:
            message( `A kérdés sikeresen törlésre került!`, `Sikeres törlés` )
            return state
        case CREATE_POLITICIAN_QUESTION_ACTIONS.CREATE_POLITICIAN_QUESTION_CLEAR:
            return initialCreatePoliticianQuestionState
        case CREATE_POLITICIAN_QUESTION_ACTIONS.CREATE_POLITICIAN_QUESTION_MODIFY_QUESTION:
            return { ...state,
                form: { ...state.form,
                    id: action.question.id,
                    question: {
                        error: '',
                        value: action.question.question,
                        required: true
                    },
                }
            }
        default:
            return state
    }
}

