
import { Expose, Type } from 'class-transformer'
import { IsDefined, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'
import { QuestionAnswer } from './question-answer.entity'

export class Question {

    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number 

    @Expose()
    @IsDefined()
    @IsString()
    question!: string

    @IsOptional()
    @ValidateNested()
    @Type( () => QuestionAnswer )
    answers?: QuestionAnswer[] | null

}
