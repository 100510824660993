import { NavLink } from "react-router-dom";
import "./dashboard-table-element.scss";

interface DashboardTableElementProps {
  title: string;
  description: string;
  image?: string | null;
  id: number;
  to: string;
}

export const DashboardTableElement = (props: DashboardTableElementProps) => {
  return (
    <li
      key={props.title + props.description}
      className="dashboard-table-element"
    >
      <NavLink to={props.to + `${props.id !== null ? props.id : ""}`} style= {{textDecoration:'none'}}>
        <div className="dashboard-table-element-container">
          <img
            className="dashboard-table-element-image"
            src={props.image == null ? "/image/dk-logo-16_9.svg" : props.image}
            alt="Dashborad elem képe"
          />

          <div className="dashboard-table-element-text-container">
            <h3 className="dashboard-table-element-title-text">
              {props.title}
            </h3>
            <h3 className="dashboard-table-element-description-text">
              {props.description}
            </h3>
          </div>
        </div>

        <div className="dashboard-table-element-divider" />
      </NavLink>
    </li>
  );
};
