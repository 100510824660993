import { store } from '../../store'

export const resetAdminPassword = async ( firstName: string, lastName: string, token: string, password: string ) => {

    let response: Response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/users/admin-password`, {
            body: JSON.stringify( {
                firstName: firstName,
                lastName: lastName,
                token: token,
                password: password
            } ),
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'SET_ADMIN_PASSWORD_TIMEOUT' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'SET_ADMIN_PASSWORD_UNEXPECTEDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'SET_ADMIN_PASSWORD_OK' } )

}
