import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { CREATE_FLOATING_MESSAGE_ACTIONS } from './floating-messages.constants'
import { GetFloatingMessageDTO } from '../../dto/floating-messages'
import { getFloatingMessages } from './get-floating-messages.action'

export const createFloatingMessage = async () => {

    store.dispatch( { type: CREATE_FLOATING_MESSAGE_ACTIONS.CREATE_FLOATING_MESSAGE_STARTED } )

    const floatingMessageId = store.getState().floatingMessages.form.id

    const body = JSON.stringify( {
        imageId: store.getState().floatingMessages.form.imageId ,
        type: store.getState().floatingMessages.form.type,
        message: store.getState().floatingMessages.form.message.value
    } )

    const promise = createCall( `floating-message`+ (floatingMessageId != null ? (`/`+floatingMessageId) : ''), floatingMessageId != null ?  BASE_METHODS.PUT :  BASE_METHODS.POST, body )
    promise.then(async response => {
        let responseBody: GetFloatingMessageDTO

        try {
            responseBody = plainToClass( GetFloatingMessageDTO, JSON.parse( await response.text() ) )
        } catch ( exception ) {
            store.dispatch( { type: CREATE_FLOATING_MESSAGE_ACTIONS.CREATE_FLOATING_MESSAGE_INVALID_JSON } )
            return
        }
    
        if ( ( await validate( responseBody ) ).length > 0 ) {
            store.dispatch( { type: CREATE_FLOATING_MESSAGE_ACTIONS.CREATE_FLOATING_MESSAGE_INVALID_RESPONSE } )
            return
        }
    
        store.dispatch( { type: CREATE_FLOATING_MESSAGE_ACTIONS.CREATE_FLOATING_MESSAGE_OK, floatingMessage: responseBody.floatingMessage } )
    
        getFloatingMessages()

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })

}
