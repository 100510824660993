import {
  ChangeEvent,
  createRef,
  MouseEvent,
  SyntheticEvent,
  useRef,
  useState,
} from "react";
import { getImage } from "../../action/media-library";
import { MediaLibraryFile } from "../../entity";
import { Checkbox } from "../checkbox";
import { LoadingIcon } from "../icon";
import "./image-editor.scss";

interface ImageEditorProps {
  aspectRatio?: {
    name: string;
    ratio: number;
  };
  mediaLibraryFile: MediaLibraryFile;
  onClose?: () => void;
  onReady?: (file: MediaLibraryFile) => void;
}

export const ImageEditor = (props: ImageEditorProps) => {
  const [loading, setLoading] = useState(true);
  const [height, setHeight] = useState(0);
  const [rotation, setRotation] = useState(0);
  const [horizontalMirror, setHorizontalMirror] = useState(0);
  const [verticalMirror, setVerticalMirror] = useState(0);
  const [width, setWidth] = useState(0);
  const [ratio, setRatio] = useState(0);
  const [aspectRatio, setAspectRatio] = useState(true);
  const [busy, setBusy] = useState(false);
  const imageRef = createRef<HTMLImageElement>();

  const changeWidth = (event: ChangeEvent<HTMLInputElement>) => {
    if (isNaN(parseInt(event.target.value))) {
      event.target.value = `0`;
    }

    setWidth(parseInt(event.target.value));
    if (aspectRatio === true) {
      setHeight(Math.round(parseInt(event.target.value) / ratio));
    }
  };

  const changeHeight = (event: ChangeEvent<HTMLInputElement>) => {
    if (isNaN(parseInt(event.target.value))) {
      event.target.value = `0`;
    }

    setHeight(parseInt(event.target.value));
    if (aspectRatio === true) {
      setWidth(Math.round(parseInt(event.target.value) * ratio));
    }
  };

  const onImageChange = (event: SyntheticEvent<HTMLImageElement>) => {
    setHeight((event.target as HTMLImageElement).naturalHeight);
    setWidth((event.target as HTMLImageElement).naturalWidth);

    setRatio(
      (event.target as HTMLImageElement).naturalWidth /
        (event.target as HTMLImageElement).naturalHeight
    );

    let w = window as any;
    w.setAspectRatio(props.aspectRatio?.ratio ?? null);
    w.setSourceImage(event.target);
    setLoading(false);
  };

  const onClose = () => {
    if (props.onClose !== undefined) {
      props.onClose();
      setLoading(true);
    }
  };

  const cutImage = async (event: MouseEvent) => {
    event.preventDefault();

    setBusy(true);
    const w = window as any;
    const image = await getImage(props.mediaLibraryFile.id, await getBlob(), {
      cut: w.getCut(),
    });
    setBusy(false);

    if (props.onReady !== undefined) {
      props.onReady(image);
    }
  };

  const onBackgroundClick = (event: MouseEvent) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  const getBlob = async (): Promise<Blob> => {
    const source = document.createElement(`canvas`);
    source.width = imageRef.current!.naturalWidth;
    source.height = imageRef.current!.naturalHeight;

    const context = source.getContext(`2d`);
    context!.drawImage(imageRef.current!, 0, 0);

    return new Promise((resolve, reject) =>
      source.toBlob((blob) => {
        if (blob === null) {
          reject();
        } else resolve(blob);
      })
    );
  };

  const rotateImage = (deg: number) => {
    const source = document.createElement(`canvas`);
    source.width = imageRef.current!.naturalHeight;
    source.height = imageRef.current!.naturalWidth;

    const context = source.getContext(`2d`);

    context?.setTransform(1, 0, 0, 1, source.width / 2, source.height / 2);
    context?.rotate((deg * Math.PI) / 180);
    context?.drawImage(
      imageRef.current!,
      -source.height / 2,
      -source.width / 2
    );

    imageRef.current!.src = source.toDataURL();
  };

  const mirrorImage = (direction: `vertical` | `horizontal`) => {
    const source = document.createElement(`canvas`);
    source.width = imageRef.current!.naturalWidth;
    source.height = imageRef.current!.naturalHeight;

    const context = source.getContext(`2d`);

    context?.setTransform(1, 0, 0, 1, source.width / 2, source.height / 2);
    context!.scale(
      direction === `horizontal` ? -1 : 1,
      direction === `vertical` ? -1 : 1
    );
    context!.drawImage(
      imageRef.current!,
      -source.width / 2,
      -source.height / 2
    );

    imageRef.current!.src = source.toDataURL();
  };

  const resizeImage = () => {
    const source = document.createElement(`canvas`);
    source.width = width;
    source.height = height;

    const context = source.getContext(`2d`);
    context!.drawImage(imageRef.current!, 0, 0, width, height);

    imageRef.current!.src = source.toDataURL();
  };

  return (
    <div className="image-editor-background" onClick={onBackgroundClick}>
      <div className="image-editor">
        <header>
          <div className="image-editor__title">Képek vágása</div>
          <div>
            <button className="button button--ghost" onClick={onClose}>
              Mégse
            </button>

            <button
              disabled={loading}
              className="button button--primary"
              onClick={cutImage}
            >
              Mentés
            </button>
          </div>
        </header>

        <main>
          <div className="image-editor__tools">
            <div className="tool">
              <div className="tool__title">Képarány</div>
              <input
                disabled
                type="text"
                value={props.aspectRatio ? props.aspectRatio.name : `Szabad`}
              />
            </div>

            <div className="tool">
              <div className="tool__title">Felbontás</div>
              <div className="resolution">
                <input
                  disabled={loading}
                  type="text"
                  value={width}
                  onChange={changeWidth}
                />
                x
                <input
                  disabled={loading}
                  type="text"
                  value={height}
                  onChange={changeHeight}
                />
              </div>

              <div className="aspect-ratio">
                <Checkbox
                  checked={aspectRatio}
                  id="keep-aspect-ratio"
                  onChange={() => setAspectRatio(!aspectRatio)}
                />
                <label htmlFor="keep-aspect-ratio">Méretarány megtartása</label>
              </div>

              <button className="button" onClick={() => resizeImage()}>
                Átméretezés
              </button>
            </div>

            <div className="tool">
              <div className="tool__title">Forgatás és tükrözés</div>

              <div className="actions">
                <span
                  className={`material-symbols-outlined${
                    loading ? ` disabled` : ``
                  }`}
                  onClick={() => rotateImage(-90)}
                >
                  rotate_right
                </span>

                <span
                  className={`material-symbols-outlined${
                    loading ? ` disabled` : ``
                  }`}
                  onClick={() => rotateImage(90)}
                >
                  rotate_left
                </span>

                <span
                  className={`material-symbols-outlined${
                    loading ? ` disabled` : ``
                  }`}
                  onClick={() => mirrorImage(`horizontal`)}
                >
                  flip
                </span>

                <span
                  className={`material-symbols-outlined${
                    loading ? ` disabled` : ``
                  }`}
                  onClick={() => mirrorImage(`vertical`)}
                >
                  flip
                </span>
              </div>
            </div>
          </div>

          <div className="image-editor__editor">
            <img
              className={loading ? `disabled` : ``}
              crossOrigin="anonymous"
              src={ props.mediaLibraryFile.url }
              onLoad={onImageChange}
              ref={imageRef}
            />

            <div className={`cutter${loading ? ` cutter--invisible` : ``}`}>
              <div className="cutter__top"></div>
              <div className="cutter__right"></div>
              <div className="cutter__bottom"></div>
              <div className="cutter__left"></div>

              <div className="cutter__top-left"></div>
              <div className="cutter__top-right"></div>
              <div className="cutter__bottom-left"></div>
              <div className="cutter__bottom-right"></div>

              <div className="cutter__fade-top"></div>
              <div className="cutter__fade-right"></div>
              <div className="cutter__fade-bottom"></div>
              <div className="cutter__fade-left"></div>
            </div>

            {loading ? <LoadingIcon /> : null}
          </div>
        </main>

        <div
          className={`image-editor__busy${
            busy ? ` image-editor__busy--show` : ``
          }`}
        >
          <div className="loading"></div>
          <span>Feltöltés folyamatban!</span>
        </div>
      </div>
    </div>
  );
};
