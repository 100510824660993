import { AdminActivity as AdminActivityEntity } from '../../entity'
import { createFormattedDateTimeString } from '../../helper'
import './admin-activity.scss'

interface AdminActivityProps {

    adminActivity: AdminActivityEntity

}

export const AdminActivity = ( props: AdminActivityProps ) => {

    return (
        <div className="admin-activity">

            <div className="admin-activity__description">

                <a href={ `/tagok/adminok/${ props.adminActivity.user.id }` }>{ props.adminActivity.user.lastName } { props.adminActivity.user.firstName }</a> <span className="description" dangerouslySetInnerHTML={ { __html: props.adminActivity.description.replaceAll( `[`, `<strong>` ).replaceAll( `]`, `</strong>` ) } }></span>

            </div>

            <div className="admin-activity__date">

                { createFormattedDateTimeString( props.adminActivity.created ) }

            </div>

        </div>
    )

}
