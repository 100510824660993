import { Expose, Type } from 'class-transformer'
import { ValidateNested } from 'class-validator'
import { QuestionnaireTag } from '../../entity'

export class GetQuestionnaireTagsDTO {

    @Expose()
    @ValidateNested()
    @Type( () => QuestionnaireTag )
    questionnaireTags!: QuestionnaireTag[]

}