import { store } from '../../store'

export const createAdmin = async ( firstName: string, lastName: string, email: string, userGroupId: number, password: string ): Promise<boolean> => {

    let response: Response
    const token = store.getState().session.token

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/users/`, {
            body: JSON.stringify( {

                firstName: firstName,
                lastName: lastName,
                email: email,
                userGroupId: userGroupId,
                password: password

            } ),
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            method: 'POST'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'CREATE_ADMIN_TIMEOUT' } )
        return false

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'CREATE_ADMIN_UNAUTHORIZED' } )
        return false

    }

    if ( response.status === 400 ) {

        store.dispatch( { type: 'CREATE_ADMIN_ERROR', error: await response.json() } )
        return false

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'CREATE_ADMIN_UNEXPECTEDRESPONSE' } )
        return false

    }

    store.dispatch( { type: 'CREATE_ADMIN_OK' } )
    return true

}
