import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetQuestionnairesStatisticsDTO } from '../../dto/statistics'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { GET_QUESTIONNAIRES_STATISTICS_ACTIONS } from './statistics.constants'

export const getQuestionnairesStatistics = async ( startDate?: Date, endDate?: Date ) => {

    store.dispatch( { type: GET_QUESTIONNAIRES_STATISTICS_ACTIONS.GET_QUESTIONNAIRES_STATISTICS_STARTED } )

    let start = null
    if(startDate != undefined ){
        start = startDate.getTime() / 1000
    }

    let end = null
    if(endDate != undefined ){
        end = endDate.getTime() / 1000
    }

    const promise = createCall(`cms/statistics/questionnaires?start=`+start+`&end=`+end, BASE_METHODS.GET)
    promise.then(async response => {

        let responseBody: GetQuestionnairesStatisticsDTO

        try {
            responseBody = plainToClass( GetQuestionnairesStatisticsDTO, JSON.parse( await response.text() ) )
        } catch ( exception ) {
            store.dispatch( { type: GET_QUESTIONNAIRES_STATISTICS_ACTIONS.GET_QUESTIONNAIRES_STATISTICS_INVALID_JSON } )
            return
        }
    
        if ( ( await validate( responseBody ) ).length > 0 ) {
            store.dispatch( { type: GET_QUESTIONNAIRES_STATISTICS_ACTIONS.GET_QUESTIONNAIRES_STATISTICS_INVALID_RESPONSE } )
            return
        }
    
        store.dispatch( { type: GET_QUESTIONNAIRES_STATISTICS_ACTIONS.GET_QUESTIONNAIRES_STATISTICS_OK, 
            newQuestionnaireCount: responseBody.newQuestionnaireCount, 
            newQuestionnaireXAxis: responseBody.newQuestionnaireXAxis,
            newQuestionnaireData: responseBody.newQuestionnaireData,
            sendQuestionnaireCount: responseBody.sendQuestionnaireCount, 
            sendQuestionnaireXAxis: responseBody.sendQuestionnaireXAxis,
            sendQuestionnaireData: responseBody.sendQuestionnaireData,
        } )

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })
   
}