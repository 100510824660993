import { Expose, Type } from 'class-transformer'
import { IsDefined, ValidateNested } from 'class-validator'
import { AdminActivity } from '../../entity'

export class ListAdminActivitiesDTO {

    @Expose()
    @IsDefined()
    @ValidateNested()
    @Type( type => AdminActivity )
    adminActivities!: AdminActivity[]

}
