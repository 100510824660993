import { MessageModal, Modal } from '../../modal'
import { ChangeEvent, createRef, MouseEvent, MouseEventHandler } from 'react'
import './politician-title-modal.scss'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../state'
import { PoliticianTitle } from '../../../entity'
import { TextField } from '../../field'
import { PoliticianTitleModalElement } from './politician-title-modal-element'
import { CREATE_POLITICIAN_TITLE_ACTIONS, POLITICIANS_ACTIONS } from '../../../action/politician/politicians.constants'
import { createPoliticianTitle } from '../../../action/politician'
import { Dropdown } from '../../dropdown'

interface PoliticianTitleModalProps {
    onCloseClick?: MouseEventHandler,
    onModalClose?: () => void
}

const positions = [
    { id: 0, value: `Első helyen` },
    { id: 1, value: `Második helyen` },
    { id: 2, value: `Harmadik helyen` },
    { id: 3, value: `Negyedik helyen` },
    { id: 4, value: `Ötödik helyen` },
    { id: 5, value: `Hatodik helyen` },
    { id: 6, value: `Hetedik helyen` },
    { id: 7, value: `Nyolcadik helyen` },
    { id: 8, value: `Kilencedik helyen` },
    { id: 9, value: `Tizedik helyen` },
    { id: 10, value: `A lista végén` }
]

export const PoliticianTitleModal = ( props: PoliticianTitleModalProps ) => {

    const dispatch = useDispatch()

    const saveDate = useSelector( ( state: State ) => state.createPoliticianTitle.saveDate )
    const politicianTitles = useSelector( ( state: State ) => state.politicians.politicianTitles )
    const newsCategoryId = useSelector( ( state: State ) => state.createPoliticianTitle.form.id )

    const form = useSelector( ( state: State ) => state.createPoliticianTitle.form )

    const newsNotEmpty = useSelector( ( state: State ) => state.newsCategories.newsNotEmpty )

    const formElement = createRef<HTMLFormElement>()

    let titleElements = [ { value: '', id: -1 } ]
    const politicianTitleElements: JSX.Element[] = []

    if ( politicianTitles !== null && politicianTitles !== undefined ) {
        titleElements = []
        politicianTitles.map( ( politicianTitleElement: PoliticianTitle ) => {
            politicianTitleElements.push( <PoliticianTitleModalElement politicianTitleElement={ politicianTitleElement } deleteVisible={ true } /> )
        } )
    }

    const onSaveButtonClick = async ( event: MouseEvent ) => {
        event.preventDefault()
        if ( !formElement.current?.reportValidity() ) { return }
        await createPoliticianTitle()
    }

    const onNewTitleButtonClick = async ( event: MouseEvent ) => {
        event.preventDefault()
        dispatch( { type: CREATE_POLITICIAN_TITLE_ACTIONS.CREATE_POLITICIAN_TITLE_CLEAR } )
    }

    const onNameChange = ( event: ChangeEvent<HTMLInputElement> ) => {
        event.preventDefault()
        dispatch( { type: CREATE_POLITICIAN_TITLE_ACTIONS.CREATE_POLITICIAN_TITLE_CHANGE_TITLE, title: event.target.value } )
    }

    const onPositionChange = ( id: number ) => {
        dispatch( { type: CREATE_POLITICIAN_TITLE_ACTIONS.CREATE_POLITICIAN_TITLE_CHANGE_POSITION, position: id } )
    }

    return (
        <Modal onModalClose={ () => props.onModalClose && props.onModalClose() }>

            <div className="politician-title-modal">

                <div className="politician-title-modal__header">
                    <div className="politician-title-modal__title"> Titulusok </div>
                    <button className="close-button" onClick={ props.onCloseClick }> <img alt="bezárás gomb" src="/image/close.svg" /> </button>
                </div>

                <div className="container">

                    <div className=" ">
                        <ul id={ "politician-title_table" } className="politician-title-list">
                            { politicianTitleElements.length != 0 ? politicianTitleElements : <span className="label-text empty-text">Nincs megjeleníthető titulus.</span> }
                        </ul>

                        <div className="new-category-button-div">
                            <button className="button " onClick={ onNewTitleButtonClick }> Új titulus hozzáadása +</button>
                        </div>
                    </div>

                    <div className="separator" />

                    <div className="card-div" >

                        <form action="" className="editor-div" ref={ formElement }>

                            <h2 className="title-text-h2 title-margin">Titulus módosítása</h2>

                            <div className="name-div">
                                <h3 className="title-text-h3 text-right-margin">Titulus neve:</h3>
                                <div className="text-section-container">
                                    <TextField maxlength={ 255 } field={ form.title } onChange={ onNameChange } placeholder="" type={ "small" } />
                                </div>
                            </div>

                            <div className="name-div">
                                <h3 className="title-text-h3 text-right-margin" style={{ minWidth: 108 }}>Pozicíó:</h3>
                                <div className="text-section-container">
                                    <Dropdown value={ form.position.value } onChange={ onPositionChange } items={ positions }  compact={ true } />
                                </div>
                            </div>

                            <div className="new-category-button-div edit-button-top-margin">
                                <button className="button" type="submit" onClick={ onSaveButtonClick } > { newsCategoryId == null ? "Mentés" : "Módosítások mentése" }</button>
                            </div>

                        </form>

                    </div>

                </div>

                {
                    newsNotEmpty ?
                    <MessageModal title={ "Figyelmeztetés" } message={ "A választott titulust nem lehet törölni, mert politikushoz van rendelve. Először állítsd át a politikus titulusát, amit törölni szeretnél." } onCloseClick={ () => { dispatch( { type: POLITICIANS_ACTIONS.GET_POLITICIAN_TITLES_POLITICIAN_NOT_EMPTY_CLEAR } ) } } />
                     : ""
                }

            </div>

        </Modal>
    )
}
