import { store } from '../../store'

export const saveQuizTopic = async ( topic: any ) => {

    let response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/quizes/topics`, {
            body: JSON.stringify( topic ),
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'POST'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'SAVE_QUIZ_TOPIC_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'SAVE_QUIZ_TOPIC_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'SAVE_QUIZ_TOPIC_UNEXPECTEDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'SAVE_QUIZ_TOPIC_OK' } )

}