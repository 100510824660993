import { ChangeEvent, MouseEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { PoliticianPhrase, Quote } from '../../../entity'
import { State } from '../../../state'
import { InputModal } from '../../modal'
import { PoliticianPhraseElement } from './politician-phrase-element'
import './politician-phrase-table.scss'

interface PoliticianPhraseTableProps {

}

export const PoliticianPhraseTable = ( props: PoliticianPhraseTableProps ) => {

    const dispatch = useDispatch()

    const [ phrase, setPhrase ] = useState<PoliticianPhrase | null>( null )

    const quotes = useSelector( ( state: State ) => state.createPolitician.form.quotes )

    const phraseElements: JSX.Element[] = []
    if ( quotes !== null && quotes !== undefined ) {
        quotes.map( ( quote: Quote ) => {
            phraseElements.push( <PoliticianPhraseElement politicianPhraseElement={ quote } /> )
        } )
    }

    return (
        <div className="container-top-margin">
            <h2 className="title-text-h3">Megjelenített a politikus oldalán</h2>

            <div className="">
                <ul id={ "phrase_table" } className="politician-phrase-list">
                    { phraseElements.length != 0 ? phraseElements : "" }
                </ul>
            </div>

            <div className="right-gravity-div container-top-margin">
                <NavLink target="_blank" to="/velemenyvezerek/idezetek" style={{ textDecoration: `none` }}><div className="button button--primary" style={{ textAlign: `center` }}>+ Új mondat</div></NavLink>
            </div>
        </div>
    )

}
