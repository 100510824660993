import { Expose, Type } from 'class-transformer'
import { IsDefined, ValidateIf, ValidateNested } from 'class-validator'
import { Event, ImageFile } from '../../entity'

export class GetEventImagesDTO {

    @Expose()
    @IsDefined()
    eventImageGroups!: {

        owned: boolean
        uploadedAt: number
        uploadedBy: string
        eventImages: ImageFile[]

    }[]

    @Expose()
    @IsDefined()
    pagination!: {
        page: number
        from: number
        to: number
        count: number
    }

}

