import { MouseEventHandler } from 'react'
import { Checkbox, Modal } from '../../../component'
import { State } from '../../../state'
import { useDispatch, useSelector } from 'react-redux'
import { CHANGE_PUSH_SETTINGS_ACTIONS } from '../../../action/push/push.constants'
import './push-message-settings-modal.scss'
import { PushSettingsEnum } from '../../../enum'
import { modifyPushSettings } from '../../../action/push'

interface PushMessageSettingsModalProps {
    onCloseClick?: MouseEventHandler
}

export const PushMessageSettingsModal = (props: PushMessageSettingsModalProps) => {
    const dispatch = useDispatch()

    const { questDailyClosing, questFridayBeforeLeagueClose, questWeeklyClosing, questMiddleOfTheWeek, eventsOneDayBeforeTheEvent, activityNotLogInForThreeDay, activityNotLogInForSixDay, activityDontBreakTheStreak, birthday, oneDayAfterRegistrationIfHaventCompletedAnyMission, markAsAFriend, friendConfirmed } = useSelector((state: State) => state.push.settings)

    const onPushSettingsChange = async (settingsId: number) => {
        dispatch({
            type: CHANGE_PUSH_SETTINGS_ACTIONS.CHANGE_PUSH_SETTINGS_CHANGE,
            settingsId: settingsId,
        })

        await modifyPushSettings()
    }

    return (
        <Modal>
            <div className="push-message-settings-modal">
                <div className="push-message-settings-modal__header">
                    <div className="push-message-settings-modal__title"> Általános értesítések kezelése </div>
                    <button className="close-button" onClick={props.onCloseClick}>
                        <img alt="bezárás gomb" src="/image/close.svg" />
                    </button>
                </div>

                <div className="">
                    <div className="divider-container">
                        <span className="divider-line" />
                        <span className="divider-text">Küldetések</span>
                        <span className="divider-line" />
                    </div>

                    <div className="push-message-settings-modal__item">
                        <div className="push-message-settings-modal__item-checkbox-container">
                            <Checkbox checked={questDailyClosing} id="keep-aspect-ratio" onChange={() => onPushSettingsChange(PushSettingsEnum.QuestDailyClosing)} />
                        </div>
                        <div className="push-message-settings-modal__item-text-container">
                            <h3 className="push-message-settings-modal__item-title">Napi zárás</h3>
                            <h3 className="push-message-settings-modal__item-desc">Megmutatja a felhasználónak, hogy az adott napon hány elvégzetlen küldetése van. Ha minden küldetést elvégzett, akkor nem kap értesítést! Ha nem nézi meg egy napig, akkor nem kap következő napon is.</h3>
                        </div>
                    </div>
                    <div className="push-message-settings-modal__item">
                        <div className="push-message-settings-modal__item-checkbox-container">
                            <Checkbox checked={questFridayBeforeLeagueClose} id="keep-aspect-ratio" onChange={() => onPushSettingsChange(PushSettingsEnum.QuestFridayBeforeLeagueClose)} />
                        </div>
                        <div className="push-message-settings-modal__item-text-container">
                            <h3 className="push-message-settings-modal__item-title">Liga zárás előtt pénteki</h3>
                            <h3 className="push-message-settings-modal__item-desc">A liga zárása előtt értesítést kap a felhasználó, hogy éppen hol tart. Felhívja a figyelmet hogy a Liga zárul vasárnap.</h3>
                        </div>
                    </div>
                    <div className="push-message-settings-modal__item">
                        <div className="push-message-settings-modal__item-checkbox-container">
                            <Checkbox checked={questWeeklyClosing} id="keep-aspect-ratio" onChange={() => onPushSettingsChange(PushSettingsEnum.QuestWeeklyClosing)} />
                        </div>
                        <div className="push-message-settings-modal__item-text-container">
                            <h3 className="push-message-settings-modal__item-title">Heti zárás</h3>
                            <h3 className="push-message-settings-modal__item-desc">Felhívja a felhasználó figyelmét, hogy vége van a heti ligának és a záró screennek mutatja.</h3>
                        </div>
                    </div>
                    <div className="push-message-settings-modal__item">
                        <div className="push-message-settings-modal__item-checkbox-container">
                            <Checkbox checked={questMiddleOfTheWeek} id="keep-aspect-ratio" onChange={() => onPushSettingsChange(PushSettingsEnum.QuestMiddleOfTheWeek)} />
                        </div>
                        <div className="push-message-settings-modal__item-text-container">
                            <h3 className="push-message-settings-modal__item-title">Hét közepe</h3>
                            <h3 className="push-message-settings-modal__item-desc">A hét közepén, hogy motiválja a felhasználókat a hátralévő hétre.</h3>
                        </div>
                    </div>

                    <div className="divider-container">
                        <span className="divider-line" />
                        <span className="divider-text">Események</span>
                        <span className="divider-line" />
                    </div>

                    <div className="push-message-settings-modal__item">
                        <div className="push-message-settings-modal__item-checkbox-container">
                            <Checkbox checked={eventsOneDayBeforeTheEvent} id="keep-aspect-ratio" onChange={() => onPushSettingsChange(PushSettingsEnum.EventsOneDayBeforeTheEvent)} />
                        </div>
                        <div className="push-message-settings-modal__item-text-container">
                            <h3 className="push-message-settings-modal__item-title">Esemény előtt egy nappal értesítés</h3>
                            <h3 className="push-message-settings-modal__item-desc">A felhasználó értesítést kap az esemény kezdete előtt egy nappal, de csak akkor, ha jelentkezett rá.</h3>
                        </div>
                    </div>

                    <div className="divider-container">
                        <span className="divider-line" />
                        <span className="divider-text">Aktivitás</span>
                        <span className="divider-line" />
                    </div>

                    <div className="push-message-settings-modal__item">
                        <div className="push-message-settings-modal__item-checkbox-container">
                            <Checkbox checked={activityNotLogInForThreeDay} id="keep-aspect-ratio" onChange={() => onPushSettingsChange(PushSettingsEnum.ActivityNotLogInForThreeDay)} />
                        </div>
                        <div className="push-message-settings-modal__item-text-container">
                            <h3 className="push-message-settings-modal__item-title">Értesítés ha 3 napig NEM lépett be</h3>
                            <h3 className="push-message-settings-modal__item-desc">Amennyiben a felhasználó 3 napi nem volt aktív emlékeztetőt küld a rendszer.</h3>
                        </div>
                    </div>

                    <div className="push-message-settings-modal__item">
                        <div className="push-message-settings-modal__item-checkbox-container">
                            <Checkbox checked={activityNotLogInForSixDay} id="keep-aspect-ratio" onChange={() => onPushSettingsChange(PushSettingsEnum.ActivityNotLogInForSixDay)} />
                        </div>
                        <div className="push-message-settings-modal__item-text-container">
                            <h3 className="push-message-settings-modal__item-title">Értesítés ha 6 napig NEM lépett be</h3>
                            <h3 className="push-message-settings-modal__item-desc">Amennyiben a felhasználó 6 napi nem volt aktív emlékeztetőt küld a rendszer.</h3>
                        </div>
                    </div>

                    <div className="push-message-settings-modal__item">
                        <div className="push-message-settings-modal__item-checkbox-container">
                            <Checkbox checked={activityDontBreakTheStreak} id="keep-aspect-ratio" onChange={() => onPushSettingsChange(PushSettingsEnum.ActivityDontBreakTheStreak)} />
                        </div>
                        <div className="push-message-settings-modal__item-text-container">
                            <h3 className="push-message-settings-modal__item-title">Ne szakadjon meg a streak</h3>
                            <h3 className="push-message-settings-modal__item-desc">Ha a felhasználó az adott napon nem lépett be az alkalmazásba, akkor este 8-kor kap egy értesítést, hogy ne szakadjon meg a streakje.</h3>
                        </div>
                    </div>

                    <div className="divider-container">
                        <span className="divider-line" />
                        <span className="divider-text">Születésnapi értesítések</span>
                        <span className="divider-line" />
                    </div>

                    <div className="push-message-settings-modal__item">
                        <div className="push-message-settings-modal__item-checkbox-container">
                            <Checkbox checked={birthday} id="keep-aspect-ratio" onChange={() => onPushSettingsChange(PushSettingsEnum.Birthday)} />
                        </div>
                        <div className="push-message-settings-modal__item-text-container">
                            <h3 className="push-message-settings-modal__item-title">Születésnapi értesítés</h3>
                            <h3 className="push-message-settings-modal__item-desc">Amennyiben a felhasználó megadta a születésnapját úgy értesítést küldünk számára.</h3>
                        </div>
                    </div>

                    <div className="divider-container">
                        <span className="divider-line" />
                        <span className="divider-text">Onboarding</span>
                        <span className="divider-line" />
                    </div>

                    <div className="push-message-settings-modal__item">
                        <div className="push-message-settings-modal__item-checkbox-container">
                            <Checkbox checked={oneDayAfterRegistrationIfHaventCompletedAnyMission} id="keep-aspect-ratio" onChange={() => onPushSettingsChange(PushSettingsEnum.OneDayAfterRegistrationIfHaventCompletedAnyMission)} />
                        </div>
                        <div className="push-message-settings-modal__item-text-container">
                            <h3 className="push-message-settings-modal__item-title">Egy nappal a regisztráció után, ha nem teljesítettetek küldetéseket</h3>
                            <h3 className="push-message-settings-modal__item-desc">Ha nem teljesített küldetést az első bejelentkezés után.</h3>
                        </div>
                    </div>

                    <div className="divider-container">
                        <span className="divider-line" />
                        <span className="divider-text">Barát meghívás</span>
                        <span className="divider-line" />
                    </div>

                    <div className="push-message-settings-modal__item">
                        <div className="push-message-settings-modal__item-checkbox-container">
                            <Checkbox checked={markAsAFriend} id="keep-aspect-ratio" onChange={() => onPushSettingsChange(PushSettingsEnum.MarkAsAFriend)} />
                        </div>
                        <div className="push-message-settings-modal__item-text-container">
                            <h3 className="push-message-settings-modal__item-title">Barátnak jelölés</h3>
                            <h3 className="push-message-settings-modal__item-desc">Ha valaki barának jelölnek, akkor kapon egy értesítést a meghívott.</h3>
                        </div>
                    </div>

                    <div className="push-message-settings-modal__item">
                        <div className="push-message-settings-modal__item-checkbox-container">
                            <Checkbox checked={friendConfirmed} id="keep-aspect-ratio" onChange={() => onPushSettingsChange(PushSettingsEnum.FriendConfirmed)} />
                        </div>
                        <div className="push-message-settings-modal__item-text-container">
                            <h3 className="push-message-settings-modal__item-title">Barát vissza igazolt</h3>
                            <h3 className="push-message-settings-modal__item-desc">Ha a meghívott fél elfogadja a felkérést akkor értesítést kap a meghívó.</h3>
                        </div>
                    </div>
                </div>

                <div className="push-message-settings-modal__footer">
                    <button className="button button-left-margin" type="button" onClick={props.onCloseClick}>
                        OK
                    </button>
                </div>
            </div>
        </Modal>
    )
}
