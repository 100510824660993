import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { forgotPassword } from '../../action'
import { EmailField } from '../../component/field'
import { MessageModal } from '../../component/modal'
import { State } from '../../state'
import { store } from '../../store'
import './forgot-password.page.scss'
import { title } from '../../helper'

export const ForgotPasswordPage = () => {

    const [ alertMessage, setAlertMessage ] = useState( null as string | null )
    const [ email, setEmail ] = useState( { error: ``, value: `` } )
    const { forgotPasswordBusy, forgotPasswordEmailRequested } = useSelector( ( state: State ) => state.users )

    const onForgotPasswordButtonClick = ( event: MouseEvent ) => { }

    const onEmailChange = ( event: ChangeEvent<HTMLInputElement> ) => {

        setEmail( { ...email, value: event.target.value } )

    }

    const onFormSubmit = ( event: FormEvent ) => {

        event.preventDefault()
        store.dispatch( { type: `FORGOT_PASSWORD_REQUEST` } )
        forgotPassword( email.value )

    }

    const navigate = useNavigate()

    useEffect( () => {

        store.dispatch( { type: `FORGOT_PASSWORD_CLEAR` } )

    }, [] )

    return (

        <main className="page page--forgot-password">

            <form action="" className="forgot-password-form" onSubmit={ onFormSubmit }>

                <img alt="A Demokratikus Koalíció logója" className="forgot-password-form__logo" src="/image/dk-logo.svg" />

                { forgotPasswordEmailRequested !== true ? (

                    <div>

                        <h1>{ title() }</h1>

                        <EmailField field={ email } onChange={ onEmailChange } placeholder="e-mail cím" />

                        <footer>

                            <button className="button" disabled={ email.value.trim().length === 0 || forgotPasswordBusy } onClick={ onForgotPasswordButtonClick } type="submit">

                                Új jelszó igénylése

                            </button>

                        </footer>

                    </div>

                ) : (

                    <div>

                        <h1>Sikeres jelszóigénylés!</h1>

                        <div className="description">

                            Amennyiben létező e-mail címet adtál meg,<br />
                            az aktiváló linket megtalálod a postafiókodban.

                        </div>

                        <footer>

                            <button className="button" onClick={ e => { e.preventDefault(); navigate( `/bejelentkezes` ) } }>

                                Vissza a bejelentkezéshez

                            </button>

                        </footer>

                    </div>

                ) }


            </form>

            { alertMessage !== null ? (

                <MessageModal title="Váratlan hiba történt" message={ alertMessage } onCloseClick={ () => setAlertMessage( null ) } />

            ) : null }

        </main>

    )

}
