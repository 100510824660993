import { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { createFolder, deleteFile as deleteAction, getFolder, moveFile, rename, uploadFiles } from '../../action/media-library'
import { MediaLibraryFile, MediaLibraryFolder } from '../../entity'
import { createFormattedDateString } from '../../helper'
import { State } from '../../state'
import { File } from './file'
import { Folder } from './folder'
import './media-library.scss'

interface MediaLibraryProps {

    title?: string
    onClose?: CallableFunction
    onMediaSelected?: CallableFunction

}

export const MediaLibrary = ( props: MediaLibraryProps ) => {

    useEffect( () => {

        getFolder()

    }, [] )

    const mediaLibraryFolder = useSelector( ( state: State ) => state.mediaLibrary.mediaLibraryFolder )
    const [ selected, setSelected ] = useState( null as MediaLibraryFile | MediaLibraryFolder | null )
    const [ keyword, setKeyword ] = useState( `` )
    const [ busy, setBusy ] = useState( false )
    const [ showFilters, setShowFilters ] = useState( false )
    const [ selectedForMove, setSelectedForMove ] = useState( null as MediaLibraryFile | MediaLibraryFolder | null )
    const selectMedia = ( content: MediaLibraryFile | MediaLibraryFolder ) => { if ( props.onMediaSelected ) props.onMediaSelected( content ) }

    const onFilterClick = async ( orderBy: string, orderDirection: `ASC` | `DESC` ) => {

        await getFolder( mediaLibraryFolder!.id, orderBy, orderDirection )
        setShowFilters( false )

    }

    const onMoveClick = async () => {

        await moveFile( selectedForMove!.type, selectedForMove!.id, mediaLibraryFolder!.id )
        await getFolder( mediaLibraryFolder!.id )
        setSelectedForMove( null )

    }

    const renameFile = async ( placeholder?: string ) => {

        const name = prompt( `Adja meg az új nevet!`, selected?.name )

        if ( name !== null && name.length > 255 ) {

            alert( "A megadott fájlnév nem lehet 255 karakternél hosszabb!" )
            await renameFile( name )

        }

        if ( name !== null ) {

            await rename( selected?.id!, selected?.type!, name )
            await getFolder( mediaLibraryFolder?.id )
            setSelected( null )

        }

    }

    const deleteFile = async () => {

        if ( window.confirm( `Biztosan törölni szeretné a ${ selected?.type === `file` ? `fájlt` : `könyvtárat és a teljes tartalmát` }?` ) ) {

            await deleteAction( selected?.id!, selected?.type! )
            setSelected( null )
            await getFolder( mediaLibraryFolder?.id )

        }

    }

    const onFilesSelected = async ( event: ChangeEvent<HTMLInputElement> ) => {

        if ( event.target.files === null ) {

            return

        }

        const files = []
        const ignoredfiles = []

        for ( let i = 0; i < event.target.files.length; i++ ) {

            const file = event.target.files.item( i )
            const header = await getHeader( file as Blob )

            switch ( header ) {

                case "8950": // PNG
                case "ffd8": // JPG
                    files.push( file )
                    break
                default:
                    ignoredfiles.push( file )

            }

        }

        if ( ignoredfiles.length !== 0 ) {

            if ( event.target.files.length === 1 ) {

                alert( `A feltölteni kívánt fájl nem feldolgozható! A kép nem JPEG vagy PNG formátumú.` )
                return

            } else {

                alert( `A feltölteni kívánt fájlok közül az alábbiak nem feldolgozhatóak: ${ ignoredfiles.map( f => f!.name ).join( `, ` ) }. A képek nem JPEG vagy PNG formátumúak.` )
                return

            }

        }

        setBusy( true )
        await uploadFiles( mediaLibraryFolder?.id, event.target.files )
        event.target.value = ""
        setBusy( false )

    }

    const getHeader = async ( file: Blob ) => {

        return new Promise( ( resolve, reject ) => {

            const fileReader = new FileReader()
            fileReader.onloadend = function(e) {
                const arr = ( new Uint8Array( e.target!.result as ArrayBuffer )  ).subarray( 0, 2 )
                let header = ""
                for ( let j = 0; j < arr.length; j++ ) {
                    header += arr[ j ].toString( 16 )
                }
                resolve( header )
            }
            fileReader.readAsArrayBuffer( file )

        } )

    }

    const onClose = () => {

        if ( props.onClose !== undefined ) {

            props.onClose()

        }

    }

    const onCreateFolderClick = () => {

        const name = prompt( `Adja meg a mappa nevét!` )
        if ( name !== null ) {

            createFolder( mediaLibraryFolder?.id, name )

        }

    }

    const changeFolder = ( folder: MediaLibraryFolder ) => {

        getFolder( folder.id )

    }

    const onURLCopyClick = ( event: MouseEvent ) => {

        event.preventDefault()
        navigator.clipboard.writeText( selected?.url! )

    }

    const onBackgroundClick = ( event: MouseEvent ) => {

        if ( event.target === event.currentTarget ) {

            onClose()

        }

    }

    return (

        <div className="media-library-background" onClick={ onBackgroundClick }>

            <div className={ `media-library${ selectedForMove !== null ? ` media-library--moving` : `` }` }>

                <header>

                    <div className="media-library__title">

                        { props.title ?? `Válassz képet!` }

                    </div>

                    <span className="material-symbols-outlined" onClick={ onClose }>close</span>

                </header>

                <main>

                    <div className="media-library__browser">

                        { showFilters ? (

                            <div className="media-library__filters">

                                <div className="media-library__filter" onClick={ e => onFilterClick( `name`, `ASC` ) }>ABC szerinti növekvő sorrend</div>
                                <div className="media-library__filter" onClick={ e => onFilterClick( `name`, `DESC` ) }>ABC szerinti csökkenő sorrend</div>
                                <div className="media-library__filter" onClick={ e => onFilterClick( `updated`, `DESC` ) }>Legutóbb módosított</div>
                                <div className="media-library__filter" onClick={ e => onFilterClick( `updated`, `ASC` ) }>Legrégebben módosított</div>

                            </div>

                        ) : null }

                        <div className="media-library__search">

                            <input placeholder="Keresés" type="text" onChange={ event => setKeyword( event.target.value ) } />
                            <span className="material-symbols-outlined">search</span>

                        </div>

                        <div className="media-library__toolbar">

                            <div className="folder-name">

                                { mediaLibraryFolder?.name }

                            </div>

                            <div className={ `up${ mediaLibraryFolder?.mediaLibraryFolder !== null ? ` up--active` : `` }` } onClick={ () => changeFolder( mediaLibraryFolder?.mediaLibraryFolder! ) } title="Egy szinttel feljebb">

                                <span className="material-symbols-outlined">arrow_upward</span>

                            </div>

                            <div className="create-folder" onClick={ onCreateFolderClick } title="Könyvtár létrehozása">

                                <span className="material-symbols-outlined">create_new_folder</span>

                            </div>

                            <div className="filter" title="Rendezés" onClick={ e => setShowFilters( !showFilters ) }>

                                <span className="material-symbols-outlined">filter_alt</span>

                            </div>

                            <div className={ `move${ selected !== null ? ` move--active` : `` }` } title="Átmozgatás" onClick={ e => setSelectedForMove( selected ) }>

                                <img alt="" src="/image/move.svg" />

                            </div>

                            <button className="button button--primary">

                                Új képek feltöltése
                                <input type="file" accept="image/jpeg,image/png" onChange={ onFilesSelected } multiple />

                            </button>

                        </div>

                        <div className="file-browser">

                            { selectedForMove !== null ? (

                                <div className="file-browser__move-panel">

                                    <span>{ selectedForMove.name.substring( 0, 15 ) }{ selectedForMove.name.length > 15 ? `...` : `` }</span>&nbsp;{ selectedForMove.type === `folder` ? `könyvtár` : `fájl` } átmozgatása
                                    <button className="button button--ghost" onClick={ onMoveClick }>Beillesztés ide</button>
                                    <img alt="" src="/image/close.svg" onClick={ e => { setSelectedForMove( null ) } } />

                                </div>

                            ) : null }

                            { mediaLibraryFolder?.mediaLibraryFolders?.filter( f => f.name.indexOf( keyword ) !== -1 ).map( content => (

                                <Folder key={ content.id } name={ content.name } selected={ selected?.id === content.id } onClick={ () => setSelected( content ) } onDoubleClick={ () => changeFolder( content ) } />

                            ) ) }


                            { mediaLibraryFolder?.mediaLibraryFiles?.filter( f => f.name.indexOf( keyword ) !== -1 ).map( content => (

                                <File key={ content.id } name={ content.name } selected={ selected?.id === content.id } thumbnail={ content.thumbnail } onClick={ () => setSelected( content ) } onDoubleClick={ () => selectMedia( content ) } />

                            ) ) }

                        </div>

                        <footer>

                            <button className="button button--primary">

                                Új képek feltöltése
                                <input type="file" accept="image/jpeg,image/png" onChange={ onFilesSelected } multiple />

                            </button>

                        </footer>

                    </div>

                    <div className="media-library__panel">

                        <div className={ `media-library__panel__preview${ selected?.thumbnail ? `` : ` no-thumbnail` }` }>

                            { selected?.thumbnail ? <img src={ selected?.thumbnail } /> : null }

                        </div>

                        <div className="media-library__panel__name">

                            { selected?.name }

                        </div>

                        <div className="media-library__panel__information">

                            <div className="caption">Fájl információk</div>

                            <div className="information">

                                <div className="information__name">Típus</div>
                                <div className="information__value">{ selected?.extension }</div>

                            </div>

                            <div className="information">

                                <div className="information__name">Méret</div>
                                <div className="information__value">{ selected?.size }</div>

                            </div>

                            <div className="information">

                                <div className="information__name">Felbontás</div>
                                <div className="information__value">{ selected?.width } x { selected?.height }</div>

                            </div>

                            <div className="information">

                                <div className="information__name">URL</div>
                                <div className="information__value">
                                    { selected !== null && selected.type !== `folder` ? (

                                        <div>

                                            <input type="hidden" name="url" value={ selected?.url } />
                                            <a href="#" onClick={ onURLCopyClick }>URL másolása</a>

                                        </div>

                                    ) : `` }

                                </div>

                            </div>

                            <div className="information">

                                <div className="information__name">Dátum</div>
                                <div className="information__value">{ selected !== null ? createFormattedDateString( selected.created ) : `` }</div>

                            </div>

                        </div>

                        <button className="button button--primary" disabled={ selected === null || selected.type !== `file` } onClick={ () => selectMedia( selected as MediaLibraryFile | MediaLibraryFolder ) }>

                            Használat

                        </button>

                        <div className="buttons">

                            <button className="button button--ghost" disabled={ selected === null } onClick={ e => renameFile() }>Átnevezés</button>
                            <button className="button button--ghost button--warning" disabled={ selected === null } onClick={ e => deleteFile() }>Törlés</button>

                        </div>

                    </div>

                </main>

                <div className={ `media-library__busy ${ busy ? ` media-library__busy--show` : `` }` }>

                    <div className="loading"></div>
                    <span>Feltöltés folyamatban!</span>

                </div>

            </div>

        </div>

    )

}
