import './lesson-page.scss'
import { LessonPage as LessonPageEntity, MediaLibraryFile } from '../../entity'
import { AddImage } from '../add-image'
import { useDispatch } from 'react-redux'
import { TextField } from '../field'
import { TextArea } from '../textarea'
import { DescriptionTextArea } from '../textarea/description'
import { useState } from 'react'
import { MediaLibrary } from '../media-library'
import { ImageEditor } from '../image-editor/image-editor'

interface LessonPageProps {

    page: LessonPageEntity

}

export const LessonPage = ( props: LessonPageProps ) => {

    const dispatch = useDispatch()

    const [ showMediaLibrary, setShowMediaLibrary ] = useState( false )
    const [ showImageEditor, setShowImageEditor ] = useState( false )
    const [ file, setFile ] = useState( null as MediaLibraryFile | null )

    const [ open, setOpen ] = useState( true )

    const u = ( field: string, value: any ) => {

        dispatch( { type: `UPDATE_LESSON_PAGE`, id: props.page.id, field, value } )

    }

    const onAddImage = () => {

        setShowMediaLibrary( true )

    }

    const onDeleteImage = () => {

        u( `image`, null )

    }

    const onDelete = () => {

        dispatch( { type: `DELETE_LESSON_PAGE`, id: props.page.id } )

    }

    return (

        <div className={ `lesson-page ${ open ? `lesson-page--open` : `` } card-div` }>

            { open ? (

                props.page.type === null ? (

                    <div className="type-select">

                        <div className="type" onClick={ () => u( `type`, 1 ) }>

                            <img alt="" src="/image/image-content.svg" />
                            <span>Képes tartalom</span>

                        </div>

                        <div className="type" onClick={ () => u( `type`, 2 ) }>

                            <img alt="" src="/image/video-content.svg" />
                            <span>Videós tartalom</span>

                        </div>

                        <div className="type" onClick={ () => u( `type`, 0 ) }>

                            <img alt="" src="/image/text-content.svg" />
                            <span>Szöveges tartalom</span>

                        </div>

                    </div>

                ) : (

                    <div className="form">

                        { props.page.type === 1 ? (

                            <div className="image-container">

                                <AddImage imageUrl={ props.page.image === null ? `` : props.page.image.url! } text="Kép hozzáadása a tananyaghoz" width="250px" height="141px" onAddImage={ onAddImage } onDeleteImage={ onDeleteImage } />

                            </div>

                        ) : null }

                        <div className="caption caption--small">Tananyag leadje</div>
                        <div className="info"></div>
                        <TextArea field={ { value: props.page!.lead, error: `` } } onChange={ e => u( `lead`, e.target.value ) } />

                        <div className="caption caption--small">Tananyag szövegének megadása</div>
                        <DescriptionTextArea field={ { value: props.page!.description, error: `` } } onChange={ html => u( `description`, html ) } />

                        { props.page.type === 2 ? (

                            <>

                                <div className="caption caption--small">Videó URL</div>
                                <div className="info"></div>
                                <TextField field={ { value: props.page!.videoUrl, error: `` } } onChange={ e => u( `videoUrl`, e.target.value ) } />

                            </>

                        ) : null }

                        <div className="form-footer">

                            <button className="button button--warning" onClick={ onDelete }>Törlés</button>

                            <button className="button button--expand" onClick={ () => setOpen( false ) }><img alt="" src="/image/chevron-left.svg" /></button>

                        </div>

                    </div>

                )

            ) : (

                <div className="lesson-page__header">

                    <h2 className="title-text-h2">{ props.page.lead }</h2>
                    <button className="button button--expand" onClick={ () => setOpen( true ) }><img alt="" src="/image/chevron-left.svg" /></button>

                </div>

            ) }

            { showMediaLibrary ? (

                <MediaLibrary onMediaSelected={ ( file: MediaLibraryFile ) => {
                    setFile( file )
                    setShowMediaLibrary( false )
                    setShowImageEditor( true )
                } } onClose={ () => setShowMediaLibrary( false ) } />

            ) : null }

            { showImageEditor ? (

                <ImageEditor aspectRatio={ { name: `Tananyag`, ratio: 16 / 9 } } mediaLibraryFile={ file! } onReady={ file => {
                    u( `image`, file )
                    setShowImageEditor( false )
                } } onClose={ () => setShowImageEditor( false ) } />

            ) : null }

        </div>

    )

}