import { getScheduledQuotes, listAllQuotes, listQuoteParties, listQuotes } from '../../action/quotes'
import { store } from '../../store'
import { getPoliticians } from '../../action/politician'

export const quotesLoader = () => {

    const tomorrow = new Date()
    tomorrow.setDate( tomorrow.getDate() + 1 )
    tomorrow.setHours( 2, 0, 0, 0 )

    store.dispatch( { type: `SET_KEYWORD`, keyword: `` } )
    getPoliticians()
    getScheduledQuotes( tomorrow )
    listQuotes()
    listAllQuotes()
    listQuoteParties()

}
