import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetNewsStatisticsDTO } from '../../dto/statistics'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { GET_NEWS_STATISTICS_ACTIONS } from './statistics.constants'

export const getNewsStatistics = async ( startDate?: Date, endDate?: Date ) => {

    store.dispatch( { type: GET_NEWS_STATISTICS_ACTIONS.GET_NEWS_STATISTICS_STARTED } )

    let start = null
    if(startDate != undefined ){
        start = startDate.getTime() / 1000
    }

    let end = null
    if(endDate != undefined ){
        end = endDate.getTime() / 1000
    }

    const promise = createCall(`cms/statistics/news?start=`+start+`&end=`+end, BASE_METHODS.GET)
    promise.then(async response => {

        let responseBody: GetNewsStatisticsDTO

        try {
            responseBody = plainToClass( GetNewsStatisticsDTO, JSON.parse( await response.text() ) )
        } catch ( exception ) {
            store.dispatch( { type: GET_NEWS_STATISTICS_ACTIONS.GET_NEWS_STATISTICS_INVALID_JSON } )
            return
        }
    
        if ( ( await validate( responseBody ) ).length > 0 ) {
            store.dispatch( { type: GET_NEWS_STATISTICS_ACTIONS.GET_NEWS_STATISTICS_INVALID_RESPONSE } )
            return
        }
    
        store.dispatch( { type: GET_NEWS_STATISTICS_ACTIONS.GET_NEWS_STATISTICS_OK, 
            newNewsCount: responseBody.newNewsCount, 
            newNewsXAxis: responseBody.newNewsXAxis,
            newNewsData: responseBody.newNewsData,
            newsReaderCount: responseBody.newsReaderCount, 
            newsReaderXAxis: responseBody.newsReaderXAxis,
            newsReaderData: responseBody.newsReaderData, 
            thisWeekNewsReadCount: responseBody.thisWeekNewsReadCount, 
            thisWeekNewsOpenCount: responseBody.thisWeekNewsOpenCount,
        } )

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })
   
}