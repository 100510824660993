import { Expose, Type } from 'class-transformer'
import { IsDefined, ValidateNested } from 'class-validator'
import { FloatingMessage } from '../../entity'

export class GetFloatingMessagesDTO {

    @Expose()
    @ValidateNested()
    @Type( () => FloatingMessage )
    floatingMessages!: FloatingMessage[]

    @Expose()
    @IsDefined()
    pagination!: {
        page: number
        from: number
        to: number
        count: number
    }

}

