export const createFormattedDateString = ( timestamp: number ): string => {
    const date = new Date( timestamp * 1000 )

    const dateString = date.getFullYear() + "." + ( date.getMonth() + 1 ).toString().padStart( 2, '0' ) + "." + date.getDate().toString().padStart( 2, '0' ) + "."

    return dateString
}

export const createFormattedTimeString = ( timestamp: number ): string => {
    const date = new Date( timestamp * 1000 )

    const dateString = date.getHours() + ":" + date.getMinutes()
    return dateString
}

export const createFormattedDateTimeString = ( timestamp: number ): string => {
    const date = new Date( timestamp * 1000 )

    const dateString = date.getFullYear() + "." + ( date.getMonth() + 1 ).toString().padStart( 2, '0' ) + "." + date.getDate().toString().padStart( 2, '0' ) + ". - " + date.getHours().toString().padStart( 2, '0' ) + ":" + date.getMinutes().toString().padStart( 2, '0' )

    return dateString
}

export const createFormattedFullDateString = ( timestamp: number ): string => {

    const days = [ `vasárnap`, `hétfő`, `kedd`, `szerda`, `csütörtök`, `péntek`, `szombat` ]
    const months = [ `január`, `február`, `március`, `április`, `május`, `június`, `július`, `augusztus`, `szeptember`, `október`, `november`, `december` ]
    const date = new Date( timestamp * 1000 )

    const dateString = date.getFullYear() + ". " + months[ date.getMonth() ] + " " + date.getDate().toString().padStart( 2, '0' ) + "., " + days[ date.getDay() ] + ` ` + date.getHours().toString().padStart( 2, `0` ) + `:` + date.getMinutes().toString().padStart( 2, `0` )

    return dateString

}

export const getLocalISOTime = (): string => {
    const date = new Date()

    const isoDateTime = new Date( date.getTime() - ( date.getTimezoneOffset() * 60000 ) ).toISOString()
    return isoDateTime.substring( 0, 16 ) //'2022-09-15T12:30'
}

export const getLocalISOTimePlus = ( year: number ): string => {
    const date = new Date()
    date.setFullYear( new Date().getFullYear() + year )

    const isoDateTime = new Date( date.getTime() - ( date.getTimezoneOffset() * 60000 ) ).toISOString()
    return isoDateTime.substring( 0, 16 ) //'2022-09-15T12:30'
}

export const createLocalISOTime = ( timestamp: number ): string => {
    const date = new Date( timestamp * 1000 )

    const isoDateTime = new Date( date.getTime() - ( date.getTimezoneOffset() * 60000 ) ).toISOString()
    return isoDateTime.substring( 0, 16 )
}


export const createFormattedTimeFromSec = ( sec: number ): string => {
    var h = Math.floor(sec / 3600);
    var m = Math.floor(sec % 3600 / 60);
    var s = Math.floor(sec % 3600 % 60);
    
    return ('0' + h).slice(-2) + ":" + ('0' + m).slice(-2) + ":" + ('0' + s).slice(-2);
}

export const format = ( format: string, timestamp: number ): string => {
    const date = new Date(timestamp * 1000);
    const month_short = [
        `jan`,
        `feb`,
        `már`,
        `ápr`,
        `máj`,
        `jún`,
        `júl`,
        `aug`,
        `sze`,
        `okt`,
        `nov`,
        `dec`,
    ];
    return format
        .replaceAll(`%Y`, date.getFullYear().toString())
        .replaceAll(`%d`, date.getDate().toString().padStart(2, `0`))
        .replaceAll(`%h`, date.getHours().toString().padStart(2, `0`))
        .replaceAll(`%i`, date.getMinutes().toString().padStart(2, `0`))
        .replaceAll(`%m`, month_short[date.getMonth()])
}