import { Expose, Type } from 'class-transformer'
import { IsDefined, ValidateNested } from 'class-validator'
import { User } from '../../entity'

export class GetUserDTO {

    @Expose()
    @IsDefined()
    @ValidateNested()
    @Type( type => User )
    user!: User

}
