import { QuestionnaireQuestion } from '../../../../entity'
import { QuestionnaireUserAnswerElement } from './questionnaire-user-answer-element'
import './questionnaire-user-answer-table.scss'

interface QuestionnaireUserAnswerTable {
    questionArray: QuestionnaireQuestion[]
    disabled?: boolean
}

export const QuestionnaireUserAnswerTable = ( props: QuestionnaireUserAnswerTable ) => {
    
    const questionElements: JSX.Element[] = []
    props.questionArray.map(( questionElement: QuestionnaireQuestion, index: number  ) => {
        questionElements.push( <QuestionnaireUserAnswerElement questionElement={ questionElement } disabled={ props.disabled === true } /> )
    })

    return (
        <div className="questionnaire-user-answer-table-container">

            <ul id={"questionnaire_user-answer_table"} className="questionnaire-user-answer-table">
                { questionElements.length != 0 ? questionElements : null }
            </ul>

        </div>
    )

}
