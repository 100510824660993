import { Expose } from 'class-transformer'
import { IsDefined, IsNumber } from 'class-validator'

export class GetPoliticianStatsDTO {

    @Expose()
    @IsDefined()
    @IsNumber()
    openCount!: number 
    
    @Expose()
    @IsDefined()
    @IsNumber()
    sendQuestionCount!: number  

    @Expose()
    @IsDefined()
    @IsNumber()
    openPercent!: number 

}
