import React, { useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux'
import { getMe } from './action'
import { prizesRouter, router } from './router'
import { store } from './store'

export const App = () => {

    useEffect( () => { getMe() }, [] )

    return (
        <React.StrictMode>
            <Provider store={ store }>
                <RouterProvider router={ process.env.REACT_APP_BUILD === `PRIZES` ? prizesRouter : router } />
            </Provider>
        </React.StrictMode>
    )

}
