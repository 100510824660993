import { LoaderFunctionArgs } from 'react-router-dom'
import { getCourse } from '../../action/courses'
import { listTags } from '../../action/tags'
import { store } from '../../store'
import { listDefaultExperiencePoints } from "../../action/gamification"

export const lessonLoader = async ( args: LoaderFunctionArgs ) => {

    await listDefaultExperiencePoints()
    await listTags()
    await getCourse( parseInt( args.params.courseId! ) )

    if ( isNaN( parseInt( args.params.id! ) ) ) {

        store.dispatch( {
            type: `CREATE_LESSON`,
            oneStarExperience: store.getState().gamification.defaultExperiencePoints.find( d => d.name === `courseonestar` )?.experience || 100,
            twoStarExperience: store.getState().gamification.defaultExperiencePoints.find( d => d.name === `coursetwostar` )?.experience || 200,
            threeStarExperience: store.getState().gamification.defaultExperiencePoints.find( d => d.name === `coursethreestar` )?.experience || 300
        } )

    } else {

        store.dispatch( { type: `SELECT_LESSON`, id: parseInt( args.params.id! ) } )

    }

}