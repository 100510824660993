import { Expose, Type } from 'class-transformer'
import { IsDefined, ValidateNested } from 'class-validator'
import { Event } from '../../entity'

export class GetEventDTO {

    @Expose()
    @IsDefined()
    @Type( type => Event )
    @ValidateNested()
    event!: Event

}

