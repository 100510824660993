import { store } from '../../store'

export const unreportEventImage = async ( id: number ) => {

    let response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/event-images/${ id }/unreport`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'POST'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'UNREPORT_EVENT_IMAGE_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'UNREPORT_EVENT_IMAGE_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'UNREPORT_EVENT_IMAGE_UNEXPECTEDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'UNREPORT_EVENT_IMAGE_OK' } )

}
