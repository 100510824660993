import { MouseEvent, MouseEventHandler } from 'react'
import { Modal } from './modal'
import './message-modal.scss'

interface MessageModalProps {
    title: string
    message: string
    onCloseClick?: MouseEventHandler
    onOkClick?: MouseEventHandler
}

export const MessageModal = ( props: MessageModalProps ) => {

    return (
        <Modal>

            <div className="message-modal">

                <div className="message-modal__header">
                    <div className="message-modal__title"> {props.title} </div>
                    <button className="close-button" onClick={ props.onCloseClick }> <img alt="bezárás gomb" src="/image/close.svg" /> </button>
                </div>

                <div className="">
                    <h3 className="message-modal__message">{props.message}</h3>
                </div>

                <div className="message-modal__footer">
                    { props.onOkClick ? <button className="button" type="button" onClick={ props.onOkClick } > OK </button> : null }
                    <button className="button button-left-margin" type="button" onClick={ props.onCloseClick } > { props.onOkClick ? "Mégse" : "OK" }</button>
                </div>

            </div>

        </Modal>
    )

}
