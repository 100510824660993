import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetNewsCategoriesDTO } from '../../dto/news-category'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { GET_NEWS_CATEGORIES_ACTIONS } from './news-category.constants'

export const getNewsCategories = async () => {

    const promise = createCall('news-category', BASE_METHODS.GET)
    promise.then(async response => {

        let body: GetNewsCategoriesDTO

        try {
            body = plainToClass( GetNewsCategoriesDTO, JSON.parse( await response.text() ) )
        } catch ( exception ) {
            store.dispatch( { type: GET_NEWS_CATEGORIES_ACTIONS.GET_NEWS_CATEGORIES_INVALID_JSON } )
            return
        }
    
        if ( ( await validate( body ) ).length > 0 ) {
            store.dispatch( { type: GET_NEWS_CATEGORIES_ACTIONS.GET_NEWS_CATEGORIES_INVALID_RESPONSE } )
            return
        }
    
        store.dispatch( { type: GET_NEWS_CATEGORIES_ACTIONS.GET_NEWS_CATEGORIES_OK, newsCategories: body.newsCategories } )

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })
   
}
