import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Calendar, QuoteCard, QuotesModal } from '../../../component'
import { State } from "../../../state";
import "./quotes-calendar.page.scss";
import { getMonthQuotes, getScheduledQuotes, getThreeScheduledQuotes, listAllQuotes, replaceQuoteCard } from '../../../action/quotes'
import { useState } from 'react'
import { createFormattedDateString } from '../../../helper'

export const QuotesCalendarPage = () => {

  const { monthQuotes, tomorrow } = useSelector((state: State) => state.quotes);

    const [replaceScheduledQuote, setReplaceScheduledQuote] = useState(
        null as number | null
    );
    const [showQuotesModal, setShowQuotesModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null as Date | null);
    const [clickDate, setClickDate] = useState(null as Date | null);

    const onQuoteSelected = async (id: number | null) => {
        await replaceQuoteCard(selectedDate!, replaceScheduledQuote!, id);
        await getScheduledQuotes( clickDate! );
        setSelectedDate(null);
        setShowQuotesModal(false);
        setReplaceScheduledQuote(null);
        await listAllQuotes();
    };

    const onRemoveCard = async (date: Date, slot: number) => {
        await replaceQuoteCard(date, slot, null);
        await getScheduledQuotes( clickDate! );
        await listAllQuotes();
    };

    const onQuoteCardClick = (date: Date, num: number) => {
        setSelectedDate(date);
        setReplaceScheduledQuote(num);
        setShowQuotesModal(true);
    };

    const getDay = ( item: any ) => {

        setClickDate( new Date( item.event.startDate * 1000 ) )
        getScheduledQuotes( new Date( item.event.startDate * 1000 ) )

    }

    const changeMonth = async ( year: number, month: number ) => {

        await getMonthQuotes( new Date( year, month, 1, 0, 0, 0, 0 ) )

    }

  return (
    <main className="page page--quotes-calendar">
      <div className="main-div">
        <div className="navigation-title">
          <NavLink to="/velemenyvezerek">
            <h1 className="title-text-h1">Véleményvezérek &gt;</h1>
          </NavLink>
          <h1 className="title-text-h1">Ütemezett idézetkártyák - naptár</h1>
        </div>

        <div className="toolbar">
          <NavLink to="/velemenyvezerek" style={{ marginRight: `auto` }}>
            <button className="button button--ghost" style={{ minWidth: 173 }}>
              Vissza
            </button>
          </NavLink>

          <NavLink to="/velemenyvezerek" style={{ marginRight: 10 }}>
            <button className="button button--ghost" style={{ minWidth: 247 }}>
              Idézetek főoldal
            </button>
          </NavLink>

          <NavLink to="/velemenyvezerek/idezetek/">
            <button className="button" style={{ minWidth: 202 }}>
              + Új idézet
            </button>
          </NavLink>
        </div>

        <div className="card-div">
          <Calendar items={ monthQuotes } onItemClick={ item => getDay( item ) } onMonthChange={ ( year, month ) => changeMonth( year, month ) } />
        </div>

          { clickDate !== null ? (

              <div className="card-div day-quotes" style={{ marginTop: 30 }}>
                  <div className="title-text-h2">
                      { createFormattedDateString( clickDate!.getTime() / 1000 ) }, ütemezett idézetkártyák
                  </div>

                  <div className="quote-cards">
                      {tomorrow.map((quote: any, index: number) => (
                          <QuoteCard key={index} quote={quote} onClick={(e) => onQuoteCardClick( clickDate!, index ) } onDeleteClick={(e) => onRemoveCard( clickDate!, index ) } />
                      ))}

                      <div className="quote-info">

                          <div className="quote-info__title">

                              Info

                          </div>

                          <div className="quote-info__description">

                              Legközelebb a következő nap éjfélkor jelennek meg az általad választott polikikusi idézetek.<br />
                              A kártya, amelyre nem tettél idézetet, az véletlenszerűen kerül kitöltésre!<br />
                              A kártyákat tudod ütemezni is a későbbi napokra! Használd az “Idézetkártyák a naptárban” gombot a naptár megnyitásához.

                          </div>

                      </div>

                  </div>
              </div>

          ) : null }

      </div>

        {showQuotesModal ? (
            <QuotesModal
                onModalClose={() => setShowQuotesModal(false)}
                onSelect={onQuoteSelected}
            />
        ) : null}

    </main>
  );
};
