import './stat.page.scss'
import { useSelector } from 'react-redux'
import { State } from '../../../../state'
import { county } from '../../../../helper'

export const StatPage = () => {

    const { stats } = useSelector( ( state: State ) => state.gamification )
    const fields = [
        { name: `Felhasználók átlagéletkora`, value: `${ stats?.averageAge } év` },
        { name: `Felhasználók 50 év fölött`, value: `${ stats?.olderThan50 }%` },
        { name: `Felhasználók 50 év alatt`, value: `${ ( 100 - stats?.olderThan50! ) }%` },
        { name: `Eddig továbbjutottak`, value: `${ stats?.alreadyAdvanced } fő` },
        { name: `Innen jutottak tovább a legtöbben`, value: county( stats?.mostAdvancedFromCounty! ) },
        { name: `Átlagos továbbjutási idő`, value: `${ stats?.averageAdvanceTime } nap` },
        { name: `Leggyorsabban teljesített kihívás`, value: stats?.quickestQuest },
        { name: `Leglassabban teljesített kihívás`, value: stats?.slowestQuest },
        { name: `Leggyorsabban teljesített kihívás csoport`, value: stats?.quickestQuestGroup },
        { name: `Leglassabban teljesített kihívás csoport`, value: stats?.slowestQuestGroup }
    ]

    return (

        <div className="card-div stat-page">

            { fields.map( field => (

                <div className="card-div">

                    <div className="label">{ field.name }:</div>
                    <div className="value">{ field.value }</div>

                </div>

            ) ) }

            { /*

            <div className="card-div">

                <div className="label">Leggyorsabban teljesített kihívás:</div>
                <div className="value">Ismerj meg 3 politikust</div>

            </div>

            <div className="card-div">

                <div className="label">Leglassabban teljesített kihívás:</div>
                <div className="value">Ismerj meg 3 politikust</div>

            </div>

            <div className="card-div">

                <div className="label">Leggyorsabban teljesített kihívás csoport:</div>
                <div className="value">Ismerj meg 3 politikust</div>

            </div>

            <div className="card-div">

                <div className="label">Leglassabban teljesített kihívás csoport:</div>
                <div className="value">Ismerj meg 3 politikust</div>

            </div> */ }

        </div>

    )

}