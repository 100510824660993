import { useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import {getScheduledQuotes, listAllQuotes, listQuotes, replaceQuoteCard} from '../../../action/quotes'
import { Dropdown, QuoteCard, QuotePartiesModal, QuotesModal, QuotesTable } from '../../../component'
import { SearchButton, SearchInput } from '../../../component/search'
import { State } from '../../../state'
import { onSearchButtonClick, onSearchInputChange } from '../quotes/event-listener'
import './quotes.page.scss'

const timelines = [
    { id: 0, value: `Bármikor` },
    { id: 1, value: `Elmúlt egy héten` },
    { id: 2, value: `Elmúlt egy hónapban` },
    { id: 3, value: `Elmúlt három hónapban` },
    { id: 4, value: `Elmúlt fél évben` },
    { id: 5, value: `Elmúlt évben` },
]

export const QuotesPage = () => {

    const dispatch = useDispatch()
    const { tomorrow, deleted, pagination, selectedTimeline, selectedPolitician } = useSelector( ( state: State ) => state.quotes )
    const { politicians } = useSelector( ( state: State ) => state.politicians )
    const [ replaceScheduledQuote, setReplaceScheduledQuote ] = useState( null as number | null )
    const [ showQuotesModal, setShowQuotesModal ] = useState( false )
    const [ showQuotePartiesModal, setShowQuotePartiesModal ] = useState( false )

    const navigate = useNavigate()

    const onQuoteCardClick = ( num: number ) => {

        setReplaceScheduledQuote( num )
        setShowQuotesModal( true )

    }

    const onQuoteSelected = async ( id: number | null ) => {

        const tomorrow = new Date()
        tomorrow.setDate( tomorrow.getDate() + 1 )
        tomorrow.setHours( 2, 0, 0, 0 )

        await replaceQuoteCard( tomorrow, replaceScheduledQuote!, id )
        await getScheduledQuotes( tomorrow )
        setShowQuotesModal( false )
        setReplaceScheduledQuote( null )
        await listAllQuotes()

    }

    const onRemoveCard = async ( slot: number ) => {

        const tomorrow = new Date()
        tomorrow.setDate( tomorrow.getDate() + 1 )
        tomorrow.setHours( 2, 0, 0, 0 )

        await replaceQuoteCard( tomorrow, slot, null )
        await getScheduledQuotes( tomorrow )
        await listAllQuotes()

    }

    const changeShowDeleted = () => {

        dispatch( { type: `QUOTES_TOGGLE_DELETED` } )
        listQuotes( pagination.page )

    }

    const setSelectedPolitician = async ( id: number ) => {

        dispatch( { type: `QUOTES_SET_SELECTED_POLITICIAN`, selectedPolitician: id } )
        await listQuotes()

    }

    const setSelectedTimeline = async ( id: number ) => {

        dispatch( { type: `QUOTES_SET_SELECTED_TIMELINE`, selectedTimeline: id } )
        await listQuotes()

    }

    return (

        <main className="page page--quotes">

            <div className="main-div">

                <div className="navigation-title">

                    <h1 className="title-text-h1">Véleményvezérek</h1>

                </div>

                <div className="toolbar">

                    <SearchInput onSearch={ onSearchButtonClick } placeholder="Keresés az idézetek között" onChange={ onSearchInputChange } />

                    <SearchButton onClick={ onSearchButtonClick } />

                    <button className="button button--ghost deleted-button" style={ { minWidth: 169 } } onClick={ e => changeShowDeleted() }>

                        { deleted ? `Aktív` : `Törölt` }

                    </button>

                    <button className="button button--ghost parties-button" style={ { minWidth: 239 } } onClick={ e => setShowQuotePartiesModal( true ) }>Politikai pártok</button>

                    <NavLink to="/velemenyvezerek/idezetek" className="new-a">

                        <button className="button new-button" style={ { minWidth: 202 } }>+ Új idézet</button>

                    </NavLink>

                </div>

                <div className="card-div">

                    <h2 className="title-text-h2">Holnapi idézetkártyák</h2>

                    <div className="quote-cards">

                        { tomorrow.map( ( quote, index ) => (

                            <QuoteCard key={ index } quote={ quote } onClick={ e => onQuoteCardClick( index ) } onDeleteClick={ e => onRemoveCard( index ) } />

                        ) ) }

                        <div className="quote-info">

                            <div className="quote-info__title">

                                Info

                            </div>

                            <div className="quote-info__description">

                                Legközelebb a következő nap éjfélkor jelennek meg az általad választott polikikusi idézetek.<br />
                                A kártya, amelyre nem tettél idézetet, az véletlenszerűen kerül kitöltésre!<br />
                                A kártyákat tudod ütemezni is a későbbi napokra! Használd az “Idézetkártyák a naptárban” gombot a naptár megnyitásához.

                            </div>

                        </div>

                    </div>

                    <footer className="quote-cards-footer">

                        <button className="button sch-button" style={ { minWidth: 313 } } onClick={ e => navigate( `/velemenyvezerek/utemezett` ) }>Ütemezett idézetkártyák</button>
                        <button className="button scheduled-button" style={ { minWidth: 409 } } onClick={ e => navigate( `/velemenyvezerek/naptar` ) }>Ütemezett idézetkártyák a naptárban</button>

                    </footer>

                </div>

                <div className="filters">

                    <div className="politician-filter">

                        <Dropdown value={ selectedPolitician } items={ [ { id: -1, value: `Bármelyik politikus` }, ... ( politicians || [] ).map( p => ( { id: p.id, value: `${ p.lastName } ${ p.firstName }` } ) ) ] } onChange={ setSelectedPolitician } compact={ false } />

                    </div>

                    <div className="timeline-filter">

                        <Dropdown value={ selectedTimeline } items={ timelines } onChange={ setSelectedTimeline } compact={ false } />

                    </div>

                </div>

                <div className="card-div">

                    <QuotesTable />

                </div>

            </div>

            { showQuotesModal ? (

                <QuotesModal onModalClose={ () => setShowQuotesModal( false ) } onSelect={ onQuoteSelected } />

            ) : null }

            { showQuotePartiesModal ? (

                <QuotePartiesModal onModalClose={ () => setShowQuotePartiesModal( false ) } />

            ) : null }

        </main>

    )

}
