import { modifyNewsStatus } from "../../../action/news"
import { News } from "../../../entity"
import { NewsStatus } from "../../../enum/news-status.enum"
import { Dropdown } from "../../dropdown"

interface NewsTableStatusDropdownProps {
    newsElement: News
}

export const NewsTableStatusDropdown = ( props: NewsTableStatusDropdownProps ) => {

    const statusOptions = [
        { id: NewsStatus.Active, value: 'Aktív', color:"#27B06C" },
        { id: NewsStatus.Draft, value: 'Vázlat', color:"#59595B" },
        { id: NewsStatus.Deleted, value: 'Törölt', color:"#C30870" },
    ]

    const onStatusChange = ( itemId: any ) => {
        modifyNewsStatus([props.newsElement.id], itemId)

    }

    return (
        <Dropdown value={ props.newsElement.status } items={ statusOptions } onChange={ onStatusChange } compact={ true } />
    )

}