import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetEventsStatisticsDTO } from '../../dto/statistics'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { GET_EVENTS_STATISTICS_ACTIONS } from './statistics.constants'

export const getEventsStatistics = async ( startDate?: Date, endDate?: Date ) => {

    store.dispatch( { type: GET_EVENTS_STATISTICS_ACTIONS.GET_EVENTS_STATISTICS_STARTED } )

    let start = null
    if(startDate != undefined ){
        start = startDate.getTime() / 1000
    }

    let end = null
    if(endDate != undefined ){
        end = endDate.getTime() / 1000
    }

    const promise = createCall(`cms/statistics/events?start=`+start+`&end=`+end, BASE_METHODS.GET)
    promise.then(async response => {

        let responseBody: GetEventsStatisticsDTO

        try {
            responseBody = plainToClass( GetEventsStatisticsDTO, JSON.parse( await response.text() ) )
        } catch ( exception ) {
            store.dispatch( { type: GET_EVENTS_STATISTICS_ACTIONS.GET_EVENTS_STATISTICS_INVALID_JSON } )
            return
        }
    
        if ( ( await validate( responseBody ) ).length > 0 ) {
            store.dispatch( { type: GET_EVENTS_STATISTICS_ACTIONS.GET_EVENTS_STATISTICS_INVALID_RESPONSE } )
            return
        }
    
        store.dispatch( { type: GET_EVENTS_STATISTICS_ACTIONS.GET_EVENTS_STATISTICS_OK, 
            eventAttendanceCount: responseBody.eventAttendanceCount, 
            eventAttendanceXAxis: responseBody.eventAttendanceXAxis,
            eventAttendanceData: responseBody.eventAttendanceData
        } )

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })
   
}