
import { Expose, Type } from 'class-transformer'
import { IsArray, IsDefined, IsNumber, IsOptional, ValidateNested } from 'class-validator'

export class GetNewsStatisticsDTO {

    @Expose()
    @IsDefined()
    @IsNumber()
    newNewsCount!: number 

    @Expose()
    @IsDefined()
    @IsArray()
    newNewsXAxis!: [] 

    @Expose()
    @IsDefined()
    @IsArray()
    newNewsData!: [] 

    @Expose()
    @IsDefined()
    @IsNumber()
    newsReaderCount!: number 

    @Expose()
    @IsDefined()
    @IsArray()
    newsReaderXAxis!: [] 

    @Expose()
    @IsDefined()
    @IsArray()
    newsReaderData!: [] 

    @Expose()
    @IsDefined()
    @IsNumber()
    thisWeekNewsOpenCount!: number 

    @Expose()
    @IsDefined()
    @IsNumber()
    thisWeekNewsReadCount!: number 

}



