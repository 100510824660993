import { Expose, Type } from 'class-transformer'
import { IsDefined, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'
import { ImageFile } from './image-file.entity'
import { MediaLibraryFile } from './media-library-file.entity'
import { QuestionnaireQuestionAnswer } from './questionnaire-question-answer.entity'
import { QuestionnaireQuestionUserAnswer } from './questionnaire-question-user-answer.entity'

export class QuestionnaireQuestion {

    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number 

    @Expose()
    @IsDefined()
    @IsString()
    question!: string

    @Expose()
    @IsDefined()
    @IsString()
    description!: string

    @Expose()
    @IsDefined()
    @IsNumber()
    order!: number

    @IsOptional()
    @IsDefined()
    @IsNumber()
    scaleFrom!: number | null

    @IsOptional()
    @IsDefined()
    @IsString()
    scaleFromTitle!: string | null

    @IsOptional()
    @IsDefined()
    @IsNumber()
    scaleTo!: number | null

    @IsOptional()
    @IsDefined()
    @IsString()
    scaleToTitle!: string | null

    @Expose()
    @IsDefined()
    @IsNumber()
    type!: number

    @IsOptional()
    @ValidateNested()
    @Type( () => ImageFile )
    imageFile?: ImageFile | null

    @IsOptional()
    @ValidateNested()
    @Type( () => QuestionnaireQuestionAnswer )
    answers?: QuestionnaireQuestionAnswer[] | null

    @IsOptional()
    @ValidateNested()
    @Type( () => QuestionnaireQuestionUserAnswer )
    userAnswers?: QuestionnaireQuestionUserAnswer[] | null

    //mezők a formhoz
    questionField!: {
        error: string
        value: string 
        required: boolean
    }

    descriptionField!: {
        error: string
        value: string 
        required: boolean
    }

    scaleToTitleField!: {
        error: string
        value: string 
        required: boolean
    }

    scaleFromTitleField!: {
        error: string
        value: string 
        required: boolean
    }

    imageUrl!: string

    mediaLibraryFile!: MediaLibraryFile | null

    isNew!: boolean

}




