
import { Expose, Type } from 'class-transformer'
import { IsArray, IsDefined, IsNumber, IsOptional, ValidateNested } from 'class-validator'

export class GetStatisticsDTO {

    @Expose()
    @IsDefined()
    @IsNumber()
    applicationLaunchedCount!: number 

    @Expose()
    @IsDefined()
    @IsArray()
    applicationLaunchedXAxis!: [] 

    @Expose()
    @IsDefined()
    @IsArray()
    applicationLaunchedData!: [] 

    @Expose()
    @IsDefined()
    @IsNumber()
    userCount!: number 

    @Expose()
    @IsDefined()
    @IsArray()
    usersChartXAxis!: [] 

    @Expose()
    @IsDefined()
    @IsArray()
    usersChartData!: [] 

    @Expose()
    @IsDefined()
    @IsNumber()
    iOSCount!: number 

    @Expose()
    @IsDefined()
    @IsNumber()
    androidCount!: number 

}

