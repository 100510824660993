import { MenuButton } from '..'
import './info-slot.scss'

interface InfoSlotProps {

    additionalInfo: string
    caption: string
    color: string
    info: {
        suffix: string,
        value: string
    }

}

export const InfoSlot = ( props: InfoSlotProps ) => {

    return (

        <div className={ `info-slot info-slot--${ props.color }` }>

            <div className="info-slot__description">

                <span className="caption">

                    { props.caption }

                </span>

                <span className="additional-info">

                    { props.additionalInfo }

                </span>

            </div>

            <div className="info-slot__info">

                <span className="value">{ props.info.value }</span> { props.info.suffix }

            </div>

            <div className="info-slot__menu-button">

                <MenuButton hasBackground={ true } onChange={ () => { } } items={ [] } />

            </div>

        </div>


    )

}
