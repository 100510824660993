import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetMeDTO } from '../../dto'
import { store } from '../../store'

export const getMe = async () => {

    let body: GetMeDTO
    let response: Response
    const token = store.getState().session.token

    if ( token === null ) {

        store.dispatch( { type: 'GET_ME_NOUSER' } )
        return

    }

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/users/me`, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            method: 'get'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'GET_ME_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'GET_ME_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'GET_ME_UNEXPECTEDRESPONSE' } )
        return

    }

    try {

        body = plainToClass( GetMeDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'GET_ME_INVALIDJSON' } )
        return

    }

    if ( ( await validate( body ) ).length > 0 ) {

        store.dispatch( { type: 'GET_ME_INVALIDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'GET_ME_OK', user: body.user } )

}
