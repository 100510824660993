import { VotesState } from "../state"
import { message } from "../helper"

const initialPushState: VotesState = {
    votes: [],
    pagination: {
        count: 0,
        from: 0,
        page: 1,
        to: 0,
    },
}

export const votesReducer = (state: VotesState = initialPushState, action: any): VotesState => {
    console.log( action )
    switch (action.type) {
        case `LIST_VOTES_OK`:
            return { ...state, votes: action.votes, pagination: action.pagination }
        case `SAVE_VOTE_OK`:
            message( `A szavazás sikeresen mentésre került!` )
            return state
        case `DELETE_VOTE_OK`:
            message( `A szavazás sikeresen törlésre került!` )
            return state
        default:
            return state
    }
}
