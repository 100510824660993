import { Expose, Type } from 'class-transformer'
import { ValidateNested } from 'class-validator'
import { News } from '../../entity'

export class GetNewsDTO {

    @Expose()
    @ValidateNested()
    @Type( () => News )
    news!: News
    
}
