import { LoaderFunctionArgs } from 'react-router-dom'
import { getQuestionnaires, getQuestionnairesStats } from '../../action/questionnaires'
import { GET_QUESTIONNAIRES_ACTIONS } from '../../action/questionnaires/questionnaires.constants'
import { store } from '../../store'

export const questionnaireListLoader = async ( args: LoaderFunctionArgs ) => {

    store.dispatch( { type: GET_QUESTIONNAIRES_ACTIONS.GET_QUESTIONNAIRES_SEARCH, search: "" }  )

    await getQuestionnairesStats() 
    await getQuestionnaires() 

}
