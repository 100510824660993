
import { Expose, Type } from 'class-transformer'
import { IsDefined, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'
import { PoliticianQuestionAnswer } from './politician-question-answer.entity'

export class PoliticianQuestion {

    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number 

    @Expose()
    @IsDefined()
    @IsString()
    question!: string

    @IsOptional()
    @ValidateNested()
    @Type( () => PoliticianQuestionAnswer )
    answers?: PoliticianQuestionAnswer[] | null

}
