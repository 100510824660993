import { store } from "../../store"
import { createCall } from "../base"
import { BASE_METHODS } from "../base/base.constants"
import { getPoliticians } from "./get-politicians.action"
import { DELETE_POLITICIAN_ACTIONS } from "./politicians.constants"

export const deletePolitician = async (politicianId:number) => {

    store.dispatch( { type: DELETE_POLITICIAN_ACTIONS.DELETE_POLITICIAN_STARTED } )

    const promise = createCall(`politicians/`+politicianId, BASE_METHODS.DELETE)
    promise.then(async response => {

        store.dispatch( { type: DELETE_POLITICIAN_ACTIONS.DELETE_POLITICIAN_OK, response: response.text() } )

        getPoliticians()

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })

}
