import { store } from '../../store'

export const saveVideoCategory = async ( category: any ) => {

    let response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/2.0.0/cms/videos/categories`, {
            body: JSON.stringify( category ),
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'POST'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'SAVE_VIDEO_CATEGORY_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'SAVE_VIDEO_CATEGORY_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'SAVE_VIDEO_CATEGORY_UNEXPECTEDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'SAVE_VIDEO_CATEGORY_OK' } )

}