import './quest-group-settings.scss'
import { TextField } from '../field'
import { TextArea } from '../textarea'
import { AddImage } from '../add-image'
import { DescriptionTextArea } from '../textarea/description'
import { ImageFile, League, MediaLibraryFile, Tag } from '../../entity'
import { useState } from 'react'
import { MediaLibrary } from '../media-library'
import { ImageEditor } from '../image-editor/image-editor'
import { TagSelector } from '../tag-selector'

interface QuestGroupSettingsProps {

    name: { error: string, value: string }
    lead: { error: string, value: string }
    description: { error: string, value: string }
    experience: { error: string, value: string }
    tags: Tag[]
    leagues: League[]
    image: ImageFile | null
    hideLeagues?: boolean

    onFieldChange?: ( field: string, value: any ) => void

}

export const QuestGroupSettings = ( props: QuestGroupSettingsProps ) => {

    const [ mediaLibraryVisibility, setMediaLibraryVisibility ] = useState( false )
    const [ imageEditorVisibility, setImageEditorVisibility ] = useState( false )
    const [ image, setImage ] = useState( null as MediaLibraryFile | null )
    const [ selectedLeagues, setSelectedLeagues ] = useState( props.leagues.map( l => l.id ) )

    const onFieldChange = ( field: string, value: any ) => {

        if ( props.onFieldChange ) props.onFieldChange( field, value )

    }

    const onLeagueClick = ( leagueId: number ) => {

        let newleagues = []

        if ( selectedLeagues.indexOf( leagueId ) !== -1 ) {

            newleagues = selectedLeagues.filter( l => l !== leagueId )

        } else {

            newleagues = selectedLeagues
            newleagues.push( leagueId )

        }

        setSelectedLeagues( [ ... newleagues ] )
        onFieldChange( `selectedLeagues`, newleagues )

    }

    const leagues = [
        { id: 2, name: `Bronz liga`, icon: `/image/leagues/0002.png` },
        { id: 3, name: `Ezüst liga`, icon: `/image/leagues/0003.png` },
        { id: 4, name: `Arany liga`, icon: `/image/leagues/0004.png` },
        { id: 5, name: `DK liga`, icon: `/image/leagues/0005.png` }
    ]

    return (

        <div className="quest-group-settings">

            <div className="left-column">

                <div className="field">

                    <label>Kihívás csoport megnevezése *</label>
                    <TextField field={ props.name } onChange={ e => onFieldChange( `name`, e.target.value ) } />

                </div>

                <div className="field">

                    <label>Kihívás lead *</label>
                    <TextArea field={ props.lead } onChange={ e => onFieldChange( `lead`, e.target.value ) } />

                </div>

            </div>

            <div className="right-column">

                <div className="field">

                    <label>Kihívás képe (16:9)</label>
                    <AddImage width={ "207.5px" } height={ "121px" } imageUrl={ props.image === null ? `` : props.image.url } text={ "Kép hozzáadása (16:9)" }
                        onAddImage={ () => {
                            setMediaLibraryVisibility( true )
                        } }
                        onDeleteImage={ () => {
                            onFieldChange( `image`, null )
                        } }
                    />

                </div>

            </div>

            <div className="field">

                <label>Kihívás szövegének megadása *</label>
                <DescriptionTextArea field={ props.description } onChange={ text => onFieldChange( `description`, text ) } />

            </div>

            <div className="card-div experience-div">

                <div className="field">

                    <div className="field">

                        <label>Tapasztalati pont értéke *</label>
                        <TextField field={ props.experience } onChange={ e => onFieldChange( `exp`, isNaN( parseInt( e.target.value ) ) ? 0 : parseInt( e.target.value ) ) } />

                    </div>

                </div>

            </div>

            <div className="card-div tags-div">

                <TagSelector tags={ props.tags } onTagAdd={ tag => onFieldChange( `tags`, [ ...props.tags, tag ] ) } onTagDelete={ tag => onFieldChange( `tags`, [ ...props.tags.filter( t => t.id !== tag.id ) ] ) } />

            </div>

            { props.hideLeagues !== true ? (

            <div className="league-selector">

                <div className="field">

                    <label>Ligába elérhető</label>

                        <div className="leagues">

                            { leagues.map( league => (

                                <div className={ `league${ selectedLeagues.indexOf( league.id ) !== -1 ? ` league--selected` : `` }` } onClick={ e => onLeagueClick( league.id ) }>

                                    <div className="league__icon">

                                        <img src={ league.icon } />

                                    </div>

                                    <div className="league__name">

                                        { league.name }

                                    </div>

                                </div>

                            ) ) }

                        </div>

                </div>

            </div>

            ) : null }

            { mediaLibraryVisibility ? <MediaLibrary onClose={ () => setMediaLibraryVisibility( false ) } onMediaSelected={ ( file: MediaLibraryFile ) => {
                setImage( file )
                setMediaLibraryVisibility( false )
                setImageEditorVisibility( true )
            } } /> : null }

            { imageEditorVisibility ? <ImageEditor mediaLibraryFile={ image! } aspectRatio ={{name: "16:9", ratio: 16/9}} onClose={ () => {
                setImageEditorVisibility( false )
                setImage( null )
            } } onReady={ file => {
                setImage( file )
                onFieldChange( `image`, file )
                setImageEditorVisibility( false )
            } } /> : null }

        </div>

    )

}