import './winners.page.scss'
import { SearchButton, SearchInput } from '../../component/search'
import { ChangeEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../state'
import { listUserRounds } from '../../action/gamification'
import { format } from '../../helper'
import { WinnersTable } from '../../component/winners-table'
import { listRounds } from '../../action/winners'

const weekGroups = [ `1. - 10. hét`, `11. - 20. hét`, `21. - 30. hét`, `31. - 40. hét`, `41. - 50. hét`, `51. - 53. hét` ]

export const WinnersPage = () => {

    const dispatch = useDispatch()

    const { years, userRoundsPagination, userRoundsOrder } = useSelector( ( state: State ) => state.gamification )
    const [ keyword, setKeyword ] = useState( `` )

    const search = () => { listRounds( keyword ) }

    const onKeywordChange = ( e: ChangeEvent<HTMLInputElement> ) => {

        setKeyword( e.target.value )
        if ( e.target.value.length === 0 ) {
            listRounds()
        }

    }

    return (

        <main className="page page--winners">

            <div className="main-div">

                <div className="navigation-title">

                    <h1 className="title-text-h1">Nyertesek</h1>

                </div>

                <div className="toolbar">

                    <SearchInput onSearch={ search } onChange={ onKeywordChange  } placeholder="Keresés a nyertesek között" />
                    <SearchButton onClick={ search } />

                    { /* <button className="button button--primary button--new">+ Új nyertes</button> */ }

                </div>

                { Object.keys( years ).map( year => (

                    <div className={ `year${ years[ year ].open === true ? ` year--open` : `` }` }>

                        <div className="year__header" onClick={ () => dispatch( { type: `TOGGLE_YEAR`, year: year } ) }>

                            <span>{ year } év</span>
                            <button className="button button--expand">

                                <img alt="" src="/image/chevron-left.svg" />

                            </button>

                        </div>

                        { years[ year ].open ? (

                            <div className="year__body">

                                { Object.keys( years[ year ] ).filter( key => key !== `open` ).map( ( weekGroup: string ) => (

                                    <div className={ `week-group${ years[ year ][ weekGroup ].open === true ? ` week-group--open` : `` }` }>

                                        <div className="week-group__header" onClick={ () => dispatch( { type: `TOGGLE_WEEKGROUP`, year: year, weekGroup: weekGroup } ) }>

                                            <span>{ weekGroups[ parseInt( weekGroup ) ] }, { year }</span>
                                            <button className="button button--expand">

                                                <img alt="" src="/image/chevron-left.svg" />

                                            </button>

                                        </div>

                                        { years[ year ][ weekGroup ].open ? (

                                            <div className="week-group__body">

                                                { years[ year ][ weekGroup ].filter( ( round: any, index: string ) => index !== `open` ).map( ( round: any, index: number ) => (

                                                    <div className={ `week ${ years[ year ][ weekGroup ][ index ].open === true ? `week--open` : `` } ` }>

                                                        <div className="week__header" onClick={ () => dispatch( { type: `TOGGLE_WEEK`, year: year, weekGroup: weekGroup, index: index } ) }>

                                                            <span>{ round.week }. hét - { format( `%m. %d`, round.round.startDate ) } - { format( `%m. %d`, round.round.endDate ) }</span>
                                                            <button className="button button--expand">

                                                                <img alt="" src="/image/chevron-left.svg" />

                                                            </button>

                                                        </div>

                                                        { years[ year ][ weekGroup ][ index ].open ? (

                                                            <div className="card-div">

                                                                <WinnersTable keyword={ keyword } roundId={ round.round.id } />

                                                            </div>

                                                        ) : null }

                                                    </div>

                                                ) ) }

                                            </div>

                                        ) : null }

                                    </div>

                                ) ) }

                            </div>

                        ) : null }

                    </div>

                ) ) }

            </div>

        </main>

    )

}