import { MouseEvent } from 'react'
import './modal.scss'

interface ModalProps {

    className?: string
    children: JSX.Element | JSX.Element[]
    onModalClose?: CallableFunction

}

export const Modal = ( props: ModalProps ) => {

    const onModalClose = ( event: MouseEvent ) => {

        if ( event.target === event.currentTarget && props.onModalClose ) {

            props.onModalClose()

        }

    }

    return (
        <div className="modal-container" onClick={ onModalClose }>
            <div className={ `modal ${ props.className }` }>
                { props.children }
            </div>
        </div >
    )

}
