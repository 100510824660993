import { Expose } from 'class-transformer'
import { IsDefined, IsNumber } from 'class-validator'

export class GetNewsItemStatsDTO {

    @Expose()
    @IsDefined()
    @IsNumber()
    openCount!: number 
    
    @Expose()
    @IsDefined()
    @IsNumber()
    readCount!: number  

    @Expose()
    @IsDefined()
    @IsNumber()
    readTime!: number 

    @Expose()
    @IsDefined()
    @IsNumber()
    sendQuestionCount!: number 

}
