import { LoaderFunctionArgs } from 'react-router-dom'
import { getPoliticianQuestions, getPoliticians, getPoliticianTitles } from '../../action/politician'
import { POLITICIANS_ACTIONS } from '../../action/politician/politicians.constants'
import { store } from '../../store'

export const politiciansLoader = async ( args: LoaderFunctionArgs ) => {

    store.dispatch( { type: POLITICIANS_ACTIONS.GET_POLITICIANS_SEARCH, search: "" }  )

    await getPoliticians()
    await getPoliticianTitles()
    await getPoliticianQuestions()

}
