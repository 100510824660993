import { Expose } from 'class-transformer'
import { IsDefined, IsNumber, IsOptional, IsString } from 'class-validator'

export class QuestionnaireQuestionUserAnswer {

    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number 

    @Expose()
    @IsDefined()
    @IsNumber()
    userId!: number 

    @Expose()
    @IsOptional()
    @IsNumber()
    questionId!: number 

    @Expose()
    @IsOptional()
    @IsNumber()
    answerId!: number 

    @Expose()
    @IsOptional()
    @IsString()
    answer!: string

    @Expose()
    @IsOptional()
    @IsNumber()
    scale!: number 

}
