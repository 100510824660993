import { NavLink, Outlet, useLocation } from 'react-router-dom'
import './events-detail.page.scss'

export const EventsDetail = () => {

    const location = useLocation()

    return (

        <main className="page page--events-detail">

            <div className="main-div">

                <div className="navigation-title">

                    <NavLink to="/esemenyek">

                        <h1 className="title-text-h1">Események &gt;</h1>

                    </NavLink> <NavLink to="/esemenyek/lista">

                    <h1 className="title-text-h1">Események lista &gt;</h1>

                </NavLink>

                    <h1 className="title-text-h1">Esemény szerkesztése</h1>

                </div>

                { location.pathname === `/esemenyek/uj` ? null : (

                    <div className="card-div">

                        <div className="page-select">

                            <NavLink className={ location.pathname.endsWith( `/statisztika` ) ? `` : `selected` } to="">Esemény</NavLink>
                            <NavLink className={ location.pathname.endsWith( `/statisztika` ) ? `selected` : `` } to="statisztika">Statisztika</NavLink>

                        </div>

                    </div>

                ) }

                <Outlet />

            </div>

        </main>

    )

}
