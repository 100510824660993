import { Expose } from "class-transformer"
import { IsDefined, IsNumber, IsOptional, IsString } from "class-validator"

export class VideoQuestion {

    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsString()
    question!: string

    @IsOptional()
    answers?: {
        id: number
        answer: string
        isSolution: boolean
    }[]

}