import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { GET_FLOATING_MESSAGES_ACTIONS } from './floating-messages.constants'
import { GetFloatingMessagesDTO } from '../../dto/floating-messages'

export const getFloatingMessages = async () => {

    const page = store.getState().floatingMessages.pagination.page
    const search = store.getState().floatingMessages.search
    const order = store.getState().floatingMessages.order

    const promise = createCall(`floating-message?page=`+page+`&search=`+search+`&order=`+order, BASE_METHODS.GET)
    promise.then(async response => {

        let body: GetFloatingMessagesDTO

        try {
            body = plainToClass( GetFloatingMessagesDTO, JSON.parse( await response!.text() ) )
        } catch ( exception ) {
            store.dispatch( { type: GET_FLOATING_MESSAGES_ACTIONS.GET_FLOATING_MESSAGES_INVALID_JSON } )
            return
        }
    
        if ( ( await validate( body ) ).length > 0 ) {
            store.dispatch( { type: GET_FLOATING_MESSAGES_ACTIONS.GET_FLOATING_MESSAGES_INVALID_RESPONSE } )
            return
        }

        store.dispatch( { type: GET_FLOATING_MESSAGES_ACTIONS.GET_FLOATING_MESSAGES_OK, floatingMessages: body.floatingMessages, pagination: body.pagination } )

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })

}
