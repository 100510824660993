import { TextField } from '../field'
import { Dropdown } from '../dropdown'
import { TextArea } from '../textarea'
import { DescriptionTextArea } from '../textarea/description'
import { AddImage } from '../add-image'
import './prize-card.scss'
import { useState } from 'react'
import { Prize } from '../../entity'
import { savePrize } from '../../action/gamification'
import { MessageModal } from '../modal'

interface PrizeCardProps {

    prize?: Prize

    position: number
    leagueId: number

    onAddImage: () => void
    onDeleteImage: () => void

}

export const PrizeCard = ( props: PrizeCardProps ) => {

    const [infoModalVisibility, setInfoModalVisibility] = useState(false)
    const [infoModalTitle, setInfoModalTitle] = useState("")
    const [infoModalDescription, setInfoModalDescription] = useState("")
  
    const [ name, setName ] = useState( props.prize?.name || `` )
    const [ exp, setExp ] = useState( props.prize?.exp || 0 )
    const [ type, setType ] = useState( props.prize?.type || 0 )
    const [ lead, setLead ] = useState( props.prize?.lead || `` )
    const [ description, setDescription ] = useState( props.prize?.description || `` )
    const [ congratulation, setCongratulation ] = useState( props.prize?.congratulation || `` )

    const positionText = [ `Első helyezett`, `Második helyezett`, `Harmadik helyezett`, `Továbbjutók` ][ props.position ]

    const onSavePrize = async () => {

        if (!checkFields()) {
            return
        } 
      
        await savePrize( {
            name: name,
            type: type,
            lead: lead,
            description: description,
            congratulation: congratulation,
            position: props.position,
            leagueId: props.leagueId,
            image: type === 2 ? null : props.prize!.image!.id,
            exp: exp
        } )

    }

    const checkFields = (): boolean => {
        if ( type !== 2 && name.trim().length === 0) {
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription( "A nyeremény megnevezésének megadása kötelező!");
            setInfoModalVisibility(true);
            return false
        }

        if (lead.trim().length === 0) {
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription( "A nyeremény lead megadása kötelező!");
            setInfoModalVisibility(true);
            return false
        }

        if (description.trim().length === 0) {
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription( "A nyeremény szövegének megadása kötelező!");
            setInfoModalVisibility(true);
            return false
        }

        if (congratulation.trim().length === 0) {
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription( "A gratuláló szöveg megadása kötelező!");
            setInfoModalVisibility(true);
            return false
        }

        if ( type !== 2 && ( props.prize!.image === undefined || props.prize!.image === null ) ) {
             setInfoModalTitle("Figyelmeztetés");
             setInfoModalDescription( "A nyeremény képének megadása kötelező!");
             setInfoModalVisibility(true);
             return false
        }

        return true
    }

    return (

        <div className="prize-card-div card-div">

            <div className="prize-card-div__title">

                { positionText } nyereménye

            </div>

            <div className="columns">

                { type === 2 ? (

                    <div className="field field--name">

                        <label>Tapasztalati pont mennyisége *</label>
                        <TextField field={{ value: exp.toString(), error: `` }} onChange={ e => setExp( isNaN( parseInt( e.target.value ) ) ? exp : parseInt( e.target.value ) ) } />

                    </div>

                ) : (

                    <div className="field field--name">

                        <label>A nyeremény megnevezése *</label>
                        <TextField field={{ value: name, error: `` }} onChange={ e => setName( e.target.value ) } />

                    </div>

                ) }

                <div className="field field--type">

                    <label></label>
                    <Dropdown value={ type } items={ [ { id: 0, value: `Fizikai` }, { id: 1, value: `Digitális` },  { id: 2, value: `Tapasztalati pont` } ] } compact={ true } onChange={ ( id: number ) => setType( id ) } />

                </div>

            </div>

            <div className="field">

                <label>A nyeremény lead szövegének megadása *</label>
                <TextArea field={{ value: lead, error: `` }} onChange={ e => setLead( e.target.value ) } />

            </div>

            <div className="field">

                <label>A nyeremény szövegének megadása *</label>
                <DescriptionTextArea field={{ value: description, error: `` }} onChange={ text => setDescription( text ) } />

            </div>

            <div className="columns">

                { type !== 2 ? (

                    <div className="field field--image">

                        <label>A nyeremény képe (1:1) *</label>
                        <AddImage text="Kép hozzáadása (1:1)" imageUrl={ props.prize?.image?.url || `` } width="120px" height="120px" onAddImage={ props.onAddImage } onDeleteImage={ props.onDeleteImage } />

                    </div>

                ) : null }

                <div className="field field--congrat">

                    <label>Gratuláló szöveg, amit a nyertes lát *</label>
                    <TextField field={{ value: congratulation, error: `` }} onChange={ e => setCongratulation( e.target.value ) } />

                </div>

            </div>

            <footer>

                <button className="button button--primary" style={{ minWidth: 182 }} onClick={ onSavePrize }>Mentés</button>

            </footer>

            {infoModalVisibility ? (

                <MessageModal
                    title={infoModalTitle}
                    message={infoModalDescription}
                    onCloseClick={() => {
                    setInfoModalVisibility(false);
                    }}
                />

            ) : null}

        </div>

    )

}