import { MouseEvent } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import './courses.page.scss'
import { SearchButton, SearchInput } from '../../../component/search'
import { CourseBlock } from '../../../component/course-block'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../state'
import { listCourses } from '../../../action/courses/list-courses.action'

export const Courses = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { stats } = useSelector( ( state: State ) => state.courses )

    const search = async () => {

        await listCourses()
        navigate( `/kepzesek/lista` )

    }

    const create = ( e: MouseEvent ) => {

        e.preventDefault()
        navigate( `/kepzesek/uj` )

    }

    const toggleArchive = () => {

        dispatch( { type: `TOGGLE_COURSE_ARCHIVE` } )
        search()

    }

    const setKeyword = ( keyword: string ) => {

        dispatch( { type: `SET_COURSE_KEYWORD`, keyword } )

    }

    return (

        <main className="page page--courses">

            <div className="main-div">

                <div className="navigation-title">

                    <h1 className="title-text-h1">Képzések áttekintése</h1>

                </div>

                <div className="toolbar">

                    <SearchInput placeholder="Keresés a képzések között" onChange={ e => setKeyword( e.target.value ) } onSearch={ search } />
                    <SearchButton onClick={ search } />
                    <button className="button button--primary" onClick={ toggleArchive }>Archív képzések</button>
                    <button className="button button--primary" onClick={ create }>+ Új képzés</button>

                </div>

                <div className="card-div">

                    <div className="columns">

                        <div className="column">

                            <CourseBlock caption="Legutóbbi képzés" course={ stats.newest } />

                        </div>

                        <div className="separator"></div>

                        <div className="column">

                            <CourseBlock caption="Legtöbb kitöltés" course={ stats.mostAnswer } />

                        </div>

                        <div className="separator"></div>

                        <div className="column">

                            <CourseBlock caption="Legkevesebb kitöltés" course={ stats.leastAnswer } />

                        </div>

                    </div>

                </div>

                <div className="card-div list-button-card-div">

                    <NavLink to="/kepzesek/lista">

                        <div className="list-button">

                            <img alt="" className="list-button__image" src="/image/hungary.svg" />
                            <div className="list-button__panel">

                                <div className="list-button__title">Országos képzések</div>
                                <div className="list-button__info">{ stats.courses } képzés • { stats.answers } kitöltés</div>

                            </div>

                        </div>

                    </NavLink>

                </div>

            </div>

        </main>

    )

}