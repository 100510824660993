import { LoaderFunctionArgs } from 'react-router-dom'
import { checkAdminSetPassword } from '../../action'
import { store } from '../../store'

export const adminSetPasswordLoader = async ( args: LoaderFunctionArgs ) => {

    store.dispatch( { type: `CHECK_ADMIN_SET_PASSWORD_CLEAR` } )
    await checkAdminSetPassword( args.params.token! )

}
