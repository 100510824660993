import { Table } from '../table'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../state'
import './videos-table.scss'
import { CourseTopic } from '../course-topic'
import { createFormattedDateTimeString } from '../../helper'
import { MenuButton } from '../button'
import { useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import { Modal } from '../modal'
import { listVideos, deleteVideo } from "../../action/videos"

export const VideosTable = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { videos, pagination, order } = useSelector( ( state: State ) => state.videos )

    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )
    const [ showConfirmModal, setShowConfirmModal ] = useState( false )

    const onDelete = async () => {

        await deleteFunc!()
        setShowConfirmModal( false )

    }

    const onPageChange = async ( page: number ) => {

        await listVideos( page )

    }

    const onOrderChange = async ( column: string ) => {

        console.log( { by: column, direction: ( order.by === column && order.direction === `ASC` ) ? `DESC` : `ASC` } )
        dispatch( { type: `VIDEO_ORDER_CHANGE`, by: column, direction: ( order.by === column && order.direction === `ASC` ) ? `DESC` : `ASC` } )
        await listVideos( pagination.page )

    }

    const onMenuSelect = async ( action: number, id: number ) => {

        switch ( action ) {

            case 3:
                setDeleteFunc( () => {
                    return async () => {
                        await deleteVideo( id )
                        await listVideos( pagination.page )
                    }
                } )
                setShowConfirmModal( true )
                break

            default:
                navigate( `/videok/${ id }` )

        }

    }

    const onRowClick = ( course: any ) => {

        navigate( `/videok/${ course.id }` )

    }

    return (

        <>

            <Table className="courses-table" columns={ [
                {
                    align: `center`,
                    name: `id`,
                    headerRender: () => `ID`,
                    render: video => video.id,
                    width: 100
                },
                {
                    align: `left`,
                    name: `title`,
                    headerRender: () => `Megnevezés`,
                    render: video => video.title,
                    width: 600
                },
                {
                    align: `center`,
                    name: `category_name`,
                    headerRender: () => `Kategória`,
                    render: video => <CourseTopic color={ video.category_color } name={ video.category_name } />,
                    width: 250
                },
                {
                    align: `center`,
                    name: `views`,
                    headerRender: () => `Megtekintések`,
                    render: video => video.views,
                    width: 250
                },
                {
                    align: `center`,
                    name: `visibleFrom`,
                    headerRender: () => `Megjelenítés`,
                    render: video => video.visibleFrom ? createFormattedDateTimeString( video.visibleFrom ) : ``,
                    width: 250
                },
                {
                    canOrder: false,
                    name: `actions`,
                    headerRender: () => ``,
                    render: course => <MenuButton hasBackground={ true } items={ [
                        {
                            id: 0,
                            value: `Szerkesztés`,
                            icon: `edit.svg`
                        },
                        {
                            id: 1,
                            value: `Kiemelés`,
                            icon: `pin.svg`
                        },
                        {
                            id: 3,
                            value: `Törlés`,
                            icon: `trash.svg`
                        }
                    ] } onChange={ ( action: number ) => onMenuSelect( action, course.id ) } />,
                    width: 100
                }
            ] } dataset={ videos } order={ order } onRowClick={ onRowClick } pagination={ { ...pagination, onPageChange } } onOrderChange={ onOrderChange } />

            { showConfirmModal ? (

                <Modal className="application-version-confirm-modal" onModalClose={ () => setShowConfirmModal( false ) }>

                    <div className="modal-title">Biztosan törlöd a videót?</div>
                    <div className="modal-description">Ezt a műveletet nem lehet visszavonni! Biztosan törlöd?</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowConfirmModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ onDelete }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

        </>

    )

}