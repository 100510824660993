import { Expose } from 'class-transformer'
import { IsDefined } from 'class-validator'
import { Prize, User } from '../../entity'

export class GetLeagueStatsDTO {

    @Expose()
    @IsDefined()
    averageAge!: number

    @Expose()
    @IsDefined()
    olderThan50!: number

    @Expose()
    @IsDefined()
    alreadyAdvanced!: number

    @Expose()
    @IsDefined()
    mostAdvancedFromCounty!: number

    @Expose()
    @IsDefined()
    averageAdvanceTime!: number

    @Expose()
    @IsDefined()
    quickestQuest!: string

    @Expose()
    @IsDefined()
    slowestQuest!: string

    @Expose()
    @IsDefined()
    quickestQuestGroup!: string

    @Expose()
    @IsDefined()
    slowestQuestGroup!: string

}

