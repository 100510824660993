import './video.page.scss'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../state'
import { TagSelector } from '../../../component/tag-selector'
import DatePicker from 'react-datepicker'
import hu from 'date-fns/locale/hu'
import { TextField } from '../../../component/field'
import { Dropdown, MenuButton } from "../../../component"
import { useState } from 'react'
import { MediaLibrary } from '../../../component/media-library'
import { MediaLibraryFile, Politician } from "../../../entity"
import { TextArea } from '../../../component/textarea'
import { DescriptionTextArea } from '../../../component/textarea/description'
import { PoliticianModal } from '../../../component/politicians'
import { saveVideo } from '../../../action/videos'
import { MessageModal } from '../../../component/modal'


export const Video = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { video, videoCategories } = useSelector( ( state: State ) => state.videos )

    const [ showMediaLibrary, setShowMediaLibrary ] = useState( false )
    const [ showImageEditor, setShowImageEditor ] = useState( false )
    const [ file, setFile ] = useState( null as MediaLibraryFile | null )

    const [ showPoliticianModal, setShowPoliticianModal ] = useState( false )

    const [ imageType, setImageType ] = useState( null as string | null )

    const [ infoModalVisibility, setInfoModalVisibility ] = useState( false )
    const [ infoModalTitle, setInfoModalTitle ] = useState( `` )
    const [ infoModalDescription, setInfoModalDescription ] = useState( `` )

    const checkFields = (): boolean => {

        if ( ! video!.experience || isNaN( video!.experience ) || video!.experience < 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A megtekintésért járó tapasztalati pontnak pozitív egész számnak kell lennie!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( ! video!.quizExperience || isNaN( video!.quizExperience ) || video!.quizExperience < 100 || video!.quizExperience > 250 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A kitöltésért járó tapasztalati pontnak 100 és 250 közötti egész számnak kell lennie!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( video!.visibleTo === null ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A videó időpontjának megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( ! video!.url || video!.url.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A videó URL-jének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( ! video!.quality || video!.quality.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A videó minőségének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( ! video!.title || video!.title.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A videó címének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( ! video!.lead || video!.lead.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A videó lead szövegének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( ! video!.description || video!.description.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A videó szövegének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( ! video!.question.question || video!.question.question.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A videó kvíz kérdésének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        for ( const answer of video!.question.answers! ) {

            if ( ! answer.answer || answer.answer.trim().length === 0 ) {

                setInfoModalTitle( `Figyelmeztetés` )
                setInfoModalDescription( `A videó kvíz válaszainak megadása kötelező!` )
                setInfoModalVisibility( true )

                return false

            }

        }

        if ( video!.relatedPoliticians.length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A házigazda kiválasztása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        return true

    }

    const onAddImage = ( image: string ) => {

        setImageType( image )
        setShowMediaLibrary( true )

    }

    const onPoliticianSelected = ( politician: Politician ) => {

        dispatch( { type: `VIDEO_ADD_POLITICIAN`, politician: politician } )

    }

    const u = ( field: string, value: any ) => {

        dispatch( { type: `UPDATE_VIDEO`, field: field, value: value } )

    }

    const onSave = async () => {

        if ( !checkFields() ) return
        const v = await saveVideo( video )
        if ( v !== null ) {
            navigate( `/videok/${ v.id }` )
        }

    }

    return (

        <main className="page page--video">

            <div className="main-div">

                <div className="navigation-title">

                    <NavLink to="/videok/lista">

                        <h1 className="title-text-h1">Videók lista &gt;</h1>

                    </NavLink>

                    <h1 className="title-text-h1">{ video!.id === undefined ? `Új videó` : `Videó szerkesztése` }</h1>

                </div>

                <div className="toolbar">

                    <NavLink to="/videok/lista">

                        <button className="button button--ghost">Vissza</button>

                    </NavLink>

                    <button className="button button--primary" onClick={ onSave }>Mentés</button>

                </div>

                <div className="grid">

                    <div className="card-div card-div--00">

                        <div className="cols">

                            <div className="col">

                                <div className="caption">Megjelenés időpontja *</div>
                                <DatePicker selected={ new Date( video!.visibleFrom * 1000 ) } onChange={ ( date ) => u( `visibleFrom`, date ? Math.round( date.getTime() / 1000 ) : null ) } showTimeSelect timeIntervals={ 1 } timeFormat="HH:mm" dateFormat="yyyy. MMMM dd. - HH:mm" disabled={ video !== null && video!.visibleTo !== null && ( video!.visibleTo! * 1000 < new Date().getTime() ) } locale={ hu }/>

                            </div>

                            <div className="col">

                                <div className="caption">Elrejtés időpontja *</div>
                                <DatePicker selected={ video!.visibleTo === null ? null : new Date( video!.visibleTo * 1000 ) } onChange={ ( date ) => u( `visibleTo`, date ? Math.round( date.getTime() / 1000 ) : null ) } showTimeSelect timeIntervals={ 1 } minDate={ new Date( video!.visibleFrom * 1000 ) } timeFormat="HH:mm" dateFormat="yyyy. MMMM dd. - HH:mm" disabled={ video !== null && video!.visibleTo !== null && ( video!.visibleTo! * 1000 < new Date().getTime() ) } locale={ hu }/>

                            </div>

                            <div className="col">

                                <div className="caption">Státusz *</div>
                                <div className="options">

                                    <div className="option">

                                        <input checked={ video!.isDraft } type="radio" value={ `true` } name="isDraft" onClick={ e => u( `isDraft`, true ) } id="isDraftTrue"/>
                                        <label htmlFor="isDraftTrue">Vázlat</label>

                                    </div>

                                    <div className="option">

                                        <input checked={ !video!.isDraft } type="radio" name="isDraft" value={ `false` } onClick={ e => u( `isDraft`, false ) } id="isDraftFalse"/>
                                        <label htmlFor="isDraftFalse">Aktív</label>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="card-div card-div--01">

                        <TagSelector tags={ video!.tags } onTagAdd={ tag => dispatch( {
                            type: `VIDEO_ADD_TAG`, tag
                        } ) } onTagDelete={ tag => dispatch( { type: `VIDEO_REMOVE_TAG`, tag } ) }/>

                    </div>

                    <div className="card-div card-div--02">

                        <div className="cols">

                            <div className="col">

                                <img alt="" src="/image/exp.svg" width={ 87 }/>

                            </div>

                            <div className="col">

                                <div className="caption">Tapasztalati pont a 40% nézéséért *</div>
                                <TextField field={ {
                                    value: video!.experience.toString(), error: ``
                                } } onChange={ e => u( `experience`, e.target.value ) }/>

                            </div>

                            <div className="col">

                                <div className="caption">Tapasztalati pont a kvízért (100-250 TP) *</div>
                                <TextField field={ {
                                    value: video!.quizExperience.toString(), error: ``
                                } } onChange={ e => u( `quizExperience`, e.target.value ) }/>

                            </div>

                        </div>

                    </div>

                    <div className="card-div card-div--03">

                        <div className="cols">

                            <div className="col">

                                <div className="caption">Téma kiválasztása *</div>
                                <Dropdown value={ video!.category === null ? null : video!.category.id } items={ videoCategories.map( videoCategory => ( {
                                    id: videoCategory.id, value: videoCategory.name
                                } ) ) } onChange={ ( id: number ) =>
                                {
                                    u( `category`, videoCategories.find( c => c.id === id ) )
                                } } compact={ false }/>

                            </div>

                        </div>

                    </div>

                    <div className="card-div card-div--04">

                        <div className="cols">

                            <div className="col">

                                <div className="caption">Kiemelt videó</div>
                                <button className={ `button ${ video!.isHighlighted ? `button--primary` : `button--ghost` }` } onClick={ () => u( `isHighlighted`, !video!.isHighlighted ) }>{ video!.isHighlighted ? `Videó kiemelve` : `Videó kiemelése` }</button>

                            </div>

                        </div>

                    </div>

                    <div className="card-div card-div--05">

                        <div className="caption">Videó tartalmi elemeinek megadása</div>

                        <div className="cols">

                            <div className="col">

                                <div className="caption">Videóra mutató link *</div>
                                <div className="info">A videóhoz youtube linket csatolj, amely egy videóra mutat.</div>
                                <TextField field={ {
                                    value: video!.url, error: ``
                                } } onChange={ e => u( `url`, e.target.value ) }/>

                            </div>

                        </div>

                        <div className="cols">

                            <div className="col">

                                <div className="caption">Mi a videó minősége? *</div>
                                <div className="info">A videó minősége fog megjelenni a cím előtt</div>
                                <TextField field={ {
                                    value: video!.quality, error: ``
                                } } onChange={ e => u( `quality`, e.target.value ) }/>

                            </div>

                        </div>

                        <div className="cols">

                            <div className="col">

                                <div className="caption">A videó címe *</div>
                                <div className="info">Ajánlott karakterszám: 120 karakter</div>
                                <TextField field={ {
                                    value: video!.title, error: ``
                                } } onChange={ e => u( `title`, e.target.value ) }/>

                            </div>

                        </div>

                        <div className="cols">

                            <div className="col">

                                <div className="caption">Videó lead szöveg megadása *</div>
                                <TextArea field={ {
                                    value: video!.lead, error: ``
                                } } onChange={ e => u( `lead`, e.target.value ) }/>

                            </div>

                        </div>

                        <div className="cols">

                            <div className="col">

                                <div className="caption">Videó szövegének megadása *</div>
                                <DescriptionTextArea field={ {
                                    value: video!.description, error: ``
                                } } onChange={ html => u( `description`, html ) }/>

                            </div>

                        </div>

                    </div>

                    <div className="card-div card-div--06">

                        <div className="column">

                            <h2 className="title-text-h2">Kapcsolódó politikusok megadása *</h2>

                            <div className="politicians-list">

                                <div className="add-politician" onClick={ () => setShowPoliticianModal( true ) }>

                                    <img alt="" src="/image/add-blue.svg"/> <span>Politikus hozzáadása</span>

                                </div>

                                { video!.relatedPoliticians!.map( politician => (

                                    <div className="host-politician">

                                        <img alt="" src={ politician.imageFile!.url }/>
                                        <span className="host-politician__name">{ politician.lastName } { politician.firstName }</span>
                                        <span className="host-politician__titles">{ politician.innerPosition }</span>
                                        <MenuButton hasBackground={ true } items={ [ {
                                            id: 0, value: `Törlés`, icon: `trash.svg`
                                        } ] } onChange={ () => dispatch( {
                                            type: `VIDEO_REMOVE_POLITICIAN`, id: politician.id
                                        } ) }/>

                                    </div>

                                ) ) }

                            </div>

                        </div>

                    </div>

                    <div className="card-div card-div--07">

                        <div className="column">

                            <h2 className="title-text-h2">Videó villámkvíz *</h2>

                            <div className="text-section-container container-top-margin">

                                <h3 className="title-text-h3">Kvíz kérdés</h3>

                                <TextArea field={ { value: video!.question.question, error: `` } } onChange={ e => dispatch({ type: `VIDEO_CHANGE_QUESTION`, question: e.target.value } ) } placeholder="" category="question" maxLength={ 255 }/>

                                <div className="container container-center">

                                    <h3 className="title-text-h3 header-text-center">
                                        1. Válasz
                                    </h3>

                                    <button className={ `correct-answer-button ${ video!.question.answers![ 0 ].isSolution ? `` : `correct-answer-button--ghost` }` } type="button" onClick={ () => dispatch( { type: `VIDEO_UPDATE_QUIZ_SOLUTION`, id: video!.question.answers![ 0 ].id } ) }>
                                        Ez a helyes válasz
                                    </button>

                                </div>


                                <TextArea field={ { value: video!.question.answers![ 0 ].answer, error: `` } } onChange={ e => dispatch({ type: `VIDEO_CHANGE_ANSWER`, id: video!.question.answers![ 0 ].id, answer: e.target.value } ) } placeholder="" category="answer" maxLength={ 255 }/>

                                <div className="container container-center">

                                    <h3 className="title-text-h3  header-text-center">
                                        2. Válasz
                                    </h3>

                                    <button className={ `correct-answer-button ${ video!.question.answers![ 1 ].isSolution ? `` : `correct-answer-button--ghost` }` } type="button" onClick={ () => dispatch( { type: `VIDEO_UPDATE_QUIZ_SOLUTION`, id: video!.question.answers![ 1 ].id } ) }>
                                        Ez a helyes válasz
                                    </button>

                                </div>


                                <TextArea field={ { value: video!.question.answers![ 1 ].answer, error: `` } } onChange={ e => dispatch({ type: `VIDEO_CHANGE_ANSWER`, id: video!.question.answers![ 1 ].id, answer: e.target.value } ) } placeholder="" category="answer" maxLength={ 255 }/>

                                <div className="container container-center">

                                    <h3 className="title-text-h3  header-text-center">
                                        3. Válasz
                                    </h3>

                                    <button className={ `correct-answer-button ${ video!.question.answers![ 2 ].isSolution ? `` : `correct-answer-button--ghost` }` } type="button" onClick={ () => dispatch( { type: `VIDEO_UPDATE_QUIZ_SOLUTION`, id: video!.question.answers![ 2 ].id } ) }>
                                        Ez a helyes válasz
                                    </button>

                                </div>


                                <TextArea field={ { value: video!.question.answers![ 2 ].answer, error: `` } } onChange={ e => dispatch({ type: `VIDEO_CHANGE_ANSWER`, id: video!.question.answers![ 2 ].id, answer: e.target.value } ) } placeholder="" category="answer" maxLength={ 255 }/>

                                <div className="container container-center">

                                    <h3 className="title-text-h3  header-text-center">
                                        4. Válasz
                                    </h3>

                                    <button className={ `correct-answer-button ${ video!.question.answers![ 3 ].isSolution ? `` : `correct-answer-button--ghost` }` } type="button" onClick={ () => dispatch( { type: `VIDEO_UPDATE_QUIZ_SOLUTION`, id: video!.question.answers![ 3 ].id } ) }>
                                        Ez a helyes válasz
                                    </button>

                                </div>

                                <TextArea field={ { value: video!.question.answers![ 3 ].answer, error: `` } } onChange={ e => dispatch({ type: `VIDEO_CHANGE_ANSWER`, id: video!.question.answers![ 3 ].id, answer: e.target.value } ) } placeholder="" category="answer" maxLength={ 255 }/>

                            </div>

                        </div>

                    </div>

                    <footer>

                        <button className="button button--primary" onClick={ onSave }>Mentés</button>

                    </footer>

                </div>

            </div>

            { showMediaLibrary ? (

                <MediaLibrary onMediaSelected={ ( file: MediaLibraryFile ) =>
                {

                    setFile( file )
                    setShowMediaLibrary( false )
                    setShowImageEditor( true )

                } } onClose={ () => setShowMediaLibrary( false ) }/>

            ) : null }

            { showPoliticianModal ? (

                <PoliticianModal onCloseClick={ () =>
                {

                    setShowPoliticianModal( false )

                } } onModalClose={ () => setShowPoliticianModal( false ) } onPoliticianSelected={ onPoliticianSelected }/>

            ) : null }

            { infoModalVisibility ? (

                <MessageModal title={ infoModalTitle } message={ infoModalDescription } onCloseClick={ () => setInfoModalVisibility( false ) }/>

            ) : null }

        </main>

    )

}
