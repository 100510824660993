import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetQuestionnaireStatsDTO } from '../../dto/questionnaires'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { GET_QUESTIONNAIRES_STAT_ACTIONS } from './questionnaires.constants'

export const getQuestionnairesStats = async () => {

    const promise = createCall('questionnaires/stats', BASE_METHODS.GET)
    promise.then(async response => {
        let body: GetQuestionnaireStatsDTO

        try {
            body = plainToClass( GetQuestionnaireStatsDTO, JSON.parse( await response.text() ) )
        } catch ( exception ) {
            store.dispatch( { type: GET_QUESTIONNAIRES_STAT_ACTIONS.GET_QUESTIONNAIRES_STATS_INVALID_JSON } )
            return
        }
    
        if ( ( await validate( body ) ).length > 0 ) {
            store.dispatch( { type: GET_QUESTIONNAIRES_STAT_ACTIONS.GET_QUESTIONNAIRES_STATS_INVALID_RESPONSE } )
            return
        }
    
        store.dispatch( { type: GET_QUESTIONNAIRES_STAT_ACTIONS.GET_QUESTIONNAIRES_STATS_OK, 
            lastQuestionnaire: body.lastQuestionnaire, 
            mostFillingQuestionnaire: body.mostFillingQuestionnaire, 
            lastFillingQuestionnaire: body.lastFillingQuestionnaire, 
            questionnaireCount: body.questionnaireCount, 
            fillingCount: body.fillingCount 
        } )
    
    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })

}
