import { Expose, Type } from 'class-transformer'
import { IsDefined, IsNumber, IsOptional, IsString, ValidateIf, ValidateNested } from 'class-validator'
import { ImageFile } from './image-file.entity'
import { Politician } from './politician.entity'
import { Tag } from './tag.entity'
import { AchievementGroup } from './achievement-group.entity'

export class Event {

    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsString()
    title!: string

    @Expose()
    @IsDefined()
    @IsNumber()
    startDate!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf( ( object, value ) => value !== null )
    endDate!: number | null

    @Expose()
    @IsDefined()
    @IsString()
    location!: string

    @Expose()
    @IsDefined()
    @IsNumber()
    visibility!: number

    @IsOptional()
    @ValidateNested()
    @Type( () => ImageFile )
    imageFile?: ImageFile | null

    @IsOptional()
    @ValidateNested()
    @Type( () => Politician )
    hostPoliticians?: Politician[]

    @IsOptional()
    @IsDefined()
    @ValidateNested()
    notifications!: {
        id: number
        notification: string
        created: number
    }[]

    @Expose()
    tags!: Tag[]

    @Expose()
    summary!: string | null

    @Expose()
    achievementGroup?: AchievementGroup | null

    @Expose()
    isOnline?: boolean

    @Expose()
    highlighted?: boolean

}
