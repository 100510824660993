import { listLeagues, listQuestGroups } from '../../action/gamification'
import { listQuests } from '../../action/gamification/list-quests.action'
import { listTags } from '../../action/tags'
import { LoaderFunctionArgs } from "react-router-dom"

export const questsPageLoader = async ( args: LoaderFunctionArgs ) => {

    await listTags()
    await listLeagues( parseInt( args.params.seasonId as string ) )
    await listQuestGroups( parseInt( args.params.seasonId as string ),  )
    await listQuests( parseInt( args.params.seasonId as string ),  )

    return { seasonId: parseInt( args.params.seasonId as string ) }

}
