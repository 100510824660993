export const GET_NEWS_CATEGORIES_ACTIONS = {
    GET_NEWS_CATEGORIES_INVALID_JSON: "GET_NEWS_CATEGORIES_INVALID_JSON",
    GET_NEWS_CATEGORIES_INVALID_RESPONSE: "GET_NEWS_CATEGORIES_INVALID_RESPONSE",
    GET_NEWS_CATEGORIES_OK: "GET_NEWS_CATEGORIES_OK",
    GET_NEWS_CATEGORIES_NEWS_NOT_EMPTY: "GET_NEWS_CATEGORIES_NEWS_NOT_EMPTY",
    GET_NEWS_CATEGORIES_NEWS_NOT_EMPTY_CLEAR: "GET_NEWS_CATEGORIES_NEWS_NOT_EMPTY_CLEAR",
}

export const DELETE_NEWS_CATEGORY_ACTIONS = {
    DELETE_NEWS_CATEGORY_STARTED: "DELETE_NEWS_CATEGORY_STARTED",
    DELETE_NEWS_CATEGORY_OK: "DELETE_NEWS_CATEGORY_OK",
}

