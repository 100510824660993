import { Modal } from '../../modal'
import { MouseEventHandler } from 'react'
import './politician-title-add-modal.scss'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../state'
import { PoliticianTitle } from '../../../entity'
import { PoliticianTitleModalElement } from '../politician-title-modal/politician-title-modal-element'

interface AddPoliticianTitleModalProps {
    onCloseClick?: MouseEventHandler
    onModalClose?: () => void
}

export const AddPoliticianTitleModal = ( props: AddPoliticianTitleModalProps ) => {

    const politicianTitles = useSelector( ( state: State ) => state.politicians.politicianTitles )
    const selectedTitle = useSelector( ( state: State ) => state.createPolitician.form.titles )

    const politicianTitleElements: JSX.Element[] = []

    if ( politicianTitles !== null && politicianTitles !== undefined ) {
        politicianTitles.map( ( politicianTitleElement: PoliticianTitle ) => {
            politicianTitleElements.push( <PoliticianTitleModalElement politicianTitleElement={ politicianTitleElement }  deleteVisible={ false } checkVisible={ selectedTitle.some( item => politicianTitleElement.id === item.id ) } /> )
        } )
    }

    return (
        <Modal onModalClose={ () => props.onModalClose && props.onModalClose() }>

            <div className="politician-title-add-modal">

                <div className="politician-title-add-modal__header">
                    <div className="politician-title-add-modal__title"> Új titulus kiválasztása </div>
                    <button className="close-button" onClick={ props.onCloseClick }> <img alt="bezárás gomb" src="/image/close.svg" /> </button>
                </div>

                <div className=" ">
                    <ul id={ "politician-title_table" } className="politician-title-modal politician-title-list">
                        { politicianTitleElements.length != 0 ? politicianTitleElements : <span className="label-text empty-text">Nincs megjeleníthető titulus.</span> }
                    </ul>

                </div>

            </div>

        </Modal>
    )
}
