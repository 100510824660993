import { Expose } from 'class-transformer'
import { IsDefined, IsNumber } from 'class-validator'

export class GetUserStatsDTO {

    @Expose()
    @IsDefined()
    @IsNumber()
    usersCount!: number

}
