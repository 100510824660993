import { store } from '../../store'
import { plainToClass } from 'class-transformer'
import { ListCoursesDTO } from '../../dto/courses'
import { validate } from 'class-validator'

export const listCourses = async ( page: number = 1 ) => {

    let body, response

    const { archive, keyword, order } = store.getState().courses

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/courses?page=${ page }&archive=${ archive ? `true` : `false` }&order_by=${ order.by }&order_direction=${ order.direction }${ keyword !== `` ? `&keyword=${ keyword }` : `` }`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'GET'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'LIST_COURSES_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'LIST_COURSES_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'LIST_COURSES_UNEXPECTEDRESPONSE' } )
        return

    }

    try {

        body = plainToClass( ListCoursesDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'LIST_COURSES_INVALIDJSON' } )
        return

    }

    if ( ( await validate( body ) ).length > 0 ) {

        store.dispatch( { type: 'LIST_COURSES_INVALIDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'LIST_COURSES_OK', courses: body.courses, pagination: body.pagination, highlighted: body.highlighted } )

}