import { Expose } from 'class-transformer'
import { IsDefined } from 'class-validator'
import { Lesson } from '../../entity'

export class SaveLessonDTO {

    @Expose()
    @IsDefined()
    lesson!: Lesson

}

