import './season.scss'
import { Season as SeasonEntity } from '../../entity'
import { createFormattedDateString } from '../../helper'
import { MenuButton } from '../button'
import { NavLink } from 'react-router-dom'

interface SeasonProps {

    season: SeasonEntity
    next: boolean
    onShowModal?: ( season: SeasonEntity ) => void

}

export const Season = ( props: SeasonProps ) => {

    const showModal = () => { if ( props.onShowModal ) props.onShowModal( props.season ) }

    return (

        <div className="season">

            <div className="season__image">

                <img alt="" src={ props.season.image ? props.season.image.url : `` } />

            </div>

            <div className="season__details">

                <div className="season__name">

                    <NavLink to={ `/szezon/${ props.season.id }` }>

                        { props.season.name }

                    </NavLink>

                </div>

                <div className="season__info">{ createFormattedDateString( props.season.startDate ) } - { createFormattedDateString( props.season.endDate ) } • { props.season.leagues - 1 } liga • { props.season.users } résztvevő</div>

            </div>

            { props.next ? (

                <div className="season__actions">

                    <MenuButton hasBackground={ true } items={ [ { id: 0, value: `Szerkesztés`, icon: `edit.svg` } ] } onChange={ showModal } />

                </div>

            ) : null }

        </div>

    )

}