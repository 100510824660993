import { BASE_ACTIONS } from '../../action/base/base.constants'
import { BaseState } from '../../state/base'

const initialBaseState: BaseState  = {
    errorTitle: null,
    errorMessage: null
}

export const baseReducer = ( state: BaseState = initialBaseState, action: any ): BaseState => {
    switch ( action.type ) {
        case BASE_ACTIONS.ERROR_NO_USER:
            return { ...state, errorTitle: "Hiba", errorMessage: "Nincs bejelentkezett felhasználó." }
        case BASE_ACTIONS.ERROR_TIMEOUT:
            return { ...state, errorTitle: "Hiba", errorMessage: "Timeout." }
        case BASE_ACTIONS.ERROR_UNAUTHORIZED:
            return { ...state, errorTitle: "Hiba", errorMessage: "Nincs jogosultsága a felhasználónak." }
        case BASE_ACTIONS.ERROR_UNEXPECTED_RESPONSE:
            return { ...state, errorTitle: "Hiba", errorMessage: "A szerver válasz nem megfelelő." }
        case BASE_ACTIONS.CLEAR_ERROR:
            return { ...state, errorTitle: null, errorMessage: null }
        default:
            return state
    }
}
