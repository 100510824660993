import './quests.page.scss'
import { QuestGroupCard } from '../../../../component/quest-group-card'
import { QuestCard } from '../../../../component/quest-card'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../../state'
import { deleteQuest, deleteQuestGroup, saveQuest, saveQuestGroup } from '../../../../action/gamification'
import { useState } from 'react'
import { Modal } from '../../../../component'
import { v4 as uuid } from 'uuid'
import { useLoaderData } from "react-router-dom"

export const QuestsPage = () => {

    const loaderData: any = useLoaderData()
    const dispatch = useDispatch()
    const [ showDeleteModal, setShowDeleteModal ] = useState( false )
    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )
    const { quests, questGroups, league } = useSelector( ( state: State ) => state.gamification )

    const confirmDelete = async () => {

        if ( deleteFunc !== null ) {

            await deleteFunc()

        }
        setShowDeleteModal( false )
        setDeleteFunc( null )

    }

    const onQuestGroupDelete = async ( index: number ) => {

        const questGroup = questGroups[ index ]

        const deleteFunc = async () => {

            if ( questGroup.id !== null ) {

                if ( league!.id === 1 ) {

                    await deleteQuestGroup( questGroup.id )

                } else {

                    const leagues = questGroups[ index ].leagues.filter( l => l.id !== league!.id )
                    dispatch( { type: `QUEST_GROUPS_UPDATE_FIELD`, index: index, field: `leagues`, value: leagues } )
                    const group = await saveQuestGroup( questGroups[ index ], undefined, loaderData.seasonId )
                    if ( group ) {
                        dispatch( { type: `QUEST_GROUPS_UPDATE_FIELD`, index: index, field: `id`, value: group.id } )
                        dispatch( { type: `QUEST_GROUPS_UPDATE_FIELD`, index: index, field: `quests`, value: group.quests } )
                    }

                }

            }
            dispatch( { type: `QUEST_GROUPS_DELETE`, index: index } )

        }
        setDeleteFunc( () => deleteFunc )
        setShowDeleteModal( true )

    }

    const onQuestDelete = async ( index: number ) => {

        const quest = quests[ index ]

        const deleteFunc = async () => {

            if ( quest.id !== null ) {

                if ( league!.id === 1 ) {

                    await deleteQuest( quest.id )

                } else {

                    const quest = quests[ index ]
                    const leagues = quest!.leagues.filter( l => l.id !== league!.id )
                    dispatch( { type: `QUEST_UPDATE_FIELD`, index: index, field: `leagues`, value: leagues } )

                    const id = await saveQuest( quest!, loaderData.seasonId )
                    if ( id ) {
                        dispatch( { type: `QUEST_UPDATE_FIELD`, index: index, field: `id`, value: id } )
                    }

                }

            }
            dispatch( { type: `QUEST_DELETE`, index: index } )

        }
        setDeleteFunc( () => deleteFunc )
        setShowDeleteModal( true )

    }

    const onQuestGroupCreate = async () => {

        const uid = uuid()

        dispatch( { type: 'CREATE_QUEST_GROUP_OK', questGroup: {
            uuid: uid,
            id: null,
            name: ``,
            lead: ``,
            description: ``,
            quests: [],
            exp: 300,
            tags: [],
            leagues: [ league!.id ],
            selectedLeagues: [ league!.id ],
            image: null,
            deleteQuests: [],
            created: Math.round( new Date().getTime() / 1000 )
        } } )

        setTimeout( () => { window.location.href = `#${ uid }` }, 200 )

    }

    const onQuestCreate = async () => {

        const uid = uuid()

        dispatch( { type: 'CREATE_QUEST_OK', quest: {
            uuid: uid,
            id: null,
            name: ``,
            type: 0,
            questConditionId: 5,
            contentId: null,
            repeats: 1,
            exp: 100,
            tags: [],
            leagues: [ league!.id ],
            selectedLeagues: [ league!.id ],
            created: Math.round( new Date().getTime() / 1000 )
        } } )

        setTimeout( () => { window.location.href = `#${ uid }` }, 200 )

    }


    return (

        <div className="quests-page">

            { questGroups.sort( ( a, b ) => b.created - a.created ).map( ( questGroup, index ) => (

                <QuestGroupCard index={ index } key={ questGroup.id } questGroup={ questGroup } onDelete={ () => onQuestGroupDelete( index ) } hideLeagues={ true } />

            ) ) }

            { quests.sort( ( a, b ) => b.created - a.created ).map( ( quest, index ) => (

                <QuestCard index={ index } key={ quest.id } quest={ quest } onDelete={ () => onQuestDelete( index ) } hideLeagues={ true } />

            ) ) }

            <div className="quests-toolbar">

                <button className="new-quest-button" onClick={ onQuestCreate }>Új kihívás</button>
                <button className="new-quest-group-button" onClick={ onQuestGroupCreate }>Új kihívás csoport</button>

            </div>

            { showDeleteModal ? (

                <Modal onModalClose={ () => setShowDeleteModal( false ) }>

                    <div className="modal-title">Biztosan törlöd az elemet?</div>
                    <div className="modal-description">Biztosan törlöd?</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowDeleteModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ () => confirmDelete() }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

        </div>

    )

}