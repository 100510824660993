import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { LoginDTO } from '../../dto'
import { store } from '../../store'
import { BASE_ACTIONS } from "../base/base.constants";

export const login = async () => {

    let body: LoginDTO
    let response: Response

    store.dispatch( { type: 'LOGIN_STARTED' } )

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/users/login${process.env.REACT_APP_BUILD === `PRIZES` ? `?type=prizes`: ``}`, {
            body: JSON.stringify( {
                email: store.getState().login.form.email.value,
                password: store.getState().login.form.password.value,
                deviceType: 3
            } ),
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'post'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'LOGIN_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'LOGIN_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'LOGIN_UNEXPECTEDRESPONSE' } )
        return

    }

    try {

        body = plainToClass( LoginDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'LOGIN_INVALIDJSON' } )
        return

    }

    if ( ( await validate( body ) ).length > 0 ) {

        store.dispatch( { type: 'LOGIN_INVALIDRESPONSE' } )
        return

    }

    store.dispatch( { type: BASE_ACTIONS.CLEAR_ERROR } )
    store.dispatch( { type: 'LOGIN_OK', user: body.user, token: body.session.token } )

}
