import { Expose, Type } from 'class-transformer'
import { IsDefined, IsNumber, IsString, ValidateNested } from 'class-validator'
import { User } from './user.entity'

export class UserGroup {

    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsString()
    name!: string

    @Expose()
    @ValidateNested()
    @Type( type => User )
    users?: User[]

}
