import { Expose } from "class-transformer"
import { IsDefined, IsNumber, IsString } from "class-validator"

export class PoliticianBiographyItem {

    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number 

    @Expose()
    @IsDefined()
    @IsString()
    year!: string

    @Expose()
    @IsDefined()
    @IsString()
    title!: string

    @Expose()
    @IsDefined()
    @IsString()
    description!: string

    yearField!: {
        error: string
        value: string 
        required: boolean
    }

    titleField!: {
        error: string
        value: string 
        required: boolean
    }
    
    descriptionField!: {
        error: string
        value: string 
        required: boolean
    }
}