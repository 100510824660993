import { GET_NEWS_STAT_ACTIONS } from '../../action/news/news.constants'
import { NewsStatsState } from '../../state/news'

const initialNewsStatsState: NewsStatsState  = {
    highlightedNews: null,
    highlightedNewsStats: null,
    mostReadedNews: null,
    lastReadedNews: null, 
    newsCount: 0, 
    userCount: 0
}

export const newsStatsReducer = ( state: NewsStatsState = initialNewsStatsState, action: any ): NewsStatsState => {
    switch ( action.type ) {
        case GET_NEWS_STAT_ACTIONS.GET_NEWS_STATS_OK:
            return { ...state, highlightedNews: action.highlightedNews,
                highlightedNewsStats: action.highlightedNewsStats,
                mostReadedNews: action.mostReadedNews, 
                lastReadedNews: action.lastReadedNews, 
                newsCount: action.newsCount, 
                userCount: action.userCount 
            }
        case GET_NEWS_STAT_ACTIONS.GET_NEWS_STATS_INVALID_JSON:
            return { ...state, highlightedNews: null, mostReadedNews: null, lastReadedNews: null }
        case GET_NEWS_STAT_ACTIONS.GET_NEWS_STATS_INVALID_RESPONSE:
            return { ...state, highlightedNews: null, mostReadedNews: null, lastReadedNews: null }
        default:
            return state
    }
}
