import './course-block.scss'
import { Course } from '../../entity'
import { NavLink } from 'react-router-dom'

interface CourseBlockProps {

    caption: string
    course: Course | null

}

export const CourseBlock = ( props: CourseBlockProps ) => {

    return (

        <div className="course-block">

            <div className="course-block__caption">

                { props.caption }

            </div>

            { props.course !== null ? (

                <NavLink to={ `/kepzesek/${ props.course.id }` }>

                    <div className="course-block__container">

                        <img alt="" className="course-block__image" src={ props.course.image ? props.course.image.url : `/image/dk-logo-16_9.svg` } />

                        <div className="course-block__panel">

                            <div className="course-block__available">{ props.course.endDate === null ? `Határozatlan ideig` : `Még ${ Math.round( ( props.course.endDate / 60 / 60 / 24 ) - ( new Date().getTime() / 1000 / 60 / 60 / 24 ) ) } napig` } elérhető</div>
                            <div className="course-block__questions">{ props.course.lessons.length } rövid kérdés</div>
                            <div className="course-block__title">{ props.course.title }</div>

                        </div>

                    </div>

                </NavLink>

            ) : (

                <>Nincs megjeleníthető képzés</>

            ) }

        </div>

    )

}