import './news-statistics.tab.scss'
import Chart from 'react-apexcharts'
import { useSelector } from 'react-redux'
import { State } from '../../../state'

interface QuotesStatisticsTabProps { }

export const QuotesStatisticsTab = ( props: QuotesStatisticsTabProps ) => {

    const { applicationLaunchedCount } = useSelector( ( state: State ) => state.statistics )
    const { quotePoliticianSwipedCount, quotePoliticianSwipedXAxis, quotePoliticianSwipedData } = useSelector( ( state: State ) => state.statistics.quotesStats )


    const quotePoliticianSwipedOptions = {
        chart: {
            id: 'line-chart',    
        },
        dataLabels: {
            enabled: true
        },
        stroke: {
            curve: "smooth" as "smooth",
        },
        colors: ['#0267AA'],
        xaxis: {
          categories: quotePoliticianSwipedXAxis
        }
    }

    const quotePoliticianSwipedSeries = [{
        name: 'Kártyahúzások száma',
        data: quotePoliticianSwipedData
    }]


    return (
        <div className="card-div">

            <div className="container">
                <div className="card-div card-div-right-margin">

                    <div className="container column">
                        <div className="info-slot">
                            <div className="info-slot__description">
                                <span className="caption"> Kártyahúzások száma </span>
                                <span className="additional-info"> Hány ember húzott el az adott héten kártyát  </span>
                            </div>

                            <div className="info-slot__info">
                                <span className="value">{ quotePoliticianSwipedCount }</span> { "db" }
                            </div>
                        </div>

                        <Chart options={ quotePoliticianSwipedOptions } series={ quotePoliticianSwipedSeries } type="bar" height={ 260 } />
                    </div>

                </div>

                <div className="card-div card-div-right-margin">

                    <div className="container column">
                        <div className="info-slot">
                            <div className="info-slot__description">
                                <span className="caption"> Kártyahúzások száma </span>
                                <span className="additional-info"> Hány ember húzott el az adott héten kártyát </span>
                            </div>

                            <div className="info-slot__info">
                                <span className="value">{ quotePoliticianSwipedCount }</span> { "db" }
                            </div>
                        </div>
                    </div>

                    <div className="container column">
                        <div className="info-slot">
                            <div className="info-slot__description">
                               <span className="caption"> Megnyitások száma </span>
                                <span className="additional-info"> Hányan nyitották meg az appot </span>
                            </div>

                            <div className="info-slot__info">
                                <span className="value">{ applicationLaunchedCount }</span> { "db" }
                            </div>
                        </div>
                    </div>

                    <div className="container column">
                        <div className="info-slot">
                            <div className="info-slot__description">
                                <span className="caption"> Az applikációt megnyitók közül hány % húzta el mind az 5 kártyát</span>
                                <span className="additional-info">  </span>
                            </div>

                            <div className="info-slot__info">
                                <span className="value">{ (applicationLaunchedCount == 0 ||quotePoliticianSwipedCount == 0) ? 0 : (quotePoliticianSwipedCount/applicationLaunchedCount * 100).toFixed(0) }</span> { "%" }
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}


