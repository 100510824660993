import { LoaderFunctionArgs } from 'react-router-dom'
import { listAchievementGroups } from '../../action/achievements'
import { getPoliticians } from '../../action/politician'
import { listTags } from '../../action/tags'

export const achievementsLoader = async ( args: LoaderFunctionArgs ) => {

    await getPoliticians()
    await listTags()

    await listAchievementGroups()

}
