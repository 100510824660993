import { store } from '../../store'

export const createNotification = async ( id: number, body: string ) => {

    let response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/questionnaires/${ id }/notifications`, {
            body: JSON.stringify( {
                body: body
            } ),
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'POST'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'QUESTIONNAIRE_CREATE_NOTIFICATION_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'QUESTIONNAIRE_CREATE_NOTIFICATION_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'QUESTIONNAIRE_CREATE_NOTIFICATION_UNEXPECTEDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'QUESTIONNAIRE_CREATE_NOTIFICATION_OK' } )

}
