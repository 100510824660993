import './achievements.page.scss'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { State } from '../../state'
import { AchievementGroup as AchievementGroupEntity } from '../../entity'
import { AchievementGroup } from '../../component/achievement-group'
import { AchievementModal } from '../../component/achievement-modal'
import {
    createAchievementGroup,
    deleteAchievementGroup,
    listAchievementGroups,
    modifyAchievementGroup
} from '../../action/achievements'

export const AchievementsPage = () => {

    const create = () => {

        setSelectedAchievement( { category: 0 } )
        setShowAchievementModal( true )

    }

    const [ selectedAchievement, setSelectedAchievement ] = useState( {} as any )

    const openModal = ( achievementGroup: AchievementGroupEntity ) => {

        setSelectedAchievement( {
            showHeader: achievementGroup.category === 0,
            showTypeSelect: achievementGroup.category === 0,
            id: achievementGroup.id,
            activity: achievementGroup.activity,
            category: achievementGroup.category,
            name: achievementGroup.name,
            description: achievementGroup.description,
            type: achievementGroup.type,
            bronzeDesiredValue: achievementGroup.bronzeAchievement?.desiredValue,
            silverDesiredValue: achievementGroup.silverAchievement?.desiredValue,
            goldDesiredValue: achievementGroup.goldAchievement.desiredValue,
            bronzeImage: achievementGroup.bronzeAchievement?.image,
            silverImage: achievementGroup.silverAchievement?.image,
            goldImage: achievementGroup.goldAchievement?.image,
            bronzeExp: achievementGroup.bronzeAchievement?.experience,
            silverExp: achievementGroup.silverAchievement?.experience,
            goldExp: achievementGroup.goldAchievement.experience,
            tagId: achievementGroup.tag ? achievementGroup.tag.id : null,
            politicianId: achievementGroup.politician ? achievementGroup.politician.id : null
        } )
        setShowAchievementModal( true )

    }

    const { achievementGroups } = useSelector( ( state: State ) => state.achievements )

    const [ showAchievementModal, setShowAchievementModal ] = useState( false )

    const [ defaultOpen, setDefaultOpen ] = useState( true )
    const [ questsOpen, setQuestsOpen ] = useState( false )
    const [ coursesOpen, setCoursesOpen ] = useState( false )
    const [ quizesOpen, setQuizesOpen ] = useState( false )
    const [ eventsOpen, setEventsOpen ] = useState( false )


    const onSave = async ( achievementGroupData: any ) => {

        if ( !achievementGroupData.id ) {

            await createAchievementGroup( achievementGroupData )

        } else {

            await modifyAchievementGroup( achievementGroupData )

        }

        setShowAchievementModal( false )
        await listAchievementGroups()

    }

    const onDeleteAchievement = async () => {

        setShowAchievementModal( false )
        await listAchievementGroups()

    }

    return (

        <main className="page page--achievements">

            <div className="main-div">

                <div className="navigation-title">

                    <h1 className="title-text-h1">Kitüntetések</h1>

                </div>

                <div className="toolbar">

                    <button className="button button--primary" onClick={ create }>+ Új kitüntetés</button>

                </div>

                <div className={ `card-div card-div--achievements ${ defaultOpen ? `open` : `` }` }>

                    <div className="caption">Általános kitüntetések</div>
                    <button className="button button--expand" onClick={ () => setDefaultOpen( !defaultOpen ) }>

                        <img alt="" src="/image/chevron-left.svg" />

                    </button>

                    <div className="list">

                        { achievementGroups.filter( achievementGroup => achievementGroup.category === 0 ).map( achievementGroup => (

                            <AchievementGroup achievementGroup={ achievementGroup } onClick={ openModal } />

                        ) ) }

                    </div>

                </div>

                <div className={ `card-div card-div--achievements ${ questsOpen ? `open` : `` }` }>

                    <div className="caption">Küldetések</div>
                    <button className="button button--expand" onClick={ () => setQuestsOpen( !questsOpen ) }>

                        <img alt="" src="/image/chevron-left.svg" />

                    </button>

                    <div className="list">

                        { achievementGroups.filter( achievementGroup => achievementGroup.category === 1 ).map( achievementGroup => (

                            <AchievementGroup achievementGroup={ achievementGroup } onClick={ openModal } />

                        ) ) }

                    </div>

                </div>

                <div className={ `card-div card-div--achievements ${ coursesOpen ? `open` : `` }` }>

                    <div className="caption">Képzések</div>
                    <button className="button button--expand" onClick={ () => setCoursesOpen( !coursesOpen ) }>

                        <img alt="" src="/image/chevron-left.svg" />

                    </button>

                    <div className="list">

                        { achievementGroups.filter( achievementGroup => achievementGroup.category === 2 ).map( achievementGroup => (

                            <AchievementGroup achievementGroup={ achievementGroup } onClick={ openModal } />

                        ) ) }

                    </div>

                </div>

                <div className={ `card-div card-div--achievements ${ quizesOpen ? `open` : `` }` }>

                    <div className="caption">Kvízek és tesztek</div>
                    <button className="button button--expand" onClick={ () => setQuizesOpen( !quizesOpen ) }>

                        <img alt="" src="/image/chevron-left.svg" />

                    </button>

                    <div className="list">

                        { achievementGroups.filter( achievementGroup => achievementGroup.category === 3 ).map( achievementGroup => (

                            <AchievementGroup achievementGroup={ achievementGroup } onClick={ openModal } />

                        ) ) }

                    </div>

                </div>

                <div className={ `card-div card-div--achievements ${ eventsOpen ? `open` : `` }` }>

                    <div className="caption">Események</div>
                    <button className="button button--expand" onClick={ () => setEventsOpen( !eventsOpen ) }>

                        <img alt="" src="/image/chevron-left.svg" />

                    </button>

                    <div className="list">

                        { achievementGroups.filter( achievementGroup => achievementGroup.category === 4 ).map( achievementGroup => (

                            <AchievementGroup achievementGroup={ achievementGroup } onClick={ openModal } />

                        ) ) }

                    </div>

                </div>

            </div>

            { showAchievementModal ? (

                <AchievementModal selectedAchievement={ selectedAchievement } onClose={ () => setShowAchievementModal( false ) } onSave={ onSave } onDelete={ onDeleteAchievement } />

            ) : null }

        </main>

    )

}