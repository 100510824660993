import './homepage.page.scss'
import { TextField } from "../../component/field"
import DatePicker from "react-datepicker"
import React, { useState } from "react"
import { MenuButton, Modal, Table } from "../../component"
import { useSelector } from "react-redux"
import { State } from "../../state"
import { createFormattedDateTimeString } from "../../helper"
import { deleteVote, listVotes, saveVote } from "../../action/votes"
import { MessageModal } from "../../component/modal"

const now = new Date()
now.setHours(0, 0, 0, 0)

const aMonth = new Date(now)
aMonth.setDate(aMonth.getDate() + 30)

export const Homepage = () => {

    const [id, setId] = useState(null as number | null)
    const [question, setQuestion] = useState(``)
    const [startDate, setStartDate] = useState(now as Date | null)
    const [endDate, setEndDate] = useState(aMonth as Date | null)

    const [infoModalVisibility, setInfoModalVisibility] = useState(false)
    const [infoModalTitle, setInfoModalTitle] = useState("")
    const [infoModalDescription, setInfoModalDescription] = useState("")

    const [showConfirmModal, setShowConfirmModal] = useState(false)

    const { votes } = useSelector((state: State) => state.votes)

    const checkFields = () => {

        if (question === "") {
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription("A kérdés megadása kötelező!");
            setInfoModalVisibility(true);
            return false
        }

        if (startDate && endDate && startDate.getTime() >= endDate.getTime()) {
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription("A kérdés megjelenítésének dátuma nem lehet később az elrejtés dátumánál!");
            setInfoModalVisibility(true);
            return false
        }

        return true

    }

    const onSave = async () => {

        if (!checkFields()) {
            return
        }

        await saveVote({
            id: id,
            question: question,
            startDate: startDate ? Math.round(startDate.getTime() / 1000) : null,
            endDate: endDate ? Math.round(endDate.getTime() / 1000) : null
        })
        await listVotes()

        setId(null)
        setQuestion(``)
        setStartDate(now)
        setEndDate(aMonth)

    }

    const onDelete = async () => {

        setShowConfirmModal(false)

        await deleteVote(id!)
        await listVotes()

        setId(null)
        setQuestion(``)
        setStartDate(now)
        setEndDate(aMonth)

    }

    const onMenuButtonClick = (action: number, vote: any) => {

        setId(vote.id)
        setQuestion(vote.question)
        setStartDate(new Date(vote.startDate * 1000))
        setEndDate(new Date(vote.endDate * 1000))

        switch (action) {

            case 0:
                break
            default:
                setShowConfirmModal(true)
                break

        }

    }

    return (

        <main className="page page--homepage">

            <div className="main-div">

                <div className="navigation-title">

                    <h1 className="title-text-h1">Főoldal</h1>

                </div>

                <div className="card-div" >

                    <h2 className="title-text-h2">Szavazás {id === null ? `hozzáadása` : `szerkesztése`}</h2>

                    <div className="input-field">

                        <h3 className="title-text-h3">Szavazáson feltett kérdés *</h3>
                        <TextField field={{ error: ``, value: question }} onChange={e => setQuestion(e.target.value)} />

                    </div>

                    <div className="input-fields">

                        <div className="input-field">

                            <h3 className="title-text-h3">Szavazás kezdete *</h3>
                            <DatePicker onChange={date => setStartDate(date)} dateFormat="yyyy. MM. dd." value={startDate!.toLocaleString()} />

                        </div>

                        <div className="input-field">

                            <h3 className="title-text-h3">Szavazás vége *</h3>
                            <DatePicker onChange={date => setEndDate(date)} dateFormat="yyyy. MM. dd." value={endDate!.toLocaleString()} />

                        </div>

                    </div>

                    <footer>

                        <button className="button" onClick={onSave}>Szavazás {id === null ? `hozzáadása` : `mentése`}</button>

                    </footer>

                </div>


                <div className="navigation-title">

                    <h1 className="title-text-h1">Kiküldött szavazások</h1>

                </div>

                <div className="card-div card-div--no-border">

                    <Table columns={[
                        { align: `center`, name: `id`, key: `id`, caption: `ID`, width: 100 },
                        { align: `left`, name: `question`, key: `question`, caption: ``, width: 400 },
                        { align: `center`, name: `yes`, render: data => data.yes, caption: `Igen`, width: 150 },
                        { align: `center`, name: `no`, render: data => data.no, caption: `Nem`, width: 150 },
                        { align: `center`, name: `startDate`, render: data => createFormattedDateTimeString(data.startDate), caption: `Publikálás`, width: 200 },
                        { align: `center`, name: `endDate`, render: data => createFormattedDateTimeString(data.endDate), caption: `Archiválás`, width: 200 },
                        { align: `center`, canOrder: false, name: `actions`, render: data => <MenuButton items={[{ id: 0, value: `Szerkesztés`, icon: `edit.svg` }, { id: 1, value: `Törlés`, icon: `trash.svg` }]} onChange={(id: number) => onMenuButtonClick(id, data)} hasBackground={true} />, caption: ``, width: 50 },
                    ]} dataset={votes} />

                </div>

            </div>

            {infoModalVisibility ? (
                <MessageModal
                    title={infoModalTitle}
                    message={infoModalDescription}
                    onCloseClick={() => {
                        setInfoModalVisibility(false);
                    }}
                />
            ) : null}

            {showConfirmModal ? (

                <Modal className="questionnaire-table-confirm-modal" onModalClose={() => setShowConfirmModal(false)}>

                    <div className="modal-title">Biztosan törlöd a kérdést?</div>
                    <div className="modal-description">Ezt a műveletet nem lehet visszavonni! Biztosan törlöd?</div>
                    <footer>
                        <button className="button button--primary" onClick={() => setShowConfirmModal(false)}>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={onDelete}>Törlés</button>
                    </footer>

                </Modal>

            ) : null}

        </main>
    )
}



