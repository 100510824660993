import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { ListQuotesDTO } from '../../dto/quotes'
import { store } from '../../store'

export const listQuotes = async ( page: number = 1 ) => {

    let body, response
    const { orderBy, orderDirection, keyword, deleted, selectedPolitician, selectedTimeline } = store.getState().quotes

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/quotes/?page=${ page }&order_by=${ orderBy }&order_direction=${ orderDirection }&deleted=${ deleted }${ keyword.length > 0 ? `&keyword=${ keyword }` : `` }&selectedPolitician=${ selectedPolitician }&selectedTimeline=${ selectedTimeline }`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'get'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'LIST_QUOTES_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'LIST_QUOTES_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'LIST_QUOTES_UNEXPECTEDRESPONSE' } )
        return

    }

    try {

        body = plainToClass( ListQuotesDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'LIST_QUOTES_INVALIDJSON' } )
        return

    }

    if ( ( await validate( body ) ).length > 0 ) {

        store.dispatch( { type: 'LIST_QUOTES_INVALIDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'LIST_QUOTES_OK', pagination: body.pagination, quotes: body.quotes } )

}
