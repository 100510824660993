import { MouseEvent, useState } from 'react'
import './menu.button.scss'

interface MenuButtonProps {
    hasBackground: boolean
    value?: any
    items: MenuButtonItem[]
    onChange?: any
    disabled?: boolean
}

interface MenuButtonItem {
    id: number
    value: string
    icon: string
    disabled?: boolean
}

export const MenuButton = (props: MenuButtonProps) => {
    const [items, setItems] = useState<MenuButtonItem[]>(props.items || [])
    const [showItems, setShowItems] = useState(false)

    const closeMenu = () => {
        setShowItems(false)
        window.removeEventListener(`click`, closeMenu)
    }

    const onDropDownClick = (e: MouseEvent) => {
        if (props.disabled === true) {
            return
        }
        if (!showItems) {
            setTimeout(() => window.addEventListener(`click`, closeMenu), 0)
            setShowItems(true)
        }
    }

    const onSelectItem = (item: MenuButtonItem) => {
        setShowItems(false)
        props.onChange(item.id)
    }

    return (
        <div className="button--menu--container">
            <button type="button" className={props.hasBackground ? `button--menu` : `button--menu-hidden`} onClick={onDropDownClick}>
                <span id={'menu-div'} className="material-symbols-outlined points">
                    more_horiz
                </span>
            </button>

            <div style={{ display: showItems ? 'inline' : 'none' }} className={'button--menu--items'}>
                {items.map((item) => (
                    <div key={item.id} onClick={() => onSelectItem(item)} className={item.disabled === true ? `disabled` : ``}>
                        <img className="icon" alt={item.value} src={`/image/` + item.icon} />
                        <span className="text">{item.value} </span>
                    </div>
                ))}
            </div>
        </div>
    )
}
