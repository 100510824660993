import { Expose, Type } from 'class-transformer'
import { IsBoolean, IsDefined, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'
import { ImageFile } from './image-file.entity'
import { QuestionnaireQuestion } from './questionnaire-question.entity'
import { Tag } from './tag.entity'
import { QuestionnaireMessage } from './questionnaire-message.entity'

export class Questionnaire {

    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number 

    @Expose()
    @IsDefined()
    @IsString()
    title!: string

    @Expose()
    @IsDefined()
    @IsString()
    lead!: string

    @Expose()
    @IsDefined()
    @IsString()
    description!: string

    @Expose()
    @IsDefined()
    @IsNumber()
    numberOfCompleteRequired!: number

    @IsOptional()
    @IsNumber()
    numberOfCompleted!: number

    @IsOptional()
    @ValidateNested()
    @Type( () => ImageFile )
    imageFile?: ImageFile | null

    @Expose()
    @IsDefined()
    @IsNumber()
    visibility!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    visibleFrom!: number

    @Expose()
    @IsDefined()
    @IsBoolean()
    sendPush!: boolean

    @Expose()
    @IsDefined()
    @IsNumber()
    visibleTo!: number 

    @Expose()
    @IsDefined()
    @IsNumber()
    status!: number 

    @IsOptional()
    @ValidateNested()
    @Type( () => QuestionnaireQuestion )
    questions?: QuestionnaireQuestion[] | null

    @Expose()
    tags!: Tag[]

    @Expose()
    experience!: number

    @Expose()
    messages!: QuestionnaireMessage[]

}
