

import { Expose, Type } from 'class-transformer'
import { IsArray, IsDefined, IsNumber, IsOptional, ValidateNested } from 'class-validator'

export class GetQuotesStatisticsDTO {

    @Expose()
    @IsDefined()
    @IsNumber()
    quotePoliticianSwipedCount!: number 

    @Expose()
    @IsDefined()
    @IsArray()
    quotePoliticianSwipedXAxis!: [] 

    @Expose()
    @IsDefined()
    @IsArray()
    quotePoliticianSwipedData!: [] 

}
