import { Expose } from 'class-transformer'
import { IsDefined, IsNumber, IsString } from 'class-validator'

export class UserActivity {

    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsString()
    action!: string

    @Expose()
    @IsDefined()
    @IsString()
    description!: string

    @Expose()
    @IsDefined()
    @IsNumber()
    activityDate!: number

}
