import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { resetAdminPassword } from '../../action'
import { PasswordField, TextField } from '../../component/field'
import { State } from '../../state'
import { store } from '../../store'
import './admin-set-password.page.scss'
import { title } from '../../helper'

export const AdminSetPasswordPage = () => {

    const dispatch = useDispatch()

    const { token } = useParams()

    const { firstName, lastName } = useSelector( ( state: State ) => state.users.adminSetForm )

    const [ password, setPassword ] = useState( { error: ``, value: `` } )
    const [ confirmPassword, setConfirmPassword ] = useState( { error: ``, value: `` } )
    const { setAdminPasswordBusy, setAdminPasswordDone, setAdminPasswordTokenValid } = useSelector( ( state: State ) => state.users )

    const onResetPasswordButtonClick = ( event: MouseEvent ) => { }

    const onPasswordChange = ( event: ChangeEvent<HTMLInputElement> ) => {

        setPassword( { ...password, value: event.target.value, error: `` } )
        setConfirmPassword( { ...confirmPassword, error: `` } )

    }

    const onConfirmPasswordChange = ( event: ChangeEvent<HTMLInputElement> ) => {

        setPassword( { ...password, error: `` } )
        setConfirmPassword( { ...confirmPassword, value: event.target.value, error: `` } )

    }

    const onFormSubmit = ( event: FormEvent ) => {

        event.preventDefault()

        if ( password.value !== confirmPassword.value ) {

            setPassword( { ...password, error: ` ` } )
            setConfirmPassword( { ...confirmPassword, error: `A két jelszó nem egyezik meg!` } )
            return

        }

        store.dispatch( { type: `SET_ADMIN_PASSWORD_REQUEST` } )
        resetAdminPassword( firstName.value, lastName.value, token!, password.value )

    }

    const navigate = useNavigate()

    const onLastNameChange = ( event: ChangeEvent<HTMLInputElement> ) => {
        dispatch( { type: `SET_ADMIN_SET_FORM`, field: `lastName`, value: event.target.value } )
    }
    const onFirstNameChange = ( event: ChangeEvent<HTMLInputElement> ) => {
        dispatch( { type: `SET_ADMIN_SET_FORM`, field: `firstName`, value: event.target.value } )
    }

    return (

        <main className="page page--admin-set-password">

            <form action="" className="admin-set-password-form" onSubmit={ onFormSubmit }>

                <img alt="A Demokratikus Koalíció logója" className="admin-set-password-form__logo" src="/image/dk-logo.svg" />

                { setAdminPasswordTokenValid === false ? (

                    <div>

                        <h1>{ title( `password` ) }</h1>

                        <div className="description">

                            Sajnos az általad használt<br />hivatkozás nem működik.<br /><br />
                            Lehetséges, hogy túl sokat vártál<br /> és a hivatkozás érvényessége lejárt,<br />
                            vagy már egyszer felhasználtad azt.<br /><br />
                            Próbálkozz újra!

                        </div>

                        <footer>

                            <button className="button" onClick={ e => { e.preventDefault(); navigate( `/bejelentkezes` ) } }>

                                Vissza a bejelentkezéshez

                            </button>

                        </footer>

                    </div>

                ) : setAdminPasswordDone !== true ? (

                    <div>

                        <h1>{ title( `password` ) }</h1>

                        <TextField field={ lastName } onChange={ onLastNameChange } placeholder="keresztnév" />
                        <TextField field={ firstName } onChange={ onFirstNameChange } placeholder="vezetéknév" />
                        <PasswordField field={ password } onChange={ onPasswordChange } placeholder="jelszó" />
                        <PasswordField field={ confirmPassword } onChange={ onConfirmPasswordChange } placeholder="jelszó megerősítése" />

                        <footer>

                            <button className="button" disabled={ password.value.trim().length === 0 || confirmPassword.value.trim().length === 0 || setAdminPasswordBusy } onClick={ onResetPasswordButtonClick } type="submit">

                                Módosítás

                            </button>

                        </footer>

                    </div>

                ) : (

                    <div>

                        <h1>Sikeres jelszó változtatás!</h1>

                        <div className="description">

                            Most már beléphetsz az új jelszavaddal!

                        </div>

                        <footer>

                            <button className="button" onClick={ e => { e.preventDefault(); navigate( `/bejelentkezes` ) } }>

                                Vissza a bejelentkezéshez

                            </button>

                        </footer>

                    </div>

                ) }


            </form>

        </main>

    )

}
