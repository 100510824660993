import { GET_NEWS_CATEGORIES_ACTIONS } from '../../action/news-category/news-category.constants'
import { NewsCategoriesState } from '../../state/news-category'

const initialNewsCategoriesState: NewsCategoriesState  = {
    newsCategories: undefined,
    newsNotEmpty: false
}

export const newsCategoriesReducer = ( state: NewsCategoriesState = initialNewsCategoriesState, action: any ): NewsCategoriesState => {
    switch ( action.type ) {
        case 'GET_NEWS_CATEGORIES_OK':
            return { ...state, newsCategories: action.newsCategories }
        case 'GET_NEWS_CATEGORIES_INVALIDJSON':
        case 'GET_NEWS_CATEGORIES_INVALIDRESPONSE':
        case 'GET_NEWS_CATEGORIES_NOUSER':
        case 'GET_NEWS_CATEGORIES_TIMEOUT':
        case 'GET_NEWS_CATEGORIES_UNAUTHORIZED':
        case 'GET_NEWS_CATEGORIES_UNEXPECTEDRESPONSE':
            return { ...state, newsCategories: null }
        case GET_NEWS_CATEGORIES_ACTIONS.GET_NEWS_CATEGORIES_NEWS_NOT_EMPTY:
            return { ...state, newsNotEmpty : true }
        case GET_NEWS_CATEGORIES_ACTIONS.GET_NEWS_CATEGORIES_NEWS_NOT_EMPTY_CLEAR:
            return { ...state, newsNotEmpty : false }
        default:
            return state
    }
}
