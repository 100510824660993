import React, { useState } from "react";
import { useSelector } from "react-redux";
import { deleteUser, listAdminActivities } from "../../action";
import { Modal, UserModal } from "../../component";
import { AdminActivity } from "../../component/admin-activity";
import { State } from "../../state";
import "./admin-user.page.scss";
import { NavLink, useNavigate } from "react-router-dom";

const items = [
  { name: `Hírek`, activityTypes: [`News`, `NewsCategory`] },
  { name: `Véleményvezérek`, activityTypes: [`Quote`] },
  { name: `Kérdőívek`, activityTypes: [`Questionnaire`] },
  { name: `Események`, activityTypes: [`Event`] },
  { name: `Politikusaink`, activityTypes: [`Politician`] },
  { name: `Kvízek és tesztek`, activityTypes: [`Quiz`, `Test`] },
  { name: `Képzések`, activityTypes: [`Course`, `Lesson`] },
  { name: `Küldetések`, activityTypes: [`Quest`, `QuestGroup`] },
  { name: `Értesítések`, activityTypes: [`PushMessage`] },
  { name: `Floating messages`, activityTypes: [`FloatingMessage`] },
  { name: `Címkék`, activityTypes: [`Tag`, `TagGroup`] },
];

export const AdminUserPage = () => {
  const navigate = useNavigate();
  const [showUserModal, setShowUserModal] = useState(false);
  const { adminActivities, user } = useSelector((state: State) => state.users);
  const currentUser = useSelector(
    (state: State) => state.session.authenticatedUser
  );
  const [selectedItem, setSelectedItem] = useState(items[0]);

  const [deleteFunc, setDeleteFunc] = useState(null as Function | null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onDelete = async () => {
    await deleteFunc!();
    setShowConfirmModal(false);
  };

  const onDeleteClick = async () => {
    setDeleteFunc(() => {
      return async () => {
        await deleteUser(user!.id);
        navigate(`/tagok/adminok`);
      };
    });
    setShowConfirmModal(true);
  };

  const selectItem = async (item: {
    name: string;
    activityTypes: string[];
  }) => {
    await listAdminActivities(item.activityTypes, user!.id);
    setSelectedItem(item);
  };

  return (
    <main className="page page--admin-user">
      <div className="main-div">
        <header>
          <h1>
            <NavLink to="/tagok/adminok">Adminok</NavLink>
            &nbsp;&gt;&nbsp;
            <span>
              {user!.lastName} {user!.firstName}
            </span>
          </h1>
        </header>

        <div className="admin-details card-div">
          <div className="admin-name">
            {user!.lastName} {user!.firstName}
          </div>

          <div className="admin-email">
            <label>E-mail cím</label>
            <span>{user!.email}</span>
          </div>

          <div className="actions">
            <button
              className="button button--warning"
              onClick={onDeleteClick}
              disabled={
                user!.userGroups.find((userGroup) => userGroup.id === 2) !==
                  undefined || currentUser!.membershipId === user!.membershipId
              }
            >
              Törlés
            </button>
            <button
              className="button button--primary"
              onClick={() => setShowUserModal(true)}
            >
              Szerkesztés
            </button>
          </div>
        </div>

        <div className="activities card-div">
          <div className="tabs">
            {items.map((item) => (
              <a
                className={
                  selectedItem !== null && selectedItem.name === item.name
                    ? `selected`
                    : ``
                }
                onClick={() => selectItem(item)}
              >
                {item.name}
              </a>
            ))}
          </div>

          <div className="admin-activities">
            {adminActivities.length === 0 ? (
              <div className="user-activities__empty">
                Nincs megjeleníthető elem
              </div>
            ) : null}

            {adminActivities.map((adminActivity) => (
              <AdminActivity
                key={adminActivity.id}
                adminActivity={adminActivity}
              />
            ))}
          </div>
        </div>
      </div>

      {showUserModal ? (
        <UserModal onClose={() => setShowUserModal(false)} />
      ) : null}

      {showConfirmModal ? (
        <Modal
          className="application-version-confirm-modal"
          onModalClose={() => setShowConfirmModal(false)}
        >
          <div className="modal-title">Biztosan törlöd az adminisztrátort?</div>
          <div className="modal-description">
            Ezt a műveletet nem lehet visszavonni! Biztosan törlöd?
          </div>
          <footer>
            <button
              className="button button--primary"
              onClick={() => setShowConfirmModal(false)}
            >
              Mégsem
            </button>
            <button
              className="button button--ghost button--warning"
              onClick={onDelete}
            >
              Törlés
            </button>
          </footer>
        </Modal>
      ) : null}
    </main>
  );
};
