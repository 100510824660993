export const POLITICIANS_ACTIONS = {
    GET_POLITICIANS_INVALID_JSON: "GET_POLITICIANS_INVALID_JSON",
    GET_POLITICIANS_INVALID_RESPONSE: "GET_POLITICIANS_INVALID_RESPONSE",
    GET_POLITICIANS_OK: "GET_POLITICIANS_OK",
    GET_POLITICIANS_SEARCH: "GET_POLITICIANS_SEARCH",
    GET_POLITICIANS_ORDER: "GET_POLITICIANS_ORDER",
    GET_POLITICIANS_SELECTED_POLITICIAN: "GET_POLITICIANS_SELECTED_POLITICIAN",

    GET_POLITICIAN_QUESTIONS_OK: "GET_POLITICIAN_QUESTIONS_OK",
    GET_POLITICIAN_QUESTION_SEARCH: "GET_POLITICIAN_QUESTION_SEARCH",
    GET_POLITICIAN_QUESTIONS_INVALID_JSON: "GET_POLITICIAN_QUESTIONS_INVALID_JSON",
    GET_POLITICIAN_QUESTIONS_INVALID_RESPONSE: "GET_POLITICIAN_QUESTIONS_INVALID_RESPONSE",

    GET_POLITICIAN_TITLES_INVALID_JSON: "GET_POLITICIAN_TITLES_INVALID_JSON",
    GET_POLITICIAN_TITLES_INVALID_RESPONSE: "GET_POLITICIAN_TITLES_INVALID_RESPONSE",
    GET_POLITICIAN_TITLES_OK: "GET_POLITICIAN_TITLES_OK",
    GET_POLITICIAN_TITLES_POLITICIAN_NOT_EMPTY: "GET_POLITICIAN_TITLES_POLITICIAN_NOT_EMPTY",
    GET_POLITICIAN_TITLES_POLITICIAN_NOT_EMPTY_CLEAR:  "GET_POLITICIAN_TITLES_POLITICIAN_NOT_EMPTY_CLEAR",
}

export const GET_POLITICIAN_STATS_ACTIONS = {
    GET_POLITICIAN_STATS_INVALID_JSON: "GET_POLITICIAN_STATS_INVALID_JSON",
    GET_POLITICIAN_STATS_INVALID_RESPONSE: "GET_POLITICIAN_STATS_INVALID_RESPONSE",
    GET_POLITICIAN_STATS_OK: "GET_POLITICIAN_STATS_OK",
}

export const DELETE_POLITICIAN_QUESTION_ACTIONS = {
    DELETE_POLITICIAN_QUESTION_STARTED: "DELETE_POLITICIAN_QUESTION_STARTED",
    DELETE_POLITICIAN_QUESTION_OK: "DELETE_POLITICIAN_QUESTION_OK",
}

export const CREATE_POLITICIAN_QUESTION_ACTIONS = {
    CREATE_POLITICIAN_QUESTION_STARTED: "CREATE_POLITICIAN_QUESTION_STARTED",
    CREATE_POLITICIAN_QUESTION_INVALID_JSON: "CREATE_POLITICIAN_QUESTION_INVALID_JSON",
    CREATE_POLITICIAN_QUESTION_INVALID_RESPONSE: "CREATE_POLITICIAN_QUESTION_INVALID_RESPONSE",
    CREATE_POLITICIAN_QUESTION_OK: "CREATE_POLITICIAN_QUESTION_OK",
    CREATE_POLITICIAN_QUESTION_CHANGE_QUESTION: "CREATE_POLITICIAN_QUESTION_CHANGE_QUESTION",
    CREATE_POLITICIAN_QUESTION_CLEAR: "CREATE_POLITICIAN_QUESTION_CLEAR",
    CREATE_POLITICIAN_QUESTION_MODIFY_QUESTION: "CREATE_POLITICIAN_QUESTION_MODIFY_QUESTION",
}

export const DELETE_POLITICIAN_ACTIONS = {
    DELETE_POLITICIAN_STARTED: "DELETE_POLITICIAN_STARTED",
    DELETE_POLITICIAN_OK: "DELETE_POLITICIAN_OK",
}

export const CREATE_POLITICIAN_TITLE_ACTIONS = {
    CREATE_POLITICIAN_TITLE_STARTED: "CREATE_POLITICIAN_TITLE_STARTED",
    CREATE_POLITICIAN_TITLE_INVALID_JSON: "CREATE_POLITICIAN_TITLE_INVALID_JSON",
    CREATE_POLITICIAN_TITLE_INVALID_RESPONSE: "CREATE_POLITICIAN_TITLE_INVALID_RESPONSE",
    CREATE_POLITICIAN_TITLE_OK: "CREATE_POLITICIAN_TITLE_OK",
    CREATE_POLITICIAN_TITLE_CHANGE_TITLE: "CREATE_POLITICIAN_TITLE_CHANGE_TITLE",
    CREATE_POLITICIAN_TITLE_CLEAR: "CREATE_POLITICIAN_TITLE_CLEAR",
    CREATE_POLITICIAN_TITLE_MODIFY_TITLE: "CREATE_POLITICIAN_TITLE_MODIFY_TITLE",
    CREATE_POLITICIAN_TITLE_CHANGE_POSITION: "CREATE_POLITICIAN_TITLE_MODIFY_POSITION"
}

export const DELETE_POLITICIAN_TITLE_ACTIONS = {
    DELETE_POLITICIAN_TITLE_STARTED: "DELETE_POLITICIAN_TITLE_STARTED",
    DELETE_POLITICIAN_TITLE_OK: "DELETE_POLITICIAN_TITLE_OK",
}
