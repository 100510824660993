import { Expose } from 'class-transformer'
import { IsDefined } from 'class-validator'

export class ListConsolationPrizesDTO {

    @Expose()
    @IsDefined()
    consolationPrizes!: {
        leagueId: number,
        exp: number
    }[]

}

