import { Expose } from 'class-transformer'
import { IsDefined, IsNumber } from 'class-validator'

export class GetEventStatsDTO {

    @Expose()
    @IsDefined()
    @IsNumber()
    eventCount!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    userCount!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    uploadedImageCount!: number

}

