import { MouseEvent } from 'react'
import { useDispatch } from 'react-redux'
import { PushMessageTarget } from '../../../entity'
import { TargetElement } from './target-element'
import { CREATE_PUSH_ACTIONS } from '../../../action/push/push.constants'
import './target-table.scss'

interface TargetTableProps {
    targetArray: PushMessageTarget[]
}

export const TargetTable = (props: TargetTableProps) => {
    const dispatch = useDispatch()

    const targetElements: JSX.Element[] = []
    props.targetArray.map((targetElement: PushMessageTarget, index: number) => {
        targetElements.push(<TargetElement targetElement={targetElement} position={index} />)
    })

    const onAddTargetClick = async (event: MouseEvent) => {
        event.preventDefault()
        dispatch({
            type: CREATE_PUSH_ACTIONS.CREATE_PUSH_ADD_TARGET,
        })
    }

    return (
        <div className="target-table-container ">
            <ul id={'target_table'} className="target-table">
                {targetElements.length != 0 ? targetElements : null}
            </ul>

            <button className="button" onClick={onAddTargetClick}>
                Új kritérium
            </button>
        </div>
    )
}
