import { LoaderFunctionArgs } from 'react-router-dom'
import { getPoliticianQuestions, getPoliticianTitles } from '../../action/politician'
import { listTags } from '../../action/tags'
import { listDefaultExperiencePoints } from '../../action/gamification'

export const createPoliticianLoader = async ( args: LoaderFunctionArgs ) => {

    await listDefaultExperiencePoints()

    await listTags()

    await getPoliticianQuestions()

    await getPoliticianTitles()

}
