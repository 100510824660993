import { ChangeEvent, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CREATE_QUESTIONNAIRE_ACTIONS } from '../../../../action/questionnaires/questionnaires.constants'
import { AddImage } from '../../../../component/add-image'
import { TextField } from '../../../../component/field'
import { ImageEditor } from '../../../../component/image-editor/image-editor'
import { MediaLibrary } from '../../../../component/media-library'
import { MediaLibraryFile, QuestionnaireQuestion } from '../../../../entity'
import './questionnaire-question-answer-image-table.scss'

interface QuestionnaireQuestionAnsweImageTableProps {
    questionnaireQuestionElement: QuestionnaireQuestion
    disabled?: boolean
}

export const QuestionnaireQuestionAnsweImageTable = ( props: QuestionnaireQuestionAnsweImageTableProps ) => {
    
    const dispatch = useDispatch()
    
    const onAnswer1Change = ( event: ChangeEvent<HTMLInputElement> ) => {
        event.preventDefault()
        dispatch( { type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ANSWER_ITEM_ANSWER, answer: event.target.value, id: props.questionnaireQuestionElement.answers!![0].id, questionId: props.questionnaireQuestionElement.id } )
    }

    const onAnswer2Change = ( event: ChangeEvent<HTMLInputElement> ) => {
        event.preventDefault()
        dispatch( { type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ANSWER_ITEM_ANSWER, answer: event.target.value, id: props.questionnaireQuestionElement.answers!![1].id, questionId: props.questionnaireQuestionElement.id } )
    }

    const onAnswer3Change = ( event: ChangeEvent<HTMLInputElement> ) => {
        event.preventDefault()
        dispatch( { type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ANSWER_ITEM_ANSWER, answer: event.target.value, id: props.questionnaireQuestionElement.answers!![2].id, questionId: props.questionnaireQuestionElement.id } )
    }

    const onAnswer4Change = ( event: ChangeEvent<HTMLInputElement> ) => {
        event.preventDefault()
        dispatch( { type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ANSWER_ITEM_ANSWER, answer: event.target.value, id: props.questionnaireQuestionElement.answers!![3].id, questionId: props.questionnaireQuestionElement.id } )
    }

    const [ mediaLibraryFile, setMediaLibraryFile ] = useState( null as MediaLibraryFile | null )
    const [ mediaLibraryVisibility, setMediaLibraryVisibility ] = useState( false )
    const [ imageEditorVisibility, setImageEditorVisibility ] = useState( false )
    const [ answerPosition, setAnswerPosition ] = useState( 0 )

    return (
        <div className="questionnaire-question-answer-image-table-container">

            <h3 className="title-text-h3">Képek feltöltése *</h3>

            <div className="questionnaire-question-answer-image-div-container">

                {/* --- Kép--- */}
                <div className="image-section-container"  >                                

                    <div className="image-text-field-container" style={{marginTop:"20px"}} >
                        <AddImage width={ "255px" } height={ "148px" } imageUrl={ ( props.questionnaireQuestionElement.answers != null && props.questionnaireQuestionElement.answers[0] != null) ? props.questionnaireQuestionElement.answers[0].imageUrl : "" } text={ "Kép hozzáadása a kérdőívhez (16:9)" }
                            onAddImage={ () =>  {
                                setMediaLibraryVisibility( true ) 
                                setAnswerPosition(0)
                            } } 
                            onDeleteImage={ () => {
                                setMediaLibraryFile( null )
                                dispatch( { type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ANSWER_MEDIA_LIBRARY_FILE, mediaLibraryFile: null, id: props.questionnaireQuestionElement.id, answerPosition: 0} )
                            } } disabled={ props.disabled === true }
                        />

                        { props.questionnaireQuestionElement.answers != null && props.questionnaireQuestionElement.answers[0] != null ? 
                            <div className="" style={{marginLeft:'20px'}}>
                                <div className="input-container-header" >
                                    <h3 className="title-text-h3">Kép 1 szöveg (max 40 karakter) *</h3>
                                </div>
                                <TextField field={ props.questionnaireQuestionElement.answers[0].answerField } maxlength={ 40 } onChange={ onAnswer1Change } placeholder="" category='question' disabled={ props.disabled === true } />
                            </div> 
                            : null
                        }
                    </div> 
                      
                </div>

                <div className="image-section-container" >                                

                    <div className="image-text-field-container" style={{marginTop:"20px"}} >
                        <AddImage width={ "255px" } height={ "148px" } imageUrl={ ( props.questionnaireQuestionElement.answers != null && props.questionnaireQuestionElement.answers[1] != null) ? props.questionnaireQuestionElement.answers[1].imageUrl : "" } text={ "Kép hozzáadása a kérdőívhez (16:9)" }
                            onAddImage={ () =>  {
                                setMediaLibraryVisibility( true ) 
                                setAnswerPosition(1)
                            } } 
                            onDeleteImage={ () => {
                                setMediaLibraryFile( null )
                                dispatch( { type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ANSWER_MEDIA_LIBRARY_FILE, mediaLibraryFile: null, id: props.questionnaireQuestionElement.id, answerPosition: 1} )
                            } } disabled={ props.disabled === true }
                        />

                        { props.questionnaireQuestionElement.answers != null && props.questionnaireQuestionElement.answers[1] != null ? 
                            <div className="" style={{marginLeft:'20px'}}>
                                <div className="input-container-header" >
                                    <h3 className="title-text-h3">Kép 2 szöveg (max 40 karakter) *</h3>
                                </div>
                                <TextField field={ props.questionnaireQuestionElement.answers[1 ].answerField } maxlength={ 40 } onChange={ onAnswer2Change } placeholder="" category='question' disabled={ props.disabled === true } />
                            </div> 
                            : null
                        }
                    </div> 

                </div>

            </div>

            <div className="questionnaire-question-answer-image-div-container">

                <div className="image-section-container" >   

                    <div className="image-text-field-container" style={{marginTop:"20px"}} >
                        <AddImage width={ "255px" } height={ "148px" } imageUrl={ ( props.questionnaireQuestionElement.answers != null && props.questionnaireQuestionElement.answers[2] != null) ? props.questionnaireQuestionElement.answers[2].imageUrl : "" } text={ "Kép hozzáadása a kérdőívhez (16:9)" }
                            onAddImage={ () =>  {
                                setMediaLibraryVisibility( true ) 
                                setAnswerPosition(2)
                            } } 
                            onDeleteImage={ () => {
                                setMediaLibraryFile( null )
                                dispatch( { type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ANSWER_MEDIA_LIBRARY_FILE, mediaLibraryFile: null, id: props.questionnaireQuestionElement.id, answerPosition: 2} )
                            } } disabled={ props.disabled === true }
                        />

                        { props.questionnaireQuestionElement.answers != null && props.questionnaireQuestionElement.answers[2] != null ? 
                            <div className="" style={{marginLeft:'20px'}}>
                                <div className="input-container-header" >
                                    <h3 className="title-text-h3">Kép 3 szöveg (max 40 karakter) *</h3>
                                </div>
                                <TextField field={ props.questionnaireQuestionElement.answers[2].answerField } maxlength={ 40 } onChange={ onAnswer3Change } placeholder="" category='question' disabled={ props.disabled === true } />
                            </div> 
                            : null
                        }
                    </div>                              

                </div>

                <div className="image-section-container" >                                

                    <div className="image-text-field-container" style={{marginTop:"20px"}} >
                        <AddImage width={ "255px" } height={ "148px" } imageUrl={ ( props.questionnaireQuestionElement.answers != null && props.questionnaireQuestionElement.answers[3] != null) ? props.questionnaireQuestionElement.answers[3].imageUrl : "" } text={ "Kép hozzáadása a kérdőívhez (16:9)" }
                            onAddImage={ () =>  {
                                setMediaLibraryVisibility( true ) 
                                setAnswerPosition(3)
                            } } 
                            onDeleteImage={ () => {
                                setMediaLibraryFile( null )
                                dispatch( { type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ANSWER_MEDIA_LIBRARY_FILE, mediaLibraryFile: null, id: props.questionnaireQuestionElement.id, answerPosition: 3} )
                            } }  disabled={ props.disabled === true }
                        />

                        { props.questionnaireQuestionElement.answers != null && props.questionnaireQuestionElement.answers[3] != null ? 
                            <div className="" style={{marginLeft:'20px'}}>
                                <div className="input-container-header" >
                                    <h3 className="title-text-h3">Kép 4 szöveg (max 40 karakter) *</h3>
                                </div>
                                <TextField field={ props.questionnaireQuestionElement.answers[3].answerField } maxlength={ 40 } onChange={ onAnswer4Change } placeholder="" category='question' disabled={ props.disabled === true } />
                            </div> 
                            : null
                        }
                    </div>    
                
                </div>
                    
            </div>

            { mediaLibraryVisibility ? <MediaLibrary onClose={ () => setMediaLibraryVisibility( false ) } onMediaSelected={ ( file: MediaLibraryFile ) => {
                setMediaLibraryFile( file )
                setMediaLibraryVisibility( false )
                setImageEditorVisibility( true )
            } } /> : null }

            { imageEditorVisibility ? <ImageEditor mediaLibraryFile={ mediaLibraryFile! } aspectRatio ={{name: "16:9", ratio: 16/9}} onClose={ () => setImageEditorVisibility( false ) } onReady={ file => {
                dispatch( { type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ANSWER_MEDIA_LIBRARY_FILE, mediaLibraryFile: file, id: props.questionnaireQuestionElement.id, answerPosition: answerPosition} )
                setImageEditorVisibility( false )
            } } /> : null } 

        </div>
    )

}
