import './video-categories-modal.scss'
import {MessageModal, Modal} from '../modal'
import { TextField } from '../field'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { State } from '../../state'
import { deleteVideoCategory, listVideoCategories, saveVideoCategory } from '../../action/videos'

interface CourseTopicsModalProps {

    onClose?: () => void

}

export const VideoCategoriesModal = ( props: CourseTopicsModalProps ) => {

    const onClose = () => { if ( props.onClose ) props.onClose()  }

    const { videoCategories } = useSelector( ( state: State ) => state.videos )

    const [ selectedCategory, setSelectedCategory ] = useState( null as number | null )
    const [ name, setName ] = useState( `` )
    const [ color, setColor ] = useState( `` )

    const colors = [ `#2AA7DF`, `#C30870`, `#FFD200`, `#007F4C`, `#7FAD17`, `#9A8822`, `#555ADF`, `#401794`, `#7D3B8A`, `#784CD2`, `#C61A2E`, `#FF168B` ]

    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )
    const [ showConfirmModal, setShowConfirmModal ] = useState( false )

    const [infoModalVisibility, setInfoModalVisibility] = useState(false);
    const [infoModalTitle, setInfoModalTitle] = useState("");
    const [infoModalDescription, setInfoModalDescription] = useState("");

    const onDelete = async () => {

        await deleteFunc!()
        setShowConfirmModal( false )

    }

    const create = () => {

        setName( `` )
        setColor( `#2AA7DF` )
        setSelectedCategory( null )

    }

    const select = ( category: any ) => {

        setName( category.name )
        setColor( category.color )
        setSelectedCategory( category.id )

    }

    const save = async () => {

        if (name.trim().length === 0) {
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription("A kategória nevének megadása kötelező!");
            setInfoModalVisibility(true);
            return;
        }

        await saveVideoCategory( {
            id: selectedCategory,
            name: name,
            color: color
        } )
        await listVideoCategories()
        create()

    }

    const deleteCategory = async () => {

        setDeleteFunc( () => {
            return async () => {
                await deleteVideoCategory( selectedCategory! )
                await listVideoCategories()
                create()
            }
        } )
        setShowConfirmModal( true )

    }

    return (

        <>

            <Modal className="video-categories-modal" onModalClose={ onClose }>

                <img alt="" className="modal-close" src="/image/close.svg" onClick={ onClose } />

                <div className="modal-header">

                    <div className="modal-title">Kategóriák</div>

                </div>

                <div className="modal-body">

                    <div className="categories-list">

                        { videoCategories.map( category => (

                            <div className="category" onClick={ () => select( category ) }>

                                <span>{ category.name }</span>
                                <img alt="" src="/image/edit.svg" />

                            </div>

                        ) ) }
                        <button className="button button--primary" onClick={ create }>Új kategória hozzáadása +</button>

                    </div>

                    <div className="separator"></div>

                    <div className="category-detail card-div">

                        <div className="category-detail--title">Kategória { selectedCategory === null ? `hozzáadása` : `szerkesztése` }</div>

                        <div className="category-detail--form">

                            <div className="category-detail--field">

                                <label>Kategória neve:</label>
                                <TextField field={{ value: name, error: `` }} onChange={ e => setName( e.target.value ) } />

                            </div>

                            <div className="category-detail--field">

                                <label>Kategória színe:</label>
                                <div className="color-picker">

                                    <div className="color-picker__selected" style={{ backgroundColor: color }}></div>

                                    <div className="color-picker__selector">

                                        { colors.map( color => (

                                            <div className="color" style={{ backgroundColor: color }} onClick={ () => setColor( color ) }></div>

                                        ) ) }

                                    </div>

                                </div>

                            </div>

                            <div className="category-detail--footer">

                                { selectedCategory !== null ? (

                                    <button className="button button--warning button--ghost" onClick={ deleteCategory }>Törlés</button>

                                ) : null }

                                <button className="button button--primary" onClick={ save } >Módosítások mentése</button>

                            </div>

                        </div>

                    </div>

                </div>

            </Modal>

            { showConfirmModal ? (

                <Modal className="application-version-confirm-modal" onModalClose={ () => setShowConfirmModal( false ) }>

                    <div className="modal-title">Biztosan törlöd a kategóriát?</div>
                    <div className="modal-description">Ezt a műveletet nem lehet visszavonni! Biztosan törlöd?</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowConfirmModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ onDelete }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

            {infoModalVisibility ? (

                <MessageModal
                    title={infoModalTitle}
                    message={infoModalDescription}
                    onCloseClick={() => {
                        setInfoModalVisibility(false);
                    }}
                />

            ) : null}

        </>

    )

}
