export enum County {

    BACS_KISKUN = 1,
    BARANYA = 2,
    BEKES = 3,
    BORSOD_ABAUJ_ZEMPLEN = 4,
    CSONGRAD_CSANAD = 5,
    FEJER = 6,
    GYOR_MOSON_SOPRON = 7,
    HAJDU_BIHAR = 8,
    HEVES = 9,
    JASZ_NAGYKUN_SZOLNOK = 10,
    KOMAROM_ESZTERGOM = 11,
    NOGRAD = 12,
    PEST = 13,
    SOMOGY = 14,
    SZABOLCS_SZATMAR_BEREG = 15,
    TOLNA = 16,
    VAS = 17,
    VESZPREM = 18,
    ZALA = 19

}
