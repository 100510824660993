import { LoaderFunctionArgs } from 'react-router-dom'
import { getUser, getUserActivities } from '../../action'
import { listTags } from '../../action/tags'
import { getInterests } from '../../action/users/get-interest.action'

export const mobileAppUserLoader = async ( args: LoaderFunctionArgs ) => {

    await listTags()
    await getUser( parseInt( args.params.id as string ) )
    await getUserActivities( parseInt( args.params.id as string ), [ 18 ] )
    await getInterests( parseInt( args.params.id as string ) )

}
