import { MouseEventHandler } from 'react'
import { Modal } from '../modal'
import { Event } from '../../entity'
import './event-modal.scss'

const format = ( d: number ): string => {

    const date = new Date( d * 1000 )
    const dayOfTheWeek = [ `vasárnap`, `hétfő`, `kedd`, `szerda`, `csütörtök`, `péntek`, `szombat` ]
    const fullmonth = [ `január`, `február`, `március`, `április`, `május`, `június`, `július`, `augusztus`, `szeptember`, `október`, `november`, `december` ]

    return `${ date.getFullYear() }. ${ fullmonth[ date.getMonth() ] } ${ date.getDate().toString().padStart( 2, `0` ) }., ${ dayOfTheWeek[ date.getDay() ] } ${ date.getHours().toString().padStart( 2, `0` ) }:${ date.getMinutes().toString().padStart( 2, `0` ) }`

}

interface EventModalProps {

    event: Event
    onCloseClick?: MouseEventHandler
    onModalClose?: () => void

}

export const EventModal = ( props: EventModalProps ) => {

    return (

        <Modal onModalClose={ () => props.onModalClose && props.onModalClose() }>

            <div className="event-modal">

                <div className="event-modal__title">

                    Előnézet

                </div>

                <div className="event-modal-content">


                    <img className="event-image" src={ props.event.imageFile === null || props.event.imageFile === undefined ? "/image/dk-logo.svg" : props.event.imageFile.url } alt="Eseményhez tartozó kép" />

                    <div className="content">

                        <h3 className="title-text-h3 title">

                            { props.event.title }

                        </h3>

                        <div className="dates">

                            <div className="dates-icon">

                                <img alt="" src="/image/clock.svg" />

                            </div>

                            <div className="dates-info">

                                <span>{ format( props.event.startDate ) }-tól</span>
                                <span>{ props.event.endDate !== null ? `${ format( props.event.endDate ) }-ig` : `` }</span>

                            </div>

                        </div>

                        <button className="button">

                            <img src="/image/check.svg" />
                            Ott leszek

                        </button>

                    </div>

                </div>

                <footer className="event-modal__footer">

                    <button className="button" onClick={ props.onCloseClick }>

                        OK

                    </button>

                </footer>

            </div>

        </Modal>

    )

}
