import { Expose, Type } from 'class-transformer'
import { IsBoolean, IsDefined, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'
import { ContentAction } from './content-action.entity'
import { PushMessageTarget } from './push-message-target.entity'
import { ImageFile } from './image-file.entity'

export class PushMessage {

    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number 

    @Expose()
    @IsDefined()
    @IsString()
    title!: string 

    @Expose()
    @IsDefined()
    @IsString()
    body!: string 

    @Expose()
    @IsDefined()
    @IsString()
    data!: string 

    @IsOptional()
    topic?: number | null

    @IsOptional()
    eventId?: number | null

    @IsOptional()
    newsId?: number | null

    @Expose()
    @IsDefined()
    @IsNumber()
    created!: number 

    @IsOptional()
    timing!: number | null

    @IsOptional()
    @ValidateNested()
    @Type( () => ImageFile )
    imageFile?: ImageFile | null

    @Expose()
    @IsDefined()
    @IsBoolean()
    immediate!: boolean 

    @IsOptional()
    contentAction?: ContentAction | null

    @IsOptional()
    targets?: PushMessageTarget[] | null
   
}