import { Expose, Type } from 'class-transformer'
import { IsDefined, ValidateNested } from 'class-validator'

export class GetInterestsDTO {

    @Expose()
    @IsDefined()
    @ValidateNested()
    @Type( type => Interest )
    interests!: Interest[]

}

export class Interest {

    tag!: string
    percent!: number
    color!: string

}
