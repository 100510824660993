import { MouseEvent as ReactMouseEvent } from 'react'
import { Menu } from '../menu'
import './sidebar.scss'

let grabbed: boolean = false

const scrollMouseDown = ( event: ReactMouseEvent ) => {
    event.preventDefault()
    grabbed = true
}

window.addEventListener( 'mousemove', ( event: MouseEvent ) => {
    if ( grabbed ) {
        const scrollbar = document.querySelector( `.scroll-bar` ) as HTMLElement
        const menu = document.querySelector( `.menu` ) as HTMLElement
        menu.scrollTop += ( event.movementY * ( menu.scrollHeight / scrollbar.scrollHeight ) )
    }
} )

window.addEventListener( 'mouseup', ( event: MouseEvent ) => {
    grabbed = false
} )

interface SidebarProps { 

    onMobileMenuToggle?: ( open: boolean ) => void

}

export const Sidebar = ( props: SidebarProps ) => {

    const onMobileMenuToggle = ( open: boolean ) => {
        if ( props.onMobileMenuToggle ) props.onMobileMenuToggle( open )
    }

    return (
        <div className="sidebar">
            <Menu onMobileMenuToggle= { open => onMobileMenuToggle( open ) } />
            <div className="scroll-bar">
                <div className="scroll" onMouseDown={ scrollMouseDown } />
            </div>
        </div>
    )
}
