import { LoaderFunctionArgs } from 'react-router-dom'
import { getUser } from '../../action'
import { listAdminActivities } from '../../action'
import { store } from '../../store'

export const adminUserLoader = async ( args: LoaderFunctionArgs ) => {

    const user = await getUser( parseInt( args.params.id! as string ) )
    store.dispatch( { type: `SELECT_USER`, user: user } )
    await listAdminActivities( [ `News`, `NewsCategory` ], parseInt( args.params.id! ) )

}
