import './achievement-group.scss'
import { AchievementGroup as AchievementGroupEntity } from '../../entity'

interface AchievementGroupProps {

    achievementGroup: AchievementGroupEntity
    onClick?: ( achievementGroup: AchievementGroupEntity ) => void

}

export const AchievementGroup = ( props: AchievementGroupProps ) => {

    const onClick = () => {

        if ( props.onClick ) props.onClick( props.achievementGroup )

    }

    return (

        <div className="achievement-group card-div" onClick={ onClick }>

            <div className="achievement-group__image">

                <img alt="" src={ props.achievementGroup.image ? props.achievementGroup.image.url : `` } />

            </div>

            <div className="achievement-group__info">

                <div className="achievement-group__name">

                    { props.achievementGroup.name }

                </div>

                <div className="achievement-group__description">

                    { props.achievementGroup.description }

                </div>

            </div>

        </div>

    )

}