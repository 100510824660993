import { LoaderFunctionArgs } from 'react-router-dom'
import { getEvent, getEventImages, getEventStat } from '../../action/events'
import { getPoliticians, getPoliticianTitles } from '../../action/politician'
import { listTags } from '../../action/tags'

export const eventsDetailLoader = async ( args: LoaderFunctionArgs ) => {

    listTags()
    getPoliticians()
    getPoliticianTitles()
    getEvent( parseInt( args.params.id as string ) )
    getEventImages( parseInt( args.params.id as string ), false )
    getEventImages( parseInt( args.params.id as string ), true )
    getEventStat( parseInt( args.params.id as string ) )

}
