import { Expose, Type } from 'class-transformer'
import { IsDefined, IsNumber, ValidateNested } from 'class-validator'
import { News } from '../../entity'

export class GetAllNewsDTO {

    @Expose()
    @ValidateNested()
    @Type( () => News )
    news!: News[]

    @Expose()
    @IsDefined()
    pagination!: {
        page: number
        from: number
        to: number
        count: number
    }

}

