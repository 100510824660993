import './file.scss'

interface FileProps {

    name: string
    selected?: boolean
    thumbnail?: string
    onClick?: CallableFunction
    onDoubleClick?: CallableFunction

}

export const File = ( props: FileProps ) => {

    const onClick = () => { if ( props.onClick !== undefined ) { props.onClick() } }
    const onDoubleClick = () => { if ( props.onDoubleClick !== undefined ) { props.onDoubleClick() } }

    return (

        <div className={ `file${ props.selected === true ? ` selected` : `` }` } onClick={ onClick } onDoubleClick={ onDoubleClick }>

            <div className="file__icon">

                <img className={ props.thumbnail ? `` : `no-thumbnail` } src={ props.thumbnail } />

            </div>

            <div className="file__name">

                { props.name }

            </div>

        </div>

    )

}
