import './stats.scss'
import { useSelector } from 'react-redux'
import { State } from '../../state'

export const Stats = () => {

    const eventStat = useSelector( ( state: State ) => state.events.eventStat )

    const stats = [
        { name: `Résztvevő`, value: eventStat ? `${ eventStat.joined } fő` : `` },
        { name: `Megnyitotta`, value: eventStat ? `${ eventStat.opened } fő` : `` },
        { name: `Helyszín`, value: eventStat ? eventStat.location : `` },
        { name: `Jóváhagyásra váró kép`, value: eventStat ? `${ eventStat?.toBeApproved } db` : `` },
    ]

    return (

        <div className="event-details-stats">

            <div className="card-div">

                <span>Az eseményhez tartozó statisztikai adatok itt jelennek meg:</span>

                <div className="stats">

                    { stats.map( stat => (

                        <div className="card-div stat">

                            <div className="stat__name">{ stat.name }</div>
                            <div className="stat__value">{ stat.value }</div>

                        </div>

                    ) ) }

                </div>

            </div>

        </div>

    )

}