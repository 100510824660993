import { useDispatch } from 'react-redux'
import { FloatingMessage } from '../../../entity'
import { MenuButton, Modal } from '../../../component'
import { CREATE_FLOATING_MESSAGE_ACTIONS } from '../../../action/floating-messages/floating-messages.constants'
import { deleteFloatingMessage } from '../../../action/floating-messages'
import { useState } from 'react'

interface FloatingMessageTableMenuButtonProps {
    floatingMessageElement: FloatingMessage
}

export const FloatingMessageTableMenuButton = ( props: FloatingMessageTableMenuButtonProps ) => {

    const dispatch = useDispatch()

    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )
    const [ showConfirmModal, setShowConfirmModal ] = useState( false )

    const onDelete = async () => {

        await deleteFunc!()
        setShowConfirmModal( false )

    }

    const onMenuItemChange = async ( itemId: any ) => {
        switch(itemId) {
            case 1:
                dispatch( { type: CREATE_FLOATING_MESSAGE_ACTIONS.CREATE_FLOATING_MESSAGE_MODIFY_FLOATING_MESSAGE, floatingMessage: props.floatingMessageElement } )
                break
            default:
                setDeleteFunc( () => {
                    return async () => {
                        deleteFloatingMessage(props.floatingMessageElement.id)
                    }
                } )
                setShowConfirmModal( true )
                break
        }
    }

    return (
        <>

            <MenuButton
                hasBackground={ true }
                items={ [
                    { id: 1, value: 'Szerkesztés', icon: "edit.svg" },
                    { id: 2, value: 'Törlés', icon: "trash.svg" },
                ] }
                onChange={ onMenuItemChange }
            />

            { showConfirmModal ? (

                <Modal className="floating-message-table-confirm-modal" onModalClose={ () => setShowConfirmModal( false ) }>

                    <div className="modal-title">Biztosan törlöd az üzenetet?</div>
                    <div className="modal-description">Ezt a műveletet nem lehet visszavonni! Biztosan törlöd?</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowConfirmModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ onDelete }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

        </>

    )

}

