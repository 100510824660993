import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetNewsCategoryDTO } from '../../dto/news-category'
import { store } from '../../store'
import { getNewsStats } from '../news/get-news-stats.action'
import { getAllNews } from '../news/get-all-news.action'
import { getNewsCategories } from './get-news-categories.action'

export const createNewsCategory = async () => {

    let body: GetNewsCategoryDTO
    let response: Response

    const token = store.getState().session.token
    const newsCategoryId = store.getState().createNewsCategory.form.id

    store.dispatch( { type: 'CREATE_NEWS_CATEGORY_STARTED' } )

    try {
        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/news-category`+ (newsCategoryId != null ? (`/`+newsCategoryId) : ''), {
            body: JSON.stringify( {
                name: store.getState().createNewsCategory.form.name.value,
                color: store.getState().createNewsCategory.form.color,
            } ),
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            method: newsCategoryId != null ? 'put' : 'post'
        } )

    } catch ( exception ) {
        store.dispatch( { type: 'CREATE_NEWS_CATEGORY_TIMEOUT' } )
        return
    }

    if ( response.status === 401 ) {
        store.dispatch( { type: 'CREATE_NEWS_CATEGORY_UNAUTHORIZED' } )
        return
    }

    if ( ![200, 201].includes(response.status) ) {
        store.dispatch( { type: 'CREATE_NEWS_CATEGORY_UNEXPECTEDRESPONSE' } )
        return
    }

    try {
        body = plainToClass( GetNewsCategoryDTO, JSON.parse( await response.text() ) )
    } catch ( exception ) {
        store.dispatch( { type: 'CREATE_NEWS_CATEGORY_CATEGORY_INVALIDJSON' } )
        return
    }

    if ( ( await validate( body ) ).length > 0 ) {
        store.dispatch( { type: 'CREATE_NEWS_CATEGORY_INVALIDRESPONSE' } )
        return
    }

    store.dispatch( { type: 'CREATE_NEWS_CATEGORY_OK', newsCategory: body.newsCategory } )

    getNewsCategories()

    // a listaelemen és a statisztikán is mejelenik a kategória ezért kell frissíteni
    getAllNews()

    getNewsStats()


}
