import { store } from "../../store"
import { createCall } from "../base"
import { BASE_METHODS } from "../base/base.constants"
import { DELETE_FLOATING_MESSAGE_ACTIONS } from "./floating-messages.constants"
import { getFloatingMessages } from "./get-floating-messages.action"

export const deleteFloatingMessage = async ( messageId: number ) => {

    store.dispatch( { type: DELETE_FLOATING_MESSAGE_ACTIONS.DELETE_FLOATING_MESSAGE_STARTED } )

    const promise = createCall(`floating-message/`+messageId, BASE_METHODS.DELETE)
    promise.then(async response => {

        store.dispatch( { type: DELETE_FLOATING_MESSAGE_ACTIONS.DELETE_FLOATING_MESSAGE_OK, response: response.text() } )

        getFloatingMessages()

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })

}
