import { TextField } from '../field'
import './consolation-prize-card.scss'
import { useState } from 'react'
import { MessageModal } from '../modal'
import {listConsolationPrizes, saveConsolationPrize} from "../../action/gamification";

interface ConsolationPrizeCardProps {

    exp: number
    leagueId: number

}

export const ConsolationPrizeCard = (props: ConsolationPrizeCardProps ) => {

    const [infoModalVisibility, setInfoModalVisibility] = useState(false)
    const [infoModalTitle, setInfoModalTitle] = useState("")
    const [infoModalDescription, setInfoModalDescription] = useState("")
  
    const [ exp, setExp ] = useState( props.exp.toString() || "0" )

    const onSaveConsolationPrize = async () => {

        if (!checkFields()) {
            return
        } 
      
        await saveConsolationPrize( {
            leagueId: props.leagueId,
            exp: parseInt(exp)
        } )
        await listConsolationPrizes()

    }

    const checkFields = (): boolean => {

        if (exp.trim().length === 0) {
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription( "A tapasztalati pont megadása kötelező!");
            setInfoModalVisibility(true);
            return false
        }

        if (isNaN(parseInt(exp)) || parseInt(exp) !== parseFloat(exp) || parseInt(exp) <= 0) {
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription( "A tapasztalati pontnak pozitív egész számnak kell lennie!");
            setInfoModalVisibility(true);
            return false
        }

        return true
    }

    return (

        <div className="consolation-prize-card-div card-div">

            <div className="prize-card-div__title">

                A nem továbbjutók nyereménye

            </div>

            <div className="field">

                <label>A kapott tapaszatalati pont összege *</label>
                <TextField field={{ value: exp, error: `` }} onChange={ e => setExp( e.target.value ) } />

            </div>

            <footer>

                <button className="button button--primary" style={{ minWidth: 182 }} onClick={ onSaveConsolationPrize }>Mentés</button>

            </footer>

            {infoModalVisibility ? (

                <MessageModal
                    title={infoModalTitle}
                    message={infoModalDescription}
                    onCloseClick={() => {
                    setInfoModalVisibility(false);
                    }}
                />

            ) : null}

        </div>

    )

}