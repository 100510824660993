import { store } from '../../store'
import { getFolder } from './get-folder'

export const createFolder = async ( id: number = 1, name: string ) => {

    let response: Response

    try {
        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/media-library/${ id }/create-folder/${ name }`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: `POST`
        } )

    } catch ( exception ) {
        store.dispatch( { type: 'MEDIA_LIBRARY_CREATE_FOLDER_TIMEOUT' } )
        return
    }

    if ( response.status === 401 ) {
        store.dispatch( { type: 'MEDIA_LIBRARY_CREATE_FOLDER_UNAUTHORIZED' } )
        return
    }

    if ( response.status !== 200 ) {
        store.dispatch( { type: 'MEDIA_LIBRARY_CREATE_FOLDER_UNEXPECTEDRESPONSE' } )
        return
    }

    store.dispatch( { type: 'MEDIA_LIBRARY_CREATE_FOLDER_OK' } )
    getFolder( id )

}
