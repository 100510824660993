import { store } from '../../store'

export const saveVideo = async ( video: any ) => {

    let response

    try {

        if ( !video.id ) {
            video.question.id = null
            video.question.answers.forEach( ( a: any ) => a.id = null )
        }

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/2.0.0/cms/videos`, {
            body: JSON.stringify( video ),
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'POST'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'SAVE_VIDEO_TIMEOUT' } )
        return null

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'SAVE_VIDEO_UNAUTHORIZED' } )
        return null

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'SAVE_VIDEO_UNEXPECTEDRESPONSE' } )
        return null

    }

    store.dispatch( { type: 'SAVE_VIDEO_OK' } )
    return ( await response.json() ).video

}