import { Expose } from 'class-transformer'
import { IsDefined } from 'class-validator'
import { Quiz } from '../../entity'

export class SaveQuizDTO {

    @Expose()
    @IsDefined()
    quiz!: Quiz

}

