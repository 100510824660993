import { store } from '../../store'

export const checkResetPassword = async ( token: string ) => {

    let response: Response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/users/reset-password/${ token }`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'get'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'CHECK_RESET_PASSWORD_TIMEOUT' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'CHECK_RESET_PASSWORD_UNEXPECTEDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'CHECK_RESET_PASSWORD_OK' } )

}
