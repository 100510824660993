import { Expose, Type } from 'class-transformer'
import { ValidateNested } from 'class-validator'
import { QuestionnaireTag } from '../../entity'

export class GetQuestionnaireTagDTO {

    @Expose()
    @ValidateNested()
    @Type( () => QuestionnaireTag )
    questionnaireTag!: QuestionnaireTag

}