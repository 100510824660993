import { useSelector } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import { State } from '../../state'
import { QuestionnaireCard } from './component/questionnaire-card'
import './questionnaires.page.scss'

interface QuestionnairesProps { }

export const Questionnaires = ( props: QuestionnairesProps ) => {

    const { lastQuestionnaire, mostFillingQuestionnaire, lastFillingQuestionnaire, questionnaireCount, fillingCount } = useSelector( ( state: State ) => state.questionnaireStats )

    return (
        <main className="page page-questionnaires">
            <div className="main-div">
               
                <div className="navigation-title">
                    <h1 className="title-text-h1">Kérdőívek áttekintése</h1>
                </div>

                <div className="container main-container">
                    <div className="card-div">

                        <div className="sub-container">
                            <h2 className="title-text-h2">Legutóbbi kérdőív</h2>
                            <h3 className="title-text-h3 title-text-h3-margin">Ezt a kérdőívet hozták létre legutóbb</h3>

                            { lastQuestionnaire == null ?  
                                <QuestionnaireCard questionnaireElement={ lastQuestionnaire } />
                                :
                                <NavLink to={ `/kerdoivek/${ lastQuestionnaire?.id }` }>
                                    <QuestionnaireCard questionnaireElement={ lastQuestionnaire } />
                                </NavLink>
                            }
                        </div>

                        <div className="separator"></div>

                        <div className="sub-container">
                            <h2 className="title-text-h2">Legtöbb kitöltés</h2>
                            <h3 className="title-text-h3 title-text-h3-margin">Ezt a kérdőívet töltötték ki a legtöbben</h3>

                            { mostFillingQuestionnaire == null ?  
                                <QuestionnaireCard questionnaireElement={ mostFillingQuestionnaire } />
                                :
                                <NavLink to={ `/kerdoivek/${ mostFillingQuestionnaire?.id }` }>
                                    <QuestionnaireCard questionnaireElement={ mostFillingQuestionnaire } />
                                </NavLink>
                            }
                        </div>

                        <div className="separator"></div>

                        <div className="sub-container">
                            <h2 className="title-text-h2">Legkevesebb kitöltés</h2>
                            <h3 className="title-text-h3 title-text-h3-margin" >Ezt a kérdőívet töltötték ki a legkevesebben</h3>

                            { lastFillingQuestionnaire == null ?  
                                <QuestionnaireCard questionnaireElement={ lastFillingQuestionnaire } />
                                :
                                <NavLink to={ `/kerdoivek/${ lastFillingQuestionnaire?.id }` }>
                                    <QuestionnaireCard questionnaireElement={ lastFillingQuestionnaire } />
                                </NavLink>
                            }
                        </div>

                    </div>
                </div>

                <Link to="lista" className="nav_decoration" style= {{textDecoration:'none'}} >
                    <div className="container news_category_container">
                        <div className="card-div card_container">

                            <img className="image" alt="Hírek áttekintése országos hírek logója" src="/image/hungary.svg"/>

                            <div className="sub-container text_container">
                                <h2 className="title-text-h2">Országos kérdőívek</h2>
                                <span className="label-text description">{questionnaireCount} aktív kérdőív • {fillingCount} kitöltés</span>
                            </div>

                        </div>
                    </div>
                </Link>
              
            </div>
        </main>
    )
}



