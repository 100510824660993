import { MouseEvent, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CREATE_QUESTIONNAIRE_ACTIONS } from "../../../../action/questionnaires/questionnaires.constants";
import { QuestionnaireQuestion } from "../../../../entity";
import { QuestionnaireQuestionElement } from "./questionnaire-question-element";
import "./questionnaire-question-table.scss";
import { State } from "../../../../state";

interface QuestionnaireQuestionTableProps {
  questionArray: QuestionnaireQuestion[];
  disabled?: boolean;
}

export const QuestionnaireQuestionTable = (props: QuestionnaireQuestionTableProps) => {
  
  const dispatch = useDispatch();
  const functionRan = useRef(false);  
  
  const questionnaireId = useSelector((state: State) => state.createQuestionnaire.form.id);

  useEffect(() => {
    if (functionRan.current === false && questionnaireId == null) {
      dispatch({
        type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_ADD_QUESTION,
      });
    }
    return () => {
      functionRan.current = true;
    };
  }, [functionRan, dispatch]);

  const onAddQuestionItem = async (event: MouseEvent) => {
    event.preventDefault();
    dispatch({
      type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_ADD_QUESTION,
    });
  };

  const onDragStart = (position: number) => {
    dragItemPosition = position;
  };

  const onDragEnter = (position: number) => {
    dragOverItemPosition = position;
  };

  var dragItemPosition = -1;
  var dragOverItemPosition = -1;

  const drop = (e: any) => {
    const copyListItems = [...props.questionArray!];

    const dragItemContent = copyListItems[dragItemPosition];

    copyListItems.splice(dragItemPosition, 1);
    copyListItems.splice(dragOverItemPosition, 0, dragItemContent);

    dragItemPosition = -1;
    dragOverItemPosition = -1;

    dispatch({
      type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_ORDER_QUESTION,
      questions: copyListItems,
    });
  };

  const questionElements: JSX.Element[] = [];
  props.questionArray.map(
    (questionElement: QuestionnaireQuestion, index: number) => {
      questionElements.push(
        <QuestionnaireQuestionElement
          questionElement={questionElement}
          positon={index}
          onDragEnter={onDragEnter}
          onDragStart={onDragStart}
          onDragEnd={drop}
          disabled={props.disabled === true}
          deleteButtonDisabled={props.questionArray.length == 1}
        />
      );
    }
  );

  return (
    <div className="questionnaire-question-table-container ">
      {questionElements.length != 0 ? (
        <h3 className="label-text dotted-label">
          - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
          - - - - - - - - - - - - - - - - - - - - - - - - - Kérdőív kérdések - -
          - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
          - - - - - - - - - - - - - - - - - - - - - - -
        </h3>
      ) : null}


      <ul
        id={"questionnaire_question_table"}
        className="questionnaire-question-table"
      >
        {questionElements.length != 0 ? questionElements : null}
      </ul>

      <div
        className={`add-questionnaire-button${
          props.disabled === true ? ` disabled` : ``
        }`}
        onClick={onAddQuestionItem}
      >
        <img
          className="add-questionnaire-button--icon"
          alt={"Hozzáadás ikon"}
          src={`/image/add.svg`}
        />

        <h3 className="add-questionnaire-button--text">
          Új kérdés hozzáadása{" "}
        </h3>
      </div>
    </div>
  );
};
