import { ChangeEvent, MouseEvent, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { State } from '../../state'
import { PoliticianQuestion } from '../../entity'
import './politician-questions.page.scss'
import { SearchInput } from '../../component/search'
import { PoliticianQuestionElement } from '../../component/politicians/politician-question-element'
import { InputModal } from '../../component/modal'
import { createPoliticianQuestion } from '../../action/politician/create-politician-question.action'
import { getPoliticianQuestions } from '../../action/politician'
import { CREATE_POLITICIAN_QUESTION_ACTIONS, POLITICIANS_ACTIONS } from '../../action/politician/politicians.constants'

interface PoliticianQuestionsProps { }

export const PoliticianQuestions = ( props: PoliticianQuestionsProps ) => {

    const dispatch = useDispatch()

    const questions = useSelector( ( state: State ) => state.politicians.questions )
    const question = useSelector( ( state: State ) => state.createPoliticianQuestion.form.question )

    const [ questionModalVisibility, setQuestionModalVisibility ] = useState( false )

    const onAddQuestionButtonClick = async ( event: MouseEvent ) => {
        setQuestionModalVisibility(true)
        dispatch( { type: CREATE_POLITICIAN_QUESTION_ACTIONS.CREATE_POLITICIAN_QUESTION_CHANGE_QUESTION, question: "" } )
    }

    const onAddQuestionCloseButtonClick = async ( event: MouseEvent ) => {
        event.preventDefault()
        setQuestionModalVisibility( false )
    }

    const onQuestionChange = ( event: ChangeEvent<HTMLTextAreaElement> ) => {
        event.preventDefault()
        dispatch( { type: CREATE_POLITICIAN_QUESTION_ACTIONS.CREATE_POLITICIAN_QUESTION_CHANGE_QUESTION, question: event.target.value } )
    }

    const onAddQuestionSaveButtonClick = () => {
        createPoliticianQuestion()
        setQuestionModalVisibility( false )

        getPoliticianQuestions()
    }

    const onSearchChange = async ( event: ChangeEvent<HTMLInputElement> ) => {
        event.preventDefault()

        dispatch( { type: POLITICIANS_ACTIONS.GET_POLITICIAN_QUESTION_SEARCH , search: event.target.value} )

        await getPoliticianQuestions()
    }

    var dragItemPosition = -1
    var dragOverItemPosition = -1

    const onDragStart = ( position: number ) => {
        dragItemPosition = position
    }

    const onDragEnter = ( position: number ) => {
        dragOverItemPosition = position
    }

    const drop = ( e: any) => {
        const copyListItems = [...questions!]

        const dragItemContent = copyListItems[dragItemPosition]

        copyListItems.splice(dragItemPosition, 1)
        copyListItems.splice(dragOverItemPosition, 0, dragItemContent)

        dragItemPosition = -1
        dragOverItemPosition = -1

        dispatch( { type: POLITICIANS_ACTIONS.GET_POLITICIAN_QUESTIONS_OK, questions: copyListItems } )
    };

    const politicianElements: JSX.Element[] = []
    if ( questions !== null && questions !== undefined) {
        questions.map(( questionElement: PoliticianQuestion, index: number ) => {
            politicianElements.push( <PoliticianQuestionElement questionElement={ questionElement } 
                onModifyClick={ onAddQuestionButtonClick } 
                positon = {index} 
                onDragEnter= {onDragEnter} 
                onDragStart= {onDragStart}   
                onDragEnd= {drop}     
            /> )
        })
    }

    return (
        <main className="page page-politician-questions">

            <div className="main-div">

                <div className="navigation-title">
                    <NavLink to="/politikusok">
                        <h1 className="title-text-h1">Politikusok { '>' }</h1>
                    </NavLink>
                    <h1 className="title-text-h1">Politikus kérdések</h1>
                </div>

                <div className="toolbar">
                    <SearchInput onChange={ onSearchChange } placeholder="Keresés a kérdések között" />
                    <button className="button" onClick={ onAddQuestionButtonClick }>+ Új kérdés</button>
                </div>

                <div className="container">
                    <div className="card-div">

                        <div className="politician-question-container">
                            <ul id={ "related_politician_table" } className="politician-question-list">
                                { politicianElements.length != 0 ? politicianElements : "" }
                            </ul>
                        </div>

                    </div>
                </div>

            </div>

            {
                questionModalVisibility ?
                    <InputModal title={ "Új kérdés hozzáadása (max 120 karakter)" } field={ question } placeholder={ "" } onInputChange={ onQuestionChange } onSaveClick={ onAddQuestionSaveButtonClick } onCloseClick={ onAddQuestionCloseButtonClick } onModalClose={ () => setQuestionModalVisibility( false ) } />
                    : ""
            }

        </main>
    )

}
