import { MouseEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteNewsCategory } from '../../../action/news-category'
import { NewsCategory } from '../../../entity'
import { State } from '../../../state'
import { MessageModal } from '../../modal'
import './news-category-element.scss'

interface  NewsCategoryElementProps {
    newsCategoryElement: NewsCategory
}

export const NewsCategoryElement = ( props: NewsCategoryElementProps ) => {

    const dispatch = useDispatch()
    const selectedCategoryId = useSelector( ( state: State ) => state.createNewsCategory.form.id )
    const [ deleteModalVisibility, setDeleteModalVisibility ] = useState( false )

    const onDeleteButtonClick = async ( event: MouseEvent ) => {
        event.preventDefault()
        setDeleteModalVisibility( true ) 
    }

    const onItemSelectClick = async ( event: MouseEvent ) => {
        event.preventDefault()
        dispatch ( { type: 'CREATE_NEWS_CATEGORY_MODIFY_NEWS', newsCategory: props.newsCategoryElement } )
    }

    return (
        <li key={ props.newsCategoryElement.name } className={ `news-table-list__news-category-element ${ selectedCategoryId == props.newsCategoryElement.id ? `active` : `` }` } onClick={ onItemSelectClick }>
            <span className="label-text name"> { props.newsCategoryElement.name }</span>
            <span className="color" style={{ background: props.newsCategoryElement.color }} ></span>
            <img className="edit" onClick={ onDeleteButtonClick } alt={ "Kategória törlés ikon" } src="/image/trash.svg " />

            {
                deleteModalVisibility ?
                <MessageModal title={ "Figyelmeztetés" } message={ "Biztosan törölni szeretnéd a kategóriát?" }  
                        onOkClick={ async () => {   
                            await deleteNewsCategory( props.newsCategoryElement.id )
                            dispatch ( { type: 'CREATE_NEWS_CATEGORY_CLEAR'} ) 
                            setDeleteModalVisibility( false )
                        } }   
                        onCloseClick={ () => { setDeleteModalVisibility( false ) } } />
                : null
            }
        </li >
    )

}
