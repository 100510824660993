import './test-result-selector.scss'
import { TestResult as TestResultEntity } from '../../entity'
import { Modal } from '../modal'
import { useSelector } from 'react-redux'
import { State } from '../../state'
import { useState } from 'react'
import { SearchButton, SearchInput } from '../search'
import { TestResult } from '../test-result/test-result'

interface TestResultSelectorProps {

    compact?: boolean
    results: TestResultEntity[]
    title?: string

    onResultAdd?: ( tag: TestResultEntity ) => void
    onResultDelete?: ( tag: TestResultEntity ) => void

}

export const TestResultSelector = ( props: TestResultSelectorProps ) => {

    const onDelete = ( result: TestResultEntity ) => { if ( props.onResultDelete ) props.onResultDelete( result ) }

    const onResultClick = ( result: TestResultEntity ) => {

        if ( props.results.find( t => result.id === t.id ) === undefined ) {

            if ( props.onResultAdd ) props.onResultAdd( result )

        } else {

            if ( props.onResultDelete ) props.onResultDelete( result )

        }

    }

    const { quiz } = useSelector( ( state: State ) => state.quizes )

    const [ showModal, setShowModal ] = useState( false )
    const [ keyword, setKeyword ] = useState( `` )

    return (

        <div className={ `test-result-selector${ props.compact === true ? ` test-result-selector--compact` : `` }` }>

            <header>

                <button className="button button--ghost" onClick={ e => { e.preventDefault(); setShowModal( true ) } }>Új teszt eredmény hozzáadása</button>

            </header>

            <main>

                { props.results.map( result => (

                    <div key={ result.id } className="result">

                        <span>{ result.name }</span>

                    </div>

                ) ) }

            </main>

            { showModal ? (

                <Modal className="modal--test-result-selector" onModalClose={ () => setShowModal( false ) }>

                    <div className="modal-title">Teszt eredmények</div>

                    <img alt="" src="/image/close.svg" className="modal-close" onClick={ () => setShowModal( false ) } />

                    <div className="test-result-selector-modal-header">

                        <SearchInput onChange={ e => setKeyword( e.target.value ) } />
                        <SearchButton onClick={ e => e.preventDefault() } />

                    </div>

                    <main>

                        { quiz?.possibleResults!.filter( result => result.name.indexOf( keyword ) !== -1 ).map( result => (

                            <TestResult key={ result.id } { ...result } onClick={ () => onResultClick( result ) } selected={ props.results.find( r => r.id === result.id ) !== undefined } />

                        ) ) }

                    </main>

                </Modal>

            ) : null }

        </div>

    )

}