import { store } from '../../store'
import { plainToClass } from 'class-transformer'
import { ListCourseTopicsDTO } from '../../dto/courses'
import { validate } from 'class-validator'

export const listCourseTopics = async () => {

    let body, response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/courses/topics`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'GET'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'LIST_COURSE_TOPICS_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'LIST_COURSE_TOPICS_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'LIST_COURSE_TOPICS_UNEXPECTEDRESPONSE' } )
        return

    }

    try {

        body = plainToClass( ListCourseTopicsDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'LIST_COURSE_TOPICS_INVALIDJSON' } )
        return

    }

    if ( ( await validate( body ) ).length > 0 ) {

        store.dispatch( { type: 'LIST_COURSE_TOPICS_INVALIDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'LIST_COURSE_TOPICS_OK', courseTopics: body.courseTopics } )

}