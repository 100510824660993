import { Expose, Type } from 'class-transformer'
import { ValidateNested } from 'class-validator'
import {  NewsCategory } from '../../entity'

export class GetNewsCategoryDTO {

    @Expose()
    @ValidateNested()
    @Type( () => NewsCategory )
    newsCategory!: NewsCategory
    
}
