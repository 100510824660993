import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { saveQuote } from "../../../action/quotes";
import { Dropdown, QuotePartiesModal } from "../../../component";
import { TextField } from "../../../component/field";
import { ImageEditor } from "../../../component/image-editor/image-editor";
import { MediaLibrary } from "../../../component/media-library";
import { SearchInput } from "../../../component/search";
import { MediaLibraryFile, Tag } from '../../../entity'
import { State } from "../../../state";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./quote.page.scss";
import { createFormattedDateTimeString } from "../../../helper";
import { TagSelector } from '../../../component/tag-selector'
import { MessageModal } from "../../../component/modal";
import { AddImage } from '../../../component/add-image'

export const QuotePage = () => {

  const [infoModalVisibility, setInfoModalVisibility] = useState(false);
  const [infoModalTitle, setInfoModalTitle] = useState("");
  const [infoModalDescription, setInfoModalDescription] = useState("");

  const [selectedTab, setSelectedTab] = useState(`dk`);
  const [selectedQuotePolitician, setSelectedQuotePolitician] = useState(
    null as number | null
  );
  const [selectedParty, setSelectedParty] = useState(null as number | null);
  const [filter, setFilter] = useState(``);
  const [image, setImage] = useState(null as MediaLibraryFile | null);
  const [mediaLibraryFile, setMediaLibraryFile] = useState(
    null as MediaLibraryFile | null
  );
  const [showQuotePartiesModal, setShowQuotePartiesModal] = useState(false);
  const [ tags, setTags ] = useState( [] as Tag[] )
  const [ leftReactionTags, setLeftReactionTags ] = useState( [] as Tag[] )
  const [ rightReactionTags, setRightReactionTags ] = useState( [] as Tag[] )
  const [showMediaLibrary, setShowMediaLibrary] = useState(false);
  const [showImageEditor, setShowImageEditor] = useState(false);
  const { quoteParties, quotePoliticians, defaultReaction } = useSelector(
    (state: State) => state.quotes
  );

  const [ reactionFile, setReactionFile ] = useState( null as MediaLibraryFile | null )
  const [ selectedDirection, setSelectedDirection ] = useState( null as string | null )
  const [ leftReactionImage, setLeftReactionImage ] = useState( defaultReaction.left as MediaLibraryFile | null )
  const [ rightReactionImage, setRightReactionImage ] = useState( defaultReaction.right as MediaLibraryFile | null )

  const [form, setForm] = useState({
    politicianName: { error: ``, value: `` },
    quote: { error: ``, value: ``},
    quoteWhen: { error: ``, value: new Date().getTime() },
    quoteWhere: { error: ``, value: ``},
    source: { error: ``, value: `` },
  });
  const [status, setStatus] = useState(0);
  const [tag, setTag] = useState({ error: ``, value: `` });

  const navigate = useNavigate();

  const onAddImage = ( direction: string ) => {
      setSelectedDirection( direction )
  }

  const onDeleteImage= ( direction: string ) => {
      if ( direction === `left` ) {
          setLeftReactionImage( null )
      } else {
          setRightReactionImage( null )
      }
  }

  const setField = (name: string, value: string | Date) => {
    (form as any)[name].value = value;
    setForm({ ...form });
  };

  const save = async () => {

    if (!checkFields()) {
      return;
    } 

    let quote: any = {
      quote: form.quote.value,
      quoteWhen: createFormattedDateTimeString(form.quoteWhen.value / 1000),
      quoteWhere: form.quoteWhere.value,
      source: form.source.value,
      status: status,
      tags: tags,
      leftReactionTags: leftReactionTags,
      rightReactionTags: rightReactionTags,
      leftReactionImageId: leftReactionImage?.id || null,
      rightReactionImageId: rightReactionImage?.id || null
    };

    if (selectedTab === `dk`) {
      quote = { ...quote, quotePoliticianId: selectedQuotePolitician };
    } else {
      quote = {
        ...quote,
        quotePolitician: {
          name: form.politicianName.value,
          image: image!.id,
          party: selectedParty,
        },
      };
    }

    await saveQuote(quote);
    
    navigate(`/velemenyvezerek`);
  };

  const checkFields = (): boolean => {
    if (form.quote.value == "") {        
      setInfoModalTitle("Figyelmeztetés");
      setInfoModalDescription( "Az idézet megadása kötelező!");
      setInfoModalVisibility(true);
      return false
    }

    if (form.quoteWhere.value == "") {        
      setInfoModalTitle("Figyelmeztetés");
      setInfoModalDescription( "A helyszín megadása kötelező!");
      setInfoModalVisibility(true);
      return false
    }

    if (form.source.value == "") {        
      setInfoModalTitle("Figyelmeztetés");
      setInfoModalDescription( "A forrás megadása kötelező!");
      setInfoModalVisibility(true);
      return false
    }

    return true
  }

  return (
    <main className="page page--quote">
      <div className="main-div">
        <div className="navigation-title">
          <NavLink to="/velemenyvezerek">
            <h1 className="title-text-h1">Véleményvezérek &gt;</h1>
          </NavLink>
          <h1 className="title-text-h1">Új idézet hozzáadása</h1>
        </div>

        <div className="toolbar">
          <NavLink to="/velemenyvezerek" style={{ marginRight: `auto` }}>
            <button className="button button--ghost" style={{ minWidth: 173 }}>
              Vissza
            </button>
          </NavLink>

          <button
            className="button button--ghost"
            style={{ minWidth: 239 }}
            onClick={(e) => setShowQuotePartiesModal(true)}
          >
            Politikai pártok
          </button>

          <button
            className="button"
            style={{ minWidth: 182 }}
            onClick={(e) => save()}
          >
            Mentés
          </button>
        </div>

        <div className="columns">
          <div className="card-div">
            <h2 className="title-text-h2">Poitikus meghatározása</h2>

            <div className="tabs">
              <div className="tabs__tab-bar">
                <div
                  className={`tabs__tab-bar__tab${
                    selectedTab === "dk" ? ` tabs__tab-bar__tab--active` : ``
                  }`}
                  onClick={(e) => setSelectedTab("dk")}
                >
                  DK politikusai
                </div>
                <div
                  className={`tabs__tab-bar__tab${
                    selectedTab === "other" ? ` tabs__tab-bar__tab--active` : ``
                  }`}
                  onClick={(e) => setSelectedTab("other")}
                >
                  Más párt politikusai
                </div>
              </div>

              {selectedTab === "dk" ? (
                <div className="tabs__tab">
                  <header>
                    <div className="lineBreak">
                      <div className="caption">Politikus neve</div>
                      <SearchInput
                        placeholder="Kezdj el gépelni!"
                        onChange={(e) => setFilter(e.target.value)}
                      />
                    </div>
                  </header>

                  <div className="quote-politicians">
                    {quotePoliticians
                      .filter(
                        (quotePolitician) =>
                          quotePolitician.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
                      )
                      .slice(0, 10)
                      .map((quotePolitician) => (
                        <div
                          className={`quote-politician${
                            selectedQuotePolitician === quotePolitician.id
                              ? ` quote-politician--selected`
                              : ``
                          }`}
                          onClick={(e) =>
                            setSelectedQuotePolitician(quotePolitician.id)
                          }
                        >
                          {quotePolitician.name}
                        </div>
                      ))}
                  </div>
                </div>
              ) : null}

              {selectedTab === "other" ? (
                <div className="tabs__tab">
                  <div className="input-field input-field--strong">
                    <label htmlFor="politician-name" className="caption">
                      Politikus neve
                    </label>
                    <TextField
                      field={form.politicianName}
                      onChange={(e) =>
                        setField(`politicianName`, e.target.value)
                      }
                    />
                  </div>

                  <div
                    className="image-selector"
                    onClick={(e) => setShowMediaLibrary(true)}
                  >
                    {image === null ? (
                      <>
                        <div className="image-selector__icon">
                          <img src="/image/add-white.svg" />
                        </div>

                        <div className="image-selector__caption">
                          Új politikus
                          <br />
                          hozzáadása
                          <br />
                          2:3
                        </div>
                      </>
                    ) : (
                      <img className="image-selector__image" src={image.url} />
                    )}
                  </div>

                  <div className="quote-parties">
                    <div className="caption">Politikus Pártja</div>

                    {quoteParties.map((quoteParty) => (
                      <div
                        className={`quote-party${
                          selectedParty === quoteParty.id
                            ? ` quote-party--selected`
                            : ``
                        }`}
                        onClick={(e) => setSelectedParty(quoteParty.id)}
                      >
                        {quoteParty.name}
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="separator"></div>

          <div className="card-div">
            <h2 className="title-text-h2" style={{ marginBottom: 20 }}>
              Idézet meghatározása
            </h2>

            <div className="input-field input-field--strong">
              <label className="caption">Idézet *</label>
              <div className="info">
                {window.innerWidth <= 865 && <br />}
                Ajánlott karakter szám maximum: 120 karakter
              </div>

              <TextField
                field={form.quote}
                onChange={(e) => setField(`quote`, e.target.value)}
                maxlength={255}
              />
            </div>

            <div className="input-field">
              <label className="caption">Idézet időpontja *</label>
              <DatePicker
                selected={new Date(form.quoteWhen.value)}
                onChange={(date) => setField(`quoteWhen`, date!)}
                showTimeSelect
                timeIntervals={1}
                timeFormat="HH:mm"
                dateFormat="yyyy. MM. dd. HH:mm"
              />
            </div>

            <div className="input-field">
              <label className="caption">Helyszín *</label>
              <TextField
                field={form.quoteWhere}
                onChange={(e) => setField(`quoteWhere`, e.target.value)}
                maxlength={255}
              />
            </div>

            <div className="input-field">
              <label className="caption">Forrás megjelölés *</label>
              <TextField
                field={form.source}
                onChange={(e) => setField(`source`, e.target.value)}
                maxlength={255}
              />
            </div>

            <div className="input-field">
              <label className="caption">Idézet státusza</label>
              <Dropdown
                compact={false}
                items={[
                  { id: 0, value: `Aktív` },
                  { id: 1, value: `Csak ütemezett` },
                  { id: 2, value: `Nem aktív` },
                ]}
                value={status}
                onChange={(id: number) => setStatus(id)}
              />
            </div>

            <div className="card-div tag-selector-div">

              <TagSelector title="Címkék" tags={ tags } onTagAdd={ tag => setTags( [ ...tags, tag ] ) } onTagDelete={ tag => setTags( tags.filter( t => t.id !== tag.id ) ) } />

            </div>

              { selectedTab === `other` ? (

                  <div className="reactions">

                      <label className="caption">Reakciók</label>
                      <div className="reactions-container">

                          <div className="reaction">

                              <AddImage imageUrl={ leftReactionImage ? leftReactionImage.url! : `` } text="Balra húzás" width="128px" height="128px" onAddImage={ () => onAddImage( `left` ) } onDeleteImage={ () => onDeleteImage( `left` ) } />

                          </div>

                          <div className="reaction">

                              <AddImage imageUrl={ rightReactionImage ? rightReactionImage.url! : `` } text="Jobbra húzás" width="128px" height="128px" onAddImage={ () => onAddImage( `right` ) } onDeleteImage={ () => onDeleteImage( `right` ) } />

                          </div>

                      </div>

                  </div>

              ) : null }

            <div className="input-field">

                <label className="caption">Balra húzás címkéi</label>
                <TagSelector compact={ true } tags={ leftReactionTags } onTagAdd={ tag => setLeftReactionTags( [ ...leftReactionTags, tag ] ) } onTagDelete={ tag => setLeftReactionTags( leftReactionTags.filter( t => t.id !== tag.id ) ) } />

            </div>

              <div className="input-field">

                  <label className="caption">Jobbra húzás címkéi</label>
                  <TagSelector compact={ true } tags={ rightReactionTags } onTagAdd={ tag => setRightReactionTags( [ ...rightReactionTags, tag ] ) } onTagDelete={ tag => setRightReactionTags( rightReactionTags.filter( t => t.id !== tag.id ) ) } />

              </div>

          </div>
        </div>
        <div className="toolbar-bottom">
            <button
              className="button"
              style={{ minWidth: 182 }}
              onClick={(e) => save()}
            >
              Mentés
            </button>
        </div>
      </div>

      {showMediaLibrary ? (
        <MediaLibrary
          onClose={() => setShowMediaLibrary(false)}
          onMediaSelected={(file: MediaLibraryFile) => {
            setMediaLibraryFile(file);
            setShowMediaLibrary(false);
            setShowImageEditor(true);
          }}
        />
      ) : null}
      {showImageEditor ? (
        <ImageEditor
          aspectRatio={{ name: `Politikus`, ratio: 2 / 3 }}
          mediaLibraryFile={mediaLibraryFile!}
          onClose={() => setShowImageEditor(false)}
          onReady={(file) => {
            setImage(file);
            setShowImageEditor(false);
          }}
        />
      ) : null}

      {showQuotePartiesModal ? (
        <QuotePartiesModal
          onModalClose={() => setShowQuotePartiesModal(false)}
        />
      ) : null}

      {infoModalVisibility ? (
                <MessageModal
                    title={infoModalTitle}
                    message={infoModalDescription}
                    onCloseClick={() => {
                        setInfoModalVisibility(false);
                    }}
                />
            ) : null}

        { selectedDirection !== null && reactionFile === null ? (

            <MediaLibrary
                onClose={ () => setSelectedDirection( null ) }
                onMediaSelected={ ( file: MediaLibraryFile ) => {
                    setReactionFile( file )
                } }
            />

        ) : null }

        { reactionFile !== null ? (

            <ImageEditor
                aspectRatio={ { name: `Reakció`, ratio: 1 } }
                mediaLibraryFile={ reactionFile }
                onClose={ () => setReactionFile( null ) }
                onReady={ (file) => {
                    if ( selectedDirection === `left` ) {
                        setLeftReactionImage( file )
                    } else {
                        setRightReactionImage( file )
                    }
                    setReactionFile( null )
                    setSelectedDirection( null )
                } }
            />

        ) : null }

    </main>
  );
};
