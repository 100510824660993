import { QuestionnaireQuestionAnswer } from '../../../../entity'
import './questionnaire-user-answer-answer-element.scss'

interface QuestionnaireUserAnswerAnswerElementProps {
    position: number
    answerElement: QuestionnaireQuestionAnswer
}

export const QuestionnaireUserAnswerAnswerElement = ( props: QuestionnaireUserAnswerAnswerElementProps ) => {

    return (
        <li key={ props.answerElement.id+props.position} className="questionnaire-answer-answer-table__questionnaire-answer-answer-element" >

            <div className="text-section-container">

                <h3 className="title-text-h3">{props.position}. {props.answerElement.answer}</h3>

                <div className="percent-text-div">
                    <div className="percent-div">
                        <div className="percent" style={{width: `${(100 * props.answerElement.answerCount / props.answerElement.allAnswerCount!).toFixed(0)}%` }}/>
                    </div>

                    <h3 className="title-text-h3 text">{props.answerElement.answerCount } - { (100 * props.answerElement.answerCount /  props.answerElement.allAnswerCount!).toFixed(0)} %</h3>
                </div>

            </div>
        
        </li >
    )

}
