import { store } from "../../store"
import { createCall } from "../base"
import { BASE_METHODS } from "../base/base.constants"
import { getNewsCategories } from "./get-news-categories.action"
import { DELETE_NEWS_CATEGORY_ACTIONS, GET_NEWS_CATEGORIES_ACTIONS } from "./news-category.constants"

export const deleteNewsCategory = async (id: number) => {

    store.dispatch( { type: DELETE_NEWS_CATEGORY_ACTIONS.DELETE_NEWS_CATEGORY_STARTED } )

    const promise = createCall(`news-category/`+id, BASE_METHODS.DELETE)
    promise.then(async response => {

        //Ha hiba jön a szerverről
        if ( response.status ===  400 ) {
            //olyan kategóriát akar törölni amihez hírek tartoznak
            store.dispatch( { type: GET_NEWS_CATEGORIES_ACTIONS.GET_NEWS_CATEGORIES_NEWS_NOT_EMPTY } )
            return
        }

        store.dispatch( { type: DELETE_NEWS_CATEGORY_ACTIONS.DELETE_NEWS_CATEGORY_OK, response: response.text() } )

        getNewsCategories()

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })

}
