import { store } from "../../store"
import { createCall } from "../base"
import { BASE_METHODS } from "../base/base.constants"
import { getNewsStats } from "./get-news-stats.action"
import { getAllNews } from "./get-all-news.action"
import { DELETE_NEWS_ACTIONS } from "./news.constants"

export const deleteNews = async (newsId:number) => {

    store.dispatch( { type: DELETE_NEWS_ACTIONS.DELETE_NEWS_STARTED } )

    const promise = createCall(`news/`+newsId, BASE_METHODS.DELETE)
    promise.then(async response => {

        store.dispatch( { type: DELETE_NEWS_ACTIONS.DELETE_NEWS_OK, response: response.text() } )

        getAllNews()

        getNewsStats()

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })

}
