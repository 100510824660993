import { Expose } from 'class-transformer'
import { IsDefined, IsNumber, IsString } from 'class-validator'

export class GetEventStatDTO {

    @Expose()
    @IsDefined()
    @IsString()
    location!: string

    @Expose()
    @IsDefined()
    @IsString()
    joined!: string

    @Expose()
    @IsDefined()
    @IsString()
    opened!: string

    @Expose()
    @IsDefined()
    @IsString()
    toBeApproved!: string

}

