import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetMediaLibraryFolderDTO } from '../../dto'
import { store } from '../../store'

export const getFolder = async ( id: number = 1, orderBy: string = `name`, orderDirection: `ASC` | `DESC` = `ASC` ) => {

    let body: GetMediaLibraryFolderDTO
    let response: Response

    try {
        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/media-library/${ id }?orderBy=${ orderBy }&orderDirection=${ orderDirection }`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            }
        } )

    } catch ( exception ) {
        store.dispatch( { type: 'MEDIA_LIBRARY_GET_FOLDER_TIMEOUT' } )
        return
    }

    if ( response.status === 401 ) {
        store.dispatch( { type: 'MEDIA_LIBRARY_GET_FOLDER_UNAUTHORIZED' } )
        return
    }

    if ( response.status !== 200 ) {
        store.dispatch( { type: 'MEDIA_LIBRARY_GET_FOLDER_UNEXPECTEDRESPONSE' } )
        return
    }

    try {
        body = plainToClass( GetMediaLibraryFolderDTO, JSON.parse( await response.text() ) )
    } catch ( exception ) {
        store.dispatch( { type: 'MEDIA_LIBRARY_GET_FOLDER_INVALIDJSON' } )
        return
    }

    if ( ( await validate( body ) ).length > 0 ) {
        store.dispatch( { type: 'MEDIA_LIBRARY_GET_FOLDER_INVALIDRESPONSE' } )
        return
    }

    store.dispatch( { type: 'MEDIA_LIBRARY_GET_FOLDER_OK', mediaLibraryFolder: body.mediaLibraryFolder } )

}
