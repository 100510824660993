import { Modal } from '../modal'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../state'
import { TextField } from '../field'
import './application-version-modal.scss'
import { Dropdown } from '../dropdown'
import { useState } from 'react'
import { listApplicationVersions, saveApplicationVersion } from "../../action/application-version";

interface ApplicationVersionModalProps {

    onModalClose?: () => void

}

export const ApplicationVersionModal = ( props: ApplicationVersionModalProps ) => {

    const dispatch = useDispatch()

    const { applicationVersion } = useSelector( ( state: State ) => state.applicationVersions )

    const [ version, setVersion ] = useState( { error: ``, value: applicationVersion!.version } )
    const [ platform, setPlatform ] = useState( { error: ``, value: applicationVersion!.platform } )
    const [ critical, setCritical ] = useState( { error: ``, value: applicationVersion!.critical ? 1 : 0 } )

    const onModalClose = () => {

        if ( props.onModalClose !== undefined ) props.onModalClose()

    }

    const onSave = async () => {

        await saveApplicationVersion( {
            id: applicationVersion!.id,
            version: version.value,
            platform: platform.value,
            critical: critical.value === 1
        } )

        await listApplicationVersions()
        dispatch( { type: `SELECT_APPLICATION_VERSION`, applicationVersionId: null } )

    }

    return (

        <Modal onModalClose={ onModalClose }>

            <div className="application-version-modal">

                <div className="application-version-modal__title">

                    Alkalmazás verzió { applicationVersion!.id === null ? `létrehozása` : `szerkesztése` }

                </div>

                <form className="application-version-modal__form">

                    <div className="form-field">

                        <div className="form-field__caption">

                            Verzió

                        </div>

                        <div className="form-field__field">

                            <TextField field={ version } onChange={ e => setVersion( { ...version, value: e.target.value } ) } placeholder="Verzió" type="small" maxlength={ 10 } />

                        </div>

                    </div>

                    <div className="form-field">

                        <div className="form-field__caption">

                            Platform

                        </div>

                        <div className="form-field__field">

                            <Dropdown value={ platform.value } items={ [ { id: 1, value: `Android` }, { id: 2, value: `iOS` } ] } onChange={ ( id: number ) => setPlatform( { ...platform, value: id } ) } compact={ true } />

                        </div>

                    </div>

                    <div className="form-field">

                        <div className="form-field__caption">

                            Kritikus

                        </div>

                        <div className="form-field__field">

                            <Dropdown value={ critical.value } items={ [ { id: 0, value: `Nem` }, { id: 1, value: `Igen` } ] } onChange={ ( id: number ) => setCritical( { ...critical, value: id } ) } compact={ true } />

                        </div>

                    </div>

                </form>

                <footer className="application-version-modal__footer">

                    <button className="button" onClick={ onSave }>

                        Mentés

                    </button>

                </footer>

            </div>

        </Modal>
    )
}
