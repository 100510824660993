
import { Expose } from 'class-transformer'
import { IsDefined, IsNumber, IsString } from 'class-validator'

export class PoliticianPhrase {

    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number 

    @Expose()
    @IsDefined()
    @IsString()
    description!: string

    descriptionField!: {
        error: string
        value: string 
        required: boolean
    }

    isNew!: boolean

}
