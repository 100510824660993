import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { deleteUser, deleteUsername, getUser, getUserActivities } from "../../action"
import { Modal, UserActivity, UserExperienceModal, UserModal } from "../../component"
import { TabView } from '../../component/tab-view'
import { TabViewPage } from '../../component/tab-view/tab-view-page'
import { address, authority, county, createFormattedDateString, position } from '../../helper'
import { State } from '../../state'
import './mobile-app-user.page.scss'
import { TagSelector } from '../../component/tag-selector'
import { useState } from 'react'
import { Interest } from '../../component/interest/interest'

const items = [
    { name: `Küldetések`, activityTypes: [ 18 ] },
    { name: `Hírek`, activityTypes: [ 2, 3, 4, 5, 6, 7 ] },
    { name: `Véleményvezérek`, activityTypes: [ 15, 16, 17 ] },
    { name: `Kérdőívek`, activityTypes: [ 10, 11 ] },
    { name: `Események`, activityTypes: [ 12, 13, 14 ] },
    { name: `Politikusaink`, activityTypes: [ 8, 9, 20 ] },
    { name: `Kvízek és tesztek`, activityTypes: [ 0 ] },
    { name: `Képzések`, activityTypes: [ 21, 22, 23, 24, 25, 26 ] }
]

export const MobileAppUser = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { user, userActivities, interests, selectedUser } = useSelector( ( state: State ) => state.users )
    const userDetails: { [ key: string ]: { caption: string, value: string | null } } = {
        'name': { caption: 'Név', value: `${ user?.lastName } ${ user?.firstName }` },
        'username': { caption: 'Játékosnév', value: `${ user?.username != null ? user?.username : "" }` },
        'avatarId': { caption: 'Avatar Id', value: `${ user?.avatarId }` },
        'email': { caption: 'E-mail', value: user?.email! },
        'membershipId': { caption: 'Tagsági szám', value: user?.membershipId! },
        'birthdate': { caption: 'Születési dátum', value: user?.birthdate === null ? null : createFormattedDateString( user?.birthdate! ) },
        'gender': { caption: 'Nem', value: user?.gender === 0 ? null : ( user?.gender === 1 ? `Férfi` : `Nő` ) },
        'phone': { caption: 'Telefonszám', value: user?.phone! },
        'address': { caption: 'Cím', value: address( user! ) },
    }

    const [ deleteMessage, setDeleteMessage ] = useState( `Biztosan törlöd a felhasználót?` )
    const [ deleteDesc, setDeleteDesc ] = useState( `Ezt a műveletet nem lehet visszavonni! Biztosan törlöd?` )
    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )
    const [ showConfirmModal, setShowConfirmModal ] = useState( false )
    const [ selectedItem, setSelectedItem ] = useState( items[ 0 ] )
    const [ showExperienceModal, setShowExperienceModal ] = useState( false )

    const selectItem = async ( item: { name: string, activityTypes: number[] } ) => {

        await getUserActivities( user?.id!, item.activityTypes )
        setSelectedItem( item )

    }

    const onDelete = async () => {

        await deleteFunc!()
        setShowConfirmModal( false )

    }

    const onDeleteUsername = async () => {

        setDeleteMessage( `Biztosan törlöd a felhasználó játékosnevét?` )
        setDeleteDesc( `Amennyiben törlöd, úgy neki egy újat kell megadnia.` )
        setDeleteFunc( () => {
            return async () => {
                await deleteUsername( user?.id! )
                await getUser( user?.id! )
            }
        } )
        setShowConfirmModal( true )

    }

    const onModifyUserClick = () => {
        dispatch( { type: 'SELECT_USER', user: user } )
    }

    const onDeleteUserClick = async () => {
        setDeleteMessage( `Biztosan törlöd a felhasználót?` )
        setDeleteDesc( `Ezt a műveletet nem lehet visszavonni! Biztosan törlöd?` )
        setDeleteFunc( () => {
            return async () => {
                if ( await deleteUser( user?.id! ) ) {
                    navigate( '/tagok/mobil-app-felhasznalok' )
                }
            }
        } )
        setShowConfirmModal( true )
    }

    return (
        <main className="page page--mobile-app-user">

            <div className="main-div">

                <header>

                    <h1>
                        <NavLink to="/tagok/mobil-app-felhasznalok">
                            Mobil App Felhasználók
                        </NavLink>
                        &nbsp;&gt;&nbsp;
                        <span>
                            { user?.lastName } { user?.firstName }
                        </span>
                    </h1>

                </header>

                <div className="mobile-app-user-layout">

                    <div className="card-div">

                        <header>

                            <div className="user-name">

                                { user?.lastName } { user?.firstName }

                                <div className="username">

                                    { user?.username ?? <i>Nincs játékosneve</i> }

                                </div>

                            </div>

                            <div className="user-experience" onClick={ () => setShowExperienceModal( true ) }>

                                <img alt="tapasztalati pontok" src="/image/xp.svg" />

                                <span>{ user?.experience }</span>

                            </div>

                            <button className="button button--ghost" disabled={ user?.userGroups?.find( userGroup => userGroup.name === 'master_admins' ) !== undefined } title={ user?.userGroups?.find( userGroup => userGroup.name === 'master_admins' ) !== undefined ? `Ezt a felhasználót nem lehet törölni!` : `` } onClick={ onDeleteUserClick }>

                                Törlés

                            </button>

                            <button className="button" onClick={ onDeleteUsername }>

                                Játékosnév törlése

                            </button>

                            <button className="button" onClick={ onModifyUserClick }>

                                Szerkesztés

                            </button>

                        </header>

                        <div className="user-main-info">

                            <div className="main-info">

                                <div className="main-info__caption">

                                    E-mail cím

                                </div>

                                <div className="main-info__value">

                                    { user?.email }

                                </div>

                            </div>

                            <div className="main-info">

                                <div className="main-info__caption">

                                    Megye

                                </div>

                                <div className="main-info__value">

                                    { county( user?.county! ) }

                                </div>

                            </div>

                            <div className="main-info">

                                <div className="main-info__caption">

                                    Pozíció

                                </div>

                                <div className="main-info__value">

                                    { position( user?.position! ) } • Mobilapplikáció felhasználó

                                </div>

                            </div>

                            <div className="main-info">

                                <div className="main-info__caption">

                                    Elérés

                                </div>

                                <div className="main-info__value">

                                    { authority( user?.position! ) }

                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="card-div">

                        <TabView tabs={ { activities: 'Aktivitások', tasks: 'Feladatok', data: 'Adatok', interest: 'Érdeklődés'} }>

                            <TabViewPage key="activities">

                                <div className="tabs">

                                    { items.map( item => (

                                        <a className={ selectedItem !== null && selectedItem.name === item.name ? `selected` : `` } onClick={ () => selectItem( item ) }>{ item.name }</a>

                                    ) ) }

                                </div>

                                <div className="user-activities">

                                    { userActivities.length === 0 ? <div className="user-activities__empty">Nincs megjeleníthető elem</div> : null }
                                    { userActivities.map( userActivity => (
                                        <UserActivity key={ userActivity.id } action={ userActivity.action } description={ userActivity.description } time={ new Date( userActivity.activityDate * 1000 ) } />
                                    ) ) }

                                </div>

                            </TabViewPage>

                            <TabViewPage key="tasks">

                                <div className="">Fejlesztés alatt</div>

                            </TabViewPage>

                            <TabViewPage key="data">

                                <div className="user-details">

                                    { Object.keys( userDetails ).map( userDetail => (
                                        <div key={ userDetail } className="user-detail">

                                            <div className="user-detail__caption">{ userDetails[ userDetail ].caption }</div>
                                            <div className="user-detail__value">{ userDetails[ userDetail ].value }</div>

                                        </div>
                                    ) ) }

                                </div>

                            </TabViewPage>

                            <TabViewPage key="interest">

                                <h2 className="title-text-h2" style={{ marginTop: 30, marginBottom: 30 }}>Érdeklődés az egyes tartalmi címkék iránt:</h2>

                                <div className="user-activities">
                                    { interests.length === 0 ? <div className="user-activities__empty">Nincs megjeleníthető elem</div> : null }
                                    { interests.sort( ( a, b ) => b.percent - a.percent ).map( interest => (
                                        <Interest key={ interest.tag } tag={ interest.tag } percent={ interest.percent } color={ interest.color }  />
                                    ) ) }
                                </div>

                                <h2 className="title-text-h2" style={{ marginTop: 30, marginBottom: 30 }}>Érdeklődés megoszlása címkénként:</h2>
                                <div className="user-activities">
                                    { interests.length === 0 ? <div className="user-activities__empty">Nincs megjeleníthető elem</div> : null }
                                    { interests.map( interest => (
                                        <Interest key={ interest.tag } tag={ interest.tag } percent={ interest.percent / interests.reduce( ( sum, i ) => sum + i.percent, 0 ) * 100 } color={ interest.color }  />
                                    ) ) }
                                </div>

                            </TabViewPage>

                        </TabView>

                    </div>

                    <div className="card-div">

                        <TagSelector tags={ user?.tags ?? [] } onTagAdd={ tag => dispatch( { type: `USER_ADD_TAG`, tag: tag } ) } onTagDelete={ tag => dispatch( { type: `USER_REMOVE_TAG`, tag: tag } ) } />

                    </div>

                </div>

            </div>

            { selectedUser !== null ? <UserModal /> : null }

            { showExperienceModal ? <UserExperienceModal onClose={ () => setShowExperienceModal( false ) } /> : null }

            { showConfirmModal ? (

                <Modal className="user-confirm-modal" onModalClose={ () => setShowConfirmModal( false ) }>

                    <div className="modal-title">{ deleteMessage }</div>
                    <div className="modal-description">{ deleteDesc }</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowConfirmModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ onDelete }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

        </main >
    )

}
