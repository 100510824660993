import { ChangeEvent, KeyboardEvent } from 'react'
import './search.input.scss'

interface SearchInputProps {
    // field: {
    //     error: string
    //     value: string
    // }
    onChange: ( event: ChangeEvent<HTMLInputElement> ) => void
    placeholder?: string
    onSearch?: ( e: KeyboardEvent<HTMLInputElement> ) => void
}

export const SearchInput = ( props: SearchInputProps ) => {

    const onKeyDown = ( e: KeyboardEvent<HTMLInputElement> ) => {

        if ( e.key === `Enter` && props.onSearch ) {

            props.onSearch( e )

        }

    }

    return (
        // <div className={ `field${ props.field.error.length > 0 ? ` field--error` : `` } field--search` }>
        <div className="field field--search">

            <input
                className={'input'}
                onChange={ props.onChange }
                placeholder={ props.placeholder }
                required={ false }
                type="search"
                onKeyDown={ onKeyDown } />
        </div>
    )
}
