import { useDispatch, useSelector } from 'react-redux'
import { Questionnaire } from '../../../../entity'
import { State } from '../../../../state'
import { Table } from '../../../../component'
import { CREATE_QUESTIONNAIRE_ACTIONS, GET_QUESTIONNAIRES_ACTIONS } from '../../../../action/questionnaires/questionnaires.constants'
import { getQuestionnaires } from '../../../../action/questionnaires'
import { createFormattedDateTimeString } from '../../../../helper'
import { QuestionnaireTableMenuButton } from './questionnaire-table-menu-button'
import { NewsVisibility } from '../../../../enum/news-visibility.enum'
import { QuestionnaireTableStatusDropdown } from './questionnaire-table-status-dropdown'
import './questionnaire-table.scss'
import { useNavigate } from 'react-router-dom'

interface QuestionnaireTableProps {
    questionnaireArray: Questionnaire[]
}

export const QuestionnaireTable = ( props: QuestionnaireTableProps ) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { order, order_by } = useSelector( ( state: State ) => state.questionnaires )
    const { page, from, to, count } = useSelector( ( state: State ) => state.questionnaires.pagination )

    const onPageChange = ( page: number ) => {
        dispatch( { type: GET_QUESTIONNAIRES_ACTIONS.GET_QUESTIONNAIRES_CHANGE_PAGE, page: page } )
        getQuestionnaires()
    }

    const onOrderChange = ( column: string ) => {
        dispatch( { type: GET_QUESTIONNAIRES_ACTIONS.GET_QUESTIONNAIRES_ORDER, column: column } )
        getQuestionnaires()
    }

    const visibilityOptions = [
        { id: NewsVisibility.Open, value: 'Nyílt', icon: "lock_open" },
        { id: NewsVisibility.Internal, value: 'Belső', icon: "my_location" },
        { id: NewsVisibility.Closed, value: 'Zárt', icon: "lock" },
    ]

    const columns = [
        {
            align: `center`,
            caption: `ID`,
            key: `id`,
            name: `id`,
            width: 100
        },
        {
            caption: ``,
            key: `title`,
            name: `title`,
            width: 552
        },
        {
            align: `center`,
            canOrder: false,
            caption: `Kitöltve`,
            key: `numberOfCompleted`,
            name: `numberOfCompleted`,
            width: 100
        },
        {
            align: `center`,
            caption: `Típus`,
            key: `type`,
            name: `type`,
            width: 130,
            render: ( data: any ) => {
                return (
                        <>
                            <span className="label-text news-visibility">{ visibilityOptions[data.visibility].value }</span>
                            <span className="material-symbols-outlined" style={{fontSize:"14px", marginLeft:'8px'}}>{ visibilityOptions[data.visibility].icon }</span>
                        </>
                )
            }
        },
        {
            align: `center`,
            caption: `Publikálás`,
            render: ( data: any ) => createFormattedDateTimeString( data.visibleFrom ),
            name: `visibleFrom`,
            width: 200
        },
        {
            align: `center`,
            caption: `Archiválás`,
            render: ( data: any ) => createFormattedDateTimeString( data.visibleTo ),
            name: `visibleTo`,
            width: 200
        },
        {
            align: `center`,
            caption: `Státusz`,
            name: `status`,
            render: ( data: any ) => <QuestionnaireTableStatusDropdown questionnaire={ data } />,
            width: 130
        },
        {
            align: `center`,
            caption: ``,
            name: `actions`,
            render: ( data: any ) => <QuestionnaireTableMenuButton questionnaire={ data } />,
            width: 100
        }
    ]

    const onRowClick = ( row: Questionnaire ) => {

        navigate(`/kerdoivek/${ row.id }`)

    }

    return (
        <Table
            columns={ columns }
            dataset={ props.questionnaireArray }
            order={ { by: order_by, direction: order } }
            onOrderChange={ onOrderChange }
            onRowClick={ onRowClick }
            pagination={ { page: page, from: from, to: to, count: count, onPageChange: onPageChange } }
        />
    )

}
