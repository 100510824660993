import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetStatisticsDTO } from '../../dto/statistics'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { GET_STATISTICS_ACTIONS } from './statistics.constants'

export const getStatistics = async ( startDate?: Date, endDate?: Date ) => {

    store.dispatch( { type: GET_STATISTICS_ACTIONS.GET_STATISTICS_STARTED } )

    let start = null
    if(startDate != undefined ){
        start = startDate.getTime() / 1000
    }

    let end = null
    if(endDate != undefined ){
        end = endDate.getTime() / 1000
    }

    const promise = createCall(`cms/statistics/base?start=`+start+`&end=`+end, BASE_METHODS.GET)
    promise.then(async response => {

        let responseBody: GetStatisticsDTO

        try {
            responseBody = plainToClass( GetStatisticsDTO, JSON.parse( await response.text() ) )
        } catch ( exception ) {
            store.dispatch( { type: GET_STATISTICS_ACTIONS.GET_STATISTICS_INVALID_JSON } )
            return
        }
    
        if ( ( await validate( responseBody ) ).length > 0 ) {
            store.dispatch( { type: GET_STATISTICS_ACTIONS.GET_STATISTICS_INVALID_RESPONSE } )
            return
        }
    
        store.dispatch( { type: GET_STATISTICS_ACTIONS.GET_STATISTICS_OK, 
            applicationLaunchedCount: responseBody.applicationLaunchedCount, 
            applicationLaunchedXAxis: responseBody.applicationLaunchedXAxis,
            applicationLaunchedData: responseBody.applicationLaunchedData,
            userCount: responseBody.userCount, 
            usersChartXAxis: responseBody.usersChartXAxis,
            usersChartData: responseBody.usersChartData, 
            iOSCount: responseBody.iOSCount, 
            androidCount: responseBody.androidCount,
        } )

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })
   
}