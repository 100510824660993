import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { User } from '../../entity'
import { State } from '../../state'
import './header.scss'
import {useState} from "react"

interface HeaderProps {

    onMobileMenuToggle?: ( open: boolean ) => void

}

export const Header = ( props: HeaderProps ) => {

    const dispatch = useDispatch()

    const authenticatedUser = useSelector( ( state: State ) => state.session.authenticatedUser ) as User
    const [ open, setOpen ] = useState( false )
    const [ openDropdown, setOpenDropdown ] = useState( false )

    const openProfileModal = () => {

        setOpenDropdown( false )
        dispatch( { type: `OPEN_PROFILE_MODAL` } )

    }

    const toggleMenu = () => {

        setOpen( !open )
        if ( props.onMobileMenuToggle !== undefined ) props.onMobileMenuToggle( open )

    }

    const toggleDropdown = () => {

        setOpenDropdown( !openDropdown )

    }

    const logout = () => {

        setOpenDropdown( !openDropdown )

    }

    return (
        <header className="header">
            <div className="header__logo">
                <Link to="/">
                    <img alt="A Demokratikus Koalíció logója" className="header__logo" src="/image/dk-logo.svg" />
                </Link>
            </div>
            {/* <div className="notifications">
                <div className="badge">3</div>
                <span className="material-symbols-outlined">notifications</span>
            </div>
            <div className="separator"></div> */}
            <div className="dropdown">
                <div className="user-dropdown" onClick={ e => toggleDropdown() } >
                    <span className="user-dropdown__name">{ authenticatedUser.lastName } { authenticatedUser.firstName }</span>
                    <span className="material-symbols-outlined"> {openDropdown ? "expand_less" : "expand_more" } </span>
                </div>
                <div className="dropdown-content" style={{ display: openDropdown ? "block" : "none" }}>
                    <Link to="#" onClick={ openProfileModal } style= {{textDecoration:'none'}} >
                        <div className="dropdown-content-item">
                            <img className="dropdown-content-item__icon dropdown-content-item__icon--small" alt="Profil ikon" src="/image/user.svg" />
                            <span className="dropdown-content-item__title">Profil</span>
                        </div>
                    </Link>
                    <Link to="/bejelentkezes" onClick={ e =>  dispatch( { type: 'LOGOUT' } )} style= {{textDecoration:'none'}} >
                        <div className="dropdown-content-item">                      
                            <img className="dropdown-content-item__icon" alt="Kijelentkezés ikon" src="/image/logout.svg" />
                            <span className="dropdown-content-item__title">Kijelentkezés</span>     
                        </div>
                    </Link>
                </div>
            </div>
            <div className="mobile-menu" onClick={ e => toggleMenu() }>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </header>
    )
}
