import { User } from '../../entity'
import { store } from '../../store'
import { getUser } from './get-user.action'

export const saveUser = async ( user: Partial<User>, id?: number ) => {

    let response: Response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/users/${ id ?? `` }`, {
            body: JSON.stringify( user ),
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'post'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'SAVE_USER_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'SAVE_USER_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'SAVE_USER_UNEXPECTEDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'SAVE_USER_OK' } )
    getUser( id || user.id as number )

}
