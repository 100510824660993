import './quest-group-card.scss'
import { TabView } from '../tab-view'
import { TabViewPage } from '../tab-view/tab-view-page'
import { QuestGroupSettings } from './quest-group-settings'
import { QuestGroupQuests } from './quest-group-quests'
import { QuestGroup } from '../../entity'
import { useDispatch } from 'react-redux'
import { saveQuestGroup, sendPush } from '../../action/gamification'
import { county } from '../../helper'
import { TextArea } from '../textarea'
import React, { useRef, useState } from "react"
import { MessageModal, Modal } from '../modal'
import { AchievementModal } from '../achievement-modal'
import { useLoaderData } from "react-router-dom"

interface QuestGroupCardProps {

    questGroup: QuestGroup
    hideLeagues?: boolean
    index: number

    onDelete?: () => void

}

const leagues = [
    { id: 2, name: `Bronz liga`, icon: `/image/leagues/0002.png` },
    { id: 3, name: `Ezüst liga`, icon: `/image/leagues/0003.png` },
    { id: 4, name: `Arany liga`, icon: `/image/leagues/0004.png` },
    { id: 5, name: `DK liga`, icon: `/image/leagues/0005.png` }
]

export const QuestGroupCard = ( props: QuestGroupCardProps ) => {

    const loaderData: any = useLoaderData()
    const dispatch = useDispatch()

    const [ pushMessage, setPushMessage ] = useState( `` )
    const [ open, setOpen ] = useState( true )
    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )
    const [ showConfirmModal, setShowConfirmModal ] = useState( false )

    const [infoModalVisibility, setInfoModalVisibility] = useState(false)
    const [infoModalTitle, setInfoModalTitle] = useState("")
    const [infoModalDescription, setInfoModalDescription] = useState("")

    const [ showAchievementModal, setShowAchievementModal ] = useState( false )
    const [ achievementData, setAchievementData ] = useState( {} )
    const [ activeTab, setActiveTab ] = useState( `settings` )

    const addAchievement = () => {

        if ( props.questGroup.achievementGroup === null ) {

            setAchievementData( { type: 0, category: 1, activity: 11, showHeader: false, showTypeSelect: false, goldDesiredValue: 1, ...achievementData } )

        } else {

            setAchievementData( { showHeader: false, showTypeSelect: false, name: props.questGroup.achievementGroup.name, description: props.questGroup.achievementGroup.description, id: props.questGroup.achievementGroup.id, goldExp: props.questGroup.achievementGroup.goldAchievement.experience, goldImage: props.questGroup.achievementGroup.goldAchievement.image, ...achievementData } )

        }

        setShowAchievementModal( true )

    }

    const onSaveAchievement = async ( data: any ) => {

        setAchievementData( data )
        setShowAchievementModal( false )

    }

    const onDeleteQuestConfirm = async () => {

        await deleteFunc!()
        setShowConfirmModal( false )

    }

    const onFieldChange = ( field: string, value: string | number | null ) => {

        dispatch( { type: `QUEST_GROUPS_UPDATE_FIELD`, index: props.index, field: field, value: value } )

    }

    const onQuestFieldChange = ( index: number, field: string, value: any ) => {

        dispatch( { type: `QUEST_GROUPS_QUEST_UPDATE_FIELD`, questGroupIndex: props.index, index: index, field: field, value: value } )

    }

    const addQuest = () => {

        dispatch( { type: `QUEST_GROUPS_ADD_QUEST`, index: props.index } )

    }

    const onDeleteQuest = ( index: number ) => {

        setDeleteFunc( () => {
            return async () => {
                dispatch( { type: `QUEST_GROUPS_DELETE_QUEST`, questGroupIndex: props.index, index: index } )
            }
        } )
        setShowConfirmModal( true )

    }

    const onDelete = async () => {

        if ( props.onDelete ) props.onDelete()

    }

    const onSaveQuestGroup = async () => {

        if (!checkFields()) {
            return
        } 

        const questGroup = await saveQuestGroup( props.questGroup, achievementData, loaderData.seasonId )
        if ( questGroup ) {
            dispatch( { type: `QUEST_GROUPS_UPDATE_FIELD`, index: props.index, field: `id`, value: questGroup.id } )
            dispatch( { type: `QUEST_GROUPS_UPDATE_FIELD`, index: props.index, field: `quests`, value: questGroup.quests } )
            dispatch( { type: `QUEST_GROUPS_UPDATE_FIELD`, index: props.index, field: `leagues`, value: questGroup.leagues } )
        }

    }

    const onDeleteAchievement = async () => {

        setAchievementData( {} )
        props.questGroup.achievementGroup = null
        setShowAchievementModal( false )

    }

    const checkFields = (): boolean => {
        if (props.questGroup.name.trim().length === 0) {
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription( "Kihívás csoport megnevezése kötelező!");
            setInfoModalVisibility(true);
            return false
        }

        if (props.questGroup.lead.trim().length === 0) {
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription( "Kihívás lead megadása kötelező!");
            setInfoModalVisibility(true);
            return false
        }

        if (props.questGroup.description.trim().length === 0) {
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription( "Kihívás szövegének megadása kötelező!");
            setInfoModalVisibility(true);
            return false
        }

        if (props.questGroup.quests.length === 0) {
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription( "Feladat hozzáadása kötelező!");
            setInfoModalVisibility(true);
            return false
        }

        for ( const quest of props.questGroup.quests ) {

            if (isNaN( quest.repeats ) || Math.floor( quest.repeats ) != quest.repeats || quest.repeats < 1) {
                setInfoModalTitle("Figyelmeztetés");
                setInfoModalDescription("Az ismétlések pozitív számának egész számnak kell lennie!");
                setInfoModalVisibility(true);
                return false;
            }

        }

        return true
    }

    const onSendClick = async (  ) => {
        if (pushMessage.trim().length === 0) {
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription( "A Push szöveg megadása kötelező!");
            setInfoModalVisibility(true);
            return false
        }
        await sendPush( props.questGroup.name, pushMessage, props.questGroup.selectedLeagues ? props.questGroup.selectedLeagues : props.questGroup.leagues.map( l => l.id ), props.questGroup.id )
    }

    const ref = React.createRef<HTMLDivElement>()

    const onPushClick = () => {

        setActiveTab( `push` )
        document.documentElement.scrollTo( { top: ref.current!.offsetTop, behavior: "smooth" } )

    }

    return (

        <div id={ ( props.questGroup as any ).uuid } className="card-div quest-group-card" ref={ ref }>

            { open ? (

                <TabView active={ activeTab } onTabChange={ setActiveTab } tabs={ { settings: `Beállítások`, stats: `Statisztika`, push: `PUSH üzenetek` } }>

                <TabViewPage key="settings">

                    <QuestGroupSettings tags={ props.questGroup.tags } name={ { value: props.questGroup.name, error: `` } } lead={ { value: props.questGroup.lead, error: `` } } description={ { value: props.questGroup.description, error: `` } } experience={ { value: props.questGroup.exp.toString(), error: `` } } image={ props.questGroup.image! } leagues={ props.questGroup.leagues ?? [] } onFieldChange={ onFieldChange } hideLeagues={ props.hideLeagues === true } />

                    <QuestGroupQuests quests={ props.questGroup.quests || [] } onFieldChange={ ( index, field, value ) => onQuestFieldChange( index, field, value ) } onDeleteQuest={ index => onDeleteQuest( index ) } onPushClick={ onPushClick } />

                    <footer>

                        <button className="button button--ghost" style={{minWidth: 182}} onClick={ addAchievement } >Kitüntetés { props.questGroup.achievementGroup === null && achievementData && Object.keys(achievementData).length === 0 ? `hozzáadása` : `szerkesztése` }</button>
                        <button className="button button--primary" style={{minWidth: 182}} onClick={ onSaveQuestGroup } >Mentés</button>
                        <button className="button button--ghost" style={{minWidth: 273}} onClick={ addQuest }>Feladat hozzáadása</button>
                        <button className="button button--ghost button--warning" style={{minWidth: 234}} onClick={ onDelete }>Küldetés csoport törlése</button>
                        <button className="button button--expand" ><img src="/image/chevron-left.svg" onClick={ () => setOpen( false ) } /></button>

                    </footer>

                </TabViewPage>

                <TabViewPage key="stats">

                    { props.questGroup.stats ? (

                        <div className="stat-cards">

                            <div className="card-div">

                                <div className="label">Összes felhasználó a kihíváson:</div>
                                <div className="value">{ props.questGroup.stats.users } fő</div>

                            </div>

                            <div className="card-div">

                                <div className="label">Elvégezte:</div>
                                <div className="value">{ props.questGroup.stats.completedPercent }%</div>

                            </div>

                            <div className="card-div">

                                <div className="label">Elvégezte 50 év alatt:</div>
                                <div className="value">{ props.questGroup.stats.completedUnder50Percent }%</div>

                            </div>

                            <div className="card-div">

                                <div className="label">Innen oldották meg a legtöbben:</div>
                                <div className="value">{ county( props.questGroup.stats.mostCompletedFromCounty ) }</div>

                            </div>

                            <div className="card-div">

                                <div className="label">Átlagos elvégzési idő:</div>
                                <div className="value">{ props.questGroup.stats.averageCompletionTime } nap</div>

                            </div>

                        </div>

                    ) : (

                        <>Még nem jeleníthetőek meg statisztikák ehhez a küldetés csoporthoz!</>

                    ) }

                    { /*
                    <div className="users-completed">

                        <div className="caption">Felhasználók, akik elvégezték a kihívást:</div>

                        <Table columns={ [
                            {
                                align: `center`,
                                name: `id`,
                                headerRender: () => `ID`,
                                render: user => user.membershipId,
                                width: 150
                            },
                            {
                                align: `left`,
                                name: `name`,
                                headerRender: () => `Név`,
                                render: user => `${ user.lastName } ${ user.firstName }`,
                                width: 300
                            },
                            {
                                align: `center`,
                                name: `county`,
                                headerRender: () => `Megye`,
                                render: user => county( user.county ),
                                width: 300
                            },
                            {
                                align: `center`,
                                name: `position`,
                                headerRender: () => `Pozíció`,
                                render: user => position( user.position ),
                                width: 300
                            },
                            {
                                align: `center`,
                                name: `experience`,
                                headerRender: () => `TP`,
                                render: user => user.experience,
                                width: 300
                            },
                            {
                                align: `center`,
                                name: `interest`,
                                headerRender: () => `Érdeklődés`,
                                render: user => ``,
                                width: 300
                            },
                            {
                                align: `center`,
                                name: `league`,
                                headerRender: () => `Liga`,
                                render: user => user.league?.name,
                                width: 300
                            }
                        ] } dataset={ props.questGroup.users } />

                    </div>
                    */ }

                </TabViewPage>

                <TabViewPage key="push">

                    <div className="push-form">

                        <div className="field">

                            <label>Push szöveg megadadása *</label>
                            <TextArea field={{ value: pushMessage, error: `` }} onChange={ e => setPushMessage( e.target.value ) } />

                        </div>

                        <footer>

                            <button className="button button--primary" onClick={ onSendClick } style={{minWidth: 176}}>Küldés</button>

                        </footer>

                    </div>

                </TabViewPage>

            </TabView>

            ) : (

                <div className="quest-card-header">

                    <div className="quest-card-header__title">{ props.questGroup.name }</div>
                    <div className="quest-card-header__leagues">

                        { leagues.map( league => (

                            <div className={ `league${ props.questGroup.selectedLeagues ? ( props.questGroup.selectedLeagues?.indexOf( league.id ) !== -1 ? ` league--selected` : `` ) : ( props.questGroup.leagues.map( l => l.id ).indexOf( league.id ) !== -1 ? ` league--selected` : `` ) }` }>

                                <div className="league__icon">

                                    <img src={ league.icon } />

                                </div>

                            </div>

                        ) ) }

                    </div>
                    <button className="button button--expand" onClick={ () => setOpen( true ) }><img src="/image/chevron-left.svg" /></button>

                </div>

            ) }

            { showConfirmModal ? (

                <Modal className="quest-group-quest-delete-confirm-modal" onModalClose={ () => setShowConfirmModal( false ) }>

                    <div className="modal-title">Biztosan törlöd a küldetést?</div>
                    <div className="modal-description">A küldetés nem kerül ténylegesen törlésre, ameddig nem mented a küldetés csoportot!</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowConfirmModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ onDeleteQuestConfirm }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

            {infoModalVisibility ? (
                
                <MessageModal
                    title={infoModalTitle}
                    message={infoModalDescription}
                    onCloseClick={() => {
                        setInfoModalVisibility(false);
                    }}
                />

            ) : null}

            { showAchievementModal ? (

                <AchievementModal selectedAchievement={ achievementData } onClose={ () => setShowAchievementModal( false ) } onSave={ onSaveAchievement } onDelete={ onDeleteAchievement } />

            ) : null }

        </div>

    )

}