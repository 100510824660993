import { store } from '../../store'
import { plainToClass } from 'class-transformer'
import { SaveLessonDTO } from '../../dto/courses'
import { validate } from 'class-validator'

export const saveLesson = async ( id: number, lesson: any ) => {

    let body, response

    lesson.pages = lesson.pages.map( ( p: any ) => {

        if ( p.isNew === true ) {
            p.id = undefined
        }
        return p

    } )

    lesson.quiz.questions = lesson.quiz.questions.map( ( q: any ) => {

        if ( q.isNew === true ) {
            q.id = undefined
        }

        q.answers.map( ( a: any ) => {

            if ( a.isNew === true ) {
                a.id = undefined
            }
            return a

        } )

        return q

    } )

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/courses/${ id }/lessons`, {
            body: JSON.stringify( lesson ),
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'POST'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'SAVE_LESSON_TIMEOUT' } )
        return null

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'SAVE_LESSON_UNAUTHORIZED' } )
        return null

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'SAVE_LESSON_UNEXPECTEDRESPONSE' } )
        return null

    }

    try {

        body = plainToClass( SaveLessonDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'SAVE_LESSON_INVALIDJSON' } )
        return null

    }

    if ( ( await validate( body ) ).length > 0 ) {

        store.dispatch( { type: 'SAVE_LESSON_INVALIDRESPONSE' } )
        return null

    }

    store.dispatch( { type: 'SAVE_LESSON_OK' } )
    return body.lesson

}