import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { ListTagsDTO } from '../../dto/tags'
import { store } from '../../store'
import { Tag } from '../../entity'

export const saveTag = async ( id: number | null, name: string, color: string, tagGroupId: number ) => {

    let body, response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/tags/tags`, {
            body: JSON.stringify( {
                id: id,
                name: name,
                color: color,
                tagGroupId: tagGroupId
            } ),
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'POST'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'SAVE_TAG_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'SAVE_TAG_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'SAVE_TAG_UNEXPECTEDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'SAVE_TAG_OK' } )

}
