import { Expose, Type } from 'class-transformer'
import { ValidateNested } from 'class-validator'
import { NewsCategory } from '../../entity'

export class GetNewsCategoriesDTO {

    @Expose()
    @ValidateNested()
    @Type( () => NewsCategory )
    newsCategories!: NewsCategory[]

}

