import { modifyQuestionnaireStatus } from '../../../../action/questionnaires'
import { Dropdown } from '../../../../component'
import { Questionnaire } from '../../../../entity'
import { NewsStatus } from '../../../../enum/news-status.enum'

interface QuestionnaireTableStatusDropdownProps {
    questionnaire: Questionnaire
}

export const QuestionnaireTableStatusDropdown = ( props: QuestionnaireTableStatusDropdownProps ) => {

    const statusOptions = [
        { id: NewsStatus.Active, value: 'Aktív', color:"#27B06C" },
        { id: NewsStatus.Draft, value: 'Vázlat', color:"#59595B" },
        { id: NewsStatus.Deleted, value: 'Törölt', color:"#C30870" },
    ]

    const onStatusChange = ( itemId:any ) => {
        modifyQuestionnaireStatus([props.questionnaire.id], itemId)
    }

    return (
        <Dropdown value={ props.questionnaire.status }  items={ statusOptions } onChange={ onStatusChange } compact={ true } />
    )

}