import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'

export const sendPushMessage = async (title: string, body: string, data: string, topic: string, actionId: number, contentId: number | null, contentUrl: number | null) => {
    const contentAction = {
        actionId: actionId,
        contentUrl: contentUrl,
        contentId: contentId,
    }

    const messageBody = JSON.stringify({
        title: title,
        body: body,
        data: data,
        topic: topic,
        contentAction: contentAction,
        targets: [],
        mediaLibraryFileId: store.getState().createNews.form.mediaLibraryFile?.id ?? null,
        timing: null,
        immediate: true,
    })

    store.dispatch({ type: 'SEND_PUSH_MESSAGE_STARTED' })

    const promise = createCall(`firebase/send-push`, BASE_METHODS.POST, messageBody)
    promise.then(async (response) => {
        store.dispatch({ type: 'SEND_PUSH_MESSAGE_OK', response: response })
    })

    promise.catch((error) => {
        store.dispatch({ type: error })
    })
}
