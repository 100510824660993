import { store } from '../../store'
import { plainToClass } from 'class-transformer'
import { GetVideoDTO } from '../../dto/videos'
import { validate } from 'class-validator'

export const getVideo = async ( id: number ) => {

    let body, response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/2.0.0/cms/videos/${ id }`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'GET'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'GET_VIDEO_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'GET_VIDEO_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'GET_VIDEO_UNEXPECTEDRESPONSE' } )
        return

    }

    try {

        body = plainToClass( GetVideoDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'GET_VIDEO_INVALIDJSON' } )
        return

    }

    if ( ( await validate( body ) ).length > 0 ) {

        store.dispatch( { type: 'GET_VIDEO_INVALIDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'GET_VIDEO_OK', video: body.video } )

}