import { store } from '../../store'
import { plainToClass } from 'class-transformer'
import { ListAchievementGroupsDTO } from '../../dto/achievements'
import { validate } from 'class-validator'

export const createAchievementGroup = async ( achievementGroupData: any ) => {

    let response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/achievement-groups`, {
            body: JSON.stringify( achievementGroupData ),
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'POST'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'CREATE_ACHIEVEMENT_GROUP_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'CREATE_ACHIEVEMENT_GROUP_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'CREATE_ACHIEVEMENT_GROUP_UNEXPECTEDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'CREATE_ACHIEVEMENT_GROUP_OK' } )

}