import { LoaderFunctionArgs } from 'react-router-dom'
import { getNewsItemStats } from '../../action/news'
import { getNewsCategories } from '../../action/news-category'
import { getNews } from '../../action/news/get-news.action'
import { getPoliticians } from '../../action/politician'
import { POLITICIANS_ACTIONS } from '../../action/politician/politicians.constants'
import { GET_QUESTIONNAIRES_ACTIONS } from '../../action/questionnaires/questionnaires.constants'
import { store } from '../../store'
import { listTags } from '../../action/tags'
import { listDefaultExperiencePoints } from '../../action/gamification'

export const createNewsLoader = async ( args: LoaderFunctionArgs ) => {

    store.dispatch( { type: GET_QUESTIONNAIRES_ACTIONS.GET_QUESTIONNAIRES_SEARCH, search: "" }  )
    store.dispatch( { type: POLITICIANS_ACTIONS.GET_POLITICIANS_SEARCH, search: "" }  )

    await listDefaultExperiencePoints()
    await listTags()
    await getNewsCategories()
    await getPoliticians()

    if(args.params.id != undefined && args.params.id != null ){
        await getNews( parseInt( args.params.id as string ) )
        await getNewsItemStats( parseInt( args.params.id as string ) )
    }

}
