import './interest.scss'

interface InterestProps {

    tag: string
    percent: number
    color: string

}

export const Interest = ( props: InterestProps ) => {

    return (
        <div className="interest">

            <div className="interest__tag">

                <span className="name">{ props.tag }</span>
                
                <span className="percent">{props.percent.toFixed(0)} %</span>

                <div className="percent-div">
                    <div className="percent" style={{width: `${props.percent}%`, backgroundColor: `${props.color}` }}/>
                </div>

            </div>

        </div>
    )

}
