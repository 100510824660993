export const GET_FLOATING_MESSAGES_ACTIONS = {
    GET_FLOATING_MESSAGES_INVALID_JSON: "GET_FLOATING_MESSAGES_INVALID_JSON",
    GET_FLOATING_MESSAGES_INVALID_RESPONSE: "GET_FLOATING_MESSAGES_INVALID_RESPONSE",
    GET_FLOATING_MESSAGES_OK: "GET_FLOATING_MESSAGES_OK",
    GET_FLOATING_MESSAGES_CHANGE_PAGE: "GET_FLOATING_MESSAGES_CHANGE_PAGE",
    GET_FLOATING_MESSAGES_SEARCH: "GET_FLOATING_MESSAGES_SEARCH",
    GET_FLOATING_MESSAGES_ORDER: "GET_FLOATING_MESSAGES_ORDER",
}

export const CREATE_FLOATING_MESSAGE_ACTIONS = {
    CREATE_FLOATING_MESSAGE_STARTED: "CREATE_FLOATING_MESSAGE_STARTED",
    CREATE_FLOATING_MESSAGE_INVALID_JSON: "CREATE_FLOATING_MESSAGE_INVALID_JSON",
    CREATE_FLOATING_MESSAGE_INVALID_RESPONSE: "CREATE_FLOATING_MESSAGE_INVALID_RESPONSE",
    CREATE_FLOATING_MESSAGE_OK: "CREATE_FLOATING_MESSAGE_OK",
    CREATE_FLOATING_MESSAGE_CHANGE_TYPE: "CREATE_FLOATING_MESSAGE_CHANGE_TYPE",
    CREATE_FLOATING_MESSAGE_CHANGE_MESSAGE: "CREATE_FLOATING_MESSAGE_CHANGE_MESSAGE",
    CREATE_FLOATING_MESSAGE_CHANGE_IMAGE_ID: "CREATE_FLOATING_MESSAGE_CHANGE_IMAGE_ID",
    CREATE_FLOATING_MESSAGE_CLEAR: "CREATE_FLOATING_MESSAGE_CLEAR",
    CREATE_FLOATING_MESSAGE_MODIFY_FLOATING_MESSAGE: "CREATE_FLOATING_MESSAGE_MODIFY_FLOATING_MESSAGE",
}

export const DELETE_FLOATING_MESSAGE_ACTIONS = {
    DELETE_FLOATING_MESSAGE_STARTED: "DELETE_FLOATING_MESSAGE_STARTED",
    DELETE_FLOATING_MESSAGE_OK: "DELETE_FLOATING_MESSAGE_OK",
}


