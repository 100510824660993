import { cookie, message } from '../helper'
import { SessionState } from '../state'

const initialSessionState: SessionState = {
    authenticatedUser: undefined,
    token: cookie( 'token' ),
    activeConfirmMessage: null,
    activeTitle: undefined,
    showProfileModal: false
}

export const sessionReducer = ( state: SessionState = initialSessionState, action: any ): SessionState => {
    switch ( action.type ) {
        case 'GET_ME_OK':
            return { ...state, authenticatedUser: action.user }
        case 'GET_ME_INVALIDJSON':
        case 'GET_ME_INVALIDRESPONSE':
        case 'GET_ME_NOUSER':
        case 'GET_ME_TIMEOUT':
        case 'GET_ME_UNAUTHORIZED':
        case 'GET_ME_UNEXPECTEDRESPONSE':
            return { ...state, authenticatedUser: null }

        case 'LOGIN_OK':
            cookie( 'token', action.token )
            return { ...state, authenticatedUser: action.user, token: action.token }

        case 'LOGOUT':
            cookie( 'token', '' )
            return { ...state, authenticatedUser: null, token: null }

        case `SET_ACTIVE_CONFIRM_MESSAGE`:
            return { ...state, activeConfirmMessage: action.message, activeTitle: action.title }


        case `OPEN_PROFILE_MODAL`:
            return { ...state, showProfileModal: true }

        case `CLOSE_PROFILE_MODAL`:
            return { ...state, showProfileModal: false }


        case `MODIFY_USER_OK`:
            message( `Az adatok sikeresen módosításra kerültek!` )
            return state


        default:
            return state
    }
}
