import './winners-table.scss'
import { Table } from '../table'
import { prizeType } from '../../helper'
import { listWinners } from '../../action/winners'
import { useEffect, useState } from 'react'
import { MenuButton } from '../button'
import { WinnerModal } from './winner-modal'
import { deleteWinner, saveWinner } from '../../action/winners'
import { Modal } from '../modal'

interface WinnersTableProps {

    keyword: string
    roundId: number

}

export const WinnersTable = ( props: WinnersTableProps ) => {

    const [ winners, setWinners ] = useState( [] as { id: number, name: string, prize_type: number, prize_name: string, position: number, status: number, color: string }[] )
    const [ pagination, setPagination ] = useState( { page: 1, from: 1, to: 1, count: 1 } )
    const [ order, setOrder ] = useState( { by: `position`, direction: `ASC` as `ASC` | `DESC` } )
    const [ selectedWinner, setSelectedWinner ] = useState( null as any )

    const [ selectedForDelete, setSelectedForDelete ] = useState( null as number | null )
    const [ showConfirmModal, setShowConfirmModal ] = useState( false )

    const onPageChange = async ( page: number ) => {

        await getWinners( page )

    }

    const getWinners = async ( page?: number ) => {

        if ( page === undefined ) { page = pagination.page }

        const body = await listWinners( props.roundId, page, order, props.keyword )
        setWinners( body.winners )
        setPagination( body.pagination )

    }

    const openModal = ( winnerId: number ) => {

        setSelectedWinner( winners.find( winner => winner.id === winnerId ) || null )

    }

    const onOrderChange = async ( by: string ) => {

        let direction: `ASC` | `DESC` = `ASC`

        if ( by === order.by && order.direction === `ASC` ) {

            direction = `DESC`

        }

        setOrder( { by: by, direction: direction } )
        await getWinners()

    }

    const onMenuButtonClick = async ( action: number, winner: any ) => {

        switch ( action ) {

            case 1:
                setSelectedForDelete( winner.id )
                setShowConfirmModal( true )
                break
            default:
                openModal( winner.id )

        }

    }

    const onSave = async ( winner: any ) => {

        await saveWinner( winner )
        setSelectedWinner( null )
        await getWinners()

    }

    const onDelete = async () => {

        setShowConfirmModal( false )
        await deleteWinner( selectedForDelete! )
        await getWinners()

    }

    useEffect( () => {

        getWinners( 1 )

    }, [] )

    return (

        <>

        <Table className="winners-table" columns={ [

            {
                align: `center`,
                name: `id`,
                canOrder: true,
                headerRender: () => `ID`,
                render: winner => winner.id,
                width: 100
            },
            {
                align: `center`,
                name: `name`,
                canOrder: true,
                headerRender: () => `Nyertes neve`,
                render: winner => winner.name,
                width: 200
            },
            {
                align: `center`,
                name: `prize_type`,
                canOrder: true,
                headerRender: () => `Típus`,
                render: winner => prizeType( parseInt( winner.prize_type ) ),
                width: 150
            },
            {
                align: `center`,
                name: `prize_name`,
                canOrder: true,
                headerRender: () => `Nyeremény`,
                render: winner => winner.prize_type === `2` ? `${ winner.prize_exp } tapasztalati pont` : winner.prize_name,
                width: 250
            },
            {
                align: `center`,
                name: `league_name`,
                canOrder: true,
                headerRender: () => `Liga`,
                render: winner => winner.league_name,
                width: 150
            },
            {
                align: `center`,
                name: `position`,
                canOrder: true,
                headerRender: () => `Helyezés`,
                render: winner => <>{ winner.position }<br />{ winner.experience } TP</>,
                width: 200
            },
            {
                align: `center`,
                name: `status`,
                canOrder: true,
                headerRender: () => `Státusz`,
                render: winner => winner.status === null || winner.status.length === 0 ? `Feldolgozásra vár` : winner.status,
                width: 150
            },
            {
                align: `center`,
                name: `color`,
                canOrder: true,
                headerRender: () => ``,
                render: winner => <div className="color-preview" style={{ backgroundColor: winner.color }}></div>,
                width: 50
            },
            {
                align: `center`,
                name: `action`,
                canOrder: false,
                headerRender: () => ``,
                render: winner => <MenuButton hasBackground={ true } items={ [
                    { id: 0, value: `Szerkesztés`, icon: `edit.svg` },
                    { id: 1, value: `Törlés`, icon: `trash.svg` }
                ] } onChange={ ( action: number ) => onMenuButtonClick( action, winner ) } />,
                width: 50
            }

        ] } dataset={ winners } pagination={ { ...pagination, onPageChange } } onRowClick={ winner => openModal( winner.id ) } onOrderChange={ onOrderChange } order={ order } />

            { selectedWinner !== null ? (

                <WinnerModal winner={ selectedWinner } onClose={ () => setSelectedWinner( null ) } onSave={ onSave } />

            ) : null }

            { showConfirmModal ? (

                <Modal className="winner-confirm-modal" onModalClose={ () => setShowConfirmModal( false ) }>

                    <div className="modal-title">Biztosan törlöd a nyertest?</div>
                    <div className="modal-description">Ezt a műveletet nem lehet visszavonni! Biztosan törlöd?</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowConfirmModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ onDelete }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

        </>

    )

}