
import { Expose, Type } from 'class-transformer'
import { IsDefined, ValidateNested } from 'class-validator'
import { PushMessage } from '../../entity'

export class GetAllPushMessageDTO {

    @Expose()
    @ValidateNested()
    @Type( () => PushMessage )
    messages!: PushMessage[]

    @Expose()
    @IsDefined()
    pagination!: {
        page: number
        from: number
        to: number
        count: number
    }

}