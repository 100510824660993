import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { ListVotesDTO } from '../../dto/votes'
import { store } from '../../store'

export const listVotes = async () => {

    let body, response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/2.0.0/cms/votes`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'get'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'LIST_VOTES_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'LIST_VOTES_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'LIST_VOTES_UNEXPECTEDRESPONSE' } )
        return

    }

    try {

        body = plainToClass( ListVotesDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'LIST_VOTES_INVALIDJSON' } )
        return

    }

    if ( ( await validate( body ) ).length > 0 ) {

        store.dispatch( { type: 'LIST_VOTES_INVALIDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'LIST_VOTES_OK', votes: body.votes, pagination: body.pagination } )

}
