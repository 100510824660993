export const getCoordinates = async ( address: string ) => {

    const response = await fetch( `https://nominatim.openstreetmap.org/search?q=${ address }&format=json` )

    if ( response.status !== 200 ) {

        return null

    }

    const places = await response.json()

    if ( !Array.isArray( places ) || places.length === 0 ) {

        return null

    }

    return {

        lat: places[ 0 ].lat,
        lng: places[ 0 ].lon

    }

}
