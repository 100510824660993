import { Expose } from 'class-transformer'
import { IsDefined } from 'class-validator'
import { VideoCategory } from "../../entity"

export class ListVideoCategoriesDTO
{

    @Expose()
    @IsDefined()
    categories!: VideoCategory[]

}

