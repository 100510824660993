

import { Expose, Type } from 'class-transformer'
import { IsArray, IsDefined, IsNumber, IsOptional, ValidateNested } from 'class-validator'

export class GetQuestionnairesStatisticsDTO {

    @Expose()
    @IsDefined()
    @IsNumber()
    newQuestionnaireCount!: number 

    @Expose()
    @IsDefined()
    @IsArray()
    newQuestionnaireXAxis!: [] 

    @Expose()
    @IsDefined()
    @IsArray()
    newQuestionnaireData!: [] 

    @Expose()
    @IsDefined()
    @IsNumber()
    sendQuestionnaireCount!: number 

    @Expose()
    @IsDefined()
    @IsArray()
    sendQuestionnaireXAxis!: [] 

    @Expose()
    @IsDefined()
    @IsArray()
    sendQuestionnaireData!: [] 

}
