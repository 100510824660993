import { MouseEventHandler } from 'react'
import { PoliticianTitle } from '../../../entity'
import './politician-title-element.scss'

interface  PoliticianTitleElementProps {
    politicianTitleElement: PoliticianTitle
    onDeleteClick?: MouseEventHandler
}

export const PoliticianTitleElement = ( props: PoliticianTitleElementProps ) => {

    return (
        <li key={ props.politicianTitleElement.title } className={ `title-list__politician-title-element` } >
            <span className="label-text name"> { props.politicianTitleElement.title }</span>
            <button type='button' className="delete"  id={ props.politicianTitleElement == null ? "" : `${ props.politicianTitleElement.id }`}  onClick={ props.onDeleteClick }> <img alt="Törlés gomb" src="/image/close.svg" /></button>  
        </li >
    )

}
