import { LoaderFunctionArgs } from 'react-router-dom'
import { getUsers, getUserStats } from '../../action'
import { listUserGroups } from '../../action/user-groups'
import { store } from "../../store"
import { listAdminActivities } from '../../action'

export const adminUsersLoader = async ( args: LoaderFunctionArgs ) => {

    store.dispatch( { type: 'UPDATE_SEARCHTERM', searchTerm: `` } )
    await listUserGroups()
    await getUserStats( [ 2, 3, 4 ] )
    await getUsers( [ 2, 3, 4 ] )
    await listAdminActivities( [ `News`, `NewsCategory` ] )

}
