import { CreateNewsCategoryState } from '../../state/news-category'
import { message } from '../../helper'

const initialCreateNewsCategoryState: CreateNewsCategoryState  = {
    form: {
        id: null,
        color: "#2AA7DF",
        name: {
            error: '',
            value: '',
            required: true
        },
    },
    saveDate: null,
    newsCategory: null
}

export const createNewsCategoryReducer = ( state: CreateNewsCategoryState = initialCreateNewsCategoryState, action: any ): CreateNewsCategoryState => {
    switch ( action.type ) {
        case 'CREATE_NEWS_CATEGORY_CHANGE_COLOR':
            return { ...state, form: { ...state.form, color: action.color } }
        case 'CREATE_NEWS_CATEGORY_CHANGE_NAME':
            return { ...state, form: { ...state.form, name: { ...state.form.name, value: action.name } } }
        case 'CREATE_NEWS_CATEGORY_STARTED':
            return { ...state, saveDate: new Date() }
        case 'CREATE_NEWS_CATEGORY_INVALIDJSON':
            return { ...state, saveDate: null, newsCategory: null }
        case 'CREATE_NEWS_CATEGORY_INVALIDRESPONSE':
            return { ...state, saveDate: null, newsCategory: null }
        case 'CREATE_NEWS_CATEGORY_NOUSER':
            return { ...state, saveDate: null, newsCategory: null }
        case 'CREATE_NEWS_CATEGORY_TIMEOUT':
            return { ...state, saveDate: null, newsCategory: null }
        case 'CREATE_NEWS_CATEGORY_UNAUTHORIZED':
            return { ...state, saveDate: null, newsCategory: null }
        case 'CREATE_NEWS_CATEGORY_UNEXPECTEDRESPONSE':
            return { ...state, saveDate: null, newsCategory: null }
        case 'CREATE_NEWS_CATEGORY_OK':
            message( `A hír kategória sikeresen mentésre került!` )
            return { ...state, saveDate: null, 
                form: { ...state.form, 
                    name: { ...state.form.name, value: "" },
                    color:  "#2AA7DF" }, 
                    newsCategory: action.newsCategory }
        case 'CREATE_NEWS_CATEGORY_CLEAR':
            return initialCreateNewsCategoryState 
        case 'CREATE_NEWS_CATEGORY_MODIFY_NEWS':
            return { ...state, 
                form: { ...state.form, 
                    id: action.newsCategory.id,
                    color: action.newsCategory.color,
                    name: {
                        error: '',
                        value: action.newsCategory.name,
                        required: true,
                    },
                } 
            }
        default:
            return state
    }
}

