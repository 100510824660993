import './news-stats.tab.scss'
import { useSelector } from 'react-redux'
import { State } from '../../../state'
import { createFormattedTimeFromSec } from '../../../helper/date.helper'

interface NewsStatsTabProps { }

export const NewsStatsTab = ( props: NewsStatsTabProps ) => {

    const { openCount, readCount, readTime, sendQuestionCount } = useSelector( ( state: State ) => state.createNews.stats )

    return (
        <div className="card-div ">
     
            <div className="container column">

                <div className="caption">
                    Az hírhez tartozó statisztikai adatok itt jelennek meg:
                </div>

                <div className="container">
                    <div className="card-div card-div-right-margin">

                        <div className="info-slot">
                            <div className="info-slot__description">
                                <span className="caption"> Hány felhasználó nyitotta meg a hírt </span>
                            </div>

                            <div className="info-slot__info">
                                <span className="value">{ openCount }</span> { "db" }
                            </div>
                        </div>

                    </div>

                    <div className="card-div card-div-right-margin">

                        <div className="info-slot">
                            <div className="info-slot__description">
                                <span className="caption"> Hány felhasználó olvasta végig a hírt </span>
                            </div>

                            <div className="info-slot__info">
                                <span className="value">{ readCount }</span> { "db" }
                            </div>
                        </div>

                    </div>

                    <div className="card-div card-div-right-margin">

                        <div className="info-slot">
                            <div className="info-slot__description">
                                <span className="caption"> Mennyi időt töltöttek a felhasználók a hír olvasásával </span>
                            </div>

                            <div className="info-slot__info">
                                <span className="value">{ createFormattedTimeFromSec(readTime) }</span> 
                            </div>
                        </div>

                    </div>

                    <div className="card-div ">

                        <div className="info-slot">
                            <div className="info-slot__description">
                                <span className="caption"> Hány felhasználó töltöte ki a hírhez tartozó kvízt </span>
                            </div>

                            <div className="info-slot__info">
                                <span className="value">{ sendQuestionCount }</span> { "db" }
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    )
}


