import { Expose } from 'class-transformer'
import { IsDefined } from 'class-validator'
import { QuestGroup } from '../../entity'

export class SaveQuestGroupDTO {

    @Expose()
    @IsDefined()
    questGroup!: QuestGroup

}
