import { getMonthQuotes, getThreeScheduledQuotes, listAllQuotes, listQuoteParties } from '../../action/quotes'

export const quotesCalendarLoader = () => {

    const date = new Date()
    date.setDate( 1 )

    listAllQuotes()
    getMonthQuotes( date )
    listQuoteParties()

}
