import './color-picker.scss'
import { MouseEvent } from 'react'

interface ColorPickerProps {

    onColorSelect?: ( color: string ) => void

}

export const ColorPicker = ( props: ColorPickerProps ) => {

    const onColorSelect = ( event: MouseEvent ) => {

        const target = event.target as HTMLInputElement
        if ( target.tagName === `polygon` && props.onColorSelect && target.getAttribute( `fill` ) !== null ) { props.onColorSelect( target.getAttribute( `fill` )! ) }

    }

    return (

        <div className="color-picker">

            <svg viewBox="0 0 234 199" onClick={ onColorSelect }>
                
                <polygon points="63,0,72,4,72,15,63,19,54,15,54,4" fill="#003366" />
                <polygon points="81,0,90,4,90,15,81,19,72,15,72,4" fill="#336699"/>
                <polygon points="99,0,108,4,108,15,99,19,90,15,90,4" fill="#3366CC"/>
                <polygon points="117,0,126,4,126,15,117,19,108,15,108,4" fill="#003399"/>
                <polygon points="135,0,144,4,144,15,135,19,126,15,126,4" fill="#000099"/>
                <polygon points="153,0,162,4,162,15,153,19,144,15,144,4" fill="#0000CC"/>
                <polygon points="171,0,180,4,180,15,171,19,162,15,162,4" fill="#000066"/>
                <polygon points="54,15,63,19,63,30,54,34,45,30,45,19" fill="#006666"/>
                <polygon points="72,15,81,19,81,30,72,34,63,30,63,19" fill="#006699"/>
                <polygon points="90,15,99,19,99,30,90,34,81,30,81,19" fill="#0099CC"/>
                <polygon points="108,15,117,19,117,30,108,34,99,30,99,19" fill="#0066CC"/>
                <polygon points="126,15,135,19,135,30,126,34,117,30,117,19" fill="#0033CC"/>
                <polygon points="144,15,153,19,153,30,144,34,135,30,135,19" fill="#0000FF"/>
                <polygon points="162,15,171,19,171,30,162,34,153,30,153,19" fill="#3333FF"/>
                <polygon points="180,15,189,19,189,30,180,34,171,30,171,19" fill="#333399"/>
                <polygon points="45,30,54,34,54,45,45,49,36,45,36,34" fill="#669999"/>
                <polygon points="63,30,72,34,72,45,63,49,54,45,54,34" fill="#009999"/>
                <polygon points="81,30,90,34,90,45,81,49,72,45,72,34" fill="#33CCCC"/>
                <polygon points="99,30,108,34,108,45,99,49,90,45,90,34" fill="#00CCFF"/>
                <polygon points="117,30,126,34,126,45,117,49,108,45,108,34" fill="#0099FF"/>
                <polygon points="135,30,144,34,144,45,135,49,126,45,126,34" fill="#0066FF"/>
                <polygon points="153,30,162,34,162,45,153,49,144,45,144,34" fill="#3366FF"/>
                <polygon points="171,30,180,34,180,45,171,49,162,45,162,34" fill="#3333CC"/>
                <polygon points="189,30,198,34,198,45,189,49,180,45,180,34" fill="#666699"/>
                <polygon points="36,45,45,49,45,60,36,64,27,60,27,49" fill="#339966"/>
                <polygon points="54,45,63,49,63,60,54,64,45,60,45,49" fill="#00CC99"/>
                <polygon points="72,45,81,49,81,60,72,64,63,60,63,49" fill="#00FFCC"/>
                <polygon points="90,45,99,49,99,60,90,64,81,60,81,49" fill="#00FFFF"/>
                <polygon points="108,45,117,49,117,60,108,64,99,60,99,49" fill="#33CCFF"/>
                <polygon points="126,45,135,49,135,60,126,64,117,60,117,49" fill="#3399FF"/>
                <polygon points="144,45,153,49,153,60,144,64,135,60,135,49" fill="#6699FF"/>
                <polygon points="162,45,171,49,171,60,162,64,153,60,153,49" fill="#6666FF"/>
                <polygon points="180,45,189,49,189,60,180,64,171,60,171,49" fill="#6600FF"/>
                <polygon points="198,45,207,49,207,60,198,64,189,60,189,49" fill="#6600CC"/>
                <polygon points="27,60,36,64,36,75,27,79,18,75,18,64" fill="#339933"/>
                <polygon points="45,60,54,64,54,75,45,79,36,75,36,64" fill="#00CC66"/>
                <polygon points="63,60,72,64,72,75,63,79,54,75,54,64" fill="#00FF99"/>
                <polygon points="81,60,90,64,90,75,81,79,72,75,72,64" fill="#66FFCC"/>
                <polygon points="99,60,108,64,108,75,99,79,90,75,90,64" fill="#66FFFF"/>
                <polygon points="117,60,126,64,126,75,117,79,108,75,108,64" fill="#66CCFF"/>
                <polygon points="135,60,144,64,144,75,135,79,126,75,126,64" fill="#99CCFF"/>
                <polygon points="153,60,162,64,162,75,153,79,144,75,144,64" fill="#9999FF"/>
                <polygon points="171,60,180,64,180,75,171,79,162,75,162,64" fill="#9966FF"/>
                <polygon points="189,60,198,64,198,75,189,79,180,75,180,64" fill="#9933FF"/>
                <polygon points="207,60,216,64,216,75,207,79,198,75,198,64" fill="#9900FF"/>
                <polygon points="18,75,27,79,27,90,18,94,9,90,9,79" fill="#006600"/>
                <polygon points="36,75,45,79,45,90,36,94,27,90,27,79" fill="#00CC00"/>
                <polygon points="54,75,63,79,63,90,54,94,45,90,45,79" fill="#00FF00"/>
                <polygon points="72,75,81,79,81,90,72,94,63,90,63,79" fill="#66FF99"/>
                <polygon points="90,75,99,79,99,90,90,94,81,90,81,79" fill="#99FFCC"/>
                <polygon points="108,75,117,79,117,90,108,94,99,90,99,79" fill="#CCFFFF"/>
                <polygon points="126,75,135,79,135,90,126,94,117,90,117,79" fill="#CCCCFF"/>
                <polygon points="144,75,153,79,153,90,144,94,135,90,135,79" fill="#CC99FF"/>
                <polygon points="162,75,171,79,171,90,162,94,153,90,153,79" fill="#CC66FF"/>
                <polygon points="180,75,189,79,189,90,180,94,171,90,171,79" fill="#CC33FF"/>
                <polygon points="198,75,207,79,207,90,198,94,189,90,189,79" fill="#CC00FF"/>
                <polygon points="216,75,225,79,225,90,216,94,207,90,207,79" fill="#9900CC"/>
                <polygon points="9,90,18,94,18,105,9,109,0,105,0,94" fill="#003300"/>
                <polygon points="27,90,36,94,36,105,27,109,18,105,18,94" fill="#009933"/>
                <polygon points="45,90,54,94,54,105,45,109,36,105,36,94" fill="#33CC33"/>
                <polygon points="63,90,72,94,72,105,63,109,54,105,54,94" fill="#66FF66"/>
                <polygon points="81,90,90,94,90,105,81,109,72,105,72,94" fill="#99FF99"/>
                <polygon points="99,90,108,94,108,105,99,109,90,105,90,94" fill="#CCFFCC"/>
                <polygon points="117,90,126,94,126,105,117,109,108,105,108,94" fill="#FFFFFF"/>
                <polygon points="135,90,144,94,144,105,135,109,126,105,126,94" fill="#FFCCFF"/>
                <polygon points="153,90,162,94,162,105,153,109,144,105,144,94" fill="#FF99FF"/>
                <polygon points="171,90,180,94,180,105,171,109,162,105,162,94" fill="#FF66FF"/>
                <polygon points="189,90,198,94,198,105,189,109,180,105,180,94" fill="#FF00FF"/>
                <polygon points="207,90,216,94,216,105,207,109,198,105,198,94" fill="#CC00CC"/>
                <polygon points="225,90,234,94,234,105,225,109,216,105,216,94" fill="#660066"/>
                <polygon points="18,105,27,109,27,120,18,124,9,120,9,109" fill="#336600"/>
                <polygon points="36,105,45,109,45,120,36,124,27,120,27,109" fill="#009900"/>
                <polygon points="54,105,63,109,63,120,54,124,45,120,45,109" fill="#66FF33"/>
                <polygon points="72,105,81,109,81,120,72,124,63,120,63,109" fill="#99FF66"/>
                <polygon points="90,105,99,109,99,120,90,124,81,120,81,109" fill="#CCFF99"/>
                <polygon points="108,105,117,109,117,120,108,124,99,120,99,109" fill="#FFFFCC"/>
                <polygon points="126,105,135,109,135,120,126,124,117,120,117,109" fill="#FFCCCC"/>
                <polygon points="144,105,153,109,153,120,144,124,135,120,135,109" fill="#FF99CC"/>
                <polygon points="162,105,171,109,171,120,162,124,153,120,153,109" fill="#FF66CC"/>
                <polygon points="180,105,189,109,189,120,180,124,171,120,171,109" fill="#FF33CC"/>
                <polygon points="198,105,207,109,207,120,198,124,189,120,189,109" fill="#CC0099"/>
                <polygon points="216,105,225,109,225,120,216,124,207,120,207,109" fill="#993399"/>
                <polygon points="27,120,36,124,36,135,27,139,18,135,18,124" fill="#333300"/>
                <polygon points="45,120,54,124,54,135,45,139,36,135,36,124" fill="#669900"/>
                <polygon points="63,120,72,124,72,135,63,139,54,135,54,124" fill="#99FF33"/>
                <polygon points="81,120,90,124,90,135,81,139,72,135,72,124" fill="#CCFF66"/>
                <polygon points="99,120,108,124,108,135,99,139,90,135,90,124" fill="#FFFF99"/>
                <polygon points="117,120,126,124,126,135,117,139,108,135,108,124" fill="#FFCC99"/>
                <polygon points="135,120,144,124,144,135,135,139,126,135,126,124" fill="#FF9999"/>
                <polygon points="153,120,162,124,162,135,153,139,144,135,144,124" fill="#FF6699"/>
                <polygon points="171,120,180,124,180,135,171,139,162,135,162,124" fill="#FF3399"/>
                <polygon points="189,120,198,124,198,135,189,139,180,135,180,124" fill="#CC3399"/>
                <polygon points="207,120,216,124,216,135,207,139,198,135,198,124" fill="#990099"/>
                <polygon points="36,135,45,139,45,150,36,154,27,150,27,139" fill="#666633"/>
                <polygon points="54,135,63,139,63,150,54,154,45,150,45,139" fill="#99CC00"/>
                <polygon points="72,135,81,139,81,150,72,154,63,150,63,139" fill="#CCFF33"/>
                <polygon points="90,135,99,139,99,150,90,154,81,150,81,139" fill="#FFFF66"/>
                <polygon points="108,135,117,139,117,150,108,154,99,150,99,139" fill="#FFCC66"/>
                <polygon points="126,135,135,139,135,150,126,154,117,150,117,139" fill="#FF9966"/>
                <polygon points="144,135,153,139,153,150,144,154,135,150,135,139" fill="#FF6666"/>
                <polygon points="162,135,171,139,171,150,162,154,153,150,153,139" fill="#FF0066"/>
                <polygon points="180,135,189,139,189,150,180,154,171,150,171,139" fill="#CC6699"/>
                <polygon points="198,135,207,139,207,150,198,154,189,150,189,139" fill="#993366"/>
                <polygon points="45,150,54,154,54,165,45,169,36,165,36,154" fill="#999966"/>
                <polygon points="63,150,72,154,72,165,63,169,54,165,54,154" fill="#CCCC00"/>
                <polygon points="81,150,90,154,90,165,81,169,72,165,72,154" fill="#FFFF00"/>
                <polygon points="99,150,108,154,108,165,99,169,90,165,90,154" fill="#FFCC00"/>
                <polygon points="117,150,126,154,126,165,117,169,108,165,108,154" fill="#FF9933"/>
                <polygon points="135,150,144,154,144,165,135,169,126,165,126,154" fill="#FF6600"/>
                <polygon points="153,150,162,154,162,165,153,169,144,165,144,154" fill="#FF5050"/>
                <polygon points="171,150,180,154,180,165,171,169,162,165,162,154" fill="#CC0066"/>
                <polygon points="189,150,198,154,198,165,189,169,180,165,180,154" fill="#660033"/>
                <polygon points="54,165,63,169,63,180,54,184,45,180,45,169" fill="#996633"/>
                <polygon points="72,165,81,169,81,180,72,184,63,180,63,169" fill="#CC9900"/>
                <polygon points="90,165,99,169,99,180,90,184,81,180,81,169" fill="#FF9900"/>
                <polygon points="108,165,117,169,117,180,108,184,99,180,99,169" fill="#CC6600"/>
                <polygon points="126,165,135,169,135,180,126,184,117,180,117,169" fill="#FF3300"/>
                <polygon points="144,165,153,169,153,180,144,184,135,180,135,169" fill="#FF0000"/>
                <polygon points="162,165,171,169,171,180,162,184,153,180,153,169" fill="#CC0000"/>
                <polygon points="180,165,189,169,189,180,180,184,171,180,171,169" fill="#990033"/>
                <polygon points="63,180,72,184,72,195,63,199,54,195,54,184" fill="#663300"/>
                <polygon points="81,180,90,184,90,195,81,199,72,195,72,184" fill="#996600"/>
                <polygon points="99,180,108,184,108,195,99,199,90,195,90,184" fill="#CC3300"/>
                <polygon points="117,180,126,184,126,195,117,199,108,195,108,184" fill="#993300"/>
                <polygon points="135,180,144,184,144,195,135,199,126,195,126,184" fill="#990000"/>
                <polygon points="153,180,162,184,162,195,153,199,144,195,144,184" fill="#800000"/>
                <polygon points="171,180,180,184,180,195,171,199,162,195,162,184" fill="#993333"/>
                
            </svg>

        </div>
    
    )
    
}