import { Expose } from 'class-transformer'
import { IsDefined, ValidateIf } from 'class-validator'
import { Quiz } from '../../entity'

export class ListQuizesDTO {

    @Expose()
    @IsDefined()
    quizes!: Quiz[]

    @Expose()
    @IsDefined()
    @ValidateIf( ( object, value ) => value !== null )
    highlighted!: Quiz | null

    @Expose()
    @IsDefined()
    pagination!: {
        page: number
        from: number
        to: number
        count: number
    }

}

