import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetNewsItemStatsDTO } from '../../dto/news'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { GET_NEWS_ITEM_STATS_ACTIONS } from './news.constants'

export const getNewsItemStats = async ( id: number | null ) => {

    const promise = createCall(`news/stats/${ id }`, BASE_METHODS.GET)
    promise.then(async response => {
        let body: GetNewsItemStatsDTO

        try {
            body = plainToClass( GetNewsItemStatsDTO, JSON.parse( await response.text() ) )
        } catch ( exception ) {
            store.dispatch( { type: GET_NEWS_ITEM_STATS_ACTIONS.GET_NEWS_ITEM_STATS_INVALID_JSON } )
            return
        }
    
        if ( ( await validate( body ) ).length > 0 ) {
            store.dispatch( { type: GET_NEWS_ITEM_STATS_ACTIONS.GET_NEWS_ITEM_STATS_INVALID_RESPONSE } )
            return
        }
    
        store.dispatch( { type: GET_NEWS_ITEM_STATS_ACTIONS.GET_NEWS_ITEM_STATS_OK, 
            openCount: body.openCount, 
            readCount: body.readCount, 
            readTime: body.readTime, 
            sendQuestionCount: body.sendQuestionCount
        } )

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })

}