import { store } from '../../store'
import { Quest } from '../../entity'
import { plainToClass } from 'class-transformer'
import { SaveQuestDTO } from '../../dto/gamification'

export const saveQuest = async ( quest: Quest, seasonId: number ) => {

    let response, body

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/quests`, {
            body: JSON.stringify( {
                id: quest.id,
                seasonId: seasonId,
                name: quest.name,
                type: quest.type,
                questConditionId: quest.type === 1 ? 2 : quest.questConditionId,
                contentId: ( typeof quest.contentId === `string` && ( quest.contentId as string ).length === 0 ) ? null : quest.contentId,
                repeats: parseInt( quest.repeats.toString() ),
                exp: quest.exp,
                url: quest.url ?? ``,
                leagues: quest.selectedLeagues || quest.leagues.map( league => league.id ),
                tags: quest.tags
            } ),
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'POST'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'SAVE_QUEST_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'SAVE_QUEST_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'SAVE_QUEST_UNEXPECTEDRESPONSE' } )
        return

    }

    try {

        body = plainToClass( SaveQuestDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'LIST_LEAGUES_INVALIDJSON' } )
        return

    }

    store.dispatch( { type: 'SAVE_QUEST_OK' } )
    return body.quest

}
