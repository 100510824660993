import { LessonQuizAnswer, LessonQuizQuestion as LessonQuizQuestionEntity, MediaLibraryFile } from '../../entity'
import './lesson-quiz-question.scss'
import { Dropdown } from '../dropdown'
import { useDispatch } from 'react-redux'
import { TextField } from '../field'
import { TagSelector } from '../tag-selector'
import { AddImage } from '../add-image'
import { useState } from 'react'
import { MediaLibrary } from '../media-library'
import { ImageEditor } from '../image-editor/image-editor'

interface LessonQuizQuestionProps {

    question: LessonQuizQuestionEntity

}

export const LessonQuizQuestion = ( props: LessonQuizQuestionProps ) => {

    const dispatch = useDispatch()
    const [ showMediaLibrary, setShowMediaLibrary ] = useState( false )
    const [ showImageEditor, setShowImageEditor ] = useState( false )
    const [ selectedAnswer, setSelectedAnswer ] = useState( null as LessonQuizAnswer | null )
    const [ file, setFile ] = useState( null as MediaLibraryFile | null )

    const [ open, setOpen ] = useState( true )

    const u = ( field: string, value: any ) => {

        dispatch( { type: `UPDATE_QUIZ_QUESTION`, id: props.question.id, field, value } )

    }

    const uA = ( id: any, field: string, value: any ) => {

        dispatch( { type: `UPDATE_QUIZ_ANSWER`, questionId: props.question.id, id, field, value } )

    }

    const onDelete = () => {

        dispatch( { type: `DELETE_QUIZ_QUESTION`, id: props.question.id } )

    }

    const onAddImage = ( answer: LessonQuizAnswer ) => {

        setSelectedAnswer( answer )
        setShowMediaLibrary( true )

    }

    const onDeleteImage = ( answer: LessonQuizAnswer ) => {

        uA( answer.id, `image`, null )

    }

    return (

        <div className={ `lesson-quiz-question ${ open ? `lesson-quiz-question--open` : `` } card-div` }>

            { open ? (

                <div className="form">

                    <div className="caption caption--small">Kérdés megnevezése</div>

                    <div className="shared-field">

                        <TextField field={ { value: props.question.title, error: `` } } onChange={ e => u( `title`, e.target.value ) } />
                        <Dropdown value={ props.question.type } items={ [
                            { id: 0, value: `Feleletválasztós` }, { id: 1, value: `Képválasztó` }
                        ] } onChange={ ( id: number ) => u( `type`, id ) } compact={ false } />

                    </div>

                    { props.question.type === 0 ? (

                        <div className="answers answers--text">

                            { props.question.answers.map( ( answer, index ) => (

                                <div className="quiz-question">

                                    <div className="question-header">

                                        <div className="caption caption--small">{ index + 1 }. Válasz</div>
                                        <button className={ `button button--is-solution button--ghost ${ answer.isSolution ? `button--is-solution--selected` : `` }` } onClick={ () => dispatch( { type: `UPDATE_QUIZ_SOLUTION`, questionId: props.question.id, id: answer.id } ) }>Ez a helyes válasz</button>

                                    </div>

                                    <TextField field={ { value: answer.answer, error: `` } } onChange={ e => uA( answer.id, `answer`, e.target.value ) } />

                                    <TagSelector tags={ answer.tags } compact={ true } onTagAdd={ tag => dispatch( { type: `QUIZ_ANSWER_ADD_TAG`, questionId: props.question.id, id: answer.id, tag } ) } onTagDelete={ tag => dispatch( { type: `QUIZ_ANSWER_REMOVE_TAG`, questionId: props.question.id, id: answer.id, tag } ) } />

                                </div>

                            ) ) }

                        </div>

                    ) : (

                        <div className="answers answers--image">

                            { props.question.answers.map( ( answer, index ) => (

                                <div className="quiz-question">

                                    <AddImage imageUrl={ answer.image ? answer.image.url! : `` } text="Kép hozzáadása" width="250px" height="141px" onAddImage={ () => onAddImage( answer ) } onDeleteImage={ () => onDeleteImage( answer ) } />

                                    <div className="answer-info">

                                        <button className={ `button button--is-solution button--ghost ${ answer.isSolution ? `button--is-solution--selected` : `` }` } onClick={ () => dispatch( { type: `UPDATE_QUIZ_SOLUTION`, questionId: props.question.id, id: answer.id } ) }>Ez a helyes válasz</button>

                                        <TagSelector tags={ answer.tags } compact={ true } onTagAdd={ tag => dispatch( { type: `QUIZ_ANSWER_ADD_TAG`, questionId: props.question.id, id: answer.id, tag } ) } onTagDelete={ tag => dispatch( { type: `QUIZ_ANSWER_REMOVE_TAG`, questionId: props.question.id, id: answer.id, tag } ) } />

                                    </div>

                                </div>

                            ) ) }

                        </div>

                    ) }

                    <div className="other-fields">

                        <div className="caption caption--small">Jó válasz esetén ezt mondja a házigazda:</div>
                        <TextField field={ { value: props.question.goodAnswerResponse, error: `` } } onChange={ e => u( `goodAnswerResponse`, e.target.value ) } />

                        <div className="caption caption--small">Rossz válasz esetén ezt mondja a házigazda:</div>
                        <TextField field={ { value: props.question.badAnswerResponse, error: `` } } onChange={ e => u( `badAnswerResponse`, e.target.value ) } />

                    </div>

                    <div className="question-footer">

                        <button className="button button--warning" onClick={ onDelete }>Törlés</button>
                        <button className="button button--expand" onClick={ () => setOpen( false ) }><img alt="" src="/image/chevron-left.svg" /></button>

                    </div>

                </div>

            ) : (

                <div className="lesson-quiz-question__header">

                    <h2 className="title-text-h2">{ props.question.title }</h2>
                    <button className="button button--expand" onClick={ () => setOpen( true ) }><img alt="" src="/image/chevron-left.svg" /></button>

                </div>

            ) }


            { showMediaLibrary ? (

                <MediaLibrary onMediaSelected={ ( file: MediaLibraryFile ) => {

                    setFile( file )
                    setShowMediaLibrary( false )
                    setShowImageEditor( true )

                } } onClose={ () => setShowMediaLibrary( false ) } />

            ) : null }

            { showImageEditor ? (

                <ImageEditor mediaLibraryFile={ file! } onClose={ () => setShowImageEditor( false ) } onReady={ file => {

                    uA( selectedAnswer!.id, `image`, file )
                    setShowImageEditor( false )

                } } aspectRatio={ { name: `Válasz képe`, ratio: 16 / 9 } } />

            ) : null }

        </div>

    )

}