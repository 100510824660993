import { useState, MouseEvent, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavLink, useNavigate } from "react-router-dom"
import { createNotification, deleteEventImage, deleteNotification, getCoordinates, getEvent, getEventImages, saveEvent, unreportEventImage } from "../../action/events";
import { InfoSlot, MenuButton, Modal } from '../../component'
import { EventModal } from "../../component/event-modal"
import { TextField } from "../../component/field"
import { ImageEditor } from "../../component/image-editor/image-editor"
import { MediaLibrary } from "../../component/media-library"
import { InputModal, MessageModal } from "../../component/modal"
import { PoliticianModal } from "../../component/politicians"
import { Event, MediaLibraryFile } from "../../entity"
import { State } from "../../state"
import "./details.scss"
import DatePicker from "react-datepicker"
import { DescriptionTextArea } from "../../component/textarea/description"
import { AddImage } from "../../component/add-image"
import { TagSelector } from '../../component/tag-selector'
import { AchievementModal } from '../../component/achievement-modal'

interface DetailsProps {}

const format = (format: string, timestamp: number): string => {
    const date = new Date(timestamp * 1000);
    const month_short = [
        `jan`,
        `feb`,
        `már`,
        `ápr`,
        `máj`,
        `jún`,
        `júl`,
        `aug`,
        `sze`,
        `okt`,
        `nov`,
        `dec`,
    ];
    return format
    .replaceAll(`Y`, date.getFullYear().toString())
    .replaceAll(`mm`, month_short[date.getMonth()])
    .replaceAll(`d`, date.getDate().toString().padStart(2, `0`))
    .replaceAll(`h`, date.getHours().toString().padStart(2, `0`))
    .replaceAll(`m`, date.getMinutes().toString().padStart(2, `0`));
};

export const Details = (props: DetailsProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )
    const [ showConfirmDelete, setShowConfirmDelete ] = useState( false )
    const { selectedPolitician, hostPoliticians } = useSelector((state: State) => state.events);
    const { event, notifications } = useSelector((state: State) => state.events);
    const {
        id,
        startDate,
        endDate,
        visibility,
        isOnline,
        title,
        description,
        liveUrl,
        location,
        imageFile,
        lat,
        lng,
        tags,
        summary,
        highlighted
    } = useSelector((state: State) => state.events.form);
    const [showPoliticianModal, setShowPoliticianModal] = useState(false);
    const [mediaLibraryVisibility, setMediaLibraryVisibility] = useState(false);
    const [imageEditorVisibility, setImageEditorVisibility] = useState(false);
    const [mediaLibraryFile, setMediaLibraryFile] = useState(
        null as MediaLibraryFile | null
    );
    const [formOpen, setFormOpen] = useState(true);
    const [summaryOpen, setSummaryOpen] = useState(false);
    const [galleriesOpen, setGalleriesOpen] = useState(false);
    const { eventImageGroups, reportedEventImageGroups } = useSelector(
        (state: State) => state.events.eventImages
    );

    const [ showConfirmModal, setShowConfirmModal ] = useState( false )

    const [selectedImage, setSelectedImage] = useState(null as number | null);
    const [showInputModal, setShowInputModal] = useState(false);
    const [notificationValue, setNotificationValue] = useState("");
    const [showPreview, setShowPreview] = useState(false);
    const [previewEvent, setPreviewEvent] = useState(null as Event | null);

    const [ showSummaryModal, setShowSummaryModal ] = useState( false )

    const [infoModalVisibility, setInfoModalVisibility] = useState(false);
    const [infoModalTitle, setInfoModalTitle] = useState("");
    const [infoModalDescription, setInfoModalDescription] = useState("");

    const { from, to, count, page } = useSelector(
        (state: State) => state.events.eventImages.pagination
    );

    useEffect(() => {
        if (document.querySelector(`#leaflet-map`)?.childNodes.length !== 0) {
            return;
        }
        dispatch( { type: 'EVENTS_ADD_POLITICIAN', selectedPolitician: null } )
        /* @ts-ignore */
        window.map = L.map("leaflet-map").setView([47.497913, 19.040236], 13);
        /* @ts-ignore */
        L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(
            /* @ts-ignore */
            window.map
        );
    }, [dispatch]);

    useEffect(() => {
        updateMap();
    }, [lat, lng]);


    const [ showAchievementModal, setShowAchievementModal ] = useState( false )
    const [ achievementData, setAchievementData ] = useState( {} as any )

    const openAchievementModal = ( e: MouseEvent ) => {

        e.preventDefault()

        if ( !event || !event!.achievementGroup ) {

            setAchievementData( { type: 0, category: 4, activity: 14, showHeader: false, showTypeSelect: false, goldDesiredValue: 1, ...achievementData } )

        } else {

            setAchievementData( { showHeader: false, showTypeSelect: false, name: event!.achievementGroup!.name, description: event!.achievementGroup!.description, id: event!.achievementGroup!.id, goldExp: event!.achievementGroup!.goldAchievement.experience, goldImage: event!.achievementGroup!.goldAchievement.image, ...achievementData } )

        }

        setShowAchievementModal( true )

    }

    const onSaveAchievement = async ( data: any ) => {

        setAchievementData( data )
        setShowAchievementModal( false )

    }

    const updateMap = () => {
        // @ts-ignore
        if (window.marker !== undefined) {
            // @ts-ignore
            window.map.removeLayer(window.marker);
        }

        if (lat.value && lng.value) {
            // @ts-ignore
            window.marker = new L.Marker([lat.value, lng.value]);
            // @ts-ignore
            window.marker.addTo(window.map);
            // @ts-ignore
            window.map.panTo([lat.value, lng.value]);
        }
    };

    const onShowOnMapClick = async (event: MouseEvent) => {
        event.preventDefault();

        const coords = await getCoordinates(location.value);
        if (coords === null) {
            alert(`A megadott címet nem találtuk! Kérjük próbálja újra!`);
            return;
        }

        dispatch({ type: `EVENTS_UPDATE_FORM`, field: `lat`, value: coords.lat });
        dispatch({ type: `EVENTS_UPDATE_FORM`, field: `lng`, value: coords.lng });

        updateMap();
    };

    const updateForm = (field: string, value: any) => {
        if ( field === `description` && value.length >= 16777215 ) { return }
        if ( field === `startDate` && endDate.value !== `` && new Date( value ).getTime() > endDate.value )
        {
            dispatch({ type: `EVENTS_UPDATE_FORM`, field: `endDate`, value });
        }
        dispatch({ type: `EVENTS_UPDATE_FORM`, field, value });
    };

    const onSaveClick = async (event: MouseEvent) => {
        event.preventDefault();

        if (!checkFields()) {
            return;
        }

        if (isOnline.value === false) {

            const coords = await getCoordinates(location.value);
            if (coords === null) {
                setInfoModalTitle("Figyelmeztetés");
                setInfoModalDescription( "A megadott címet nem találtuk! Kérjük próbálja újra!");
                setInfoModalVisibility(true);
                return false
            }

            dispatch({ type: `EVENTS_UPDATE_FORM`, field: `lat`, value: coords.lat });
            dispatch({ type: `EVENTS_UPDATE_FORM`, field: `lng`, value: coords.lng });

        }

        if (await saveEvent( achievementData )) {
            navigate(`/esemenyek/lista`);
        }
    };

    const onBackClick = (event: MouseEvent) => {
        event.preventDefault();
        navigate(`/esemenyek/lista`);
    };

    const onDelete = async () => {

        await deleteFunc!()
        setShowConfirmModal( false )

    }

    const deleteImage = async (imageId: number) => {

        setDeleteFunc( () => {
            return async () => {
                await deleteEventImage(imageId);
                await getEventImages(id as number, true);
                await getEventImages(id as number, false, page);
            }
        } )
        setShowConfirmModal( true )

    };

    const deleteEventImageGroup = async (ids: number[]) => {
        setDeleteFunc( () => {
            return async () => {
                for (const id of ids) {
                    await deleteEventImage(id);
                }
                await getEventImages(id as number, true);
                await getEventImages(id as number, false, page);
            }
        } )
        setShowConfirmModal( true )
    };

    const unreportImages = async (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }

        await unreportEventImage(ids[0]);
        await getEventImages(id as number, true);
        await getEventImages(id as number, false, page);
    };

    const loadNextPage = async () => {
        await getEventImages(id as number, false, page + 1);
    };

    const loadPrevPage = async () => {
        await getEventImages(id as number, false, page - 1);
    };

    const onNotificationSave = async () => {
        await createNotification(id!, notificationValue);
        setNotificationValue(``);
        setShowInputModal(false);
        await getEvent(id!);
    };

    const onNotificationDelete = async (notificationId: number) => {

        setDeleteFunc( () => {

            return async () => {

                await deleteNotification(notificationId!)
                await getEvent(id!)

            }

        } )
        setShowConfirmDelete( true )

    };

    const onDeleteAchievement = async () => {

        event!.achievementGroup = null
        setAchievementData( {} )
        setShowAchievementModal( false )

    }

    const deleteMessage = () => {

        if ( deleteFunc !== null ) {

            deleteFunc()

        }
        setDeleteFunc( null )
        setShowConfirmDelete( false )

    }

    const preview = ( e: MouseEvent ) => {
        e.preventDefault()
        if (event === null) {
            setPreviewEvent({
                id: 0,
                tags: [],
                startDate: startDate.value,
                endDate: endDate.value,
                hostPoliticians: [ selectedPolitician! ],
                imageFile: imageFile.value,
                location: location.value,
                title: title.value,
                visibility: visibility.value,
                notifications: [],
                summary: null
            });
        } else {
            setPreviewEvent(event);
        }
        setShowPreview(true);
    };

    const checkFields = (): boolean => {
        if (startDate.value ===  "" ) {
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription("Az esemény kezdetének megadása kötelező!");
            setInfoModalVisibility(true);
            return false
        }

        if (title.value.trim().length === 0) {
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription( "Az esemény nevének megadása kötelező!");
            setInfoModalVisibility(true);
            return false
        }

        if (description.value.trim().length === 0) {
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription( "Esemény szövegének megadása kötelező!");
            setInfoModalVisibility(true);
            return false
        }

        if (isOnline.value === false && location.value.trim().length === 0) {
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription( "Az esemény címének megadása kötelező!");
            setInfoModalVisibility(true);
            return false
        }

        if (isOnline.value === true && (!liveUrl.value || liveUrl.value.trim().length === 0)) {
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription( "Az esemény online elérhetőségének megadása kötelező!");
            setInfoModalVisibility(true);
            return false
        }

        if ( hostPoliticians!.length === 0 ) {
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription( "Házigazda kiválasztása kötelező!");
            setInfoModalVisibility(true);
            return false
        }

        if (imageFile.value === null) {
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription( "Kép hozzáadása kötelező!");
            setInfoModalVisibility(true);
            return false
        }

        return true
    };

    return (
        <div className="event-details-details">
            <div className="toolbar">
                <button
                    className="button button--ghost back-button"
                    onClick={onBackClick}
                >
                    Vissza
                </button>
                {id === null ? null : (
                    <button className="button button--primary summary-button" onClick={ () => setShowSummaryModal( true ) }>Összefoglaló hozzáadása</button>
                ) }
                <button
                    className="button button--ghost preview-button"
                    onClick={ preview }
                >
                    Előnézet
                </button>
                <button className="button save-button" onClick={onSaveClick}>
                    Mentés
                </button>
            </div>

            {id === null ? null : (
                <div className="card-div stat-card-div">
                    <header>
                        <h2>Kiemelt statisztikai adatok</h2>

                        <NavLink to="#">Minden statisztika megtekintése</NavLink>
                    </header>

                    <div className="caption">A legfőbb adatok itt jelennek meg:</div>

                    <div className="info-slots">
                        <InfoSlot
                            additionalInfo="+46% felhasználóknak akik megnyitották az eseményt"
                            caption="Felhasználók, akik jelezték, hogy jelen lesznek"
                            color="green"
                            info={{ suffix: `fő`, value: `380` }}
                        />

                        <InfoSlot
                            additionalInfo="-12%-al kevesebb mint átlagosan"
                            caption="Összes felhasználó, aki látta"
                            color="red"
                            info={{ suffix: `fő`, value: `890` }}
                        />
                    </div>
                </div>
            )}

            {id === null ? null : (
                <div className="card-div notifications-card-div">
                    <header>
                        <h2>Esemény értesítései</h2>

                        <button
                            className="button"
                            onClick={(e) => setShowInputModal(true)}
                        >
                            + Új rövid üzenet
                        </button>
                    </header>

                    <div className="notifications">
                        {notifications.length === 0 ? (
                            <span>Nincsenek még értesítések</span>
                        ) : (
                            notifications.sort( ( a, b ) => b.created - a.created ).slice(0, 3).map((notification) => (
                                <div key={notification.id} className="notification">
                                    <div className="notification__text">
                                        {notification.notification}
                                    </div>

                                    <footer>
                                        <div className="notification__date">
                                            {format(`mm. d. h:m`, notification.created)}
                                        </div>

                                        <div
                                            className="notification__delete"
                                            onClick={(e) => onNotificationDelete(notification.id)}
                                        >
                                            <span className="material-symbols-outlined">
                                                delete
                                            </span>
                                            <span>Törlés</span>
                                        </div>
                                    </footer>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            )}

            {id === null ? null : (
                <div
                    className={`card-div galleries-card-div${
                        galleriesOpen ? ` galleries-card-div--open` : ``
                    }`}
                >
                    <header>
                        <h2>Esemény galéria</h2>
                    </header>

                    <div className="caption caption--review-needed">
                        Bejelentett jóváhagyandó képek
                    </div>

                    <div className="galleries galleries--review-needed">
                        {reportedEventImageGroups.length === 0 ? (
                            <span>Nincsenek jóváhagyandó képek</span>
                        ) : (
                            reportedEventImageGroups.map((eventImageGroup) => (
                                <div className="gallery gallery--review-needed">
                                    <div className="images">
                                        {eventImageGroup.eventImages.map((eventImage) => (
                                            <div
                                                className={`image${
                                                    eventImage.id === selectedImage ? ` selected` : ``
                                                }`}
                                                onClick={(e) => setSelectedImage(eventImage.id)}
                                            >
                                                <img alt="" src={eventImage.url} />
                                                <div
                                                    className="image__delete"
                                                    onClick={(e) => deleteImage(eventImage.id)}
                                                >
                                                    <span className="material-symbols-outlined">
                                                    delete
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <footer>
                  <span
                      className="material-symbols-outlined"
                      onClick={(e) =>
                          unreportImages(
                              eventImageGroup.eventImages.map(
                                  (eventImage) => eventImage.id
                              )
                          )
                      }
                  >
                    done
                  </span>

                                        <div>
                                            <div className="gallery__date">
                                                {format(`mm. d. h:m`, eventImageGroup.uploadedAt)}
                                            </div>

                                            <div className="gallery__owner">
                                                {eventImageGroup.uploadedBy}
                                            </div>
                                        </div>

                                        <span
                                            className="material-symbols-outlined"
                                            onClick={(e) =>
                                                deleteEventImageGroup(
                                                    eventImageGroup.eventImages.map(
                                                        (eventImage) => eventImage.id
                                                    )
                                                )
                                            }
                                        >
                    delete
                  </span>
                                    </footer>
                                </div>
                            ))
                        )}
                    </div>

                    <div className="caption">Megjelenő képek</div>

                    <div className="galleries">
                        {eventImageGroups.length === 0 ? (
                            <span>Még nincsenek megjelníthető képek</span>
                        ) : (
                            eventImageGroups.map((eventImageGroup) => (
                                <div className="gallery">
                                    <div className="images">
                                        {eventImageGroup.eventImages.map((eventImage) => (
                                            <div
                                                className={`image${
                                                    eventImage.id === selectedImage ? ` selected` : ``
                                                }`}
                                                onClick={(e) => setSelectedImage(eventImage.id)}
                                            >
                                                <img alt="" src={eventImage.url} />
                                                <div
                                                    className="image__delete"
                                                    onClick={(e) => deleteImage(eventImage.id)}
                                                >
                        <span className="material-symbols-outlined">
                          delete
                        </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <footer>
                                        <div>
                                            <div className="gallery__date">
                                                {format(`mm. d. h:m`, eventImageGroup.uploadedAt)}
                                            </div>

                                            <div className="gallery__owner">
                                                {eventImageGroup.uploadedBy}
                                            </div>
                                        </div>

                                        <span
                                            className="material-symbols-outlined"
                                            onClick={(e) =>
                                                deleteEventImageGroup(
                                                    eventImageGroup.eventImages.map(
                                                        (eventImage) => eventImage.id
                                                    )
                                                )
                                            }
                                        >
                    delete
                  </span>
                                    </footer>
                                </div>
                            ))
                        )}
                    </div>

                    <footer>
                        <button
                            className="button button--ghost"
                            onClick={(e) => setGalleriesOpen(true)}
                        >
                            Összes megjelenítése
                        </button>
                    </footer>

                    <div className="pagination">
                        <button
                            disabled={(from + 1 ?? 1) === 1}
                            onClick={(e) => loadPrevPage()}
                        >
                            <img src="/image/chevron-left.svg" />
                        </button>

                        <span>
            {from + 1 ?? 1} - {to ?? 1} / {count ?? 1}
          </span>

                        <button
                            disabled={(to ?? 1) === (count ?? 1)}
                            onClick={(e) => loadNextPage()}
                        >
                            <img src="/image/chevron-right.svg" />
                        </button>
                    </div>
                </div>
            )}

            {id === null ? null : (
                <div className={ `card-div summary-card-div${ summaryOpen ? ` summary-card-div--open` : `` }` }>
                    <header>
                        <h2>Esemény összefoglalója</h2>

                        <button onClick={(e) => setSummaryOpen(!summaryOpen)}>
                            <img
                                className={summaryOpen ? `rotated` : ``}
                                src="/image/chevron-right.svg"
                            />
                        </button>
                    </header>

                    <main>

                        { summary.value === null || summary.value.trim().length === 0 ? (

                            <span className="summary-empty">Még nem került összefoglaló hozzáadásra.</span>

                        ) : (

                            <span className="summary">{ summary.value }</span>

                        ) }

                    </main>
                </div>
            )}

            {id === null ? null : (
                <div className="card-div details-card-div">
                    <header>
                        <h2>Esemény részletei</h2>

                        <button onClick={(e) => setFormOpen(!formOpen)}>
                            <img
                                className={formOpen ? `rotated` : ``}
                                src="/image/chevron-right.svg"
                            />
                        </button>
                    </header>
                </div>
            )}

            <form className={`form${formOpen ? ` form--open` : ``}`}>
                    <div className="horizontal">
                        <div className="card-div">
                            <div className="column">
                                <label htmlFor="startDate" className="title-text-h2" >
                                    Esemény kezdete *
                                </label>
                                <DatePicker
                                    selected={
                                        startDate.value === `` ? null : new Date(startDate.value)
                                    }
                                    onChange={(date) => updateForm(`startDate`, date!.getTime())}
                                    showTimeSelect
                                    timeIntervals={1}
                                    minDate={new Date()}
                                    className={`${startDate.error.length > 0 ? `error` : ``}`}

                                    minTime={
                                        ( new Date( startDate.value ).getFullYear() === new Date().getFullYear() && new Date( startDate.value ).getMonth() === new Date().getMonth() && new Date( startDate.value ).getDate() === new Date().getDate() ) || !startDate.value ?
                                        new Date(
                                            new Date().getFullYear(),
                                            new Date().getMonth(),
                                            new Date().getDate(),
                                            new Date().getHours(),
                                            new Date().getMinutes()
                                        ) :
                                        new Date(
                                            new Date().getFullYear(),
                                            new Date().getMonth(),
                                            new Date().getDate(),
                                            0,
                                            0
                                        )
                                    }
                                    maxTime={
                                        new Date(
                                            new Date().getFullYear(),
                                            new Date().getMonth(),
                                            new Date().getDate(),
                                            23,
                                            59
                                        )
                                    }
                                    timeFormat="HH:mm"
                                    dateFormat="yyyy. MM. dd. HH:mm"
                                />
                            </div>

                            <div className="column">
                                <label htmlFor="endDate" className="title-text-h2">
                                    Esemény vége *
                                </label>

                                <DatePicker
                                    selected={
                                        endDate.value === null || endDate.value === `` ? null : new Date(endDate.value)
                                    }                      onChange={(date) => updateForm(`endDate`, date!.getTime())}
                                    className={`${endDate.error.length > 0 ? `error` : ``}`}

                                    showTimeSelect
                                    timeIntervals={1}
                                    minDate={
                                        startDate.value === ``
                                            ? new Date()
                                            : new Date(startDate.value)
                                    }                      minTime={
                                    new Date(startDate.value).getFullYear() ==
                                    new Date(endDate.value!).getFullYear() &&
                                    new Date(startDate.value).getMonth() ==
                                    new Date(endDate.value!).getMonth() &&
                                    new Date(startDate.value).getDate() ==
                                    new Date(endDate.value!).getDate()
                                        ? new Date(
                                            new Date(startDate.value).getFullYear(),
                                            new Date(startDate.value).getMonth(),
                                            new Date(startDate.value).getDate(),
                                            new Date(startDate.value).getHours(),
                                            new Date(startDate.value).getMinutes()
                                        )
                                        : new Date(
                                            new Date(endDate.value!).getFullYear(),
                                            new Date(endDate.value!).getMonth(),
                                            new Date(endDate.value!).getDate(),
                                            0,
                                            0
                                        )
                                }
                                    maxTime={
                                        new Date(startDate.value).getFullYear() ==
                                        new Date(endDate.value!).getFullYear() &&
                                        new Date(startDate.value).getMonth() ==
                                        new Date(endDate.value!).getMonth() &&
                                        new Date(startDate.value).getDate() ==
                                        new Date(endDate.value!).getDate()
                                            ? new Date(
                                                new Date(startDate.value).getFullYear(),
                                                new Date(startDate.value).getMonth(),
                                                new Date(startDate.value).getDate(),
                                                23,
                                                59
                                            )
                                            : new Date(
                                                new Date(endDate.value!).getFullYear(),
                                                new Date(endDate.value!).getMonth(),
                                                new Date(endDate.value!).getDate(),
                                                23,
                                                59
                                            )
                                    }
                                    timeFormat="HH:mm"
                                    dateFormat="yyyy. MM. dd. HH:mm"
                                />



                            </div>
                        </div>

                        <div className="card-div">
                            <div>
                                <h2 className="title-text-h2">
                                    Esemény láthatósága *
                                    {false ? (
                                        <span className="material-symbols-outlined info-icon">
                      info
                    </span>
                                    ) : null}
                                </h2>

                                <div className="check-box-group">
                                    <div className="input-group-div">
                                        <input
                                            checked={visibility.value === "0"}
                                            onChange={(e) => updateForm(`visibility`, "0")}
                                            type="radio"
                                            id="event_visibility_open"
                                            name="event_visibility"
                                        />
                                        <label
                                            className="label-text checkbox-label"
                                            htmlFor="event_visibility_open"
                                        >
                                            Külső
                                        </label>

                                        <input
                                            checked={visibility.value === "1"}
                                            onChange={(e) => updateForm(`visibility`, "1")}
                                            type="radio"
                                            id="event_visibility_internal"
                                            name="event_visibility"
                                        />
                                        <label
                                            className="label-text checkbox-label"
                                            htmlFor="event_visibility_internal"
                                        >
                                            Belső
                                        </label>

                                        <input
                                            checked={visibility.value === "2"}
                                            onChange={(e) => updateForm(`visibility`, "2")}
                                            type="radio"
                                            id="event_visibility_closed"
                                            name="event_visibility"
                                        />
                                        <label
                                            className="label-text checkbox-label"
                                            htmlFor="event_visibility_closed"
                                        >
                                            Zárt
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="horizontal">

                        <div className="card-div card-div--max-width">

                            <div className="column">

                                <h2 className="title-text-h2">Házigazda *</h2>

                                <div className="politicians-list">

                                    <div className="add-politician" onClick={ () => setShowPoliticianModal( true ) }>

                                        <img alt="" src="/image/add-blue.svg" />
                                        <span>Politikus hozzáadása</span>

                                    </div>

                                    { hostPoliticians!.map( politician => (

                                        <div className="host-politician">

                                            <img alt="" src={ politician.imageFile!.url } />
                                            <span>{ politician.lastName } { politician.firstName }</span>
                                            <MenuButton hasBackground={ true } items={ [ { id: 0, value: `Törlés`, icon: `trash.svg` } ] } onChange={ () => dispatch( { type: `EVENT_REMOVE_POLITICIAN`, id: politician.id } ) } />

                                        </div>

                                    ) ) }

                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="horizontal">

                        <div className="card-div" style={{ width: 405 }}>

                            <TagSelector tags={ tags } onTagAdd={ tag => dispatch( { type: `EVENT_ADD_TAG`, tag: tag } ) } onTagDelete={ tag => dispatch( { type: `EVENT_REMOVE_TAG`, tag: tag } ) } />

                        </div>

                        <div className="card-div card-div--achievement">

                            <div className="caption">Kitüntetés</div>
                            <button className="button button--ghost" onClick={ openAchievementModal }>Kitüntetés { !event?.achievementGroup && Object.keys(achievementData).length === 0 ? `hozzáadása` : `szerkesztése` }</button>

                        </div>

                        <div className="card-div" style={{ alignItems: `center`, flexDirection: `column`, textAlign: `center`, width: 300 }}>

                            <h2 className="title-text-h2" style={{ marginBottom: 10 }}>Kiemelt esemény</h2>
                            <button className={ `button ${ highlighted.value ? `button--primary` : `button--ghost` }` } onClick={ e => { e.preventDefault(); updateForm( `highlighted`, !highlighted.value ) } }>{ highlighted.value ? `Esemény kiemelve` : `Esemény kiemelése` }</button>

                        </div>

                    </div>

                    <div className="horizontal">
                        <div className="card-div" style={{ marginRight: 0, width: `100%` }}>
                            <div style={{ width: `100%` }}>
                                <h2 className="title-text-h2" style={{ marginBottom: 20 }}>
                                    Esemény tartalmi elemeinek megadása
                                </h2>

                                <div className="columns">
                                    <div className="column">

                                        <div className="image-section-container">
                                            <h3 className="title-text-h3 visibility-h2" style={ { marginBottom: "16px" } }>
                                                Kép hozzáadása *
                                                <img alt="" title="A képet a kiválasztásakor 16:9-es méretarányúra kell vágni. Ajánlott felbontás: 1280 x 720." src="/image/info.svg"/>
                                            </h3>

                                            <AddImage width={ "255px" } height={"148px"}
                                                imageUrl={ imageFile.value !== null ? imageFile.value.url : ""}
                                                text={"Kép hozzáadása (16:9)"}
                                                onAddImage={() => setMediaLibraryVisibility(true)}
                                                onDeleteImage={() => {
                                                    setMediaLibraryFile(null);
                                                    updateForm(`imageFile`, null);
                                                }}
                                            />
                                        </div>

                                    </div>

                                    <div
                                        className="column"
                                        style={{ width: `calc( 100% - 297px - 30px)` }}
                                    >
                                        <div className="text-section-container">
                                            <div className="input-container-header">
                                                <h3 className="title-text-h3 title-text-bottom-padding">
                                                    Az esemény neve *
                                                </h3>
                                                <span className="label-text info">
                          Ajánlott karakter szám maximum: 120 karakter
                        </span>
                                            </div>

                                            <TextField
                                                field={title}
                                                onChange={(e) => updateForm(`title`, e.target.value)}
                                                placeholder=""
                                                maxlength={255}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="columns">
                                    <div className="column" style={{ width: `100%` }}>
                                        <div className="text-section-container container-top-margin">
                                            <DescriptionTextArea
                                                title={"Esemény szövegének megadása *"}
                                                field={description}
                                                onChange={(text) => updateForm(`description`, text)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="columns">
                                    <div className="column" style={{ width: `100%` }}>

                                        <div className="text-section-container">

                                            <div className="input-container-header">

                                                <h3 className="title-text-h3 title-text-bottom-padding">
                                                    Online esemény?
                                                </h3>

                                                <div className="check-box-group">

                                                    <div className="input-group-div">

                                                        <input
                                                            checked={ isOnline.value === true }
                                                            onChange={(e) => updateForm(`isOnline`, true)}
                                                            type="radio"
                                                            id="is_online_online"
                                                            name="is_online"
                                                        />
                                                        <label
                                                            className="label-text checkbox-label"
                                                            htmlFor="event_visibility_open"
                                                        >
                                                            Online esemény
                                                        </label>

                                                        <input
                                                            checked={ isOnline.value === false }
                                                            onChange={(e) => updateForm(`isOnline`, false)}
                                                            type="radio"
                                                            id="is_online_not_online"
                                                            name="is_online"
                                                        />
                                                        <label
                                                            className="label-text checkbox-label"
                                                            htmlFor="event_visibility_open"
                                                        >
                                                            Fizikai esemény
                                                        </label>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div style={{ display: isOnline.value === true ? `flex` : `none` }} className="columns">
                                    <div className="column" style={{ width: `100%` }}>
                                        <div className="text-section-container">
                                            <div className="input-container-header">
                                                <h3 className="title-text-h3 title-text-bottom-padding">
                                                    Online stream elérhetősége
                                                </h3>
                                                <span className="label-text info">
                          Online élő közvetítés címér írd be a mezőbe, hogy azt
                          a felhasználók is elérhessék ha van
                        </span>
                                            </div>

                                            <TextField
                                                field={liveUrl}
                                                onChange={(e) => updateForm(`liveUrl`, e.target.value)}
                                                maxlength={255}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: isOnline.value === false ? `flex` : `none` }} className="columns">
                                    <div className="column" style={{ width: `100%` }}>
                                        <div className="text-section-container">
                                            <div className="input-container-header">
                                                <h3 className="title-text-h3 title-text-bottom-padding">
                                                    Az esemény címe *
                                                </h3>
                                                <span className="label-text info">&nbsp;</span>
                                            </div>

                                            <div className="shared-text-button-field">
                                                <TextField
                                                    field={location}
                                                    onChange={(e) =>
                                                        updateForm(`location`, e.target.value)
                                                    }
                                                    placeholder=""
                                                    maxlength={255}
                                                />
                                                <button className="button" onClick={onShowOnMapClick}>
                                                    Mutasd!
                                                </button>
                                            </div>

                                            <div id="leaflet-map"></div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="horizontal">
                        <footer>
                            <button className="button button--ghost preview-button" onClick={ preview }>
                                Előnézet
                            </button>
                            <button className="button save-button" onClick={onSaveClick}>
                                Mentés
                            </button>
                        </footer>
                    </div>
                </form>

            {infoModalVisibility ? (
                <MessageModal
                    title={infoModalTitle}
                    message={infoModalDescription}
                    onCloseClick={() => {
                        setInfoModalVisibility(false);
                    }}
                />
            ) : null}

            {showPoliticianModal ? (
                <PoliticianModal
                    onCloseClick={() => {
                        setShowPoliticianModal(false);
                    }}
                    onPoliticianSelected={ politician => {
                        dispatch( { type: `EVENT_ADD_POLITICIAN`, politician: politician } )
                    } }
                    onModalClose={() => setShowPoliticianModal(false)}
                />
            ) : null}

            {mediaLibraryVisibility ? (
                <MediaLibrary
                    onClose={() => setMediaLibraryVisibility(false)}
                    onMediaSelected={(file: MediaLibraryFile) => {
                        setMediaLibraryFile(file);
                        setMediaLibraryVisibility(false);
                        setImageEditorVisibility(true);
                    }}
                />
            ) : null}

            {imageEditorVisibility ? (
                <ImageEditor
                    mediaLibraryFile={mediaLibraryFile!}
                    aspectRatio={{ name: "16:9", ratio: 16 / 9 }}
                    onClose={() => setImageEditorVisibility(false)}
                    onReady={(file) => {
                        updateForm(`imageFile`, file);
                        setImageEditorVisibility(false);
                    }}
                />
            ) : null}

            {showInputModal ? (
                <InputModal
                    title="Új értesítés"
                    placeholder=""
                    field={{ error: "", required: true, value: notificationValue }}
                    onInputChange={(event) => {
                        setNotificationValue((event as any).target.value);
                    }}
                    onCloseClick={() => setShowInputModal(false)}
                    onSaveClick={onNotificationSave}
                    onModalClose={() => setShowInputModal(false)}
                />
            ) : null}

            {showPreview ? (
                <EventModal
                    event={previewEvent!}
                    onCloseClick={() => setShowPreview(false)}
                    onModalClose={() => setShowPreview(false)}
                />
            ) : null}

            { showConfirmDelete ? (

                <Modal className="confirm-delete-message-modal" onModalClose={ () => setShowConfirmDelete( false ) }>

                    <div className="modal-title">Értesítés törlése</div>
                    <div className="modal-description">Biztosan törlöd az értesítést?</div>
                    <footer>

                        <button className="button button--ghost" onClick={ () => setShowConfirmDelete( false ) }>Mégse</button>
                        <button className="button button--primary" onClick={ deleteMessage }>Törlés</button>

                    </footer>

                </Modal>

            ) : null }

            { showSummaryModal ? (

                <InputModal title="Összefoglaló megadása" placeholder="Esemény összefoglalója" field={ summary } onInputChange={ e => updateForm( `summary`, e.target.value ) } onModalClose={ () => setShowSummaryModal( false ) } onCloseClick={ () => setShowSummaryModal( false ) } onSaveClick={ () => setShowSummaryModal( false ) } />

            ) : null }

            { showConfirmModal ? (

                <Modal className="eventimage-confirm-modal" onModalClose={ () => setShowConfirmModal( false ) }>

                    <div className="modal-title">Biztosan törlöd az eseményhez tartozó galériát?</div>
                    <div className="modal-description">Ezt a műveletet nem lehet visszavonni! Biztosan törlöd?</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowConfirmModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ onDelete }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

            { showAchievementModal ? (

                <AchievementModal selectedAchievement={ achievementData } onClose={ () => setShowAchievementModal( false ) } onSave={ onSaveAchievement } onDelete={ onDeleteAchievement } />

            ) : null }

        </div>

    );

};
