import React, { MouseEvent, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import './quizes.page.scss'
import { SearchButton, SearchInput } from '../../../component/search'
import { QuizBlock } from '../../../component/quiz-block'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../state'
import { listQuizes } from '../../../action/quizes'
import { Modal } from '../../../component'

export const Quizes = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { stats } = useSelector( ( state: State ) => state.quizes )

    const [ showCreateQuiz, setShowCreateQuiz ] = useState( false )
    const [ quizSelected, setQuizSelected ] = useState( false )
    const [ testSelected, setTestSelected ] = useState( false )

    const search = async () => {

        await listQuizes()
        navigate( `/kvizek-es-tesztek/lista` )

    }

    const create = () => {

        if ( quizSelected ) {

            navigate( `/kvizek-es-tesztek/kvizek/uj` )

        } else {

            navigate( `/kvizek-es-tesztek/tesztek/uj` )

        }

    }

    const selectQuiz = () => {

        setQuizSelected( !quizSelected )
        setTestSelected( false )

    }

    const selectTest = () => {

        setQuizSelected( false )
        setTestSelected( !testSelected )

    }

    const toggleArchive = () => {

        dispatch( { type: `TOGGLE_QUIZ_ARCHIVE` } )
        search()

    }

    const setKeyword = ( keyword: string ) => {

        dispatch( { type: `SET_QUIZ_KEYWORD`, keyword } )

    }

    return (

        <main className="page page--quizes">

            <div className="main-div">

                <div className="navigation-title">

                    <h1 className="title-text-h1">Kvízek áttekintése</h1>

                </div>

                <div className="toolbar">

                    <SearchInput placeholder="Keresés a kvízek és tesztek között" onChange={ e => setKeyword( e.target.value ) } onSearch={ search } />
                    <SearchButton onClick={ search } />
                    <button className="button button--primary" onClick={ toggleArchive }>Archív kvízek</button>
                    <button className="button button--primary" onClick={ () => setShowCreateQuiz( true ) }>+ Új kvíz</button>

                </div>

                <div className="card-div">

                    <div className="columns">

                        <div className="column">

                            <QuizBlock caption="Legutóbbi" quiz={ stats.newest } />

                        </div>

                        <div className="separator"></div>

                        <div className="column">

                            <QuizBlock caption="Legtöbb kitöltés" quiz={ stats.mostAnswer } />

                        </div>

                        <div className="separator"></div>

                        <div className="column">

                            <QuizBlock caption="Legkevesebb kitöltés" quiz={ stats.leastAnswer } />

                        </div>

                    </div>

                </div>

                <div className="card-div list-button-card-div">

                    <NavLink to="/kvizek-es-tesztek/lista">

                        <div className="list-button">

                            <img alt="" className="list-button__image" src="/image/hungary.svg" />
                            <div className="list-button__panel">

                                <div className="list-button__title">Országos kvízek és tesztek</div>
                                <div className="list-button__info">{ stats.courses } kvíz / teszt • { stats.answers } kitöltés</div>

                            </div>

                        </div>

                    </NavLink>

                </div>

            </div>

            { showCreateQuiz ? (

                <Modal className="create-quiz-modal" onModalClose={ () => setShowCreateQuiz( false ) }>

                    <div className="modal-header">

                        <div className="modal-title">Új kvíztipus kiválasztása</div>

                    </div>

                    <div className="modal-body">

                        <div className={ `option ${ quizSelected ? `option--selected` : `` }` } onClick={ selectQuiz }>Kvíz</div>
                        <div className={ `option ${ testSelected ? `option--selected` : `` }` } onClick={ selectTest }>Teszt</div>

                    </div>

                    <div className="modal-footer">

                        <button className="button button--primary" disabled={ !quizSelected && !testSelected } onClick={ create }>Módosítások mentése</button>

                    </div>

                </Modal>

            ) : null }

        </main>

    )

}