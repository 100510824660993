import { SeasonsState } from '../state'
import { message } from '../helper'

const initialEventsState: SeasonsState = {

    active: null,
    previous: [],
    next: []

}

export const seasonsReducer = ( state: SeasonsState = initialEventsState, action: any ): SeasonsState => {

    switch ( action.type ) {

        case `LIST_SEASONS_OK`:
            return { ...state, next: action.next, active: action.active, previous: action.previous }

        case `MODIFY_SEASON_OK`:
            message( `A szezon sikeresen módosításra került!` )
            return state

        case `CREATE_SEASON_OK`:
            message( `A szezon sikeresen létrehozásra került!` )
            return state

        default:
            return state

    }

}
