import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetQuestionnairesDTO } from '../../dto/questionnaires'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { getQuestionnairesStats } from './get-questionnaires-stats.action'
import { getQuestionnaires } from './get-questionnaires.action'
import { MODIFY_QUESTIONNAIRE_STATUS_ACTIONS } from './questionnaires.constants'


export const modifyQuestionnaireStatus = async (questionnaireIds: number[], statusId: number) => {

    store.dispatch( { type: MODIFY_QUESTIONNAIRE_STATUS_ACTIONS.MODIFY_QUESTIONNAIRE_STATUS_STARTED } )

    const body =  JSON.stringify( {
        questionnaireIds: questionnaireIds,
        statusId: statusId
    } )

    const promise = createCall( `questionnaires/modify-status`, BASE_METHODS.POST, body )
    promise.then(async response => {
        let responseBody: GetQuestionnairesDTO

        try {
            responseBody = plainToClass( GetQuestionnairesDTO, JSON.parse( await response!.text() ) )
        } catch ( exception ) {
            store.dispatch( { type: MODIFY_QUESTIONNAIRE_STATUS_ACTIONS.MODIFY_QUESTIONNAIRE_STATUS_INVALID_JSON } )
            return
        }
    
        if ( ( await validate( responseBody ) ).length > 0 ) {
            store.dispatch( { type: MODIFY_QUESTIONNAIRE_STATUS_ACTIONS.MODIFY_QUESTIONNAIRE_STATUS_INVALID_RESPONSE } )
            return
        }
    
        store.dispatch( { type: MODIFY_QUESTIONNAIRE_STATUS_ACTIONS.MODIFY_QUESTIONNAIRE_STATUS_OK, questionnaires: responseBody.questionnaires } )

        getQuestionnaires()

        getQuestionnairesStats()
    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })

}
