import { store } from '../../store'

export const moveFile = async ( type: string, movableId: number, moveToId: number ) => {

    let response: Response

    try {
        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/media-library/move/${ type }/${ movableId }/${ moveToId }`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: `POST`
        } )

    } catch ( exception ) {
        store.dispatch( { type: 'MEDIA_LIBRARY_MOVE_FILE_TIMEOUT' } )
        return
    }

    if ( response.status === 401 ) {
        store.dispatch( { type: 'MEDIA_LIBRARY_MOVE_FILE_UNAUTHORIZED' } )
        return
    }

    if ( response.status !== 200 ) {
        store.dispatch( { type: 'MEDIA_LIBRARY_MOVE_FILE_UNEXPECTEDRESPONSE' } )
        return
    }

    store.dispatch( { type: 'MEDIA_LIBRARY_MOVE_FILE_OK' } )

}
