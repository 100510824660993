import { Expose } from 'class-transformer'
import { IsDefined } from 'class-validator'
import { Prize } from '../../entity'

export class ListPrizesDTO {

    @Expose()
    @IsDefined()
    prizes!: Prize[]


}

