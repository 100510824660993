import { VideosState } from '../state'
import { message } from '../helper'

const initialState: VideosState = {

    video: null,
    highlightedVideo: null,
    videos: [],
    videoCategories: [],
    keyword: ``,
    pagination: {
        page: 1,
        from: 1,
        to: 1,
        count: 1
    },
    order: {
        by: `id`,
        direction: `ASC`
    }

}

export const videosReducer = ( state: VideosState = initialState, action: any ): VideosState => {

    let answerIndex

    switch ( action.type ) {

        case `SET_VIDEO_KEYWORD`:
            return { ...state, keyword: action.keyword }

        case `VIDEO_ORDER_CHANGE`:
            return { ...state, order: { by: action.by, direction: action.direction } }


        case `LIST_VIDEOS_OK`:
            return { ...state, videos: action.videos, pagination: action.pagination, highlightedVideo: action.highlightedVideo }


        case `GET_VIDEO_OK`:
            if ( action.video === null ) {
                action.video = {
                    isHighlighted: false,
                    isDraft: true,
                    description: ``,
                    lead: ``,
                    quality: ``,
                    category: state.videoCategories.length === 0 ? null : state.videoCategories[ 0 ],
                    image: null,
                    relatedPoliticians: [],
                    experience: 0,
                    quizExperience: 0,
                    visibleFrom: new Date().getTime() / 1000,
                    visibleTo: null,
                    tags: [],
                    question: {
                        id: 0,
                        question: ``,
                        answers: [
                            { id: 0, answer: ``, isSolution: true },
                            { id: 1, answer: ``, isSolution: false },
                            { id: 2, answer: ``, isSolution: false },
                            { id: 3, answer: ``, isSolution: false }
                        ]
                    }
                }
            }
            return { ...state, video: action.video }

        case `UPDATE_VIDEO`:
            const video: any = { ...state.video! }
            video[ action.field ] = action.value
            return { ...state, video: video }

    case `VIDEO_ADD_TAG`:
        if ( state.video!.tags.find( t => t.id === action.tag.id ) === undefined ) state.video!.tags.push( action.tag )
        return { ...state, video: { ...state.video!, tags: state.video!.tags } }

    case `VIDEO_REMOVE_TAG`:
        state.video!.tags = state.video!.tags.filter( t => t.id !== action.tag.id )
        return { ...state, video: { ...state.video!, tags: state.video!.tags } }


        case `LIST_VIDEO_CATEGORIES_OK`:
            return { ...state, videoCategories: action.videoCategories }


        case `VIDEO_UPDATE_QUIZ_SOLUTION`:
            state.video!.question.answers!.forEach( a => a.isSolution = false )
            answerIndex = state.video!.question.answers!.findIndex( a => a.id === action.id );
            state.video!.question.answers![ answerIndex ].isSolution = true
            return { ...state, video: { ...state.video!, question: { ...state.video!.question, answers: [ ...state.video!.question.answers! ] } } }

        case `VIDEO_CHANGE_ANSWER`:
            answerIndex = state.video!.question.answers!.findIndex( a => a.id === action.id );
            state.video!.question.answers![ answerIndex ].answer = action.answer
            return { ...state, video: { ...state.video!, question: { ...state.video!.question, answers: [ ...state.video!.question.answers! ] } } }

        case `VIDEO_CHANGE_QUESTION`:
            return { ...state, video: { ...state.video!, question: { ...state.video!.question, question: action.question } } }

        /*
        case `CREATE_QUIZ_QUESTION`:
            const newQuizQuestion: any = {
                id: uuid(),
                type: 0,
                isNew: true,
                answers: [
                    { id: uuid(), isNew: true, tags: [], answer: ``, image: null, isSolution: true }, { id: uuid(), isNew: true, tags: [], answer: ``, image: null, isSolution: false }, { id: uuid(), isNew: true, tags: [], answer: ``, image: null, isSolution: false }, { id: uuid(), isNew: true, tags: [], answer: ``, image: null, isSolution: false }
                ]
            }
            return { ...state, lesson: { ...state.lesson!, quiz: { ...state.lesson!.quiz, questions: [ ...state.lesson!.quiz.questions, newQuizQuestion ] } } }



        case `QUIZ_ANSWER_ADD_TAG`:
            const quizQuestionIndex3 = state.lesson!.quiz.questions.findIndex( q => q.id === action.questionId );
            const quizAnswerIndex2 = state.lesson!.quiz.questions[ quizQuestionIndex3 ].answers.findIndex( a => a.id === action.id );
            if ( ( state.lesson!.quiz.questions[ quizQuestionIndex3 ].answers[ quizAnswerIndex2 ] as any ).tags.find( ( t: any ) => t.id === action.tag.id ) === undefined ) {
                ( state.lesson!.quiz.questions[ quizQuestionIndex3 ].answers[ quizAnswerIndex2 ] as any ).tags.push( action.tag )
            }
            return { ...state, lesson: { ...state.lesson!, quiz: { ...state.lesson!.quiz, questions: [ ...state.lesson!.quiz.questions ] } } }

        case `QUIZ_ANSWER_REMOVE_TAG`:
            const quizQuestionIndex4 = state.lesson!.quiz.questions.findIndex( q => q.id === action.questionId );
            const quizAnswerIndex3 = state.lesson!.quiz.questions[ quizQuestionIndex4 ].answers.findIndex( a => a.id === action.id );
            state.lesson!.quiz.questions[ quizQuestionIndex4 ].answers[ quizAnswerIndex3 ].tags = state.lesson!.quiz.questions[ quizQuestionIndex4 ].answers[ quizAnswerIndex3 ].tags.filter( t => t.id !== action.tag.id )
            return { ...state, lesson: { ...state.lesson!, quiz: { ...state.lesson!.quiz, questions: [ ...state.lesson!.quiz.questions ] } } }

        case `UPDATE_QUIZ_ANSWER`:
            const quizQuestionIndex2 = state.lesson!.quiz.questions.findIndex( q => q.id === action.questionId );
            const quizAnswerIndex = state.lesson!.quiz.questions[ quizQuestionIndex2 ].answers.findIndex( a => a.id === action.id );
            ( state.lesson!.quiz.questions[ quizQuestionIndex2 ].answers[ quizAnswerIndex ] as any )[ action.field ] = action.value
            return { ...state, lesson: { ...state.lesson!, quiz: { ...state.lesson!.quiz, questions: [ ...state.lesson!.quiz.questions ] } } }

        case `DELETE_QUIZ_QUESTION`:
            return { ...state, lesson: { ...state.lesson!, quiz: { ...state.lesson!.quiz, questions: [ ...state.lesson!.quiz.questions.filter( q => q.id !== action.id ) ] } } }
        */

        case `VIDEO_ADD_POLITICIAN`:
            const politicians = state.video?.relatedPoliticians!
            if ( !politicians.find( p => p.id === action.politician.id ) ) { politicians.push( action.politician ) }
            return { ...state, video: { ...state.video!, relatedPoliticians: [ ...politicians ] } }

        case `VIDEO_REMOVE_POLITICIAN`:
            return { ...state, video: { ...state.video!, relatedPoliticians: state.video!.relatedPoliticians.filter( p => p.id !== action.id ) } }


        case `SAVE_VIDEO_OK`:
            message( `A videó sikeresen mentésre került!` )
            return state

        case `DELETE_VIDEO_OK`:
            message( `A videó sikeresen törlésre került!`, `Sikeres törlés` )
            return state


        case `DELETE_VIDEO_CATEGORY_ERROR`:
            message( `A kategória törlése sikertelen volt, mert a kategória tartalmaz videókat!`, `Hiba` )
            return state

        case `SAVE_VIDEO_CATEGORY_OK`:
            message( `A kategória sikeresen mentésre került!` )
            return state

        case `DELETE_VIDEO_CATEGORY_OK`:
            message( `A kategória sikeresen törlésre került!`, `Sikeres törlés` )
            return state


        default:
            return state

    }

}
