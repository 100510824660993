import { Expose } from 'class-transformer'
import { IsDefined, IsNumber, IsString } from 'class-validator'

export class ImageFile {

    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number 

    @Expose()
    @IsDefined()
    @IsString()
    url!: string

    @Expose()
    @IsDefined()
    @IsString()
    blurHash!: string

    @Expose()
    @IsDefined()
    @IsNumber()
    width!: string

    @Expose()
    @IsDefined()
    @IsNumber()
    height!: string

}

