import { Expose, Type } from 'class-transformer'
import { ValidateNested } from 'class-validator'
import { PoliticianQuestion } from '../../entity'

export class GetPoliticianQuestionsDTO {

    @Expose()
    @ValidateNested()
    @Type( () => PoliticianQuestion )
    questions!: PoliticianQuestion[]

}