import { LoaderFunctionArgs } from 'react-router-dom'
import { getAllNews, getNewsStats } from '../../action'
import { getNewsCategories } from '../../action/news-category'
import { NEWS_ACTIONS } from '../../action/news/news.constants'
import { store } from '../../store'
import { getPoliticians } from '../../action/politician'

export const newsListLoader = async ( args: LoaderFunctionArgs ) => {

    store.dispatch( { type: NEWS_ACTIONS.GET_NEWS_SEARCH, search: "" }  )

    await getPoliticians()
    await getNewsStats()
    await getAllNews() 
    await getNewsCategories() 

}
