import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetUsersDTO } from '../../dto'
import { store } from '../../store'

export const getUsers = async ( userGroup: number[] = [ 1 ], page: number = 1 ) => {

    let body: GetUsersDTO
    let response: Response

    try {

        const searchTerm = store.getState().users.searchTerm
        const order = store.getState().users.order

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/users/?page=${ page }&userGroup=${ userGroup }&order_by=${ order.by }&order_direction=${ order.direction }${ searchTerm.length !== 0 ? `&searchTerm=${ searchTerm }` : `` }`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'get'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'GET_USERS_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'GET_USERS_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'GET_USERS_UNEXPECTEDRESPONSE' } )
        return

    }

    try {

        body = plainToClass( GetUsersDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'GET_USERS_INVALIDJSON' } )
        return

    }

    if ( ( await validate( body ) ).length > 0 ) {

        store.dispatch( { type: 'GET_USERS_INVALIDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'GET_USERS_OK', users: body.users, pagination: body.pagination } )

}
