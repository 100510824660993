import { MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteQuestionnaireTag } from "../../../../action/questionnaires";
import {
  CREATE_QUESTIONNAIRE_ACTIONS,
  CREATE_QUESTIONNAIRE_TAG_ACTIONS,
} from "../../../../action/questionnaires/questionnaires.constants";
import { QuestionnaireTag } from "../../../../entity";
import { State } from "../../../../state";
import "./questionnaire-tag-modal-element.scss";

interface QuestionnaireTagModalElementtProps {
  questionnaireTagElement: QuestionnaireTag;
}

export const QuestionnaireTagModalElement = (
  props: QuestionnaireTagModalElementtProps
) => {
  const dispatch = useDispatch();
  const selectedTagId = useSelector(
    (state: State) => state.createQuestionnaireTag.form.id
  );

  const { tags } = useSelector(
    (state: State) => state.createQuestionnaire.form
  );

  const onDeleteButtonClick = async (event: MouseEvent) => {
    event.preventDefault();
    await deleteQuestionnaireTag(props.questionnaireTagElement.id);
    dispatch({
      type: CREATE_QUESTIONNAIRE_TAG_ACTIONS.CREATE_QUESTIONNAIRE_TAG_CLEAR,
    });
  };

  const onItemSelectClick = async (event: MouseEvent) => {
    event.preventDefault();
    dispatch({
      type: CREATE_QUESTIONNAIRE_TAG_ACTIONS.CREATE_QUESTIONNAIRE_TAG_MODIFY_TAG,
      questionnaireTag: props.questionnaireTagElement,
    });
  };

  const onAddTitleClick = (event: MouseEvent) => {
    event.preventDefault();
    tags.map((tg) => tg.id).includes(props.questionnaireTagElement.id)
      ? dispatch({
          type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_REMOVE_TAG,
          selectedTagId: props.questionnaireTagElement.id,
        })
      : dispatch({
          type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_ADD_TAG,
          selectedTag: props.questionnaireTagElement,
        });
  };

  return (
    <li
      key={props.questionnaireTagElement.tag}
      className={`questionnaire-tag-list__questionnaire-tag-element ${
        selectedTagId == props.questionnaireTagElement.id ? `active` : ``
      }`}
      onClick={onAddTitleClick}
    >
      <span className="label-text name">
        {props.questionnaireTagElement.tag}
      </span>
      {tags.map((tg) => tg.id).includes(props.questionnaireTagElement.id) ? (
        <img
          className="check"
          alt={"Tiltulus pipa ikon"}
          src="/image/pin.svg"
        />
      ) : null}
      <img
        className="delete"
        onClick={onItemSelectClick}
        alt={"Tiltulus szerkesztése ikon"}
        src="/image/edit.svg "
      />
      <img
        className="delete"
        onClick={onDeleteButtonClick}
        alt={"Tiltulus törlés ikon"}
        src="/image/trash.svg "
      />
    </li>
  );
};
