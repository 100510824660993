
import { Expose, Type } from 'class-transformer'
import { IsDefined, IsNumber, IsOptional, ValidateNested } from 'class-validator'
import { Questionnaire } from '../../entity'

export class GetQuestionnaireStatsDTO {

    @IsOptional()
    @ValidateNested()
    @Type( () => Questionnaire )
    lastQuestionnaire?: Questionnaire | null

    @IsOptional()
    @ValidateNested()
    @Type( () => Questionnaire )
    mostFillingQuestionnaire?: Questionnaire | null

    @IsOptional()
    @ValidateNested()
    @Type( () => Questionnaire )
    lastFillingQuestionnaire?: Questionnaire | null

    @Expose()
    @IsDefined()
    @IsNumber()
    questionnaireCount!: number 

    @Expose()
    @IsDefined()
    @IsNumber()
    fillingCount!: number 

}
