import { useState } from 'react'
import './tag-group.scss'
import { Tag } from '../tag/tag'
import { Tag as TagEntity } from '../../../entity'

interface TagGroupProps {

    id: number
    name: string
    tags: TagEntity[]

    keyword?: string
    onEdit?: () => void
    onEditTag?: ( id: number ) => void

    onTagClick?: ( tag: TagEntity ) => void

    selectedTags?: TagEntity[]

}

export const TagGroup = ( props: TagGroupProps ) => {

    const [ open, setOpen ] = useState( false )
    const onEdit = () => { if ( props.onEdit ) props.onEdit() }
    const onEditTag = ( id: number ) => { if ( props.onEditTag ) props.onEditTag( id ) }
    const onTagClick = ( tag: TagEntity ) => { if ( props.onTagClick ) props.onTagClick( tag ) }

    return (

        <div className={ `tag-group ${ open ? `tag-group--open` : `` }` }>

            <div className="tag-group__header" onClick={ () => setOpen( !open ) }>

                <div className="tag-group__header__name">{ props.name }</div>
                { props.onEdit ? (
                    <img alt="" className="tag-group__header__edit" src="/image/edit.svg" onClick={ onEdit } />
                ) : null }
                <img alt="" className="tag-group__header__open" src="/image/chevron-left.svg" />

            </div>

            <div className="tag-group__tags">

                { props.tags.filter( tag => !props.keyword || tag.name.toLowerCase().indexOf( props.keyword.toLowerCase() ) !== -1 ).map( tag => ( props.onEditTag ?

                        <Tag key={ tag.id } { ...tag } onEditTag={ () => onEditTag( tag.id ) } />

                        :

                        <Tag key={ tag.id } { ...tag } onClick={ () => onTagClick( tag ) } selected={ props.selectedTags && props.selectedTags.find( t => tag.id === t.id ) !== undefined } />

                ) ) }

            </div>

        </div>

    )

}