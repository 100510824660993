import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { GET_PUSH_SETTINGS_ACTIONS } from './push.constants'
import { GetPushSettingsDTO } from '../../dto/push'

export const getPushSettings = async () => {
    const promise = createCall(`firebase/push-settings`, BASE_METHODS.GET)
    promise.then(async (response) => {
        let body: GetPushSettingsDTO

        try {
            body = plainToClass(GetPushSettingsDTO, JSON.parse(await response!.text()))
        } catch (exception) {
            store.dispatch({ type: GET_PUSH_SETTINGS_ACTIONS.GET_PUSH_SETTINGS_INVALID_JSON })
            return
        }

        if ((await validate(body)).length > 0) {
            store.dispatch({ type: GET_PUSH_SETTINGS_ACTIONS.GET_PUSH_SETTINGS_INVALID_RESPONSE })
            return
        }

        store.dispatch({ type: GET_PUSH_SETTINGS_ACTIONS.GET_PUSH_SETTINGS_OK, pushSettings: body.pushSettings })
    })

    promise.catch((error) => {
        store.dispatch({ type: error })
    })
}
