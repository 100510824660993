import { useSelector } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import { NewsCard } from '../../component/news'
import { State } from '../../state'
import './news.page.scss'

interface NewsProps { }

export const News = ( props: NewsProps ) => {

    const { highlightedNews, mostReadedNews, lastReadedNews, newsCount, userCount } = useSelector( ( state: State ) => state.newsStats )

    return (
        <main className="page page-news">
            <div className="main-div">
               
                <div className="navigation-title">
                    <h1 className="title-text-h1">Hírek áttekintése</h1>
                </div>

                <div className="container top-container">
                    <div className="card-div">

                        <div className="sub-container">
                            <h2 className="title-text-h2">Kiemelt hírek</h2>
                            <h3 className="title-text-h3 title-text-h3-margin">Országos kiemelt hír</h3>

                            { highlightedNews == null ?  
                                <NewsCard newsElement={ highlightedNews } />
                                :
                                <NavLink to={ `/hirek/${ highlightedNews?.id }` }>
                                    <NewsCard newsElement={ highlightedNews } />
                                </NavLink>
                            }
                        </div>

                        <div className="separator"></div>

                        <div className="sub-container">
                            <h2 className="title-text-h2">Legolvasottabb hír</h2>
                            <h3 className="title-text-h3 title-text-h3-margin">Ezt a hírt olvasták a legtöbben</h3>

                            { mostReadedNews == null ?                                     
                                <NewsCard newsElement={ mostReadedNews } />
                                :              
                                <NavLink to={ `/hirek/${ mostReadedNews?.id }` }>
                                    <NewsCard newsElement={ mostReadedNews } />
                                </NavLink>
                            }
                        </div>

                        <div className="separator"></div>

                        <div className="sub-container">
                            <h2 className="title-text-h2">Legkevesebben olvasták</h2>
                            <h3 className="title-text-h3 title-text-h3-margin">Ezt a hírt olvasták a legkevesebben</h3>

                            { lastReadedNews == null ?  
                                <NewsCard newsElement={ lastReadedNews } />
                                :
                                <NavLink to={ `/hirek/${ lastReadedNews?.id }` }>
                                    <NewsCard newsElement={ lastReadedNews } />
                                </NavLink>
                            }
                        </div>

                    </div>
                </div>

                <Link to="lista" className="nav_decoration" style= {{textDecoration:'none'}} >
                    <div className="container news_category_container">
                        <div className="card-div card_container">

                            <img className="image" alt="Hírek áttekintése országos hírek logója" src="/image/hungary.svg"/>

                            <div className="sub-container text_container">
                                <h2 className="title-text-h2">Országos hírek</h2>
                                <span className="label-text description">{newsCount} aktív hír • {userCount} olvasó</span>
                            </div>

                        </div>
                    </div>
                </Link>
              
            </div>
        </main>
    )
}



