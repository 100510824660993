import { LoaderFunctionArgs } from 'react-router-dom'
import { store } from '../../store'
import { getCourse, listCourseTopics } from '../../action/courses'
import { listTags } from '../../action/tags'
import { getPoliticians } from '../../action/politician'
import { listDefaultExperiencePoints } from "../../action/gamification"

export const courseLoader = async ( args: LoaderFunctionArgs ) => {

    await listDefaultExperiencePoints()

    await getPoliticians()

    await listTags()

    await listCourseTopics()

    if ( args.params.id ) {

        await getCourse( parseInt( args.params.id ) )

    } else {

        store.dispatch( {
            type: `GET_COURSE_OK`,
            course: null,
            experience: store.getState().gamification.defaultExperiencePoints.find( d => d.name === `courseanswer` )?.experience || 10
        } )

    }

}