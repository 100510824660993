import { Outlet } from 'react-router-dom'
import './login.layout.scss'

export const LoginLayout = () => {

    return (

        <div className="layout layout--login">

            <div className="background"></div>
            <Outlet />

        </div>

    )

}
