import './course-topic.scss'

interface CourseTopicProps {

    color: string
    name: string

}

export const CourseTopic = ( props: CourseTopicProps ) => {

    return (

        <div className="course-topic" style={{ backgroundColor: props.color }}>

            { props.name }

        </div>

    )

}