import { DELETE_POLITICIAN_ACTIONS, GET_POLITICIAN_STATS_ACTIONS } from '../../action/politician/politicians.constants'
import {
  PoliticianBiographyItem,
  PoliticianPhrase,
  PoliticianQuestion,
} from "../../entity";
import { CreatePoliticianState } from "../../state/politicians";
import { message } from '../../helper'
import { stat } from 'fs'

const initialCreatePoliticianState: CreatePoliticianState = {
  form: {
      tags: [],
      quotes: [],
    id: null,
      experience: {
          error: '',
          value: '',
          required: true
      },
      quizExperience: {
          error: '',
          value: '',
          required: true
      },
      isVisible: true,
    firstName: {
      error: "",
      value: "",
      required: true,
    },
    lastName: {
      error: "",
      value: "",
      required: true,
    },
    innerPosition: {
      error: "",
      value: "",
      required: false,
    },
    position: {
      error: "",
      value: "",
      required: false,
    },
    shadowGovernmentPosition: {
      error: "",
      value: "",
      required: false,
    },
    description: {
      error: "",
      value: "",
      required: true,
    },
    imageUrl: "",
    mediaLibraryFile: null,
    biographyItems: null,
    phrases: [],
    titles: [],
    questions: null,
    question: {
      error: "",
      value: "",
      required: true,
      correctAnswerId: 1,

      answer1: {
        error: "",
        value: "",
        required: true,
      },
      answer2: {
        error: "",
        value: "",
        required: true,
      },
      answer3: {
        error: "",
        value: "",
        required: true,
      },
      answer4: {
        error: "",
        value: "",
        required: true,
      },
    },
  },
  saveDate: null,
  politician: null,

  stats: {
    openCount: 0,
    sendQuestionCount: 0,
    openPercent: 0,
  },
};

export const createPoliticianReducer = (
  state: CreatePoliticianState = initialCreatePoliticianState,
  action: any
): CreatePoliticianState => {
  switch (action.type) {
      case `POLITICIAN_CHANGE_EXPERIENCE`:
          if ( isNaN( action.experience ) ) { return state }
          if ( action.experience === `` ) { action.experience = `0` }
          return { ...state, form: { ...state.form, experience: { ...state.form.experience, value: action.experience } } }
      case `POLITICIAN_CHANGE_QUIZ_EXPERIENCE`:
          if ( isNaN( action.quizExperience ) ) { return state }
          if ( action.quizExperience === `` ) { action.quizExperience = `0` }
          return { ...state, form: { ...state.form, quizExperience: { ...state.form.quizExperience, value: action.quizExperience } } }
    case "CREATE_POLITICIAN_CHANGE_MEDIA_LIBRARY_FILE":
      return {
        ...state,
        form: {
          ...state.form,
          mediaLibraryFile: action.mediaLibraryFile,
          imageUrl: action.mediaLibraryFile ? action.mediaLibraryFile.url : "",
        },
      };
    case "CREATE_POLITICIAN_CHANGE_FIRST_NAME":
      return {
        ...state,
        form: {
          ...state.form,
          firstName: { ...state.form.firstName, value: action.firstName },
        },
      };
    case "CREATE_POLITICIAN_CHANGE_LAST_NAME":
      return {
        ...state,
        form: {
          ...state.form,
          lastName: { ...state.form.firstName, value: action.lastName },
        },
      };
    case "CREATE_POLITICIAN_CHANGE_INNER_POSITION":
      return {
        ...state,
        form: {
          ...state.form,
          innerPosition: {
            ...state.form.innerPosition,
            value: action.innerPosition,
          },
        },
      };
    case "CREATE_POLITICIAN_CHANGE_POSITION":
      return {
        ...state,
        form: {
          ...state.form,
          position: { ...state.form.position, value: action.position },
        },
      };
    case "CREATE_POLITICIAN_CHANGE_SHADOW_GOVERNMENT_POSITION":
      return {
        ...state,
        form: {
          ...state.form,
          shadowGovernmentPosition: {
            ...state.form.shadowGovernmentPosition,
            value: action.shadowGovernmentPosition,
          },
        },
      };
    case "CREATE_POLITICIAN_ADD_TITLE":
      if (
        state.form.titles.some((item) => action.selectedTitle.id === item.id)
      ) {
        return state;
      }
      return {
        ...state,
        form: {
          ...state.form,
          titles: [...state.form.titles, action.selectedTitle],
        },
      };
    case "CREATE_POLITICIAN_REMOVE_TITLE":
      return {
        ...state,
        form: {
          ...state.form,
          titles: state.form.titles.filter(
            (item) => action.selectedTitleId !== item.id
          ),
        },
      };
    case "CREATE_POLITICIAN_CHANGE_DESCRIPTION":
      return {
        ...state,
        form: {
          ...state.form,
          description: {
            ...state.form.description,
            value: action.description,
            required: true,
          },
        },
      };
    case "CREATE_POLITICIAN_ADD_PHRASE":
      if (state.form.phrases == null) {
        state.form.phrases = [];
      }

      state.form.phrases.push({
        id: state.form.phrases.length,
        description: "",
        descriptionField: {
          error: "",
          value: "",
          required: false,
        },
        isNew: true,
      });

      return { ...state, form: { ...state.form, phrases: state.form.phrases } };
    case "CREATE_POLITICIAN_REMOVE_PHRASE":
      return {
        ...state,
        form: {
          ...state.form,
          phrases: state.form.phrases.filter(
            (item) => action.selectedPhraseId !== item.id
          ),
        },
      };
    case "CREATE_POLITICIAN_CHANGE_PHRASE_DESCRIPTION":
      if (state.form.phrases !== null) {
        state.form.phrases.map((phrase: any) => {
          if (phrase.id == action.id) {
            phrase.descriptionField.value = action.description;
          }
        });
      }

      return { ...state, form: { ...state.form, phrases: state.form.phrases } };
    case "CREATE_POLITICIAN_ADD_BIOGRAPHY_ITEM":
      if (state.form.biographyItems == null) {
        state.form.biographyItems = [];
      }

      state.form.biographyItems.push({
        id: state.form.biographyItems.length,
        year: "",
        title: "",
        description: "",
        yearField: {
          error: "",
          value: "",
          required: false,
        },
        titleField: {
          error: "",
          value: "",
          required: false,
        },
        descriptionField: {
          error: "",
          value: "",
          required: false,
        },
      });

      return {
        ...state,
        form: { ...state.form, biographyItems: state.form.biographyItems },
      };
    case "CREATE_POLITICIAN_REMOVE_BIOGRAPHY_ITEM":
      if (state.form.biographyItems != null) {
        return {
          ...state,
          form: {
            ...state.form,
            biographyItems: state.form.biographyItems.filter(
              (item) => action.id !== item.id
            ),
          },
        };
      }
      return { ...state };
    case "CREATE_POLITICIAN_CHANGE_BIOGRAPHY_ITEM_YEAR":
      if (state.form.biographyItems !== null) {
        state.form.biographyItems.map((biographyItem: any) => {
          if (biographyItem.id == action.id) {
            biographyItem.yearField.value = action.year;
          }
        });
      }

      return {
        ...state,
        form: { ...state.form, biographyItems: state.form.biographyItems },
      };
    case "CREATE_POLITICIAN_CHANGE_BIOGRAPHY_ITEM_TITLE":
      if (state.form.biographyItems !== null) {
        state.form.biographyItems.map((biographyItem: any) => {
          if (biographyItem.id == action.id) {
            biographyItem.titleField.value = action.title;
          }
        });
      }

      return {
        ...state,
        form: { ...state.form, biographyItems: state.form.biographyItems },
      };
    case "CREATE_POLITICIAN_CHANGE_BIOGRAPHY_ITEM_DESCRIPTION":
      if (state.form.biographyItems !== null) {
        state.form.biographyItems.map((biographyItem: any) => {
          if (biographyItem.id == action.id) {
            biographyItem.descriptionField.value = action.description;
          }
        });
      }

      return {
        ...state,
        form: { ...state.form, biographyItems: state.form.biographyItems },
      };
    case "CREATE_POLITICIAN_CHANGE_QUESTION":
      return {
        ...state,
        form: {
          ...state.form,
          question: {
            ...state.form.question,
            value: action.question,
            answer1: { ...state.form.question.answer1 },
            answer2: { ...state.form.question.answer2 },
            answer3: { ...state.form.question.answer3 },
            answer4: { ...state.form.question.answer4 },
          },
        },
      };
    case "CREATE_POLITICIAN_CHANGE_ANSWER1":
      return {
        ...state,
        form: {
          ...state.form,
          question: {
            ...state.form.question,
            answer1: { ...state.form.question.answer1, value: action.answer },
          },
        },
      };
    case "CREATE_POLITICIAN_CHANGE_ANSWER2":
      return {
        ...state,
        form: {
          ...state.form,
          question: {
            ...state.form.question,
            answer2: { ...state.form.question.answer2, value: action.answer },
          },
        },
      };
    case "CREATE_POLITICIAN_CHANGE_ANSWER3":
      return {
        ...state,
        form: {
          ...state.form,
          question: {
            ...state.form.question,
            answer3: { ...state.form.question.answer3, value: action.answer },
          },
        },
      };
    case "CREATE_POLITICIAN_CHANGE_ANSWER4":
      return {
        ...state,
        form: {
          ...state.form,
          question: {
            ...state.form.question,
            answer4: { ...state.form.question.answer4, value: action.answer },
          },
        },
      };
    case "CREATE_POLITICIAN_CHANGE_CORRECT_ANSWER_ID":
      return {
        ...state,
        form: {
          ...state.form,
          question: { ...state.form.question, correctAnswerId: action.id },
        },
      };
    case "CREATE_POLITICIAN_CHANGE_QUESTION_ANSWER":
      if (state.form.questions !== null) {
        state.form.questions.map((question: any) => {
          if (question.id == action.id) {
            question.value = action.answer;
          }
        });
      }

      return {
        ...state,
        form: { ...state.form, questions: state.form.questions },
      };
    case "CREATE_POLITICIAN_INIT_QUESTIONS":
      var questions: [
        {
          id: number;
          question: string;
          error: string;
          value: string;
          required: boolean;
        }
      ] = [
        {
          id: 0,
          question: "Kérdés",
          error: "",
          value: "válasz",
          required: false,
        },
      ];

      if (action.questions !== null && action.questions !== undefined) {
        questions.pop();

        action.questions.map((questionElement: PoliticianQuestion) => {
          questions.push({
            id: questionElement.id,
            question: questionElement.question,
            error: "",
            value: "",
            required: false,
          });
        });
      }

      return {
        ...state,
        form: { ...state.form, questions: questions, phrases: [] },
      };
    case "CREATE_POLITICIAN_MODIFY_POLITICIAN":
      var questions: [
        {
          id: number;
          question: string;
          error: string;
          value: string;
          required: boolean;
        }
      ] = [
        {
          id: 0,
          question: "Kérdés",
          error: "",
          value: "válasz",
          required: false,
        },
      ];

      questions.pop();

      if (
        action.politician.questions !== null &&
        action.politician.questions !== undefined
      ) {
        action.politician.questions.map(
          (questionElement: PoliticianQuestion) => {
            questions.push({
              id: questionElement.id,
              question: questionElement.question,
              error: "",
              value:
                questionElement.answers != null &&
                questionElement.answers.length != 0
                  ? questionElement.answers[0].answer
                  : "",
              required: false,
            });
          }
        );
      }

      if (
        action.politician.phrases !== null &&
        action.politician.phrases !== undefined
      ) {
        action.politician.phrases.map((phrase: PoliticianPhrase) => {
          phrase.descriptionField = {
            error: "",
            value: phrase.description,
            required: false,
          };
        });
      }

      if (
        action.politician.biographyItems !== null &&
        action.politician.biographyItems !== undefined
      ) {
        action.politician.biographyItems.map(
          (biographyItem: PoliticianBiographyItem) => {
            biographyItem.yearField = {
              error: "",
              value: biographyItem.year,
              required: false,
            };
            biographyItem.titleField = {
              error: "",
              value: biographyItem.title,
              required: false,
            };
            biographyItem.descriptionField = {
              error: "",
              value: biographyItem.description,
              required: false,
            };
          }
        );
      }

      let correctAnswerId = 1;

      if (
        action.politician.question != null &&
        action.politician.question.answers != null &&
        action.politician.question.answers.length != 0
      ) {
        if (
          action.politician.question.answers[0] != null &&
          action.politician.question.answers[0].isSolution
        ) {
          correctAnswerId = 1;
        }
        if (
          action.politician.question.answers[1] != null &&
          action.politician.question.answers[1].isSolution
        ) {
          correctAnswerId = 2;
        }
        if (
          action.politician.question.answers[2] != null &&
          action.politician.question.answers[2].isSolution
        ) {
          correctAnswerId = 3;
        }
        if (
          action.politician.question.answers[3] != null &&
          action.politician.question.answers[3].isSolution
        ) {
          correctAnswerId = 4;
        }
      }

            return { ...state,
                form: { ...state.form,
                    tags: action.politician.tags,
                    quotes: action.politician.quotes,
                    id: action.politician.id,
                    experience: {
                        error: "",
                        value: action.politician.experience,
                        required: true,
                    },
                    quizExperience: {
                        error: "",
                        value: action.politician.quizExperience,
                        required: true,
                    },
                    isVisible: action.politician.isVisible,
          firstName: {
            error: "",
            value: action.politician.firstName,
            required: true,
          },
          lastName: {
            error: "",
            value: action.politician.lastName,
            required: true,
          },
          innerPosition: {
            error: "",
            value: action.politician.innerPosition,
            required: false,
          },
          position: {
            error: "",
            value: action.politician.position,
            required: false,
          },
          shadowGovernmentPosition: {
            error: "",
            value: action.politician.shadowGovernmentPosition,
            required: false,
          },
          description: {
            error: "",
            value: action.politician.description,
            required: true,
          },
          question: {
            error: "",
            value:
              action.politician.question != null
                ? action.politician.question.question
                : "",
            required: false,

            correctAnswerId: correctAnswerId,

            answer1: {
              error: "",
              value:
                action.politician.question != null
                  ? action.politician.question.answers != null
                    ? action.politician.question.answers[0] != null
                      ? action.politician.question.answers[0].answer
                      : ""
                    : ""
                  : "",
              required: false,
            },
            answer2: {
              error: "",
              value:
                action.politician.question != null
                  ? action.politician.question.answers != null
                    ? action.politician.question.answers[1] != null
                      ? action.politician.question.answers[1].answer
                      : ""
                    : ""
                  : "",
              required: false,
            },
            answer3: {
              error: "",
              value:
                action.politician.question != null
                  ? action.politician.question.answers != null
                    ? action.politician.question.answers[2] != null
                      ? action.politician.question.answers[2].answer
                      : ""
                    : ""
                  : "",
              required: false,
            },
            answer4: {
              error: "",
              value:
                action.politician.question != null
                  ? action.politician.question.answers != null
                    ? action.politician.question.answers[3] != null
                      ? action.politician.question.answers[3].answer
                      : ""
                    : ""
                  : "",
              required: false,
            },
          },
          questions: questions,
          imageUrl:
            action.politician.imageFile != null
              ? action.politician.imageFile.url
              : "",
          mediaLibraryFile:
            action.politician.imageFile != null
              ? {
                  id: action.politician.imageFile.id,
                  extension: "jpg",
                  name: "",
                  width: 0,
                  height: 0,
                  size: "",
                  thumbnail: "",
                  type: "file",
                  url: "",
                  created: 0,
                }
              : null,
          phrases: action.politician.phrases,
          biographyItems: action.politician.biographyItems,
          titles: action.politician.titles,
        },
      };
    case "CREATE_POLITICIAN_STARTED":
      return { ...state, saveDate: new Date() };
    case "CREATE_POLITICIAN_INVALIDJSON":
      return { ...state, saveDate: null, politician: null };
    case "CREATE_POLITICIAN_INVALIDRESPONSE":
      return { ...state, saveDate: null, politician: null };
    case "CREATE_POLITICIAN_NOUSER":
      return { ...state, saveDate: null, politician: null };
    case "CREATE_POLITICIAN_TIMEOUT":
      return { ...state, saveDate: null, politician: null };
    case "CREATE_POLITICIAN_UNAUTHORIZED":
      return { ...state, saveDate: null, politician: null };
    case "CREATE_POLITICIAN_UNEXPECTEDRESPONSE":
      return { ...state, saveDate: null, politician: null };
    case "CREATE_POLITICIAN_OK":
        message( `A politikus sikeresen mentésre került!` )
      return { ...state, saveDate: null, politician: action.politician };
    case "CREATE_POLITICIAN_CLEAR":
      return initialCreatePoliticianState;

        case `POLITICIAN_ADD_TAG`:
            return { ...state, form: { ...state.form, tags: [ ...state.form.tags, action.tag ] } }

        case `POLITICIAN_REMOVE_TAG`:
            return { ...state, form: { ...state.form, tags: state.form.tags.filter( t => t.id !== action.tag.id ) } }

      case DELETE_POLITICIAN_ACTIONS.DELETE_POLITICIAN_OK:
          message( `A politikus sikeresen törlésre került!`, `Sikeres törlés` )
          return state

      case `CREATE_POLITICIAN_CHANGE_IS_VISIBLE`:
          return { ...state, form: { ...state.form, isVisible: action.isVisible } }

    case GET_POLITICIAN_STATS_ACTIONS.GET_POLITICIAN_STATS_OK:
      return {
        ...state,
        stats: {
          openCount: action.openCount,
          sendQuestionCount: action.sendQuestionCount,
          openPercent: action.openPercent,
        },
      };
    default:
      return state;
  }
};
