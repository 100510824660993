import { getEvents } from '../../action/events'
import { store } from '../../store'
import { getPoliticians } from '../../action/politician'

export const eventsListLoader = () => {

    store.dispatch( { type: `EVENTS_UPDATE_SEARCH`, value: `` } )
    getPoliticians()
    getEvents()

}
