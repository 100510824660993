import { Modal } from '../modal'
import './league-modal.scss'
import { League } from '../../entity'
import { TextField } from '../field'
import { useDispatch } from 'react-redux'
import { saveLeague } from '../../action/gamification'

interface LeagueModalProps {

    league: League
    onClose?: () => void

}

export const LeagueModal = ( props: LeagueModalProps ) => {

    const dispatch = useDispatch()
    const onClose = () => { if ( props.onClose ) props.onClose() }
    const onFieldChange = ( field: string, value: any ) => { dispatch( { type: `UPDATE_LEAGUE`, field: field, value: value } ) }
    const onSave = async () => { await saveLeague( props.league.id, props.league ); if ( props.onClose ) { props.onClose() } }

    return (

        <Modal className="league-modal" onModalClose={ onClose }>

            <div className="league-modal__title">

                { props.league.name } beállításai

            </div>

            <div className="row-field row-field--disabled">

                <label>Liga neve*</label>
                <TextField field={{ value: props.league.name, error: ``, disabled: true }} onChange={ e => onFieldChange( `name`, e.target.value ) } />

            </div>

            <div className="row-field row-field--options">

                <label>Összes feladat a továbbjutáshoz *</label>
                <div className="option">
                    <input checked={ props.league.allQuestsRequiredToAdvance } type="radio" value={ `true` } name="allQuestsRequiredToAdvance" onClick={ e => onFieldChange( `allQuestsRequiredToAdvance`, true ) } />
                    <label>Igen</label>
                </div>
                <div className="option">
                    <input checked={ !props.league.allQuestsRequiredToAdvance } type="radio" name="allQuestsRequiredToAdvance" value={ `false` } onClick={ e => onFieldChange( `allQuestsRequiredToAdvance`, false ) } />
                    <label>Nem</label>
                </div>

            </div>

            { props.league.id === 1 ? (

                <div className="row-field">

                    <label>Továbbjutási feltételek (TP) *</label>
                    <TextField field={{ value: props.league.requiredAdvanceEXP?.toString() || ``, error: `` }} onChange={ e => onFieldChange( `requiredAdvanceEXP`, e.target.value ) } />

                </div>

            ) : (

                <div className="row-field">

                    <label>Továbbjutási feltételek (felső X%) *</label>
                    <TextField field={{ value: props.league.requiredAdvanceTopPercentage?.toString() || ``, error: `` }} onChange={ e => onFieldChange( `requiredAdvanceTopPercentage`, e.target.value ) } />

                </div>

            ) }

            <div className="row-field">

                <label>Pontgyűjtés közbeni felirat*</label>
                <TextField field={{ value: props.league.instruction, error: `` }} onChange={ e => onFieldChange( `instruction`, e.target.value ) } />

            </div>

            <div className="row-field row-field--options">

                <label>Továbbjutás forduló előtt</label>
                <div className="option">
                    <input checked={ props.league.advanceImmediately } type="radio" name="advanceImmediately" value={ `true` } onClick={ e => onFieldChange( `advanceImmediately`, true ) } />
                    <label>Igen</label>
                </div>
                <div className="option">
                    <input checked={ !props.league.advanceImmediately } type="radio" name="advanceImmediately" value={ `false` } onClick={ e => onFieldChange( `advanceImmediately`, false ) } />
                    <label>Nem</label>
                </div>

            </div>

            <footer>

                <button className="button button--primary" onClick={ onSave }>Mentés</button>

            </footer>

        </Modal>

    )

}