import { Expose } from 'class-transformer'
import { IsDefined, IsNumber, IsString } from 'class-validator'

export class FloatingMessage {

    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number 

    @Expose()
    @IsDefined()
    @IsNumber()
    imageId!: number 

    @Expose()
    @IsDefined()
    @IsNumber()
    type!: number 

    @Expose()
    @IsDefined()
    @IsString()
    message!: string 

    @Expose()
    @IsDefined()
    @IsNumber()
    created!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    updated!: number 
   
}


