import { store } from "../../store"
import { createCall } from "../base"
import { BASE_METHODS } from "../base/base.constants"
import { getPoliticianTitles } from "./get-politician-titles.action"
import { DELETE_POLITICIAN_TITLE_ACTIONS, POLITICIANS_ACTIONS } from "./politicians.constants"

export const deletePoliticianTitle = async (id: number) => {

    store.dispatch( { type: DELETE_POLITICIAN_TITLE_ACTIONS.DELETE_POLITICIAN_TITLE_STARTED } )

    const promise = createCall(`politicians/titles/`+id, BASE_METHODS.DELETE)
    promise.then(async response => {

         //Ha hiba jön a szerverről
         if ( response.status ===  400 ) {
            //olyan titlust akar törölni amihez politikus tartoznak
            store.dispatch( { type: POLITICIANS_ACTIONS.GET_POLITICIAN_TITLES_POLITICIAN_NOT_EMPTY } )
            return
        }

        store.dispatch( { type: DELETE_POLITICIAN_TITLE_ACTIONS.DELETE_POLITICIAN_TITLE_OK, response: response.text() } )

        getPoliticianTitles()

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })

}
