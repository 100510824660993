export const county = ( county: number ): string => {

    if ( county === undefined ) {

        return ``

    }

    switch ( county ) {

        case 1:
            return `Bács-Kiskun megye`
        case 2:
            return `Baranya megye`
        case 3:
            return `Békés megye`
        case 4:
            return `Borsod-Abaúj-Zemplén megye`
        case 5:
            return `Csongrád-Csanád megye`

        case 6:
            return `Fejér megye`
        case 7:
            return `Győr-Moson-Sopron megye`
        case 8:
            return `Hajdú-Bihar megye`
        case 9:
            return `Heves megye`
        case 10:
            return `Jász-Nagykun-Szolnok megye`

        case 11:
            return `Komárom-Esztergom megye`
        case 12:
            return `Nógrád megye`
        case 13:
            return `Pest megye`
        case 14:
            return `Somogy megye`
        case 15:
            return `Szabolcs-Szatmár-Bereg megye`

        case 16:
            return `Tolna megye`
        case 17:
            return `Vas megye`
        case 18:
            return `Veszprém megye`
        case 19:
            return `Zala megye`

        default:
            return ``

    }

}

export const counties = () => {

    const counties = []
    for ( let i = 1; i < 20; i++ ) {

        counties.push( { id: i, value: county( i ) } )

    }

    return counties

}
