import { store } from '../../store'

export const saveBannedWord = async ( id: number | null, word: string ): Promise<boolean> => {

    let response: Response
    const token = store.getState().session.token

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/2.0.0/cms/banned-words`, {
            body: JSON.stringify( {
                id: id,
                bannedWord: word
            } ),
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            method: 'post'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'SAVE_BANNED_WORD_TIMEOUT' } )
        return false

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'SAVE_BANNED_WORD_UNAUTHORIZED' } )
        return false

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'SAVE_BANNED_WORD_UNEXPECTEDRESPONSE' } )
        return false

    }

    store.dispatch( { type: id === null ? 'CREATE_BANNED_WORD_OK' : 'SAVE_BANNED_WORD_OK' } )
    return true

}
