import { Questionnaire } from '../../../../entity'
import { createFormattedDateString } from '../../../../helper'
import './questionnaire-card.scss'

interface QuestionnaireCardProps {
    questionnaireElement: Questionnaire | null 
}

export const QuestionnaireCard = ( props: QuestionnaireCardProps ) => {

    return (
        <div className="questionnaire-card">
  
            <img className= { `${props.questionnaireElement == null ? "image-loading" : "questionnaire-image"}` } 
                 src= { `${ props.questionnaireElement == null ? null : `${ props.questionnaireElement.imageFile == null ? "/image/dk-logo-16_9.svg" : props.questionnaireElement.imageFile.url }` }` } alt= "Hírhez tartozó kép" />
                      
            <div className="bottom-div">
           
                <span className={`${props.questionnaireElement == null ? "text-loading" : "label-text date"}`} > { props.questionnaireElement == null ? "" : createFormattedDateString( props.questionnaireElement.visibleFrom ) }</span>

                <span className={`${props.questionnaireElement == null ? "text-loading" : "label-text question"}`} >
                    {props.questionnaireElement == null ? "" : ( props.questionnaireElement.questions != null ) ? props.questionnaireElement.questions.length + " kérdés" : "0 kérdés" }
                </span>

            </div>

            { props.questionnaireElement == null ? <h3 className= "title-text-loading" /> : ( props.questionnaireElement.title == null ? "" : <h3 className="title-text-h3 title">{ props.questionnaireElement.title } </h3> ) }
            
        </div>
    )

}
