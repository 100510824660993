import { ChangeEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { changeQuoteStatus, deleteQuotes, listQuotes } from '../../action/quotes'
import { State } from '../../state'
import { MenuButton } from '../button'
import { Checkbox } from '../checkbox'
import { Dropdown } from '../dropdown'
import { Table } from '../table'
import './quotes-table.scss'
import { Modal } from '../modal'

export const QuotesTable = () => {

    const [ checked, setChecked ] = useState( [] as number[] )
    const [ pageChecked, setPageChecked ] = useState( false )
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { orderBy, orderDirection, pagination, quotes } = useSelector( ( state: State ) => state.quotes )

    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )
    const [ showConfirmModal, setShowConfirmModal ] = useState( false )

    const onDelete = async () => {

        await deleteFunc!()
        setShowConfirmModal( false )

    }

    const deleteCheckedQuotes = async () => {

        setDeleteFunc( () => {
            return async () => {
                await deleteQuotes( checked )
                await listQuotes( pagination.page )
            }
        } )
        setShowConfirmModal( true )

    }

    const onMenuButtonAction = async ( id: number, action: number ) => {

        if ( action === 0 ) {

            navigate( `/velemenyvezerek/idezetek/${ id }` )

        }

        if ( action === 1 ) {

            setDeleteFunc( () => {
                return async () => {
                    await deleteQuotes( [ id ] )
                    await listQuotes( pagination.page )
                }
            } )
            setShowConfirmModal( true )

        }

    }

    const onStatusChange = async ( id: number, value: number ) => {

        await changeQuoteStatus( id, value )
        await listQuotes( pagination.page )

    }

    const onPageChange = ( page: number ) => {

        listQuotes( page )

    }

    const onOrderChange = ( column: string ) => {

        let newDirection = `ASC`

        if ( orderBy === column ) {

            if ( orderDirection === `ASC` ) {

                newDirection = `DESC`

            }

        }

        dispatch( { type: `CHANGE_QUOTES_ORDER`, by: column, direction: newDirection } )
        listQuotes( pagination.page )

    }

    const check = ( event: ChangeEvent<HTMLInputElement>, id: number ) => {

        let c = [ ...checked ]
        const value = checked.find( i => id === i ) !== undefined

        if ( value ) {

            setChecked( c.filter( i => i !== id ) )
            c = c.filter( i => i !== id )

        } else {

            setChecked( [ ...c, id ] )
            c.push( id )

        }

        setPageChecked( quotes.filter( q => c.find( id => q.id === id ) !== undefined ).length === quotes.length )

    }

    const checkPage = ( event: ChangeEvent<HTMLInputElement> ) => {

        let c = [ ...checked ]
        setPageChecked( event.target.checked )

        quotes.forEach( quote => {

            if ( event.target.checked ) {

                if ( c.find( id => quote.id === id ) === undefined ) {

                    c.push( quote.id )

                }

            } else {

                c = c.filter( id => quote.id !== id )

            }

        } )

        setChecked( c )

    }

    return (

        <>

            <Table className="quotes-table" columns={ [
                {
                    canOrder: false,
                    name: `check`,
                    render: column => <Checkbox checked={ checked.find( id => column.id === id ) !== undefined } id="null" onChange={ e => check( e, column.id ) } />,
                    headerRender: () => <Checkbox checked={ pageChecked } id="null" onChange={ checkPage } />,
                    width: 50
                },
                {
                    align: `center`,
                    name: `id`,
                    render: column => <>{ column.id }</>,
                    headerRender: () => <>ID</>,
                    width: 100
                },
                {
                    name: `quote`,
                    render: column => <><span className="politician">{ column.politician }</span>: { column.quote }</>,
                    headerRender: () => <><img src="/image/trash.svg" onClick={ deleteCheckedQuotes } />Idézetek</>,
                    width: 600
                },
                {
                    align: `center`,
                    name: `popular_reaction_percent`,
                    render: column => <></>,
                    headerRender: () => <></>,
                    width: 100
                },
                {
                    align: `center`,
                    name: `views`,
                    render: column => <>{ column.views }</>,
                    headerRender: () => <>Látták</>,
                    width: 150
                },
                {
                    align: `center`,
                    canOrder: false,
                    name: `status`,
                    render: column => <Dropdown compact={ true } items={ [
                        {
                            id: 0,
                            value: `Aktív`,
                            color: `var( --color-green )`
                        },
                        {
                            id: 1,
                            value: `Csak ütemezett`
                        },
                        {
                            id: 2,
                            value: `Nem aktív`,
                            color: `var( --color-warning )`
                        }
                    ] } value={ column.status } onChange={ ( value: number ) => onStatusChange( column.id, value ) } />,
                    headerRender: () => <>Státusz</>,
                    width: 200
                },
                {
                    align: `right`,
                    name: `actions`,
                    render: column => <MenuButton hasBackground={ true } items={ [
                        {
                            id: 0,
                            icon: `eye.svg`,
                            value: `Megtekintés`
                        },
                        {
                            id: 1,
                            icon: `trash.svg`,
                            value: `Törlés`
                        }
                    ] } onChange={ ( id: number ) => onMenuButtonAction( column.id, id ) } />,
                    headerRender: () => <></>,
                    width: 100
                }
            ] } dataset={ quotes } pagination={ { ...pagination, onPageChange } } order={ { by: orderBy, direction: orderDirection } } onOrderChange={ onOrderChange } onRowClick={ column => navigate( `/velemenyvezerek/idezetek/${ column.id }` ) } />

            { showConfirmModal ? (

                <Modal className="quotes-table-confirm-modal" onModalClose={ () => setShowConfirmModal( false ) }>

                    <div className="modal-title">Biztosan törlöd a mondatot?</div>
                    <div className="modal-description">Ezt a műveletet nem lehet visszavonni! Biztosan törlöd?</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowConfirmModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ onDelete }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

        </>

    )

}
