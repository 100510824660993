export const GET_QUESTIONNAIRES_ACTIONS = {
    GET_QUESTIONNAIRES_INVALID_JSON: "GET_QUESTIONNAIRES_INVALID_JSON",
    GET_QUESTIONNAIRES_INVALID_RESPONSE: "GET_QUESTIONNAIRES_INVALID_RESPONSE",
    GET_QUESTIONNAIRES_OK: "GET_QUESTIONNAIRES_OK",
    GET_QUESTIONNAIRES_NEXT_PAGE: "GET_QUESTIONNAIRES_NEXT_PAGE",
    GET_QUESTIONNAIRES_PREV_PAGE: "GET_QUESTIONNAIRES_PREV_PAGE",
    GET_QUESTIONNAIRES_FROM_PAGE: "GET_QUESTIONNAIRES_FROM_PAGE",
    GET_QUESTIONNAIRES_TO_PAGE: "GET_QUESTIONNAIRES_TO_PAGE",
    GET_QUESTIONNAIRES_SEARCH: "GET_QUESTIONNAIRES_SEARCH",
    GET_QUESTIONNAIRES_ORDER: "GET_QUESTIONNAIRES_ORDER",
    GET_QUESTIONNAIRES_DELETED: "GET_QUESTIONNAIRES_DELETED",
    GET_QUESTIONNAIRES_CHANGE_PAGE: "GET_QUESTIONNAIRES_CHANGE_PAGE",

    GET_QUESTIONNAIRE_TAG_INVALID_JSON: "GET_QUESTIONNAIRE_TAG_INVALID_JSON",
    GET_QUESTIONNAIRE_TAG_INVALID_RESPONSE: "GET_QUESTIONNAIRE_TAG_INVALID_RESPONSE",
    GET_QUESTIONNAIRE_TAG_OK: "GET_QUESTIONNAIRE_TAG_OK",
    GET_QUESTIONNAIRE_TAG_QUESTIONNAIRE_NOT_EMPTY: "GET_QUESTIONNAIRE_TAG_QUESTIONNAIRE_NOT_EMPTY",
    GET_QUESTIONNAIRE_TAG_QUESTIONNAIRE_NOT_EMPTY_CLEAR:  "GET_QUESTIONNAIRE_TAG_QUESTIONNAIRE_NOT_EMPTY_CLEAR",
}


export const GET_QUESTIONNAIRES_STAT_ACTIONS = {
    GET_QUESTIONNAIRES_STATS_INVALID_JSON: "GET_QUESTIONNAIRES_STATS_INVALID_JSON",
    GET_QUESTIONNAIRES_STATS_INVALID_RESPONSE: "GET_QUESTIONNAIRES_STATS_INVALID_RESPONSE",
    GET_QUESTIONNAIRES_STATS_OK: "GET_QUESTIONNAIRES_STATS_OK",
}


export const CREATE_QUESTIONNAIRE_ACTIONS = {
    CREATE_QUESTIONNAIRE_STARTED: "CREATE_QUESTIONNAIRE_STARTED",
    CREATE_QUESTIONNAIRE_INVALID_JSON: "CREATE_QUESTIONNAIRE_INVALID_JSON",
    CREATE_QUESTIONNAIRE_INVALID_RESPONSE: "CREATE_QUESTIONNAIRE_INVALID_RESPONSE",
    CREATE_QUESTIONNAIRE_OK: "CREATE_QUESTIONNAIRE_OK",
    CREATE_QUESTIONNAIRE_CHANGE_MEDIA_LIBRARY_FILE: "CREATE_QUESTIONNAIRE_CHANGE_MEDIA_LIBRARY_FILE",
    CREATE_QUESTIONNAIRE_CHANGE_VISIBLE_FROM: "CREATE_QUESTIONNAIRE_CHANGE_VISIBLE_FROM",
    CREATE_QUESTIONNAIRE_CHANGE_VISIBLE_TO: "CREATE_QUESTIONNAIRE_CHANGE_VISIBLE_TO",
    CREATE_QUESTIONNAIRE_CHANGE_STATUS: "CREATE_QUESTIONNAIRE_CHANGE_STATUS",
    CREATE_QUESTIONNAIRE_CHANGE_VISIBILITY: "CREATE_QUESTIONNAIRE_CHANGE_VISIBILITY",
    CREATE_QUESTIONNAIRE_CHANGE_TITLE: "CREATE_QUESTIONNAIRE_CHANGE_TITLE",
    CREATE_QUESTIONNAIRE_CHANGE_LEAD: "CREATE_QUESTIONNAIRE_CHANGE_LEAD",
    CREATE_QUESTIONNAIRE_CHANGE_NUMBER_OF_COMPLETE_REQUIRED: "CREATE_QUESTIONNAIRE_CHANGE_NUMBER_OF_COMPLETE_REQUIRED",
    CREATE_QUESTIONNAIRE_CHANGE_DESCRIPTION: "CREATE_QUESTIONNAIRE_CHANGE_DESCRIPTION",
    CREATE_QUESTIONNAIRE_CLEAR: "CREATE_QUESTIONNAIRE_CLEAR",
    CREATE_QUESTIONNAIRE_MODIFY_QUESTIONNAIRE: "CREATE_QUESTIONNAIRE_MODIFY_QUESTIONNAIRE",
    CREATE_QUESTIONNAIRE_ADD_TAG: "CREATE_QUESTIONNAIRE_ADD_TAG",
    CREATE_QUESTIONNAIRE_REMOVE_TAG: "CREATE_QUESTIONNAIRE_REMOVE_TAG",

    CREATE_QUESTIONNAIRE_ORDER_QUESTION: "CREATE_QUESTIONNAIRE_ORDER_QUESTION",

    CREATE_QUESTIONNAIRE_ADD_QUESTION: "CREATE_QUESTIONNAIRE_ADD_QUESTION",
    CREATE_QUESTIONNAIRE_REMOVE_QUESTION: "CREATE_QUESTIONNAIRE_REMOVE_QUESTION",
    CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ITEM_QUESTION: "CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ITEM_QUESTION",
    CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ITEM_DESCRIPTION: "CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ITEM_DESCRIPTION",
    CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ITEM_TYPE: "CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ITEM_TYPE",
    CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ITEM_TO_SCALE: "CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ITEM_SCALE",
    CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ITEM_FROM_SCALE_TITLE: "CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ITEM_FROM_SCALE_TITLE",
    CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ITEM_TO_SCALE_TITLE: "CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ITEM_TO_SCALE_TITLE",
    CREATE_QUESTIONNAIRE_CHANGE_QUESTION_MEDIA_LIBRARY_FILE: "CREATE_QUESTIONNAIRE_CHANGE_QUESTION_MEDIA_LIBRARY_FILE",

    CREATE_QUESTIONNAIRE_ADD_QUESTION_ANSWER: "CREATE_QUESTIONNAIRE_ADD_QUESTION_ANSWER",
    CREATE_QUESTIONNAIRE_REMOVE_QUESTION_ANSWER: "CREATE_QUESTIONNAIRE_REMOVE_QUESTION_ANSWER",
    CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ANSWER_ITEM_ANSWER: "CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ANSWER_ITEM_ANSWER",
    CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ANSWER_MEDIA_LIBRARY_FILE: "CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ANSWER_MEDIA_LIBRARY_FILE",
}


export const DELETE_QUESTIONNAIRE_ACTIONS = {
    DELETE_QUESTIONNAIRE_STARTED: "DELETE_QUESTIONNAIRE_STARTED",
    DELETE_QUESTIONNAIRE_OK: "DELETE_QUESTIONNAIRE_OK",
}


export const MODIFY_QUESTIONNAIRE_STATUS_ACTIONS = {
    MODIFY_QUESTIONNAIRE_STATUS_STARTED: "MODIFY_QUESTIONNAIRE_STATUS_STARTED",
    MODIFY_QUESTIONNAIRE_STATUS_INVALID_JSON: "MODIFY_QUESTIONNAIRE_STATUS_INVALID_JSON",
    MODIFY_QUESTIONNAIRE_STATUS_INVALID_RESPONSE: "MODIFY_QUESTIONNAIRE_STATUS_INVALID_RESPONSE",
    MODIFY_QUESTIONNAIRE_STATUS_OK: "MODIFY_QUESTIONNAIRE_STATUS_OK",
}


export const CREATE_QUESTIONNAIRE_TAG_ACTIONS = {
    CREATE_QUESTIONNAIRE_TAG_STARTED: "CREATE_QUESTIONNAIRE_TAG_STARTED",
    CREATE_QUESTIONNAIRE_TAG_INVALID_JSON: "CREATE_QUESTIONNAIRE_TAG_INVALID_JSON",
    CREATE_QUESTIONNAIRE_TAG_INVALID_RESPONSE: "CREATE_QUESTIONNAIRE_TAG_INVALID_RESPONSE",
    CREATE_QUESTIONNAIRE_TAG_OK: "CREATE_QUESTIONNAIRE_TAG_OK",
    CREATE_QUESTIONNAIRE_TAG_CHANGE_TAG: "CREATE_QUESTIONNAIRE_TAG_CHANGE_TAG",
    CREATE_QUESTIONNAIRE_TAG_CLEAR: "CREATE_QUESTIONNAIRE_TAG_CLEAR",
    CREATE_QUESTIONNAIRE_TAG_MODIFY_TAG: "CREATE_QUESTIONNAIRE_TAG_MODIFY_TAG",
}


export const DELETE_QUESTIONNAIRE_TAG_ACTIONS = {
    DELETE_QUESTIONNAIRE_TAG_STARTED: "DELETE_QUESTIONNAIRE_TAG_STARTED",
    DELETE_QUESTIONNAIRE_TAG_OK: "DELETE_QUESTIONNAIRE_TAG_OK",
}