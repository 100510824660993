import { useState } from "react"
import { useSelector } from "react-redux"
import { deleteBannedWord, listBannedWords, saveBannedWord } from "../../action"
import { State } from "../../state"
import { TextField } from "../field"
import { Modal } from "../modal"
import "./banned-words-modal.scss"

interface BannedWordsModalProps {
    onClose?: () => void;
}

export const BannedWordsModal = ( props: BannedWordsModalProps ) => {

    const { bannedWords } = useSelector( ( state: State ) => state.users )
    const [ selectedBannedWord, setSelectedBannedWord ] = useState( undefined as number | null | undefined )
    const [ word, setWord ] = useState( { error: ``, value: `` } )

    const save = async () => {

        if ( word.value !== "" ) {

            await saveBannedWord( selectedBannedWord || null, word.value )
            await listBannedWords()
            selectBannedWord( null )

        }

    }

    const selectBannedWord = ( id: number | null ) => {

        setWord( { value: bannedWords.find( bannedWord => bannedWord.id === id )?.bannedWord || ``, error: "" } )
        setSelectedBannedWord( id )

    }

    const [ showConfirmDelete, setShowConfirmDelete ] = useState( false )
    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )

    const onBannedWordDelete = async () => {

        setDeleteFunc( () => {
            return async () => {
                await deleteBannedWord( selectedBannedWord! )
                await listBannedWords()
                setSelectedBannedWord( undefined )
            }
        } )
        setShowConfirmDelete( true )

    }
    const onDelete = () => {
        if ( deleteFunc !== null ) {
            deleteFunc()
        }
        setDeleteFunc( null )
        setShowConfirmDelete( false )
    }

    return (

        <Modal className="banned-words-modal" onModalClose={ () => props.onClose && props.onClose() }>

            <div className="modal-title">Tiltott játékosnév hozzáadása</div>
            <img className="modal-close" src="/image/close.svg" onClick={ () => props.onClose && props.onClose() }/>

            <main>

                <div className="banned-words">
                    <div className="list">
                    { bannedWords.map( ( bannedWord ) => (
                        <div className={ `banned-word${ bannedWord.id === selectedBannedWord ? ` banned-word--selected` : `` }` } onClick={ ( e ) => selectBannedWord( bannedWord.id ) }>
                            <span>{ bannedWord.bannedWord }</span> <img alt="" src="/image/edit.svg"/>
                        </div> ) ) }
                    </div>
                    <button className="button" onClick={ ( e ) => selectBannedWord( null ) }>
                        Új tiltott szó hozzáadása +
                    </button>
                </div>

                <div className="separator"></div>

                { selectedBannedWord !== undefined ? (

                    <div className="quote-party-form card-div">

                        <div className="quote-party-form__title">
                            Tiltott szó { selectedBannedWord === null ? `hozzáadása` : `módosítása` }
                        </div>

                        <div className="input-field">

                            <label>Tiltott szó:</label>
                            <TextField field={ word } onChange={ ( e ) => setWord( { ...word, value: e.target.value } ) } maxlength={ 255 }/>

                        </div>

                        <footer>

                            <button className="button" style={ { minWidth: 250 } } onClick={ ( e ) => save() }>Módosítások mentése</button>

                            { selectedBannedWord !== null ? (

                                <button className="button button--ghost button--warning" style={ { marginLeft: 30, minWidth: 200 } } onClick={ () => onBannedWordDelete() }>Törlés</button>

                            ) : null }
                        </footer>

                    </div>

                ) : null }

                { showConfirmDelete ? (
                    <Modal className="confirm-delete-party-modal" onModalClose={ () => setShowConfirmDelete( false ) }>
                        <div className="modal-title">Tiltott szó törlése</div>
                        <div className="modal-description">Biztosan törlöd?</div>
                        <footer>
                            <button className="button button--ghost" onClick={ () => setShowConfirmDelete( false ) }>Mégse</button>
                            <button className="button button--primary" onClick={ onDelete }>Törlés</button>
                        </footer>
                    </Modal>
                ) : null }

            </main>

        </Modal>

    )
}
