import { getUsers } from '../../../../action'
import { store } from '../../../../store'

export const onTableOrderChange = ( column: string, order: any ) => {

    if ( order.by === column ) {

        if ( order.direction === 'ASC' ) {

            store.dispatch( { type: 'CHANGE_ORDER', by: order.by, direction: 'DESC' } )

        } else {

            store.dispatch( { type: 'CHANGE_ORDER', by: '', direction: 'ASC' } )

        }

    } else {

        store.dispatch( { type: 'CHANGE_ORDER', by: column, direction: 'ASC' } )

    }

    getUsers()

}
