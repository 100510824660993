export const title = ( type: string = `normal` ) => {

    if ( type === `password` ) {

        return <>Örülünk, hogy itt látunk,<br />csak pár adatra van szükség!</>

        if ( process.env.REACT_APP_BUILD === `PRIZES` ) {
            return <>Jutalom kezelő.<br />Csak pár adatra van szükség!</>
        }

    }


    if ( process.env.REACT_APP_BUILD === `PRIZES` ) {
        return <>Jutalom kezelő</>
    }

    return <>Örülünk, hogy<br />visszatértél!</>

}