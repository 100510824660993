import { Table } from '../table'
import './application-versions-table.scss'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../state'
import { createFormattedDateTimeString } from '../../helper'
import { MenuButton } from '../button'
import { deleteApplicationVersion, listApplicationVersions } from '../../action/application-version'
import { useState } from 'react'
import { Modal } from '../modal'

export const ApplicationVersionsTable = () => {

    const dispatch = useDispatch()
    const { applicationVersions, pagination, orderBy, orderDirection } = useSelector( ( state: State ) => state.applicationVersions )

    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )
    const [ showConfirmModal, setShowConfirmModal ] = useState( false )

    const onDelete = async () => {

        await deleteFunc!()
        setShowConfirmModal( false )

    }


    const onMenuButtonClick = async ( action: number, applicationVersionId: number ) => {

        switch ( action ) {

            case 1:
                setDeleteFunc( () => {
                    return async () => {
                        await deleteApplicationVersion( applicationVersionId )
                        await listApplicationVersions( pagination.page )
                    }
                } )
                setShowConfirmModal( true )
                break

            default:
                dispatch( { type: `SELECT_APPLICATION_VERSION`, applicationVersionId: applicationVersionId } )

        }

    }

    const onPageChange = ( page: number ) => {

        listApplicationVersions( page )

    }

    const onOrderChange = ( column: string ) => {

        let by = column
        let direction = `ASC`

        if ( column === orderBy ) {

            if ( orderDirection === `ASC` ) {

                direction = `DESC`

            }

        }

        dispatch( { type: `APPLICATION_VERSIONS_CHANGE_ORDER`, by: by, direction: direction } )
        listApplicationVersions()

    }

    return (

        <>

            <Table className="application-versions-table" columns={[
                {
                    headerRender: () => `Platform`,
                    name: `platform`,
                    render: row => row.platform === 2 ? `iOS` : `Android`,
                    width: 313
                },
                {
                    headerRender: () => `Verzió`,
                    name: `version`,
                    render: row => row.version,
                    width: 313
                },
                {
                    headerRender: () => `Kritikus`,
                    name: `critical`,
                    render: row => row.critical ? `Igen` : `Nem`,
                    width: 313
                },
                {
                    headerRender: () => `Létrehozva`,
                    name: `created`,
                    render: row => createFormattedDateTimeString( row.created ),
                    width: 313
                },
                {
                    align: `center`,
                    name: `actions`,
                    render: row => <MenuButton hasBackground={ true } items={ [
                        {
                            id: 0,
                            icon: `edit.svg`,
                            value: `Szerkesztés`
                        },
                        {
                            id: 1,
                            icon: `trash.svg`,
                            value: `Törlés`
                        }
                    ] } onChange={ ( id: number ) => onMenuButtonClick( id, row.id ) } />,
                    width: 156
                }
            ]} dataset={ applicationVersions } pagination={ { ...pagination, onPageChange: onPageChange } } order={ { by: orderBy, direction: orderDirection } } onOrderChange={ onOrderChange } />

            { showConfirmModal ? (

                <Modal className="application-version-confirm-modal" onModalClose={ () => setShowConfirmModal( false ) }>

                    <div className="modal-title">Biztosan törlöd az alkalmazás verziót?</div>
                    <div className="modal-description">Ezt a műveletet nem lehet visszavonni! Biztosan törlöd?</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowConfirmModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ onDelete }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

        </>

    )

}