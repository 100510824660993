import { useState } from "react";
import { useSelector } from "react-redux";
import { createAdmin, getUser, getUsers, getUserStats } from "../../action";
import { getUserGroupName } from "../../helper";
import { State } from "../../state";
import { Dropdown } from "../dropdown";
import { EmailField, PasswordField, TextField } from "../field";
import { Modal } from "../modal";
import "./admin-modal.scss";

interface AdminModalProps {
  onModalClose?: () => void;
}

export const AdminModal = (props: AdminModalProps) => {
  const { userGroups } = useSelector((state: State) => state.users);

  const [lastName, setLastName] = useState({
    id: `lastName`,
    error: ``,
    value: ``,
  });
  const [firstName, setFirstName] = useState({
    id: `firstName`,
    error: ``,
    value: ``,
  });
  const [email, setEmail] = useState({ id: `email`, error: ``, value: `` });
  const [userGroupId, setUserGroupId] = useState({
    id: `userGroupId`,
    error: ``,
    value: `0`,
  });
  const [password, setPassword] = useState({
    id: `password`,
    error: ``,
    value: ``,
  });

  const save = async () => {
    if (
      await createAdmin(
        firstName.value,
        lastName.value,
        email.value,
        parseInt(userGroupId.value),
        password.value
      )
    ) {
      props.onModalClose && props.onModalClose();
      // ToDo: felugró, hogy sikeres volt
      getUsers([2, 3, 4]);
      getUserStats([2, 3, 4]);
      return;
    }

    // ToDo: hibaüzenet megjelenítése
  };

  return (
    <div className="admin-modal">
      <Modal onModalClose={() => props.onModalClose && props.onModalClose()}>
        <header>
          <div className="admin-modal__title">Új admin hozzáadása!</div>
          <div className="admin-modal__sub-title">Add meg a nevét!</div>
        </header>

        <main>
          <div className="row">
            <label htmlFor="lastName">Vezetéknév*</label>
            <TextField
              field={lastName}
              onChange={(e) =>
                setLastName({ ...lastName, value: e.target.value })
              }
            />
          </div>

          <div className="row" style={{ marginBottom: 23 }}>
            <label htmlFor="firstName">Keresztnév*</label>
            <TextField
              field={firstName}
              onChange={(e) =>
                setFirstName({ ...firstName, value: e.target.value })
              }
            />
          </div>

          <div className="row" style={{ marginBottom: 23 }}>
            <label htmlFor="email">E-mail cím*</label>
            <EmailField
              field={email}
              onChange={(e) => setEmail({ ...email, value: e.target.value })}
            />
          </div>

          <div className="row">
            <label htmlFor="userGroupId">Hatáskör*</label>
            <Dropdown
              compact={true}
              items={userGroups.map((userGroup) => ({
                id: userGroup.id,
                value: getUserGroupName(userGroup),
              }))}
              value={parseInt(userGroupId.value)}
              onChange={(userGroup: number) =>
                setUserGroupId({ ...userGroupId, value: userGroup.toString() })
              }
            />
          </div>

          <div className="info-text">A hitelesítéshez add meg a jelszavad!</div>

          <div className="row">
            <label htmlFor="password">Jelszavad</label>
            <PasswordField
              field={password}
              onChange={(e) =>
                setPassword({ ...password, value: e.target.value })
              }
            />
          </div>
        </main>

        <footer>
          <button className="button" onClick={save}>
            Meghívó küldése
          </button>
        </footer>
      </Modal>
    </div>
  );
};
