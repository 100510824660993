import { useSelector } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import { EventCard } from '../../component/events'
import { State } from '../../state'
import './events.page.scss'

interface EventsProps { }

export const Events = ( props: EventsProps ) => {

    const { eventCount, userCount, uploadedImageCount, mostInterestingEvent, leastInterestingEvent, nextEvent } = useSelector( ( state: State ) => state.events.stats )

    return (

        <main className="page page--events">

            <div className="main-div">

                <div className="navigation-title">

                    <h1 className="title-text-h1">Események áttekintése</h1>

                </div>

                <div className="top-container container">

                    <div className="card-div">

                        <div className="sub-container">

                            <h2 className="title-text-h2">Legtöbb érdeklődő</h2>
                            <h3 className="title-text-h3 title-text-h3-margin"></h3>
                            <NavLink to={ `/esemenyek/${ mostInterestingEvent?.id }` }>
                                <EventCard eventElement={ mostInterestingEvent } />
                            </NavLink>

                        </div>

                        <div className="separator"></div>

                        <div className="sub-container">

                            <h2 className="title-text-h2">Legkevesebb érdeklődő</h2>
                            <h3 className="title-text-h3 title-text-h3-margin"></h3>
                            <NavLink to={ `/esemenyek/${ leastInterestingEvent?.id }` }>
                                <EventCard eventElement={ leastInterestingEvent } />
                            </NavLink>

                        </div>

                        <div className="separator"></div>

                        <div className="sub-container">

                            <h2 className="title-text-h2">Közelgő esemény</h2>
                            <h3 className="title-text-h3 title-text-h3-margin"></h3>
                            <NavLink to={ `/esemenyek/${ nextEvent?.id }` }>
                                <EventCard eventElement={ nextEvent } />
                            </NavLink>

                        </div>

                    </div>

                </div>

                <Link to="lista" className="nav_decoration" style={ { textDecoration: 'none' } } >

                    <div className="container events_category_container">

                        <div className="card-div card_container">

                            <img className="image" alt="Események áttekintése országos események logója" src="/image/hungary.svg" />

                            <div className="text_container">

                                <h2 className="title-text-h2 title">Országos események</h2>
                                <span className="label-text description">{ eventCount } esemény • { userCount } résztvevő • { uploadedImageCount } bejelentett kép vár jóváhagyásra</span>

                            </div>

                        </div>

                    </div>

                </Link>

            </div>

        </main>

    )

}



