import { useState } from "react";
import "./info-textbox.scss";

export const InfoTextbox = (props: any) => {
  const { text } = props;
  const [infoTextVisiblility, setInfoTextVisiblility] = useState(false);
  return (
    <span
      onMouseOver={() => setInfoTextVisiblility(true)}
      onMouseOut={() => setInfoTextVisiblility(false)}
      className="material-symbols-outlined info-icon"
    >
      info
      {infoTextVisiblility && (
        <div className="info-textbox active">
          <div className="info-hover">{text}</div>
        </div>
      )}
    </span>
  );
};
