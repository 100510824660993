import { useDispatch, useSelector } from 'react-redux'
import { getAllPushMessage } from '../../../action/push'
import { GET_ALL_PUSH_ACTIONS } from '../../../action/push/push.constants'
import { Table } from '../../../component'
import { createFormattedDateTimeString } from '../../../helper'
import { State } from '../../../state'
import { PushMesageTableMenuButton } from './push-message-table-menu-button'
import './push-message-table.scss'

export const PushMessageTable = () => {
    const dispatch = useDispatch()
    const { messages } = useSelector((state: State) => state.push)
    const { page, from, to, count } = useSelector((state: State) => state.push.pagination)

    const onPageChange = (page: number) => {
        dispatch({ type: GET_ALL_PUSH_ACTIONS.GET_ALL_PUSH_CHANGE_PAGE, page: page })
        getAllPushMessage()
    }

    const columns = [
        {
            align: `center`,
            caption: `ID`,
            key: `id`,
            name: `id`,
            width: 50,
        },
        {
            caption: `Cím`,
            render: (row: any) => <span className="push-title">{row.title}</span>,
            name: `title`,
            width: 280,
        },
        {
            caption: `Szöveg`,
            render: (row: any) => <span className="push-body">{row.body}</span>,
            name: `body`,
            width: 552,
        },
        {
            align: `center`,
            caption: `Élesítés`,
            render: (data: any) => createFormattedDateTimeString(data.immediate ? data.created : data.timing),
            name: `created`,
            width: 150,
        },
        {
            align: `center`,
            caption: `Státus`,
            render: (data: any) => (data.immediate ? 'Kiküldve' : data.timing > new Date().getTime() / 1000 ? 'Aktív' : 'Kiküldve'),
            name: `topic`,
            width: 170,
        },
        {
            align: `center`,
            caption: ``,
            name: `actions`,
            render: (data: any) => <PushMesageTableMenuButton pushMessageElement={data} />,
            width: 80,
        },
    ]

    return <Table className="push-message-table" columns={columns} dataset={messages !== null && messages !== undefined ? messages : []} pagination={{ page: page, from: from, to: to, count: count, onPageChange: onPageChange }} />
}
