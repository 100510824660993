import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './calendar.scss'

interface CalendarItem {

    startDate: number
    endDate: number | null

}

interface CalendarProps {

    items: CalendarItem[]

    onMonthChange?: ( year: number, month: number ) => void
    onItemClick?: ( item: any ) => void

}

const dayOfWeek = ( timestamp: number ): number => {

    const day = new Date( timestamp * 1000 ).getDay()

    return day === 0 ? 6 : day - 1

}

const overlaps = ( a: any, b: any ): boolean => {

    return (
        ( ( a.startDate < b.startDate ) && ( a.endDate >= b.endDate ) ) ||
        ( ( a.startDate >= b.startDate ) && ( a.startDate < b.endDate ) ) ||
        ( ( a.endDate >= b.startDate ) && ( a.endDate < b.endDate ) )
    )

}

const checklevel = ( item: any, items: any[], level: number ): number => {

    for ( const i of items ) {

        if ( level === i.level && overlaps( item, i ) ) {

            return checklevel( item, items, level + 1 )

        }

    }

    return level

}

export const Calendar = ( props: CalendarProps ) => {

    const navigate = useNavigate()

    const onItemClick = ( item: any ) => {

        if ( props.onItemClick ) props.onItemClick( item )

    }

    const today = new Date()
    const [ year, setYear ] = useState( today.getFullYear() )
    const [ month, setMonth ] = useState( today.getMonth() )
    const months = [ "január", "február", "március", "április", "május", "június", "július", "augusztus", "szeptember", "október", "november", "december" ]

    const monthsFirstWeekday = new Date( year, month ).getDay() === 0 ? 6 : new Date( year, month ).getDay() - 1
    const firstDayOfTheCalendar = new Date( year, month, 1 )
    firstDayOfTheCalendar.setDate( 1 - monthsFirstWeekday )
    const lastDayOfTheMonth = new Date( month === 11 ? year + 1 : year, month === 11 ? 0 : month + 1, 1 )
    lastDayOfTheMonth.setDate( 0 )

    const weeks = []
    const items: any[] = []

    for ( const dayIterator = firstDayOfTheCalendar; dayIterator.getTime() <= lastDayOfTheMonth.getTime(); ) {

        const week: { year: number, month: number, day: number }[] = []

        for ( let i = 0; i < 7; i++ ) {

            week.push( {
                year: dayIterator.getFullYear(),
                month: dayIterator.getMonth(),
                day: dayIterator.getDate()
            } )

            dayIterator.setDate( dayIterator.getDate() + 1 )

        }

        weeks.push( { days: week, items: [] as any[] } )

    }

    for ( const item of props.items ) {

        const level = checklevel( item, items, 0 )

        items.push( {

            ...item,
            level: level

        } )

        weeks.map( week => {

            const weekstart = new Date( week.days[ 0 ].year, week.days[ 0 ].month, week.days[ 0 ].day )
            const weekend = new Date( week.days[ 0 ].year, week.days[ 0 ].month, week.days[ 0 ].day )
            weekend.setDate( week.days[ 0 ].day + 7 )

            if ( overlaps( item, { startDate: weekstart.getTime() / 1000, endDate: weekend.getTime() / 1000 } ) ) {

                const start = item.startDate <= ( weekstart.getTime() / 1000 ) ? 0 : dayOfWeek( item.startDate )

                week.items.push( {

                    event: item,
                    start: start,
                    width: item.endDate !== null && item.endDate <= ( weekend.getTime() / 1000 ) ? ( dayOfWeek( item.endDate ) - start + 1 ) : ( 7 - start ),
                    level: level,
                    starting: ( item.startDate >= ( weekstart.getTime() / 1000 ) ),
                    ending: item.endDate !== null && ( item.endDate <= ( weekend.getTime() / 1000 ) )

                } )

            }

        } )

    }


    const prevMonth = () => {

        if ( month === 0 ) {

            setMonth( 11 )
            setYear( year - 1 )
            props.onMonthChange && props.onMonthChange( year - 1, 11 )

        } else {

            setMonth( month - 1 )
            props.onMonthChange && props.onMonthChange( year, month - 1 )

        }

    }
    const nextMonth = () => {

        if ( month === 11 ) {

            setMonth( 0 )
            setYear( year + 1 )
            props.onMonthChange && props.onMonthChange( year + 1, 0 )

        } else {

            setMonth( month + 1 )
            props.onMonthChange && props.onMonthChange( year, month + 1 )

        }

    }

    return (

        <div className="calendar">

            <header>

                <button onClick={ prevMonth }><img src="/image/chevron-left.svg" /></button>
                <h2>{ year }. { months[ month ] }</h2>
                <button onClick={ nextMonth }><img src="/image/chevron-right.svg" /></button>

            </header>

            <div className="calendar__header">

                <div className="column">Hétfő</div>
                <div className="column">Kedd</div>
                <div className="column">Szerda</div>
                <div className="column">Csütörtök</div>
                <div className="column">Péntek</div>
                <div className="column">Szombat</div>
                <div className="column">Vasárnap</div>

            </div>

            <div className="calendar__body">

                { weeks.map( week => (

                    <div className="row">

                        { week.days.map( day => (

                            <div className={ `column${ day.month !== month ? ` column--not-this-month` : `` }${ day.year === today.getFullYear() && day.month === today.getMonth() && day.day === today.getDate() ? ` column--today` : `` }` } style={ { height: `${ ( week.items.length * 36 ) + 56 + 37 }px` } }>

                                <div className="day">{ day.day }</div>

                            </div>

                        ) ) }

                        { week.items.map( item => (

                            <span className={ `${ item.starting ? `starting` : `` } ${ item.ending ? `ending` : `` }` } style={ { left: `${ item.start * 100 / 7 }%`, width: `calc( ${ item.width * 100 / 7 }% - 24px - 12px )`, top: `${ ( item.level * 36 ) + 56 }px` } } onClick={ e => onItemClick( item ) }>

                                { item.event.title }

                            </span>

                        ) ) }

                    </div>

                ) ) }

            </div>

        </div>

    )

}
