import ReactDOM from 'react-dom/client'
import 'reflect-metadata'
import { initializeApp } from 'firebase/app'
import { getMessaging, onMessage } from 'firebase/messaging'
import { App } from './app'
import './asset/scss/index.scss'

function setInnerHeight () {
    document.body.setAttribute( `style`, `--inner-height: ${ window.innerHeight }px` )
}
setInnerHeight()
window.onresize = setInnerHeight

const firebaseConfig = {
    apiKey: "AIzaSyC7z7xCVR7Bq7UL1b3r3uonkXoc3GYlKEQ",
    authDomain: "dkapp-9dd50.firebaseapp.com",
    projectId: "dkapp-9dd50",
    storageBucket: "dkapp-9dd50.appspot.com",
    messagingSenderId: "516401748398",
    appId: "1:516401748398:web:59301e6ebdd9015da0503f",
    measurementId: "G-C53H8L3J3Y"
}
initializeApp( firebaseConfig )

ReactDOM.createRoot( document.querySelector( `#app` ) as HTMLElement ).render( <App /> )
