import { DragEventHandler, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { deletePoliticianQuestion } from '../../../action/politician'
import { CREATE_POLITICIAN_QUESTION_ACTIONS } from '../../../action/politician/politicians.constants'
import { MenuButton } from '../../../component'
import { PoliticianQuestion } from '../../../entity'
import { MessageModal } from '../../modal'
import './politician-question-element.scss'

interface PoliticianQuestionElementProps {
    questionElement: PoliticianQuestion
    onModifyClick: any

    positon: number
    onDragStart: any
    onDragEnter: any
    onDragEnd: DragEventHandler
}

export const PoliticianQuestionElement = ( props: PoliticianQuestionElementProps ) => {
  
    const dispatch = useDispatch()
    const [ deleteModalVisibility, setDeleteModalVisibility ] = useState( false )

    const menuOptions = [
        { id: 1, value: 'Szerkesztés', icon: "edit.svg" },
        { id: 2, value: 'Törlés', icon: "trash.svg" },
    ]
    
    const onMenuItemChange = async ( itemId: any ) => {
        switch(itemId) {
            case 1:
                props.onModifyClick()
                dispatch( { type: CREATE_POLITICIAN_QUESTION_ACTIONS.CREATE_POLITICIAN_QUESTION_MODIFY_QUESTION, question: props.questionElement } )
                break
            default:
                setDeleteModalVisibility(true)
                break
        }
    }

    const onAddQuestionCloseButtonClick = async ( event: MouseEvent ) => {
        event.preventDefault()
    }


    const dragStart = ( e: any, position: number ) => {
        props.onDragStart( position)
    }

    const dragEnter = ( e: any, position: number ) => {
        props.onDragEnter( position)
    }

    return (
        <li key={ props.questionElement.question } className="politician-question-list__politician-question-element" 
        draggable      
        onDragStart={(e) => dragStart(e, props.positon)}
        onDragEnter={(e) => dragEnter(e, props.positon)}
        onDragEnd={props.onDragEnd} >

            <span className="material-symbols-outlined drag_icon">open_with</span>

            <span className="label-text question">{ props.questionElement.question } </span>
                
            <div className="menu-container" >
                <MenuButton hasBackground = { true } onChange={ onMenuItemChange } items={ menuOptions }/>
            </div>

            {
                deleteModalVisibility ?
                <MessageModal title={ "Figyelmeztetés" } message={ "Biztosan törölni szeretnéd a kérdést?" }  
                        onOkClick={ () => { deletePoliticianQuestion(props.questionElement.id) } }   
                        onCloseClick={ () => { setDeleteModalVisibility(false)} } />
                : null
            }
            
            
        </li >
    )

}
