import { MouseEvent } from 'react'
import './search.button.scss'

interface SearchButtonProps {
    onClick: ( event: MouseEvent ) => void
}

export const SearchButton = ( props: SearchButtonProps ) => {

    return (
        <button className="button button-icon" onClick={ props.onClick } >
            <img alt="keresés ikon" src="/image/search.svg" />
         </button>
    )
}
