import { ChangeEvent, createRef, MouseEvent, KeyboardEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { getAllNews } from '../../action'
import { Tag, Dropdown } from '../../component'
import { NewsModal, NewsTable } from '../../component/news'
import { SearchButton, SearchInput } from '../../component/search'
import { State } from '../../state'
import './news-list.page.scss'
import { createFormattedDateTimeString } from '../../helper'
import { NewsCategoryModal } from '../../component/news/news-category-modal'
import { CREATE_NEWS_ACTIONS, NEWS_ACTIONS } from '../../action/news/news.constants'
import { NewsTableMenuButton } from '../../component/news/news-table/new-table-menu-button'
import { News } from '../../entity'

interface NewsListProps { }

export const NewsList = ( props: NewsListProps ) => {

    const searchInputRef = createRef<HTMLInputElement>()
    const dispatch = useDispatch()

    const { highlightedNews, highlightedNewsStats } = useSelector( ( state: State ) => state.newsStats )
    const { politicians } = useSelector( ( state: State ) => state.politicians )
    const { news, selectedPolitician } = useSelector( ( state: State ) => state.news )
    const deleted = useSelector( ( state: State ) => state.news.deleted )


    const [ newsCategoryModalVisibility, setNewsCategoryModalVisibility ] = useState<boolean>( false )

    useEffect( () => {
        dispatch( { type: CREATE_NEWS_ACTIONS.CREATE_NEWS_CLEAR } )
    }, [] )

    const onSearchChange = async ( event: ChangeEvent<HTMLInputElement> ) => {
        event.preventDefault()

        dispatch( { type: NEWS_ACTIONS.GET_NEWS_SEARCH, search: event.target.value } )

        if ( event.target.value.length == 0 ) {
            await getAllNews()
        }
    }

    const onSearchButtonClick = async ( event: MouseEvent | KeyboardEvent ) => {
        event.preventDefault()

        await getAllNews()
    }

    const onDeltedButtonClick = async ( event: MouseEvent ) => {
        event.preventDefault()

        dispatch( { type: NEWS_ACTIONS.GET_NEWS_DELETED } )
        await getAllNews()

    }

    const onCategoriesButtonClick = async ( event: MouseEvent ) => {
        event.preventDefault()

        dispatch( { type: 'CREATE_NEWS_CATEGORY_CLEAR' } )

        setNewsCategoryModalVisibility( true )
    }

    const onCategoriesCloseButtonClick = async ( event: MouseEvent ) => {
        event.preventDefault()

        setNewsCategoryModalVisibility( false )
    }

    const onInfoMenuButtonClick = async ( event: MouseEvent ) => {
        event.preventDefault()
        //TODO  
    }

    const [ previewNews, setPreviewNews ] = useState<News | null >( null )

    const onPreviewButtonClick = ( news: News ) => {
        setPreviewNews(news)
    }

    const setSelectedPolitician = async ( id: number ) => {

        dispatch( { type: `NEWS_SET_SELECTED_POLITICIAN`, selectedPolitician: id } )
        await getAllNews()

    }

    return (
        <main className="page page-news page-news-list">

            <div className="main-div">

                <div className="navigation-title">
                    <NavLink to="/hirek" >
                        <h1 className="title-text-h1">Hírek áttekintése { '>' }</h1>
                    </NavLink>
                    <h1 className="title-text-h1">{ deleted == 0 ? "Országos hírek" : "Országos hírek (Törölt)" }</h1>
                </div>

                <div className="toolbar">
                    <SearchInput onSearch={ onSearchButtonClick } onChange={ onSearchChange } placeholder="Keresés a hírek között" />
                    <SearchButton onClick={ onSearchButtonClick } />
                    <button className="button button-white delete-button" onClick={ onDeltedButtonClick } > { deleted == 0 ? "Törölt" : "Nem törölt" }</button>
                    <button className="button categories-button" onClick={ onCategoriesButtonClick } >Kategóriák</button>
                    <NavLink className="new-a" to="/hirek/uj">
                        <button className="button new-button">+ Új hír</button>
                    </NavLink>
                </div>

                <div className="info">
                    <h2 className="title-text-h2">Kiemelt Országos hír</h2>

                    { highlightedNews == null ? "" :
                        <div className="info inner">

                            <span className="label-text title">{ highlightedNews.statementBy != null ? highlightedNews.statementBy + ':' : "" } { highlightedNews.title }</span>

                            <div className="tag-container">
                                { highlightedNews.category != null ? <Tag text={ highlightedNews.category.name } color={ highlightedNews.category.color } /> : "" }
                            </div>

                            <span className="label-text date">{ createFormattedDateTimeString( highlightedNews.visibleFrom ) } </span>

                            <span className="label-text open-count">Megnyitások száma</span>

                            <div className="count-container">
                                <span className="label-text count">{ highlightedNewsStats?.openCount }</span>
                                <span className="label-text person">fő</span>
                            </div>

                            <div className="menu-container" >
                                <NewsTableMenuButton newsElement={ highlightedNews } onPreviewClick={ onPreviewButtonClick } />
                            </div>
                        </div>
                    }
                </div>

                <div className="filters">

                    <div className="politician-filter">

                        <Dropdown value={ selectedPolitician } items={ [ { id: -1, value: `Bármelyik politikus` }, ... ( politicians || [] ).map( p => ( { id: p.id, value: `${ p.lastName } ${ p.firstName }` } ) ) ] } onChange={ setSelectedPolitician } compact={ false } />

                    </div>

                </div>

                <NewsTable newsArray={ news || [] } />

            </div>

            { newsCategoryModalVisibility ? <NewsCategoryModal onModalClose={ () => setNewsCategoryModalVisibility( false ) } onCloseClick={ onCategoriesCloseButtonClick } /> : null }

            { previewNews !== null ? <NewsModal newsElement={ previewNews } onModalClose={ () => setPreviewNews( null ) } onCloseClick={ e => setPreviewNews( null ) } /> : null }

        </main>
    )

}
