import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { State } from '../../state'

interface RequireAuthProps {
    children: JSX.Element
}

export const RequireAuth = ( props: RequireAuthProps ) => {
    const authenticatedUser = useSelector( ( state: State ) => state.session.authenticatedUser )
    if ( authenticatedUser === null || authenticatedUser === undefined ) {
        return <Navigate to={ `/bejelentkezes?redirect=${ encodeURIComponent( window.location.pathname ) }` } />
    }
    return props.children
}
