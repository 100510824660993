import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetAllPushMessageDTO } from '../../dto/push/get-all-push-message.dto'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { GET_ALL_PUSH_ACTIONS } from './push.constants'

export const getAllPushMessage = async () => {
    const promise = createCall(`firebase?page=` + store.getState().push.pagination.page, BASE_METHODS.GET)
    promise.then(async (response) => {
        let body: GetAllPushMessageDTO

        try {
            body = plainToClass(GetAllPushMessageDTO, JSON.parse(await response!.text()))
        } catch (exception) {
            store.dispatch({ type: GET_ALL_PUSH_ACTIONS.GET_ALL_PUSH_INVALID_JSON })
            return
        }

        if ((await validate(body)).length > 0) {
            store.dispatch({ type: GET_ALL_PUSH_ACTIONS.GET_ALL_PUSH_INVALID_RESPONSE })
            return
        }

        store.dispatch({ type: GET_ALL_PUSH_ACTIONS.GET_ALL_PUSH_OK, messages: body.messages, pagination: body.pagination })
    })

    promise.catch((error) => {
        store.dispatch({ type: error })
    })
}
