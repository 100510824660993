import { Expose } from 'class-transformer'
import { IsDefined } from 'class-validator'
import { UserRound } from '../../entity'

export class ListUserRoundsDTO {

    @Expose()
    @IsDefined()
    userRounds!: UserRound[]

    @Expose()
    @IsDefined()
    pagination!: any

}

