import { MouseEvent } from 'react'
import { Quote } from '../../entity'
import './quote-card.scss'

interface QuoteCardProps {

    placeholder?: string
    quote: Quote | null
    onClick?: ( event: MouseEvent ) => void
    onDeleteClick?: ( event: MouseEvent ) => void

}

export const QuoteCard = ( props: QuoteCardProps ) => {

    return (

        <div className="quote-card" onClick={ e => props.quote === null && props.onClick && props.onClick( e ) }>

            { props.quote === null ? (

                <>

                    <div className="quote-card__add">

                        <span className="material-symbols-outlined">add</span>

                    </div>

                    <div className="quote-card__placeholder">

                        { props.placeholder ?? `Politikusi idézet a következő napra` }

                    </div>

                </>

            ) : (

                <>

                    <picture className="quote-card__image">

                        <img alt="" src={ props.quote.quotePolitician?.image?.url } />

                    </picture>

                    <picture className="quote-card__party-image">

                        <img alt="" src={ props.quote.quoteParty?.image?.url } />

                    </picture>

                    <div className="mask-top"></div>
                    <div className="mask-bottom"></div>

                    <div className="quote-card__quote">

                        “{ props.quote.quote }”

                    </div>

                    <div className="quote-card__quote-id">

                        #{ props.quote.id }

                    </div>

                    <div className="quote-card__remove" onClick={ e => props.onDeleteClick && props.onDeleteClick( e ) }>

                        <span className="material-symbols-outlined">delete</span>

                    </div>

                </>

            ) }

        </div>

    )

}
