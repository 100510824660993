import { Expose, Type } from 'class-transformer'
import { ValidateNested } from 'class-validator'
import { FloatingMessage } from '../../entity'

export class GetFloatingMessageDTO {

    @Expose()
    @ValidateNested()
    @Type( () => FloatingMessage )
    floatingMessage!: FloatingMessage


}

