import { store } from "../../store"
import { Season } from '../../entity'

export const createSeason = async ( season: Season ) => {

    let response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/seasons`, {
            body: JSON.stringify( season ),
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'POST'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'CREATE_SEASON_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'CREATE_SEASON_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'CREATE_SEASON_UNEXPECTEDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'CREATE_SEASON_OK' } )

}