import { Expose } from 'class-transformer'
import { MediaLibraryFile } from '../../entity'

export class GetDefaultReactionsDTO {

    @Expose()
    left!: MediaLibraryFile

    @Expose()
    right!: MediaLibraryFile

}
