import { GET_DASHBOARD_STATS_ACTIONS } from "../action/dashboard/dashboard.constants"
import { DashboardState } from "../state"

const initialDashboardState: DashboardState  = {
    applicationLaunchedCount: 0,
    applicationLaunchedCountPercent: 0,
    userCount: 0,
    userCountPercent: 0,
    lastEvents: null,
    lastNews: null,
    lastPushMessages: null
}

export const dashboardReducer = ( state: DashboardState = initialDashboardState, action: any ): DashboardState => {
    switch ( action.type ) {
        case GET_DASHBOARD_STATS_ACTIONS.GET_DASHBOARD_STATS_OK:
            return { ...state, 
                lastEvents: action.lastEvents, 
                lastNews: action.lastNews, 
                applicationLaunchedCount: action.applicationLaunchedCount,
                applicationLaunchedCountPercent: action.applicationLaunchedCountPercent, 
                userCount: action.userCount, 
                userCountPercent: action.userCountPercent, 
                lastPushMessages: action.lastPushMessages }
        case GET_DASHBOARD_STATS_ACTIONS.GET_DASHBOARD_STATS_INVALID_JSON:
            return { ...state, 
                lastEvents: null, 
                lastNews: null, 
                lastPushMessages: null, 
                applicationLaunchedCount: 0, 
                userCount: 0,
                 applicationLaunchedCountPercent: 0, 
                 userCountPercent: 0 
            }
        case GET_DASHBOARD_STATS_ACTIONS.GET_DASHBOARD_STATS_INVALID_RESPONSE:
            return { ...state, 
                lastEvents: null, 
                lastNews: null, 
                lastPushMessages: null, 
                applicationLaunchedCount: 0, 
                userCount: 0,
                 applicationLaunchedCountPercent: 0, 
                 userCountPercent: 0 
            }
        default:
            return state
    }
}
