import { store } from '../../store'
import { getFolder } from './get-folder'

export const uploadFiles = async ( id: number = 1, files: FileList | null ) => {

    if ( files === null ) {

        return

    }

    let response
    const formData = new FormData()

    for ( let i = 0; i < files.length; i++ ) {

        formData.append( `files`, files.item( i ) as Blob )

    }

    try {
        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/media-library/${ id }`, {
            body: formData,
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token
            },
            method: 'POST'
        } )

    } catch ( exception ) {
        store.dispatch( { type: 'MEDIA_LIBRARY_UPLOAD_FILES_TIMEOUT' } )
        return
    }

    if ( response.status === 401 ) {
        store.dispatch( { type: 'MEDIA_LIBRARY_UPLOAD_FILES_UNAUTHORIZED' } )
        return
    }

    if ( response.status !== 200 ) {
        store.dispatch( { type: 'MEDIA_LIBRARY_UPLOAD_FILES_UNEXPECTEDRESPONSE' } )
        return
    }

    store.dispatch( { type: 'MEDIA_LIBRARY_UPLOAD_FILES_OK' } )
    getFolder( id )

}
