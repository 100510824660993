import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { CheckAdminSetPasswordDTO } from '../../dto'
import { store } from '../../store'

export const checkAdminSetPassword = async ( token: string ) => {

    let body: CheckAdminSetPasswordDTO
    let response: Response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/users/admin-password/${ token }`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'get'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'CHECK_ADMIN_SET_PASSWORD_TIMEOUT' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'CHECK_ADMIN_SET_PASSWORD_UNEXPECTEDRESPONSE' } )
        return

    }

    try {

        body = plainToClass( CheckAdminSetPasswordDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'CHECK_ADMIN_SET_PASSWORD_INVALIDJSON' } )
        return

    }

    if ( ( await validate( body ) ).length > 0 ) {

        store.dispatch( { type: 'CHECK_ADMIN_SET_PASSWORD_INVALIDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'CHECK_ADMIN_SET_PASSWORD_OK', user: body.user } )

}
