import { store } from '../../store'

export const replaceQuoteCard = async ( date: Date, slot: number, id: number | null ) => {

    date.setHours( 2, 0, 0, 0 )

    let response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/quotes/scheduled`, {
            body: JSON.stringify( {
                date: Math.floor( date.getTime() / 1000 ),
                slot: slot,
                id: id
            } ),
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'POST'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'REPLACE_QUOTE_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'REPLACE_QUOTE_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'REPLACE_QUOTE_UNEXPECTEDRESPONSE' } )
        return

    }

}
