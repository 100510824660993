import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  getThreeScheduledQuotes,
  listAllQuotes,
  replaceQuoteCard,
} from "../../../action/quotes";
import { QuoteCard, QuotePartiesModal, QuotesModal } from "../../../component";
import { State } from "../../../state";
import "./scheduled-quotes.page.scss";

export const ScheduledQuotesPage = () => {
  const weekdays = [
    `Vasárnap`,
    `Hétfő`,
    `Kedd`,
    `Szerda`,
    `Csütörtök`,
    `Péntek`,
    `Szombat`,
  ];

  const { scheduledQuotes } = useSelector((state: State) => state.quotes);

  const [showQuotePartiesModal, setShowQuotePartiesModal] = useState(false);
  const [replaceScheduledQuote, setReplaceScheduledQuote] = useState(
    null as number | null
  );
  const [showQuotesModal, setShowQuotesModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null as Date | null);

  const onQuoteCardClick = (date: Date, num: number) => {
    setSelectedDate(date);
    setReplaceScheduledQuote(num);
    setShowQuotesModal(true);
  };

  const onQuoteSelected = async (id: number | null) => {
    await replaceQuoteCard(selectedDate!, replaceScheduledQuote!, id);
    await getThreeScheduledQuotes();
    setSelectedDate(null);
    setShowQuotesModal(false);
    setReplaceScheduledQuote(null);
    await listAllQuotes();
  };

  const onRemoveCard = async (date: Date, slot: number) => {
    await replaceQuoteCard(date, slot, null);
    await getThreeScheduledQuotes();
    await listAllQuotes();
  };
  return (
    <main className="page page--scheduled-quotes">
      <div className="main-div">
        <div className="navigation-title">
          <NavLink to="/velemenyvezerek">
            <h1 className="title-text-h1">Véleményvezérek &gt;</h1>
          </NavLink>
          <h1 className="title-text-h1">Ütemezett idézetkártyák</h1>
        </div>

        <div className="toolbar">
          <NavLink to="/velemenyvezerek" style={{ marginRight: `auto` }}>
            <button
              className="button button--ghost"
              style={
                window.innerWidth >= 576
                  ? { marginLeft: 0, minWidth: 173 }
                  : { marginLeft: 0, minWidth: 100 }
              }
            >
              Vissza
            </button>
          </NavLink>

          <button
            className="button button--ghost"
            style={
              window.innerWidth >= 576
                ? { marginLeft: `auto`, minWidth: 239 }
                : { marginLeft: `auto`, minWidth: 100 }
            }
            onClick={(e) => setShowQuotePartiesModal(true)}
          >
            Politikai pártok
          </button>

          <NavLink to="/velemenyvezerek/idezetek">
            <button
              className="button"
              style={
                window.innerWidth >= 576
                  ? { minWidth: 202 }
                  : { minWidth: 100, marginRight: 30 }
              }
            >
              + Új idézet
            </button>
          </NavLink>
        </div>

        {scheduledQuotes.map((sch) => (
          <div className="card-div">
            <div className="caption">
              {sch.date}
              <br />
              {weekdays[new Date(sch.date * 1000).getDay()]}
            </div>

            <div className="quote-cards">
              {sch.quotes.map((quote: any, index: number) => (
                <QuoteCard
                  key={index}
                  quote={quote}
                  onClick={(e) =>
                    onQuoteCardClick(new Date( sch.date ), index)
                  }
                  onDeleteClick={(e) =>
                    onRemoveCard(new Date( sch.date ), index)
                  }
                />
              ))}
            </div>
          </div>
        ))}
      </div>

      {showQuotePartiesModal ? (
        <QuotePartiesModal
          onModalClose={() => setShowQuotePartiesModal(false)}
        />
      ) : null}

      {showQuotesModal ? (
        <QuotesModal
          onModalClose={() => setShowQuotesModal(false)}
          onSelect={onQuoteSelected}
        />
      ) : null}
    </main>
  );
};
