import './news-statistics.tab.scss'
import Chart from 'react-apexcharts'
import { useSelector } from 'react-redux'
import { State } from '../../../state'

interface EventsStatisticsTabProps { }

export const EventsStatisticsTab = ( props: EventsStatisticsTabProps ) => {

    const { eventAttendanceCount, eventAttendanceXAxis, eventAttendanceData,  } = useSelector( ( state: State ) => state.statistics.eventsStats )


    const eventAttendanceOptions = {
        chart: {
            id: 'line-chart',    
        },
        dataLabels: {
            enabled: true
        },
        stroke: {
            curve: "smooth" as "smooth",
        },
        colors: ['#0267AA'],
        xaxis: {
          categories: eventAttendanceXAxis
        }
    }

    const eventAttendanceSeries = [{
        name: 'Ott lesznek az eseményen',
        data: eventAttendanceData
    }]
  

    return (
        <div className="card-div">

            <div className="container">
                <div className="card-div card-div-right-margin">

                    <div className="container column">
                        <div className="info-slot">
                            <div className="info-slot__description">
                                <span className="caption"> Ott lesznek az eseményen </span>
                                <span className="additional-info"> Hányan jelölték azt az egyes egyeményeknél, hogy ott lesznek </span>
                            </div>

                            <div className="info-slot__info">
                                <span className="value">{ eventAttendanceCount }</span> { "db" }
                            </div>
                        </div>

                        <Chart options={ eventAttendanceOptions } series={ eventAttendanceSeries } type="bar" height={ 260 } />
                    </div>

                </div>
                
            </div>

        </div>
    )
}


