import './seasons.page.scss'
import React, { useState } from 'react'
import { Season as SeasonEntity } from '../../entity'
import { Season } from '../../component/season'
import { State } from '../../state'
import { useSelector } from 'react-redux'
import { SeasonModal } from '../../component/season-modal'
import { NavLink } from 'react-router-dom'

export const SeasonsPage = () => {

    const { active, next, previous } = useSelector( ( state: State ) => state.seasons )
    const [ season, setSeason ] = useState( null as SeasonEntity | null )
    const [ showModal, setShowModal ] = useState( false )

    const create = () => {

        setSeason( null )
        setShowModal( true )

    }

    const modify = ( season: SeasonEntity ) => {

        setSeason( season )
        setShowModal( true )

    }

    return (

        <main className="page page--seasons">

            <div className="main-div">

                <div className="navigation-title">

                    <h1 className="title-text-h1">Küldetések szezonjai</h1>

                </div>

                <div className="toolbar">

                    <button className="button" disabled>

                        Beállítások

                    </button>

                    <button className="button" onClick={ create }>

                        + Új szezon

                    </button>

                </div>

                <div className="card-div card-div--seasons">

                    <div className="seasons">

                        <div className="seasons__active">

                            <div className="seasons__caption">

                                Jelenlegi szezon

                            </div>

                            <div className="seasons__list">

                                { active !== null ? (

                                    <Season season={ active } next={ false } />

                                ) : (

                                    <div className="season-info">Jelenleg nincs elérhető szezon!</div>

                                ) }

                            </div>

                        </div>

                        <div className="seasons__next">

                            <div className="seasons__caption">

                                Következő szezonok

                            </div>

                            <div className="seasons__list">

                                { next.length > 0 ? next.map( season => (

                                    <Season season={ season } next={ true } onShowModal={ modify } />

                                ) ) : (

                                    <div className="season-info">Jelenleg nincs elérhető szezon!</div>

                                ) }

                            </div>

                        </div>

                        <div className="seasons__previous">

                            <div className="seasons__caption">

                                Lejárt szezonok

                            </div>

                            <div className="seasons__list">

                                { previous.length > 0 ? previous.map( season => (

                                    <Season season={ season } next={ false } />

                                ) ) : (

                                    <div className="season-info">Jelenleg nincs elérhető szezon!</div>

                                ) }

                            </div>

                        </div>

                    </div>

                </div>

            </div>

            { showModal ? (

                <SeasonModal season={ season } onClose={ () => setShowModal( false ) } />

            ) : null }

        </main>

    )

}
