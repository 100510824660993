import { store } from '../../store'
import { League, Quest } from '../../entity'

export const saveLeague = async ( id: number, league: League ) => {

    let response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/leagues/${ id }`, {
            body: JSON.stringify( {
                name: league.name,
                advanceImmediately: league.advanceImmediately,
                instruction: league.instruction,
                requiredAdvanceEXP: parseInt( league.requiredAdvanceEXP as any ),
                allQuestsRequiredToAdvance: league.allQuestsRequiredToAdvance,
                requiredAdvanceTopPercentage: league.requiredAdvanceTopPercentage
            } ),
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'POST'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'SAVE_LEAGUE_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'SAVE_LEAGUE_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'SAVE_LEAGUE_UNEXPECTEDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'SAVE_LEAGUE_OK' } )

}
