import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetScheduledQuotesDTO } from '../../dto/quotes'
import { store } from '../../store'

export const getScheduledQuotes = async ( date: Date ) => {

    let body, response
    const formatedDate = `${ date.getFullYear() }-${ ( date.getMonth() + 1 ).toString().padStart( 2, `0` ) }-${ date.getDate().toString().padStart( 2, `0` ) }`

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/quotes/scheduled/${ formatedDate }`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'get'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'GET_SCHEDULED_QUOTES_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'GET_SCHEDULED_QUOTES_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'GET_SCHEDULED_QUOTES_UNEXPECTEDRESPONSE' } )
        return

    }

    try {

        body = plainToClass( GetScheduledQuotesDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'GET_SCHEDULED_QUOTES_INVALIDJSON' } )
        return

    }

    if ( ( await validate( body ) ).length > 0 ) {

        store.dispatch( { type: 'GET_SCHEDULED_QUOTES_INVALIDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'GET_SCHEDULED_QUOTES_OK', quotes: body.quotes } )

}
