export const BASE_ACTIONS = {
    ERROR_NO_USER: "ERROR_NO_USER",
    ERROR_TIMEOUT: "ERROR_TIMEOUT",
    ERROR_UNAUTHORIZED: "ERROR_UNAUTHORIZED",
    ERROR_UNEXPECTED_RESPONSE: "ERROR_UNEXPECTED_RESPONSE",
    CLEAR_ERROR: "CLEAR_ERROR",
};

export const BASE_METHODS = {
    GET: 'get',
    POST: 'post',
    DELETE: 'delete',
    PUT: 'put',
};

