import { LoaderFunctionArgs } from 'react-router-dom'
import { listVideoCategories, listVideos } from "../../action/videos"
import {store} from "../../store";

export const videosListLoader = async ( args: LoaderFunctionArgs ) => {

    await listVideoCategories()
    await listVideos()
    store.dispatch( { type: `SET_VIDEO_KEYWORD`, keyword: `` } )

}
