import { Expose } from 'class-transformer'
import { IsDefined } from 'class-validator'
import { Course } from '../../entity'

export class GetCourseDTO {

    @Expose()
    @IsDefined()
    course!: Course

}

