import { useEffect } from 'react'
import { menu, prizesMenu } from '../../menu'
import { MenuGroup } from './menu-group'
import './menu.scss'

interface MenuProps { 

    onMobileMenuToggle?: ( open: boolean ) => void

}

const activeMenu = process.env.REACT_APP_BUILD === `PRIZES` ? prizesMenu : menu

export const Menu = ( props: MenuProps ) => {

    const onScroll = ( event: any ) => {

        const scroll = document.querySelector( `.scroll` ) as HTMLElement
        const scrollbar = document.querySelector( `.scroll-bar` ) as HTMLElement

        scroll.setAttribute( 'style', `--scroll-height: ${ scrollbar.clientHeight * ( event.target.clientHeight / event.target.scrollHeight ) }px; --scroll-top: ${ scrollbar.clientHeight * ( event.target.scrollTop / event.target.scrollHeight ) }px` )

    }

    useEffect( () => {
        const scroll = document.querySelector( `.scroll` ) as HTMLElement
        const scrollbar = document.querySelector( `.scroll-bar` ) as HTMLElement
        const menu = document.querySelector( `.menu` ) as HTMLElement

        scrollbar.style.visibility = ( menu.scrollHeight <= scrollbar.clientHeight ) ? `hidden` : `visible`

        scroll.setAttribute( 'style', `--scroll-height: ${ scrollbar.clientHeight * ( menu.clientHeight / menu.scrollHeight ) }px; --scroll-top: ${ scrollbar.clientHeight * ( menu.scrollTop / menu.scrollHeight ) }px` )
    } )

    let menuGroups: JSX.Element[] = []
    activeMenu.forEach( ( menuGroup: any, index: number ) => {
        menuGroups.push( <MenuGroup key={ index } menuGroup={ menuGroup } onMobileMenuToggle= { props.onMobileMenuToggle } /> )
    } )

    return (
        <nav className="menu" onScroll={ onScroll }>
            { menuGroups }
        </nav>
    )

}
