import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { store } from '../../store'
import { ListQuestsDTO } from '../../dto/gamification'

export const listQuests = async ( seasonId?: number, leagueId?: number ) => {

    let body, response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/quests?seasonId=${ seasonId ? seasonId : `null` }${ leagueId ? `&leagueId=${ leagueId }` : `` }`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'get'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'LIST_QUESTS_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'LIST_QUESTS_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'LIST_QUESTS_UNEXPECTEDRESPONSE' } )
        return

    }

    try {

        body = plainToClass( ListQuestsDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'LIST_QUESTS_INVALIDJSON' } )
        return

    }

    if ( ( await validate( body ) ).length > 0 ) {

        store.dispatch( { type: 'LIST_QUESTS_INVALIDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'LIST_QUESTS_OK', quests: body.quests } )

}
