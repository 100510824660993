import { createBrowserRouter } from 'react-router-dom'
import { RequireAuth } from '../component'
import { DefaultLayout, LoginLayout } from '../layout'
import { resetPasswordLoader, adminSetPasswordLoader } from '../loader'
import { AdminSetPasswordPage, ForgotPasswordPage, LoginPage, ResetPasswordPage } from '../page/users'
import { WinnersPage } from '../page/gamification'
import { winnersLoader } from '../loader/winners'

export const prizesRouter = createBrowserRouter( [
    {
        children: [
            {
                element: <WinnersPage />,
                loader: winnersLoader,
                path: ''
            }
        ],
        element: <RequireAuth><DefaultLayout /></RequireAuth>,
        errorElement: null,
        path: '/'
    },
    {
        children: [
            {
                element: <LoginPage />,
                path: `bejelentkezes`
            },
            {
                element: <ForgotPasswordPage />,
                path: `elfelejtettem-a-jelszavam`
            },
            {
                element: <ResetPasswordPage />,
                loader: resetPasswordLoader,
                path: `jelszo-helyreallitasa/:token`
            },
            {
                element: <AdminSetPasswordPage />,
                loader: adminSetPasswordLoader,
                path: `adminisztrator-jelszo/:token`
            }
        ],
        element: <LoginLayout />,
        errorElement: null,
        path: '/'
    }
] )
