import './tag.scss'

interface TagProps {

    id: number
    name: string
    color: string

    onEditTag?: () => void
    onClick?: () => void

    selected?: boolean

}

export const Tag = ( props: TagProps ) => {

    const onEditTag = () => { if ( props.onEditTag ) props.onEditTag() }
    const onClick = () => { if ( props.onClick ) props.onClick() }

    return (

        <div className={ `tag-group__tag${ props.selected === true ? ` tag-group__tag--selected` : `` }` } onClick={ onClick }>

            <div className="tag-group__tag__name">{ props.name }</div>
            <div className="tag-group__tag__color" style={{backgroundColor: props.color}}></div>
            { props.onEditTag ? (

                <img alt="" className="tag-group__tag__edit" src="/image/edit.svg" onClick={ e => onEditTag() } />

            ) : null }

        </div>

    )

}