import { ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'
import { TextArea } from '../../textarea'
import './politician-question-answer-element.scss'

interface PoliticianQuestionAnswerElementProps {
    field: {
        id: number
        question: string
        error: string
        required?: boolean
        value: string
    }
}

export const PoliticianQuestionAnswerElement = ( props: PoliticianQuestionAnswerElementProps ) => {
  
    const dispatch = useDispatch()

    const onAnswerChange = ( event: ChangeEvent<HTMLTextAreaElement> ) => {
        event.preventDefault()
        dispatch( { type: 'CREATE_POLITICIAN_CHANGE_QUESTION_ANSWER', answer: event.target.value, id: props.field.id } )
    }

    return (
        <li key={ props.field.question } className="politician-question-answer-list__politician-question-answer-element">

            <div className="politician-question-answer-element-container">
                <h3 className="title-text-h3  header-text-center">{ props.field.question }</h3>

                <TextArea id={ props.field.question} field={ props.field } onChange={ onAnswerChange } placeholder="" category='answer'/>
            </div>
                

        </li >
    )

}
