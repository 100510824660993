import { Expose } from 'class-transformer'
import { IsDefined } from 'class-validator'
import { CourseTopic } from '../../entity'

export class ListCourseTopicsDTO {

    @Expose()
    @IsDefined()
    courseTopics!: CourseTopic[]

}

