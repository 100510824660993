import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { deleteEvent, getEvents } from '../../action/events';
import { EventsTable } from '../../component/events';
import { SearchButton, SearchInput } from '../../component/search';
import { State } from '../../state';
import './events-list.page.scss';
import { Dropdown, Modal } from '../../component';
import { createFormattedDateTimeString, visibility } from '../../helper';
import { EventsTableMenuButton } from '../../component/events/events-table-menu-button';
import { useState } from 'react';
import { EventModal } from '../../component/event-modal';

interface EventsListProps {}

export const EventsList = ( props: EventsListProps ) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { politicians } = useSelector( ( state: State ) => state.politicians );
  const { highlightedEvent, showArchive, selectedPoliticianForSearch } = useSelector( ( state: State ) => state.events );
  const [ showPreview, setShowPreview ] = useState( false )
  const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )
  const [ showConfirmModal, setShowConfirmModal ] = useState( false )

  const toggleShowArchive = () => {
    dispatch( { type: `EVENTS_TOGGLE_SHOW_ARCHIVE` } );
    getEvents();
  };

  const setSelectedPolitician = async ( id: number ) => {

    dispatch( { type: `SET_SELECTED_POLITICIAN_FOR_SEARCH`, selectedPolitician: id } );
    await getEvents();

  };

  const onMenuButtonClick = ( id: number ) => {

    switch ( id ) {
      case 0:
        navigate( `/esemenyek/${ highlightedEvent!.id }` )
        break
      case 1:
        setShowPreview( true )
        break
      case 2:
        setDeleteFunc( () => {
          return async () => {
            await deleteEvent( highlightedEvent!.id )
            await getEvents()
          }
        } )
        setShowConfirmModal( true )
        break
    }

  }

  const onDelete = async () => {

    if ( deleteFunc !== null ) {

      await deleteFunc()

    }
    setShowConfirmModal( false )

  }

  return (

    <main className="page page--events-list">

      <div className="main-div">

        <div className="navigation-title">

          <NavLink to="/esemenyek">
            <h1 className="title-text-h1">Események &gt;</h1>
          </NavLink>
          <h1 className="title-text-h1">{ !showArchive ? `Aktív` : `Archivált` } események</h1>

        </div>

        <div className="toolbar">

          <SearchInput onSearch={ e => getEvents() } placeholder="Keresés az események között" onChange={ event => dispatch( {
            type: `EVENTS_UPDATE_SEARCH`, value: event.target.value,
          } ) } /> <SearchButton onClick={ e => getEvents() } /> <NavLink to="/esemenyek/naptar">
          <button className="button calendar-button">
            <img src="/image/calendar.svg" />
          </button>
        </NavLink>
          <button className="button new-button" onClick={ e => navigate( `/esemenyek/uj` ) }>+ Új esemény</button>

        </div>

        <div className="actions">

          <button className="button" onClick={ e => toggleShowArchive() }>
            { showArchive ? `Aktuális események` : `Archív események` }
          </button>

        </div>

        { highlightedEvent ? (

          <div className="info">

            <h2 className="title-text-h2">Kiemelt Országos esemény</h2>

            { highlightedEvent == null ? '' : <div className="info inner">

              <span className="label-text title">{ highlightedEvent.title }</span>

              <div className="tag-container">
                { visibility( highlightedEvent.visibility ) }
              </div>

              <span className="label-text date">
              { createFormattedDateTimeString( highlightedEvent.startDate ) }
            </span>

              <span className="label-text open-count">

            </span>

              <div className="count-container">

              </div>

              <div className="menu-container">

                <EventsTableMenuButton event={ highlightedEvent } onMenuButtonClick={ onMenuButtonClick } />

              </div>

            </div> }

          </div>

        ) : null }

        <div className="filters">

          <div className="politician-filter">

            <Dropdown value={ selectedPoliticianForSearch } items={ [
              { id: -1, value: `Bármelyik politikus` },
              ...( politicians || [] ).map( p => ( { id: p.id, value: `${ p.lastName } ${ p.firstName }` } ) ),
            ] } onChange={ setSelectedPolitician } compact={ false } />

          </div>

        </div>

        <EventsTable />

      </div>

      { showPreview ? <EventModal event={ highlightedEvent! } onCloseClick={ () => setShowPreview( false ) } onModalClose={ () => setShowPreview( false ) } /> : null }

      { showConfirmModal ? (

        <Modal className="event-table-confirm-modal" onModalClose={ () => setShowConfirmModal( false ) }>

          <div className="modal-title">Biztosan törlöd az eseményt?</div>
          <div className="modal-description">Ezt a műveletet nem lehet visszavonni! Biztosan törlöd?</div>
          <footer>
            <button className="button button--primary" onClick={ () => setShowConfirmModal( false ) }>Mégsem</button>
            <button className="button button--ghost button--warning" onClick={ onDelete }>Törlés</button>
          </footer>

        </Modal>

      ) : null }

    </main>

  );

};



