import './achievement-modal.scss'
import { Modal } from '../modal'
import { AchievementForm } from '../achievement-form'

interface AchievementModalProps {

    selectedAchievement: any
    onClose?: () => void
    onSave?: ( achievementGroupData: any ) => void
    onDelete?: () => void

}

export const AchievementModal = ( props: AchievementModalProps ) => {

    const onClose = () => {

        if ( props.onClose ) props.onClose()

    }

    const onDelete = () => {

        if ( props.onDelete ) props.onDelete()

    }

    const onSave = ( achievementGroupData: any ) => {

        if ( props.onSave ) props.onSave( achievementGroupData )

    }

    return (

        <Modal onModalClose={ onClose } className="achievement-modal">

            <div className="modal-title">

                Kitüntetés

            </div>

            <AchievementForm { ...props.selectedAchievement } onSave={ onSave } onDelete={ onDelete } />

        </Modal>

    )

}