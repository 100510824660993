import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { getAllEvents, getEvents } from '../../action/events'
import { Calendar } from '../../component'
import { SearchButton, SearchInput } from '../../component/search'
import { State } from '../../state'
import './events-calendar.page.scss'

interface EventsCalendarProps { }

export const EventsCalendar = ( props: EventsCalendarProps ) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { events } = useSelector( ( state: State ) => state.events )

    return (

        <main className="page page--events-calendar">

            <div className="main-div">

                <div className="navigation-title">

                    <NavLink to="/esemenyek">
                        <h1 className="title-text-h1">Események &gt;</h1>
                    </NavLink>
                    <h1 className="title-text-h1">Események lista</h1>

                </div>

                <div className="toolbar">

                    <SearchInput onSearch={ e => getEvents() } placeholder="Keresés az események között" onChange={ event => dispatch( { type: `EVENTS_UPDATE_SEARCH`, value: event.target.value } ) } />
                    <SearchButton onClick={ e => getEvents() } />
                    <button className="button calendar-button" onClick={ e => navigate( `/esemenyek/lista` ) }>
                        <img src="/image/calendar.svg" />
                    </button>
                    <button className="button new-button" onClick={ e => navigate( `/esemenyek/uj` ) }>+ Új esemény</button>

                </div>

                <Calendar items={ events } onMonthChange={ ( year, month ) => getAllEvents( year, month ) } onItemClick={ item => navigate( `/esemenyek/${ item.event.id }` ) } />

            </div>

        </main>

    )

}



