import './quiz-topic.scss'

interface QuizTopicProps {

    color: string
    name: string

}

export const QuizTopic = ( props: QuizTopicProps ) => {

    return (

        <div className="quiz-topic" style={{ backgroundColor: props.color }}>

            { props.name }

        </div>

    )

}