import { ChangeEvent, DragEventHandler, MouseEvent, useState } from "react";
import { MediaLibraryFile, QuestionnaireQuestion } from "../../../../entity";
import { useDispatch } from "react-redux";
import { Dropdown } from "../../../../component";
import { CREATE_QUESTIONNAIRE_ACTIONS } from "../../../../action/questionnaires/questionnaires.constants";
import "./questionnaire-question-element.scss";
import { TextField } from "../../../../component/field";
import { QuestionnaireQuestionAnswerTable } from "../questionnaire-question-answer-table";
import { MediaLibrary } from "../../../../component/media-library";
import { ImageEditor } from "../../../../component/image-editor/image-editor";
import { QuestionnaireQuestionAnsweImageTable } from "../questionnaire-question-answer-image-table";
import { DescriptionTextArea } from "../../../../component/textarea/description";
import { AddImage } from "../../../../component/add-image";

interface QuestionnaireQuestionElementProps {
  questionElement: QuestionnaireQuestion;
  positon: number;
  onDragStart: any;
  onDragEnter: any;
  onDragEnd: DragEventHandler;
  disabled?: boolean;
  deleteButtonDisabled: boolean;
}

export const QuestionnaireQuestionElement = (
  props: QuestionnaireQuestionElementProps
) => {
  const dispatch = useDispatch();

  const [hasDescription, setHasDescription] = useState(false);
  const [hasImage, setHasImage] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [mediaLibraryFile, setMediaLibraryFile] = useState(
    null as MediaLibraryFile | null
  );
  const [mediaLibraryVisibility, setMediaLibraryVisibility] = useState(false);
  const [imageEditorVisibility, setImageEditorVisibility] = useState(false);

  const typeElements = [
    { id: 1, value: "Feleletválasztós", icon: "edit.svg" },
    { id: 2, value: "Jelölőnégyzetes", icon: "eye.svg" },
    { id: 3, value: "Skála", icon: "trash.svg" },
    { id: 4, value: "Szabadválasz", icon: "trash.svg" },
    { id: 5, value: "Képválasztó", icon: "trash.svg" },
  ];

  const scaleElements = [
    { id: 2, value: "2" },
    { id: 3, value: "3" },
    { id: 4, value: "4" },
    { id: 5, value: "5" },
    { id: 6, value: "6" },
    { id: 7, value: "7" },
    { id: 8, value: "8" },
    { id: 9, value: "9" },
    { id: 10, value: "10" },
  ];

  const onQuestionChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    dispatch({
      type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ITEM_QUESTION,
      question: event.target.value,
      id: props.questionElement.id,
    });
  };

  const onDescriptionChange = (text: string) => {
    dispatch({
      type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ITEM_DESCRIPTION,
      description: text,
      id: props.questionElement.id,
    });
  };

  const onTypeChange = (typeId: any) => {
    dispatch({
      type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ITEM_TYPE,
      typeId: typeId,
      id: props.questionElement.id,
    });
  };

  const onToScaleChange = (scale: any) => {
    dispatch({
      type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ITEM_TO_SCALE,
      toScale: scale,
      id: props.questionElement.id,
    });
  };

  const onToScaleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    dispatch({
      type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ITEM_TO_SCALE_TITLE,
      toScaleTitle: event.target.value,
      id: props.questionElement.id,
    });
  };

  const onFromScaleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    dispatch({
      type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ITEM_FROM_SCALE_TITLE,
      fromScaleTitle: event.target.value,
      id: props.questionElement.id,
    });
  };

  const onDeleteButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatch({
      type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_REMOVE_QUESTION,
      selectedQuestionId: props.questionElement.id,
    });
  };

  const onDescriptionVisibilityClick = (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setHasDescription(!hasDescription);
  };

  const onImageVisibilityClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setHasImage(!hasImage);
  };

  const onCollapseClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsCollapsed(!isCollapsed);
  };

  const dragStart = (e: any, position: number) => {
    props.onDragStart(position);
  };

  const dragEnter = (e: any, position: number) => {
    props.onDragEnter(position);
  };

  return (
    <li
      key={props.questionElement.id}
      className="questionnaire-question-table__questionnaire-question-element"
      draggable
      onDragStart={(e) => dragStart(e, props.positon)}
      onDragEnter={(e) => dragEnter(e, props.positon)}
      onDragEnd={props.onDragEnd}
    >
      <div className="questionnaire-question-element-indicator" />

      <div className="questionnaire-question-element-container">
        <div className="text-section-container">
          <span
            className="material-symbols-outlined"
            style={{ rotate: "90deg" }}
          >
            drag_indicator
          </span>

          <div className="question-top-div">
            <div className="question-question-div">
              <div className="input-container-header ">
                <h3 className="title-text-h3 title-text-bottom-padding">
                  Kérdés megnevezése *
                </h3>
              </div>
              <TextField
                field={props.questionElement.questionField}
                maxlength={255}
                onChange={onQuestionChange}
                placeholder=""
                category="question"
                disabled={props.disabled === true}
              />
            </div>

            <Dropdown
              value={props.questionElement.type}
              items={typeElements}
              onChange={onTypeChange}
              compact={false}
              disabled={props.disabled === true}
            />
          </div>

          {hasDescription ? (
            <div style={{ width: "100%" }}>
              <DescriptionTextArea
                title={"Kérdés leírásának megadása"}
                field={props.questionElement.descriptionField}
                onChange={onDescriptionChange}
                disabled={props.disabled === true}
              />
            </div>
          ) : null}

          {props.questionElement.type != 5 && hasImage ? (
            <div style={{ width: "100%", marginTop: "20px" }}>
              <AddImage
                width={"255px"}
                height={"148px"}
                imageUrl={props.questionElement.imageUrl}
                text={"Kép hozzáadása a kérdéshez (16:9)"}
                onAddImage={() => {
                  setMediaLibraryVisibility(true);
                }}
                onDeleteImage={() => {
                  setMediaLibraryFile(null);
                  dispatch({
                    type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_MEDIA_LIBRARY_FILE,
                    mediaLibraryFile: null,
                    id: props.questionElement.id,
                  });
                }}
                disabled={props.disabled === true}
              />
            </div>
          ) : null}
        </div>

        {/* --- Skála ---  */}

        {!isCollapsed && props.questionElement.type == 3 ? (
          <div className="question-scale-container-div">
            <div className="question-scale-div">
              <h3 className="question-scale-from-div"> 1 </h3>

              <h3 className="question-scale-div-center-div"> - </h3>

              <Dropdown
                value={props.questionElement.scaleTo}
                items={scaleElements}
                onChange={onToScaleChange}
                compact={false}
                disabled={props.disabled === true}
              />
            </div>

            <div className="question-question-div">
              <div className="input-container-header">
                <h3 className="title-text-h3 title-text-bottom-padding">
                  Skála “1” megnevezése
                </h3>
              </div>
              <TextField
                field={props.questionElement.scaleFromTitleField}
                maxlength={255}
                onChange={onFromScaleTitleChange}
                placeholder=""
                category="question"
                disabled={props.disabled === true}
              />

              <div
                className="input-container-header"
                style={{ marginTop: "20px" }}
              >
                <h3 className="title-text-h3 title-text-bottom-padding ">
                  Skála “{props.questionElement.scaleTo}” megnevezése
                </h3>
              </div>
              <TextField
                field={props.questionElement.scaleToTitleField}
                maxlength={255}
                onChange={onToScaleTitleChange}
                placeholder=""
                category="question"
                disabled={props.disabled === true}
              />
            </div>
          </div>
        ) : null}

        {/* --- Válaszok ---  */}

        {!isCollapsed && props.questionElement.type < 3 ? (
          <QuestionnaireQuestionAnswerTable
            answerArray={props.questionElement.answers || []}
            questionId={props.questionElement.id}
            disabled={props.disabled === true}
          />
        ) : null}

        {/* --- Képválasztós ---  */}

        {!isCollapsed && props.questionElement.type == 5 ? (
          <QuestionnaireQuestionAnsweImageTable
            questionnaireQuestionElement={props.questionElement}
            disabled={props.disabled === true}
          />
        ) : null}

        <h3 className="label-text">
          - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
          - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
          - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
          - - - - - - - - - - - - - - - - - - - - -
        </h3>

        <div className="question-bottom-div">
          {props.questionElement.type != 5 ? (
            <button
              className="button button-white"
              onClick={onImageVisibilityClick}
            >
              Kép {hasImage ? "elrejtése" : "megjelenítése"}
            </button>
          ) : null}

          <button
            className="button button-white bottom-button-margin-left"
            onClick={onDescriptionVisibilityClick}
          >
            Leírás {hasDescription ? "elrejtése" : "megjelenítése"}
          </button>

          <button
            className="button button-red bottom-button-margin-left"
            onClick={onDeleteButtonClick}
            disabled={props.disabled === true || props.deleteButtonDisabled}
          >
            Törlés
          </button>

          <button className="expand-button" onClick={onCollapseClick}>
            <span className="material-symbols-outlined">
              {!isCollapsed ? "expand_less" : "expand_more"}
            </span>
          </button>
        </div>

        {mediaLibraryVisibility ? (
          <MediaLibrary
            onClose={() => setMediaLibraryVisibility(false)}
            onMediaSelected={(file: MediaLibraryFile) => {
              setMediaLibraryFile(file);
              setMediaLibraryVisibility(false);
              setImageEditorVisibility(true);
            }}
          />
        ) : null}

        {imageEditorVisibility ? (
          <ImageEditor
            mediaLibraryFile={mediaLibraryFile!}
            aspectRatio={{ name: "16:9", ratio: 16 / 9 }}
            onClose={() => setImageEditorVisibility(false)}
            onReady={(file) => {
              dispatch({
                type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_MEDIA_LIBRARY_FILE,
                mediaLibraryFile: file,
                id: props.questionElement.id,
              });
              setImageEditorVisibility(false);
            }}
          />
        ) : null}
      </div>
    </li>
  );
};
