import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { ListAdminActivitiesDTO } from '../../dto'
import { store } from '../../store'

export const listAdminActivities = async ( activityTypes: string[], id?: number ) => {

    let body: ListAdminActivitiesDTO
    let response: Response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/users/admin-activities/?types=${ activityTypes.join( `,` ) }${ id ? `&id=${ id }` : `` }`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'get'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'LIST_ADMIN_ACTIVITIES_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'LIST_ADMIN_ACTIVITIES_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'LIST_ADMIN_ACTIVITIES_UNEXPECTEDRESPONSE' } )
        return

    }

    try {

        body = plainToClass( ListAdminActivitiesDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'LIST_ADMIN_ACTIVITIES_INVALIDJSON' } )
        return

    }

    if ( ( await validate( body ) ).length > 0 ) {

        store.dispatch( { type: 'LIST_ADMIN_ACTIVITIES_INVALIDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'LIST_ADMIN_ACTIVITIES_OK', adminActivities: body.adminActivities } )

}
