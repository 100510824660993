import { CREATE_QUESTIONNAIRE_TAG_ACTIONS } from '../../action/questionnaires/questionnaires.constants'
import { CreateQuestionnaireTagState } from '../../state/questionnaires'

const initialCreateQuestionnaireTagState: CreateQuestionnaireTagState  = {
    form: {
        id: null,
        tag: {
            error: '',
            value: '',
            required: true
        },
    },
    saveDate: null,
    questionnaireTag: null
}

export const createQuestionnaireTagReducer = ( state: CreateQuestionnaireTagState = initialCreateQuestionnaireTagState, action: any ): CreateQuestionnaireTagState => {
    switch ( action.type ) {
        case CREATE_QUESTIONNAIRE_TAG_ACTIONS.CREATE_QUESTIONNAIRE_TAG_CHANGE_TAG:
            return { ...state, form: { ...state.form, tag: { ...state.form.tag, value: action.tag } } }
        case CREATE_QUESTIONNAIRE_TAG_ACTIONS.CREATE_QUESTIONNAIRE_TAG_STARTED:
            return { ...state, saveDate: new Date() }
        case CREATE_QUESTIONNAIRE_TAG_ACTIONS.CREATE_QUESTIONNAIRE_TAG_INVALID_JSON:
            return { ...state, saveDate: null, questionnaireTag: null }
        case CREATE_QUESTIONNAIRE_TAG_ACTIONS.CREATE_QUESTIONNAIRE_TAG_INVALID_RESPONSE:
            return { ...state, saveDate: null, questionnaireTag: null } 
        case CREATE_QUESTIONNAIRE_TAG_ACTIONS.CREATE_QUESTIONNAIRE_TAG_OK:
            return { ...state, saveDate: null, questionnaireTag: action.questionnaireTag }
        case CREATE_QUESTIONNAIRE_TAG_ACTIONS.CREATE_QUESTIONNAIRE_TAG_CLEAR:
            return initialCreateQuestionnaireTagState 
        case CREATE_QUESTIONNAIRE_TAG_ACTIONS.CREATE_QUESTIONNAIRE_TAG_MODIFY_TAG:
            return { ...state, 
                form: { ...state.form, 
                    id: action.questionnaireTag.id,
                    tag: {
                        error: '',
                        value: action.questionnaireTag.tag,
                        required: true
                    },
                } 
            }
        default:
            return state
    }
}



