import { Expose } from 'class-transformer'
import { IsDefined, ValidateIf } from 'class-validator'
import { Course } from '../../entity'

export class ListCoursesDTO {

    @Expose()
    @IsDefined()
    courses!: Course[]

    @Expose()
    @IsDefined()
    @ValidateIf( ( object, value ) => value !== null )
    highlighted!: Course | null

    @Expose()
    @IsDefined()
    pagination!: {
        page: number
        from: number
        to: number
        count: number
    }

}

