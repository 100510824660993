import { CHANGE_PUSH_SETTINGS_ACTIONS, CREATE_PUSH_ACTIONS, GET_ALL_PUSH_ACTIONS, GET_PUSH_SETTINGS_ACTIONS } from '../action/push/push.constants'
import { PushState } from '../state'
import { createLocalISOTime, message } from '../helper'
import { PushSettingsEnum } from '../enum'

const initialPushState: PushState = {
    form: {
        title: {
            error: '',
            value: '',
            required: true,
        },
        body: {
            error: '',
            value: '',
            required: true,
        },
        imageUrl: '',
        mediaLibraryFile: null,
        contentAction: {
            categoryId: -1,
            actionId: -1,
            contentId: -1,
            contentUrl: {
                error: '',
                value: '',
                required: false,
            },
        },
        targets: [],
        timing: null,
        immediate: true,
    },
    settings: {
        questDailyClosing: true,
        questFridayBeforeLeagueClose: true,
        questWeeklyClosing: true,
        questMiddleOfTheWeek: true,
        eventsOneDayBeforeTheEvent: true,
        activityNotLogInForThreeDay: true,
        activityNotLogInForSixDay: true,
        activityDontBreakTheStreak: true,
        birthday: true,
        oneDayAfterRegistrationIfHaventCompletedAnyMission: true,
        markAsAFriend: true,
        friendConfirmed: true,
    },
    messages: [],
    pagination: {
        count: 0,
        from: 0,
        page: 1,
        to: 0,
    },
}

export const pushReducer = (state: PushState = initialPushState, action: any): PushState => {
    switch (action.type) {
        case CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_TITLE:
            return { ...state, form: { ...state.form, title: { ...state.form.title, value: action.title } } }
        case CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_BODY:
            return { ...state, form: { ...state.form, body: { ...state.form.body, value: action.body } } }
        case CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_MEDIA_LIBRARY_FILE:
            return { ...state, form: { ...state.form, mediaLibraryFile: action.mediaLibraryFile, imageUrl: action.mediaLibraryFile ? action.mediaLibraryFile.url : '' } }
        case CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_CONTENT_ACTION_CATEGORY_ID:
            return { ...state, form: { ...state.form, contentAction: { ...state.form.contentAction, categoryId: action.categoryId } } }
        case CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_CONTENT_ACTION_ID:
            return { ...state, form: { ...state.form, contentAction: { ...state.form.contentAction, actionId: action.actionId } } }
        case CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_CONTENT_ACTION_URL:
            return { ...state, form: { ...state.form, contentAction: { ...state.form.contentAction, contentUrl: { ...state.form.contentAction.contentUrl, value: action.url } } } }
        case CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_CONTENT_ACTION_CONTENT_ID:
            return { ...state, form: { ...state.form, contentAction: { ...state.form.contentAction, contentId: action.contentId } } }
        case CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_IMMEDIATE:
            return { ...state, form: { ...state.form, immediate: action.immediate == 0 ? false : true } }
        case CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_TIMING:
            return { ...state, form: { ...state.form, timing: action.timing } }
        case CREATE_PUSH_ACTIONS.CREATE_PUSH_ADD_TARGET:
            if (state.form.targets == null) {
                state.form.targets = []
            }

            state.form.targets.push({
                id: state.form.targets.length,
                type: -1,
                subType: -1,
                subSubType: -1,
            })

            return { ...state, form: { ...state.form, targets: state.form.targets } }
        case CREATE_PUSH_ACTIONS.CREATE_PUSH_REMOVE_TARGET:
            return { ...state, form: { ...state.form, targets: state.form.targets.filter((item) => action.selectedTargetId !== item.id) } }

        case CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_TARGET_TYPE:
            if (state.form.targets !== null) {
                state.form.targets.map((targetItem: any) => {
                    if (targetItem.id == action.id) {
                        targetItem.type = action.typeId
                    }
                })
            }

            return { ...state, form: { ...state.form, targets: state.form.targets } }
        case CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_TARGET_SUB_TYPE:
            if (state.form.targets !== null) {
                state.form.targets.map((targetItem: any) => {
                    if (targetItem.id == action.id) {
                        targetItem.subType = action.typeId
                    }
                })
            }

            return { ...state, form: { ...state.form, targets: state.form.targets } }
        case CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_TARGET_SUB_SUB_TYPE:
            if (state.form.targets !== null) {
                state.form.targets.map((targetItem: any) => {
                    if (targetItem.id == action.id) {
                        targetItem.subSubType = action.typeId
                    }
                })
            }

            return { ...state, form: { ...state.form, targets: state.form.targets } }
        case CREATE_PUSH_ACTIONS.CREATE_PUSH_OK:
            message(`Az értesítés sikeresen elküldésre került!`)
            return {
                ...state,
                form: {
                    ...state.form,
                    body: { ...state.form.body, value: '' },
                    title: { ...state.form.title, value: '' },
                    targets: [],
                    imageUrl: '',
                    mediaLibraryFile: null,
                    timing: null,
                    immediate: true,
                    contentAction: {
                        categoryId: -1,
                        actionId: -1,
                        contentId: null,
                        contentUrl: {
                            error: '',
                            value: '',
                            required: false,
                        },
                    },
                },
            }
        case CREATE_PUSH_ACTIONS.CREATE_PUSH_CLEAR:
            return initialPushState

        case CREATE_PUSH_ACTIONS.CREATE_PUSH_RELOAD_PUSH:
            return {
                ...state,
                form: {
                    ...state.form,
                    body: { ...state.form.body, value: action.push.body },
                    title: { ...state.form.title, value: action.push.title },
                    imageUrl: action.push.imageFile != null ? action.push.imageFile.url : '',
                    mediaLibraryFile:
                        action.push.imageFile != null
                            ? {
                                  id: action.push.imageFile.id,
                                  extension: 'jpg',
                                  name: '',
                                  width: 0,
                                  height: 0,
                                  size: '',
                                  thumbnail: '',
                                  type: 'file',
                                  url: '',
                                  created: 0,
                              }
                            : null,
                    contentAction: {
                        categoryId: 0,
                        actionId: action.push.contentAction.actionId,
                        contentUrl: {
                            error: '',
                            value: action.push.contentAction.contentUrl ? action.push.contentAction.contentUrl : '',
                            required: false,
                        },
                        contentId: action.push.contentAction.contentId,
                    },
                    targets: action.push.targets,
                    timing: action.push.timing != null ? createLocalISOTime(action.push.timing) : null,
                    immediate: action.push.immediate,
                },
            }

        case GET_ALL_PUSH_ACTIONS.GET_ALL_PUSH_OK:
            return { ...state, messages: action.messages, pagination: action.pagination }
        case GET_ALL_PUSH_ACTIONS.GET_ALL_PUSH_INVALID_JSON:
            return { ...state, messages: [] }
        case GET_ALL_PUSH_ACTIONS.GET_ALL_PUSH_INVALID_RESPONSE:
            return { ...state, messages: [] }
        case GET_ALL_PUSH_ACTIONS.GET_ALL_PUSH_CHANGE_PAGE:
            return { ...state, pagination: { ...state.pagination, page: action.page } }
        case GET_ALL_PUSH_ACTIONS.GET_ALL_PUSH_CHANGE_PAGE:
            return { ...state, pagination: { ...state.pagination, page: action.page } }

        case CHANGE_PUSH_SETTINGS_ACTIONS.CHANGE_PUSH_SETTINGS_CHANGE:
            switch (action.settingsId) {
                case PushSettingsEnum.QuestDailyClosing:
                    return { ...state, settings: { ...state.settings, questDailyClosing: !state.settings.questDailyClosing } }
                case PushSettingsEnum.QuestFridayBeforeLeagueClose:
                    return { ...state, settings: { ...state.settings, questFridayBeforeLeagueClose: !state.settings.questFridayBeforeLeagueClose } }
                case PushSettingsEnum.QuestWeeklyClosing:
                    return { ...state, settings: { ...state.settings, questWeeklyClosing: !state.settings.questWeeklyClosing } }
                case PushSettingsEnum.QuestMiddleOfTheWeek:
                    return { ...state, settings: { ...state.settings, questMiddleOfTheWeek: !state.settings.questMiddleOfTheWeek } }
                case PushSettingsEnum.EventsOneDayBeforeTheEvent:
                    return { ...state, settings: { ...state.settings, eventsOneDayBeforeTheEvent: !state.settings.eventsOneDayBeforeTheEvent } }
                case PushSettingsEnum.ActivityNotLogInForThreeDay:
                    return { ...state, settings: { ...state.settings, activityNotLogInForThreeDay: !state.settings.activityNotLogInForThreeDay } }
                case PushSettingsEnum.ActivityNotLogInForSixDay:
                    return { ...state, settings: { ...state.settings, activityNotLogInForSixDay: !state.settings.activityNotLogInForSixDay } }
                case PushSettingsEnum.ActivityDontBreakTheStreak:
                    return { ...state, settings: { ...state.settings, activityDontBreakTheStreak: !state.settings.activityDontBreakTheStreak } }
                case PushSettingsEnum.Birthday:
                    return { ...state, settings: { ...state.settings, birthday: !state.settings.birthday } }
                case PushSettingsEnum.OneDayAfterRegistrationIfHaventCompletedAnyMission:
                    return { ...state, settings: { ...state.settings, oneDayAfterRegistrationIfHaventCompletedAnyMission: !state.settings.oneDayAfterRegistrationIfHaventCompletedAnyMission } }
                case PushSettingsEnum.MarkAsAFriend:
                    return { ...state, settings: { ...state.settings, markAsAFriend: !state.settings.markAsAFriend } }
                case PushSettingsEnum.FriendConfirmed:
                    return { ...state, settings: { ...state.settings, friendConfirmed: !state.settings.friendConfirmed } }
                default:
                    return state
            }

        case GET_PUSH_SETTINGS_ACTIONS.GET_PUSH_SETTINGS_OK:
            return { ...state, settings: action.pushSettings }
        case GET_PUSH_SETTINGS_ACTIONS.GET_PUSH_SETTINGS_INVALID_JSON:
            return state
        case GET_PUSH_SETTINGS_ACTIONS.GET_PUSH_SETTINGS_INVALID_RESPONSE:
            return state

        default:
            return state
    }
}
