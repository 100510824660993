import { Expose } from 'class-transformer'
import { IsDefined } from 'class-validator'

export class ListDefaultExperiencePointsDTO {

    @Expose()
    @IsDefined()
    defaultExperiencePoints!: {
        name: string
        experience: number
    }[]


}

