import { store } from '../../store'

export const changeQuoteStatus = async ( id: number, status: number ) => {

    let response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/quotes/${ id }/status`, {
            body: JSON.stringify( { status: status } ),
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'post'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'CHANGE_QUOTE_STATUS_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'CHANGE_QUOTE_STATUS_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'CHANGE_QUOTE_STATUS_UNEXPECTEDRESPONSE' } )
        return

    }

}
