import './quiz-block.scss'
import { Quiz } from '../../entity'
import { NavLink } from 'react-router-dom'

interface QuizBlockProps {

    caption: string
    quiz: Quiz | null

}

export const QuizBlock = ( props: QuizBlockProps ) => {

    return (

        <div className="quiz-block">

            <div className="quiz-block__caption">

                { props.caption }

            </div>

            { props.quiz !== null ? (

                <NavLink to={ `/kvizek-es-tesztek/kvizek/${ props.quiz.id }` }>

                    <div className="quiz-block__container">

                        <img alt="" className="quiz-block__image" src={ props.quiz.image ? props.quiz.image.url : `/image/dk-logo-16_9.svg` } />

                        <div className="quiz-block__panel">

                            <div className="quiz-block__available">{ props.quiz.visibleTo === null ? `Határozatlan ideig` : `Még ${ Math.round( ( props.quiz.visibleTo / 60 / 60 / 24 ) - ( new Date().getTime() / 1000 / 60 / 60 / 24 ) ) } napig` } elérhető</div>
                            <div className="quiz-block__questions">{ props.quiz.questions.length } rövid kérdés</div>
                            <div className="quiz-block__title">{ props.quiz.title }</div>

                        </div>

                    </div>

                </NavLink>

            ) : (

                <>Nincs megjeleníthető kvíz / teszt</>

            ) }

        </div>

    )

}