import { Expose, Type } from 'class-transformer'
import { IsArray, IsBoolean, IsDefined, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'
import { ImageFile } from './image-file.entity'
import { NewsCategory } from './news-category.entity'
import { Question } from './question.entity'
import { Tag } from './tag.entity'

export class News {

    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number 

    @IsOptional()
    @IsString()
    statementBy?: string | null

    @Expose()
    @IsDefined()
    @IsString()
    title!: string

    @Expose()
    @IsDefined()
    @IsString()
    lead!: string

    @Expose()
    @IsDefined()
    @IsString()
    description!: string

    @IsOptional()
    @ValidateNested()
    @Type( () => ImageFile )
    imageFile?: ImageFile | null

    @Expose()
    @IsDefined()
    @IsNumber()
    visibility!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    visibleFrom!: number 

    @IsOptional()
    @IsNumber()
    visibleTo?: number | null

    @Expose()
    @IsDefined()
    @IsNumber()
    status!: number 

    @IsOptional()
    @ValidateNested()
    @Type( () => NewsCategory )
    category?: NewsCategory | null

    @Expose()
    @IsDefined()
    @IsBoolean()
    highlighted!: boolean 

    @Expose()
    @IsDefined()
    @IsBoolean()
    sendPush!: boolean 

    @Expose()
    @IsDefined()
    @IsBoolean()
    imageVisibilityInList!: boolean 

    @IsOptional()
    @ValidateNested()
    @Type( () => Question )
    question?: Question | null

    @IsOptional()
    @IsArray()
    relatedPoliticians?: [] | null

    @IsOptional()
    @IsArray()
    tags?: Tag[] | null

    @Expose()
    experience!: number

    @Expose()
    quizExperience!: number

}
