export const GET_STATISTICS_ACTIONS = {
    GET_STATISTICS_STARTED: "GET_STATISTICS_STARTED",
    GET_STATISTICS_INVALID_JSON: "GET_STATISTICS_INVALID_JSON",
    GET_STATISTICS_INVALID_RESPONSE: "GET_STATISTICS_INVALID_RESPONSE",
    GET_STATISTICS_OK: "GET_STATISTICS_OK",
    GET_STATISTICS_SET_DATE_RANGE: "GET_STATISTICS_SET_DATE_RANGE",
}


export const GET_NEWS_STATISTICS_ACTIONS = {
    GET_NEWS_STATISTICS_STARTED: "GET_NEWS_STATISTICS_STARTED",
    GET_NEWS_STATISTICS_INVALID_JSON: "GET_NEWS_STATISTICS_INVALID_JSON",
    GET_NEWS_STATISTICS_INVALID_RESPONSE: "GET_NEWS_STATISTICS_INVALID_RESPONSE",
    GET_NEWS_STATISTICS_OK: "GET_NEWS_STATISTICS_OK",
}


export const GET_EVENTS_STATISTICS_ACTIONS = {
    GET_EVENTS_STATISTICS_STARTED: "GET_EVENTS_STATISTICS_STARTED",
    GET_EVENTS_STATISTICS_INVALID_JSON: "GET_EVENTS_STATISTICS_INVALID_JSON",
    GET_EVENTS_STATISTICS_INVALID_RESPONSE: "GET_EVENTS_STATISTICS_INVALID_RESPONSE",
    GET_EVENTS_STATISTICS_OK: "GET_EVENTS_STATISTICS_OK",
}


export const GET_QUESTIONNAIRES_STATISTICS_ACTIONS = {
    GET_QUESTIONNAIRES_STATISTICS_STARTED: "GET_QUESTIONNAIRES_STATISTICS_STARTED",
    GET_QUESTIONNAIRES_STATISTICS_INVALID_JSON: "GET_QUESTIONNAIRES_STATISTICS_INVALID_JSON",
    GET_QUESTIONNAIRES_STATISTICS_INVALID_RESPONSE: "GET_QUESTIONNAIRES_STATISTICS_INVALID_RESPONSE",
    GET_QUESTIONNAIRES_STATISTICS_OK: "GET_QUESTIONNAIRES_STATISTICS_OK",
}


export const GET_QUOTES_STATISTICS_ACTIONS = {
    GET_QUOTES_STATISTICS_STARTED: "GET_QUOTES_STATISTICS_STARTED",
    GET_QUOTES_STATISTICS_INVALID_JSON: "GET_QUOTES_STATISTICS_INVALID_JSON",
    GET_QUOTES_STATISTICS_INVALID_RESPONSE: "GET_QUOTES_STATISTICS_INVALID_RESPONSE",
    GET_QUOTES_STATISTICS_OK: "GET_QUOTES_STATISTICS_OK",
}
