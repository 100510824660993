import { createBrowserRouter } from 'react-router-dom'
import { RequireAuth } from '../component'
import { DefaultLayout, LoginLayout } from '../layout'
import {
    mobileAppUserLoader,
    mobileAppUsersLoader,
    newsLoader,
    newsListLoader,
    createNewsLoader,
    politiciansLoader,
    politicianQuestionsLoader,
    createPoliticianLoader,
    questionnairesLoader,
    eventsDashboardLoader,
    resetPasswordLoader,
    adminUsersLoader,
    createQuestionnaireLoader,
    questionnaireListLoader,
    adminSetPasswordLoader,
    dashboardLoader,
    statisticsLoader,
    settingsLoader,
    adminUserLoader,
    getPoliticianLoader
} from '../loader'
import { eventsCalendarLoader, eventsDetailLoader, eventsListLoader, eventsNewLoader } from '../loader/events'
import { newQuoteLoader, quotesCalendarLoader, quotesLoader, scheduledQuotesLoader, viewQuoteLoader } from '../loader/quotes'
import { pushMessagesLoader } from '../loader/push/push-messages.loader'
import { Dashboard, Events, News, Politicians } from '../page'
import { Details, EventsCalendar, EventsDetail, EventsList, Stats } from '../page/events'
import { CreateNews } from '../page/news'
import { NewsList } from "../page/news"
import { CreatePolitician, PoliticianQuestions } from '../page/politicians'
import { Push } from '../page/push'
import { CreateQuestionnaire, QuestionnaireList, Questionnaires } from '../page/questionnaires'
import { QuotePage, QuotesCalendarPage, QuotesPage, ScheduledQuotesPage, ViewQuotePage } from '../page/quotes'
import { Settings } from '../page/settings'
import { Statistics } from '../page/statistics'
import { AdminSetPasswordPage, AdminUserPage, AdminUsers, ForgotPasswordPage, LoginPage, MobileAppUser, MobileAppUsers, ResetPasswordPage, Users } from '../page/users'
import { LeaderboardPage, LeaguePage, LeagueQuestsPage, PrizesPage, PushPage, QuestsPage, StatPage, WinnersPage } from '../page/gamification'
import { experiencePointsPageLoader, leagueLeaderboardPageLoader, leaguePageLoader, leaguePrizesPageLoader, leagueQuestsPageLoader, leagueStatsPageLoader, questsPageLoader, winnersPageLoader } from '../loader/gamification'
import { ExperiencePointsPage } from '../page/gamification/experience-points.page'
import { Tags } from '../page/tags'
import { tagsLoader } from '../loader/tags'
import { FirebasePage } from '../page/firebase'
import { FlotaingMessages } from '../page/floating-messages'
import { floatingMessagesLoader } from '../loader/floating-messages/floating-messages.loader'
import { TesterPage } from '../page/gamification/tester/tester.page'
import { Course, Courses, CoursesList, Lesson } from '../page/courses'
import { courseLoader, coursesListLoader, coursesLoader, lessonLoader } from '../loader/courses'
import { Quiz, Quizes, QuizesList } from '../page/quizes'
import { quizesListLoader, quizesLoader, quizLoader, testLoader } from '../loader/quizes'
import { seasonsLoader } from '../loader/seasons'
import { SeasonsPage } from '../page/seasons'
import { AchievementsPage } from '../page/achievements'
import { achievementsLoader } from '../loader/achievements/achievements.loader'
import { Homepage } from "../page/homepage"
import { votesLoader } from "../loader/votes"
import { VideosList } from "../page/videos/videos-list/videos-list.page"
import { videoLoader, videosListLoader } from "../loader/videos"
import { Video } from "../page/videos/video/video.page"

export const router = createBrowserRouter( [
    {
        children: [
            {
                element: <Dashboard />,
                loader: dashboardLoader,
                path: ''
            },
            {
                element: <FirebasePage />,
                path: 'firebase'
            },
            {
                element: <News />,
                loader: newsLoader,
                path: 'hirek'
            },
            {
                element: <CreateNews />,
                loader: createNewsLoader,
                path: 'hirek/uj'
            },
            {
                element: <CreateNews />,
                loader: createNewsLoader,
                path: 'hirek/:id'
            },
            {
                element: <NewsList />,
                loader: newsListLoader,
                path: 'hirek/lista'
            },
            {
                element: <Events />,
                loader: eventsDashboardLoader,
                path: 'esemenyek'
            },
            {
                element: <EventsList />,
                loader: eventsListLoader,
                path: 'esemenyek/lista'
            },
            {
                element: <EventsCalendar />,
                loader: eventsCalendarLoader,
                path: 'esemenyek/naptar'
            },
            {
                children: [
                    {
                        element: <Details />,
                        path: ''
                    }
                ],
                element: <EventsDetail />,
                loader: eventsNewLoader,
                path: 'esemenyek/uj'
            },
            {
                children: [
                    {
                        element: <Details />,
                        path: ''
                    },
                    {
                        element: <Stats />,
                        path: 'statisztika'
                    }
                ],
                element: <EventsDetail />,
                loader: eventsDetailLoader,
                path: 'esemenyek/:id'
            },
            {
                element: <Push />,
                loader: pushMessagesLoader,
                path: 'push-uzenetek'
            },
            {
                element: <FlotaingMessages />,
                loader: floatingMessagesLoader,
                path: 'floating-uzenetek'
            },
            {
                element: <Dashboard />,
                path: 'feladat-kezelo'
            },
            {
                element: <Questionnaires />,
                loader: questionnairesLoader,
                path: 'kerdoivek'
            },
            {
                element: <QuestionnaireList />,
                loader: questionnaireListLoader,
                path: 'kerdoivek/lista'
            },
            {
                element: <CreateQuestionnaire />,
                loader: createQuestionnaireLoader,
                path: 'kerdoivek/uj'
            },
            {
                element: <CreateQuestionnaire />,
                loader: createQuestionnaireLoader,
                path: 'kerdoivek/:id'
            },

            {
                element: <VideosList />,
                loader: videosListLoader,
                path: 'videok/lista'
            },
            {
                element: <Video />,
                loader: videoLoader,
                path: 'videok/uj'
            },
            {
                element: <Video />,
                loader: videoLoader,
                path: 'videok/:id'
            },

            {
                element: <Courses />,
                loader: coursesLoader,
                path: 'kepzesek'
            },
            {
                element: <CoursesList />,
                loader: coursesListLoader,
                path: 'kepzesek/lista'
            },
            {
                element: <Course />,
                loader: courseLoader,
                path: 'kepzesek/uj'
            },
            {
                element: <Course />,
                loader: courseLoader,
                path: 'kepzesek/:id'
            },
            {
                element: <Lesson />,
                loader: lessonLoader,
                path: 'kepzesek/:courseId/leckek/uj'
            },
            {
                element: <Lesson />,
                loader: lessonLoader,
                path: 'kepzesek/:courseId/leckek/:id'
            },

            {
                element: <Quizes />,
                loader: quizesLoader,
                path: 'kvizek-es-tesztek'
            },
            {
                element: <QuizesList />,
                loader: quizesListLoader,
                path: 'kvizek-es-tesztek/lista'
            },
            {
                element: <Quiz />,
                loader: quizLoader,
                path: 'kvizek-es-tesztek/kvizek/uj'
            },
            {
                element: <Quiz />,
                loader: testLoader,
                path: 'kvizek-es-tesztek/tesztek/uj'
            },
            {
                element: <Quiz />,
                loader: quizLoader,
                path: 'kvizek-es-tesztek/kvizek/:id'
            },
            {
                element: <Quiz />,
                loader: testLoader,
                path: 'kvizek-es-tesztek/tesztek/:id'
            },

            {
                element: <QuotesPage />,
                loader: quotesLoader,
                path: 'velemenyvezerek'
            },
            {
                element: <QuotePage />,
                loader: newQuoteLoader,
                path: 'velemenyvezerek/idezetek'
            },
            {
                element: <ScheduledQuotesPage />,
                loader: scheduledQuotesLoader,
                path: 'velemenyvezerek/utemezett'
            },
            {
                element: <QuotesCalendarPage />,
                loader: quotesCalendarLoader,
                path: 'velemenyvezerek/naptar'
            },
            {
                element: <ViewQuotePage />,
                loader: viewQuoteLoader,
                path: 'velemenyvezerek/idezetek/:id'
            },
            {
                element: <ExperiencePointsPage />,
                loader: experiencePointsPageLoader,
                path: 'tapasztalati-pontok'
            },
            {
                element: <AchievementsPage />,
                loader: achievementsLoader,
                path: `kituntetesek`
            },
            {
                children: [
                    {
                        children: [
                            {
                                element: <StatPage />,
                                loader: leagueStatsPageLoader,
                                path: 'statisztika'
                            },
                            {
                                element: <LeagueQuestsPage />,
                                loader: leagueQuestsPageLoader,
                                path: 'kihivasok'
                            },
                            {
                                element: <LeaderboardPage />,
                                loader: leagueLeaderboardPageLoader,
                                path: 'ranglista'
                            },
                            {
                                element: <PrizesPage />,
                                loader: leaguePrizesPageLoader,
                                path: 'nyeremenyek'
                            },
                            {
                                element: <PushPage />,
                                path: 'push-uzenetek'
                            }
                        ],
                        element: <LeaguePage />,
                        loader: leaguePageLoader,
                        path: 'ligak/:id'
                    }
                ],
                element: <QuestsPage />,
                loader: questsPageLoader,
                path: `szezon/:seasonId`
            },
            {
                element: <SeasonsPage />,
                loader: seasonsLoader,
                path: 'ligak-es-kihivasok'
            },
            {
                element: <WinnersPage />,
                loader: winnersPageLoader,
                path: 'nyertesek'
            },
            {
                element: <Dashboard />,
                path: 'feri-4-you'
            },
            {
                element: <Dashboard />,
                path: 'informacios-feluletek'
            },
            {
                element: <Politicians />,
                loader: politiciansLoader,
                path: 'politikusok'
            },
            {
                element: <CreatePolitician />,
                loader: createPoliticianLoader,
                path: 'politikusok/uj'
            },
            {
                element: <CreatePolitician />,
                loader: getPoliticianLoader,
                path: 'politikusok/:id'
            },
            {
                element: <PoliticianQuestions />,
                loader: politicianQuestionsLoader,
                path: 'politikusok/kerdesek'
            },
            {
                element: <MobileAppUsers />,
                loader: mobileAppUsersLoader,
                path: 'tagok/mobil-app-felhasznalok'
            },
            {
                element: <MobileAppUser />,
                loader: mobileAppUserLoader,
                path: 'tagok/mobil-app-felhasznalok/:id'
            },
            {
                element: <AdminUsers />,
                loader: adminUsersLoader,
                path: 'tagok/adminok'
            },
            {
                element: <AdminUsers />,
                loader: adminUsersLoader,
                path: 'tagok/adminok/tevekenysegek'
            },
            {
                element: <Users />,
                path: 'tagok'
            },
            {
                element: <AdminUserPage />,
                loader: adminUserLoader,
                path: 'tagok/adminok/:id'
            },
            {
                element: <Statistics />,
                loader: statisticsLoader,
                path: 'statisztikak'
            },
            {
                element: <Settings />,
                loader: settingsLoader,
                path: 'beallitasok'
            },
            {
                element: <Homepage />,
                loader: votesLoader,
                path: 'fooldal'
            },
            {
                element: <Tags />,
                loader: tagsLoader,
                path: 'cimkezo'
            },
            {
                element: <TesterPage />,
                path: 'tester'
            }
        ],
        element: <RequireAuth><DefaultLayout /></RequireAuth>,
        errorElement: null,
        path: '/'
    },
    {
        children: [
            {
                element: <LoginPage />,
                path: `bejelentkezes`
            },
            {
                element: <ForgotPasswordPage />,
                path: `elfelejtettem-a-jelszavam`
            },
            {
                element: <ResetPasswordPage />,
                loader: resetPasswordLoader,
                path: `jelszo-helyreallitasa/:token`
            },
            {
                element: <AdminSetPasswordPage />,
                loader: adminSetPasswordLoader,
                path: `adminisztrator-jelszo/:token`
            }
        ],
        element: <LoginLayout />,
        errorElement: null,
        path: '/'
    },
    {
        element: <div />,
        errorElement: null,
        path: '/elfelejtettem-a-jelszavam'
    }
] )
