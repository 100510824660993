import { store } from '../../store'
import { plainToClass } from 'class-transformer'
import { SaveQuizDTO } from '../../dto/quizes'
import { validate } from 'class-validator'

export const saveTest = async ( quiz: any ) => {

    let body, response

    for ( const question of quiz.questions ) {

        if ( question.isNew === true ) { question.id = null }
        for ( const answer of question.answers ) {

            answer.userAnswers = undefined
            if ( answer.isNew === true ) { answer.id = null }

        }

    }

    for ( const result of quiz.possibleResults ) {

        if ( result.isNew ) {
            result.id = null
        }

    }

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/tests`, {
            body: JSON.stringify( quiz ),
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'POST'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'SAVE_QUIZ_TIMEOUT' } )
        return null

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'SAVE_QUIZ_UNAUTHORIZED' } )
        return null

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'SAVE_QUIZ_UNEXPECTEDRESPONSE' } )
        return null

    }

    try {

        body = plainToClass( SaveQuizDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'SAVE_QUIZ_INVALIDJSON' } )
        return null

    }

    if ( ( await validate( body ) ).length > 0 ) {

        store.dispatch( { type: 'SAVE_QUIZ_INVALIDRESPONSE' } )
        return null

    }

    store.dispatch( { type: 'SET_QUIZ_TYPE', quizType: `test` } )
    store.dispatch( { type: 'SAVE_TEST_OK' } )
    return body.quiz

}