import { Modal } from '../modal'
import './confirm-message.scss'

interface ConfirmMessageProps {

    message: string
    title?: string
    onClose: () => void

}

export const ConfirmMessage = ( props: ConfirmMessageProps ) => {

    return (

        <Modal className="confirm-message-modal">

            <div className="modal-title">{ props.title || `Sikeres mentés!` }</div>
            <div className="modal-message">{ props.message }</div>
            <footer>
                <button className="button button--primary" onClick={ () => props.onClose() }>Rendben</button>
            </footer>

        </Modal>

    )

}