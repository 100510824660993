import { ChangeEvent, KeyboardEvent, useRef, useEffect } from "react";
import "./text.area.scss";

interface TextAreaProps {
  field: {
    error: string;
    required?: boolean;
    value: string;
  };
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  category?: string;
  id?: string;
  maxLength?: number;
  disabled?: boolean;
}

export const TextArea = (props: TextAreaProps) => {
  const textareaRef = useRef<any>(null);

  useEffect(() => {
    textareaRef.current.style.height = "0px";
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = scrollHeight - 36 + "px";
  }, []);

  const onKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    const target = event.target as HTMLTextAreaElement;
    textareaRef.current.style.height = "inherit";
    textareaRef.current.style.height = `${target.scrollHeight - 36}px`;
  };

  useEffect(() => {
    if (!props.field.value) {
      textareaRef.current.style.height = "0px";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight - 36 + "px";
    }
  }, [props.field.value]);

  const handlePaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    const cleanText = text.replace(/<[^>]+>/g, "");
    document.execCommand("insertText", false, cleanText);
      const target = event.target as HTMLTextAreaElement;
      textareaRef.current.style.height = "inherit";
      textareaRef.current.style.height = `${target.scrollHeight - 36}px`;
  };

  return (
    <div
      className={`field${
        props.field.error.length > 0 ? ` field--error` : ``
      } area--text `}
    >
      <textarea
        id={props.id}
        ref={textareaRef}
        className={props.category}
        onChange={props.onChange}
        placeholder={props.placeholder}
        required={props.field.required !== false}
        onKeyDown={onKeyDown}
        maxLength={props.maxLength}
        rows={1}
        value={props.field.value}
        onPaste={handlePaste}
        disabled={props.disabled === true}
      />
    </div>
  );
};
