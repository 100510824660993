import { UserGroup } from '../entity'

export const getUserGroupName = ( userGroup: UserGroup ): string => {

    if ( userGroup === undefined ) {

        return `Felhasználó`

    }

    switch ( userGroup.name ) {

        case `master_admins`:
            return `Főadmin`
        case `county_admins`:
            return `Megyei admin`
        case `vk_admins`:
            return `VK adminisztrátor`
        default:
            return `Felhasználó`

    }

}
