import { LoaderFunctionArgs } from 'react-router-dom'
import {getPoliticianQuestions, getPoliticians, getPoliticianTitles} from '../../action/politician'
import { listTags } from '../../action/tags'
import { listDefaultExperiencePoints } from '../../action/gamification'
import {getPoliticianStats} from "../../action/politician/get-politician-stats.action";
import {store} from "../../store";
import {Politician} from "../../entity";

export const getPoliticianLoader = async ( args: LoaderFunctionArgs ) => {

    await listDefaultExperiencePoints()

    await listTags()

    await getPoliticians()

    await getPoliticianQuestions()

    await getPoliticianTitles()

    const politicianId = parseInt(args.params.id as string)
    await getPoliticianStats(politicianId)
    store.dispatch( { type: 'CREATE_POLITICIAN_MODIFY_POLITICIAN', politician: store.getState().politicians.politicians!.find(politician => politician.id === politicianId) } )

}
