import React, { MouseEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './videos-list.page.scss'
import { SearchButton, SearchInput } from '../../../component/search'
import { useDispatch, useSelector } from 'react-redux'
import { VideosTable } from '../../../component/videos-table'
import { State } from '../../../state'
import { VideoCategoriesModal } from '../../../component/video-categories-modal'
import { createFormattedDateTimeString, message } from '../../../helper';
import { MenuButton, Modal } from '../../../component';
import { deleteVideo, listVideos } from '../../../action/videos';
import { CourseTopic } from '../../../component/course-topic';

export const VideosList = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { videoCategories, highlightedVideo, pagination } = useSelector( ( state: State ) => state.videos )
    const [ showCategoriesModal, setShowCategoriesModal ] = useState( false )

    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )
    const [ showConfirmModal, setShowConfirmModal ] = useState( false )

    const onDelete = async () => {

        await deleteFunc!()
        setShowConfirmModal( false )

    }

    const search = async () => {

        await listVideos()
        navigate( `/videok/lista` )

    }

    const create = ( e: MouseEvent ) => {

        if ( videoCategories.length === 0 ) {

            message( `A videók létrehozása előtt legalább egy kategória létrehozása szükséges!`, `Hiba` )
            return

        }

        e.preventDefault()
        navigate( `/videok/uj` )

    }

    const setKeyword = async ( keyword: string ) => {

        dispatch( { type: `SET_VIDEO_KEYWORD`, keyword } )

        if ( keyword.trim().length === 0 ) {
            await search()
        }

    }

  const onMenuSelect = async ( action: number ) => {

    switch ( action ) {

      case 3:
        setDeleteFunc( () => {
          return async () => {
            await deleteVideo( highlightedVideo!.id )
            await listVideos( pagination.page )
          }
        } )
        setShowConfirmModal( true )
        break

      default:
        navigate( `/videok/${ highlightedVideo!.id }` )

    }

  }

    return (

        <main className="page page--videos-list">

            <div className="main-div">

                <div className="navigation-title">

                    <h1 className="title-text-h1">Videók lista</h1>

                </div>

                <div className="toolbar">

                    <SearchInput placeholder="Keresés a videók között" onChange={ e => setKeyword( e.target.value ) } onSearch={ search } />
                    <SearchButton onClick={ search } />
                    <button className="button button--ghost" onClick={ () => setShowCategoriesModal( true ) }>Kategóriák</button>
                    <button className="button button--primary" onClick={ create }>+ Új videó</button>

                </div>

                { highlightedVideo ? (

                  <div className="info">

                    <h2 className="title-text-h2">Kiemelt Országos videó</h2>

                    { highlightedVideo === null ? '' : <div className="info inner">

                      <span className="label-text title">{ highlightedVideo.title }</span>

                      <div className="tag-container">
                        <CourseTopic color={ ( highlightedVideo as any ).category_color } name={ ( highlightedVideo as any ).category_name } />
                      </div>

                      <span className="label-text date">
                          { createFormattedDateTimeString( highlightedVideo.visibleFrom ) }
                        </span>

                      <span className="label-text open-count">

                        </span>

                      <div className="count-container">

                      </div>

                      <div className="menu-container">

                        <MenuButton hasBackground={ true } items={ [
                          {
                            id: 0,
                            value: `Szerkesztés`,
                            icon: `edit.svg`
                          },
                          {
                            id: 1,
                            value: `Kiemelés`,
                            icon: `pin.svg`
                          },
                          {
                            id: 3,
                            value: `Törlés`,
                            icon: `trash.svg`
                          }
                        ] } onChange={ ( action: number ) => onMenuSelect( action ) } />

                      </div>

                    </div> }

                  </div>

                ) : null }

                <div className="card-div card-div--table">

                    <VideosTable />

                </div>

            </div>

            { showCategoriesModal ? (

                <VideoCategoriesModal onClose={ () => setShowCategoriesModal( false ) } />

            ) : null }

            { showConfirmModal ? (

                <Modal className="application-version-confirm-modal" onModalClose={ () => setShowConfirmModal( false ) }>

                    <div className="modal-title">Biztosan törlöd a videót?</div>
                    <div className="modal-description">Ezt a műveletet nem lehet visszavonni! Biztosan törlöd?</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowConfirmModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ onDelete }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

        </main>

    )

}