import { useState } from 'react'
import './tab-view.scss'

interface TabViewProps {

    children: JSX.Element | JSX.Element[]
    tabs: { [ key: string ]: string }
    forcedTab?: string

    active?: string
    onTabChange?: ( key: string ) => void

}

export const TabView = ( props: TabViewProps ) => {

    const [ activeKey, setActiveKey ] = useState( Object.keys( props.tabs )[ 0 ] )

    const children = Array.isArray( props.children ) ? props.children : [ props.children ]

    const tabChange = ( key: string ) => {

        setActiveKey( key )
        if ( props.onTabChange ) {
            props.onTabChange( key )
        }

    }

    return (

        <div className="tab-view">

            { props.forcedTab === undefined ? (

                <header>

                    { Object.keys( props.tabs ).map( ( key: string ) => (
                        <div className={ `tab${ props.active ? ( props.active === key ? ` tab--active` : `` ) : ( activeKey === key ? ` tab--active` : `` ) }` } key={ key } onClick={ () => tabChange( key ) }>

                            { props.tabs[ key ] }

                        </div>
                    ) ) }

                </header>

            ) : null }

            <div className="tab-view-pages">

                { props.forcedTab === undefined ? (

                    children.filter( ( child: JSX.Element ) => child.key === ( props.active ? props.active : activeKey ) )

                ) : (

                    children.filter( ( child: JSX.Element ) => child.key === props.forcedTab )

                ) }

            </div>

        </div>

    )

}
