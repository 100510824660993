import { LoaderFunctionArgs } from 'react-router-dom'
import { store } from '../../store'
import { listTags } from '../../action/tags'
import { getPoliticians } from '../../action/politician'
import { listDefaultExperiencePoints } from "../../action/gamification"
import { getVideo, listVideoCategories } from "../../action/videos"

export const videoLoader = async ( args: LoaderFunctionArgs ) => {

    await listDefaultExperiencePoints()

    await getPoliticians()

    await listTags()

    await listVideoCategories()

    if ( args.params.id ) {

        await getVideo( parseInt( args.params.id ) )

    } else {

        store.dispatch( {
            type: `GET_VIDEO_OK`,
            video: null
        } )

    }

}