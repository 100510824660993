import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetAllNewsDTO, GetNewsDTO } from '../../dto'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { CREATE_NEWS_ACTIONS, NEWS_ACTIONS } from './news.constants'

export const getNews = async (id: number | null ) => {

    const promise = createCall(`cms/news/${ id }`, BASE_METHODS.GET, undefined, `2.0.0`)
    promise.then(async response => {

        let body: GetNewsDTO

        try {
            body = plainToClass( GetNewsDTO, JSON.parse( await response!.text() ) )
        } catch ( exception ) {
            store.dispatch( { type: NEWS_ACTIONS.GET_NEWS_INVALID_JSON } )
            return
        }

        if ( ( await validate( body ) ).length > 0 ) {
            store.dispatch( { type: NEWS_ACTIONS.GET_NEWS_INVALID_RESPONSE } )
            return
        }

        //store.dispatch( { type: NEWS_ACTIONS.GET_NEWS_OK, news: body.news } )
        store.dispatch( { type: CREATE_NEWS_ACTIONS.CREATE_NEWS_MODIFY_NEWS, news: body.news } )

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })

}
