import { ChangeEvent } from "react";
import "./text.field.scss";

interface TextFieldProps {
  field: {
    disabled?: boolean;
    error: string;
    name?: string;
    required?: boolean;
    value: string;
  };
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: "normal" | "small";
  category?: string;
  maxlength?: number;
  disabled?: boolean;
}

export const TextField = (props: TextFieldProps) => {
  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    const cleanText = text.replace(/<[^>]+>/g, "");
    document.execCommand("insertText", false, cleanText);
  };

  return (
    <div
      className={`field${props.field?.error.length > 0 ? ` field--error` : ``}${
        props.type === "small" ? ` field--small` : ``
      } field--text`}
    >
      <input
        className={props.category}
        onChange={props.onChange}
        disabled={props.disabled === true || props.field?.disabled === true}
        id={props.field?.name}
        name={props.field?.name}
        placeholder={props.placeholder}
        required={props.field?.required === true}
        type="text"
        maxLength={props.maxlength}
        onPaste={handlePaste}
        value={props.field?.value}
      />
    </div>
  );
};
