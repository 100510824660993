import './tag-selector.scss'
import { Tag } from '../../entity'
import { Modal } from '../modal'
import { TagGroup } from '../../page/tags/tag-group/tag-group'
import { useSelector } from 'react-redux'
import { State } from '../../state'
import { useState, MouseEvent } from 'react'
import { SearchButton, SearchInput } from '../search'

interface TagSelectorProps {

    compact?: boolean
    tags: Tag[]
    title?: string

    onTagAdd?: ( tag: Tag ) => void
    onTagDelete?: ( tag: Tag ) => void

}

export const TagSelector = ( props: TagSelectorProps ) => {

    const onDelete = ( tag: Tag ) => { if ( props.onTagDelete ) props.onTagDelete( tag ) }

    const onAddClick = ( e: MouseEvent ) => {
        e.preventDefault()
        setKeyword( `` )
        setShowModal( true )
    }

    const onTagClick = ( tag: Tag ) => {

        if ( props.tags.find( t => tag.id === t.id ) === undefined ) {

            if ( props.onTagAdd ) props.onTagAdd( tag )

        } else {

            if ( props.onTagDelete ) props.onTagDelete( tag )

        }

    }

    const { tagGroups } = useSelector( ( state: State ) => state.tags )

    const [ showModal, setShowModal ] = useState( false )
    const [ keyword, setKeyword ] = useState( `` )

    return (

        <div className={ `tag-selector${ props.compact === true ? ` tag-selector--compact` : `` }` }>

            <header>

                { props.compact !== true ? (

                    <div className="tag-selector__title">

                        { props.title ?? `Zárt címkék` }

                    </div>

                ) : null }

                <button className="button button--ghost" onClick={ onAddClick }>Új címke hozzáadása</button>

            </header>

            <main>

                { props.tags.map( tag => (

                    <div key={ tag.id } className="tag" style={{ backgroundColor: tag.color }}>

                        <span>{ tag.name }</span>
                        <img alt="" src="/image/close-white.svg" onClick={ () => onDelete( tag ) } />

                    </div>

                ) ) }

            </main>

            { showModal ? (

                <Modal className="modal--tag-selector" onModalClose={ () => setShowModal( false ) }>

                    <div className="modal-title">Címkék</div>

                    <img alt="" src="/image/close.svg" className="modal-close" onClick={ () => setShowModal( false ) } />

                    <div className="tag-selector-modal-header">

                        <SearchInput onChange={ e => setKeyword( e.target.value ) } />
                        <SearchButton onClick={ e => e.preventDefault() } />

                    </div>

                    <main>

                        { tagGroups.filter( tagGroup => tagGroup.tags.find( tag => tag.name.toLowerCase().indexOf( keyword.toLowerCase() ) !== -1 ) !== undefined ).map( tagGroup => (

                            <TagGroup key={ tagGroup.id } { ...tagGroup } onTagClick={ tag => onTagClick( tag ) } selectedTags={ props.tags } keyword={ keyword } />

                        ) ) }

                    </main>

                </Modal>

            ) : null }

        </div>

    )

}