import { Expose } from 'class-transformer'
import { IsDefined, IsString } from 'class-validator'

export class Session {

    @Expose()
    @IsDefined()
    @IsString()
    token!: string

}
