export const menu = [
    [
        {
            caption: 'Vezérlőpult',
            path: '/',
            icon: 'dashboard.svg'
        }
    ],
    [
        {
            caption: 'Hírek',
            path: '/hirek',
            icon: 'news.svg'
        },
        {
            caption: 'Események',
            path: '/esemenyek',
            icon: 'event.svg'
        }
    ],
    [
        {
            caption: 'Értesítések',
            path: '/push-uzenetek',
            icon: 'message.svg'
        },
        {
            caption: 'Floating üzenetek',
            path: '/floating-uzenetek',
            icon: 'floating.svg'
        }
    ],
    [
        {
            caption: 'Címkéző',
            path: '/cimkezo',
            icon: 'tag.svg'
        }
    ],
    // [
    //     {
    //         caption: 'Feladat kezelő',
    //         path: '/feladat-kezelo',
    //         icon: 'task.svg'
    //     }
    // ],
    [
        {
            caption: 'Kérdőívek',
            path: '/kerdoivek',
            icon: 'question.svg'
        },
        {
            caption: 'Szavazások',
            path: '/fooldal',
            icon: 'settings.svg'
        },
        {
            caption: 'Képzések',
            path: '/kepzesek',
            icon: 'question.svg'
        },
        {
            caption: 'Kvízek és tesztek',
            path: '/kvizek-es-tesztek',
            icon: 'question.svg'
        },
        {
            caption: 'Véleményvezérek',
            path: '/velemenyvezerek',
            icon: 'tinder.svg'
        },
        {
            caption: 'Videók',
            path: '/videok/lista',
            icon: 'question.svg'
        }
    ],
    [
        {
            caption: 'Ligák és kihívások',
            path: '/ligak-es-kihivasok',
            icon: 'point.svg'
        },
        // {
        //     caption: 'Főoldal - szavazasok',
        //     path: '/fooldal',
        //     icon: 'point.svg'
        // }
        // {
        //     caption: 'Feri 4 You',
        //     path: '/feri-4-you',
        //     icon: 'feri.svg'
        // }
    ],
    [
        // {
        //     caption: 'Információs felületek',
        //     path: '/informacios-feluletek',
        //     icon: 'info.svg'
        // },
        {
            caption: 'Politikusok',
            path: '/politikusok',
            icon: 'politician.svg'
        }
    ],
    [
        {
            caption: 'Tagok',
            children: [
                {
                    caption: 'Mobil App Felhasználók',
                    path: '/tagok/mobil-app-felhasznalok',
                    icon: 'users.svg'
                },
                {
                    caption: 'Adminok',
                    path: '/tagok/adminok',
                    icon: 'users.svg'
                }
            ],
            path: '/tagok',
            icon: 'users.svg'
        }
    ],
    [
        {
            caption: 'Statisztikák',
            path: '/statisztikak',
            icon: 'stats.svg'
        },
        {
            caption: 'Kitüntetések',
            path: '/kituntetesek',
            icon: 'stats.svg'
        }
    ],
    [
        {
            caption: 'Beállítások',
            path: '/beallitasok',
            icon: 'settings.svg'
        }
    ]
]
