import './news-statistics.tab.scss'
import Chart from 'react-apexcharts'
import { useSelector } from 'react-redux'
import { State } from '../../../state'

interface NewsStatisticsTabProps { }

export const NewsStatisticsTab = ( props: NewsStatisticsTabProps ) => {

    const { newNewsCount, newNewsXAxis, newNewsData, newsReaderCount, newsReaderXAxis, newsReaderData, thisWeekNewsReadCount, thisWeekNewsOpenCount } = useSelector( ( state: State ) => state.statistics.newsStats )

    const newNewsOptions = {
        chart: {
            id: 'line-chart',    
        },
        dataLabels: {
            enabled: true
        },
        stroke: {
            curve: "smooth" as "smooth",
        },
        colors: ['#0267AA'],
        xaxis: {
          categories: newNewsXAxis
        }
    }

    const newNewsSeries = [{
        name: 'Új aktív hír',
        data: newNewsData
    }]


    const newsReaderOptions = {
        chart: {
          id: 'bar-chart'
        },
        dataLabels: {
            enabled: true
        },
        colors: ['#0267AA'],

        xaxis: {
          categories: newsReaderXAxis
        }
    }

    const newsReaderSeries = [{
        name: 'Hány ember nyitott meg hírt',
        data: newsReaderData
    }]
    
  
    return (
        <div className="card-div">

            <div className="container">
                <div className="card-div card-div-right-margin">

                    <div className="container column">
                        <div className="info-slot">
                            <div className="info-slot__description">
                                <span className="caption"> Új aktív hír </span>
                                <span className="additional-info"> Hány új hír van a héten </span>
                            </div>

                            <div className="info-slot__info">
                                <span className="value">{ newNewsCount }</span> { "db" }
                            </div>
                        </div>

                        <Chart options={ newNewsOptions } series={ newNewsSeries } type="bar" height={ 260 } />
                    </div>

                </div>

                <div className="card-div card-div-right-margin">

                    <div className="container column">
                        <div className="info-slot">
                            <div className="info-slot__description">
                                <span className="caption"> Hány felhasználó nyitott meg hírt </span>
                                <span className="additional-info"> Hány olvasó volt az adott héten </span>
                            </div>

                            <div className="info-slot__info">
                                <span className="value">{ newsReaderCount }</span> { "db" }
                            </div>
                        </div>

                        <Chart options={ newsReaderOptions } series={ newsReaderSeries } type="bar" height={ 260 } />
                    </div>

                </div>

                <div className="card-div">

                    <div className="container column" >

                        <div className="info-slot">
                            <div className="info-slot__description">
                                <span className="caption"> Megnyitott hír </span>
                                <span className="additional-info"> Hányszor nyílt meg hír </span>
                            </div>

                            <div className="info-slot__info">
                                <span className="value">{ thisWeekNewsOpenCount }</span> { "db" }
                            </div>
                        </div>
 
                        <div className="info-slot" >
                            <div className="info-slot__description">
                                <span className="caption"> Végigolvasott hír </span>
                                <span className="additional-info"> Hányszor olvastak végig hírt </span>
                            </div>

                            <div className="info-slot__info">
                                <span className="value">{ thisWeekNewsReadCount }</span> { "db" }
                            </div>
                        </div>

                        <div className="info-slot" >
                            <div className="info-slot__description">
                                <span className="caption"> Végigolvasott hírek aránya </span>
                                <span className="additional-info">  </span>
                            </div>

                            <div className="info-slot__info">
                                <span className="value">{ ((thisWeekNewsReadCount/thisWeekNewsOpenCount) * 100).toFixed(0) }</span> { "%" }
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}


