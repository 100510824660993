export class ListWinnersDTO {

    winners!: any[]
    pagination!: {
        page: number
        from: number
        to: number
        count: number
    }

}