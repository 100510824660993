import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { ListWinnersDTO } from '../../dto/winners'
import { store } from '../../store'

export const listWinners = async ( roundId: number, page: number = 1, order = { by: `position`, direction: `ASC` }, keyword = `` ) => {

    let body: ListWinnersDTO
    let response: Response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/winners/${ roundId }?page=${ page }&order_by=${ order.by }&order_direction=${ order.direction }&keyword=${ keyword }`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'get'
        } )

    } catch ( exception ) {

        throw new Error

    }

    if ( response.status === 401 ) {

        throw new Error

    }

    if ( response.status !== 200 ) {

        throw new Error

    }

    try {

        body = plainToClass( ListWinnersDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        throw new Error

    }

    if ( ( await validate( body ) ).length > 0 ) {

        throw new Error

    }

    return { winners: body.winners, pagination: body.pagination }

}
