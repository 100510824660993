import { Link, useLocation } from 'react-router-dom'
import { MenuGroup } from './menu-group'
import './menu-element.scss'

interface MenuElementProps {
    menuElement: any
    onMobileMenuToggle?: ( open: boolean ) => void
}

export const MenuElement = ( props: MenuElementProps ) => {

    const location = useLocation()
    const open = props.menuElement.path !== '/' && location.pathname.startsWith( props.menuElement.path )

    const className = `${ props.menuElement.path === location.pathname ? `active` : `` }${ open ? ` open` : `` }${ props.menuElement.path !== location.pathname && open ? ` highlighted` : `` }`
    //location.pathname.includes(props.menuElement.path)

    const onMobileMenuToggle = ( open: boolean ) => {

        if ( props.onMobileMenuToggle ) props.onMobileMenuToggle( open )

    }

    return (
        <li className="menu__menu-element">
            <Link className={ className } to={ props.menuElement.path } onClick= { () => onMobileMenuToggle( false ) }>

                <span>{ props.menuElement.caption }</span>
                { props.menuElement.children ? <span className="material-symbols-outlined">
                    chevron_right
                </span> : null }

                <img alt= { props.menuElement.caption } src={'/image/menu/'+props.menuElement.icon} />

                <div>{ props.menuElement.caption }</div>

            </Link>
            { props.menuElement.children ? <MenuGroup menuGroup={ props.menuElement.children } open={ open } /> : null }
        </li >
    )

}
