import { Modal } from '../modal'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../state'
import { TextField } from '../field'
import { addExperience, getUser } from "../../action"
import './user-experience-modal.scss'
import { useState } from "react"
import { message } from "../../helper"

interface UserExperienceModalProps {

    onClose?: () => void

}

export const UserExperienceModal = ( props: UserExperienceModalProps ) => {

    const dispatch = useDispatch()
    const [ experience, setExperience ] = useState( `0` )
    const { user, userForm } = useSelector( ( state: State ) => state.users )

    const onClose = () => {

        if ( props.onClose ) props.onClose()

    }

    const onAddExperience = async () => {

        if ( isNaN( parseInt( experience ) ) || parseInt( experience ) <= 0 ) {
            message( `A tapasztalati pontnak pozitív egész számnak kell lennie!`, `Hibás adat!` )
            return
        }

        await addExperience( user!.id!, parseInt( experience ) )
        await getUser( user!.id! )
        if ( props.onClose ) props.onClose()

    }

    return (

        <Modal onModalClose={ onClose }>

            <div className="user-experience-modal">

                <div className="user-modal__title">

                    { user?.lastName } { user?.firstName }

                </div>

                <form className="user-modal__form">

                    <div className="form-field">

                        <div className="form-field__caption">

                            Vezetéknév

                        </div>

                        <div className="form-field__field">

                            { user?.lastName }

                        </div>

                    </div>

                    <div className="form-field">

                        <div className="form-field__caption">

                            Keresztnév

                        </div>

                        <div className="form-field__field">

                            { user?.firstName }

                        </div>

                    </div>

                    <div className="form-field">

                        <div className="form-field__caption">

                            E-mail cím

                        </div>

                        <div className="form-field__field">

                            { user?.email }

                        </div>

                    </div>

                    <div className="form-field">

                        <div className="form-field__caption">

                            Tapasztalati pont

                        </div>

                        <div className="form-field__field">

                            <TextField field={ { value: experience, error: `` } } onChange={ e => setExperience( e.target.value ) } placeholder="Tapasztalati pont" type="small" />

                        </div>

                    </div>

                </form>

                <footer className="user-modal__footer">

                    <button className="button" onClick={ onAddExperience }>

                        Tapasztalati pont hozzáadása

                    </button>

                </footer>

            </div>

        </Modal>
    )
}
