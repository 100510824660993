import { ChangeEvent } from 'react'
import { store } from '../../../../store'
import {listQuotes} from "../../../../action/quotes";

export const onSearchInputChange = ( event: ChangeEvent<HTMLInputElement> ) => {

    store.dispatch( { type: `SET_KEYWORD`, keyword: event.target.value } )

    if ( event.target.value === `` ) {

        listQuotes( store.getState().quotes.pagination.page )

    }

}
