import { Expose } from 'class-transformer'
import { IsDefined, IsOptional } from 'class-validator';
import { Video } from '../../entity'

export class ListVideosDTO {

    @Expose()
    @IsDefined()
    videos!: Video[]

    @Expose()
    @IsOptional()
    highlightedVideo!: Video

    @Expose()
    @IsDefined()
    pagination!: {
        page: number
        from: number
        to: number
        count: number
    }

}

