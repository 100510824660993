import { QuestionnaireQuestion, QuestionnaireQuestionAnswer, QuestionnaireQuestionUserAnswer } from '../../../../entity'
import './questionnaire-user-answer-element.scss'
import { QuestionnaireUserAnswerAnswerElement } from './questionnaire-user-answer-answer-element'
import { UserTextAnswerElement } from './user-text-answer-element'
import { UserImageAnswerElement } from './user-image-answer-element'
import { useSelector } from 'react-redux'
import { State } from '../../../../state'

interface QuestionnaireUserAnswerElementProps {
    questionElement: QuestionnaireQuestion
    disabled?: boolean
}

export const QuestionnaireUserAnswerElement = ( props: QuestionnaireUserAnswerElementProps ) => {

    const { userAnswers } = useSelector( ( state: State ) => state.questionnaires )

    let hashMap = new Map()
    let scale = 0
    let scaleCount = 0
    let scaleAvg = 0

    const questionElements: JSX.Element[] = []
    if(props.questionElement.answers != null){
        props.questionElement.answers.map(( answerElement: QuestionnaireQuestionAnswer, index  ) => {
            questionElements.push( <QuestionnaireUserAnswerAnswerElement answerElement={ answerElement } position={ index+1 }  /> )
        })
    }

    const answerElements: JSX.Element[] = []
    if(props.questionElement.userAnswers != null){
        props.questionElement.userAnswers.map(( userAnswerElement: QuestionnaireQuestionUserAnswer ) => {
            if(userAnswerElement.answer != null && userAnswerElement.answer != ""){
                answerElements.push( <UserTextAnswerElement answerElement={ userAnswerElement }  /> )
            }
        })
    }

    let avgCount = 0;
    if (props.questionElement.answers) {
        for (const answer of props.questionElement.answers) {
            avgCount += answer.answerCount
            scaleAvg += answer.answerCount * parseInt( answer.answer )
        }
        scaleAvg = parseFloat(( scaleAvg / avgCount ).toFixed(2))
    }


    return (
        <li key={ props.questionElement.id } className="questionnaire-user-answer-table__questionnaire-user-answer-element" >

            <div className="questionnaire-user-answer-element-indicator"/>

            <div className="questionnaire-user-answer-element-container">

                <div className="text-section-container">

                    <div className="input-container-header ">
                        <h3 className="title-text-h3 title-text-bottom-padding">{props.questionElement.question}</h3>
                    </div>

                    <div className="input-container-header ">
                        <h3 className="label-text">{props.questionElement.description}</h3>
                    </div>

                    { props.questionElement.imageUrl !== "" ? <img className= "question-image" src= { props.questionElement.imageUrl } alt= "Kérdéshez tartozó kép" /> : null }
                    
                 </div>


                {/* --- Válaszok ---  */}
                { ( props.questionElement.type < 3 ) ?

                    <div className="question-scale-container-div">
                        <ul id={"questionnaire_answer_answer_table"} className="questionnaire-question-answer-table">
                            { questionElements.length != 0 ? questionElements : null }
                        </ul>
                    </div>
                    : null 
                }


                {/* --- Skála ---  */}
                { ( props.questionElement.type == 3 ) ?
                    <div className="question-scale-container-div">
                      
                        <div className="input-container-header">
                            <h3 className="title-text-h3">Skála “{props.questionElement.scaleFrom}“ - {props.questionElement.scaleFromTitle}</h3>
                        </div>

                        <div className="input-container-header">
                            <h3 className="title-text-h3">{scaleAvg.toFixed(1)}</h3>
                        </div>

                        <div className="input-container-header" >
                            <h3 className="title-text-h3">Skála “{props.questionElement.scaleTo}” - {props.questionElement.scaleToTitle}</h3>
                        </div>
                                        
                    </div>
                    : null 
                } 
               

                {/* --- Szabadválasz ---  */}
                { ( props.questionElement.type == 4 ) ?

                    <ul id={"user-text-answer_table"} className="user-text-answer-table">

                        { userAnswers.find( q => q.id === props.questionElement.id )?.answers.map( answer => <pre>{ answer }</pre> ) }

                    </ul>
                    : null
                }


                {/* --- Képválasztós ---  */}
                { ( props.questionElement.type == 5 ) ?

                    <div className="questionnaire-question-answer-image-table-container">

                        <div className="questionnaire-question-answer-image-div-container">

                            <UserImageAnswerElement questionElement={props.questionElement} position={0} />                                

                            <UserImageAnswerElement questionElement={props.questionElement} position={1} />                                

                        </div>

                        <div className="questionnaire-question-answer-image-div-container">

                            <UserImageAnswerElement questionElement={props.questionElement} position={2} />                                

                            <UserImageAnswerElement questionElement={props.questionElement} position={3} />   
                                
                        </div>

                    </div>
                    : null 
                }

            </div>

        </li >
    )

}
