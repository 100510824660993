import { LoaderFunctionArgs } from 'react-router-dom'
import { store } from '../../store'
import { getQuiz, listQuizTopics } from '../../action/quizes'
import { listTags } from '../../action/tags'
import { listDefaultExperiencePoints } from "../../action/gamification"

export const quizLoader = async ( args: LoaderFunctionArgs ) => {

    await listDefaultExperiencePoints()

    await listTags()

    await listQuizTopics()

    if ( args.params.id ) {

        await getQuiz( parseInt( args.params.id ) )

    } else {

        store.dispatch( {
            type: `SET_QUIZ_TYPE`,
            quizType: `quiz`
        } )
        store.dispatch( {
            type: `GET_QUIZ_OK`,
            quiz: null,
            experience: store.getState().gamification.defaultExperiencePoints.find( d => d.name === `quizexperience` )?.experience || 200,
            answerExperience: store.getState().gamification.defaultExperiencePoints.find( d => d.name === `quizanswer` )?.experience || 10
        } )

    }

}