import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetQuestionnaireDTO } from '../../dto/questionnaires'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { CREATE_QUESTIONNAIRE_ACTIONS, GET_QUESTIONNAIRES_ACTIONS } from './questionnaires.constants'

export const getQuestionnaire = async (id: number | null ) => {

    const promise = createCall(`questionnaires/${ id }`, BASE_METHODS.GET)
    promise.then(async response => {

        let body: GetQuestionnaireDTO

        try {
            body = plainToClass( GetQuestionnaireDTO, JSON.parse( await response!.text() ) )
        } catch ( exception ) {
            store.dispatch( { type: GET_QUESTIONNAIRES_ACTIONS.GET_QUESTIONNAIRES_INVALID_JSON } )
            return
        }
    
        if ( ( await validate( body ) ).length > 0 ) {
            store.dispatch( { type: GET_QUESTIONNAIRES_ACTIONS.GET_QUESTIONNAIRES_INVALID_RESPONSE } )
            return
        }
    
        store.dispatch( { type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_MODIFY_QUESTIONNAIRE, questionnaire: body.questionnaire } )

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })

}
