import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetDashboardStatsDTO } from '../../dto/dashboard'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { GET_DASHBOARD_STATS_ACTIONS } from './dashboard.constants'

export const getDashboardStats = async () => {

    store.dispatch( { type: GET_DASHBOARD_STATS_ACTIONS.GET_DASHBOARD_STATS_STARTED } )

    const promise = createCall(`cms/dashboard/stats`, BASE_METHODS.GET)
    promise.then(async response => {

        let responseBody: GetDashboardStatsDTO

        try {
            responseBody = plainToClass( GetDashboardStatsDTO, JSON.parse( await response.text() ) )
        } catch ( exception ) {
            store.dispatch( { type: GET_DASHBOARD_STATS_ACTIONS.GET_DASHBOARD_STATS_INVALID_JSON } )
            return
        }
    
        if ( ( await validate( responseBody ) ).length > 0 ) {
            store.dispatch( { type: GET_DASHBOARD_STATS_ACTIONS.GET_DASHBOARD_STATS_INVALID_RESPONSE } )
            return
        }
    
        store.dispatch( { type: GET_DASHBOARD_STATS_ACTIONS.GET_DASHBOARD_STATS_OK, 
            lastEvents: responseBody.lastEvents, 
            lastNews: responseBody.lastNews,
            userCount: responseBody.userCount,
            userCountPercent: responseBody.userCountPercent,
            lastPushMessages: responseBody.lastPushMessages,
            applicationLaunchedCountPercent: responseBody.applicationLaunchedCountPercent,
            applicationLaunchedCount: responseBody.applicationLaunchedCount 
        } )
    
    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })
   
}