import { Expose } from 'class-transformer'
import { IsDefined } from 'class-validator'
import { BannedWord } from "../../entity"

export class ListBannedWordsDTO {

    @Expose()
    @IsDefined()
    bannedWords!: BannedWord[]

}
