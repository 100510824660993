import { Expose } from 'class-transformer'
import { IsDefined } from 'class-validator'
import { Video } from "../../entity"

export class GetVideoDTO
{

    @Expose()
    @IsDefined()
    video!: Video

}

