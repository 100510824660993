import { ChangeEvent, createRef, MouseEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import { createPolitician, deletePolitician } from "../../action/politician";
import { AddImage } from "../../component/add-image";
import { TextField } from "../../component/field";
import { ImageEditor } from "../../component/image-editor/image-editor";
import { MediaLibrary } from "../../component/media-library";
import { MessageModal } from "../../component/modal";
import { PoliticianBiographyTable } from "../../component/politicians";
import { PoliticianPhraseTable } from "../../component/politicians/politician-phrase-table";
import { PoliticianQuestionAnswerElement } from "../../component/politicians/politician-question-answer-element";
import { AddPoliticianTitleModal } from "../../component/politicians/politician-title-add-modal";
import { PoliticianTitleElement } from "../../component/politicians/politician-title-element";
import { TabView } from "../../component/tab-view";
import { TabViewPage } from "../../component/tab-view/tab-view-page";
import { TextArea } from "../../component/textarea";
import { DescriptionTextArea } from "../../component/textarea/description";
import { MediaLibraryFile, PoliticianTitle } from '../../entity'
import { State } from "../../state";
import { PoliticainStatsTab } from "./components/politician-stats.tab";
import "./create-politician.page.scss";
import { TagSelector } from '../../component/tag-selector'

interface CreatePoliticianProps {}

export const CreatePolitician = (props: CreatePoliticianProps) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [infoModalVisibility, setInfoModalVisibility] = useState(false);
  const [infoModalTitle, setInfoModalTitle] = useState("");
  const [infoModalDescription, setInfoModalDescription] = useState("");

  const changeWithinLimits = ( type: string, fieldName: string, e: ChangeEvent<HTMLInputElement>, low: number, high: number ) => {
    let value = e.target.value
    if ( value.trim().length === 0 ) { value = low.toString() }
    if ( isNaN( parseInt( value ) ) ) { return }
    if ( parseInt( value ) > high ) { value = high.toString() }
    if ( parseInt( value ) < low ) { value = low.toString() }

    const action: any = { type: type }
    action[ fieldName ] = value
    dispatch( action )
  }

  const [mediaLibraryFile, setMediaLibraryFile] = useState( null as MediaLibraryFile | null);
  const [mediaLibraryVisibility, setMediaLibraryVisibility] = useState(false);
  const [imageEditorVisibility, setImageEditorVisibility] = useState(false);
  const [titleModalVisibility, setTitleModalVisibility] = useState(false);
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);

  const saveDate = useSelector(
    (state: State) => state.createPolitician.saveDate
  );
  const politicianId = useSelector(
    (state: State) => state.createPolitician.form.id
  );
  const politician = useSelector(
    (state: State) => state.createPolitician.politician
  );
  const originalQuestions = useSelector(
    (state: State) => state.politicians.questions
  );
  const titles = useSelector(
    (state: State) => state.createPolitician.form.titles
  );
  const questions = useSelector(
    (state: State) => state.createPolitician.form.questions
  );
  const correctAnswerId = useSelector(
    (state: State) => state.createPolitician.form.question.correctAnswerId
  );

  const form = useSelector((state: State) => state.createPolitician.form);

    const { defaultExperiencePoints } = useSelector( ( state: State ) => state.gamification )

    const readExperiencePoint = defaultExperiencePoints.find( dep => dep.name === `politicianread` )

    if ( form.experience.value === `` && readExperiencePoint ) {
        dispatch( { type: `POLITICIAN_CHANGE_EXPERIENCE`, experience: readExperiencePoint.experience.toString() } )
    }

    const quizExperiencePoint = defaultExperiencePoints.find( dep => dep.name === `politicianquiz` )
    if ( form.quizExperience.value === `` && quizExperiencePoint ) {
        dispatch( { type: `POLITICIAN_CHANGE_QUIZ_EXPERIENCE`, quizExperience: quizExperiencePoint.experience.toString() } )
    }

  const formElement = createRef<HTMLFormElement>();

  useEffect(() => {
    if (politicianId == null) {
      //új politikus létrhozásánál, a közös kérdések betöltése
      dispatch({
        type: "CREATE_POLITICIAN_INIT_QUESTIONS",
        questions: originalQuestions,
      });
    }
  }, []);

  if (politician !== null) {
    return <Navigate to="/politikusok" />;
  }

  const onBackClick = (event: MouseEvent) => {
    event.preventDefault();
    navigate(`/politikusok`);
  };

  const onSaveButtonClick = async (event: MouseEvent) => {
    event.preventDefault();

    if (!checkFields()) {
      return;
    }

    if (!formElement.current?.reportValidity()) {
      return;
    }

    await createPolitician();
  };

  const checkFields = (): boolean => {
    if (form.lastName.value.trim().length === 0) {
      setInfoModalTitle("Figyelmeztetés");
      setInfoModalDescription( "Politikus vezetéknevének megadása kötelező!");
      setInfoModalVisibility(true);
      return false
    }

    if (form.firstName.value.trim().length === 0) {
      setInfoModalTitle("Figyelmeztetés");
      setInfoModalDescription( "Politikus keresztnevének megadása kötelező!");
      setInfoModalVisibility(true);
      return false
    }

    if (form.description.value.trim().length === 0) {
      setInfoModalTitle("Figyelmeztetés");
      setInfoModalDescription( "Politikushoz tartozó leírás kötelező!");
      setInfoModalVisibility(true);
      return false
    }

    if (form.mediaLibraryFile === null) {
      setInfoModalTitle("Figyelmeztetés");
      setInfoModalDescription( "Kép választása kötelező!");
      setInfoModalVisibility(true);
      return false
    }

    if (form.experience.value === "") {
      setInfoModalTitle("Figyelmeztetés");
      setInfoModalDescription( "Tapasztalati pont a végigolvasásért megadása kötelező!");
      setInfoModalVisibility(true);
      return false
    }
    if (form.quizExperience.value === "") {
      setInfoModalTitle("Figyelmeztetés");
      setInfoModalDescription( "Tapasztalati pont a kvízért megadása kötelező!");
      setInfoModalVisibility(true);
      return false
    }

    if (form.question.value.trim().length === 0) {
      setInfoModalTitle("Figyelmeztetés");
      setInfoModalDescription( "Villámkérdés megadása kötelező!");
      setInfoModalVisibility(true);
      return false
    }

    if (form.question.answer1.value.trim().length === 0) {
      setInfoModalTitle("Figyelmeztetés");
      setInfoModalDescription( "Villámkérdés 1. válasz megadása kötelező!");
      setInfoModalVisibility(true);
      return false
    }

    if (form.question.answer2.value.trim().length === 0) {
      setInfoModalTitle("Figyelmeztetés");
      setInfoModalDescription( "Villámkérdés 2. válasz megadása kötelező!");
      setInfoModalVisibility(true);
      return false
    }

    if (form.question.answer3.value.trim().length === 0) {
      setInfoModalTitle("Figyelmeztetés");
      setInfoModalDescription( "Villámkérdés 3. válasz megadása kötelező!");
      setInfoModalVisibility(true);
      return false
    }

    if (form.question.answer4.value.trim().length === 0) {
      setInfoModalTitle("Figyelmeztetés");
      setInfoModalDescription( "Villámkérdés 4. válasz megadása kötelező!");
      setInfoModalVisibility(true);
      return false
    }

    if (form.biographyItems) {
      for (const biographyItem of form.biographyItems) {
        if (isNaN(parseInt(biographyItem.yearField.value))) {
          setInfoModalTitle("Figyelmeztetés");
          setInfoModalDescription( "Az évszámnak egész számnak kell lennie!");
          setInfoModalVisibility(true);
          return false
        }
        if (parseInt(biographyItem.yearField.value) > new Date().getFullYear()) {
          setInfoModalTitle("Figyelmeztetés");
          setInfoModalDescription( "Nem lehet jövőbeli eseményt megadni!");
          setInfoModalVisibility(true);
          return false
        }
      }
    }

    return true
  }

  const onDeleteButtonClick = async (event: MouseEvent) => {
    event.preventDefault();
    setDeleteModalVisibility(true);
  };

  const onFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    dispatch({
      type: "CREATE_POLITICIAN_CHANGE_FIRST_NAME",
      firstName: event.target.value,
    });
  };

  const onLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    dispatch({
      type: "CREATE_POLITICIAN_CHANGE_LAST_NAME",
      lastName: event.target.value,
    });
  };

  const onInnerPositionChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    dispatch({
      type: "CREATE_POLITICIAN_CHANGE_INNER_POSITION",
      innerPosition: event.target.value,
    });
  };

  const onPoliticianIsVisibleChange = ( e: ChangeEvent<HTMLInputElement> ) => {

      dispatch({
          type: "CREATE_POLITICIAN_CHANGE_IS_VISIBLE",
          isVisible: e.target.value === `1`
      });

  }


  const onPositionChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    dispatch({
      type: "CREATE_POLITICIAN_CHANGE_POSITION",
      position: event.target.value,
    });
  };

  const onShadowGovernmentPositionChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    dispatch({
      type: "CREATE_POLITICIAN_CHANGE_SHADOW_GOVERNMENT_POSITION",
      shadowGovernmentPosition: event.target.value,
    });
  };

  const onDescriptionChange = (text: string) => {
    dispatch({
      type: "CREATE_POLITICIAN_CHANGE_DESCRIPTION",
      description: text,
    });
  };

  //Villámkérdés
  const onQuestionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    dispatch({
      type: "CREATE_POLITICIAN_CHANGE_QUESTION",
      question: event.target.value,
    });
  };

  const onAnswer1Change = (event: ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    dispatch({
      type: "CREATE_POLITICIAN_CHANGE_ANSWER1",
      answer: event.target.value,
    });
  };

  const onCorrectAnswerButtonClick = (answerId: number) => {
    dispatch({
      type: "CREATE_POLITICIAN_CHANGE_CORRECT_ANSWER_ID",
      id: answerId,
    });
  };

  const onAnswer2Change = (event: ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    dispatch({
      type: "CREATE_POLITICIAN_CHANGE_ANSWER2",
      answer: event.target.value,
    });
  };

  const onAnswer3Change = (event: ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    dispatch({
      type: "CREATE_POLITICIAN_CHANGE_ANSWER3",
      answer: event.target.value,
    });
  };

  const onAnswer4Change = (event: ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    dispatch({
      type: "CREATE_POLITICIAN_CHANGE_ANSWER4",
      answer: event.target.value,
    });
  };

  const politicianElements: JSX.Element[] = [];

  if (questions !== null && questions !== undefined) {
    questions.map((questionElement: any) => {
      politicianElements.push(
        <PoliticianQuestionAnswerElement field={questionElement} />
      );
    });
  }

  const onDeleteTitleButtonClick = async (event: MouseEvent) => {
    event.preventDefault();
    dispatch({
      type: "CREATE_POLITICIAN_REMOVE_TITLE",
      selectedTitleId: parseInt(event.currentTarget.id),
    });
  };

  const onAddTitleButtonClick = async (event: MouseEvent) => {
    event.preventDefault();

    setTitleModalVisibility(true);
  };

  const onAddTitleCloseButtonClick = async (event: MouseEvent) => {
    event.preventDefault();

    setTitleModalVisibility(false);
  };

  const titlesElements: JSX.Element[] = [];

  if (titles !== null && titles !== undefined) {
    titles.map((politicianTitleElement: PoliticianTitle) => {
      titlesElements.push(
        <PoliticianTitleElement
          politicianTitleElement={politicianTitleElement}
          onDeleteClick={onDeleteTitleButtonClick}
        />
      );
    });
  }

  return (
    <main className="page page-politicians page-create-politician">
      <div className="main-div">
        <div className="navigation-title">
          <NavLink to="/politikusok">
            <h1 className="title-text-h1">Politikusok {">"}</h1>
          </NavLink>
          <h1 className="title-text-h1">
            {form.id == null ? "Új politikus" : "Politikus szerkesztése"}{" "}
          </h1>
        </div>

        <TabView
          tabs={{ politician: "Politikus", stats: "Statisztika" }}
          forcedTab={form.id == null ? `politician` : undefined}
        >
          <TabViewPage key="politician">
            <div className="toolbar">
              <button
                className="button button--ghost back-button"
                onClick={onBackClick}
              >
                Vissza
              </button>

              {politicianId !== null ? (
                <button className="button" onClick={onDeleteButtonClick}>
                  Politikus törlése
                </button>
              ) : null}

              <button className="button" onClick={onSaveButtonClick}>
                Mentés
              </button>
            </div>

            <form action="" className="create-news-form" ref={formElement}>
              {/* --- Politikus megadása  --- */}
              <div className="container container-top-margin">
                <div className="card-div">
                  <div className="container column">
                    <div className="title-container">
                      <h2 className="title-text-h2">Politikus megadása</h2>
                    </div>

                    <div className="container main-container">
                      {/* --- Kép--- */}
                      <div className="image-section-container">
                        <AddImage
                          width={"184px"}
                          height={"248px"}
                          imageUrl={form.imageUrl}
                          text={"Kép hozzáadása a politikushoz (2:3)"}
                          onAddImage={() => setMediaLibraryVisibility(true)}
                          onDeleteImage={() => {
                            setMediaLibraryFile(null);
                            dispatch({
                              type: "CREATE_POLITICIAN_CHANGE_MEDIA_LIBRARY_FILE",
                              mediaLibraryFile: null,
                            });
                          }}
                        />
                      </div>

                      {/* --- Politikus neve --- */}
                      <div className="text-section-container">
                        <div className="input-container-header">
                          <h3 className="title-text-h3 title-text-bottom-padding">
                            Politikus vezetékneve *
                          </h3>
                        </div>

                        <TextField
                          field={form.lastName}
                          maxlength={255}
                          onChange={onLastNameChange}
                          placeholder=""
                          category="title"
                        />

                        <div className="input-container-header container-top-margin">
                          <h3 className="title-text-h3 title-text-bottom-padding">
                            Politikus keresztneve *
                          </h3>
                          <span className="label-text info">
                            Ajánlott karakter szám maximum: 120 karakter
                          </span>
                        </div>

                        <TextField
                          field={form.firstName}
                          maxlength={255}
                          onChange={onFirstNameChange}
                          placeholder=""
                          category="title"
                        />

                          <div className="columns">

                              <div className="card-div" style={{ marginTop: 20, marginRight: 20 }}>

                                  <TagSelector tags={ form.tags } onTagAdd={ tag => dispatch( { type: `POLITICIAN_ADD_TAG`, tag: tag } ) } onTagDelete={ tag => dispatch( { type: `POLITICIAN_REMOVE_TAG`, tag: tag } ) } />

                              </div>

                              <div className="card-div card-div--options" style={{ marginTop: 20, width: 400 }}>

                                  <h3 className="title-text-h3 title-text-bottom-padding">Politikus megjelenítése</h3>

                                  <div className="check-box-group">

                                       <div className="input-group-div">

                                          <input checked={ form.isVisible } type="radio" id="politician_visible" name="politician_is_visible" value={ 1 } onChange={ onPoliticianIsVisibleChange } />

                                          <label className="label-text checkbox-label" htmlFor="politician_visible">

                                              Látható

                                          </label>

                                          <input checked={ !form.isVisible } type="radio" id="politician_hidden" name="politician_is_visible" value={ 0 } onChange={ onPoliticianIsVisibleChange } />

                                          <label className="label-text checkbox-label" htmlFor="politician_hidden">

                                              Rejtett

                                          </label>

                                       </div>

                                  </div>

                              </div>

                          </div>

                      </div>

                    </div>

                    {/* --- Pozíció , leírás--- */}
                    <div className="text-section-container ">
                      <div className="title-section-container">
                        <img className="dk-icon" src="/image/dk-logo.svg" />
                        <h3 className="title-text-h3 title-bottom-margin">
                          DK-n belüli pozíció
                        </h3>
                      </div>
                      <TextField
                        field={form.innerPosition}
                        maxlength={255}
                        onChange={onInnerPositionChange}
                        placeholder=""
                        category="position"
                      />

                      <div className="title-section-container container-top-margin">
                        <img
                          className="pos-icon"
                          src="/image/politician_position.png"
                        />
                        <h3 className="title-text-h3 title-bottom-margin">
                          DK-n kívüli pozíció
                        </h3>
                      </div>
                      <TextField
                        field={form.position}
                        maxlength={255}
                        onChange={onPositionChange}
                        placeholder=""
                        category="position"
                      />

                      <div className="title-section-container container-top-margin">
                        <img
                          className="pos-icon"
                          src="/image/politician_position.png"
                        />
                        <h3 className="title-text-h3 title-bottom-margin">
                          Árnyékkormány tisztség
                        </h3>
                      </div>
                      <TextField
                        field={form.shadowGovernmentPosition}
                        maxlength={255}
                        onChange={onShadowGovernmentPositionChange}
                        placeholder=""
                        category="position"
                      />

                      <div className="title-section-container container-top-margin">
                        <h3 className="title-text-h3 title-bottom-margin">
                          Titulus kiválasztása
                        </h3>
                      </div>

                      <div className="add-title-container">
                        <button
                          className="button add-title-button"
                          onClick={onAddTitleButtonClick}
                        >
                          {" "}
                          Új titulus hozzáadása{" "}
                        </button>

                        <ul id={"title_table"} className="title-list">
                          {titlesElements.length != 0 ? titlesElements : ""}
                        </ul>
                      </div>

                      <DescriptionTextArea
                        title={"Politikushoz tartozó leírás *"}
                        field={form.description}
                        onChange={onDescriptionChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

                <div className="card-div" style={{ marginTop: 20 }}>

                    <div className="xp-editor">

                        <img alt="" src="/image/exp.svg" />

                        <div className="field">

                            <label>Tapasztalati pont a végigolvasásért (1-50 TP) *</label>
                            <TextField field={ form.experience } onChange={ e => changeWithinLimits( `POLITICIAN_CHANGE_EXPERIENCE`, `experience`, e, 1, 50 ) } />

                        </div>

                        <div className="field">

                            <label>Tapasztalati pont a kvízért (100-250 TP) *</label>
                            <TextField field={ form.quizExperience } onChange={ e => changeWithinLimits( `POLITICIAN_CHANGE_QUIZ_EXPERIENCE`, `quizExperience`, e, 100, 250 ) } />

                        </div>

                    </div>

                </div>

              {/* --- Politikus kérdés - válasz --- */}
              <div className="container container-top-margin">
                <div className="card-div">
                  <div className="container column">
                    <h2 className="title-text-h2">Politikus kérdés - válasz</h2>

                    <div className="politician-question-answer-container">
                      <ul
                        id={"politicia_question_answer_table"}
                        className="politician-question-answer-list"
                      >
                        {politicianElements.length != 0
                          ? politicianElements
                          : ""}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* --- Politikus életút --- */}
              <div className="container container-top-margin">
                <div className="card-div">
                  <div className="container column">
                    <h2 className="title-text-h2">Politikus életút</h2>

                    <PoliticianBiographyTable />
                  </div>
                </div>
              </div>

              {/* --- Villámkérdés --- */}

              <div className="container container-top-margin">
                <div className="card-div">
                  <div className="container column">
                    <h2 className="title-text-h2">Villámkérdés *</h2>

                    <div className="text-section-container container-top-margin">
                      <TextArea
                        field={form.question}
                        onChange={onQuestionChange}
                        placeholder=""
                        category="question"
                      />

                      <div className="container container-center">
                        <h3 className="title-text-h3  header-text-center">
                          1. Válasz *
                        </h3>

                        <button
                          className={`correct-answer-button ${
                            correctAnswerId == 1
                              ? ``
                              : `correct-answer-button--ghost`
                          }`}
                          type="button"
                          onClick={(e) => onCorrectAnswerButtonClick(1)}
                        >
                          Ez a helyes válasz
                        </button>
                      </div>
                      <TextArea
                        field={form.question.answer1}
                        onChange={onAnswer1Change}
                        placeholder=""
                        category="answer"
                      />

                      <div className="container container-center">
                        <h3 className="title-text-h3  header-text-center">
                          2. Válasz *
                        </h3>
                        <button
                          className={`correct-answer-button ${
                            correctAnswerId == 2
                              ? ``
                              : `correct-answer-button--ghost`
                          }`}
                          type="button"
                          onClick={(e) => onCorrectAnswerButtonClick(2)}
                        >
                          Ez a helyes válasz
                        </button>
                      </div>
                      <TextArea
                        field={form.question.answer2}
                        onChange={onAnswer2Change}
                        placeholder=""
                        category="answer"
                      />

                      <div className="container container-center">
                        <h3 className="title-text-h3  header-text-center">
                          3. Válasz *
                        </h3>
                        <button
                          className={`correct-answer-button ${
                            correctAnswerId == 3
                              ? ``
                              : `correct-answer-button--ghost`
                          }`}
                          type="button"
                          onClick={(e) => onCorrectAnswerButtonClick(3)}
                        >
                          Ez a helyes válasz
                        </button>
                      </div>
                      <TextArea
                        field={form.question.answer3}
                        onChange={onAnswer3Change}
                        placeholder=""
                        category="answer"
                      />

                      <div className="container container-center">
                        <h3 className="title-text-h3  header-text-center">
                          4. Válasz *
                        </h3>
                        <button
                          className={`correct-answer-button ${
                            correctAnswerId == 4
                              ? ``
                              : `correct-answer-button--ghost`
                          }`}
                          type="button"
                          onClick={(e) => onCorrectAnswerButtonClick(4)}
                        >
                          Ez a helyes válasz
                        </button>
                      </div>
                      <TextArea
                        field={form.question.answer4}
                        onChange={onAnswer4Change}
                        placeholder=""
                        category="answer"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {politicianId !== null && (
                /* --- Mondatok --- */

                <div className="container container-top-margin">
                  <div className="card-div">
                    <div className="container column">
                      <h2 className="title-text-h2">Mondatok</h2>

                      <PoliticianPhraseTable />
                    </div>
                  </div>
                </div>
              )}

              <footer>
                {politicianId !== null ? (
                  <button className="button" onClick={onDeleteButtonClick}>
                    Politikus törlése
                  </button>
                ) : null}

                <button className="button" onClick={onSaveButtonClick}>
                  Mentés
                </button>
              </footer>
            </form>
          </TabViewPage>

          <TabViewPage key="stats">
            <PoliticainStatsTab />
          </TabViewPage>
        </TabView>
      </div>

      {infoModalVisibility ? (
                <MessageModal
                    title={infoModalTitle}
                    message={infoModalDescription}
                    onCloseClick={() => {
                        setInfoModalVisibility(false);
                    }}
                />
            ) : null}

      {titleModalVisibility ? (
        <AddPoliticianTitleModal
          onCloseClick={onAddTitleCloseButtonClick}
          onModalClose={() => setTitleModalVisibility(false)}
        />
      ) : null}

      {mediaLibraryVisibility ? (
        <MediaLibrary
          onClose={() => setMediaLibraryVisibility(false)}
          onMediaSelected={(file: MediaLibraryFile) => {
            setMediaLibraryFile(file);
            setMediaLibraryVisibility(false);
            setImageEditorVisibility(true);
          }}
        />
      ) : null}

      {imageEditorVisibility ? (
        <ImageEditor
          mediaLibraryFile={mediaLibraryFile!}
          aspectRatio={{ name: "2:3", ratio: 2 / 3 }}
          onClose={() => setImageEditorVisibility(false)}
          onReady={(file) => {
            dispatch({
              type: "CREATE_POLITICIAN_CHANGE_MEDIA_LIBRARY_FILE",
              mediaLibraryFile: file,
            });
            setImageEditorVisibility(false);
          }}
        />
      ) : null}

      {deleteModalVisibility ? (
        <MessageModal
          title={"Figyelmeztetés"}
          message={"Biztosan törölni szeretnéd a politikust?"}
          onOkClick={async () => {
            if (politicianId != null) {
              deletePolitician(politicianId);
              navigate("/politikusok");
            }
            setDeleteModalVisibility(false);
          }}
          onCloseClick={() => {
            setDeleteModalVisibility(false);
          }}
        />
      ) : null}
    </main>
  );
};
