import './profile-modal.scss'
import { MessageModal, Modal } from '../modal'
import { PasswordField, TextField } from '../field'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { State } from '../../state'
import { message } from '../../helper'
import { modifyUser } from '../../action'

interface ProfileModalProps {

    onModalClose?: () => void

}

export const ProfileModal = ( props: ProfileModalProps ) => {

    const { authenticatedUser } = useSelector( ( state: State ) => state.session )

    const [ infoModalVisibility, setInfoModalVisibility ] = useState( false )
    const [ infoModalTitle, setInfoModalTitle ] = useState( "" )
    const [ infoModalDescription, setInfoModalDescription ] = useState( "" )

    const checkFields = () => {

        if ( firstName.value.trim().length === 0 ) {

            setInfoModalTitle( "Figyelmeztetés" )
            setInfoModalDescription( "A keresztnév kitöltése kötelező!" )
            setInfoModalVisibility( true )
            return false

        }

        if ( lastName.value.trim().length === 0 ) {

            setInfoModalTitle( "Figyelmeztetés" )
            setInfoModalDescription( "A vezetéknév kitöltése kötelező!" )
            setInfoModalVisibility( true )
            return false

        }

        if ( newPassword.value !== confirmNewPassword.value ) {

            setInfoModalTitle( "Figyelmeztetés" )
            setInfoModalDescription( "A megadott két jelszó nem egyezik meg!" )
            setInfoModalVisibility( true )
            return false

        }

        return true

    }

    const onSave = async () => {

        if ( !checkFields() ) { return }

        let user: any = { firstName: firstName.value, lastName: lastName.value }

        if ( newPassword.value.trim().length > 0 ) {

            user = { ...user, password: newPassword.value }

        }

        await modifyUser( user )
        onModalClose()

    }

    const onModalClose = () => { if ( props.onModalClose ) props.onModalClose() }

    const [ lastName, setLastName ] = useState( { error: ``, value: authenticatedUser!.lastName } )
    const [ firstName, setFirstName ] = useState( { error: ``, value: authenticatedUser!.firstName } )
    const [ newPassword, setNewPassword ] = useState( { error: ``, value: `` } )
    const [ confirmNewPassword, setConfirmNewPassword ] = useState( { error: ``, value: `` } )

    return (

        <>

        <Modal className="profile-modal" onModalClose={ onModalClose } >

            <div className="profile-modal__title">

                Adminisztrátori profil

            </div>

            <div className="profile-modal__form">

                <div className="field">

                    <label>Vezetéknév:</label>
                    <TextField field={ lastName } onChange={ e => setLastName( { ...lastName, value: e.target.value } ) } />

                </div>

                <div className="field">

                    <label>Keresztnév:</label>
                    <TextField field={ firstName } onChange={ e => setFirstName( { ...firstName, value: e.target.value } ) } />

                </div>

                <div className="field" style={{ marginTop: 40 }}>

                    <label>Új jelszó:</label>
                    <PasswordField field={ newPassword } onChange={ e => setNewPassword( { ...newPassword, value: e.target.value } ) } />

                </div>

                <div className="field">

                    <label>Új jelszó megerősítése:</label>
                    <PasswordField field={ confirmNewPassword } onChange={ e => setConfirmNewPassword( { ...confirmNewPassword, value: e.target.value } ) } />

                </div>

            </div>

            <div className="profile-modal__footer">

                <button className="button button--primary" onClick={ onSave }>Mentés</button>

            </div>

        </Modal>

        { infoModalVisibility ? (
            <MessageModal
                title={infoModalTitle}
                message={infoModalDescription}
                onCloseClick={() => {
                    setInfoModalVisibility(false)
                }}
            />
        ) : null }

    </>

    )

}