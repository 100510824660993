
import { Expose } from 'class-transformer'
import { IsBoolean, IsDefined, IsNumber, IsOptional, IsString } from 'class-validator'

export class QuestionAnswer {

    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number 

    @Expose()
    @IsDefined()
    @IsString()
    answer!: string 

    @Expose()
    @IsOptional()
    @IsBoolean()
    isSolution?: string
   
}