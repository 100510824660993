import './course-topics-modal.scss'
import {MessageModal, Modal} from '../modal'
import { TextField } from '../field'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { State } from '../../state'
import { deleteCourse, deleteCourseTopic, listCourses, listCourseTopics, saveCourseTopic } from '../../action/courses'

interface CourseTopicsModalProps {

    onClose?: () => void

}

export const CourseTopicsModal = ( props: CourseTopicsModalProps ) => {

    const onClose = () => { if ( props.onClose ) props.onClose()  }

    const { courseTopics } = useSelector( ( state: State ) => state.courses )

    const [ selectedTopic, setSelectedTopic ] = useState( null as number | null )
    const [ name, setName ] = useState( `` )
    const [ color, setColor ] = useState( `` )

    const colors = [ `#2AA7DF`, `#C30870`, `#FFD200`, `#007F4C`, `#7FAD17`, `#9A8822`, `#555ADF`, `#401794`, `#7D3B8A`, `#784CD2`, `#C61A2E`, `#FF168B` ]

    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )
    const [ showConfirmModal, setShowConfirmModal ] = useState( false )

    const [infoModalVisibility, setInfoModalVisibility] = useState(false);
    const [infoModalTitle, setInfoModalTitle] = useState("");
    const [infoModalDescription, setInfoModalDescription] = useState("");

    const onDelete = async () => {

        await deleteFunc!()
        setShowConfirmModal( false )

    }

    const create = () => {

        setName( `` )
        setColor( `#2AA7DF` )
        setSelectedTopic( null )

    }

    const select = ( topic: any ) => {

        setName( topic.name )
        setColor( topic.color )
        setSelectedTopic( topic.id )

    }

    const save = async () => {

        if (name.trim().length === 0) {
            setInfoModalTitle("Figyelmeztetés");
            setInfoModalDescription("A téma nevének megadása kötelező!");
            setInfoModalVisibility(true);
            return;
        }

        await saveCourseTopic( {
            id: selectedTopic,
            name: name,
            color: color
        } )
        await listCourseTopics()
        create()

    }

    const deleteTopic = async () => {

        setDeleteFunc( () => {
            return async () => {
                await deleteCourseTopic( selectedTopic! )
                await listCourseTopics()
                create()
            }
        } )
        setShowConfirmModal( true )

    }

    return (

        <>

            <Modal className="course-topics-modal" onModalClose={ onClose }>

                <img alt="" className="modal-close" src="/image/close.svg" onClick={ onClose } />

                <div className="modal-header">

                    <div className="modal-title">Képzés témák</div>

                </div>

                <div className="modal-body">

                    <div className="topics-list">

                        { courseTopics.map( topic => (

                            <div className="topic" onClick={ () => select( topic ) }>

                                <span>{ topic.name }</span>
                                <img alt="" src="/image/edit.svg" />

                            </div>

                        ) ) }
                        <button className="button button--primary" onClick={ create }>Új téma hozzáadása +</button>

                    </div>

                    <div className="separator"></div>

                    <div className="topic-detail card-div">

                        <div className="topic-detail--title">Téma { selectedTopic === null ? `hozzáadása` : `szerkesztése` }</div>

                        <div className="topic-detail--form">

                            <div className="topic-detail--field">

                                <label>Téma neve:</label>
                                <TextField field={{ value: name, error: `` }} onChange={ e => setName( e.target.value ) } />

                            </div>

                            <div className="topic-detail--field">

                                <label>Téma színe:</label>
                                <div className="color-picker">

                                    <div className="color-picker__selected" style={{ backgroundColor: color }}></div>

                                    <div className="color-picker__selector">

                                        { colors.map( color => (

                                            <div className="color" style={{ backgroundColor: color }} onClick={ () => setColor( color ) }></div>

                                        ) ) }

                                    </div>

                                </div>

                            </div>

                            <div className="topic-detail--footer">

                                { selectedTopic !== null ? (

                                    <button className="button button--warning button--ghost" onClick={ deleteTopic }>Törlés</button>

                                ) : null }

                                <button className="button button--primary" onClick={ save } >Módosítások mentése</button>

                            </div>

                        </div>

                    </div>

                </div>

            </Modal>

            { showConfirmModal ? (

                <Modal className="application-version-confirm-modal" onModalClose={ () => setShowConfirmModal( false ) }>

                    <div className="modal-title">Biztosan törlöd a témát?</div>
                    <div className="modal-description">Ezt a műveletet nem lehet visszavonni! Biztosan törlöd?</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowConfirmModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ onDelete }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

            {infoModalVisibility ? (

                <MessageModal
                    title={infoModalTitle}
                    message={infoModalDescription}
                    onCloseClick={() => {
                        setInfoModalVisibility(false);
                    }}
                />

            ) : null}

        </>

    )

}
