import './season-modal.scss'
import { MediaLibraryFile, Season as SeasonEntity } from '../../entity'
import { MessageModal, Modal } from '../modal'
import React, { useState } from 'react'
import { TextField } from '../field'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { AddImage } from '../add-image'
import { useSelector } from 'react-redux'
import { State } from '../../state'
import { MediaLibrary } from '../media-library'
import { ImageEditor } from '../image-editor/image-editor'
import { createSeason, listSeasons, modifySeason } from '../../action/season'

interface SeasonModalProps {

    season: SeasonEntity | null
    onClose?: () => void

}

export const SeasonModal = ( props: SeasonModalProps ) => {

    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const { active, next, previous } = useSelector( ( state: State ) => state.seasons )

    const st = [ active, ...next, ...previous ].reduce( ( st, season ) => ( season && season!.endDate > st ) ? season!.endDate : st , (today.getTime() / 1000) )

    const onClose = () => { if ( props.onClose ) props.onClose() }
    const [ name, setName ] = useState( props.season ? props.season.name : `` )
    const [ startDate, setStartDate ] = useState( props.season ? props.season.startDate : st )
    const [ endDate, setEndDate ] = useState( props.season ? props.season.endDate : null )
    const [ image, setImage ] = useState( props.season && props.season.image ? props.season.image : null )

    const [ infoModalVisibility, setInfoModalVisibility ] = useState( false )
    const [ infoModalTitle, setInfoModalTitle ] = useState( `` )
    const [ infoModalDescription, setInfoModalDescription ] = useState( `` )

    const [ showMediaLibrary, setShowMediaLibrary ] = useState( false )
    const [ showImageEditor, setShowImageEditor ] = useState( false )
    const [ file, setFile ] = useState( null as MediaLibraryFile | null )

    const checkFields = (): boolean => {

        if ( name.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A szezon nevének megadása kötelező!` )
            setInfoModalVisibility( true )
            return false

        }

        if ( endDate === null ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A szezon záró dátumának megadása kötelező!` )
            setInfoModalVisibility( true )
            return false

        }

        if ( image === null ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A szezon képének kiválasztása kötelező!` )
            setInfoModalVisibility( true )
            return false

        }

        return true

    }

    const save = async () => {

        if ( !checkFields() ) { return }

        let season: any = {

            name: name,
            startDate: startDate,
            endDate: endDate,
            image: image

        }

        if ( props.season === null ) {

            await createSeason( season )

        } else {

            season.id = props.season.id
            await modifySeason( season )

        }

        await listSeasons()
        if ( props.onClose ) props.onClose()

    }

    return (

        <Modal className="season-modal" onModalClose={ onClose }>

            <div className="season-modal__title">{ props.season === null ? `Új szezon hozzáadása` : `Szezon szerkesztése` }</div>

            <div className="season-modal__body">

                <div className="form-field">

                    <label>Szezon neve</label>
                    <TextField field={ { value: name, error: `` } } onChange={ e => setName( e.target.value ) } />

                </div>

                <div className="form-fields">

                    <div>

                        <label>Megjelenés időpontja</label>
                        <DatePicker disabled={ true } onChange={ date => setStartDate( date === null ? st : date.getTime() / 1000 ) } selected={ new Date( startDate * 1000 ) } dateFormat="yyyy. MM. dd." />

                    </div>

                    <div>

                        <label>Elrejtés időpontja</label>
                        <DatePicker onChange={ date => setEndDate( date === null ? null : date.getTime() / 1000 ) } selected={ endDate === null ? null : new Date( endDate * 1000 ) } dateFormat="yyyy. MM. dd." filterDate={ d => d.getTime() > ( startDate * 1000 ) && d.getDay() === 0 } />

                    </div>

                </div>

                <div className="info">

                    A kezdés időpontja jelenleg a legutolsó szezon vége. Minden szezonnak vasárnap kell végetérnie és folytatódnia, nem lehet, olyan, hogy nincs futó szezon.

                </div>

                <div className="image">

                    <AddImage imageUrl={ image ? image.url! : `` } text="Kép hozzáadása a szezonhoz" width="141px" height="141px" onAddImage={ () => setShowMediaLibrary( true ) } onDeleteImage={ () => setImage( null ) } />

                    <span>A szezon képét csak te fogod látni a listában.</span>

                </div>

            </div>

            <div className="season-modal__footer">

                <button className="button button--primary" onClick={ save }>Mentés</button>

            </div>

            <>

                { showMediaLibrary ? (

                    <MediaLibrary onMediaSelected={ ( file: MediaLibraryFile ) => {
                        setFile( file )
                        setShowMediaLibrary( false )
                        setShowImageEditor( true )
                    } } onClose={ () => setShowMediaLibrary( false ) } />

                ) : null }

                { showImageEditor ? (

                    <ImageEditor aspectRatio={ { name: `Szezon`, ratio: 1 / 1 } } mediaLibraryFile={ file! } onReady={ file => {
                        setImage( file )
                        setShowImageEditor( false )
                    } } onClose={ () => setShowImageEditor( false ) } />

                ) : null }

                { infoModalVisibility ? (

                    <MessageModal title={ infoModalTitle } message={ infoModalDescription } onCloseClick={ () => { setInfoModalVisibility(false) } } />

                ) : null }

            </>

        </Modal>

    )

}
