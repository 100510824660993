import { MediaLibraryState } from '../state'

const initialMediaLibraryState: MediaLibraryState = {

    mediaLibraryFolder: null

}

export const mediaLibraryReducer = ( state: MediaLibraryState = initialMediaLibraryState, action: any ): MediaLibraryState => {
    switch ( action.type ) {

        case 'MEDIA_LIBRARY_GET_FOLDER_OK':
            return { ...state, mediaLibraryFolder: action.mediaLibraryFolder }

        default:
            return state
    }
}
