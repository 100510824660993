import { LoaderFunctionArgs } from 'react-router-dom'
import { getStatistics, getNewsStatistics, getEventsStatistics, getQuestionnairesStatistics, getQuotesStatistics } from '../../action/statistics'

export const statisticsLoader = async ( args: LoaderFunctionArgs ) => {

    await getStatistics( )
    await getNewsStatistics( )
    await getEventsStatistics( )
    await getQuestionnairesStatistics( )
    await getQuotesStatistics( )

}
