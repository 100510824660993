export enum PushSettingsEnum {
    QuestDailyClosing = 0,
    QuestFridayBeforeLeagueClose = 1,
    QuestWeeklyClosing = 2,
    QuestMiddleOfTheWeek = 3,
    EventsOneDayBeforeTheEvent = 4,
    ActivityNotLogInForThreeDay = 5,
    ActivityNotLogInForSixDay = 6,
    ActivityDontBreakTheStreak = 9,
    Birthday = 10,
    OneDayAfterRegistrationIfHaventCompletedAnyMission = 11,
    MarkAsAFriend = 12,
    FriendConfirmed = 13,
}
