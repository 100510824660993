import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { resetPassword } from '../../action'
import { PasswordField } from '../../component/field'
import { State } from '../../state'
import { store } from '../../store'
import './reset-password.page.scss'
import { title } from '../../helper'

export const ResetPasswordPage = () => {

    const { token } = useParams()

    const [ password, setPassword ] = useState( { error: ``, value: `` } )
    const [ confirmPassword, setConfirmPassword ] = useState( { error: ``, value: `` } )
    const { resetPasswordBusy, resetPasswordEmailRequested, resetTokenValid } = useSelector( ( state: State ) => state.users )

    const onResetPasswordButtonClick = ( event: MouseEvent ) => { }

    const onPasswordChange = ( event: ChangeEvent<HTMLInputElement> ) => {

        setPassword( { ...password, value: event.target.value, error: `` } )
        setConfirmPassword( { ...confirmPassword, error: `` } )

    }

    const onConfirmPasswordChange = ( event: ChangeEvent<HTMLInputElement> ) => {

        setPassword( { ...password, error: `` } )
        setConfirmPassword( { ...confirmPassword, value: event.target.value, error: `` } )

    }

    const onFormSubmit = ( event: FormEvent ) => {

        event.preventDefault()

        if ( password.value !== confirmPassword.value ) {

            setPassword( { ...password, error: ` ` } )
            setConfirmPassword( { ...confirmPassword, error: `A két jelszó nem egyezik meg!` } )
            return

        }

        store.dispatch( { type: `RESET_PASSWORD_REQUEST` } )
        resetPassword( token!, password.value )

    }

    const navigate = useNavigate()

    return (

        <main className="page page--reset-password">

            <form action="" className="reset-password-form" onSubmit={ onFormSubmit }>

                <img alt="A Demokratikus Koalíció logója" className="reset-password-form__logo" src="/image/dk-logo.svg" />

                { resetTokenValid === false ? (

                    <div>

                        <h1>{ title() }</h1>

                        <div className="description">

                            Sajnos az általad használt<br />hivatkozás nem működik.<br /><br />
                            Lehetséges, hogy túl sokat vártál<br /> és a hivatkozás érvényessége lejárt,<br />
                            vagy már egyszer felhasználtad azt.<br /><br />
                            Próbálkozz újra!

                        </div>

                        <footer>

                            <button className="button" onClick={ e => { e.preventDefault(); navigate( `/bejelentkezes` ) } }>

                                Vissza a bejelentkezéshez

                            </button>

                        </footer>

                    </div>

                ) : resetPasswordEmailRequested !== true ? (

                    <div>

                        <h1>{ title() }</h1>

                        <PasswordField field={ password } onChange={ onPasswordChange } placeholder="jelszó" />
                        <PasswordField field={ confirmPassword } onChange={ onConfirmPasswordChange } placeholder="jelszó megerősítése" />

                        <footer>

                            <button className="button" disabled={ password.value.trim().length === 0 || confirmPassword.value.trim().length === 0 || resetPasswordBusy } onClick={ onResetPasswordButtonClick } type="submit">

                                Módosítás

                            </button>

                        </footer>

                    </div>

                ) : (

                    <div>

                        <h1>Sikeres jelszó változtatás!</h1>

                        <div className="description">

                            Most már beléphetsz az új jelszavaddal!

                        </div>

                        <footer>

                            <button className="button" onClick={ e => { e.preventDefault(); navigate( `/bejelentkezes` ) } }>

                                Vissza a bejelentkezéshez

                            </button>

                        </footer>

                    </div>

                ) }


            </form>

        </main>

    )

}
