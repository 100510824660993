import {  PoliticianPhrase } from '../../../entity'
import './politician-phrase-element.scss'

interface  PoliticianPhraseElementProps {
    politicianPhraseElement: PoliticianPhrase
}

export const PoliticianPhraseElement = ( props: PoliticianPhraseElementProps ) => {

    return (
        <li key={ props.politicianPhraseElement.id+props.politicianPhraseElement.description } className="politician-phreses-list__politician-phrese-element" >
            <span className="label-text description"> { props.politicianPhraseElement.description } </span>
        </li >
    )

}
