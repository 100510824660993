import { Expose, Type } from 'class-transformer'
import { IsDefined, ValidateNested } from 'class-validator'
import { UserGroup } from '../../entity'

export class ListUserGroupsDTO {

    @Expose()
    @IsDefined()
    @Type( type => UserGroup )
    @ValidateNested()
    userGroups!: UserGroup[]

}

