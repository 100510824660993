import { useNavigate } from 'react-router-dom'
import { MenuButton } from '../..'
import { Event } from '../../../entity'

interface EventsTableMenuButtonProps {

    event: Event
    onMenuButtonClick?: ( id: number, eventId: number ) => void

}

export const EventsTableMenuButton = ( props: EventsTableMenuButtonProps ) => {

    const navigate = useNavigate()
    const onMenuButtonClick = ( id: number, eventId: number ) => {
        if ( props.onMenuButtonClick !== undefined ) props.onMenuButtonClick( id, eventId )
    }

    return (

        <MenuButton
            hasBackground={ true }
            items={ [
                { id: 0, value: 'Szerkesztés', icon: 'pin.svg' },
                { id: 1, value: 'Megtekintés', icon: 'eye.svg' },
                { id: 2, value: 'Törlés', icon: 'trash.svg' }
            ] }
            onChange={ ( id: number ) => onMenuButtonClick( id, props.event.id ) }
        />

    )


}
