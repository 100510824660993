import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetPoliticianTitleDTO } from '../../dto/politician'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { getPoliticianTitles } from './get-politician-titles.action'
import { CREATE_POLITICIAN_TITLE_ACTIONS } from './politicians.constants'

export const createPoliticianTitle = async () => {

    const body = JSON.stringify( {
        title: store.getState().createPoliticianTitle.form.title.value,
        position: store.getState().createPoliticianTitle.form.position.value,
    } )

    const titleId = store.getState().createPoliticianTitle.form.id

    store.dispatch( { type: CREATE_POLITICIAN_TITLE_ACTIONS.CREATE_POLITICIAN_TITLE_STARTED } )

    const promise = createCall(`politicians/titles`+ (titleId != null ? (`/`+titleId) : ''), titleId != null ? BASE_METHODS.PUT : BASE_METHODS.POST, body)
    promise.then(async response => {

        let responseBody: GetPoliticianTitleDTO

        try {
            responseBody = plainToClass( GetPoliticianTitleDTO, JSON.parse( await response.text() ) )
        } catch ( exception ) {
            store.dispatch( { type: CREATE_POLITICIAN_TITLE_ACTIONS.CREATE_POLITICIAN_TITLE_INVALID_JSON } )
            return
        }
    
        if ( ( await validate( responseBody ) ).length > 0 ) {
            store.dispatch( { type: CREATE_POLITICIAN_TITLE_ACTIONS.CREATE_POLITICIAN_TITLE_INVALID_RESPONSE } )
            return
        }
    
        store.dispatch( { type: CREATE_POLITICIAN_TITLE_ACTIONS.CREATE_POLITICIAN_TITLE_OK, politicianTitle: responseBody.politicianTitle } )
    
        getPoliticianTitles()

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })
   
}
