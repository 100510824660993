import { store } from '../../store'
import { plainToClass } from 'class-transformer'
import { ListQuizesDTO } from '../../dto/quizes'
import { validate } from 'class-validator'

export const listQuizes = async ( page: number = 1 ) => {

    let body, response

    const { archive, keyword, order } = store.getState().quizes

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/quizes?page=${ page }&archive=${ archive ? `true` : `false` }&order_by=${ order.by }&order_direction=${ order.direction }${ keyword !== `` ? `&keyword=${ keyword }` : `` }`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'GET'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'LIST_QUIZES_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'LIST_QUIZES_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'LIST_QUIZES_UNEXPECTEDRESPONSE' } )
        return

    }

    try {

        body = plainToClass( ListQuizesDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'LIST_QUIZES_INVALIDJSON' } )
        return

    }

    if ( ( await validate( body ) ).length > 0 ) {

        store.dispatch( { type: 'LIST_QUIZES_INVALIDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'LIST_QUIZES_OK', quizes: body.quizes, pagination: body.pagination, highlighted: body.highlighted } )

}