import { QuotesState } from '../state'
import { message } from '../helper'

const initialSessionState: QuotesState = {
    selectedTimeline: 0,
    selectedPolitician: -1,
    allQuotes: [],
    monthQuotes: [],
    orderBy: `views`,
    orderDirection: `DESC`,
    pagination: {
        page: 0,
        from: 0,
        to: 0,
        count: 0
    },
    tomorrow: [],
    quotes: [],
    quoteParties: [],
    quotePoliticians: [],
    scheduledQuotes: [],
    quote: null,
    keyword: ``,
    deleted: false,
    defaultReaction: {
        left: null,
        right: null
    }

}

export const quotesReducer = ( state: QuotesState = initialSessionState, action: any ): QuotesState => {

    switch ( action.type ) {

        case `SET_KEYWORD`:
            return { ...state, keyword: action.keyword }


        case `GET_QUOTE_OK`:
            return { ...state, quote: action.quote }

        case `CHANGE_QUOTES_ORDER`:
            return { ...state, orderBy: action.by, orderDirection: action.direction }

        case `GET_SCHEDULED_QUOTES_OK`:
            return { ...state, tomorrow: action.quotes }

        case `LIST_ALL_QUOTES_OK`:
            return { ...state, allQuotes: action.quotes }

        case `LIST_QUOTES_OK`:
            return { ...state, pagination: action.pagination, quotes: action.quotes }

        case `LIST_QUOTE_PARTIES_OK`:
            return { ...state, quoteParties: action.quoteParties }

        case `LIST_QUOTE_POLITICIANS_OK`:
            return { ...state, quotePoliticians: action.quotePoliticians }

        case `GET_THREE_SCHEDULED_QUOTES_OK`:
            return { ...state, scheduledQuotes: action.scheduledQuotes }

        case `QUOTES_TOGGLE_DELETED`:
            return { ...state, deleted: !state.deleted  }

        case `GET_MONTH_QUOTES_OK`:
            return { ...state, monthQuotes: action.monthQuotes }

        case `SAVE_QUOTE_OK`:
            message( `Az idézet sikeresen mentésre került!` )
            return state

        case `DELETE_QUOTES_OK`:
            message( `Az idézet sikeresen törlésre került!`, `Sikeres törlés` )
            return state

        case `CREATE_QUOTE_PARTY_OK`:
            message( `A párt sikeresen létrehozásra került!` )
            return state

        case `SAVE_QUOTE_PARTY_OK`:
            message( `A párt sikeresen módosításra került!` )
            return state

        case `DELETE_QUOTE_PARTY_OK`:
            message( `A párt sikeresen törlésre került!`, `Sikeres törlés` )
            return state

        case `GET_DEFAULT_REACTIONS_OK`:
            return { ...state, defaultReaction: { left: action.left, right: action.right } }

        case `QUOTES_SET_SELECTED_POLITICIAN`:
            return { ...state, selectedPolitician: action.selectedPolitician }

        case `QUOTES_SET_SELECTED_TIMELINE`:
            return { ...state, selectedTimeline: action.selectedTimeline }

        default:
            return state

    }

}
