import { DELETE_NEWS_CATEGORY_ACTIONS } from '../../action/news-category/news-category.constants'
import { DeleteNewsState } from '../../state/news'
import { message } from '../../helper'

const initialDeleteNewsState: DeleteNewsState  = {
    deleteStarted: null,
    response: null,
}

export const deleteNewsCategoryReducer = ( state: DeleteNewsState = initialDeleteNewsState, action: any ): DeleteNewsState => {
    switch ( action.type ) {
        case DELETE_NEWS_CATEGORY_ACTIONS.DELETE_NEWS_CATEGORY_STARTED:
            return { ...state, deleteStarted: new Date(), response: null}
        case 'DELETE_NEWS_CATEGORY_INVALIDJSON':
            return { ...state, deleteStarted: null, response: null }
        case 'DELETE_NEWS_CATEGORY_INVALIDRESPONSE':
            return { ...state, deleteStarted: null, response: null }
        case DELETE_NEWS_CATEGORY_ACTIONS.DELETE_NEWS_CATEGORY_OK:
            message( `A hír kategória sikeresen törlésre került!`, `Sikeres törlés` )
            return { ...state, deleteStarted: null, response: action.response }
        default:
            return state
    }
}

