import { store } from '../../store'

export const saveWinner = async ( winner: any ) => {

    let response: Response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/winners`, {
            body: JSON.stringify( winner ),
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'POST'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'SAVE_WINNER_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'SAVE_WINNER_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'SAVE_WINNER_UNEXPECTEDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'SAVE_WINNER_OK' } )

}
