import { Modal } from '../../modal'
import { News, Politician } from '../../../entity'
import './news-modal.scss'
import { MouseEventHandler, useRef } from 'react'
import { createFormattedDateTimeString } from '../../../helper'
import { PoliticianCard } from '../../politicians'

interface NewsModalProps {
    newsElement: News | null
    relatedPoliticians?: Politician[]

    onCloseClick?: MouseEventHandler
    onModalClose?: () => void
}

export const NewsModal = ( props: NewsModalProps ) => {

    const politicianElements: JSX.Element[] = []

    if ( props.relatedPoliticians !== null && props.relatedPoliticians !== undefined && props.relatedPoliticians !== undefined ) {
        props.relatedPoliticians.map( ( politicianElement: Politician ) => {
            politicianElements.push( <PoliticianCard politicianElement={ politicianElement } /> )
        } )
    }

    return (
        <Modal onModalClose={ () => props.onModalClose && props.onModalClose() }>

            <div className="news-modal">

                <div className="news-modal__title">
                    Előnézet
                </div>

                <div className="news-modal-content">


                    <img className={ `${ props.newsElement == null ? "image-loading" : "news-image" }` }
                        src={ `${ props.newsElement == null ? null : `${ props.newsElement.imageFile == null ? "/image/dk-logo.svg" : props.newsElement.imageFile.url }` }` } alt="Hírhez tartozó kép" />

                    <div className="category-div">

                        { props.newsElement != null ? ( props.newsElement.category != null ? <span className="category" style={ { background: props.newsElement.category.color } } >{ props.newsElement.category.name }</span> : "" ) : "" }

                        {/* { (props.newsElement != null && props.newsElement.highlighted) ?  <img className="icon" alt="Kiemelt hír ikon" src= "/image/pin.svg"/> : "" } */ }

                        <span className={ `${ props.newsElement == null ? "text-loading" : "label-text date" }` } > { props.newsElement == null ? "" : createFormattedDateTimeString( props.newsElement.visibleFrom ) }</span>

                    </div>

                    { props.newsElement == null ?
                        <h3 className="title-text-loading" ></h3>
                        :
                        <div className="content">

                            { props.newsElement.statementBy == null ?
                                <h3 className="title-text-h3 title"> { props.newsElement.title }</h3>
                                :
                                <h3 className="title-text-h3 title"><a className="statement">{ props.newsElement.statementBy != '' ? props.newsElement.statementBy + ':' : "" }</a> { props.newsElement.title }</h3>
                            }

                            <span className="label-text lead"> { props.newsElement.lead }</span>
                            <div className="label-text description" dangerouslySetInnerHTML={ { __html: props.newsElement.description } } /> 
                           

                        </div>
                    }

                </div>

                <footer className="news-modal__footer">
                    <button className="button" onClick={ props.onCloseClick }> OK </button>
                </footer>

            </div>

        </Modal>
    )
}
