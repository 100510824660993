import { LoaderFunctionArgs } from 'react-router-dom'
import { getUsers, getUserStats, listBannedWords } from "../../action"
import {store} from "../../store";

export const mobileAppUsersLoader = async ( args: LoaderFunctionArgs ) => {

    store.dispatch( { type: 'UPDATE_SEARCHTERM', searchTerm: `` } )
    await getUserStats()
    await getUsers()
    await listBannedWords()

}
