import { LoginState } from '../state'

const initialLoginState: LoginState = {
    alertMessage: null,
    form: {
        email: {
            error: '',
            value: ''
        },
        password: {
            error: '',
            value: ''
        }
    },
    loginStarted: null
}

export const loginReducer = ( state: LoginState = initialLoginState, action: any ): LoginState => {

    switch ( action.type ) {

        case 'LOGIN_CHANGE_EMAIL':
            return { ...state, form: { ...state.form, email: { ...state.form.email, value: action.email } } }
        case 'LOGIN_CHANGE_PASSWORD':
            return { ...state, form: { ...state.form, password: { ...state.form.password, value: action.password } } }
        case 'LOGIN_STARTED':
            return { ...state, loginStarted: new Date() }
        case 'LOGIN_UNEXPECTEDRESPONSE':
        case 'LOGIN_INVALIDJSON':
        case 'LOGIN_INVALIDRESPONSE':
            return { ...state, loginStarted: null, alertMessage: `Váratlan hiba történt!\nA hiba tényét rögzítettük és továbbítottuk a fejlesztőknek!` }
        case 'LOGIN_TIMEOUT':
            return { ...state, loginStarted: null, alertMessage: `A szerver jelenleg nem elérhető!\nKérjük próbáld újra később!` }
        case 'LOGIN_UNAUTHORIZED':
            return { ...state, form: { ...state.form, email: { ...state.form.email, error: 'unauthorized' }, password: { ...state.form.password, error: 'unauthorized' } }, loginStarted: null }
        case 'LOGIN_OK':
            return { ...state, loginStarted: null }
        case `SET_ALERT_MESSAGE`:
            return { ...state, alertMessage: action.message }
        default:
            return state

    }

}
