import { useDispatch, useSelector } from 'react-redux'
import { Table } from '../../../component'
import { createFormattedDateTimeString } from '../../../helper'
import { State } from '../../../state'
import { FloatingMessageTableMenuButton } from './floating-message-table-menu-button'
import { GET_FLOATING_MESSAGES_ACTIONS } from '../../../action/floating-messages/floating-messages.constants'
import './floating-message-table.scss'
import { getFloatingMessages } from '../../../action/floating-messages'

export const FloatingMessageTable = () => {

    const dispatch = useDispatch()
    const { order, floatingMessages } = useSelector( ( state: State ) => state.floatingMessages )
    const { page, from, to, count} = useSelector( ( state: State ) => state.floatingMessages.pagination )

    const onPageChange = ( page: number ) => {
        dispatch( { type: GET_FLOATING_MESSAGES_ACTIONS.GET_FLOATING_MESSAGES_CHANGE_PAGE, page: page } )
        getFloatingMessages()
    }

    const columns = [
        {
            align: `center`,
            caption: `ID`,
            key: `id`,
            name: `id`,
            width: 100
        },
        {
            caption: ``,
            render: ( row: any ) => <span className="message-body">{ row.message }</span>,
            name: `message`,
            width: 900
        },
        {
            caption: ``,
            render: ( data: any ) => <img className="floating_icon" alt="Floating Feri ikon" src= {'/image/floating/floating_'+data.imageId+'.png'} />,
            width: 150
        },
        {
            align: `center`,
            caption: `Publikálás`,
            render: ( data: any ) => createFormattedDateTimeString( data.created ),
            name: `created`,
            width: 200
        },
        {
            align: `center`,
            caption: `Helye`,
            render: ( data: any ) => <span>{ (data.type == 1) ? "Hírek" : (data.type == 2) ? "Események" : (data.type == 3) ? "Kérdőívek" : "Politikusok" }</span>,
            width: 200
        },
        {
            align: `center`,
            caption: ``,
            name: `actions`,
            render: ( data: any ) => <FloatingMessageTableMenuButton floatingMessageElement={ data } />,
            width: 80
        }
    ]

    return (
        <Table
            className="floating-message-table"
            order={ { by: `created`, direction: order } }
            columns={ columns }
            dataset={ ( floatingMessages !== null && floatingMessages !== undefined ) ? floatingMessages : [] }
            pagination={ { page: page, from: from, to: to, count: count, onPageChange: onPageChange } } />
    )

}
