import { store } from '../../store'
import { QuestGroup } from '../../entity'
import { plainToClass } from 'class-transformer'
import { SaveQuestGroupDTO } from '../../dto/gamification'

export const saveQuestGroup = async ( questGroup: QuestGroup, achievementData: any, seasonId: number ) => {

    let response, body

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/quest-groups`, {
            body: JSON.stringify( {
                deleteQuests: questGroup.deleteQuests,
                id: questGroup.id,
                seasonId: seasonId,
                name: questGroup.name,
                lead: questGroup.lead,
                description: questGroup.description,
                image: questGroup.image?.id || null,
                exp: questGroup.exp,
                leagues: questGroup.selectedLeagues || questGroup.leagues.map( league => league.id ),
                tags: questGroup.tags,
                achievementData: achievementData,
                quests: questGroup.quests ? questGroup.quests.map( quest => { return {
                    id: quest.id,
                    name: quest.name,
                    type: quest.type,
                    url: quest.url,
                    questConditionId: quest.questConditionId,
                    contentId: ( typeof quest.contentId === `string` && ( quest.contentId as string ).length === 0 ) ? null : quest.contentId,
                    repeats: parseInt( quest.repeats.toString() )
                } } ) : []
            } ),
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'POST'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'SAVE_QUEST_GROUP_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'SAVE_QUEST_GROUP_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'SAVE_QUEST_GROUP_UNEXPECTEDRESPONSE' } )
        return

    }

    try {

        body = plainToClass( SaveQuestGroupDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'LIST_LEAGUES_INVALIDJSON' } )
        return

    }

    store.dispatch( { type: 'SAVE_QUEST_GROUP_OK' } )
    return body.questGroup

}
